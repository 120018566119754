
import React, { useContext } from 'react';
import styled from 'styled-components';

import { List__table, List__content } from 'components/list/list';
import { Grid__card } from 'components/grid/grid';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';

import Content__tag from 'components/content/tag/content__tag';
import { ContentContext } from 'context/contentContext';
import { sequelizeDate } from 'util/date.util';
import { parseStatus } from 'util/conditions/statusProps.util';
import { strapiRepairApprovalService } from 'util/strapi/content.starpi';
import t from 'theme';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';


const Container = styled.div`
  height: 100%;
  min-height: 400px;
`;

const ListCardContainer = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'center' })};
  height: 40px;
  margin-bottom: 30px;
`;

const Card = styled(Grid__card)`
  height: 100%;
`;

const Table = styled(List__table)`
  height: calc(100% - 70px);
`;

const LeadGenerationWorkshopHistoric = ({
  style, tableEvent, data, scrollEndEvent, loadingNewPage, config,
}) => {
  const { getField, getGlobal, getFieldGroup } = useContext(ContentContext);
  const headerCol = [
    {
      label: getGlobal({ option: 'workshopInformation', field: 'email' }),
    },
    {
      label: getGlobal({ option: 'service_table', field: 'date_header' }),
    },
    {
      label: getGlobal({ option: 'service_table', field: 'status_header' }),
    },
  ];

  const mailStatus = {
    [config.constant.mailStatus.DONE]: getFieldGroup({ base: config.strapiPage, groupe: config.constant.mailContent.strapiGroup, field: config.constant.mailContent.done }),
    [config.constant.mailStatus.WAITING]: getFieldGroup({ base: config.strapiPage, groupe: config.constant.mailContent.strapiGroup, field: config.constant.mailContent.waiting }),
    [config.constant.mailStatus.ERROR]: getFieldGroup({ base: config.strapiPage, groupe: config.constant.mailContent.strapiGroup, field: config.constant.mailContent.error }),
  };

  const renderList = () => {
    const orderArray = data;
    const arrayOfLine = [];

    orderArray.forEach((element) => {
      arrayOfLine.push([

        {
          component: (
            <List__content>{element.fleetCompanyName}</List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>
              {sequelizeDate({ date: element.createdAt, format: 'd/m/y' })}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <Content__tag
              content={mailStatus[element.status]}
              {...parseStatus({
                hand: true,
                status: config.constant.mailStatusProps[element.status],
              })}
            />
          ),
          type: 'node',
        },
      ]);
    });

    return arrayOfLine;
  };

  const handleLineClick = (vehicle) => {
    tableEvent(vehicle, data[vehicle.key]);
  };

  return (
    <Container style={style}>
      <Card padding="30px">
        <ListCardContainer>
          <Content__cardTitle content={getField({ base: strapiRepairApprovalService, field: 'dashboard_historic_title' })} />
        </ListCardContainer>
        <Table
          headerCol={headerCol}
          lineCol={data ? renderList() : []}
          noSelect
          lineEvent={handleLineClick}
          maxHeight="100%"
          scrollEndEvent={scrollEndEvent}
        />
        {
          loadingNewPage && (
            <Loader__wrapper />
          )
        }
      </Card>
    </Container>
  );
};

export default LeadGenerationWorkshopHistoric;
