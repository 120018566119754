
/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';
import './loader__small.scss';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ContainerSpinner = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
`;

const Spinner = styled.div`
    width: 30px;
    height: 30px;
`;

const Bar = styled.div`
  border: 3px solid ${t.color.dark};
  border-color: ${({ color }) => color || t.color.dark};
`;

const Loader__small = ({ style, color }) => (
  <Container style={style}>
    <ContainerSpinner>
      <Spinner className="spinner">
        <div className="right-side">
          <Bar className="bar" color={color} />
        </div>
        <div className="left-side">
          <Bar className="bar" color={color} />
        </div>
      </Spinner>
    </ContainerSpinner>
  </Container>
);

export default Loader__small;
