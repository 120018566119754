
import React, {
  useContext, useEffect, useState,
} from 'react';
import Nav from 'components/global/nav';
import t from 'theme';
import { ContentContext } from 'context/contentContext';

import {
  faListOl, faQuestion, faBookOpen, faShoppingCart,
} from '@fortawesome/pro-light-svg-icons';
import Layout__container from 'containers/layout/container/layout__container';
import { Transition__loader_controller } from 'components/transition/transition';
import DashboardRepairApproval__historic from 'containers/workshopView/dashboardRepairApproval/historic/dashboardRepairApproval__historic';
import DashboardRepairApproval__action from 'containers/workshopView/dashboardRepairApproval/action/dashboardRepairApproval__action';
import DashboardRepairApproval__infoPop from 'containers/workshopView/dashboardRepairApproval/infoPop/dashboardRepairApproval__infoPop';
import DashboardRepairApproval__singlePop from 'containers/workshopView/dashboardRepairApproval/singlePop/dashboardRepairApproval__singlePop';
import {
  strapiRepairApprovalService,
  strapiSingleServicePage,
  strapiWorkshopDashboardRepairApproval,
  strapiPaymentGuaranteeService,
  strapiStandartrepairService,
} from 'util/strapi/content.starpi';
import ServiceDashboard__layout from 'containers/workshopView/serviceDashboard/layout/serviceDashboard__layout';
import { ROUTING } from 'util/constant/routing.constant';
import { getParams } from 'util/get.util';
import { getAction } from 'util/function/reloadAction.function';
import { SERVICENAME } from 'util/service/serviceName';
import { formatApiCountRes } from 'util/function/formatApiRes.function';
import { AppContext } from 'context/appContext';
import { getActionNeededWorkshopRepairApproval } from 'helpers/api/routes/getData';
import { useData } from 'components/data/getter/data__getter';
import { faHandshakeAlt } from '@fortawesome/pro-regular-svg-icons';


const DashboardRepairApproval = () => {
  const {
    loadPage, haveContentFor, getField, getGlobal,
  } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [activeinfoPop, setactiveinfoPop] = useState(false);
  const [, setactiveSettingPop] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activesinglePop, setactivesinglePop] = useState(false);
  const [, setactiveLaunchPop] = useState(false);
  const [actionCount, setactionCount] = useState(0);
  let pageNumber = 1;
  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      id: user.user.UserWorkshop.id,
      user: true,
      workshop: true,
      userid: user.user.id,
      both_counter: true,
      received: activeTab === 0 ? true : undefined,
    },
    service: SERVICENAME.repairapproval,
    type: 'service',
  });

  useEffect(() => {
    refreshData({
      id: user.user.UserWorkshop.id,
      user: true,
      workshop: true,
      userid: user.user.id,
      both_counter: true,
      received: activeTab === 0 ? true : undefined,
    });
  }, [activeTab]);

  const handleCheckParams = () => {
    const actionParams = getAction({ key: 'action' });
    if (actionParams.success === true && actionParams.value !== true) {
      setactivesinglePop({ active: true, id: actionParams.value });
    }
  };

  const loadContent = async () => {
    if (haveContentFor(strapiRepairApprovalService).success === false) {
      await loadPage(strapiRepairApprovalService);
    }
    if (haveContentFor(strapiSingleServicePage).success === false) {
      await loadPage(strapiSingleServicePage);
    }
    if (haveContentFor(strapiWorkshopDashboardRepairApproval).success === false) {
      await loadPage(strapiWorkshopDashboardRepairApproval);
    }
    if (haveContentFor(strapiStandartrepairService).success === false) {
      await loadPage(strapiStandartrepairService);
    }
    if (haveContentFor(strapiPaymentGuaranteeService).success === false) {
      await loadPage(strapiPaymentGuaranteeService);
    }

    if (getParams('launch')) {
      setactiveLaunchPop(true);
      window.history.pushState({}, '', window.location.pathname);
    }

    handleCheckParams();
  };

  const getActionNeeded = async () => {
    const res = await getActionNeededWorkshopRepairApproval({
      filter: null,
      limit: 10,
      page: 1,
      id: user.user.UserWorkshop.id,
      userWorkshopid: user.user.id,
    });
    const formated = formatApiCountRes(res);
    setactionCount(formated.count);
  };

  useEffect(() => {
    loadContent();
    getActionNeeded();
  }, []);

  if (
    haveContentFor(strapiRepairApprovalService).success === false
    || haveContentFor(strapiSingleServicePage).success === false
    || haveContentFor(strapiWorkshopDashboardRepairApproval).success === false
    || haveContentFor(strapiStandartrepairService).success === false
    || haveContentFor(strapiPaymentGuaranteeService).success === false
  ) {
    return <Transition__loader_controller active />;
  }

  const handleCloseInfoPop = () => {
    setactiveinfoPop(false);
  };

  const handleOpenInfoPop = () => {
    setactiveinfoPop(true);
  };

  const handleOpenSettingPop = () => {
    setactiveSettingPop(true);
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({ active: false, id: null });
  };


  const handleOpenSinglePop = ({ id }) => {
    setactivesinglePop({ active: true, id });
  };

  const renderInfoPopProps = () => ({
    intro: {
      title: getField({ base: strapiRepairApprovalService, field: 'service_title' }),
      txt: getField({ base: strapiRepairApprovalService, field: 'description' }),
      price: `0,00 ${getField({ base: strapiSingleServicePage, field: 'currency' })}`,
      label: getField({ base: strapiSingleServicePage, field: 'price_detail' }),
      banner: t.image.global.repair_approval,
    },
    condition1: {
      content: getField({ base: strapiRepairApprovalService, field: 'registration_processs' }),
      title: getField({ base: strapiSingleServicePage, field: 'registration_processs' }),
      icon: faListOl,
    },
    condition2: {
      content: getField({ base: strapiRepairApprovalService, field: 'how' }),
      title: getField({ base: strapiSingleServicePage, field: 'how' }),
      icon: faQuestion,
    },
    condition3: {
      content: getField({ base: strapiRepairApprovalService, field: 'conditions' }),
      title: getField({ base: strapiSingleServicePage, field: 'conditions' }),
      icon: faBookOpen,
    },
    condition4: {
      // content: <div dangerouslySetInnerHTML={{ __html: getField({ base: strapiRepairApprovalService, field: 'payment' }) }} />,
      content: getField({ base: strapiRepairApprovalService, field: 'payment' }),
      title: getField({ base: strapiSingleServicePage, field: 'payment' }),
      icon: faShoppingCart,
    },
  });

  const handleTableClick = (line, vehicle) => {
    handleOpenSinglePop({ id: vehicle.id });
  };


  const handleLoadNewPage = () => {
    if (resData.data && !resData.loading && pageNumber + 1 > 1 && resData.data.length < resData.count) {
      pageNumber += 1;
      setPage(pageNumber, resData.data);
    }
  };

  return (
    <Layout__container>
      <Nav workshop active="cases" />
      <Transition__loader_controller active={false} />
      <ServiceDashboard__layout
        page_title={getField({ base: strapiRepairApprovalService, field: 'service_title' })}
        page_icon={faHandshakeAlt}
        actionCount={actionCount}
        breadCrumb_parent={getGlobal({ option: 'workshop_menu', field: 'my_cases' })}
        breadCrumb_parentLink={ROUTING.workshop.cases.path}
        // Service history layout
        history={(
          <DashboardRepairApproval__historic
            tableEvent={handleTableClick}
            data={resData ? resData.data : []}
            count={resData.count}
            brut={resData}
            scrollEndEvent={handleLoadNewPage}
            loadingNewPage={resData.loading}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        // Action side element
        action={(
          <DashboardRepairApproval__action
            infoEvent={handleOpenInfoPop}
            settingsEvent={handleOpenSettingPop}
            count={resData.count}
          />
        )}
        // Info pop up
        activeInfoPop={activeinfoPop}
        closeInfoPop={handleCloseInfoPop}
        infoPopProps={renderInfoPopProps()}
        infoPop={<DashboardRepairApproval__infoPop />}
        // Single pop up
        activeSinglePop={activesinglePop}
        closeSinglePop={handleCloseSinglePop}
        singlePop={(
          <DashboardRepairApproval__singlePop
            active={activesinglePop.active}
            id={activesinglePop.id}
            closeEvent={handleCloseSinglePop}
          />
        )}
      />
    </Layout__container>
  );
};

export default DashboardRepairApproval;
