/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

const Container = styled.span`
  font-weight: normal;
  font-size: 25px;
  color: #4d4d4d;
`;

const Content__cardTitle = ({ style, content, className }) => (
  <Container style={style} className={className}>
    {content}
  </Container>
);

export default Content__cardTitle;
