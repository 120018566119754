
import React from 'react';
import styled from 'styled-components';


/**
 * styled components
 */

const Container = styled.div`
  background-color: ${(props) => props.background || 'transparent'};
  width: 100%;
  height: ${(props) => (props.overflow ? '100vh' : 'auto')};
  overflow: ${(props) => (props.overflow ? 'hidden' : 'visible')};
`;


/**
 * @name Layout__container
 * @property pageName: name of the page
 * @property children: child content
 */

const Layout__container = ({
  style, children, overflow, background, className,
}) => (
  <Container
    style={style}
    overflow={overflow}
    background={background}
    className={className}
  >
    {children}
  </Container>
);

export default Layout__container;
