/**
 * Import Lib & assets
 */

import React, { useState } from 'react';
import styled from 'styled-components';

/**
 * import components
 */
import { Content__text } from 'components/content/content';
import { Form__textfield } from 'components/form/form';

/**
 * styled components
 */

const Container = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
`;

const Line = styled.tr`
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  border-bottom: ${({ borderLine }) => (borderLine ? '1px solid rgba(0,0,0,0.20)' : 'none')};
`;

const ColItem = styled.td`
  display: table-cell;
  vertical-align: top;
  text-align: left;
  line-break: inherit;
  word-wrap: break-word;
  width: auto;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-right: 20px;
`;

const ItemContent = styled(Content__text)`
  font-size: 12px;
`;

/**
 * inputTable component is..
 */

const List__inputTable = ({
  style, col, checkButton,
}) => {
  const [colState, setColState] = useState([
    {
      Registration: '',
      Country: '',
      Vin: '',
      Cat: '',
      Brand: '',
      First: '',
      Mileage: '',
    },
  ]);

  const addLineToState = ({ temp }) => {
    temp.push({
      Registration: '',
      Country: '',
      Vin: '',
      Cat: '',
      Brand: '',
      First: '',
      Mileage: '',
    });
    setColState(temp);
  };

  const isEmpty = ({ array }) => {
    let empty = false;
    array.forEach((element) => {
      if (
        element.Registration !== ''
        || element.Country !== ''
        || element.Vin !== ''
        || element.Cat !== ''
        || element.Brand !== ''
        || element.First !== ''
        || element.Mileage !== ''
      ) {
        if (
          element.Registration === ''
          || element.Country === ''
          || element.Vin === ''
          || element.Cat === ''
          || element.Brand === ''
          || element.First === ''
          || element.Mileage === ''
        ) {
          empty = true;
        }
      }
    });

    return empty;
  };

  const handleInputChange = ({ property, value }) => {
    const keys = property.split('[-]');
    const temp = [...colState];
    if (
      colState[parseInt(keys[0], 10)].Registration !== ''
      && colState[parseInt(keys[0], 10)].Country !== ''
      && colState[parseInt(keys[0], 10)].Vin !== ''
      && colState[parseInt(keys[0], 10)].Cat !== ''
      && colState[parseInt(keys[0], 10)].Brand !== ''
      && colState[parseInt(keys[0], 10)].First !== ''
      && colState[parseInt(keys[0], 10)].Mileage === ''
    ) {
      temp[parseInt(keys[0], 10)][keys[1]] = value;
      checkButton(isEmpty({ array: temp }));
      addLineToState({ temp });
    } else {
      temp[parseInt(keys[0], 10)][keys[1]] = value;
      checkButton(isEmpty({ array: temp }));
      setColState(temp);
    }
  };

  return (
    <Container style={style}>
      {colState.map((line, key1) => (
        <Line style={style} key={key1}>
          <ColItem>
            <ItemContent>
              <Form__textfield
                change={handleInputChange}
                value={line.Registration}
                property={`${key1}[-]Registration`}
                placeholder={col[0].label}
              />
            </ItemContent>
          </ColItem>
          <ColItem>
            <ItemContent>
              <Form__textfield
                change={handleInputChange}
                value={line.Country}
                property={`${key1}[-]Country`}
                placeholder={col[1].label}
              />
            </ItemContent>
          </ColItem>
          <ColItem>
            <ItemContent>
              <Form__textfield
                change={handleInputChange}
                value={line.Vin}
                property={`${key1}[-]Vin`}
                placeholder={col[2].label}
              />
            </ItemContent>
          </ColItem>
          <ColItem>
            <ItemContent>
              <Form__textfield
                change={handleInputChange}
                value={line.Cat}
                property={`${key1}[-]Cat`}
                placeholder={col[3].label}
              />
            </ItemContent>
          </ColItem>
          <ColItem>
            <ItemContent>
              <Form__textfield
                change={handleInputChange}
                value={line.Brand}
                property={`${key1}[-]Brand`}
                placeholder={col[4].label}
              />
            </ItemContent>
          </ColItem>
          <ColItem>
            <ItemContent>
              <Form__textfield
                change={handleInputChange}
                value={line.First}
                property={`${key1}[-]First`}
                placeholder={col[5].label}
              />
            </ItemContent>
          </ColItem>
          <ColItem>
            <ItemContent>
              <Form__textfield
                change={handleInputChange}
                value={line.Mileage}
                property={`${key1}[-]Mileage`}
                placeholder={col[6].label}
              />
            </ItemContent>
          </ColItem>
        </Line>
      ))}
    </Container>
  );
};

export default List__inputTable;
