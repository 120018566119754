
import React, {
  useEffect, useState, Fragment, useContext,
} from 'react';
import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { Grid__separator } from 'components/grid/grid';
import { faTruck, faTools, faUser } from '@fortawesome/pro-solid-svg-icons';
import Content__labelText from 'components/content/labelText/content__labelText';
import Content__labelTextarea from 'components/content/labelTextarea/content__labelTextarea';
import Content__serviceStatus from 'components/content/serviceStatus/content__serviceStatus';
import { GetSingleService } from 'helpers/api/routes/Service24';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import Content__tag from 'components/content/tag/content__tag';
import {
  getFleetInformation,
  getFleetUserInformation,
} from 'util/service/getDataFromService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { strapiService24Service } from 'util/strapi/content.starpi';
import { parseStatus } from 'util/conditions/statusProps.util';
import { sequelizeDate } from 'util/date.util';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import {
  Header,
  Title,
  ItemContainer,
  Row,
  Popup,
} from 'containers/workshopView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import { SERVICENAME } from 'util/service/serviceName';
import { AppContext } from 'context/appContext';

const Container = styled.div``;

const Column = styled.div``;

const DashboardService24__singlePop = ({
  style, active, closeEvent, id,
}) => {
  const { user } = useContext(AppContext);
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(false);
  const {
    getFieldGroup, loadPage, haveContentFor, getField, getGlobal,
  } = useContext(ContentContext);
  const getService = async () => {
    const res = await GetSingleService({ id });
    if (res) {
      setdata(res.data.data);
      setloading(false);
    }
  };

  useEffect(() => {
    if (haveContentFor(strapiService24Service).success === false) {
      loadPage(strapiService24Service);
    }
  }, []);

  useEffect(() => {
    if (active === true) {
      setloading(true);
      getService();
    }
  }, [active]);

  const renderLoader = () => <Loader__wrapper />;

  if (
    haveContentFor(strapiService24Service).success === false
  ) {
    return (
      <Container style={style}>
        <Popup active={active} closeEvent={closeEvent}>
          {renderLoader()}
        </Popup>
      </Container>
    );
  }

  const renderContent = () => (
    <Fragment>
      <Header>
        <Title>{getField({ base: strapiService24Service, field: 'service_title' })}</Title>
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_id' })}
          value={data.uid}
        />
        <Grid__separator width="15px" />
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_date' })}
          value={sequelizeDate({ date: data.createdAt, format: 'd/m/y' })}
        />
      </Header>
      <Grid__separator size="80px" />
      <Content__serviceStatus
        tag={(
          <Content__tag
            border
            content={getFieldGroup({
              base: strapiService24Service,
              groupe: 'status',
              field: data.status,
            })}
            {...parseStatus({ status: data.status })}
          />
        )}
        {...parseStatus({ status: data.status })}
      >
        <Grid__separator size="10px" />
        {getField({ base: strapiService24Service, field: 'response_text' })}
      </Content__serviceStatus>
      <Grid__separator size="100px" />
      <Row>
        <ItemContainer>
          <Content__sectionTitle
            line
            title={getField({ base: strapiService24Service, field: 'vehicle_title' })}
            icon={<FontAwesomeIcon icon={faTruck} />}
          />
          <Row>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' })}
                content={getFleetInformation({ obj: data, key: 'registrationNumber' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'category' })}
                content={getGlobal({ option: 'vehicletype', field: getFleetInformation({ obj: data, key: 'type' }) }) || ''}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' })}
                content={getGlobal({ option: 'country', field: getFleetInformation({ obj: data, key: 'countryOfRegistration' }) }) || ''}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'mileage' })}
                content={getFleetInformation({ obj: data, key: 'mileage' })}
              />
            </Column>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'vincode' })}
                content={getFleetInformation({ obj: data, key: 'vin' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'brand' })}
                content={getFleetInformation({ obj: data, key: 'brand' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'fleet' })}
                content={getFleetUserInformation({ obj: data, key: 'companyName' })}
              />
            </Column>
          </Row>
        </ItemContainer>
        <ItemContainer>
          <Content__sectionTitle
            line
            title={getField({ base: strapiService24Service, field: 'form_driver_title' })}
            icon={<FontAwesomeIcon icon={faUser} />}
          />
          <Row>
            <Column>
              <Content__labelText
                label={getField({ base: strapiService24Service, field: 'form_driver_name' })}
                content={data.driverName}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getField({ base: strapiService24Service, field: 'form_driver_phone' })}
                content={data.driverPhone}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getField({ base: strapiService24Service, field: 'form_driver_language' })}
                content={data.driverLanguage}
              />
            </Column>
          </Row>
        </ItemContainer>
      </Row>
      <Grid__separator size="100px" />
      <Content__sectionTitle
        line
        title={getField({ base: strapiService24Service, field: 'repair_title' })}
        icon={<FontAwesomeIcon icon={faTools} />}
      />
      <Row>
        <Column style={{ width: '60%' }}>
          <Content__labelTextarea
            label={getField({ base: strapiService24Service, field: 'breakdown_description' })}
            lang={data.textLang}
            convert={user.user.lang}
            content={data.description}
            dataName={SERVICENAME.service24}
            base={data.translate}
            id={data.id}
          />
          <Grid__separator size="30px" />
          <Content__labelTextarea
            label={getField({ base: strapiService24Service, field: 'additional_information' })}
            lang={data.textLang}
            convert={user.user.lang}
            content={data.additional}
            dataName={`${SERVICENAME.service24}_2`}
            base={data.translate2}
            id={data.id}
          />
        </Column>
        <Column style={{ width: '25%' }}>
          {
            data.country && (
              <Fragment>
                <Content__labelText
                  capitalize
                  label={getField({ base: strapiService24Service, field: 'country' })}
                  content={data.country}
                />
                <Grid__separator size="30px" />
              </Fragment>
            )
          }
          {
            data.city && (
              <Fragment>
                <Content__labelText
                  label={getField({ base: strapiService24Service, field: 'city' })}
                  content={data.city}
                />
                <Grid__separator size="30px" />
              </Fragment>
            )
          }
          {
            data.location && (
              <Fragment>
                <Content__labelText
                  label={getField({ base: strapiService24Service, field: 'location' })}
                  content={data.location}
                />
                <Grid__separator size="30px" />
              </Fragment>
            )
          }
          {
            data.road && (
              <Fragment>
                <Content__labelText
                  label={getField({ base: strapiService24Service, field: 'road' })}
                  content={data.road}
                />
                <Grid__separator size="30px" />
              </Fragment>
            )
          }
          <Content__labelText
            capitalize
            label={getField({ base: strapiService24Service, field: 'severity' })}
            content={data.severity}
          />
          <Grid__separator size="25px" />
        </Column>
      </Row>
    </Fragment>
  );

  return (
    <Container style={style}>
      <Popup active={active} closeEvent={closeEvent}>
        {loading || !data ? renderLoader() : renderContent()}
      </Popup>
    </Container>
  );
};

export default DashboardService24__singlePop;
