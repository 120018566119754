import React, {
  useContext, useEffect, Fragment, useState,
} from 'react';

import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import Content__tag from 'components/content/tag/content__tag';

import Cards__listCard from 'components/cards/listCard/cards__listCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { sequelizeDate } from 'util/date.util';
import { strapiPriority90Service, strapiServiceHeader } from 'util/strapi/content.starpi';
import { ROUTING } from 'util/constant/routing.constant';
import { handleLineClick_singleNew } from 'util/function/openSingleService';
import DashboardPriority90__singlePop from 'containers/fleetView/dashboardPriority90/singlePop/dashboardPriority90__singlePop';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import List__content from 'components/list/content/list__content';
import { SERVICENAME } from 'util/service/serviceName';
import { getFormService } from 'util/function/getter';
import { useData } from 'components/data/getter/data__getter';
import { parseStatus, renderPriority90FleetStatus } from 'util/conditions/statusProps.util';


const MyServices__repairApproval = ({ basePage }) => {
  const {
    loadPage, haveContentFor, getField, getFieldGroup, getGlobal,
  } = useContext(ContentContext);

  const { user } = useContext(AppContext);
  const [activesinglePop, setactivesinglePop] = useState({
    active: false,
    id: null,
  });

  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      id: user.user.UserFleet.id,
      user: true,
    },
    service: SERVICENAME.priority90,
    type: 'service',
    limite: 3,
  });

  useEffect(() => {
    if (haveContentFor(strapiPriority90Service).success === false) {
      loadPage(strapiPriority90Service);
    }
  }, []);

  const headerCol = [
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'vehicle_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'date_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'status_header' }),
    },
  ];

  const renderList = () => {
    const orderArray = resData.data;
    const arrayOfLine = [];

    if (orderArray) {
      orderArray.forEach((element) => {
        arrayOfLine.push([
          {
            component: (
              <List__content uppercase>{getFormService(element, 'registrationNumber', 'vehicle')}</List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <List__content>
                {sequelizeDate({ date: getFormService(element, 'createdAt'), format: 'd/m/y' })}
              </List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <Content__tag
                content={
                  getFormService(element, 'status') === 'done'
                    ? getField({ base: strapiPriority90Service, field: 'status_fleet_done' })
                    : getFieldGroup({
                      base: strapiPriority90Service,
                      groupe: 'status',
                      field: renderPriority90FleetStatus(getFormService(element, 'status')),
                    })
                }
                {...parseStatus({ status: renderPriority90FleetStatus(getFormService(element, 'status')) })}
              />
            ),
            type: 'node',
          },
        ]);
      });
    }

    return arrayOfLine;
  };


  const handleLine = (line) => {
    handleLineClick_singleNew({
      line,
      array: resData.data,
      callback: ({ active, id }) => setactivesinglePop({
        active,
        id,
      }),
    });
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({
      active: false,
      id: null,
    });
  };

  return (
    <Fragment>
      <Cards__listCard
        countValue={0}
        headerCol={headerCol}
        line={resData && resData.data ? renderList() : []}
        serviceName="priority90"
        icon={<FontAwesomeIcon icon={faStopwatch} />}
        title={getField({ base: strapiPriority90Service, field: 'service_title' })}
        buttonRequest={getField({ base: basePage, field: 'dashboard_button' })}
        buttonRequestHref={`${ROUTING.fleet.dashboardPriority90.path}`}
        loading={!haveContentFor(strapiPriority90Service).success || resData.loading}
        hasLoader
        lineEvent={handleLine}
        fleet
        width="380px"
      />
      <DashboardPriority90__singlePop
        active={activesinglePop.active}
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
    </Fragment>
  );
};

export default MyServices__repairApproval;
