
/**
   * Import Lib & assets
   */

import React, {
  Fragment, useState, useContext,
} from 'react';
import styled from 'styled-components';
import Form__textfield from 'components/form/textfield/form__textfield';
import Button__primary from 'components/button/primary/button__primary';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid__separator from 'components/grid/separator/grid__separator';
import t from 'theme';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { AppContext } from 'context/appContext';
import { formatApiRes } from 'util/function/formatApiRes.function';
import Grid__card from 'components/grid/card/grid__card';
import { ContentContext } from 'context/contentContext';
import Grid__popup from 'components/grid/popup/grid__popup';
import { createErrorObject } from 'util/conditions/checkField.condition';
import { hasFieldError } from 'util/conditions/haveErrorField.conditions';
import { getNavigatorLang } from 'util/function/getNavigatorLang.function';
import Form__combined from 'components/form/combined/form__combined';
import Message__sucess from 'components/message/sucess/message__sucess';

const Cards = styled(Grid__card)`
    width: 100%;
    text-align: center;
    position: relative;
    padding: 30px;
  `;

const Container = styled.div``;

const Illu = styled.img`
    width: 70px;
  `;

const Header = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'row' })}
`;

const Title = styled.div`
  margin-left: 20px;
  font-weight: 600;
  font-size: 26px;
  text-align: left;
  text-transform: uppercase;
  color: #01B5E2;
`;

const ErrorText = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #E74C3C;
`;

const Content = styled.div`
  margin-top: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: #4D4D4D;
  margin-bottom: 25px;
`;

const Button = styled(Button__primary)`
  width: 100%;
`;


const SucessPop = styled(Grid__popup)`
  display: flex;
  justify-content:center;
  align-items:center;
`;


const LeadGenerationForm = ({
  style, title, content, buttonContent, handleRefresh, config,
}) => {
  const { user } = useContext(AppContext);
  const { getGlobal, getFieldGroup, getField } = useContext(ContentContext);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [success, setsuccess] = useState(false);
  const [errorSubscription, seterrorSubscription] = useState(false);
  const [company, setCompany] = useState('');
  const [contact, setcontact] = useState('');
  const [email, setemail] = useState('');
  const [phone, setphone] = useState({ value: '' });


  const handleCompany = (value) => {
    setCompany(value.value);
  };

  const handleContact = (value) => {
    setcontact(value.value);
  };

  const handleEmail = (value) => {
    setemail(value.value);
  };

  const handlePhone = (value) => {
    setphone(value);
  };

  const handleCloseSucess = () => {
    setsuccess(false);
  };

  const resetState = () => {
    setCompany('');
    setcontact('');
    setemail('');
    setphone('');
    seterror(false);
    seterrorSubscription(false);
  };


  const checkField = () => {
    let errorObj = {};
    let errorArray = {};

    const baseArrayField = [
      {
        key: 'email',
        type: 'email',
        value: email,
      },
      {
        key: 'company',
        type: 'simple',
        value: company,
      },
      {
        key: 'contact',
        type: 'simple',
        value: contact,
      },
      {
        key: 'phone',
        type: 'phoneLib',
        value: phone,
      },
    ];


    errorObj = createErrorObject(errorArray, baseArrayField, errorObj.hasError, getGlobal);
    errorArray = errorObj.object;
    seterror(errorArray);
    return errorObj.hasError;
  };

  const handleSubmit = async () => {
    // handleCheckVehicle(registration);
    if (!checkField()) {
      setloading(true);
      const res = await config.api.checkMail(email);
      const formated = formatApiRes(res);

      if (!formated.success) {
        seterrorSubscription(false);
        const addServices = await config.api.addFormWorkshop({
          userId: user.user.id,
          fleetCompanyName: company,
          fleetContact: contact,
          fleetEmail: email,
          fleetPhoneNumber: phone.value,
        });
        const addServicesFormated = formatApiRes(addServices);
        setloading(false);
        setsuccess(true);
        resetState();
        handleRefresh();
      } else {
        seterrorSubscription(true);
        setloading(false);
      }
    }
  };

  return (
    <Fragment>
      <Cards>
        <Container style={style}>
          <Header>
            <Illu src={t.image.illu.send} />
            <Title>{title}</Title>
          </Header>
          <Content>{content}</Content>
          <Form__textfield
            change={handleCompany}
            value={company}
            require
            label={getField({ base: config.strapiPage, field: config.content.dashboard_action_company })}
            error={hasFieldError(error, 'company')}
          />
          <Grid__separator size="25px" />
          <Form__textfield
            change={handleContact}
            value={contact}
            require
            label={getField({ base: config.strapiPage, field: config.content.dashboard_action_person })}
            error={hasFieldError(error, 'contact')}
          />
          <Grid__separator size="25px" />
          <Form__textfield
            change={handleEmail}
            value={email}
            require
            label={getField({ base: config.strapiPage, field: config.content.dashboard_action_email })}
            error={hasFieldError(error, 'email')}
          />
          <Grid__separator size="25px" />
          <Form__combined
            handleTextChange={(isValide, res, final) => handlePhone({ valide: isValide, value: final })}
            textValue={phone.value}
            property="phone"
            label={getField({ base: config.strapiPage, field: config.content.dashboard_action_phone })}
            require
            styleLabel={{ display: 'block', textAlign: 'left' }}
            error={hasFieldError(error, 'phone')}
            defaultCountry={getNavigatorLang().lang}
          />
          {
            errorSubscription && (
              <Fragment>
                <Grid__separator size="10px" />
                <ErrorText>{getField({ base: config.strapiPage, field: config.content.dashboard_action_error })}</ErrorText>
              </Fragment>
            )
          }
          <Grid__separator size="25px" />
          <Button
            withIcon
            icon={<FontAwesomeIcon icon={faArrowRight} />}
            iconEnd
            event={handleSubmit}
          >
            {buttonContent}
          </Button>
        </Container>
        {loading && <Loader__wrapper absolute />}
      </Cards>
      <SucessPop active={success} closeEvent={handleCloseSucess}>
        <Message__sucess
          icon={t.image.illu.check}
          title={getFieldGroup({ base: config.strapiPage, groupe: config.content.popup_form_success.group, field: config.content.popup_form_success.title })}
          text={getFieldGroup({ base: config.strapiPage, groupe: config.content.popup_form_success.group, field: config.content.popup_form_success.content })}
          buttonText={getFieldGroup({ base: config.strapiPage, groupe: config.content.popup_form_success.group, field: config.content.popup_form_success.button })}
          buttonEvent={handleCloseSucess}
        />
      </SucessPop>
    </Fragment>
  );
};

export default LeadGenerationForm;
