import { SERVICENAME } from 'util/service/serviceName';
import {
  faHandshakeAlt, faShieldCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { parseStatus } from 'util/conditions/statusProps.util';
import { strapiPaymentGuaranteeService, strapiRepairApprovalService } from 'util/strapi/content.starpi';

const dateComparison = (a, b) => {
  const date1 = new Date(a.date);
  const date2 = new Date(b.date);

  return date1 - date2;
};


export const formatServiceIntoArray = (data, getFieldGroup, getField) => {
  const array = [];
  if (data) {
    if (data.paymentguarantees) {
      data.paymentguarantees.forEach((paymentguarantees) => {
        array.push({
          type: SERVICENAME.paymentguarantee,
          date: paymentguarantees.createdAt,
          id: paymentguarantees.id,
          detail: paymentguarantees,
          icon: faShieldCheck,
          title: getField({ base: strapiPaymentGuaranteeService, field: 'service_title' }),
          status: {
            brut: paymentguarantees.status,
            color: parseStatus({
              status: paymentguarantees.status,
            }),
            text: getFieldGroup({
              base: strapiPaymentGuaranteeService,
              groupe: 'status',
              field: paymentguarantees.status,
            }),
          },
        });
      });
    }
    if (data.repairApprovals) {
      data.repairApprovals.forEach((repairApprovals) => {
        array.push({
          type: SERVICENAME.repairapproval,
          date: repairApprovals.createdAt,
          id: repairApprovals.id,
          detail: repairApprovals,
          icon: faHandshakeAlt,
          title: getField({ base: strapiRepairApprovalService, field: 'service_title' }),
          status: {
            brut: repairApprovals.status,
            color: parseStatus({
              status: repairApprovals.status,
            }),
            text: getFieldGroup({
              base: strapiRepairApprovalService,
              groupe: 'status',
              field: repairApprovals.status,
            }),
          },
        });
      });
    }
  }

  return array.sort(dateComparison);
};


export const canCloseCase = (serviceArray) => {
  if (serviceArray) {
    const find = serviceArray.find((service) => service.status.brut === 'waiting');
    if (find) {
      return false;
    }
  }

  return true;
};
