/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

const Container = styled.a`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  background: #e6f8fc;
  border-radius: 50%;
  border: ${(props) => (props.border ? '1px solid #01b5e2' : null)};
  transition: 0.3s ease all;
  transform-origin: center;
  color: #01b5e2;
  cursor: pointer;
  ${t.mixins.flex()};
  &:hover {
    background-color: #01b5e2;
    color: #fff;
  }
`;

const Button__buttonCircle = ({
  style, icon, href, noprevent, event, target, border,
}) => {
  const eventHandler = (e) => {
    if (!noprevent) {
      e.preventDefault();
    }
    if (event) {
      event();
    }
  };
  return (
    <Container border={border} target={target} href={href} style={style} onClick={(e) => eventHandler(e)}>
      {icon}
    </Container>
  );
};

export default Button__buttonCircle;
