const Mail__confirmRegistration = ({
  statusLabel,
  content,
  btnContent,
  btnLink,
  title,
}) => (`
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${title}</title>
  </head>
  <body>
    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; background-color: #f9f9f9;" id="bodyTable">
      <tbody>
        <tr>
          <td align="center" valign="top" style="padding-right: 10px; padding-left: 10px;" id="bodyCell">
            <table border="0" cellpadding="0" cellspacing="0" style="max-width: 600px;" width="100%" class="wrapperWebview">
              <tbody>
                <tr>
                  <td align="center" valign="top">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                      <tbody>
                        <tr>
                          <td align="center" valign="middle" style="padding-top: 40px; padding-bottom: 40px;" class="emailLogo">
                            <img src="https://alltrucks-assets.s3.eu-west-3.amazonaws.com/logo.png" alt="" width="300" border="0" style="width: 100%; max-width: 300px; height: auto; display: block;" class="" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table border="0" cellpadding="0" cellspacing="0" style="max-width: 600px;" width="100%" class="wrapperBody">
              <tbody>
                <tr>
                  <td align="center" valign="top">
                    <table border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-color: #e5e5e5; border-style: solid; border-width: 0 1px 1px 1px;" width="100%" class="tableCard">
                      <tbody>
                        <tr>
                          <td height="3" style="background-color: rgb(1, 181, 226); font-size: 1px; line-height: 3px;" class="topBorder">&nbsp;</td>
                        </tr>
                        <tr>
                          <td height="65">&nbsp;</td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" style="padding-bottom: 20px;" class="imgHero">
                            <img src="https://alltrucks-assets.s3.eu-west-3.amazonaws.com/check.png" width="150" alt="" border="0" style="width: 100%; max-width: 150px; height: auto; display: block;" />
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" style="padding-bottom: 5px; padding-left: 20px; padding-right: 20px;" class="mainTitle">
                            <h2
                              class="text"
                              style="
                                color: #000000;
                                font-family: Helvetica, Arial, sans-serif;
                                font-size: 28px;
                                font-weight: 500;
                                font-style: normal;
                                letter-spacing: normal;
                                line-height: 36px;
                                text-transform: none;
                                text-align: center;
                                padding: 0;
                                margin: 0;
                              "
                            >
                              ${title}
                            </h2>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" style="padding-bottom: 30px; padding-left: 20px; padding-right: 20px;" class="subTitle">
                            <h4
                              class="text"
                              style="
                                color: #999999;
                                font-family: Helvetica, Arial, sans-serif;
                                font-size: 16px;
                                font-weight: 500;
                                font-style: normal;
                                letter-spacing: normal;
                                line-height: 24px;
                                text-transform: none;
                                text-align: center;
                                padding: 0;
                                margin: 0;
                              "
                            >
                              ${statusLabel}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" style="padding-left: 20px; padding-right: 20px;" class="containtTable ui-sortable">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="tableDescription" style="">
                              <tbody>
                                <tr>
                                  <td align="center" valign="top" style="padding-bottom: 20px;" class="description">
                                    <p
                                      class="text"
                                      style="
                                        color: #666666;
                                        font-family: 'Open Sans', Helvetica, Arial, sans-serif;
                                        font-size: 14px;
                                        font-weight: 400;
                                        font-style: normal;
                                        letter-spacing: normal;
                                        line-height: 22px;
                                        text-transform: none;
                                        text-align: center;
                                        padding: 0;
                                        margin: 0;
                                      "
                                    >
                                      ${content}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="tableButton" style="">
                              <tbody>
                                <tr>
                                  <td align="center" valign="top" style="padding-top: 20px; padding-bottom: 20px;">
                                    <table align="center" border="0" cellpadding="0" cellspacing="0">
                                      <tbody>
                                        <tr>
                                          <td align="center" class="ctaButton" style="background-color: rgb(1, 181, 226); padding: 12px 35px; border-radius: 50px;">
                                            <a
                                              class="text"
                                              href="${btnLink}"
                                              target="_top"
                                              style="color: #ffffff; font-family: Helvetica, Arial, sans-serif; font-size: 13px; font-weight: 600; font-style: normal; line-height: 20px; text-decoration: none; display: block;"
                                            >
                                              ${btnContent}
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="middle" style="padding-bottom: 65px;" class="emailRegards"></td>
                        </tr>
                      </tbody>
                    </table>
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="space">
                      <tbody>
                        <tr>
                          <td height="30" style="font-size: 1px; line-height: 1px;">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table border="0" cellpadding="0" cellspacing="0" style="max-width: 600px;" width="100%" class="wrapperFooter">
              <tbody>
                <tr>
                  <td align="center" valign="top">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="footer">
                      <tbody>
                        <tr>
                          <td align="center" valign="top" style="padding-top: 10px; padding-bottom: 5px; padding-left: 10px; padding-right: 10px;" class="brandInfo">
                            <p
                              class="text"
                              style="
                                color: #777777;
                                font-family: 'Open Sans', Helvetica, Arial, sans-serif;
                                font-size: 12px;
                                font-weight: 400;
                                font-style: normal;
                                letter-spacing: normal;
                                line-height: 20px;
                                text-transform: none;
                                text-align: center;
                                padding: 0;
                                margin: 0;
                              "
                            >
                              Alltrucks GmbH & Co. KG | Perchtinger Strasse 6 | 81379 Munich, Allemagne
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" style="padding-top: 0px; padding-bottom: 10px; padding-left: 10px; padding-right: 10px;" class="footerEmailInfo">
                            <p
                              class="text"
                              style="
                                color: #777777;
                                font-family: 'Open Sans', Helvetica, Arial, sans-serif;
                                font-size: 12px;
                                font-weight: 400;
                                font-style: normal;
                                letter-spacing: normal;
                                line-height: 20px;
                                text-transform: none;
                                text-align: center;
                                padding: 0;
                                margin: 0;
                              "
                            >
                              <a href="mailto:support@alltrucks-fleet-platform.com" style="color: #777777; text-decoration: underline;" target="_blank">support@alltrucks-fleet-platform.com</a>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td height="30" style="font-size: 1px; line-height: 1px;">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td height="30" style="font-size: 1px; line-height: 1px;">&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
</html>
`);

export default Mail__confirmRegistration;
