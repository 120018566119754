
import React, {
  useContext, useState, useRef, useLayoutEffect, useEffect,
} from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { List__header, List__line, List__inputTable } from 'components/list/list';
import { Content__text } from 'components/content/content';


const Wrapper = styled.div`
  width: ${(props) => (props.noScroll ? '100% ' : 'calc(100% + 8px)')};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: ${(props) => (props.noScroll ? null : 'scroll')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : null)};
  &::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #01b5e2 !important;
    }
  }
`;

const Container = styled.table`
  min-width: 100%;
  border: 0;
  border-collapse: collapse;
  table-layout: fixed;
  display: table;
  border-spacing: 2px;
  max-width: 100%;
  border-color: grey;
`;

const NoResultContainer = styled.div`
  width: 100%;
  min-height: 80px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const NoResultContent = styled(Content__text)`
  text-align: center;
  width: 100%;
  color: #454545;
`;

const Fleet__list = ({
  style,
  headerCol,
  lineCol,
  noSelect,
  maxHeight,
  borderLine,
  selectedLine,
  noContent,
  withInput,
  checkButton,
  noSelectAll,
  selectOne,
  lineEvent,
  addVehicule,
  noResultText,
  className,
  square,
  isCheck,
  noScroll,
  scrollEndEvent,
  emptyComponent,
}) => {
  const { getGlobal } = useContext(ContentContext);
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const tableContainer = useRef(null);
  const headerRef = useRef(null);
  const tableInner = useRef(null);
  let prevScroll = 0;

  const renderArray = (array) => {
    const toReturn = [];
    const keys = Object.keys(array);
    keys.forEach((element) => {
      toReturn.push({ key: element, value: array[element] });
    });
    return toReturn.filter((check) => check.value === true);
  };

  const handleAllCheck = (value) => {
    const ObjectCreate = {};
    let array = [];
    if (value === true) {
      lineCol.forEach((element, key) => {
        ObjectCreate[key] = true;
      });
    }

    array = renderArray(ObjectCreate);

    setCheckBoxValue(array);
    if (selectedLine) {
      selectedLine(array);
    }
  };

  const handleSingleCheck = ({ array }) => {
    setCheckBoxValue(array);
    if (selectedLine) {
      selectedLine(array);
    }
  };

  const setHeader = () => {
    if (tableContainer && tableContainer.current) {
      const head = tableContainer.current.querySelectorAll('thead');
      if (head && head[0]) {
        const cells_head = head[0].querySelectorAll('th');
        const cells_head_model = head[1].querySelectorAll('th');
        cells_head.forEach((element, key) => {
          element.style.width = `${cells_head_model[key].getBoundingClientRect().width}px`;
        });

        head[0].style.position = 'absolute';
        head[0].style.marginTop = `-${head[1].getBoundingClientRect().height}px`;
      }
    }
  };

  const handleScroll = () => {
    // setTimeout(() => {
    if (tableContainer && tableContainer.current) {
      setHeader();

      window.addEventListener('resize', () => {
        setHeader();
      });
    }
    // }, 100);
    setTimeout(() => {
      if (tableContainer && tableContainer.current) {
        setHeader();
      }
    }, 150);
    setTimeout(() => {
      if (tableContainer && tableContainer.current) {
        setHeader();
      }
    }, 300);
  };


  const scrollEvent = () => {
    if (scrollEndEvent) {
      const tableEl = tableContainer.current;
      const tableInnerEl = tableInner.current;
      if (prevScroll !== tableEl.scrollTop) {
        prevScroll += tableEl.scrollTop;
        if (tableEl.scrollTop >= tableInnerEl.offsetHeight - tableEl.offsetHeight - 10) {
          scrollEndEvent();
          tableEl.removeEventListener('scroll', scrollEvent, true);
        }
      }
    }
  };
  useLayoutEffect(() => {
    if (tableContainer) {
      handleScroll();
    }
  }, [tableContainer, tableInner]);

  useEffect(() => {
    if (tableContainer && tableContainer.current && tableInner && tableInner.current && lineCol && lineCol.length > 0) {
      const tableEl = tableContainer.current;
      tableEl.addEventListener('scroll', scrollEvent, true);
    }
    return () => {
      const tableEl = tableContainer.current;
      tableEl.removeEventListener('scroll', scrollEvent, true);
    };
  }, [lineCol]);

  return (
    <Wrapper
      ref={tableContainer}
      className={className}
      style={style}
      maxHeight={maxHeight}
      noScroll={noScroll}
    >
      <Container ref={tableInner}>
        <List__header
          noSelect={noSelect}
          noSelectAll={noSelectAll}
          col={headerCol}
          handleAllCheck={(value) => handleAllCheck(value)}
          headerRef={headerRef}
          initial={isCheck}
        />
        <List__header
          noSelect={noSelect}
          noSelectAll={noSelectAll}
          col={headerCol}
          handleAllCheck={(value) => handleAllCheck(value)}
          headerRef={headerRef}
          initial={isCheck}
        />
        {!withInput && !addVehicule ? (
          !noContent ? (
            lineCol !== null ? (
              lineCol.length > 0 ? (
                <List__line
                  borderLine={borderLine}
                  noSelect={noSelect}
                  selectOne={selectOne}
                  col={lineCol}
                  allCheck={checkBoxValue.length === 0 ? 'removeAll' : checkBoxValue.length === lineCol.length ? 'all' : 'basic'}
                  handleSingleCheck={(value) => handleSingleCheck(value)}
                  lineEvent={lineEvent}
                  square={square}
                  isCheck={isCheck || false}
                />
              ) : null
            ) : null
          ) : null
        ) : (
          <List__inputTable
            col={headerCol}
            checkButton={(value) => (checkButton ? checkButton(value) : {})}
          />
        )}
      </Container>
      {!noContent ? (
        lineCol === null || lineCol.length <= 0 ? (
          <NoResultContainer>
            <NoResultContent>
              {emptyComponent ? (
                emptyComponent
              ) : noResultText || getGlobal({ option: 'globalcontent', field: 'table_no_result' })}
            </NoResultContent>
          </NoResultContainer>
        ) : null
      ) : null}
    </Wrapper>
  );
};

export default Fleet__list;
