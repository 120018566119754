
/**
 * Import Lib & assets
 */

import { useEffect } from 'react';


const Transition__loader_controller = ({ active }) => {
  const activeAnimation = () => {
    if (active === true) {
      const wrapper = document.querySelector('.transition__loader');

      wrapper.classList.add('active');
    } else if (active === false) {
      const wrapper = document.querySelector('.transition__loader');

      wrapper.classList.remove('active');
    }
  };

  useEffect(() => {
    activeAnimation();
  }, [active]);

  return null;
};

export default Transition__loader_controller;
