
import React, {
  useContext, Fragment, useState, useEffect,
} from 'react';
import styled from 'styled-components';
import t from 'theme';

import { Grid__separator, Grid__popup } from 'components/grid/grid';
import {
  SingleService__banner as Banner,
  SingleService__intro as Intro,
} from 'components/singleService/singleService';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import Cards__focusCard from 'components/cards/focusCard/cards__focusCard';
import { Button__primary } from 'components/button/button';
import Content__titleLight from 'components/content/titleLight/content__titleLight';
import { ContentContext } from 'context/contentContext';
import marked from 'marked';
import { strapiSmartMaitenanceService } from '../../../../util/strapi/content.starpi';


const Container = styled.div``;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% + 130px);
  margin-left: -65px;
  padding-left: 65px;
  padding-right: 65px;
  padding-top: 65px;
  padding-bottom: 65px;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : props.column ? 'column' : 'row')};
  background: ${(props) => (props.contrast ? '#F9F9F9' : '#fff')};
`;

const Pricing = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(65%);
  margin: 0 auto;
  padding-top: 65px;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : props.column ? 'column' : 'row')};
`;

const Content = styled.div`
  max-width: 540px;
`;

const Txt = styled.div`
  text-align: left; 
  font-size: 14px;
  line-height: 1.5;
  color: #4D4D4D;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : null)};
  margin: 0 auto;
  & p {
    margin-top: 10px;
  }
`;

const Picture = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 270px;
  width: ${(props) => (props.small ? '90px' : '270px')};;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 25px;
  line-height:  1.5;
  color: #4D4D4D;
  margin-bottom: 20px;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

const SubTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height:  1.5;
  color: #4D4D4D;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const Item = styled.div`
  width: 250px;
  text-align: center;
`;

const Module = styled.div`
  display: flex;
`;

const Focus__content = styled.div`
  font-size: 14px;
  line-height: 21px;
  margin-top: 15px;
  max-width: 500px;
  line-height: 1.5;
  & p {
    margin-bottom: 10px;
  }
  & h2 {
    font-weight: 600;
    font-size: 16px;
    line-height:  1.5;
    color: #4D4D4D;
    margin-top: 20px;
    margin-bottom: 15px;
  }
`;

const Table = styled.table`
  margin-top: 45px;
  border-collapse:collapse;
  border-spacing:0;
  td {
    border-style:solid;
    border-width:1px;
    color:#4D4D4D;
    border-color:#e3e3e3;
    font-size:16px;
    overflow:hidden;
    padding:13px;
    word-break:normal;
    vertical-align:middle
  }
  td:nth-child(2) {
    text-align: center;
  }
  th {
    font-weight: 600;
    border-color:#e3e3e3;
    background-color:#e3e3e3;
    border-style:solid;
    border-width:1px;
    color:#4D4D4D;
    font-size:18px;
    overflow:hidden;
    padding: 13px;
    word-break:normal;
    text-align:center;
    vertical-align:middle
  }
`;


const AddServices__singleAddService = ({
  style,
  popupActive,
  closeEvent,
  handleSave,
  alreadyHave,
  loading,
  workshop,
  clickSubscribe,
  infoOnly,
}) => {
  const { getField, getFieldGroup } = useContext(ContentContext);
  const handleClickInfo = () => {
    handleSave();
  };

  const handleClickSubscribe = () => {
    if (clickSubscribe) {
      clickSubscribe();
    }
  };

  const renderMarked = (value) => {
    if (value && value !== '') {
      return <div dangerouslySetInnerHTML={{ __html: marked(value) }} />;
    }
    return null;
  };

  return (
    <Container style={style}>
      <Grid__popup active={popupActive} closeEvent={() => closeEvent()}>
        <Banner imageUrl={t.image.service.smart_banner} />
        <Intro
          title={getField({ base: strapiSmartMaitenanceService, field: 'service_title' })}
          txt={getField({ base: strapiSmartMaitenanceService, field: 'service_description' })}
          brandLabel={t.image.service.smart_logo}
        />
        <Grid__separator size="65px" />
        <Row contrast>
          <Picture>
            <Img src={t.image.service.smart_what} />
          </Picture>
          <Content>
            <Title>
              {getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_1_title' })}
            </Title>
            <Txt>
              {renderMarked(getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_1_content' }))}
            </Txt>
          </Content>
        </Row>
        <Row reverse>
          <Picture>
            <Img src={t.image.service.smart_why} />
          </Picture>
          <Content>
            <Title>
              {getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_2_title' })}
            </Title>
            <Txt>
              {renderMarked(getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_2_content' }))}
            </Txt>
          </Content>
        </Row>
        <Row contrast>
          <Item>
            <Img small src={t.image.service.smart_1} />
            <SubTitle>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_3_1_title' })}</SubTitle>
            <Txt>
              {renderMarked(getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_3_1_content' }))}
            </Txt>
          </Item>
          <Item>
            <Img small src={t.image.service.smart_2} />
            <SubTitle>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_3_2_title' })}</SubTitle>
            <Txt>
              {renderMarked(getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_3_2_content' }))}
            </Txt>
          </Item>
          <Item>
            <Img small src={t.image.service.smart_3} />
            <SubTitle>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_3_3_title' })}</SubTitle>
            <Txt>
              {renderMarked(getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_3_3_content' }))}
            </Txt>
          </Item>
        </Row>
        <Row column>
          <Content style={{ margin: '0 auto' }}>
            <Title style={{ textAlign: 'center' }}>
              {getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_4_title' })}
            </Title>
            <Txt>
              {renderMarked(getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_4_content' }))}
            </Txt>
          </Content>
        </Row>
        <Row column contrast>
          <Module>
            <Picture>
              <Img src={t.image.service.smart_mod_1} />
            </Picture>
            <Content>
              <SubTitle>
                {getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_5_1_title' })}
              </SubTitle>
              <Txt>
                {renderMarked(getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_5_1_content' }))}
              </Txt>
            </Content>
          </Module>
          <Grid__separator size="80px" />
          <Module>
            <Picture>
              <Img src={t.image.service.smart_mod_2} />
            </Picture>
            <Content>
              <SubTitle>
                {getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_5_2_title' })}
              </SubTitle>
              <Txt>
                {renderMarked(getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_5_2_content' }))}
              </Txt>
            </Content>
          </Module>
          <Grid__separator size="80px" />
          <Module>
            <Picture>
              <Img src={t.image.service.smart_mod_3} />
            </Picture>
            <Content>
              <SubTitle>
                {getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_5_3_title' })}
              </SubTitle>
              <Txt>
                {renderMarked(getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_presentation', field: 'section_5_3_content' }))}
              </Txt>
            </Content>
          </Module>
        </Row>
        {!infoOnly && (
          <>
            { !workshop && (
              <Pricing column>
                <Title>
                  {getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'title' })}
                </Title>
                <Txt>
                  {getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'description' })}
                </Txt>
                <Table>
                  <thead>
                    <tr>
                      <th>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'label_1' })}</th>
                      <th>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'label_2' })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'column_1_1' })}</td>
                      <td>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'column_2_1' })}</td>
                    </tr>
                    <tr>
                      <td>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'column_1_2' })}</td>
                      <td rowSpan="2">{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'column_2_2' })}</td>
                    </tr>
                    <tr><td>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'column_1_3' })}</td></tr>
                    <tr>
                      <td>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'column_1_4' })}</td>
                      <td rowSpan="2">{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'column_2_3' })}</td>
                    </tr>
                    <tr><td>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'column_1_5' })}</td></tr>
                    <tr>
                      <td>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'column_1_6' })}</td>
                      <td>{getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'service_pricing', field: 'column_2_4' })}</td>
                    </tr>
                  </tbody>
                </Table>
              </Pricing>
            )}
            <Grid__separator size="65px" />
            { !workshop ? (
              !alreadyHave ? (
                <Cards__focusCard>
                  <Content__titleLight content={getField({ base: strapiSmartMaitenanceService, field: 'blue_card_fleet_title' })} />
                  <Grid__separator size="15px" />
                  <Focus__content>{renderMarked(getField({ base: strapiSmartMaitenanceService, field: 'blue_card_fleet_content' }))}</Focus__content>
                  <Grid__separator size="35px" />
                  <Button__primary event={handleClickInfo}>{getField({ base: strapiSmartMaitenanceService, field: 'blue_card_fleet_button' })}</Button__primary>
                </Cards__focusCard>
              ) : (
                <Cards__focusCard>
                  <Content__titleLight content={getField({ base: strapiSmartMaitenanceService, field: 'blue_card_fleet_already_sent' })} />
                </Cards__focusCard>
              )
            ) : (
              <Cards__focusCard>
                <Content__titleLight content={getField({ base: strapiSmartMaitenanceService, field: 'blue_card_workshop_title' })} />
                <Grid__separator size="15px" />
                <Focus__content>
                  {renderMarked(getField({ base: strapiSmartMaitenanceService, field: 'blue_card_workshop_content' }))}
                </Focus__content>
                <Grid__separator size="35px" />
                <Button__primary event={handleClickSubscribe}>{getField({ base: strapiSmartMaitenanceService, field: 'blue_card_workshop_button' })}</Button__primary>
              </Cards__focusCard>
            ) }
          </>
        )}
        {loading ? <Loader__wrapper /> : false}
      </Grid__popup>
    </Container>
  );
};

export default AddServices__singleAddService;
