
/**
   * Import Lib & assets
   */

import React, { Fragment } from 'react';
import styled from 'styled-components';
import t from 'theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

const Cercle = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  line-height: 23px;
  text-align: center;
  border: 2px solid #4D4D4D;
  box-sizing: border-box;
  position: relative;
  transition: all ease 0.5s;
`;

const CercleContent = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: #4D4D4D;
  position: absolute;
  left: 7px;
  top: 0px;
  transition: all ease 0.5s;
`;

const Content = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #4D4D4D;
  margin-top: 7px;
  transition: all ease 0.5s;
`;

const Line = styled.div`
  height: 2px;
  width: 165px;
  background-color: #CACACA;
  margin-top: 11px;
  margin-right: -70px;
  margin-left: -70px;
  transition: all ease 0.5s;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 15px;
  color: #FFFFFF;
  position: absolute;
  z-index: 5;
  left: -3px;
  top: 4px;
  opacity: 0;
  transition: all ease 0.5s;
`;

const Item = styled.div`
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
  width: 200px;
`;

const Container = styled.div`
  ${t.mixins.flex({ justify: 'center', direction: 'row', align: 'flex-start' })}
`;

const Global__stepsIndicator = ({
  style, step1Name, step2Name, step3Name, step4Name, currentStep,
}) => (
  <Container style={style} currentStep={currentStep}>
    <Item>
      <Cercle style={{
        borderColor: currentStep ? '#01B5E2' : '#4d4d4d',
        background: currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#fff',
      }}
      >
        <CercleContent
          style={{
            color: currentStep ? '#01B5E2' : '#4d4d4d',
          }}
        >
          1
          <Icon
            icon={faCheck}
            style={{
              opacity: currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 ? 1 : 0,
            }}
          />
        </CercleContent>
      </Cercle>
      <Content
        style={{
          color: currentStep ? '#01B5E2' : '#4d4d4d',
        }}
      >
        {step1Name}
      </Content>
    </Item>
    <Line
      style={{
        background: currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#cacaca',
      }}
    />
    <Item>
      <Cercle
        style={{
          borderColor: currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#4d4d4d',
          background: currentStep === 3 || currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#fff',
        }}
      >
        <CercleContent
          style={{
            color: currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#4d4d4d',
          }}
        >
          2
          <Icon
            icon={faCheck}
            style={{
              opacity: currentStep === 3 || currentStep === 4 || currentStep === 5 ? 1 : 0,
            }}
          />
        </CercleContent>
      </Cercle>
      <Content
        style={{
          color: currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#4d4d4d',
        }}
      >
        {step2Name}
      </Content>
    </Item>
    { step3Name ? (
      <Fragment>
        <Line
          style={{
            background: currentStep === 3 || currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#cacaca',
          }}
        />
        <Item>
          <Cercle
            style={{
              borderColor: currentStep === 3 || currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#4d4d4d',
              background: currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#fff',
            }}
          >
            <CercleContent
              style={{
                color: currentStep === 3 || currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#4d4d4d',
              }}
            >
              <Icon
                icon={faCheck}
                style={{
                  opacity: currentStep === 4 || currentStep === 5 ? 1 : 0,
                }}
              />
              3
            </CercleContent>
          </Cercle>
          <Content
            style={{
              color: currentStep === 3 || currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#4d4d4d',
            }}
          >
            {step3Name}
          </Content>
        </Item>
      </Fragment>
    ) : null }
    { step4Name ? (
      <Fragment>
        <Line
          style={{
            background: currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#cacaca',
          }}
        />
        <Item>
          <Cercle
            style={{
              borderColor: currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#4d4d4d',
              background: currentStep === 5 ? '#01B5E2' : '#fff',
            }}
          >
            <CercleContent
              style={{
                color: currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#4d4d4d',
              }}
            >
              <Icon
                icon={faCheck}
                style={{
                  opacity: currentStep === 5 ? 1 : 0,
                }}
              />
              4
            </CercleContent>
          </Cercle>
          <Content
            style={{
              color: currentStep === 4 || currentStep === 5 ? '#01B5E2' : '#4d4d4d',
            }}
          >
            {step4Name}
          </Content>
        </Item>
      </Fragment>
    ) : null }
  </Container>
);

export default Global__stepsIndicator;
