import t from 'theme';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { faDesktopAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContentContext } from 'context/contentContext';
import Grid__separator from 'components/grid/separator/grid__separator';


const Container = styled.div`
  display: none;
  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999999;
    background-color: #001540;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 30px;
  color: #FFFFFF;
`;

const SubTitle = styled.div`
  font-weight: normal;
  font-size: 18px;
  color: #FFFFFF;
`;

const Content = styled.div`
  text-align: center;
`;

const Icon = styled(FontAwesomeIcon)`
  font-weight: 300;
  font-size: 135px;
  color: #ffffff;
`;

const Logo = styled.img`
  width: 150px;
  position: absolute;
  top: 20px;
  left: 20px;
`;

const Global__smallScreen = ({ style }) => {
  const { getGlobal } = useContext(ContentContext);
  return (
    <Container style={style}>
      <Logo src={t.image.global.logo} />
      <Content>
        <Icon icon={faDesktopAlt} />
        <Grid__separator size="30px" />
        <Title>{getGlobal({ option: 'globalcontent', field: 'small_screen_title' })}</Title>
        <Grid__separator size="25px" />
        <SubTitle>{getGlobal({ option: 'globalcontent', field: 'small_screen_subTitle' })}</SubTitle>
        <Logo src={t.image.global.logo} />
      </Content>
    </Container>
  );
};
export default Global__smallScreen;
