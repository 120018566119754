import React from 'react';
import styled from 'styled-components';

const Card = styled.a`
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 99999999;
  display: block;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: #fff;
  border: 1px solid #764abc;
  font-weight: 500;
  font-size: 12px;
  color: #764abc;
  transition: .3s ease all;
  cursor: pointer;
  &:hover {
    background: #764abc;
    color: #fff;
  }
`;

const Cards__logRocket = ({ style, url }) => {
  const successCopy = () => {
    // eslint-disable-next-line no-alert
    alert('The session url was succefuly copy to your clipboard');
  };

  const fallbackCopyTextToClipboard = (text, context) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.className = 'hidden';
    if (context) {
      context.appendChild(textArea);
    }
    textArea.focus();
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      const oldContentEditable = textArea.contentEditable;
      const oldReadOnly = textArea.readOnly;
      const range = document.createRange();

      textArea.contenteditable = true;
      textArea.readonly = false;
      range.selectNodeContents(textArea);
      const s = window.getSelection();
      s.removeAllRanges();
      s.addRange(range);
      textArea.setSelectionRange(0, 999999);
      textArea.contentEditable = oldContentEditable;
      textArea.readOnly = oldReadOnly;
    } else {
      textArea.select();
    }

    try {
      successCopy();
    } catch (err) {
      console.error(err);
    }

    context.removeChild(textArea);
  };

  const copyTextToClipboard = (text, context) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text, context);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      successCopy();
    });
  };

  const handleClick = (e) => {
    if (e) {
      e.preventDefault();
      copyTextToClipboard(url, document.querySelector('body'));
    }
  };

  return (
    <Card style={style} onClick={handleClick} padding="25px">
      copy session ID
    </Card>
  );
};

export default Cards__logRocket;
