
/**
   * Import Lib & assets
   */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';
import { Grid__card } from 'components/grid/grid/';

const Container = styled(Grid__card)`
  width: calc(50% - 15px);
  ${t.mixins.flex({ justify: 'center', align: 'center', direction: 'column' })}
`;

const Value = styled.div`
  font-weight: normal;
  font-size: 35px;
  color: #4D4D4D;
`;

const Label = styled.div`
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #808080;
  margin-top: 6px;
  line-height: 1.3;
`;

const Cards__counterSimple = ({ style, value, label }) => (
  <Container style={style}>
    <Value>{value}</Value>
    <Label>{label}</Label>
  </Container>
);

export default Cards__counterSimple;
