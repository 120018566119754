
/**
   * Import Lib & assets
   */

import React, { Fragment, useContext } from 'react';
import Form__checkCondition from 'components/form/checkCondition/form__checkCondition';
import Cards__focusCard from 'components/cards/focusCard/cards__focusCard';
import { Grid__separator } from 'components/grid/grid';
import Content__titleLight from 'components/content/titleLight/content__titleLight';
import Content__totalPrice from 'components/content/totalPrice/content__totalPrice';
import Button__primary from 'components/button/primary/button__primary';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import {
  SingleService__totalVehicle as TotalVehicle,
} from 'components/singleService/singleService';


const ErrorText = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #E74C3C;
  margin-bottom: 25px;
`;

const Card = styled(Cards__focusCard)`
  ${(props) => (props.onlyButton ? 'background: transparent;' : null)};
  ${(props) => (props.onlyButton ? 'padding-bottom: 0;' : null)};
  ${({ disable }) => disable && `
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  `};
`;

const Form__totalCount = ({
  style,
  title,
  price,
  currency,
  labelPrice,
  numberVehicle,
  numberLabel,
  buttonText,
  handleSave,
  handleCgv,
  cgv,
  formError,
  cgvPath,
  onlyButton,
  disable,
  customCGV,
}) => {
  const { getGlobal } = useContext(ContentContext);
  return (
    <Card onlyButton={onlyButton} style={style} disable={disable}>
      {
        !onlyButton && (
          <Fragment>
            {title && (
              <Fragment>
                <Content__titleLight content={title} />
                <Grid__separator size="35px" />
                <Content__totalPrice value={price} currency={currency} label={labelPrice} />
                <TotalVehicle number={numberVehicle} label={numberLabel} />
              </Fragment>
            )}
            {(cgv || handleCgv) && (
              <Fragment>
                <Form__checkCondition
                  property="cgv"
                  callback={handleCgv && (handleCgv)}
                  value={cgv}
                  content={
                    customCGV ? (
                      <span>{customCGV}</span>
                    ) : (
                      <span>
                        {getGlobal({ option: 'error', field: 'service_terms_1' })}
                        {' '}
                        <a target="_blank" rel="noopener noreferrer" href={cgvPath}>{getGlobal({ option: 'error', field: 'service_terms_2' })}</a>
                      </span>
                    )
                  }
                />
                <Grid__separator size="25px" />
              </Fragment>
            )}
            {(formError && formError.active) && (
              <ErrorText>
                {
                  formError.message === 'vehicle' ? getGlobal({ option: 'error', field: 'no_vehicle_error' }) : formError.message === 'cgv' ? getGlobal({ option: 'error', field: 'accept_terms_error' }) : null
                }
              </ErrorText>
            )}
          </Fragment>
        )
      }
      <Button__primary event={handleSave}>{buttonText}</Button__primary>
    </Card>
  );
};

export default Form__totalCount;
