import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import Content__tag from 'components/content/tag/content__tag';
import { List__table, List__content } from 'components/list/list';
import { Grid__separator, Grid__card } from 'components/grid/grid';
import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';
import { ContentContext } from 'context/contentContext';
import Cards__navTab from 'components/cards/navTab/cards__navTab';
import Content__counterInfo from 'components/content/counterInfo/content__counterInfo';
import moment from 'moment';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatApiRes, formatApiCountRes } from 'util/function/formatApiRes.function';
import { sequelizeToMoment, sequelizeCustomDate } from 'util/date.util';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { AppContext } from 'context/appContext';
import { useHistory, useLocation } from 'react-router-dom';
import useCalendar from '../../../util/Hooks/Calendar';
import Button__primary from '../../../components/button/primary/button__primary';
import Appointment__formPop from '../formPop/appointment__formPop';
import { findScheduleAppointment, listEvent } from '../../../helpers/api/routes/event';
import Appointment__Single from '../Single/appointment__Single';
import { APPOINTMENT_STATUS } from '../../../util/constant/Appointment_status';
import { createDateTime } from '../../../util/function/createDateTime';
import { parseAppointmentStatus } from '../../../util/conditions/statusProps.util';

const Content__container = styled.div`
  ${t.mixins.flex({
    justify: 'flex-start',
    align: 'flex-start',
  })}
  width: 100%;
  margin-top: 40px;
`;
const Icon = styled(FontAwesomeIcon)`
  font-size: 28px;
  color: #313232;
  margin-right: 15px;
`;
const Table__container = styled(Grid__card)`
  width: 100%;
  height: calc(100vh - 150px);
  min-height: 500px;
  text-align: center;
`;
const Container = styled.div`
  margin-top: 60px;
  height: calc(100% - 135px);
`;
const Title__container = styled.div`
  display: flex;
  align-items: center;
`;
const Table = styled(List__table)`
  margin-top: 60px;
  height: calc(100% - 135px);
`;

let pageNumber = 1;
const Appointment__calendar = ({
  setActiveTab,
  activeTab,
  isFleet,
  isWorkshop,
  basePage,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [activeLaunchPop, setactiveLaunchPop] = useState(false);
  const [count, setcount] = useState(0);
  const [actionCount, setactionCount] = useState(0);
  const [page, setpage] = useState(1);
  const [activeSinglePop, setActiveSinglePop] = useState({
    active: false,
    id: undefined,
  });
  const [events, setEvents] = useState([]);
  const [range, setRange] = useState(undefined);
  const { getField, getFieldGroup } = useContext(ContentContext);
  const navItems = [
    {
      label: getField({ base: basePage, field: 'page_nav_calendar' }),
      active: activeTab === 0,
      id: 0,
    },
    {
      label: getField({ base: basePage, field: 'page_nav_list' }),
      active: activeTab === 1,
      id: 1,
    },
  ];
  const headerCol = [
    {
      label: getField({ base: basePage, field: 'table_field_date' }),
    },
    {
      label: getField({ base: basePage, field: 'table_field_reason' }),
    },
    {
      label: getField({ base: basePage, field: 'table_field_workshop' }),
    },
    {
      label: getField({ base: basePage, field: 'table_field_vehicle' }),
    },
    {
      label: getField({ base: basePage, field: 'table_field_status' }),
    },
  ];
  const statusLabel = {
    refused: getFieldGroup({ base: basePage, groupe: 'appointment_status', field: 'error' }),
    done: getFieldGroup({ base: basePage, groupe: 'appointment_status', field: 'done' }),
    approved: getFieldGroup({ base: basePage, groupe: 'appointment_status', field: 'action' }),
    waiting: getFieldGroup({ base: basePage, groupe: 'appointment_status', field: 'waiting' }),
    reschedule: getField({ base: basePage, field: 'status_waiting_fleet_validation' }),
    cancel: getField({ base: basePage, field: 'status_canceled_by_fleet' }),
  };
  const appointmentType = {
    maintenance: getField({ base: basePage, field: 'repair_type_maintenance' }),
    repair: getField({ base: basePage, field: 'repair_type_repair' }),
    urgentrepair: getField({ base: basePage, field: 'repair_type_repair_urgent' }),
  };

  const renderList = () => {
    const arrayOfLine = [];

    if (events && events.length > 0) {
      events.forEach((event) => {
        arrayOfLine.push([
          {
            component: <List__content uppercase>{createDateTime(moment(sequelizeToMoment(event.date)), event.time).format('DD/MM/YYYY - HH:mm')}</List__content>,
            type: 'node',
          },
          {
            component: (
              <List__content>{appointmentType[event.type]}</List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <List__content>
                {event.UserWorkshop.UserWorkshopAlias.companyName }
              </List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <List__content>{event.Fleet.registrationNumber}</List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <Content__tag
                content={statusLabel[event.status]}
                {...parseAppointmentStatus({
                  icon: true,
                  status: event.status,
                })}
              />
            ),
            type: 'node',
          },
        ]);
      });
    }

    return arrayOfLine;
  };

  const renderEventContent = (eventInfo) => {
    const eventData = eventInfo.event.extendedProps.data;
    return eventData.name;
  };


  const handleEventClick = (clickInfo) => {
    if (clickInfo.event.start && clickInfo.event.end) {
      const data = clickInfo.event.toPlainObject({
        collapseExtendedProps: true,
      });
      if (data.id) {
        setActiveSinglePop({
          id: data.id,
          active: true,
        });
      }
    }
  };

  const getBackgroudColor = (status) => {
    if (status === APPOINTMENT_STATUS.done) {
      return '#2ECC71';
    }
    if (status === APPOINTMENT_STATUS.waiting || status === APPOINTMENT_STATUS.reschedule) {
      return '#FFAA29';
    }

    if (status === APPOINTMENT_STATUS.refused || status === APPOINTMENT_STATUS.cancel) {
      return '#E74C3C';
    }

    if (status === APPOINTMENT_STATUS.approved) {
      return '#01B5E2';
    }
  };

  const getEventClassName = (status) => {
    if (status === APPOINTMENT_STATUS.done) {
      return 'event-done';
    }
    if (status === APPOINTMENT_STATUS.waiting || status === APPOINTMENT_STATUS.reschedule) {
      return 'event-waiting';
    }

    if (status === APPOINTMENT_STATUS.refused || status === APPOINTMENT_STATUS.cancel) {
      return 'event-refused';
    }

    if (status === APPOINTMENT_STATUS.approved) {
      return 'event-approved';
    }
  };

  const getHoursSlots = () => {
    if (user.user.UserWorkshop) {
      const wrk = user.user.UserWorkshop;
      let start = '7:00';
      let end = '19:00';
      const arrayStart = [
        wrk.sundayAmStart || '7:00',
        wrk.mondayAmStart || '7:00',
        wrk.tuesdayAmStart || '7:00',
        wrk.wednesdayAmStart || '7:00',
        wrk.thursdayAmStart || '7:00',
        wrk.fridayAmStart || '7:00',
        wrk.saturdayAmStart || '7:00',
        wrk.sundayPmStart || '7:00',
        wrk.mondayPmStart || '7:00',
        wrk.tuesdayPmStart || '7:00',
        wrk.wednesdayPmStart || '7:00',
        wrk.thursdayPmStart || '7:00',
        wrk.fridayPmStart || '7:00',
        wrk.saturdayPmStart || '7:00',
      ];

      const arrayEnd = [
        wrk.sundayAmEnd || '19:00',
        wrk.sundayPmEnd || '19:00',
        wrk.mondayAmEnd || '19:00',
        wrk.mondayPmEnd || '19:00',
        wrk.tuesdayAmEnd || '19:00',
        wrk.tuesdayPmEnd || '19:00',
        wrk.wednesdayAmEnd || '19:00',
        wrk.wednesdayPmEnd || '19:00',
        wrk.thursdayAmEnd || '19:00',
        wrk.thursdayPmEnd || '19:00',
        wrk.fridayAmEnd || '19:00',
        wrk.fridayPmEnd || '19:00',
        wrk.saturdayAmEnd || '19:00',
        wrk.saturdayPmEnd || '19:00',
      ];

      arrayStart.forEach((startItem) => {
        const itemHour = startItem.split(':')[0] || 7;
        const itemMinutes = startItem.split(':')[1] || 0;
        const currentHour = start.split(':')[0] || 7;
        const currentMinutes = start.split(':')[1] || 0;

        if (itemHour <= currentHour && itemMinutes <= currentMinutes) {
          start = startItem;
        }
      });

      arrayEnd.forEach((endItem) => {
        const itemHour = endItem.split(':')[0] || 19;
        const itemMinutes = endItem.split(':')[1] || 0;
        const currentHour = end.split(':')[0] || 19;
        const currentMinutes = end.split(':')[1] || 0;

        if (itemHour >= currentHour && itemMinutes >= currentMinutes) {
          end = endItem;
        }
      });

      return {
        slotMinTime: start,
        slotMaxTime: end,
      };
    }

    return {
      slotMinTime: '7:00',
      slotMaxTime: '19:00',
    };
  };

  const [
    _1,
    calendar,
    _2,
    calendarRange,
  ] = useCalendar({
    eventContent: renderEventContent,
    slotDuration: { minutes: 30 },
    select: () => null,
    selectable: false,
    selectOverlap: false,
    selectConstraint: {
      start: new Date(),
    },
    eventClick: handleEventClick,
    events: events.map((event) => ({
      id: event.id,
      start: createDateTime(moment(sequelizeToMoment(event.date)), event.time).toDate(),
      end: createDateTime(moment(sequelizeToMoment(event.date)), event.time).add('30', 'minute').toDate(),
      data: {
        name: `${event.time} - ${event.Fleet.registrationNumber}`,
      },
      backgroundColor: getBackgroudColor(event.status),
      borderColor: getBackgroudColor(event.status),
      className: getEventClassName(event.status),
    })),
    editable: false,
    ...getHoursSlots(),
  });

  const findSchedule = async () => {
    const schedule = await findScheduleAppointment();
    if (schedule) {
      setactionCount(formatApiCountRes(schedule).count || 0);
    }
  };

  const fetchEventsOnAdd = async () => {
    setLoading(true);
    const res = await listEvent({
      limit: 300,
      page: 1,
      start: calendarRange.date.start.toISOString(),
      end: calendarRange.date.end.toISOString(),
    });

    const formated = formatApiCountRes(res);
    if (res) {
      if (formated.success && formated.data) {
        setLoading(false);
        setEvents(formated.data);
        findSchedule();
        setRange(calendarRange.date.start.toISOString());
      }
    }
  };

  const fetchEvents = async () => {
    if (calendarRange.date.start.toISOString() !== range) {
      setRange(calendarRange.date.start.toISOString());
      setLoading(true);
      const res = await listEvent({
        limit: 300,
        page: 1,
        start: calendarRange.date.start.toISOString(),
        end: calendarRange.date.end.toISOString(),
      });

      const formated = formatApiCountRes(res);
      if (res) {
        if (formated.success && formated.data) {
          setLoading(false);
          setEvents(formated.data);
          findSchedule();
        }
      }
    }
  };


  const handleTabChange = (id) => {
    setActiveTab(id);
  };
  const openPopup = () => {
    setactiveLaunchPop(true);
  };

  const closePopup = () => {
    setactiveLaunchPop(false);
  };

  const getDataAsync = async (pagination, number) => {
    setLoading(true);
    const res = await listEvent({
      page: pagination ? number : 1,
      limit: 20,
    });

    setcount(formatApiCountRes(res).count);

    if (pagination) {
      let tempData = events;
      const newData = formatApiCountRes(res);
      tempData = tempData.concat(newData.data);
      setEvents(tempData);
      findSchedule();
      setLoading(false);
    } else {
      const resData = formatApiCountRes(res);
      setLoading(false);
      setEvents(resData.data);
      findSchedule();
    }
  };

  const handleRefresh = () => {
    if (activeTab === 0) {
      fetchEventsOnAdd();
    } else {
      getDataAsync(false);
    }
  };

  const closeAndAdd = () => {
    handleRefresh();
    setactiveLaunchPop(false);
  };

  const closeSinglePop = () => {
    setActiveSinglePop({
      id: undefined,
      active: false,
    });
  };


  const handleLineClick = (event) => {
    if (events[event.key]) {
      setActiveSinglePop({
        id: events[event.key].id,
        active: true,
      });
    }
  };

  useEffect(() => {
    if (calendarRange && activeTab === 0) {
      fetchEvents();
    }
  }, [calendarRange]);



  const handleScrollEndEvent = () => {
    if (page * 20 < count) {
      pageNumber += 1;
      setpage(pageNumber);
      getDataAsync(true, pageNumber);
    }
  };

  useEffect(() => () => {
    if (activeTab === 1) {
      pageNumber = 1;
      setcount(0);
      setpage(1);
      fetchEvents();
    } else {
      getDataAsync(false);
    }
  }, [activeTab]);

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      const _id = params.get('id');
      if (_id) {
        setActiveSinglePop({
          id: _id,
          active: true,
        });
        params.delete('id');
        history.replace({
          search: params.toString(),
        });
      }
    }
  }, [search]);

  return (
    <>
      <Title__container>
        <Icon icon={faCalendarDay} />
        <Content__pageTitle content={getField({ base: basePage, field: 'page_title' })} />
        <Grid__separator width="25px" />
        {
          (actionCount) ? (
            <Content__counterInfo
              value={actionCount}
              label={getField({ base: basePage, field: 'page_notification_counter' })}
            />
          ) : null
        }
      </Title__container>
      <Content__container>
        <Table__container>
          <Cards__navTab
            items={navItems}
            event={handleTabChange}
          />
          {
            activeTab === 0 ? (
              <>
                <Container>
                  {calendar}
                </Container>
                {
                  isFleet && (
                    <>
                      <Grid__separator size="25px" />
                      <Button__primary
                        withIcon
                        noprevent
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        event={openPopup}
                      >
                        {getField({ base: basePage, field: 'new_appointment_button' })}
                      </Button__primary>
                    </>
                  )
                }
              </>
            ) : (
              <>
                <Table
                  headerCol={headerCol}
                  lineCol={renderList()}
                  noSelect
                  lineEvent={handleLineClick}
                  maxHeight="100%"
                  scrollEndEvent={handleScrollEndEvent}
                />
                {
                  isFleet && (
                    <>
                      <Grid__separator size="25px" />
                      <Button__primary
                        withIcon
                        noprevent
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        event={openPopup}
                      >
                        {getField({ base: basePage, field: 'new_appointment_button' })}
                      </Button__primary>
                    </>
                  )
                }
              </>
            )
          }
        </Table__container>
      </Content__container>
      {activeLaunchPop && isFleet && <Appointment__formPop basePage={basePage} isFleet={isFleet} isWorkshop={isWorkshop} active={activeLaunchPop} closeEvent={closePopup} closeAndAdd={closeAndAdd} />}
      <Appointment__Single basePage={basePage} isWorkshop={isWorkshop} isFleet={isFleet} id={activeSinglePop.id} active={activeSinglePop.active} closeEvent={closeSinglePop} refresh={handleRefresh} />
      {loading && <Loader__wrapper />}
    </>
  );
};

export default Appointment__calendar;

