import React, { Fragment, useRef } from 'react';
import styled from 'styled-components';
import t from 'theme';
import Button__primary from 'components/button/primary/button__primary';
import { Grid__card } from 'components/grid/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import Onboarding__switcher from 'components/onboarding/switcher/onboarding__switcher';

const MainCards = styled(Grid__card)`
  width: 100%;
  padding: 40px;
  background: #01B5E2;
  height: 240px;
  position: relative;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 1.4;
  max-width: 220px;
  color: #FFFFFF;
`;

const Button = styled(Button__primary)`
  position: absolute;
  bottom: 40px;
  left: 40px;
`;

const Img = styled.img`
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Cards__tuto = ({
  style,
  title,
  button,
}) => {
  const launchRef = useRef(null);

  const handleClick = () => {
    if (launchRef && launchRef.current) {
      launchRef.current.handleOpen();
    }
  };

  return (
    <Fragment>
      <MainCards style={style}>
        <Title>{title}</Title>
        <Button
          white
          textColor="#01B5E2"
          withIcon
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          event={handleClick}
        >
          {button}
        </Button>
        <Img src={t.image.illu.onboardCard} />
      </MainCards>
      <Onboarding__switcher
        ref={launchRef}
        visible={false}
        account
      />
    </Fragment>
  );
};

export default Cards__tuto;
