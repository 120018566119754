
import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import Nav from 'components/global/nav';
import t from 'theme';
import { ContentContext } from 'context/contentContext';

import {
  faListOl, faQuestion, faBookOpen, faShoppingCart,
} from '@fortawesome/pro-light-svg-icons';
import Layout__container from 'containers/layout/container/layout__container';
import { Transition__loader_controller } from 'components/transition/transition';
import DashboardService24__historic from 'containers/workshopView/dashboardService24/historic/dashboardService24__historic';
import DashboardService24__action from 'containers/workshopView/dashboardService24/action/dashboardService24__action';
import DashboardService24__infoPop from 'containers/workshopView/dashboardService24/infoPop/dashboardService24__infoPop';
import DashboardService24__singlePop from 'containers/workshopView/dashboardService24/singlePop/dashboardService24__singlePop';
import DashboardService24__formPop from 'containers/workshopView/dashboardService24/formPop/dashboardService24__formPop';
import { strapiService24Service, strapiSingleServicePage } from 'util/strapi/content.starpi';
import ServiceDashboard__layout from 'containers/workshopView/serviceDashboard/layout/serviceDashboard__vehicleList';
import { ROUTING } from 'util/constant/routing.constant';
import { SERVICENAME } from 'util/service/serviceName';
import { getAction } from 'util/function/reloadAction.function';
import { AppContext } from 'context/appContext';
import { useData } from 'components/data/getter/data__getter';
import marked from 'marked';


/**
 * @name DashboardService24
 */

const DashboardService24 = () => {
  const {
    loadPage, haveContentFor, getField, getGlobal, page,
  } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [activeinfoPop, setactiveinfoPop] = useState(false);
  const [activeSettingPop, setactiveSettingPop] = useState(false);
  const [activesinglePop, setactivesinglePop] = useState(false);
  const [activeLaunchPop, setactiveLaunchPop] = useState(false);
  const [, setactiveFormPop] = useState(false);
  const [requestSucces, setrequestSucces] = useState(false);
  const [selectLaunchVehicle, setselectLaunchVehicle] = useState(null);
  const launchRef = useRef(null);
  const formRef = useRef(null);
  let pageNumber = 1;
  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      id: user.user.UserWorkshop.id,
      workshop: true,
      user: true,
    },
    service: SERVICENAME.service24,
    type: 'service',
  });


  const handleCheckParams = () => {
    const actionParams = getAction({ key: 'action' });
    if (actionParams.success === true) {
      setactivesinglePop({ active: true, id: actionParams.value });
    }
  };
  const loadContent = async () => {
    if (haveContentFor(strapiService24Service).success === false) {
      await loadPage(strapiService24Service);
    }
    if (haveContentFor(strapiSingleServicePage).success === false) {
      await loadPage(strapiSingleServicePage);
    }

    const params = getAction({ key: 'launch' });
    if (params.success) {
      if (params.value === '-1' || params.value === -1) {
        setactiveLaunchPop(true);
      } else {
        setactiveLaunchPop({ active: true, id: params.value });
      }
    }

    handleCheckParams();
  };
  useEffect(() => {
    loadContent();
  }, []);


  useEffect(() => {
    if (activeLaunchPop === true) {
      if (formRef && formRef.current) {
        formRef.current.handleCleanHook();
        setselectLaunchVehicle(null);
        setactiveFormPop(false);
      }
      if (launchRef && launchRef.current) {
        launchRef.current.handleClean();
      }
    }
  }, [activeLaunchPop]);

  if (
    haveContentFor(strapiService24Service).success === false
    || haveContentFor(strapiSingleServicePage).success === false
  ) {
    return <Transition__loader_controller active />;
  }

  const handleCloseInfoPop = () => {
    setactiveinfoPop(false);
  };

  const handleOpenInfoPop = () => {
    setactiveinfoPop(true);
  };

  const handleOpenSettingPop = () => {
    setactiveSettingPop(true);
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({ active: false, id: null });
  };

  const handleOpenSinglePop = ({ id }) => {
    setactivesinglePop({ active: true, id });
  };

  const handleCloseLaunchPop = () => {
    if (requestSucces) {
      if (formRef && formRef.current) {
        formRef.current.handleReloadHook();
      }
    } else {
      setactiveLaunchPop(false);
    }
  };
  const handleOpenLaunchPop = () => {
    setactiveLaunchPop(true);
  };

  const handleCallButton = () => {
    window.location.href = 'tel:0080046835033';
  };

  const handleOpenFormPop = (selectedVehicle) => {
    setselectLaunchVehicle(selectedVehicle);
    setactiveFormPop(true);
  };

  const handlePrevAction = () => {
    if (launchRef && launchRef.current) {
      launchRef.current.handlePrevHook('vehicle');
    }
  };

  const renderInfoPopProps = () => ({
    intro: {
      title: getField({ base: strapiService24Service, field: 'service_title' }),
      txt: <div dangerouslySetInnerHTML={{ __html: marked(getField({ base: strapiService24Service, field: 'description' })) }} />,
      label: getField({ base: strapiSingleServicePage, field: 'price_detail' }),
      banner: t.image.global.service24,
    },
    condition1: {
      content: <div dangerouslySetInnerHTML={{ __html: marked(getField({ base: strapiService24Service, field: 'registration_processs' })) }} />,
      title: getField({ base: strapiSingleServicePage, field: 'registration_processs' }),
      icon: faListOl,
    },
    condition2: {
      content: <div dangerouslySetInnerHTML={{ __html: marked(getField({ base: strapiService24Service, field: 'how' })) }} />,
      title: getField({ base: strapiSingleServicePage, field: 'how' }),
      icon: faQuestion,
    },
    condition3: {
      content: <div dangerouslySetInnerHTML={{ __html: marked(getField({ base: strapiService24Service, field: 'conditions' })) }} />,
      title: getField({ base: strapiSingleServicePage, field: 'conditions' }),
      icon: faBookOpen,
    },
    condition4: {
      content: <div dangerouslySetInnerHTML={{ __html: marked(getField({ base: strapiService24Service, field: 'payment' })) }} />,
      title: getField({ base: strapiSingleServicePage, field: 'payment' }),
      icon: faShoppingCart,
    },
  });

  const handleTableClick = (line, vehicle) => {
    handleOpenSinglePop({ id: vehicle.id });
  };


  const handleisRequestSucces = () => {
    setrequestSucces(true);
  };

  const handleLoadNewPage = () => {
    if (resData.data && !resData.loading && pageNumber + 1 > 1 && resData.data.length < resData.count) {
      pageNumber += 1;
      setPage(pageNumber, resData.data);
    }
  };

  return (
    <Layout__container>
      <Nav workshop active="cases" />
      <Transition__loader_controller active={false} />
      <ServiceDashboard__layout
        page_title={getField({ base: strapiService24Service, field: 'service_title' })}
        // breadCrumb
        breadCrumb_parent={getGlobal({ option: 'workshop_menu', field: 'my_cases' })}
        breadCrumb_parentLink={ROUTING.workshop.cases.path}
        // Service history layout
        history={(
          <DashboardService24__historic
            tableEvent={handleTableClick}
            loadingData={resData.loading}
            data={resData.data}
            scrollEndEvent={handleLoadNewPage}
            loadingNewPage={resData.loading}
          />
        )}
        // Action side element
        action={(
          <DashboardService24__action
            infoEvent={handleOpenInfoPop}
            settingsEvent={handleOpenSettingPop}
            launchServicePop={handleOpenLaunchPop}
            handleCallButton={handleCallButton}
            serviceCount={resData.count}
          />
        )}
        // Info pop up
        activeInfoPop={activeinfoPop}
        closeInfoPop={handleCloseInfoPop}
        infoPopProps={renderInfoPopProps()}
        infoPop={<DashboardService24__infoPop />}
        // Single pop up
        activeSinglePop={activesinglePop}
        closeSinglePop={handleCloseSinglePop}
        singlePop={(
          <DashboardService24__singlePop
            active={activesinglePop.active}
            id={activesinglePop.id}
            closeEvent={handleCloseSinglePop}
          />
        )}

        // Launch request pop up
        launchRequest
        launchTitle={getField({ base: strapiService24Service, field: 'request_title' })}
        launchRef={launchRef}
        activeLaunchPop={activeLaunchPop}
        closeLaunchPop={handleCloseLaunchPop}
        nextEventLaunchPop={handleOpenFormPop}
        serviceName={SERVICENAME.service24}
        requestSucces={requestSucces}
        // launch request form pop uo
        formPop={(
          <DashboardService24__formPop
            vehicle={selectLaunchVehicle}
            closeEvent={handleCloseLaunchPop}
            prevEvent={handlePrevAction}
            isRequestSucces={handleisRequestSucces}
            ref={formRef}
          />
        )}
        Step1Title={getField({ base: strapiService24Service, field: 'step1_title' })}
        Step2Title={getField({ base: strapiService24Service, field: 'step2_title' })}
      />
    </Layout__container>
  );
};

export default DashboardService24;
