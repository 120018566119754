import { API_URL } from '../Utile';
import { CallGet, CallPost } from '../Helpers';

/**
 * Base routes
 */

/* ---------------------------------- LOGIN --------------------------------- */

export const Login = async ({ mail, pass }) => {
  const params = {
    email: mail,
    pass,
  };

  const url = `${API_URL}users/login`;

  const res = await CallPost(url, params);

  return res;
};

/* ------------------------------- CHECK AUTH ------------------------------- */

export const CheckAuth = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = {};

  const url = `${API_URL}users/checkauth`;

  const res = await CallGet(url, params, headers);

  return res;
};

/* -------------------------- GET USER INFORMATION -------------------------- */

export const UserInformation = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = {};

  const url = `${API_URL}users/me`;

  const res = await CallGet(url, params, headers);

  return res;
};

/* -------------------------- UPDATE USER INFORMATION -------------------------- */

export const updateUserInformation = async ({ field }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = {};

  field.forEach((element) => {
    params[element.key] = element.value;
  });

  const url = `${API_URL}users/update/`;

  const res = await CallPost(url, params, headers);

  return res;
};

/* -------------------------- UPDATE FLEETUSER INFORMATION -------------------------- */

export const updatefleetUserInformation = async (field) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = field;

  const url = `${API_URL}users/updateFleetUser/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const updateFleetUserInRegistration = async (field) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = field;

  const url = `${API_URL}users/updateFleetUserInRegistration/`;

  const res = await CallPost(url, params, headers);

  return res;
};

/* -------------------------- UPDATE FLEETUSER INFORMATION -------------------------- */

export const updateWorkshopUserInRegistration = async (field) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = field;

  const url = `${API_URL}users/updateWorkshopUserInRegistration/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const updateWorkshopUserPrivate = async (field) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = field;

  const url = `${API_URL}users/updateWorkshopUser/`;

  const res = await CallPost(url, params, headers);

  return res;
};

/* -------------------------- UPDATE PASSWORD -------------------------- */

export const updatePassword = async ({ field }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = {
    currentPassword: field.current,
    newPassword: field.new,
  };

  const url = `${API_URL}users/updatePass/`;

  const res = await CallPost(url, params, headers);

  return res;
};

/* -------------------------- REGISTER -------------------------- */

export const registerUser = async ({ field }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = field;

  const url = `${API_URL}users/registerUser`;

  const res = await CallPost(url, params, headers);

  return res;
};

/* -------------------------- REGISTER -------------------------- */

export const getOne = async ({ id }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = {};

  const url = `${API_URL}users/getOne/${id}`;

  const res = await CallGet(url, params, headers);

  return res;
};

/* -------------------------- REGISTER -------------------------- */

export const getUserByUrl = async ({ urlId }) => {
  const params = {};

  const url = `${API_URL}users/getUserByUrl/${urlId}`;

  const res = await CallGet(url, params, { Authorization: '' });

  return res;
};

/* -------------------------- GET WORKSHOPUSER -------------------------- */

export const getWorkShopUser = async ({ userId }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}users/userWorkshop/${userId}`;

  const res = await CallGet(url, params, headers);

  return res;
};
/* -------------------------- REGISTER -------------------------- */

export const definePassword = async ({ id, password, tempUrl }) => {
  const params = {
    userId: id,
    pass: password,
    url: tempUrl,
  };

  const url = `${API_URL}users/definePassword`;

  const res = await CallPost(url, params, { Authorization: '' });

  return res;
};

export const acceptContract = async ({ id, tempUrl }) => {
  const params = {
    userId: id,
    url: tempUrl,
  };

  const url = `${API_URL}users/acceptContract`;

  const res = await CallPost(url, params, { Authorization: '' });

  return res;
};

/* -------------------------- FORGOT -------------------------- */

export const requestPassword = async ({ mail, fromUrl }) => {
  const params = {
    mail,
    fromUrl,
  };

  const url = `${API_URL}users/requestPassword`;

  const res = await CallPost(url, params, { Authorization: '' });

  return res;
};
/* -------------------------- Reset -------------------------- */

export const resetPassword = async ({ token, newPassword }) => {
  const params = {
    token,
    newPassword,
  };

  const url = `${API_URL}users/resetPassword`;

  const res = await CallPost(url, params, { Authorization: '' });

  return res;
};

/* -------------------------- Reset -------------------------- */

export const canChangeHomeBase = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}homebase/canChange/${params.userId}`;

  const res = await CallGet(url, {}, headers);

  return res;
};

export const getUserFleetByObligoUrl = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}users/getUserFleetByObligoUrl/${payload.url}`;

  const res = await CallGet(url, params, headers);

  return res;
};
