
import React, {
  Fragment, useContext, useState,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import { Grid__separator } from 'components/grid/grid';
import Button__primary from 'components/button/primary/button__primary';
import { Form__textfield, Form__uploader } from 'components/form/form';
import { Message__sucess } from 'components/message/message';
import Grid__popup from 'components/grid/popup/grid__popup';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';
import { createErrorObject, ScrollToError } from 'util/conditions/checkField.condition';
import { hasFieldError } from 'util/conditions/haveErrorField.conditions';
import { sizeLimit } from 'util/conditions/sizeFile.util';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { AppContext } from 'context/appContext';
import { ContentContext } from 'context/contentContext';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { CloseCase, createCaseFile } from '../../../../../helpers/api/routes/Cases';

const Container = styled(Grid__popup)`
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
`;

const ContainerForm = styled.div`
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
  width: 500px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
  max-width: 500px;
`;


const SingleCase__closedCaseForm = ({
  style, active, closePop, caseId, data, refresh, basePage,
}) => {
  const { getFieldGroup } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const { getGlobal } = useContext(ContentContext);
  const [sucessScreen, setsucessScreen] = useState(false);
  const [file, setfile] = useState(null);
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [error, seterror] = useState(false);
  const [loading, setloading] = useState(false);
  const handleClose = () => {
    if (closePop) {
      closePop();
    }
  };

  const handleSuccesMessage = () => {
    if (closePop) {
      closePop();
      setsucessScreen(false);
    }
  };

  const onDescriptionChange = (e) => {
    setDescription(e.value);
  };

  const onPriceChange = (e) => {
    setPrice(e.value);
  };

  const parseFiles = (fileArray) => {
    setfile(fileArray[0]);
  };

  const checkField = () => {
    let errorObj = {};
    let errorArray = {};

    const baseArrayField = [
      {
        key: 'description',
        type: 'simple',
        value: description,
      },
      {
        key: 'price',
        type: 'simple',
        value: price,
      },
    ];

    errorObj = createErrorObject(errorArray, baseArrayField, errorObj.hasError, getGlobal);
    errorArray = errorObj.object;

    if (file === null || sizeLimit(file)) {
      if (sizeLimit(file)) {
        errorArray.file = { active: true, message: 'File limite' };
      } else {
        errorArray.file = { active: true };
      }
      errorObj.hasError = true;
    }

    if (errorObj.hasError === false) {
      ScrollToError();
    }
    seterror(errorArray);
    return errorObj.hasError;
  };

  const onCloseCase = async () => {
    if (!checkField()) {
      setloading(true);
      const params = {
        docDescription: description,
        docTextLang: user.user.lang,
        price,
        id: caseId,
      };
      let fileParams = false;
      if (file) {
        fileParams = {
          file,
          extension: file.extension,
          serviceUid: data.uid,
          fileId: data.id,
        };
      }

      const res = await CloseCase(params);
      const formated = formatApiRes(res);
      if (res && formated.success && res.data.success === true) {
        const fileRes = await createCaseFile(fileParams);
        if (fileRes && fileRes.data && fileRes.data.success === true) {
          setloading(false);
          setsucessScreen(true);
          refresh();
        } else {
          setloading(false);
        }
      } else {
        setloading(false);
      }
    }
  };

  return (
    <Container style={style} active={active} closeEvent={handleClose}>
      {!sucessScreen ? (
        <Fragment>
          <Content__cardTitle content={getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'title' })} />
          <Grid__separator size="25px" />
          <Description>
            {getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'content' })}
          </Description>
          <Grid__separator size="45px" />
          <ContainerForm>
            <Form__textfield
              type="textarea"
              label={getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'description_label' })}
              require
              property="description"
              value={description}
              change={onDescriptionChange}
              error={hasFieldError(error, 'description')}
            />
            <Grid__separator size="35px" />
            <Form__textfield
              label={getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'price_label' })}
              require
              type="price"
              property="price"
              value={price}
              change={onPriceChange}
              error={hasFieldError(error, 'price')}
            />
            <Grid__separator size="35px" />
            <Form__uploader
              label={getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'invoice_label' })}
              onFilesAddedCallBack={parseFiles}
              error={error.hasOwnProperty('file') ? { active: true, message: error.file.message } : false}
            />
            <Grid__separator size="35px" />
            <Row>
              <Button__primary
                event={onCloseCase}
              >
                {getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'validate_button' })}
              </Button__primary>
            </Row>
            <Grid__separator size="25px" />
          </ContainerForm>
        </Fragment>
      ) : (
        <Message__sucess
          icon={t.image.illu.check}
          title={getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'confirmation_title' })}
          text={getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'confirmation_content' })}
          buttonEvent={handleSuccesMessage}
          buttonText={getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'confirmation_button' })}
        />
      )}
      {loading ? <Loader__wrapper /> : false}
    </Container>
  );
};

export default SingleCase__closedCaseForm;
