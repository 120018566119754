
export const capitalizeFirstLetter = (s) => {
  if (!s) return s;
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.slice(1);
};

/* ------------------------------ FORMAT STRING ----------------------------- */

export const format_str = ({ string, format }) => {
  let returnStr = string;

  if (string === '' || string === null || string === undefined) {
    returnStr = null;
  } else if (format && format === 'capital') {
    returnStr = capitalizeFirstLetter(string);
  }

  return returnStr;
};

/* ------------------------------ FORMAT INTEGER ----------------------------- */

export const format_int = ({ int, toString }) => {
  let returnInt = `${int}`;

  if (int === '' || int === null || int === undefined) {
    returnInt = null;
  // eslint-disable-next-line no-restricted-globals
  } else if (!toString && Number.isNaN(parseInt(int, 10)) === false) {
    returnInt = parseInt(int, 10);
  }

  return returnInt;
};

/* ------------------------------ CHECK FORMAT OBJECT ----------------------------- */

export const format_apiobject = ({ obj }) => {
  if (!obj.hasOwnProperty('success') || !obj.hasOwnProperty('data')) {
    return null;
  } if (obj.success === false) {
    return null;
  }


  return obj.data || null;
};
