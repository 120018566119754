
/**
   * Import Lib
   */

import React, { Fragment, useContext, useState } from 'react';
import styled from 'styled-components';
import { ROUTING } from 'util/constant/routing.constant';
import { SendMail } from 'helpers/api/routes/Mail';
import t from 'theme';
import { List__table, List__content } from 'components/list/list';
import { Content__text } from 'components/content/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faListUl } from '@fortawesome/pro-regular-svg-icons';
import Content__labelText from 'components/content/labelText/content__labelText';
import Content__tag from 'components/content/tag/content__tag';
import {
  strapiServiceHeader,
  strapiPaymentGuaranteeService,
  strapiWorkshopDashboardPaymentGuarantee,
} from 'util/strapi/content.starpi';
import { ContentContext } from 'context/contentContext';
import Grid__popup from 'components/grid/popup/grid__popup';
import Grid__separator from 'components/grid/separator/grid__separator';
import Cards__focusCard from 'components/cards/focusCard/cards__focusCard';
import Button__primary from 'components/button/primary/button__primary';
import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import { AppContext } from 'context/appContext';

import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import Message__sucess from 'components/message/sucess/message__sucess';
import { parseStatus } from 'util/conditions/statusProps.util';
import { sequelizeDate, sequelizeDateWithOneMonths, sequelizeDateTime } from 'util/date.util';
import { formatPrice } from 'util/format/price.format';
import { OBLIGO } from 'util/constant/obligo.constant';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import { updatefleetUserInformation } from 'helpers/api/routes/User';
import DashboardPaymentGuarantee__singlePop from 'containers/fleetView/dashboardPaymentGuarantee/singlePop/dashboardPaymentGuarantee__singlePop';
import { handleLineClick_singleNew } from 'util/function/openSingleService';
import { SERVICENAME } from 'util/service/serviceName';
import { useData } from 'components/data/getter/data__getter';
import { getFormService } from 'util/function/getter';
import { createRandomUrl } from 'util/randomUrl.util';
import Contract__pdf from 'components/contract/pdf/contract__pdf';
import { slugify } from 'helpers/js/Utile';


const Popup = styled(Grid__popup)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoHeader = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #4D4D4D;
  max-width: 700px;
`;

const Content = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #4D4D4D;
  max-width: 600px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DashboardPaymentGuarantee__obligoPop = ({
  style,
  active,
  closeEvent,
}) => {
  let pageNumber = 1;
  const {
    getField, getGlobal, getFieldGroup,
  } = useContext(ContentContext);
  const { user, getUser } = useContext(AppContext);
  const [loadingAction, setloadingAction] = useState(false);
  const [sucessSend, setsucessSend] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [paymentguarantee, refreshData, setPage, setSearch] = useData({
    params: {
      id: user.user.UserFleet.id,
      user: true,
      obligo: true,
    },
    service: SERVICENAME.paymentguarantee,
    type: 'service',
  });
  const [activesinglePop, setactivesinglePop] = useState({
    active: false,
    id: null,
  });
  const renderObligoStatus = () => {
    if (user.user) {
      if (user.user.UserFleet) {
        if (user.user.UserFleet.obligoRequest && user.user.UserFleet.obligoStatus) {
          return user.user.UserFleet.obligoStatus;
        }
      }
    }

    return false;
  };

  const renderObligoInfo = () => {
    if (user.user) {
      if (user.user.UserFleet) {
        if (user.user.UserFleet.obligoAmount && user.user.UserFleet.obligoSetDate) {
          const used = user.user.UserFleet.obligoAvailable || 0;
          return {
            date: user.user.UserFleet.obligoSetDate,
            current: user.user.UserFleet.obligoAmount - used,
            amount: user.user.UserFleet.obligoAmount,
          };
        }
      }
    }

    return {
      date: new Date(),
      current: 0,
      amount: 0,
    };
  };
  const headerCol = [
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'id_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'vehicle_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'date_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'type_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'amount_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'status_header' }),
    },
  ];

  const renderList = () => {
    const orderArray = paymentguarantee.data;
    const arrayOfLine = [];

    orderArray.forEach((element) => {
      arrayOfLine.push([
        {
          label: (
            <List__content capitalize>
              {getFormService(element, 'uid')}
            </List__content>
          ),
        },
        {
          label: (
            <List__content capitalize>
              {getFormService(element, 'registrationNumber', 'vehicle')}
            </List__content>
          ),
        },
        {
          label: (
            <List__content capitalize>
              {sequelizeDate({ date: getFormService(element, 'createdAt'), format: 'd/m/y' })}
            </List__content>
          ),
        },
        {
          label: (
            <List__content capitalize>
              {getGlobal({ option: 'obligo', field: OBLIGO.type[getFormService(element, 'type')] })}
            </List__content>
          ),
        },
        {
          label: (
            <List__content capitalize>
              {getFormService(element, 'price')}
            </List__content>
          ),
        },
        {
          label: (
            <Content__tag
              content={getFieldGroup({
                base: strapiPaymentGuaranteeService,
                groupe: 'status',
                field: getFormService(element, 'status'),
              })}
              {...parseStatus({ status: getFormService(element, 'status') })}
            />
          ),
        },
      ]);
    });

    return arrayOfLine;
  };


  const handleSendRequest = async () => {
    setloadingAction(true);
    const res = await updatefleetUserInformation({
      obligoRequest: true,
      obligoStatus: 'waiting',
      userId: user.user.UserFleet.id,
      obligoValidationUrl: createRandomUrl(),
    });
    if (res) {
      setloadingAction(false);
    }
    if (formatApiRes(res).success === true) {
      setsucessSend(true);
      if (!user.user.UserFleet.UserWorkshop.UserWorkshopAlias.notificationDisable) {
        SendMail({
          template: 'notification',
          templateNotification: {
            status: 'action',
            statusLabel: getFieldGroup({
              select_lang: user.user.lang, base: strapiPaymentGuaranteeService, groupe: 'obligo_demand', field: 'status',
            }),
            content: `${user.user.companyName} ${getFieldGroup({
              select_lang: user.user.lang, base: strapiPaymentGuaranteeService, groupe: 'obligo_demand', field: 'content',
            })}`,
            btnContent: getFieldGroup({
              select_lang: user.user.lang, base: strapiPaymentGuaranteeService, groupe: 'obligo_demand', field: 'btn',
            }),
            btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.workshop.dashboardPaymentGuarantee.path}`,
          },
          subject: getFieldGroup({
            select_lang: user.user.lang, base: strapiPaymentGuaranteeService, groupe: 'obligo_demand', field: 'subject',
          }),
          destination: user.user.UserFleet.UserWorkshop.UserWorkshopAlias.email,
        });
      }
    }
  };

  const handleReloadUser = async () => {
    setloadingAction(true);
    const resUser = await getUser();
    if (resUser) {
      setloadingAction(false);
    } else {
      setloadingAction(false);
    }
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({
      active: false,
      id: null,
    });
  };

  const handleLine = (line) => {
    handleLineClick_singleNew({
      line,
      array: paymentguarantee.data,
      callback: (callback) => setactivesinglePop({
        active: callback.active,
        id: callback.id,
      }),
    });
  };

  const handleLoadNewPage = () => {
    if (paymentguarantee.data && !paymentguarantee.loading && pageNumber + 1 > 1 && paymentguarantee.data.length < paymentguarantee.count) {
      pageNumber += 1;
      setPage(pageNumber, paymentguarantee.data);
    }
  };

  const renderPDf = () => (
    <Contract__pdf
      buttonText={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_downnload' })}
      loadingtext={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_download_active' })}
      content={getFieldGroup({
        base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'contract_obligo', field: 'contract_content',
      })}
      type="obligo"
      name={`contract_obligo_${slugify(user.user.companyName)}`}
      guarantor_company={user.user.UserFleet.UserWorkshop.UserWorkshopAlias.companyName}
      guarantor_address={`${user.user.UserFleet.UserWorkshop.UserWorkshopAlias.adress} ${user.user.UserFleet.UserWorkshop.UserWorkshopAlias.city}, ${user.user.UserFleet.UserWorkshop.UserWorkshopAlias.zipcode}`}
      guarantor_country={user.user.UserFleet.UserWorkshop.UserWorkshopAlias.region}
      guarantor_contact={user.user.UserFleet.UserWorkshop.UserWorkshopAlias.email}

      supervisor_name={`${user.user.UserFleet.UserWorkshop.supervisorname} ${user.user.UserFleet.UserWorkshop.supervisorlastname}`}
      supervisor_mail={user.user.UserFleet.UserWorkshop.supervisormail}
      supervisor_time={sequelizeDateTime({ date: user.user.UserFleet.confirmationDate, atContent: getGlobal({ option: 'globalcontent', field: 'at' }) })}

      amount={formatPrice(renderObligoInfo().amount)}

      fleet_company={user.user.companyName}
      fleet_address={user.user.adress}
      fleet_zip={user.user.zipcode}
      fleet_location={user.user.city}
      fleet_phone={user.user.phoneNumber}
      fleet_cmail={user.user.email}
      fleet_tva={user.user.vat}
    />
  );

  return (
    <Fragment>
      <Popup style={style} active={active} closeEvent={closeEvent}>
        {
          !sucessSend ? (
            <Fragment>
              <Content__text
                type="p"
                color="#4d4d4d"
                align="center"
                style={{ fontWeight: 600, fontSize: 26 }}
              >
                {getField({ base: strapiPaymentGuaranteeService, field: 'obligo_popup_title' })}
              </Content__text>
              <Grid__separator size="15px" />
              <InfoHeader>
                {getField({ base: strapiPaymentGuaranteeService, field: 'obligo_popup_text' })}
              </InfoHeader>
              <Grid__separator size="45px" />
              { renderObligoStatus() === false ? (
                <Cards__focusCard blue>
                  <Content>
                    {getField({ base: strapiPaymentGuaranteeService, field: 'obligo_notset_text' })}
                  </Content>
                  <Grid__separator size="25px" />
                  <Button__primary event={handleSendRequest}>{getField({ base: strapiPaymentGuaranteeService, field: 'obligo_notset_button' })}</Button__primary>
                </Cards__focusCard>
              ) : renderObligoStatus() === SERVICE_STATUS.waiting ? (
                <Cards__focusCard orange>
                  <Content>
                    {getField({ base: strapiPaymentGuaranteeService, field: 'obligo_waiting' })}
                  </Content>
                </Cards__focusCard>
              ) : renderObligoStatus() === SERVICE_STATUS.error ? (
                <Cards__focusCard red>
                  <Content>
                    {getField({ base: strapiPaymentGuaranteeService, field: 'obligo_refused_text' })}
                  </Content>
                  <Grid__separator size="25px" />
                  <Button__primary event={handleSendRequest}>{getField({ base: strapiPaymentGuaranteeService, field: 'obligo_refused_button' })}</Button__primary>
                </Cards__focusCard>
              ) : (
                <Fragment>
                  <Content__sectionTitle
                    line
                    icon={<FontAwesomeIcon icon={faChartBar} />}
                    title={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_popup_information_title' })}
                  />
                  <Row>
                    <Content__bigTag
                      column
                      label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_popup_information_available' })}
                      value={formatPrice(renderObligoInfo().amount - renderObligoInfo().current)}
                    />
                    <Content__bigTag
                      style={{ marginLeft: 20, marginRight: 20 }}
                      column
                      label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_remaining' })}
                      value={formatPrice(renderObligoInfo().current)}
                    />
                    <Content__bigTag
                      column
                      label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_autorised' })}
                      value={formatPrice(renderObligoInfo().amount)}
                    />
                  </Row>
                  <Grid__separator size="35px" />
                  <Row>
                    <Content__labelText
                      label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_popup_information_reset' })}
                      content={sequelizeDateWithOneMonths({ date: renderObligoInfo().date, format: 'd/m/y' })}
                    />
                    {renderPDf()}
                  </Row>
                  <Grid__separator size="65px" />
                  <Content__sectionTitle
                    line
                    icon={<FontAwesomeIcon icon={faListUl} />}
                    title={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_popup_historic' })}
                  />
                  <List__table
                    headerCol={headerCol}
                    lineCol={paymentguarantee && paymentguarantee.data ? renderList() : []}
                    noSelect
                    lineEvent={handleLine}
                    maxHeight="320px"
                    scrollEndEvent={handleLoadNewPage}
                  />
                  {
                    paymentguarantee.loading && (
                      <Loader__wrapper />
                    )
                  }
                </Fragment>
              )}
            </Fragment>

          ) : (
            <Message__sucess
              icon={t.image.illu.check}
              title={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_sucess_title' })}
              text={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_sucess_text' })}
              buttonText={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_sucess_button' })}
              buttonEvent={handleReloadUser}
            />
          )
        }
        {loadingAction ? <Loader__wrapper /> : false}
      </Popup>
      <DashboardPaymentGuarantee__singlePop
        active={activesinglePop.active}
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
    </Fragment>
  );
};

export default DashboardPaymentGuarantee__obligoPop;
