import { API_URL } from '../Utile';
import { CallGet, CallPost, CallDelete } from '../Helpers';

/**
 * Base routes
 */

/* ---------------------------- GET ALL VEHICULES ---------------------------- */

/* ------------------------- ADD VEHICLE TO ACCOUNT ------------------------ */

export const AddVehicule = async ({ fleetObj, userId }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const json = JSON.stringify(fleetObj);
  const params = {
    fleetObj: json,
    userId,
  };

  const url = `${API_URL}fleet/create/`;

  const res = await CallPost(url, params, headers);

  return res;
};

/* ------------------------- UPDATE VEHICLE ------------------------ */

export const UpdateVehicule = async ({ fleetObj }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = fleetObj;

  const url = `${API_URL}fleet/update/`;

  const res = await CallPost(url, params, headers);

  return res;
};

/* ----------------------------- DELETE VEHICLE ---------------------------- */

export const DeleteVehicule = async ({ id }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {
    fleetId: id,
  };

  const url = `${API_URL}fleet/delete`;

  const res = await CallDelete(url, params, headers);

  return res;
};

/* ------------------------- SET HOME BASE WORKSHOP ------------------------- */

export const setHomeBase = async ({ id }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = {
    workshopId: id,
  };

  const url = `${API_URL}homebase/add/`;

  const res = await CallPost(url, params, headers);

  return res;
};

/* ------------------------- REMOVE HOME BASE WORKSHOP ------------------------- */

export const removeHomeBase = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = {};

  const url = `${API_URL}homebase/remove/`;

  const res = await CallPost(url, params, headers);

  return res;
};

/* ---------------------------- GET ONE VEHICLE ---------------------------- */

export const CheckVehicleId = async (id) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = {};

  const url = `${API_URL}fleet/${id}`;

  const res = await CallGet(url, params, headers);

  return res;
};

export const CheckVehicle = async (id) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = {};

  const url = `${API_URL}fleet/check/${id}`;

  const res = await CallGet(url, params, headers);

  return res;
};
/* ---------------------------- CHECK VIN VEHICLE ---------------------------- */

export const checkVin = async ({ fleetObj }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const json = JSON.stringify(fleetObj);
  const params = {
    fleetObj: json,
  };
  const url = `${API_URL}fleet/checkVin/`;
  const res = await CallPost(url, params, headers);

  return res;
};
