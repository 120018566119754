import XLSX from 'xlsx';

class XlsxFile {
  constructor(file) {
    this.file = file;
  }

  readXlsx = () => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);

    fileReader.onload = (e) => {
      const bufferArray = e.target.result;

      const workbook = XLSX.read(bufferArray, { type: 'buffer' });

      const wsname = workbook.SheetNames[0];

      const ws = workbook.Sheets[wsname];

      const data = XLSX.utils.sheet_to_csv(ws);

      resolve(data);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  })

  start() {
    return this.readXlsx();
  }
}
export default XlsxFile;
