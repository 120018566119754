import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import { isVehicleHomeBase } from '../workshop/getVehicleObjectInformation';

export const getPaymentGuaranteeStatus = ({
  status, vehicle, user,
}) => {
  if (isVehicleHomeBase(vehicle, user) && status === SERVICE_STATUS.waiting) {
    return SERVICE_STATUS.action;
  }

  return status;
};

export const getHomebaseIdFromVehicle = (vehicle) => {
  if (vehicle && vehicle.UserFleet && vehicle.UserFleet.UserWorkshop && vehicle.UserFleet.UserWorkshop.UserWorkshopAlias) {
    return vehicle.UserFleet.UserWorkshop.UserWorkshopAlias.userId;
  }

  return {
    workshop: {},
    user: {},
  };
};

export const getPaymentGuaranteeStatusWorkshopHome = ({ status, vehicle, user }) => {
  if (vehicle && vehicle.UserFleet && vehicle.UserFleet.UserWorkshop) {
    const homeBase = vehicle.UserFleet.UserWorkshop.userId;
    const userId = user.user.id;
    if (homeBase === userId && status === SERVICE_STATUS.waiting) {
      return SERVICE_STATUS.action;
    }
  }

  return status;
};
