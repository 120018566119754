/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

const Container = styled.div`
  width: 100%;
`;

const InfoHeader = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-end' })};
  margin-bottom: 8px;
`;

const Value = styled.div`
  font-weight: normal;
  font-size: 16px;
  color: #4d4d4d;
`;

const Label = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #808080;
  margin-left: 5px;
  text-align: left;
`;

const BarContainer = styled.div`
  background: #f0f0f0;
  border-radius: 2px;
  overflow: hidden;
  height: 5px;
  width: 100%;
  position: relative;
`;

const BarProgress = styled.div`
  background: ${({ progress }) => (progress <= 20 ? '#E74C3C' : progress <= 50 ? '#ffaa29' : '#2ECC71')};
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: ${({ progress }) => (progress ? `scaleX(${progress === 100 ? '1' : `0.${progress}`})` : 'scale(0)')};
  transform-origin: left;
`;

const Content__progressBar = ({ style, progress, label }) => (
  <Container style={style}>
    <InfoHeader>
      <Value>
        {progress}
        %
      </Value>
      <Label>{label}</Label>
    </InfoHeader>
    <BarContainer>
      <BarProgress progress={progress} />
    </BarContainer>
  </Container>
);

export default Content__progressBar;
