
/**
   * Import Lib & assets
   */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

const Container = styled.div`
  position: relative;
  text-align: left;
  width: 100%;
  padding: 45px;
  padding-left: 65px;
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-start' })}
  border-radius: 10px;
  background: ${({ color }) => (color ? color : 'transparent')};
`;
const ChildrenContainer = styled.div`
  padding-right: 100px;
`;

const Content__serviceStatus = ({
  style, tag, children, color, blue, red, orange, green,
}) => (
  <Container
    style={style}
    color={
      color
        ? color
        : blue
          ? '#E6F8FC'
          : red
            ? '#FDEDEB'
            : orange
              ? '#FFF6EA'
              : green
                ? '#EAFAF1'
                : '#EAFAF1'
    }
  >
    <ChildrenContainer>
      {children}
    </ChildrenContainer>
    {tag}
  </Container>
);

export default Content__serviceStatus;
