/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

/**
 * import components
 */

/**
 * styled components
 */

const BtnContainer = styled.a`
  height: 40px;
  border-radius: 30px;
  line-height: 40px;
  background-color: ${(props) => (props.border ? 'transparent' : props.color || t.color.cyan)};
  color: ${(props) => (props.border && props.color ? props.color : props.textColor || 'white')};
  font-size: 14px;
  font-weight: 600;
  font-family: ${t.font.primary};
  display: inline-block;
  transition: all ${t.ease.slow_fast_slow1} 400ms;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  padding-left: 25px;
  padding-right: 25px;
  min-width: 150px;
  text-align: center;
  box-shadow: ${(props) => (!props.border && !props.noShadow && !props.red ? '0px 7px 14px rgba(1, 181, 226, 0.2)' : !props.border && !props.noShadow && props.red ? '0px 7px 14px rgba(231, 76, 46, 0.2)' : null)};
  border: ${(props) => (props.border && props.color ? `1px solid ${props.color}` : null)};
  transition: 0.3s ease all;
  &:hover {
      transform: ${(props) => (!props.border && !props.noShadow ? 'translateY(-2px)' : null)};
      box-shadow: ${(props) => (!props.border && !props.noShadow && !props.red ? '0px 7px 14px rgba(1, 181, 226, 0.3)' : !props.border && !props.noShadow && props.red ? '0px 7px 14px rgba(231, 76, 46, 0.3)' : null)};
      background-color: ${(props) => (props.border && props.color ? `${props.color}` : null)};
      color: ${(props) => (props.border && props.color && !props.white ? '#fff' : props.border && props.color && props.white ? t.color.cyan : null)};
    }
  ${({ disable }) => disable && (`
    pointer-events: none;
    user-select: none;
    opacity: 0.4;
  `)};
`;

const TextBtn = styled.span`
  white-space: nowrap;
  margin: 0;
  margin-left: ${(props) => (props.iconEnd ? '0px' : t.grid.xs)};
  margin-right: ${(props) => (props.iconEnd ? t.grid.xs : '0px')};
  font-size: inherit;
`;

const BtnRow = styled.div`
  ${t.mixins.flex()}
  flex-direction: ${(props) => (props.iconEnd ? 'row-reverse' : 'row')};
`;

const Button__primary = ({
  href,
  style,
  color,
  red,
  blue,
  border,
  children,
  noprevent,
  white,
  grey,
  event,
  textColor,
  withIcon,
  icon,
  iconEnd,
  className,
  noShadow,
  target,
  disable,
  download,
}) => {
  const eventHandler = (e) => {
    if (!noprevent) {
      e.preventDefault();
    }
    if (event) {
      event();
    }
  };

  return (
    <BtnContainer
      white={white}
      style={style}
      href={href}
      textColor={textColor}
      className={className}
      border={border}
      noShadow={noShadow}
      target={target}
      disable={disable}
      download={download}
      red={red}
      color={
        color
          ? color
          : blue
            ? t.color.cyan
            : red
              ? t.color.red
              : grey
                ? t.color.grey
                : white
                  ? t.color.white
                  : null
      }
      onClick={(e) => eventHandler(e)}
    >
      {withIcon ? (
        <BtnRow iconEnd={iconEnd}>
          {icon}
          <TextBtn iconEnd={iconEnd}>{children}</TextBtn>
        </BtnRow>
      ) : (
        children
      )}
    </BtnContainer>
  );
};

export default Button__primary;
