
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { sequelizeMileage } from 'util/mileage.util';

import { ContentContext } from 'context/contentContext';


import { List__table, List__content } from 'components/list/list';
import { sequelizeDate } from 'util/date.util';
/**
 * import containers & components
 */import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';


/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 420px;
  position: relative;
`;

const Fleet__list = ({
  style, handleCheck, handleLoadNewPage, data,
}) => {
  const {
    getGlobal,
  } = useContext(ContentContext);

  const fleetArray = data;

  const [, setCheckArray] = useState(false);
  const [headerCol] = useState([
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'vincode' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'category' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'brand' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'mileage' }),
    },
  ]);
  const [fleetFormatted, setFleetFormatted] = useState([]);


  useEffect(() => {
    if (fleetArray.length > 0) {
      const obj = [];
      fleetArray.forEach((element) => {
        const childObj = [];

        childObj.push({ label: <List__content uppercase>{element.registrationNumber}</List__content> });
        // childObj.push({ label: <List__content capitalize>{element.countryOfRegistration}</List__content> });
        childObj.push({ label: <List__content capitalize>{getGlobal({ option: 'country', field: element.countryOfRegistration }) || element.countryOfRegistration}</List__content> });
        childObj.push({ label: <List__content uppercase>{element.vin}</List__content> });
        childObj.push({ label: <List__content capitalize>{element.type}</List__content> });
        childObj.push({ label: <List__content capitalize>{element.brand}</List__content> });
        childObj.push({ label: <List__content>{sequelizeDate({ date: element.firstDate, format: 'd/m/y' })}</List__content> });
        childObj.push({ label: <List__content>{sequelizeMileage({ value: element.mileage })}</List__content> });

        obj.push(childObj);
      });

      setFleetFormatted(obj);
    }
  }, [fleetArray]);

  const createArrayToReturn = (value) => {
    const array = [];
    value.forEach((singleFleet) => {
      if (fleetArray && singleFleet && singleFleet.key && fleetArray[singleFleet.key]) {
        array.push(fleetArray[singleFleet.key]);
      }
    });
    return array || [];
  };

  const handleSelectLine = (value) => {
    setCheckArray(value);
    if (handleCheck) {
      handleCheck(createArrayToReturn(value));
    }
  };

  return (
    <Container style={style}>
      <List__table
        headerCol={headerCol}
        lineCol={fleetFormatted}
        selectedLine={handleSelectLine}
        square
        scrollEndEvent={handleLoadNewPage}
      />
      {
        fleetArray.loading && (
          <Loader__wrapper />
        )
      }
    </Container>
  );
};

export default Fleet__list;
