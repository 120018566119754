import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import t from 'theme';
import { ContentContext } from 'context/contentContext';
import marked from 'marked';

/**
 * import containers & components
 */
import { Transition__loader_controller } from 'components/transition/transition';
import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Grid__separator, Grid__card } from 'components/grid/grid';
import Content__externalLinks from 'components/content/externalLinks/content__externalLinks';
import Grid__ShapeBackground from 'components/grid/ShapeBackground/grid__ShapeBackground';
import { strapiConditions } from 'util/strapi/content.starpi';

const Wrapper = styled(Layout__wrapper)`
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 0;
`;

const Content = styled(Layout__content)`
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
`;

const ExternalLinks = styled(Content__externalLinks)`
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 50px;
`;

const TermsContainer = styled(Grid__card)`
  max-width: 1000px;
  width: 90%;
  padding: 65px;
  line-height: 1.4;
  p {
    margin-bottom: 20px;
  }
`;

/**
 * @name ConditionRepairApproval
 */

const ConditionRepairApproval = () => {
  const {
    loadPage, haveContentFor, getField,
  } = useContext(ContentContext);

  const loadContent = async () => {
    if (haveContentFor(strapiConditions).success === false) {
      await loadPage(strapiConditions);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (haveContentFor(strapiConditions).success === false) {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container width="100%">
      <Transition__loader_controller active={false} />
      <Wrapper>
        <Content>
          <MainContainer>
            <Grid__separator size="70px" />
            <Logo src={t.image.global.logoBlue} />
            <TermsContainer>
              <div dangerouslySetInnerHTML={{ __html: marked(getField({ base: strapiConditions, field: 'repairApproval' })) }} />
            </TermsContainer>
            <Grid__separator size="25px" />
            <Grid__separator size="75px" />
            <ExternalLinks />
            <Grid__separator size="25px" />
            <Grid__ShapeBackground />
          </MainContainer>
        </Content>
      </Wrapper>
    </Layout__container>
  );
};

export default ConditionRepairApproval;
