import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AppContext } from 'context/appContext';
import { ContentContext } from 'context/contentContext';
import t from 'theme';
import Cards__serviceCard from 'components/cards/serviceCard/cards__serviceCard';
import SingleAddServiceAlternate from 'containers/fleetView/addServices/singleAddService/addServices__singleAddServiceAlternate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSatelliteDish } from '@fortawesome/pro-regular-svg-icons';
import { formatApiRes } from 'util/function/formatApiRes.function';
import Message__sucess from 'components/message/sucess/message__sucess';
import { Grid__popup } from 'components/grid/grid';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { useHistory } from 'react-router-dom';
import { ROUTING } from 'util/constant/routing.constant';
import {
  addFleetServices, fleetHaveService, mailHaveService, registerWorkshopToLeadGeneration,
} from '../../../../helpers/api/routes/smartMaintenance';
import { strapiSmartMaitenanceService } from '../../../../util/strapi/content.starpi';


const Container = styled.div``;

const SucessPop = styled(Grid__popup)`
  display: flex;
  justify-content:center;
  align-items:center;
`;

const Service = ({ workshop }) => {
  const { getField, getFieldGroup } = useContext(ContentContext);
  const { getUser, user } = useContext(AppContext);
  const [popUpActive, setPopUpActive] = useState(false);
  const [successPop, setsuccessPop] = useState(false);
  const [alreadyHave, setalreadyHave] = useState(true);
  const [loading, setloading] = useState(false);
  const [loadingUser, setloadingUser] = useState(false);
  const history = useHistory();

  const checkhaveSmartMaintenance = async () => {
    const res = await fleetHaveService();

    const formated = formatApiRes(res);
    if (!formated.success) {
      const resMail = await mailHaveService({
        email: user.user.email,
      });
      const formatedMail = formatApiRes(resMail);
      setalreadyHave(formatedMail.success || false);
    } else {
      setalreadyHave(formated.success || false);
    }
  };

  const perfomSave = async () => {
    setloading(true);

    const addServices = await addFleetServices({
      userId: user.user.id,
      origin: 'fleet',
      source: 'fleet',
      status: 'sent-to-stratio',
      fleetCompanyName: user.user.companyName,
      fleetContact: `${user.user.lastName} ${user.user.firstName}`,
      fleetEmail: user.user.email,
      fleetPhoneNumber: user.user.phoneNumber,
    });
    if (addServices) {
      setloading(false);
      if (formatApiRes(addServices).success === true) {
        checkhaveSmartMaintenance();
        setPopUpActive(false);
        setloading(false);
        setsuccessPop(true);
      }
    } else {
      setloading(false);
    }
  };


  const handleSave = () => {
    perfomSave();
  };

  const handleCloseSucess = async () => {
    setloadingUser(true);
    const resUser = await getUser();
    if (resUser.success === true) {
      setloadingUser(false);
      setsuccessPop(false);
    }
  };

  const handleClose = async () => {
    setsuccessPop(false);
  };

  const handleDashboardSucess = async () => {
    setloadingUser(true);
    const resUser = await getUser();
    if (resUser.success === true) {
      setloadingUser(false);
      setsuccessPop(false);
      history.push(ROUTING.workshop.dashboardSmartMaintenance.path);
    }
  };

  useEffect(() => {
    if (popUpActive) {
      checkhaveSmartMaintenance();
    }
  }, [popUpActive]);

  const handleClickSubscribe = async () => {
    if (workshop) {
      setloading(true);
      const res = await registerWorkshopToLeadGeneration();
      if (formatApiRes(res).success === true) {
        setsuccessPop(true);
      }
      setloading(false);
    }
  };

  const renderSmartMaintenance__pop = () => (
    <SingleAddServiceAlternate
      workshop={workshop}
      clickSubscribe={handleClickSubscribe}
      popupActive={popUpActive}
      handleSave={handleSave}
      alreadyHave={alreadyHave}
      closeEvent={() => setPopUpActive(false)}
      loading={loading}
    />
  );

  return (
    <Container>
      {renderSmartMaintenance__pop()}
      <SucessPop active={successPop} closeEvent={handleCloseSucess}>
        {workshop ? (
          <Message__sucess
            icon={t.image.illu.check}
            title={getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'workshop_subscription_success', field: 'title' })}
            text={getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'workshop_subscription_success', field: 'content' })}
            buttonText={getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'workshop_subscription_success', field: 'button' })}
            buttonEvent={handleDashboardSucess}
          />
        ) : (
          <Message__sucess
            icon={t.image.illu.check}
            title={getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'fleet_subscription_success', field: 'title' })}
            text={getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'fleet_subscription_success', field: 'content' })}
            buttonText={getFieldGroup({ base: strapiSmartMaitenanceService, groupe: 'fleet_subscription_success', field: 'button' })}
            buttonEvent={handleClose}
          />
        )}
        {loadingUser ? <Loader__wrapper /> : false}
      </SucessPop>
      <Cards__serviceCard
        event={() => setPopUpActive(true)}
        imageUrl={t.image.service.smart_banner}
        icon={<FontAwesomeIcon icon={faSatelliteDish} color="#4D4D4D" />}
        title={getField({ base: strapiSmartMaitenanceService, field: 'service_title' })}
        description={
          workshop
            ? getField({ base: strapiSmartMaitenanceService, field: 'service_description_short_workshop' })
            : getField({ base: strapiSmartMaitenanceService, field: 'service_description_short' })
        }
        label={getField({ base: strapiSmartMaitenanceService, field: 'new_label' })}
      />
    </Container>
  );
};

export default Service;
