
import { API_URL } from '../Utile';
import { CallGet, CallPost } from '../Helpers';

export const getPublicWorkShopUserObligo = async ({ userId, tempUrl }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}users/public/userWorkshopObligo/${userId}/${tempUrl}`;

  const res = await CallGet(url, params, headers);

  return res;
};

export const updatePublicfleetUserInformationObligo = async (field) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = field;

  const url = `${API_URL}users/public/updateFleetUserObligo/${field.url}`;

  const res = await CallPost(url, params, headers);

  return res;
};


export const setObligoAmount = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}users/public/setObligoAmount/${params.url}`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const getPublicWorkShopUserGop = async ({ userId, tempUrl }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}users/public/userWorkshopGop/${userId}/${tempUrl}`;

  const res = await CallGet(url, params, headers);

  return res;
};
