export const smartMaintenanceMailStatus = {
  STRATIO: 'sent-to-stratio',
  FLEET: 'sent-to-fleet',
  REFUSED: 'refused-by-fleet',
};

export const SmartMaintenanceConstant = {
  origin: {
    fleet: 'Fleet',
    workshop: 'Workshop',
  },
  mailStatus: {
    [smartMaintenanceMailStatus.STRATIO]: 'sent to stratio',
    [smartMaintenanceMailStatus.FLEET]: 'Sent to fleet',
    [smartMaintenanceMailStatus.REFUSED]: 'Refused by fleet',
  },
  mailStatusProps: {
    [smartMaintenanceMailStatus.STRATIO]: 'done',
    [smartMaintenanceMailStatus.FLEET]: 'waiting',
    [smartMaintenanceMailStatus.REFUSED]: 'error',
  },
  salesStatus: {
    waiting: 'Ongoing',
    done: 'contracted',
  },
  salesStatusConstant: {
    ingoing: 'ingoing',
    stop: 'stop',
    cancel: 'cancel',
    done: 'done',
  },
};
