import React from 'react';
import styled from 'styled-components';
import t from 'theme';

const Label = styled.label`
  display: ${(props) => (props.children !== null ? 'inline-block' : 'none')};
  font-size: 14px;
  font-weight: normal;
  color: ${(props) => (props.current ? '#fff' : '#808080')};;
  margin-bottom: 6px;
  text-transform: uppercase;
  font-family: ${t.font.primary};
  transition: all ease 0.3s;
`;

const Require = styled.span`
  margin-left: 2px;
  color: #ff5c5c;
  font-size: 12px;
  position: relative;
`;

const Secondary = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: '#808080';
  font-family: ${t.font.primary};
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  text-transform: initial;
  display: block;
`;

const Form__label = ({
  style, children, className, require, current, labelSecondary,
}) => (
  <Label className={className} style={style} require={require} current={current}>
    {children}
    {require ? <Require>*</Require> : null}
    { Secondary ? (
      <Secondary>{labelSecondary}</Secondary>
    ) : null}
  </Label>
);

export default Form__label;
