
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Nav from 'components/global/nav';
import { ContentContext } from 'context/contentContext';

/**
 * import containers & components
 */
import { Transition__loader_controller } from 'components/transition/transition';
import { Layout__wrapper, Layout__content, Layout__container } from 'containers/layout/layout';
import { Grid__separator } from 'components/grid/grid';
import {
  Fleet__headerPage,
  Fleet__list,
  Fleet__upload,
  Fleet__insert,
} from 'containers/fleetView/fleet/fleet';
import {
  strapiStandartrepairService,
  strapiMyFleetPage,
  strapiFairPriceService,
} from 'util/strapi/content.starpi';
import { loadService, haveServiceContent } from 'util/function/loadContent.function';

const LayoutContent = styled(Layout__content)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

/**
 * @name MyFleet
 */

const MyFleet = () => {
  const { loadPage, haveContentFor } = useContext(ContentContext);
  const [uploadActive, setUploadActive] = useState(false);
  const [insertActive, setInsertActive] = useState(false);

  const loadContent = async () => {
    if (
      haveContentFor(strapiMyFleetPage).success === false
    ) {
      await loadPage(strapiMyFleetPage);
    }
    loadService({ haveFunc: haveContentFor, loadFunc: loadPage });
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (
    haveContentFor(strapiMyFleetPage).success === false
    || haveServiceContent({ haveFunc: haveContentFor }) === false
  ) {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container>
      <Nav active="myfleet" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <LayoutContent>
          {/* ------------------------- Page Header ------------------------ */}
          <Fleet__headerPage
            addVehiculeEvent={() => setInsertActive(true)}
            addVehiculeCsvEvent={() => setUploadActive(true)}
            basePage={strapiMyFleetPage}
          />
          <Grid__separator size="40px" />
          <Fleet__list
            basePage={strapiMyFleetPage}
            baseSrvFairPrice={strapiFairPriceService}
            baseSrvStandar={strapiStandartrepairService}
          />
          <Fleet__upload
            basePage={strapiMyFleetPage}
            uploadActive={uploadActive}
            closeEvent={() => setUploadActive(false)}
          />
          <Fleet__insert
            basePage={strapiMyFleetPage}
            uploadActive={insertActive}
            closeEvent={() => setInsertActive(false)}
          />
        </LayoutContent>
      </Layout__wrapper>
    </Layout__container>
  );
};

export default MyFleet;
