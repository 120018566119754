export const getUserWorkshop = (user) => {
  if (user && user.user && user.user.UserWorkshop) {
    return {
      success: true,
      data: user.user.UserWorkshop,
    };
  }
  return {
    success: false,
    data: {},
  };
};
