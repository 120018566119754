
import React, { useContext } from 'react';
import styled from 'styled-components';

import { List__table, List__content } from 'components/list/list';
import { Grid__card } from 'components/grid/grid';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';

import Content__tag from 'components/content/tag/content__tag';
import { ContentContext } from 'context/contentContext';

import { sequelizeDate } from 'util/date.util';
import { parseStatus } from 'util/conditions/statusProps.util';
import { strapiFairPriceService, strapiServiceHeader } from 'util/strapi/content.starpi';
import t from 'theme';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { getFormService } from 'util/function/getter';


const Container = styled.div`
  height: 100%;
  min-height: 400px;
`;

const ListCardContainer = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'center' })};
  height: 40px;
  margin-bottom: 30px;
`;

const Card = styled(Grid__card)`
  height: 100%;
  position: relative;
`;

const Table = styled(List__table)`
  height: calc(100% - 70px);
`;

const DashboardFairPrice__historic = ({
  style, tableEvent, loadingData, data, scrollEndEvent, loadingNewPage,
}) => {
  const { getFieldGroup, getGlobal, getField } = useContext(ContentContext);

  const fairPrice = data;
  const headerCol = [
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'id_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'date_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'workshop' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'status_header' }),
    },
  ];

  const renderWorkshopName = (singleService) => {
    if (singleService.paymentguarantee) {
      if (singleService.paymentguarantee && singleService.paymentguarantee.UserWorkshop && singleService.paymentguarantee.UserWorkshop.UserWorkshopAlias) {
        return singleService.paymentguarantee.UserWorkshop.UserWorkshopAlias.companyName;
      }
    } else if (singleService.priority90) {
      if (singleService.priority90 && singleService.priority90.UserWorkshop && singleService.priority90.UserWorkshop.UserWorkshopAlias) {
        return singleService.priority90.UserWorkshop.UserWorkshopAlias.companyName;
      }
    } else if (singleService.repairApproval) {
      if (singleService.repairApproval && singleService.repairApproval.UserWorkshop && singleService.repairApproval.UserWorkshop.UserWorkshopAlias) {
        return singleService.repairApproval.UserWorkshop.UserWorkshopAlias.companyName;
      }
    } else if (singleService.standardRepair) {
      if (singleService.standardRepair && singleService.standardRepair.UserWorkshop && singleService.standardRepair.UserWorkshop.UserWorkshopAlias) {
        return singleService.standardRepair.UserWorkshop.UserWorkshopAlias.companyName;
      }
    }

    return '';
  };

  const renderList = () => {
    const orderArray = fairPrice;
    const arrayOfLine = [];

    orderArray.forEach((element) => {
      arrayOfLine.push(
        [
          {
            component: <List__content uppercase>{getFormService(element, 'uid')}</List__content>,
            type: 'node',
          },
          {
            component: (
              <List__content>
                {sequelizeDate({ date: getFormService(element, 'createdAt'), format: 'd/m/y' })}
              </List__content>
            ),
            type: 'node',
          },
          {
            component: <List__content uppercase>{renderWorkshopName(element)}</List__content>,
            type: 'node',
          },
          {
            component: (
              <Content__tag
                content={getFieldGroup({
                  base: strapiFairPriceService,
                  groupe: 'status',
                  field: getFormService(element, 'status'),
                })}
                {...parseStatus({ status: getFormService(element, 'status') })}
              />
            ),
            type: 'node',
          },
        ],
      );
    });

    return arrayOfLine;
  };

  const handleLineClick = (vehicle) => {
    tableEvent(vehicle, fairPrice[vehicle.key]);
  };

  return (
    <Container style={style}>
      <Card padding="30px">
        <ListCardContainer>
          <Content__cardTitle content={getField({ base: strapiFairPriceService, field: 'dashboard_historic_title' })} />
        </ListCardContainer>
        <Table
          headerCol={headerCol}
          lineCol={data ? renderList() : []}
          noSelect
          lineEvent={handleLineClick}
          maxHeight="100%"
          scrollEndEvent={scrollEndEvent}
        />
        {
          loadingNewPage && (
            <Loader__wrapper />
          )
        }
        {loadingData && (<Loader__wrapper />)}
      </Card>
    </Container>
  );
};

export default DashboardFairPrice__historic;
