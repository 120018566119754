
import React, { useContext, useEffect } from 'react';
import Nav from 'components/global/nav';
import { ContentContext } from 'context/contentContext';

/**
 * import containers & components
 */

import Layout__container from 'containers/layout/container/layout__container';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Transition__loader_controller } from 'components/transition/transition';
import { strapiMapPage } from 'util/strapi/content.starpi';
import Map__view from 'containers/fleetView/map/view/map__view';
import { config as smartMaintenanceConfig } from 'util/constant/leadGeneration/smartMaintenance.config';
import { config as alertGasoilConfig } from 'util/constant/leadGeneration/alertGasoil.config';
import { config as proFleetAssistConfig } from 'util/constant/leadGeneration/proFleetAssist.config';
import { config as tpmsConfig } from 'util/constant/leadGeneration/tpms.config';
/**
 * @name Map
 */

const Map = () => {
  const { loadPage, haveContentFor } = useContext(ContentContext);


  const loadContent = async () => {
    if (haveContentFor(strapiMapPage).success === false) {
      await loadPage(strapiMapPage);
    }
    if (haveContentFor(smartMaintenanceConfig.strapiPage).success === false) {
      await loadPage(smartMaintenanceConfig.strapiPage);
    }
    if (haveContentFor(alertGasoilConfig.strapiPage).success === false) {
      await loadPage(alertGasoilConfig.strapiPage);
    }
    if (haveContentFor(proFleetAssistConfig.strapiPage).success === false) {
      await loadPage(proFleetAssistConfig.strapiPage);
    }
    if (haveContentFor(tpmsConfig.strapiPage).success === false) {
      await loadPage(tpmsConfig.strapiPage);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (haveContentFor(strapiMapPage).success === false || haveContentFor(smartMaintenanceConfig.strapiPage).success === false || haveContentFor(alertGasoilConfig.strapiPage).success === false || haveContentFor(proFleetAssistConfig.strapiPage).success === false || haveContentFor(tpmsConfig.strapiPage).success === false) {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container>
      <Nav active="map" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <Map__view />
      </Layout__wrapper>
    </Layout__container>
  );
};

export default Map;
