
import React from 'react';
import styled from 'styled-components';

/**
 * import containers & components
 */

/**
 * styled components
 */

const Container = styled.div`
  background-color: ${(props) => props.background || 'transparent'};
  padding: 0 40px;
  min-height: 100vh;
  width: 100%;
  padding-top: 40px;
`;

/**
 * Layout content is used for inner page content
 */

const Layout__content = ({
  style, children, background, className,
}) => (
  <Container
    style={style}
    background={background}
    className={className}
  >
    {children}
  </Container>
);

export default Layout__content;
