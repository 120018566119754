/* eslint-disable react/jsx-props-no-multi-spaces */
import React, {
  useContext, useState, useEffect, Fragment, useRef,
} from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import styles from 'components/form/map/mapsStyle';
import { AppContext } from 'context/appContext';
import t from 'theme';
import { WorkshopContext } from 'context/fleetUser/workshopContext';
import './appointment__map.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { slugify } from 'helpers/js/Utile';
import { Grid__separator } from 'components/grid/grid/';
import {
  faLocationArrow, faPlus, faMinus, faSearch, faWarehouse, faMapMarkerAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { strapiMapPage } from 'util/strapi/content.starpi';
import { ContentContext } from 'context/contentContext';
import Button__primary from 'components/button/primary/button__primary';
import Cards__smallMapCard from 'components/cards/mapCard/cards__mapCard_small';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete/dist/GooglePlacesAutocomplete';
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { fitZoomToMarker } from './Utils';

const Container = styled.div`
  width: 100%;
  height: 45vh;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
`;

const MapContainer = styled.div`
  width: 100%;
  height:100%;
  overflow: hidden;
  position: relative;
`;

const Map = styled(GoogleMapReact)`
`;

const Marker = styled.div`
  display: block;
  width: 30px;
  height: 30px;
  background-color: ${t.color.cyan};
  ${t.mixins.flex()};
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 100;
  position: absolute;
`;

const MarkerSearch = styled.div`
  display: block;
  width: 30px;
  height: 30px;
  background-color: #E74C3C;
  ${t.mixins.flex()};
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 100;
  position: absolute;
`;

const MarkerChild = styled.div`
  display: block;
  width: 13px;
  height: 13px;
  background-color: white;
  border-radius: 50%;
`;

const SearchContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 40px;
  width: 300px;
  z-index: 99999;
`;

const WorkshopMarker = styled.img`
  width: 44px;
  ${t.mixins.flex()};
  transform: translate(-50%, -100%);
  transition: .2s ease transform;
  transform-origin: bottom center;
  will-change: transform;
  &:hover {
    transform: translate(-50%, -100%) scale(1.1);
  }
`;

const Button__container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

const SearchInput = styled.div`
  width: 100%;
  height: 40px;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  position: relative;
`;

const IconSearch = styled(FontAwesomeIcon)`
  font-size: 16px;
  position: absolute;
  top: 13px;
  left: 12px;
  z-index: 100;
  color: #adadad;
`;

const TextField = styled.input`
  font-size: 16px;
  font-weight: normal;
  color: #191919;
  border: 1px solid #ececec;
  border-radius: 10px;
  padding-left: 35px;
  height: 42px !important;
  line-height: 42px;
  background-color: #fff;
  width: 100%;
  position: relative;
  &:focus {
    outline:0;
  }
  ::-ms-input-placeholder {
    color: #adadad;
  }
  :-ms-input-placeholder {
    color: #adadad;
  }
  ::placeholder {
    color: #adadad;
  }
`;

const SuggestionContainer = styled.div`
  width: 100%;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-top: 25px;
  padding-bottom: 10px;
  overflow: scroll;
  max-height: 30vh;
  margin-top: 8px;
  z-index: 999999;
`;

const Suggest = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #4D4D4D;
  line-height: 1.4;
  cursor: pointer;
  &:hover {
    background-color: #ececec;
  }
`;

const SuggestNoResult = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #B3B3B4;
  margin-left: 15px;
  margin-top: 20px;
  opacity: 0.8;
`;

const ZoomControl = styled.div`
  position: absolute;
  right: 40px;
  bottom: 40px;
  display: flex;
  flex-direction: column;
`;

const ZoomButton = styled.a`
  line-height: 40px;
  width: 40px;
  height: 40px;
  background: #01B5E2;
  color: #fff;
  z-index: 9999;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
  text-align: center;
  transition: .3s ease all;
  &:hover {
    color: #01B5E2;
    background: #fff;
  }
`;

const CenterButton = styled.a`
  line-height: 40px;
  position: absolute;
  right: 40px;
  bottom: 150px;
  width: 40px;
  height: 40px;
  background: #01B5E2;
  color: #fff;
  z-index: 9999;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
  text-align: center;
  transition: .3s ease all;
  &:hover {
    color: #01B5E2;
    background: #fff;
  }
`;

const CenterIcon = styled(FontAwesomeIcon)`
  text-align: center;
  font-size: 18px;
`;

const SuggestHeading = styled.div`
  display: flex;
  margin-left: 15px;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const SuggestHeading__icon = styled(FontAwesomeIcon)`
  width: 15px;
  height: auto;
  font-size: 12px;
  color: #B3B3B4;
  margin-right: 10px;
`;

const SuggestHeading__title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #B3B3B4;
`;


const Appointment__map = ({
  style, saveEvent, prevEvent, basePage,
}) => {
  const { user } = useContext(AppContext);
  const { getField, getGlobal, getFieldGroup } = useContext(ContentContext);
  const [userCoord] = useState({
    lat: parseFloat(user.user.lat) || 48.5,
    lng: parseFloat(user.user.lng) || 48.5,
    set: false,
    reset: false,
  });
  const [centerCoord, setCenterCoord] = useState({
    lat: 47.384318194075 || 48.5,
    lng: -99.505471202645 || 48.5,
    set: false,
    reset: false,
  });
  const [zoom, setZoom] = useState(8);
  const [mapProps, setMapProps] = useState(undefined);
  const [searchValue, setsearchValue] = useState('');
  const { getAllWorkshop, workshop } = useContext(WorkshopContext);
  const [workshopinfo, setWorkshopinfo] = useState(false);
  const [activeMarker, setactiveMarker] = useState(false);
  const [workshopSearch, setworkshopSearch] = useState(false);
  const [renderSearch, setrenderSearch] = useState(true);
  const [markerSearch, setMarkerSearch] = useState({ active: false, lat: 0, lng: 0 });
  const [buttonOpen, setButtonOpen] = useState(false);
  const [cardOpen, setCardOpen] = useState(false);
  const fieldRef = useRef(null);
  const suggestContainerRef = useRef(null);
  const [showBase, setshowBase] = useState(false);

  const onMarkerClick = (key) => {
    setWorkshopinfo(workshop[key]);
    setButtonOpen(true);
    setCardOpen(true);
    setactiveMarker(key);
    setCenterCoord({
      lat: parseFloat(workshop[key].lat), lng: parseFloat(workshop[key].lng), set: false, reset: false,
    });
  };

  useEffect(() => {
    if (workshop === null) {
      getAllWorkshop();
    }
  }, [workshop]);

  const asSearchResult = (el) => {
    if (el && searchValue) {
      const value = slugify(searchValue.toLowerCase());
      const city = slugify(el.city.toLowerCase());
      const region = slugify(el.region.toLowerCase());
      const adress = slugify(el.adress.toLowerCase());
      const companyName = slugify(el.companyName.toLowerCase());
      if (city.includes(value) || region.includes(value) || adress.includes(value) || companyName.includes(value)) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (searchValue) {
      const res = workshop.filter((el) => asSearchResult(el));
      setworkshopSearch(res);
    }
  }, [searchValue]);


  const handleInternalWorkshop = (workshopSuggestion) => {
    const index = workshop.findIndex((el) => el.id === workshopSuggestion.id);
    setMarkerSearch({
      active: false,
      lat: 0,
      lng: 0,
    });
    onMarkerClick(index);
    setsearchValue('');
    setZoom(15);
    setworkshopSearch(false);
    setrenderSearch(false);
  };


  const renderWorkshopList = () => {
    if (workshopSearch && workshopSearch.length > 0) {
      return workshopSearch.map((suggestion, key) => (
        <Suggest
          key={key}
          onClick={() => handleInternalWorkshop(suggestion, key)}
        >
          {suggestion.companyName}
        </Suggest>
      ));
    }
    return (
      <SuggestNoResult>
        {getField({ base: strapiMapPage, field: 'no_result' })}
      </SuggestNoResult>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setrenderSearch(true);
    }, 100);
  }, [renderSearch]);

  useEffect(() => {
    if (centerCoord.reset === false) {
      setCenterCoord((prevState) => ({
        lat: prevState.lat + 0.00010,
        lng: prevState.lng + 0.00010,
        set: prevState.set,
        reset: true,
      }));
    }
  }, [centerCoord]);

  const handleCenterMap = () => {
    setCenterCoord({
      lat: userCoord.lat, lng: userCoord.lng, set: false, reset: false,
    });
  };

  const zoomIn = (e) => {
    e.preventDefault();
    const tempZoom = zoom;
    setZoom(tempZoom + 0.7);
  };

  const zoomOut = (e) => {
    e.preventDefault();
    const tempZoom = zoom;
    setZoom(tempZoom - 0.7);
  };

  const handleClose = () => {
    if (cardOpen) {
      setCardOpen(false);
    } else {
      setCardOpen(true);
    }
  };

  const isClosed = (amStart, pmStart) => {
    const closed = {
      am: false,
      pm: false,
    };
    if (!amStart) {
      closed.am = true;
    }
    if (!pmStart) {
      closed.pm = true;
    }

    return closed;
  };


  const renderHours = () => {
    const array = [
      {
        day: getField({ base: strapiMapPage, field: 'monday' }),
        amStart: workshopinfo.UserWorkshop.mondayAmStart,
        amEnd: workshopinfo.UserWorkshop.mondayAmEnd,
        pmStart: workshopinfo.UserWorkshop.mondayPmStart,
        pmEnd: workshopinfo.UserWorkshop.mondayPmEnd,
        closed: isClosed(workshopinfo.UserWorkshop.mondayAmStart, workshopinfo.UserWorkshop.mondayPmStart),
      },
      {
        day: getField({ base: strapiMapPage, field: 'tuesday' }),
        amStart: workshopinfo.UserWorkshop.tuesdayAmStart,
        amEnd: workshopinfo.UserWorkshop.tuesdayAmEnd,
        pmStart: workshopinfo.UserWorkshop.tuesdayPmStart,
        pmEnd: workshopinfo.UserWorkshop.tuesdayPmEnd,
        closed: isClosed(workshopinfo.UserWorkshop.tuesdayAmStart, workshopinfo.UserWorkshop.tuesdayPmStart),
      },
      {
        day: getField({ base: strapiMapPage, field: 'wednesday' }),
        amStart: workshopinfo.UserWorkshop.wednesdayAmStart,
        amEnd: workshopinfo.UserWorkshop.wednesdayAmEnd,
        pmStart: workshopinfo.UserWorkshop.wednesdayPmStart,
        pmEnd: workshopinfo.UserWorkshop.wednesdayPmEnd,
        closed: isClosed(workshopinfo.UserWorkshop.wednesdayAmStart, workshopinfo.UserWorkshop.wednesdayPmStart),
      },
      {
        day: getField({ base: strapiMapPage, field: 'thursday' }),
        amStart: workshopinfo.UserWorkshop.thursdayAmStart,
        amEnd: workshopinfo.UserWorkshop.thursdayAmEnd,
        pmStart: workshopinfo.UserWorkshop.thursdayPmStart,
        pmEnd: workshopinfo.UserWorkshop.thursdayPmEnd,
        closed: isClosed(workshopinfo.UserWorkshop.thursdayAmStart, workshopinfo.UserWorkshop.thursdayPmStart),
      },
      {
        day: getField({ base: strapiMapPage, field: 'friday' }),
        amStart: workshopinfo.UserWorkshop.fridayAmStart,
        amEnd: workshopinfo.UserWorkshop.fridayAmEnd,
        pmStart: workshopinfo.UserWorkshop.fridayPmStart,
        pmEnd: workshopinfo.UserWorkshop.fridayPmEnd,
        closed: isClosed(workshopinfo.UserWorkshop.fridayAmStart, workshopinfo.UserWorkshop.fridayPmStart),
      },
      {
        day: getField({ base: strapiMapPage, field: 'saturday' }),
        amStart: workshopinfo.UserWorkshop.saturdayAmStart,
        amEnd: workshopinfo.UserWorkshop.saturdayAmEnd,
        pmStart: workshopinfo.UserWorkshop.saturdayPmStart,
        pmEnd: workshopinfo.UserWorkshop.saturdayPmEnd,
        closed: isClosed(workshopinfo.UserWorkshop.saturdayAmStart, workshopinfo.UserWorkshop.saturdayPmStart),
      },
      {
        day: getField({ base: strapiMapPage, field: 'sunday' }),
        amStart: workshopinfo.UserWorkshop.sundayAmStart,
        amEnd: workshopinfo.UserWorkshop.sundayAmEnd,
        pmStart: workshopinfo.UserWorkshop.sundayPmStart,
        pmEnd: workshopinfo.UserWorkshop.sundayPmEnd,
        closed: isClosed(workshopinfo.UserWorkshop.sundayAmStart, workshopinfo.UserWorkshop.sundayPmStart),
      },
    ];

    return array;
  };

  useEffect(() => {
    if (userCoord && workshop && mapProps) {
      fitZoomToMarker(mapProps, workshop, {
        lat: userCoord.lat,
        lng: userCoord.lng,
      });
    }
  }, [userCoord, workshop, mapProps]);


  const defaultSelect = () => {
    if (workshop && user && user.user && user.user.UserFleet && user.user.UserFleet.UserWorkshop) {
      const index = workshop.findIndex((el) => el.id === user.user.UserFleet.UserWorkshop.UserWorkshopAlias.id);
      setMarkerSearch({
        active: false,
        lat: 0,
        lng: 0,
      });
      onMarkerClick(index);
      setsearchValue('');
      setworkshopSearch(false);
    }
  };

  useEffect(() => {
    if (user && workshop) {
      defaultSelect();
    }
  }, [user, workshop]);

  const clickOnMap = () => {
    setrenderSearch(false);
    setsearchValue('');
    setworkshopSearch(false);
  };


  useEffect(() => {
    let observer = null;
    if (document.querySelector('.searchInput') && renderSearch) {
      observer = new MutationObserver((() => {
        if (document.querySelector('.searchInput').value && !document.querySelector('.loadingResult')) {
          if (document.querySelector('.SuggestionContainer')) {
            setshowBase(false);
          } else {
            setshowBase(true);
          }
        }
      }));

      observer.observe(document.querySelector('.searchCOntainer'), {
        attributes: false, childList: true, characterData: false, subtree: true,
      });
    } else if (observer) {
      observer.disconnect();
    }
  }, [renderSearch]);


  useEffect(() => {
    let observer = null;
    if (fieldRef && fieldRef.current && renderSearch) {
      observer = new MutationObserver(((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes') {
            setsearchValue(fieldRef.current.value);
          }
        });
      }));
      observer.observe(fieldRef.current, {
        attributes: true,
      });
    } else if (observer) {
      observer.disconnect();
    }
  }, [fieldRef, renderSearch]);


  useEffect(() => {
    setshowBase(false);
    setTimeout(() => {
      setrenderSearch(true);
    }, 100);
  }, [renderSearch]);

  const moveMap = ({ lat, lng }) => {
    setZoom(15);
    setshowBase(false);
    setCenterCoord({
      lat: parseFloat(lat), lng: parseFloat(lng), set: false, reset: false,
    });
    if (mapProps && workshop) {
      setMarkerSearch({
        active: true,
        lat,
        lng,
      });
      fitZoomToMarker(mapProps, workshop, {
        lat,
        lng,
      });
    }
    if (fieldRef && fieldRef.current) {
      fieldRef.current.value = null;
    }
  };

  const handleGoogleSelect = (e) => {
    geocodeByPlaceId(e.place_id)
      .then(async (results) => getLatLng(results[0])).then(({ lat, lng }) => {
        moveMap({ lat, lng });
      });
  };

  const renderWorkshopSearch = () => (
    <Fragment>
      <SuggestHeading>
        <SuggestHeading__icon icon={faWarehouse} />
        <SuggestHeading__title>{getField({ base: strapiMapPage, field: 'label_workshop' })}</SuggestHeading__title>
      </SuggestHeading>
      {renderWorkshopList()}
    </Fragment>
  );

  const renderBaseContainer = () => (
    <SuggestionContainer>
      {renderWorkshopSearch()}
      <Grid__separator size="25px" />
      <SuggestHeading>
        <SuggestHeading__icon icon={faMapMarkerAlt} />
        <SuggestHeading__title>{getField({ base: strapiMapPage, field: 'label_place' })}</SuggestHeading__title>
      </SuggestHeading>
      <SuggestNoResult>
        {getField({ base: strapiMapPage, field: 'no_result' })}
      </SuggestNoResult>
    </SuggestionContainer>
  );

  const renderSearchDom = () => (
    <>
      <SearchContainer className="searchCOntainer">
        <GooglePlacesAutocomplete
          onSelect={(e) => handleGoogleSelect(e)}
          initialValue=""
          placeholder={getField({ base: strapiMapPage, field: 'search_workshop' })}
          autocompletionRequest={{
          types: ['geocode'],
        }}

          renderInput={(props) => (
            <SearchInput style={style}>
              <IconSearch icon={faSearch} />
              <TextField
                placeholder={getField({ base: strapiMapPage, field: 'search_workshop' })}
                {...props}
                ref={fieldRef}
                className="searchInput"
              />
            </SearchInput>
        )}
          loader={<div className="loadingResult" />}
          renderSuggestions={(active, suggestions, onSelectSuggestion) => (
            <SuggestionContainer ref={suggestContainerRef} className="SuggestionContainer">
              {renderWorkshopSearch()}
              <Grid__separator size="25px" />
              <SuggestHeading>
                <SuggestHeading__icon icon={faMapMarkerAlt} />
                <SuggestHeading__title>Places</SuggestHeading__title>
              </SuggestHeading>
              {
              suggestions.map((suggestion, key) => (
                <Suggest
                  key={key}
                  className="suggestion"
                  onClick={(event) => onSelectSuggestion(suggestion, event)}
                >
                  {suggestion.description}
                </Suggest>
              ))
            }
            </SuggestionContainer>
        )}
        />
        {
        showBase && (
          renderBaseContainer()
        )
      }
      </SearchContainer>
    </>
  );
  return (
    <Container style={style}>
      <ZoomControl>
        <ZoomButton onClick={zoomIn}>
          <CenterIcon icon={faPlus} />
        </ZoomButton>
        <Grid__separator size="5px" />
        <ZoomButton onClick={zoomOut}>
          <CenterIcon icon={faMinus} />
        </ZoomButton>
      </ZoomControl>
      <CenterButton onClick={handleCenterMap}>
        <CenterIcon icon={faLocationArrow} />
      </CenterButton>
      {renderSearch ? (
        renderSearchDom()
      ) : (
        <SearchContainer>
          <SearchInput style={style}>
            <TextField
              placeholder={getField({ base: strapiMapPage, field: 'search_workshop' })}
              ref={fieldRef}
              className="searchInput"
            />
          </SearchInput>
        </SearchContainer>
      )}
      <MapContainer onClick={clickOnMap}>
        <Map
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API }}
          zoomControl={false}
          mapTypeControl={false}
          scaleControl={false}
          streetViewControl={false}
          rotateControl={false}
          fullscreenControl={false}

          options={{ styles }}
          zoom={zoom}
          onChange={(e) => {
            setZoom(e.zoom);
          }}
          onGoogleApiLoaded={(mapInformation) => setMapProps(mapInformation)}
          center={{
            lat: centerCoord.lat,
            lng: centerCoord.lng,
          }}
        >
          <Marker
            lat={userCoord.lat}
            lng={userCoord.lng}
          >
            <MarkerChild />
          </Marker>
          {
            markerSearch.active && (

              <MarkerSearch
                lat={markerSearch.lat}
                lng={markerSearch.lng}
              >
                <MarkerChild />
              </MarkerSearch>
            )
          }
          {
            workshop && workshop.map((item, key) => (
              <WorkshopMarker
                src={activeMarker === key ? t.image.global.workshopMarkerActive : t.image.global.workshopMarker}
                key={key}
                lat={item.lat}
                lng={item.lng}
                onClick={() => onMarkerClick(key)}
              />
            ))
          }
        </Map>
        <Cards__smallMapCard
          small
          buttonOpen={buttonOpen}
          event={handleClose}
          open={cardOpen}
          image={t.image.global.workshopImage}
          title={workshopinfo.companyName}
          addressLine1={workshopinfo.adress}
          addressLine2={`${workshopinfo.zipcode}, ${workshopinfo.city}`}
          addressLine3={workshopinfo.region}
          hours={workshopinfo ? renderHours() : null}
          contact={`${workshopinfo.firstName} ${workshopinfo.lastName}`}
          email={workshopinfo.email}
          phone={workshopinfo.phoneNumber}
          hourlyRate1={workshopinfo && workshopinfo.UserWorkshop.rate1}
          hourlyRate2={workshopinfo && workshopinfo.UserWorkshop.rate2}
          hourlyRate3={workshopinfo && workshopinfo.UserWorkshop.rate3}
          lat={workshopinfo && workshopinfo.lat}
          lng={workshopinfo && workshopinfo.lng}
          workshopinfo={workshopinfo}
        />
      </MapContainer>
      <Button__container>
        <Button__primary
          border
          blue
          event={prevEvent}
        >
          {getGlobal({ option: 'service', field: 'single_launch_prevbutton' })}
        </Button__primary>
        <Grid__separator width="25px" />
        <Button__primary
          noprevent
          blue
          event={() => saveEvent(workshopinfo)}
          disable={
            !workshopinfo
          }
        >
          {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'next_button' })}
        </Button__primary>
      </Button__container>
    </Container>
  );
};

export default Appointment__map;
