/**
 * Import Lib & assets
 */

import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import Button__primary from 'components/button/primary/button__primary';

import { AppContext } from '../../context/appContext';


const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: #C6EBF7;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 35px;
  padding-left: 35px;
`;

const Content = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #01B5E2;
`;

const Bold = styled.span`
  font-weight: 800;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 15px;
`;

const Banner = ({
  style, user,
}) => {
  const {
    logout,
  } = useContext(AppContext);
  return (
    <Container style={style}>
      <Content>
        <Icon icon={faExclamationTriangle} />
        Your are logged as:
        {' '}
        <Bold>{user}</Bold>
      </Content>
      <Button__primary
        withIcon
        noprevent
        icon={<FontAwesomeIcon icon={faSignOutAlt} />}
        event={logout}
      >
        Log out
      </Button__primary>
    </Container>
  );
};

export default Banner;
