
/**
   * Import Lib & assets
   */

import React from 'react';
import styled from 'styled-components';
import { Grid__card } from 'components/grid/grid';

const Container = styled(Grid__card)`
  width: 100%;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  transition: .3s ease-in all;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.1);
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
  color: #4D4D4D;
  margin-bottom: 15px;

`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Value = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #4D4D4D;
  margin-right: 5px;
`;

const Label = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #4D4D4D;
`;

const Counter = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  padding-right: 10px;
  padding-left: 10px;
  height: 35px;
  line-height: 35px;
  background: #E6F8FC;
  border-radius: 10px;
  font-weight: bold;
  font-size: 19px;
  color: #01B5E2;
  text-align: center;
`;


const Cards__obligoProvider = ({
  style, actionNeededCount, amount, event, title, label,
}) => (
  <Container style={style} event={event}>
    {actionNeededCount > 0 ? (
      <Counter>{actionNeededCount}</Counter>
    ) : null}
    <Title>{title}</Title>
    <Content>
      <Value>
        {amount}
      </Value>
      <Label>{label}</Label>
    </Content>
  </Container>
);

export default Cards__obligoProvider;
