
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import t from 'theme';
import { ContentContext } from 'context/contentContext';

/**
 * import containers & components
 */
import { useHistory } from 'react-router-dom';
import { Transition__loader_controller } from 'components/transition/transition';
import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Grid__separator, Grid__popup } from 'components/grid/grid';
import { ROUTING } from 'util/constant/routing.constant';
import { strapiRegister } from 'util/strapi/content.starpi';
import Message__sucess from 'components/message/sucess/message__sucess';
import Content__externalLinks from 'components/content/externalLinks/content__externalLinks';
import Content__signUpLink from 'components/content/signUpLink/content__signUpLink';
import Grid__ShapeBackground from 'components/grid/ShapeBackground/grid__ShapeBackground';
import Register__form from 'containers/register/form/register__form';

const Wrapper = styled(Layout__wrapper)`
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 0;
`;

const Content = styled(Layout__content)`
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
`;

const ExternalLinks = styled(Content__externalLinks)`
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 50px;
`;

const Pop = styled(Grid__popup)`
  justify-content: center;
  align-items: center;
  display: flex;
`;

/**
 * @name Register;
 */

const Register = () => {
  const {
    loadPage, haveContentFor, getFieldWithNavigatorLang,
  } = useContext(ContentContext);
  const [modal, setmodal] = useState(false);
  const history = useHistory();

  const loadContent = async () => {
    if (haveContentFor(strapiRegister).success === false) {
      await loadPage(strapiRegister);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (haveContentFor(strapiRegister).success === false) {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container width="100%">
      <Transition__loader_controller active={false} />
      <Wrapper>
        <Content>
          <MainContainer>
            <Grid__separator size="70px" />
            <Logo src={t.image.global.logoBlue} />
            <Register__form setmodal={() => setmodal(true)} />
            <Grid__separator size="25px" />
            <Content__signUpLink
              content={getFieldWithNavigatorLang({ base: strapiRegister, field: 'already_have_account' })}
              buttonContent={getFieldWithNavigatorLang({ base: strapiRegister, field: 'sign_in_button' })}
              link={ROUTING.fleet.login.path}
            />
            <Grid__separator size="75px" />
            <ExternalLinks />
            <Grid__separator size="25px" />
            <Grid__ShapeBackground />
          </MainContainer>
        </Content>
      </Wrapper>
      <Pop active={modal} closeEvent={() => history.push('/')}>
        <Message__sucess
          icon={t.image.illu.check}
          title={getFieldWithNavigatorLang({ base: strapiRegister, field: 'confirmation_title' })}
          text={getFieldWithNavigatorLang({ base: strapiRegister, field: 'confirmation_text' })}
          buttonText={getFieldWithNavigatorLang({ base: strapiRegister, field: 'confirmation_button' })}
          buttonEvent={() => history.push('/')}
        />
      </Pop>
    </Layout__container>
  );
};

export default Register;
