import React, { useContext } from 'react';
import styled from 'styled-components';
import t from 'theme';
import Cards__focusCard from 'components/cards/focusCard/cards__focusCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/pro-light-svg-icons';
import Content__labelText from 'components/content/labelText/content__labelText';
import { Grid__separator } from 'components/grid/grid';
import { ContentContext } from 'context/contentContext';

const Container = styled.div`
  width: 100%;
`;

const Card = styled(Cards__focusCard)`
  padding: 35px;
  display: block;
`;

const Icon = styled(FontAwesomeIcon)`
  font-weight: 300;
  font-size: 22px;
  margin-right: 10px;
  color: #4d4d4d;
`;

const Title = styled.span`
  font-weight: normal;
  font-size: 22px;
  color: #4d4d4d;
`;

const Row = styled.div`
  ${t.mixins.flex({ justify: 'space-between', direction: 'row' })}
`;

const Label = styled(Content__labelText)`
  color: #01b5e2;
`;

const Column = styled.div``;

const Cards__vehicleId = ({
  style,
  registrationNumber,
  vinCode,
  category,
  brand,
  country,
  fleet,
}) => {
  const { getGlobal } = useContext(ContentContext);

  return (
    <Container style={style}>
      <Card blue>
        <Icon icon={faTruck} />
        <Title>{getGlobal({ option: 'vehicleParameter', field: 'titleSelected' })}</Title>
        <Grid__separator size="30px" />
        <Row>
          <Column>
            <Label
              label={getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' })}
              content={registrationNumber}
            />
            <Grid__separator size="25px" />
            <Label
              label={getGlobal({ option: 'vehicleParameter', field: 'category' })}
              content={category}
            />
            <Grid__separator size="25px" />
            <Label
              label={getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' })}
              content={country}
            />
          </Column>
          <Column>
            <Label
              label={getGlobal({ option: 'vehicleParameter', field: 'vincode' })}
              content={vinCode}
            />
            <Grid__separator size="25px" />
            <Label
              label={getGlobal({ option: 'vehicleParameter', field: 'brand' })}
              content={brand}
            />
            <Grid__separator size="25px" />
            <Label
              label={getGlobal({ option: 'vehicleParameter', field: 'fleet' })}
              content={fleet}
            />
          </Column>
        </Row>
      </Card>
    </Container>
  );
};

export default Cards__vehicleId;
