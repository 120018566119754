
import {
  getVehicle,
  getServiceHistory,
  getAllUser,
  getActionNeedWorkshop,
} from 'helpers/api/routes/getData';

export const VehicleReq = async ({
  page, filter, limit, params,
}) => {
  const res = await getVehicle({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};

export const ServiceHistoryReq = async ({
  page, filter, limit, params,
}) => {
  const res = await getServiceHistory({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};

export const MyFleetUserReq = async ({
  page, filter, limit, params,
}) => {
  const res = await getAllUser({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};

export const GetActionWorkshopReq = async ({
  page, filter, limit, params,
}) => {
  const res = await getActionNeedWorkshop({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};
