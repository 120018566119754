
/**
   * Import Lib & assets
   */

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  width: auto;
  height: 33px;
  font-weight: normal;
  font-size: 14px;
  color: ${(props) => (props.red ? '#E74C3C' : props.orange ? '#FFAA29' : props.green ? '#2ECC71' : '#4d4d4d')};
  text-transform: ${(props) => (props.capitalize ? 'capitalize' : props.uppercase ? 'uppercase' : null)};
`;

const List__content = ({
  style,
  capitalize,
  uppercase,
  children,
  red,
  green,
  orange,
}) => (
  <Container
    style={style}
    capitalize={capitalize}
    uppercase={uppercase}
    red={red}
    orange={orange}
    green={green}
  >
    {children}
  </Container>
);

export default List__content;
