import {
  faShieldCheck, faHandshakeAlt, faStopwatch,
} from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';

const dateComparison = (a, b) => new Date(a) - new Date(b);

export const renderCasesIcon = (singleCase) => {
  const array = [];
  if (singleCase) {
    const start = moment(singleCase.createdAt);
    const services = [];
    let dateToCompare = null;
    let diff = 0;
    let started = false;
    if (singleCase.repairApprovals.length > 0) { services.push(singleCase.repairApprovals[0].createdAt); }
    if (singleCase.paymentguarantees.length > 0) { services.push(singleCase.paymentguarantees[0].createdAt); }
    services.sort(dateComparison);
    if (services.length > 0) {
      started = true;
      dateToCompare = moment(services[0]);
      diff = dateToCompare.diff(start, 'minutes');
    } else if (singleCase.status === 'closed') {
      started = true;
      dateToCompare = moment(singleCase.caseClosingDate);
      diff = dateToCompare.diff(start, 'minutes');
    } else {
      started = false;
    }
    array.push({
      icon: faStopwatch,
      red: started && diff > 90,
      orange: !started,
      green: started && diff <= 90,
    });

    if (singleCase.paymentguarantees && singleCase.paymentguarantees.length > 0) {
      const hasError = singleCase.paymentguarantees.find((el) => el.status === 'error');
      const hasDone = singleCase.paymentguarantees.find((el) => el.status === 'done');
      const hasWaiting = singleCase.paymentguarantees.find((el) => el.status === 'waiting');
      const hasAction = singleCase.paymentguarantees.find((el) => el.status === 'action');

      array.push({
        icon: faShieldCheck,
        red: !!hasError,
        blue: !!hasAction,
        orange: !!hasWaiting,
        green: !!hasDone,
      });
    }
    if (singleCase.repairApprovals && singleCase.repairApprovals.length > 0) {
      const hasError = singleCase.repairApprovals.find((el) => el.status === 'error');
      const hasDone = singleCase.repairApprovals.find((el) => el.status === 'done');
      const hasWaiting = singleCase.repairApprovals.find((el) => el.status === 'waiting');
      const hasAction = singleCase.repairApprovals.find((el) => el.status === 'action');

      array.push({
        icon: faHandshakeAlt,
        red: !!hasError,
        blue: !!hasAction,
        orange: !!hasWaiting,
        green: !!hasDone,
      });
    }
  }
  return array;
};
