import { API_URL } from '../Utile';
import { CallPost } from '../Helpers';

export const fairPriceUpdateTranslate = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/v2/translate/fairPrice/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const paymentGuaranteeUpdateTranslate = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/v2/translate/paymentGuarantee/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const priority90UpdateTranslate = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/v2/translate/priority90/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const repairApprovalUpdateTranslate = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/v2/translate/repairApproval/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const service24UpdateTranslate = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/v2/translate/service24/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const service24UpdateTranslate2 = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/v2/translate/service24_2/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const standardRepairUpdateTranslate = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/v2/translate/standardRepair/`;

  const res = await CallPost(url, params, headers);

  return res;
};


export const caseClientUpdateTranslate = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/v2/translate/case_client/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const caseCloseUpdateTranslate = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/v2/translate/case_close/`;

  const res = await CallPost(url, params, headers);

  return res;
};
