import React from 'react';
import {
  Document, Page, Text, View, StyleSheet, Image,
} from '@react-pdf/renderer';
import t from 'theme';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  section: {
    flexGrow: 1,
    margin: 10,
  },
  front: {
    marginBottom: 10,
    width: '80%',
    position: 'relative',
  },
  back: {
    width: '80%',
    position: 'relative',
  },
  image: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  containerBack: {
    position: 'absolute',
    top: 0,
    left: '0%',
    width: '100%;',
    height: '100%;',
    paddingTop: '14%',
    paddingBottom: '13%',
  },
  contentBack: {
    width: '100%;',
    height: '100%;',
    padding: '8px 50px',
    opacity: 0.7,
  },
  sectionBack: {
    width: '100%;',
    marginBottom: 10,
  },
  title: {
    fontSize: 15,
    fontWeight: 900,
    marginBottom: 5,
    fontFamily: 'Helvetica-Bold',
  },
  text: {
    fontSize: 13,
    marginBottom: 3,
    fontFamily: 'Helvetica',
  },
});


const MemberCard__pdf = ({
  companyName, companyAdress, companyNumber, companyMail, haveHomeBase,
  homeBaseName, homeBaseAdress, homeBaseNumber, homeBaseMail,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.front}>
          <Image style={styles.image} src={t.image.global.fleetCardFront} />
        </View>
        <View style={styles.back}>
          <Image style={styles.image} src={t.image.global.fleetCardBack} />
          <View style={styles.containerBack}>
            <View style={styles.contentBack}>
              <View style={styles.sectionBack}>
                <Text style={styles.title}>Company</Text>
                <Text style={styles.text}>{companyName}</Text>
                <Text style={styles.text}>{companyAdress}</Text>
                <Text style={styles.text}>{companyNumber}</Text>
                <Text style={styles.text}>{companyMail}</Text>
              </View>
              {
                haveHomeBase && (
                  <View style={styles.sectionBack}>
                    <Text style={styles.title}>Home based workshop</Text>
                    <Text style={styles.text}>{homeBaseName}</Text>
                    <Text style={styles.text}>{homeBaseAdress}</Text>
                    <Text style={styles.text}>{homeBaseNumber}</Text>
                    <Text style={styles.text}>{homeBaseMail}</Text>
                  </View>
                )
              }
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default MemberCard__pdf;
