
import {
  getSmartMaintenance,
  getAlertGasoil, getTpms,
  getProFleetAssist,
} from 'helpers/api/routes/getData';

export const SmartMaintenanceReq = async ({
  page, filter, limit, params,
}) => {
  const res = await getSmartMaintenance({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};

export const AlertGasoilReq = async ({
  page, filter, limit, params,
}) => {
  const res = await getAlertGasoil({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};

export const ProFleetAssistReq = async ({
  page, filter, limit, params,
}) => {
  const res = await getProFleetAssist({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};

export const TpmsReq = async ({
  page, filter, limit, params,
}) => {
  const res = await getTpms({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};
