
import React from 'react';
import Confirmation from '../../components/services/LeadGeneration/confirmation';
import { config } from '../../util/constant/leadGeneration/proFleetAssist.config';


/**
 * @name AlertGasoilConfirmation
 */

const AlertGasoilConfirmation = () => (
  <Confirmation config={config} />
);

export default AlertGasoilConfirmation;
