/**
 * Import Lib & assets
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import t from 'theme';

/**
 * import components
 */

import Form__label from 'components/form/label/form__label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

/**
 * styled components
 */

const Container = styled.div`
opacity: ${(props) => (props.disabled ? '0.4' : '1')};
pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
`;

const Select = styled.div`
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: red;
  width: 100%;
  height: auto;
`;

const SelectTitle = styled.p`
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: normal;
  color: #191919;
  height: 42px;
  color: ${(props) => (props.error ? t.color.red : !props.asValue ? `rgba(${t.mixins.toRgba(t.color.dark)},0.78)` : t.color.cyan)};
  border: ${({ error }) => (error ? `1px solid ${t.color.red}` : '1px solid #ececec')};
  box-shadow: ${({ error }) => (error ? `inset 0px 0px 0px 1px ${t.color.red};` : 'none')};
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  user-select: none;
  background-color: #f7f7f7;
  border-radius: 10px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${(props) => (props.error ? t.color.red : '4d4d4d')};
  position: absolute;
  right: 15px;
  top: 15px;
  transform: ${({ active }) => (active ? 'rotate(-90deg)' : 'rotate(0deg)')};
  transition: all ease 0.3s;
`;

const SelectWrapper = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  z-index: 15;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 200px;
  display: ${(props) => (props.active ? 'block' : 'none')};
  background: white;
  z-index: 100;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (!props.asValue ? `rgba(${t.mixins.toRgba(t.color.dark)},0.78)` : t.color.cyan)};
  border-radius: 10px;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
  overflow-y: scroll;
  &::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: #01b5e2 !important;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
  }
`;

const SelectItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  transition: all ease 0.3s;
  padding: 13px 15px !important;
  font-size: 16px;
  font-weight: normal;
  color: ${(props) => (props.active === true ? '#c8c8c8' : '#191919')};
  opacity: ${(props) => (props.disabled === true ? '0.3' : '1')};
  pointer-events: ${(props) => (props.disabled === true ? 'none' : 'all')};
  &:hover {
    background-color: ${(props) => (props.active === true ? '#fff' : '#ececec')};
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  cursor: default;
  z-index: 1;
  display: ${(props) => (props.active === true ? 'block' : 'none')};
`;

/**
 * select component is..
 */

const Form__select = ({
  style, event, value, labelStyle, label, className, combined, require, error, disabled,
}) => {
  const [active, setActive] = useState(false);
  const activeElement = value ? value.filter((el) => el.active === true)[0] : false;

  value = value.filter((obj) => obj.label !== null);
  value.sort((a, b) => (a.label && b.label && a.label.toString().localeCompare(b.label)));

  const handleOpenList = () => {
    const isActive = !active;
    setActive(isActive);
  };

  const resetField = () => {
    const newArray = value;

    newArray.forEach((element) => {
      element.active = false;
    });

    return newArray;
  };

  const handleClickOption = (e, key) => {
    const valueSelect = resetField();

    valueSelect[key].active = true;
    handleOpenList();

    event({ value: valueSelect, result: valueSelect[key] });
  };

  return (
    <Container style={style} className={className} disabled={disabled}>
      <Form__label style={labelStyle} require={require}>
        {label}
      </Form__label>
      <Select active={active} error>
        <SelectTitle
          onClick={() => handleOpenList()}
          active={active}
          combined={combined}
          className={error ? 'formError' : 'false'}
          error={error ? error.active : false}
        >
          {activeElement ? activeElement.label : ''}
          <Icon icon={faChevronDown} error={error ? error.active : null} />
        </SelectTitle>
        <Overlay active={active} onClick={() => handleOpenList()} />
        <SelectWrapper active={active}>
          {value.map((item, key) => (
            <SelectItem disabled={item.disabled} key={key} active={item.active} value={item.slug} onClick={(e) => handleClickOption(e, key)}>
              {item.label}
            </SelectItem>
          ))}
        </SelectWrapper>
      </Select>
    </Container>
  );
};

export default Form__select;
