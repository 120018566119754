import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Grid__separator } from 'components/grid/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/pro-solid-svg-icons';
import { ContentContext } from 'context/contentContext';
import Button__inline from 'components/button/inline/button__inline';
import { useTranslate } from 'components/data/translate/data__translate';

const Container = styled.div`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : null)};;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #a0a0a1;
`;

const Content = styled.div`
  font-weight: 600;
  line-height: 1.5;
  font-size: 16px;
  color: #4d4d4d;
`;

const TraductionIndicator = styled.div`
  ${theme.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'row' })}
`;

const SubLabel = styled.div`
  margin-right: 15px;
  font-weight: 600;
  font-size: 14px;
  color: #a0a0a1;
`;

const Content__labelTextarea = ({
  style,
  label,
  content,
  lang,
  convert,
  dataName,
  base,
  id,
  maxWidth,
}) => {
  const [showtranslated, setshowtranslated] = useState(true);
  const { getGlobal } = useContext(ContentContext);
  const [translate] = useTranslate({
    lang: convert,
    dataName,
    base,
    content,
    id,
    baseLang: lang,
  });
  const sourceLang = getGlobal({ option: 'language', field: lang });

  const handleToggle = () => {
    const value = showtranslated;
    setshowtranslated(!value);
  };
  return (
    <Container style={style} maxWidth={maxWidth}>
      <Label>{label}</Label>
      <Grid__separator size="10px" />
      <TraductionIndicator>
        {lang !== convert ? (
          <Fragment>
            <SubLabel>{`${getGlobal({ option: 'globalcontent', field: 'translation_label' })} ${sourceLang}`}</SubLabel>
            <Button__inline
              content={getGlobal({ option: 'globalcontent', field: 'translation_btn' })}
              icon={<FontAwesomeIcon icon={faLanguage} />}
              event={handleToggle}
            />
          </Fragment>
        ) : null}
      </TraductionIndicator>
      <Content>{translate && !translate.loading && showtranslated ? translate.data : content}</Content>
    </Container>
  );
};

export default Content__labelTextarea;
