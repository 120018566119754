
import React, {
  useEffect, useState, Fragment, useContext,
} from 'react';
import { AppContext } from 'context/appContext';
import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { Grid__separator } from 'components/grid/grid';
import Content__statusAnwser from 'components/content/statusAnwser/content__statusAnwser';
import {
  faTruck, faTools, faWarehouse, faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import { isWaiting, isError } from 'util/conditions/checkstatus.condition.js';
import Content__labelText from 'components/content/labelText/content__labelText';
import Content__labelTextarea from 'components/content/labelTextarea/content__labelTextarea';
import Content__serviceStatus from 'components/content/serviceStatus/content__serviceStatus';
import { GetSingleService } from 'helpers/api/routes/PaymentGuarentee';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import Content__tag from 'components/content/tag/content__tag';
import {
  getFleetInformation,
  getFleetUserInformation,
  getWorkshopInformation,
} from 'util/service/getDataFromService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { strapiPaymentGuaranteeService, strapiWorkshopDashboardPaymentGuarantee } from 'util/strapi/content.starpi';
import { parseStatus } from 'util/conditions/statusProps.util';
import { sequelizeDate, sequelizeDateTime } from 'util/date.util';
import { formatPrice } from 'util/format/price.format';
import { parseFile } from 'util/format/parseFile.format';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import Button__inline from 'components/button/inline/button__inline';
import { getWorkShopUser } from 'helpers/api/routes/User';
import {
  Header,
  Title,
  ItemContainer,
  Row,
  Popup,
} from 'containers/workshopView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import { SERVICENAME } from 'util/service/serviceName';
import Contract__pdf from 'components/contract/pdf/contract__pdf';

const Container = styled.div``;

const Column = styled.div``;

const ServiceStatusTitle = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
`;

const Button = styled.div`
  position: absolute;
  right: 45px;
  bottom: 45px;
`;


const DashboardPaymentGuarantee__singlePop = ({
  style, active, closeEvent, id,
}) => {
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(false);
  const [ashomebase, setashomebase] = useState(false);
  const { user } = useContext(AppContext);
  const {
    getFieldGroup, loadPage, haveContentFor, getField, getGlobal,
  } = useContext(ContentContext);

  const getWorkshop = async (serviceData) => {
    const res = await getWorkShopUser({ userId: serviceData.validateUser || serviceData.workshopId });
    if (res) {
      return res.data.data;
    }

    return false;
  };

  const renderPDf = () => (
    <Contract__pdf
      buttonText={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_downnload' })}
      loadingtext={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_download_active' })}
      name={`contract_gop_${data.uid}`}
      content={getFieldGroup({
        base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'contract_gop', field: 'contract_content',
      })}
      type="paymentGurantee"

      guarantor_company={ashomebase ? ashomebase.UserWorkshopAlias.companyName : ''}
      guarantor_address={`${ashomebase ? ashomebase.UserWorkshopAlias.adress : ''} ${ashomebase ? ashomebase.UserWorkshopAlias.city : ''}, ${ashomebase ? ashomebase.UserWorkshopAlias.zipcode : ''}`}
      guarantor_country={ashomebase ? ashomebase.UserWorkshopAlias.region : ''}
      guarantor_contact={ashomebase ? ashomebase.UserWorkshopAlias.email : ''}

      supervisor_name={`${ashomebase ? ashomebase.supervisorname : ''} ${ashomebase ? ashomebase.supervisorlastname : ''}`}
      supervisor_mail={ashomebase ? ashomebase.supervisormail : ''}
      supervisor_time={data.confirmationDate ? sequelizeDateTime({ date: data.confirmationDate, atContent: getGlobal({ option: 'globalcontent', field: 'at' }) }) : ''}

      workshop_company={getWorkshopInformation({ obj: data, key: 'companyName' })}
      workshop_address={`${getWorkshopInformation({ obj: data, key: 'adress' })} ${getWorkshopInformation({ obj: data, key: 'city' })}, ${getWorkshopInformation({ obj: data, key: 'zipcode' })}`}
      workshop_country={getWorkshopInformation({ obj: data, key: 'region' })}
      workshop_contact={getWorkshopInformation({ obj: data, key: 'email' })}

      amount={formatPrice(data.price)}

      fleet_company={getFleetUserInformation({ obj: data, key: 'companyName' })}
      fleet_address={getFleetUserInformation({ obj: data, key: 'adress' })}
      fleet_zip={getFleetUserInformation({ obj: data, key: 'zipcode' })}
      fleet_location={getFleetUserInformation({ obj: data, key: 'city' })}
      fleet_phone={getFleetUserInformation({ obj: data, key: 'phoneNumber' })}
      fleet_cmail={getFleetUserInformation({ obj: data, key: 'email' })}
      fleet_tva={getFleetUserInformation({ obj: data, key: 'vat' })}

      vehicle_tva={getFleetInformation({ obj: data, key: 'vin' })}
      vehicle_registration={getFleetInformation({ obj: data, key: 'registrationNumber' })}
      vehicle_type={getGlobal({ option: 'vehicletype', field: getFleetInformation({ obj: data, key: 'type' }) }) || ''}
      vehicle_brand={getFleetInformation({ obj: data, key: 'brand' })}
      vehicle_country={getGlobal({ option: 'country', field: getFleetInformation({ obj: data, key: 'countryOfRegistration' }) }) || ''}
      service_uid={data.uid}
    />
  );

  const getService = async () => {
    const res = await GetSingleService({ id });
    if (res) {
      const workshopRes = await getWorkshop(res.data.data);
      if (workshopRes) {
        setashomebase(workshopRes);
      }
      setdata(res.data.data);
      setloading(false);
    }
  };

  useEffect(() => {
    if (haveContentFor(strapiPaymentGuaranteeService).success === false) {
      loadPage(strapiPaymentGuaranteeService);
    }
  }, []);

  useEffect(() => {
    if (active === true) {
      setloading(true);
      getService();
    }
  }, [active]);

  const renderLoader = () => <Loader__wrapper />;

  if (
    haveContentFor(strapiPaymentGuaranteeService).success === false
  ) {
    return (
      <Container style={style}>
        <Popup active={active} closeEvent={closeEvent}>
          {renderLoader()}
        </Popup>
      </Container>
    );
  }

  const renderContent = () => (
    <Fragment>
      <Header>
        <Title>{getField({ base: strapiPaymentGuaranteeService, field: 'service_title' })}</Title>
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_id' })}
          value={data.uid}
        />
        <Grid__separator width="15px" />
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_date' })}
          value={sequelizeDate({ date: data.createdAt, format: 'd/m/y' })}
        />
      </Header>
      <Grid__separator size="80px" />
      <Content__serviceStatus
        tag={(
          <Content__tag
            border
            content={getFieldGroup({
              base: strapiPaymentGuaranteeService,
              groupe: 'status',
              field: data.status,
            })}
            {...parseStatus({ status: data.status })}
          />
        )}
        {...parseStatus({ status: data.status })}
      >
        {
          isError(data.status) ? (
            <div>
              <ServiceStatusTitle>
                {getField({ base: strapiPaymentGuaranteeService, field: 'error_text' })}
              </ServiceStatusTitle>
              <Grid__separator size="25px" />
              <Content__statusAnwser
                title={ashomebase ? ashomebase.UserWorkshopAlias.companyName : ''}
                subtitle1={`${ashomebase ? ashomebase.UserWorkshopAlias.adress : ''}`}
                subtitle2={`${ashomebase ? ashomebase.UserWorkshopAlias.city : ''}, ${ashomebase ? ashomebase.UserWorkshopAlias.ashomebase : ''}`}
                link1={`${ashomebase ? ashomebase.UserWorkshopAlias.phoneNumber : ''}`}
                link2={ashomebase ? ashomebase.UserWorkshopAlias.email : ''}
                link3={`${ashomebase ? ashomebase.UserWorkshopAlias.adress : ''} ${ashomebase ? ashomebase.UserWorkshopAlias.city : ''}, ${ashomebase ? ashomebase.UserWorkshopAlias.zipcode : ''}`}
              />
            </div>
          ) : isWaiting(data.status) ? (
            <div>
              <Grid__separator size="10px" />
              <ServiceStatusTitle>
                {getField({ base: strapiPaymentGuaranteeService, field: 'waiting_text' })}
              </ServiceStatusTitle>
              <Grid__separator size="25px" />
              <Content__statusAnwser
                title={ashomebase ? ashomebase.UserWorkshopAlias.companyName : ''}
                subtitle1={`${ashomebase ? ashomebase.UserWorkshopAlias.adress : ''}`}
                subtitle2={`${ashomebase ? ashomebase.UserWorkshopAlias.city : ''}, ${ashomebase ? ashomebase.UserWorkshopAlias.region : ''}`}
                link1={`+${ashomebase ? ashomebase.UserWorkshopAlias.phoneNumber : ''}`}
                link2={ashomebase ? ashomebase.UserWorkshopAlias.email : ''}
                link3={`${ashomebase ? ashomebase.UserWorkshopAlias.adress : ''} ${ashomebase ? ashomebase.UserWorkshopAlias.city : ''}, ${ashomebase ? ashomebase.UserWorkshopAlias.zipcode : ''}`}
              />
            </div>

          ) : (
            <div>
              <ServiceStatusTitle>
                {getField({ base: strapiPaymentGuaranteeService, field: 'response_text' })}
              </ServiceStatusTitle>
              <Grid__separator size="25px" />
              <Content__statusAnwser
                title={ashomebase ? ashomebase.UserWorkshopAlias.companyName : ''}
                subtitle1={`${ashomebase ? ashomebase.UserWorkshopAlias.adress : ''}`}
                subtitle2={`${ashomebase ? ashomebase.UserWorkshopAlias.city : ''}, ${ashomebase ? ashomebase.UserWorkshopAlias.region : ''}`}
                link1={`+${ashomebase ? ashomebase.UserWorkshopAlias.phoneNumber : ''}`}
                link2={ashomebase ? ashomebase.UserWorkshopAlias.email : ''}
                link3={`${ashomebase ? ashomebase.UserWorkshopAlias.adress : ''} ${ashomebase ? ashomebase.UserWorkshopAlias.city : ''}, ${ashomebase ? ashomebase.UserWorkshopAlias.zipcode : ''}`}
              />
              <Button>
                {renderPDf()}
              </Button>
            </div>
          )
        }
      </Content__serviceStatus>
      <Grid__separator size="100px" />
      <Row>
        <ItemContainer>
          <Content__sectionTitle
            line
            title={getField({ base: strapiPaymentGuaranteeService, field: 'vehicle_title' })}
            icon={<FontAwesomeIcon icon={faTruck} />}
          />
          <Row>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' })}
                content={getFleetInformation({ obj: data, key: 'registrationNumber' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'category' })}
                content={getGlobal({ option: 'vehicletype', field: getFleetInformation({ obj: data, key: 'type' }) }) || ''}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' })}
                content={getGlobal({ option: 'country', field: getFleetInformation({ obj: data, key: 'countryOfRegistration' }) }) || ''}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'mileage' })}
                content={getFleetInformation({ obj: data, key: 'mileage' })}
              />
            </Column>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'vincode' })}
                content={getFleetInformation({ obj: data, key: 'vin' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'brand' })}
                content={getFleetInformation({ obj: data, key: 'brand' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'fleet' })}
                content={getFleetUserInformation({ obj: data, key: 'companyName' })}
              />
            </Column>
          </Row>
        </ItemContainer>
        <ItemContainer>
          <Content__sectionTitle
            line
            title={getField({ base: strapiPaymentGuaranteeService, field: 'workshop_title' })}
            icon={<FontAwesomeIcon icon={faWarehouse} />}
          />
          <Row>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'name' })}
                content={getWorkshopInformation({ obj: data, key: 'companyName' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'address' })}
                content={getWorkshopInformation({ obj: data, key: 'adress' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })}
                content={`${getWorkshopInformation({ obj: data, key: 'phoneNumber' })}`}
              />
            </Column>
            <Column>
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'workshopInformation', field: 'country' })}
                content={getWorkshopInformation({ obj: data, key: 'region' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'city' })}
                content={getWorkshopInformation({ obj: data, key: 'city' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'zipcode' })}
                content={getWorkshopInformation({ obj: data, key: 'zipcode' })}
              />
            </Column>
          </Row>
        </ItemContainer>
      </Row>
      <Grid__separator size="100px" />
      <Content__sectionTitle
        line
        title={getField({ base: strapiPaymentGuaranteeService, field: 'repair_title' })}
        icon={<FontAwesomeIcon icon={faTools} />}
      />
      <Row>
        <Column style={{ width: '60%' }}>
          <Content__labelTextarea
            label={getField({ base: strapiPaymentGuaranteeService, field: 'repair_description' })}
            lang={data.textLang}
            convert={user.user.lang}
            content={data.description}
            dataName={SERVICENAME.paymentguarantee}
            base={data.translate}
            id={data.id}
          />
          <Grid__separator size="30px" />
          {
            data.file && (
              <Content__labelText
                label={getField({ base: strapiPaymentGuaranteeService, field: 'repair_file' })}
                content={(
                  <Button__inline
                    content={getField({ base: strapiPaymentGuaranteeService, field: 'repair_file_button' })}
                    icon={<FontAwesomeIcon icon={faDownload} />}
                    download
                    href={parseFile(data.file).url}
                  />
                )}
              />
            )
          }
        </Column>
        <Column style={{ width: '25%' }}>
          <Content__labelText
            label={getField({ base: strapiPaymentGuaranteeService, field: 'repair_price' })}
            content={formatPrice(data.price)}
          />
        </Column>
      </Row>
    </Fragment>
  );

  return (
    <Container style={style}>
      <Popup active={active} closeEvent={closeEvent}>
        {loading || !data ? renderLoader() : renderContent()}
      </Popup>
    </Container>
  );
};

export default DashboardPaymentGuarantee__singlePop;
