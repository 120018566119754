import React, { useContext, useEffect, useState } from 'react';
import Nav from 'components/global/nav';
import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Transition__loader_controller } from 'components/transition/transition';
import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';
import MyFleets__list from 'containers/workshopView/myFleets/list/myFleets__list';
import { Grid__separator } from 'components/grid/grid';
import { ContentContext } from 'context/contentContext';
import {
  strapiStandartrepairService,
  strapiRegister,
  strapiMyFleetPage, strapiFairPriceService, strapiWorkshopMyFleet, strapiWorkshopSingleVehicle,
} from 'util/strapi/content.starpi';
import MyFleets__singlePop from 'containers/workshopView/myFleets/singlePop/myFleets__singlePop';
import MyFleets__singleVehicle from 'containers/workshopView/myFleets/singlePop/myFleets__singleVehicle';

import { haveServiceContent, loadService } from 'util/function/loadContent.function';
import { AppContext } from 'context/appContext';
import { useData } from 'components/data/getter/data__getter';

/**
 * @name MyFleetss
 */
let pageNumber = 1;
const MyFleets = () => {
  const {
    loadPage, haveContentFor, getField,
  } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [currentVehicle, setCurrentVehicle] = useState(false);
  const [activePop, setactivePop] = useState(false);
  const [activeVehicle, setactiveVehicle] = useState(false);
  const [currentSingle, setcurrentSingle] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [fleetUser, refreshFleetUser, setFleetUser, setSearchFleetUser] = useData({
    params: {
      role: 'fleet',
      userWorkshopId: user.user.UserWorkshop.id,
    },
    service: 'myFleetUser',
    type: 'myFleetUser',
  });
  const loadContent = async () => {
    if (haveContentFor(strapiWorkshopMyFleet).success === false) {
      await loadPage(strapiWorkshopMyFleet);
    }
    if (
      haveContentFor(strapiWorkshopSingleVehicle).success === false
    ) {
      await loadPage(strapiWorkshopSingleVehicle);
    }

    if (
      haveContentFor(strapiRegister).success === false
    ) {
      await loadPage(strapiRegister);
    }

    loadService({ haveFunc: haveContentFor, loadFunc: loadPage });
  };
  useEffect(() => {
    loadContent();
    return () => {
      pageNumber = 1;
    };
  }, []);

  if (
    haveContentFor(strapiWorkshopMyFleet).success === false
    || haveContentFor(strapiWorkshopSingleVehicle).success === false
    || haveContentFor(strapiRegister).success === false
    || haveServiceContent({ haveFunc: haveContentFor }) === false
  ) {
    return <Transition__loader_controller active />;
  }

  const handleLineClick = (fleets) => {
    setCurrentVehicle(fleets);
    setactivePop(true);
  };

  const handleSelectVehicle = (vehicle) => {
    setactiveVehicle(true);
    setcurrentSingle(vehicle);
  };

  const handleCloseSingle = () => {
    setactiveVehicle(false);
  };

  const HandleClose = () => {
    setactivePop(false);
  };
  const handleLoadNewPage = () => {
    if (fleetUser.data && !fleetUser.loading && pageNumber + 1 > 1 && fleetUser.data.length < fleetUser.count) {
      pageNumber += 1;
      setFleetUser(pageNumber, fleetUser.data);
    }
  };
  return (
    <Layout__container>
      <Nav workshop active="myFleets" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <Layout__content>
          <Content__pageTitle content={getField({ base: strapiWorkshopMyFleet, field: 'title' })} />
          <Grid__separator size="40px" />
          <MyFleets__list
            handleSelectFleet={handleLineClick}
            data={fleetUser ? fleetUser.data : []}
            setSearch={(value) => {
              setSearchFleetUser(value);
            }}
            handleLoadNewPage={handleLoadNewPage}
            loading={fleetUser.loading}
          />
        </Layout__content>
        <MyFleets__singlePop
          popActive={activePop}
          closePop={HandleClose}
          currentFleetUser={currentVehicle}
          selectVehicle={handleSelectVehicle}
        />
        <MyFleets__singleVehicle
          active={activeVehicle}
          vehicle={currentSingle}
          currentUser={currentVehicle}
          closePop={handleCloseSingle}
          basePage={strapiMyFleetPage}
          baseSrvFairPrice={strapiFairPriceService}
          baseSrvStandar={strapiStandartrepairService}
        />
      </Layout__wrapper>
    </Layout__container>
  );
};

export default MyFleets;
