
import React, {
  useEffect, useState, Fragment, useContext,
} from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { Grid__separator } from 'components/grid/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTruck,
} from '@fortawesome/pro-solid-svg-icons';
import Content__labelText from 'components/content/labelText/content__labelText';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { strapiRepairApprovalService } from 'util/strapi/content.starpi';
import { sequelizeDate } from 'util/date.util';

import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import {
  Header,
  Title,
  ItemContainer,
  Row,
  Popup,
} from 'containers/workshopView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import { parseStatus } from 'util/conditions/statusProps.util';
import { SmartMaintenanceConstant } from 'util/constant/smartMaintenance.constant';
import Content__tag from '../../../../content/tag/content__tag';


const Container = styled.div``;

const Column = styled.div``;

const ItemsRow = styled(ItemContainer)`
  width: 80%;
`;

const LeadGenerationWorkshopSingle = ({
  style, active, closeEvent, id, config,
}) => {
  const { getField, getFieldGroup } = useContext(ContentContext);
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(false);
  const {
    loadPage, haveContentFor, getGlobal,
  } = useContext(ContentContext);
  const getService = async () => {
    const res = await config.api.getSingleServiceById(id);
    if (res) {
      setdata(res.data.data);
      setloading(false);
    }
  };

  useEffect(() => {
    if (haveContentFor(strapiRepairApprovalService).success === false) {
      loadPage(strapiRepairApprovalService);
    }
  }, []);

  useEffect(() => {
    if (active === true) {
      setloading(true);
      getService();
    }
  }, [active]);

  const renderLoader = () => <Loader__wrapper />;

  if (
    haveContentFor(strapiRepairApprovalService).success === false
  ) {
    return (
      <Container style={style}>
        <Popup active={active} closeEvent={closeEvent}>
          {renderLoader()}
        </Popup>
      </Container>
    );
  }


  const handleCloseEvent = async () => {
    closeEvent();
  };

  const mailStatus = {
    [config.constant.mailStatus.DONE]: getFieldGroup({ base: config.strapiPage, groupe: config.constant.mailContent.strapiGroup, field: config.constant.mailContent.done }),
    [config.constant.mailStatus.WAITING]: getFieldGroup({ base: config.strapiPage, groupe: config.constant.mailContent.strapiGroup, field: config.constant.mailContent.waiting }),
    [config.constant.mailStatus.ERROR]: getFieldGroup({ base: config.strapiPage, groupe: config.constant.mailContent.strapiGroup, field: config.constant.mailContent.error }),
  };

  const renderContent = () => (
    <Fragment>
      <Header>
        <Title>{getField({ base: config.strapiPage, field: config.content.single_service_title })}</Title>
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_id' })}
          value={data.uid}
        />
        <Grid__separator width="15px" />
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_date' })}
          value={sequelizeDate({ date: data.createdAt, format: 'd/m/y' })}
        />
      </Header>
      <Grid__separator size="100px" />
      <Row>
        <ItemsRow>
          <Content__sectionTitle
            line
            title={getField({ base: config.strapiPage, field: config.content.single_service_label })}
            icon={<FontAwesomeIcon icon={faTruck} />}
          />
          <Row>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'country' })}
                content={data.User.region}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'workshopInformation', field: 'fleet_contact' })}
                content={data.fleetContact}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })}
                content={data.fleetPhoneNumber}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getField({ base: config.strapiPage, field: config.content.single_service_mail_status })}
                content={(
                  <Content__tag
                    content={mailStatus[data.status]}
                    {...parseStatus({
                      hand: true,
                      status: SmartMaintenanceConstant.mailStatusProps[data.status],
                    })}
                  />
                )}
              />
            </Column>
            <Column>
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'workshopInformation', field: 'company_name' })}
                content={data.fleetCompanyName}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label="empty"
                content="empty"
                hide
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'email' })}
                content={data.fleetEmail}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getField({ base: config.strapiPage, field: config.content.single_service_origin })}
                content={SmartMaintenanceConstant.origin[data.origin]}
              />
            </Column>
          </Row>
        </ItemsRow>
      </Row>
      <Grid__separator size="100px" />
    </Fragment>
  );

  return (
    <Container style={style}>
      <Popup active={active} closeEvent={handleCloseEvent}>
        <Fragment>
          {loading || !data ? renderLoader() : renderContent()}
        </Fragment>
      </Popup>
    </Container>
  );
};

export default LeadGenerationWorkshopSingle;
