import React from 'react';
import styled from 'styled-components';
import t from 'theme';

import { Grid__card } from 'components/grid/grid';
import Content__titleIconService from 'components/content/titleIconService/content__titleIconService';


const Card = styled(Grid__card)`
  width: ${(props) => (props.dashboard ? '330px' : '310px')};
  height: 360px;
  position: relative;
  margin-right: ${(props) => (props.dashboard ? '0' : '40px')};
  margin-bottom: ${(props) => (props.dashboard ? '0' : '40px')};
  ${t.mixins.flex({ justify: 'space-between', direction: 'column' })};
  transition: .3s ease-in all;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.1);
  }
`;

const ContentInfo = styled.div`
  width: 100%;
`;

const Img = styled.div`
  background-color: lightgrey;
  background-image: ${({ imageUrl }) => `url('${imageUrl}')`};
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 115px;
  margin-bottom: 25px;
  border-radius: 5px;
`;

const Description = styled.div`
  width: 100%;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  color: #808080;
  margin-top: 20px;
`;

const Label = styled.span`
  display: block;
  background: #01B5E2;
  box-shadow: 0px 5px 14px rgba(1, 181, 226, 0.25);
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: -13px;
  right: -10px;
`;


const Cards__serviceCard = ({
  title, icon, description, imageUrl, event, label, dashboard,
}) => (
  <Card
    padding="20px"
    event={() => (event ? event() : null)}
    dashboard={dashboard}
  >
    {label
      && <Label>{label}</Label>}
    <ContentInfo>
      <Img imageUrl={imageUrl} />
      <Content__titleIconService icon={icon} content={title} />
      <Description>{description}</Description>
    </ContentInfo>
  </Card>
);

export default Cards__serviceCard;
