import React, { useContext, useEffect } from 'react';
import Nav from 'components/global/nav';
import styled from 'styled-components';
import t from 'theme';
import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Transition__loader_controller } from 'components/transition/transition';
import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';
import CheckVehicle__form from 'containers/workshopView/checkVehicle/form/checkVehicle__form';
import { ContentContext } from 'context/contentContext';
import {
  strapiWorkshopCheck, strapiWorkshopSingleVehicle, strapiCase, strapiRegister, strapiPaymentGuaranteeService, strapiWorkshopDashboardPaymentGuarantee,
} from 'util/strapi/content.starpi';
import { haveServiceContent, loadService } from 'util/function/loadContent.function';


const Container = styled.div`
  height: calc(100vh - 100px);
  ${t.mixins.flex({ justify: 'center', align: 'center' })}
`;

/**
 * @name MyAccount
 */

const MyAccount = () => {
  const {
    loadPage, haveContentFor, getField,
  } = useContext(ContentContext);

  const loadContent = async () => {
    if (
      haveContentFor(strapiWorkshopCheck).success === false
    ) {
      await loadPage(strapiWorkshopCheck);
    }
    if (
      haveContentFor(strapiWorkshopSingleVehicle).success === false
    ) {
      await loadPage(strapiWorkshopSingleVehicle);
    }
    if (
      haveContentFor(strapiRegister).success === false
    ) {
      await loadPage(strapiRegister);
    }
    if (
      haveContentFor(strapiCase).success === false
    ) {
      await loadPage(strapiCase);
    }
    if (
      haveContentFor(strapiPaymentGuaranteeService).success === false
    ) {
      await loadPage(strapiPaymentGuaranteeService);
    }
    if (haveContentFor(strapiWorkshopDashboardPaymentGuarantee).success === false) {
      await loadPage(strapiWorkshopDashboardPaymentGuarantee);
    }
    loadService({ haveFunc: haveContentFor, loadFunc: loadPage });
  };
  useEffect(() => {
    loadContent();
  }, []);

  if (
    haveContentFor(strapiWorkshopCheck).success === false
    || haveContentFor(strapiWorkshopSingleVehicle).success === false
    || haveContentFor(strapiRegister).success === false
    || haveContentFor(strapiPaymentGuaranteeService).success === false
    || haveContentFor(strapiWorkshopDashboardPaymentGuarantee).success === false
    || haveContentFor(strapiCase).success === false
    || haveServiceContent({ haveFunc: haveContentFor }) === false
  ) {
    return <Transition__loader_controller active />;
  }
  return (
    <Layout__container>
      <Nav workshop active="checkVehicle" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <Layout__content>
          <Content__pageTitle content={getField({ base: strapiWorkshopCheck, field: 'title_page' })} />
          <Container>
            <CheckVehicle__form />
          </Container>
        </Layout__content>
      </Layout__wrapper>
    </Layout__container>
  );
};

export default MyAccount;
