import React from 'react';
import t from 'theme';
import styled from 'styled-components';


const Container = styled.div`
  position: relative;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  border-bottom: 2px solid #F2F2F2;
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start' })}
 `;

const Item = styled.a`
  cursor: ${(props) => (props.active ? 'default' : 'pointer')};
  font-weight: 600;
  font-size: 16px;
  line-height: 60px;
  padding-left: 30px;
  padding-right: 30px;
  height: 60px;
  color: ${(props) => (props.active ? '#01B5E2' : '#4d4d4d')};
  border-bottom: 2px solid ${(props) => (props.active ? '#01B5E2' : '#F2F2F2')};
  transition: .3s ease-in color;
  &:hover {
    color: #01B5E2;
  }
`;


const Cards__navTab = ({
  style,
  items,
  event,
}) => {
  const renderItem = (item, key) => {
    const handleClick = () => {
      if (event) {
        event(item.id);
      }
    };
    return (
      <Item key={key} active={item.active} onClick={handleClick}>{item.label}</Item>
    );
  };
  return (
    <Container
      style={style}
    >
      {items.map((item, key) => renderItem(item, key))}
    </Container>
  );
};

export default Cards__navTab;
