/* eslint-disable no-unused-vars */
import React, {
  useContext, useEffect, useState,
} from 'react';
import t from 'theme';
import styled from 'styled-components';
import { AppContext } from 'context/appContext';
import { GetHomeBaseFromUser } from 'util/fleet/getfleetuserdata.util';
import { ContentContext } from 'context/contentContext';
import { UpdateTier, canRemoveService } from 'helpers/api/routes/RepairApproval';
import { Content__text } from 'components/content/content';
import { checkField_mail } from 'util/conditions/checkField.condition';
import {
  strapiRepairApprovalService,
  strapiSingleServicePage,
} from 'util/strapi/content.starpi';
import { format_apiobject } from 'util/formatContent.util';
import Form__optionSelector from 'components/form/optionSelector/form__optionSelector';
import Form__totalCount from 'components/form/totalCount/form__totalCount';
import { formatApiRes } from 'util/function/formatApiRes.function';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import Message__sucess from 'components/message/sucess/message__sucess';
import { getOne } from 'helpers/api/routes/User';
import ServiceDashboard__settingPop from 'containers/workshopView/serviceDashboard/settingPop/serviceDashboard__settingPop';


const Container = styled.div`
  margin: 0 auto;
`;


const DashboardRepairApproval__settings = ({
  style, activeSettingPop, closeSettingPop, fleetArray,
}) => {
  const { getField, getGlobal } = useContext(ContentContext);
  const { user, getUser } = useContext(AppContext);
  const homeBaseWorkshop = GetHomeBaseFromUser({ user: user.user });
  const asHomeBase = format_apiobject({ obj: homeBaseWorkshop });
  const [emailError, setemailError] = useState(false);
  const [currentOption, setcurrentOption] = useState(1);
  const [mail, setmail] = useState('');
  const price = getField({ base: strapiRepairApprovalService, field: 'price' });
  const [loading, setloading] = useState(false);
  const [successUpdatePop, setsuccessUpdatePop] = useState(false);
  const [checkArrayHaveArray, setCheckHaveArray] = useState([]);
  const [checkArrayNotHaveArray, setCheckNotHaveArray] = useState([]);
  const [allowchange, setallowchange] = useState(true);

  const getSingleUser = async () => {
    if (user.user && user.user.UserFleet) {
      const base = user.user.UserFleet;
      if (base.repairApproverUserTier === false || !base.repairApproverUserTier) {
        const id = base.repairApprovalUserId;
        const singleUser = await getOne({ id });
        const res = formatApiRes(singleUser);
        if (res.data) {
          if (res.data.role === 'workshop') {
            setcurrentOption(1);
          } else {
            setcurrentOption(2);
          }
        } else {
          setcurrentOption(2);
        }
      } else {
        setmail(base.repairApprovalUserEmail);
        setcurrentOption(3);
      }
    }
  };


  const checkAllowRemove = async () => {
    const canChangeRes = await canRemoveService(user.user.UserFleet.id);
    const formated = formatApiRes(canChangeRes);
    if (formated.success && formated.data) {
      setallowchange(false);
    } else {
      setallowchange(true);
    }
  };


  useEffect(() => {
    if (activeSettingPop) {
      checkAllowRemove();
    }
  }, [activeSettingPop]);

  useEffect(() => {
    getSingleUser();
  }, []);


  const getTotalValue = () => checkArrayNotHaveArray.length + checkArrayHaveArray.length;

  const handleSelectOption = (value) => {
    setcurrentOption(value);
  };

  const handleTextfieldChange = ({ value }) => {
    setmail(value);
  };


  const perfomSave = async ({ id, tier, email }) => {
    setloading(true);
    const resUpdateTier = await UpdateTier({
      userTier: tier,
      email,
      tierId: id,
      userId: user.user.id,
      cgv: true,
    });

    setloading(false);
    setsuccessUpdatePop(true);
  };


  const handleSave = () => {
    if (currentOption) {
      if (currentOption === 3) {
        if (checkField_mail(mail, getGlobal).valide === true) {
          perfomSave({ id: null, tier: true, email: mail });
        } else {
          setemailError({ active: true, message: checkField_mail(mail, getGlobal).message });
        }
      } else if (currentOption === 1) {
        const homeBaseId = asHomeBase ? asHomeBase.id : null;
        setemailError(false);
        perfomSave({ id: homeBaseId, tier: false, email: null });
      } else if (currentOption === 2) {
        setemailError(false);
        perfomSave({ id: user.user.id, tier: false, email: null });
      }
    }
  };

  const handleCloseSucessUpdate = async () => {
    setloading(true);
    const resUser = await getUser();
    if (resUser.success === true) {
      setloading(false);
      setsuccessUpdatePop(false);
    }
  };


  const handleCloseEvent = () => {
    if (successUpdatePop) {
      handleCloseSucessUpdate();
    } else {
      closeSettingPop();
    }
  };


  return (
    <ServiceDashboard__settingPop active={activeSettingPop} closeEvent={handleCloseEvent} flex>
      {
        successUpdatePop ? (
          <Message__sucess
            icon={t.image.illu.check}
            title={getField({ base: strapiSingleServicePage, field: 'success_update_popup_title' })}
            text={getField({ base: strapiSingleServicePage, field: 'success_update_popup_text' })}
            buttonText={getField({ base: strapiSingleServicePage, field: 'success_update_popup_button' })}
            buttonEvent={handleCloseSucessUpdate}
          />
        ) : (
          <Container style={style}>
            <Content__text
              type="p"
              color="#4d4d4d"
              align="center"
              style={{ fontWeight: 600, fontSize: 26, display: 'block' }}
            >
              {getField({ base: strapiRepairApprovalService, field: 'service_title' })}
              {' '}
              {getField({ base: strapiSingleServicePage, field: 'settings_suffix' })}
            </Content__text>
            <Form__optionSelector
              asHomeBase={asHomeBase}
              title={getField({ base: strapiRepairApprovalService, field: 'approver_title' })}
              choice1Title={getField({ base: strapiRepairApprovalService, field: 'approver_homebase' })}
              choice1Content={asHomeBase ? asHomeBase.companyName : ''}
              choice2Title={getField({ base: strapiRepairApprovalService, field: 'approver_you_label' })}
              choice2Content={getField({ base: strapiRepairApprovalService, field: 'approver_you_text' })}
              choice3Title={getField({ base: strapiRepairApprovalService, field: 'approver_third_label' })}
              choice3Content={getField({ base: strapiRepairApprovalService, field: 'approver_third_text' })}
              choice3Label={getField({ base: strapiRepairApprovalService, field: 'approver_third_mail' })}
              choice3Value={mail}
              choice3Error={emailError}
              change={handleSelectOption}
              textfieldChange={handleTextfieldChange}
              initialValue={currentOption}
              disable={!allowchange}
            />
            <Form__totalCount
              title={getField({ base: strapiSingleServicePage, field: 'rate_label' })}
              price={price}
              currency="€"
              labelPrice={getField({ base: strapiSingleServicePage, field: 'per_months' })}
              numberVehicle={getTotalValue()}
              numberLabel={getField({ base: strapiSingleServicePage, field: 'vehicle_label' })}
              buttonText={getField({ base: strapiSingleServicePage, field: 'update_button' })}
              handleSave={handleSave}
              onlyButton
              disable={!allowchange}
            />
          </Container>
        )
      }
      {loading ? <Loader__wrapper /> : false}
    </ServiceDashboard__settingPop>
  );
};

export default DashboardRepairApproval__settings;
