/* eslint-disable no-undef */
/* eslint-disable no-mixed-operators */
const calculateDistance = (lat1, lon1, lat2, lon2, unit) => {
  const radlat1 = Math.PI * lat1 / 180;
  const radlat2 = Math.PI * lat2 / 180;
  const theta = lon1 - lon2;
  const radtheta = Math.PI * theta / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = dist * 180 / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') { dist *= 1.609344; }
  if (unit === 'N') { dist *= 0.8684; }
  return dist;
};

export const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((place) => {
    if (place) {
      bounds.extend(new maps.LatLng(
        place.lat,
        place.lng,
      ));
    }
  });
  return bounds;
};


export const fitZoomToMarker = (mapProps, places, base) => {
  if (mapProps && places && base) {
    const _places = [...places];
    for (let i = 0; i < _places.length; i++) {
      _places[i].distance = calculateDistance(
        base.lat,
        base.lng,
        _places[i].lat,
        _places[i].lng,
        'K',
      );
    }

    _places.sort((a, b) => a.distance - b.distance);

    const { map, maps } = mapProps;
    const bounds = getMapBounds(map, maps, [_places[0] && _places[0], { lat: base.lat, lng: base.lng }]);
    map.fitBounds(bounds);
  }
};
