import { strapiApi } from 'util/strapi/type.strapi';
import { StrapiGet } from '../Helpers';

export const GetPageContent = async ({ pageName }) => {
  const url = `${strapiApi}${pageName}`;

  const res = await StrapiGet(url, {}, {});

  return res;
};
