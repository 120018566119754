
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import t from 'theme';
import { slugify } from 'helpers/js/Utile';

import { ContentContext } from 'context/contentContext';
import { WorkshopContext } from 'context/fleetUser/workshopContext';

/**
 * import containers & components
 */

import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { Grid__separator, Grid__popup } from 'components/grid/grid';
import Button__primary from 'components/button/primary/button__primary';
import { Form__select, Form__textfield } from 'components/form/form';
import { List__table, List__content } from 'components/list/list';
import { Message__sucess } from 'components/message/message';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';
import { AppContext } from 'context/appContext';
/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  flex: 1;
  ${t.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'column' })}
`;

const WorkShopPop = styled(Grid__popup)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const RowPopUp = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'center', align: 'flex-end' })}
`;

const InfoText = styled.div`
  width: 100%;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  max-width: 600px;
`;

const ButtonSave = styled(Button__primary)`
  ${({ disable }) => disable && `
    opacity: 0.5;
  `};
`;

/**
 * notset component is..
 */

const HomeBase__workshopList = ({
  activePop,
  closeEvent,
  saveEvent,
  sucessScreen,
  closeEventSave,
  basePage,
  loading,
}) => {
  const { getField, getGlobal, getGlobalObj } = useContext(ContentContext);
  const { getAllWorkshop, workshop, initial } = useContext(WorkshopContext);
  const [countrySelect, setCountrySelect] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [selectedWorkShop, setSelectedWorkShop] = useState(null);
  const { user } = useContext(AppContext);

  const headerCol = [
    {
      label: getGlobal({ option: 'workshopInformation', field: 'name' }),
    },
    {
      label: getGlobal({ option: 'workshopInformation', field: 'country' }),
    },
    {
      label: getGlobal({ option: 'workshopInformation', field: 'address' }),
    },
    {
      label: getGlobal({ option: 'workshopInformation', field: 'city' }),
    },
    {
      label: getGlobal({ option: 'workshopInformation', field: 'zipcode' }),
    },
  ];

  const [workshopCol, setWorkshopCol] = useState([]);
  const [workshopNoFormat, setWorkshopNoFormat] = useState([]);

  const [baseworkshopNoFormat, setbaseWorkshopNoFormat] = useState([]);

  useEffect(() => {
    const keyObject = Object.keys(getGlobalObj({ option: 'countryCode' }));
    const selectArray = [];

    keyObject.forEach((element) => {
      const obj = {
        label: getGlobal({ option: 'countryCode', field: element }),
        slug: slugify(element),
        active: false,
      };

      selectArray.push(obj);
    });

    selectArray[0].active = true;
    setCountrySelect(selectArray);
  }, []);

  const parseWorkshopCol = () => {
    const array = [];
    const arrayNoFormated = [];
    workshop.forEach((element) => {
      if (element.UserWorkshop.id !== user.user.UserFleet.workshopId) {
        array.push([
          {
            label: (
              <List__content capitalize>
                {element.companyName}
              </List__content>
            ),
          },
          {
            label: (
              <List__content capitalize>
                {element.region}
              </List__content>
            ),
          },
          {
            label: (
              <List__content capitalize>
                {element.adress}
              </List__content>
            ),
          },
          {
            label: (
              <List__content capitalize>
                {element.city}
              </List__content>
            ),
          },
          {
            label: (
              <List__content capitalize>
                {element.zipcode}
              </List__content>
            ),
          },
        ]);
        arrayNoFormated.push(element);
      }
    });

    setWorkshopNoFormat(arrayNoFormated);
    setWorkshopCol(array);
    setbaseWorkshopNoFormat(arrayNoFormated);
  };

  const filterArray = ({ value }) => {
    const filtered = baseworkshopNoFormat.filter((single) => slugify(single.countryCode) === slugify(value.slug));
    const arrayNoFormated = [];
    const array = [];
    filtered.forEach((element) => {
      array.push([
        {
          label: (
            <List__content capitalize>
              {element.companyName}
            </List__content>
          ),
        },
        {
          label: (
            <List__content capitalize>
              {element.region}
            </List__content>
          ),
        },
        {
          label: (
            <List__content capitalize>
              {element.adress}
            </List__content>
          ),
        },
        {
          label: (
            <List__content capitalize>
              {element.city}
            </List__content>
          ),
        },
        {
          label: (
            <List__content capitalize>
              {element.zipcode}
            </List__content>
          ),
        },
      ]);
      arrayNoFormated.push(element);
    });

    setWorkshopNoFormat(arrayNoFormated);
    setWorkshopCol(array);
  };

  const searchArray = ({ value }) => {
    const filtered = baseworkshopNoFormat.filter(
      (single) => slugify(single.city).includes(slugify(value))
        || slugify(single.companyName).includes(slugify(value))
        || slugify(single.region).includes(slugify(value)),
    );
    const arrayNoFormated = [];
    const array = [];
    filtered.forEach((element) => {
      array.push([
        {
          label: (
            <List__content capitalize>
              {element.companyName}
            </List__content>
          ),
        },
        {
          label: (
            <List__content capitalize>
              {element.region}
            </List__content>
          ),
        },
        {
          label: (
            <List__content capitalize>
              {element.adress}
            </List__content>
          ),
        },
        {
          label: (
            <List__content capitalize>
              {element.city}
            </List__content>
          ),
        },
        {
          label: (
            <List__content capitalize>
              {element.zipcode}
            </List__content>
          ),
        },
      ]);
      arrayNoFormated.push(element);
    });
    setWorkshopNoFormat(arrayNoFormated);
    setWorkshopCol(array);
  };

  useEffect(() => {
    if (workshop === null || initial === false) {
      getAllWorkshop();
    } else if (workshopCol.length === 0) {
      parseWorkshopCol();
    }
  }, [workshop]);

  const handleSelect = ({ value, result }) => {
    filterArray({ value: result });
    setCountrySelect(value);
  };

  const handleSearch = ({ value }) => {
    searchArray({ value });
    setSearchValue(value);
  };

  return (
    <WorkShopPop active={activePop} closeEvent={() => closeEvent()} width="760px">
      {!sucessScreen ? (
        <Container>
          <Content__cardTitle content={getField({ base: basePage, field: 'select_workshop_title' })} />
          <Grid__separator size="35px" />
          {countrySelect !== false ? (
            <RowPopUp>
              <Form__select
                value={countrySelect}
                style={{ width: 220 }}
                event={(res) => handleSelect({
                  property: 'countrySelect',
                  value: res.value,
                  result: res.result,
                  type: 'select',
                })}
              />
              <Grid__separator width="15px" />
              <Form__textfield
                change={handleSearch}
                value={searchValue}
                style={{ width: 350 }}
                property="searchValue"
                label=""
                search
                placeholder={getField({ base: basePage, field: 'select_workshop_search' })}
              />
            </RowPopUp>
          ) : null}
          <Grid__separator size="35px" />
          <List__table
            headerCol={headerCol}
            lineCol={workshopCol}
            maxHeight="320px"
            noSelectAll
            selectOne
            borderLine
            selectedLine={(value) => setSelectedWorkShop(workshopNoFormat[parseInt(value[0].key, 10)])}
          />
          <Grid__separator size="25px" />
          <ButtonSave event={() => selectedWorkShop && (saveEvent(selectedWorkShop))} disable={!selectedWorkShop ? true : false}>
            {getField({ base: basePage, field: 'select_workshop_button' })}
          </ButtonSave>
          <Grid__separator size="25px" />
          <InfoText>{getField({ base: basePage, field: 'select_workshop_text' })}</InfoText>
        </Container>
      ) : (
        <Message__sucess
          icon={t.image.illu.check}
          title={getField({ base: basePage, field: 'confirmation_title' })}
          text={getField({ base: basePage, field: 'confirmation_text' })}
          buttonText={getField({ base: basePage, field: 'confirmation_button' })}
          buttonEvent={() => closeEventSave()}
        />
      )}
      {loading ? <Loader__wrapper /> : false}
    </WorkShopPop>
  );
};

export default HomeBase__workshopList;
