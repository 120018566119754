import React, {
  useContext, useState, forwardRef, useImperativeHandle,
} from 'react';
import styled from 'styled-components';
import { SendMail } from 'helpers/api/routes/Mail';
import t from 'theme';
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { Grid__separator } from 'components/grid/grid';
import { Content__sectionTitle } from 'components/content/content';
import Button__primary from 'components/button/primary/button__primary';
import { Form__textfield, Form__uploader } from 'components/form/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandshakeAlt, faFingerprint,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faEdit,
} from '@fortawesome/pro-solid-svg-icons';
import Cards__infoCheck from 'components/cards/infoCheck/cards__infoCheck';
import { strapiFairPriceService } from 'util/strapi/content.starpi';
import { LaunchServiceFairePrice, createFairPriceFile } from 'helpers/api/routes/FairPrice';
import { sizeLimit } from 'util/conditions/sizeFile.util';
import { Message__sucess } from 'components/message/message';
import { createErrorObject } from 'util/conditions/checkField.condition';
import { hasFieldError } from 'util/conditions/haveErrorField.conditions';
import { getNavigatorLang } from 'util/function/getNavigatorLang.function';
import { setAction } from 'util/function/reloadAction.function';
import { SERVICENAME } from 'util/service/serviceName';


const Container = styled.div`
  ${t.mixins.flex({ justify: 'center', direction: 'column' })};
  width: 100%;
`;

const ContainerForm = styled.div`
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
  width: 500px;
`;

const Uploader = styled(Form__uploader)`
  width: 100%;
  min-width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DashboardFairPrice__formPop = forwardRef(({
  style, vehicle, closeEvent, prevEvent, isRequestSucces,
}, ref) => {
  const { getField, getGlobal } = useContext(ContentContext);
  const { user, getUser } = useContext(AppContext);

  const [error, seterror] = useState({});
  const [loadingAction, setloadingAction] = useState(false);
  const [claimtext, setclaimtext] = useState('');
  const [file, setfile] = useState(null);
  const [sucessScreen, setsucessScreen] = useState({ active: false });

  const getServiceInformation = (key) => {
    if (vehicle) {
      if (vehicle.service) {
        if (vehicle.service[key]) {
          return vehicle.service[key];
        }
      }
    }
    return '';
  };

  const getRequestInformation = (key) => {
    if (vehicle) {
      if (vehicle.request) {
        if (vehicle.request[key]) {
          return vehicle.request[key];
        }
      }
    }
    return '';
  };

  const handleTextField = ({ value, property }) => {
    if (property === 'claim') {
      setclaimtext(value);
    }
  };

  const checkField = () => {
    let errorObj = {};
    let errorArray = {};

    const baseArrayField = [
      {
        key: 'claimtext',
        type: 'simple',
        value: claimtext,
      },
    ];

    errorObj = createErrorObject(errorArray, baseArrayField, errorObj.hasError, getGlobal);
    errorArray = errorObj.object;

    if (sizeLimit(file)) {
      if (sizeLimit(file)) {
        errorArray.file = { active: true, message: 'File limite' };
      } else {
        errorArray.file = { active: true };
      }
      errorObj.hasError = true;
    }

    seterror(errorArray);
    return errorObj.hasError;
  };

  const parseFiles = (fileArray) => {
    setfile(fileArray[0]);
  };

  const startReq = async () => {
    const params = {
      userFleetId: user.user.UserFleet.id,
      priority90Id: getServiceInformation('slug') === SERVICENAME.priority90 ? getRequestInformation('id') : null,
      paymentguaranteeId: getServiceInformation('slug') === SERVICENAME.paymentguarantee ? getRequestInformation('id') : null,
      standardRepairId: getServiceInformation('slug') === SERVICENAME.standardrepair ? getRequestInformation('id') : null,
      repairApprovalId: getServiceInformation('slug') === SERVICENAME.repairapproval ? getRequestInformation('id') : null,
      service24Id: getServiceInformation('slug') === SERVICENAME.service24 ? getRequestInformation('id') : null,
      description: claimtext,
      textLang: getNavigatorLang().lang,
    };

    let fileParams = false;
    if (file) {
      fileParams = {
        file,
        extension: file.extension,
        serviceUid: '',
        fileId: '',
      };
    }

    const res = await LaunchServiceFairePrice(params);
    if (res) {
      if (res.data.success === true) {
        if (fileParams) {
          fileParams.serviceUid = res.data.data.uid;
          fileParams.fileId = res.data.data.id;

          const fileRes = await createFairPriceFile(fileParams);
          if (fileRes && fileRes.data) {
            if (fileRes.data.success === true) {
              setloadingAction(false);
              setsucessScreen({ active: true, id: res.data.data.id });
              SendMail({
                template: 'admin',
                templateNotification: {
                  status: 'action',
                  statusLabel: 'Action needed',
                  content: `New Claim service request from ${user.user.companyName}`,
                  btnContent: 'Anwser to the request',
                  btnLink: 'admin-link',
                },
                subject: 'Admin Notification : Action Needed',
              });

              if (isRequestSucces) {
                isRequestSucces();
              }
            } else {
              setloadingAction(false);
            }
          } else {
            setsucessScreen({ active: true, id: res.data.data.id });
          }
        } else {
          setloadingAction(false);
          setsucessScreen({ active: true, id: res.data.data.id });
          SendMail({
            template: 'admin',
            templateNotification: {
              status: 'action',
              statusLabel: 'Action needed',
              content: `New Claim service request from ${user.user.companyName}`,
              btnContent: 'Anwser to the request',
              btnLink: 'admin-link',
            },
            subject: 'Admin Notification : Action Needed',
          });

          if (isRequestSucces) {
            isRequestSucces();
          }
        }
      } else {
        setloadingAction(false);
      }
    }
  };


  const handleSuccesMessage = async () => {
    setloadingAction(true);
    setAction({ key: 'action', value: sucessScreen.id });
    const resUser = await getUser();
    if (resUser) {
      if (closeEvent) {
        closeEvent();
      }
      setloadingAction(false);
    } else {
      setloadingAction(false);
    }
  };

  const handleSave = async () => {
    if (!checkField()) {
      setloadingAction(true);
      startReq();
    }
  };


  useImperativeHandle(ref, () => ({

    handleReloadHook() {
      handleSuccesMessage();
    },

    handleCleanHook() {
      setclaimtext('');
      setfile(null);
    },

  }));

  return (
    <Container style={style}>
      {!sucessScreen.active ? (
        <ContainerForm>
          <Cards__infoCheck
            title={getField({ base: strapiFairPriceService, field: 'request_form_cardtitle' })}
            content={getServiceInformation('label')}
            icon={<FontAwesomeIcon icon={faHandshakeAlt} />}
          />
          <Grid__separator size="25px" />
          <Cards__infoCheck
            title={getField({ base: strapiFairPriceService, field: 'request_form_section1_title' })}
            content={`${getRequestInformation('uid')}`}
            icon={<FontAwesomeIcon icon={faFingerprint} />}
          />
          <Grid__separator size="65px" />
          <Content__sectionTitle
            title={getField({ base: strapiFairPriceService, field: 'request_form_section3_title' })}
            icon={<FontAwesomeIcon icon={faEdit} />}
          />
          <Form__textfield
            style={{ width: '100%' }}
            label={getField({ base: strapiFairPriceService, field: 'request_form_section3_comment_label' })}
            require
            type="textarea"
            property="claim"
            value={claimtext}
            change={handleTextField}
            error={hasFieldError(error, 'claimtext')}
          />
          <Grid__separator size="35px" />
          <Uploader
            title={getField({ base: strapiFairPriceService, field: 'request_form_section2_file_title' })}
            conditionalWord={getField({ base: strapiFairPriceService, field: 'request_form_section2_file_conditional' })}
            text={getField({ base: strapiFairPriceService, field: 'request_form_section2_file_button' })}
            label={getField({ base: strapiFairPriceService, field: 'request_form_section2_file_label' })}
            onFilesAddedCallBack={parseFiles}
            require={false}
            error={error.hasOwnProperty('file') ? { active: true, message: error.file.message } : false}
          />
          <Grid__separator size="35px" />
          <Row>
            <Button__primary border blue event={prevEvent}>{getGlobal({ option: 'service', field: 'single_launch_prevbutton' })}</Button__primary>
            <Button__primary style={{ marginLeft: 25 }} event={handleSave}>{getField({ base: strapiFairPriceService, field: 'request_form_button' })}</Button__primary>
          </Row>
        </ContainerForm>
      ) : (
        <Message__sucess
          icon={t.image.illu.check}
          title={getField({ base: strapiFairPriceService, field: 'success_title' })}
          text={getField({ base: strapiFairPriceService, field: 'success_text' })}
          buttonText={getField({ base: strapiFairPriceService, field: 'success_button' })}
          buttonEvent={handleSuccesMessage}
        />
      )}
      {loadingAction ? <Loader__wrapper /> : false}
    </Container>
  );
});

export default DashboardFairPrice__formPop;
