
import React, {
  useContext, useState, useEffect, Fragment,
} from 'react';
import styled from 'styled-components';
import t from 'theme';

import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import { WorkshopContext } from 'context/fleetUser/workshopContext';

import { slugify } from 'helpers/js/Utile';
/**
 * import containers & components
 */

import { Grid__separator, Grid__card } from 'components/grid/grid';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';
import Content__tag from 'components/content/tag/content__tag';
import Content__labelText from 'components/content/labelText/content__labelText';
import Button__buttonCircle from 'components/button/buttonCircle/button__buttonCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { UpdateTier } from 'helpers/api/routes/RepairApproval';
import { resetObligo } from 'helpers/api/routes/Workshop';
import { canChangeHomeBase } from 'helpers/api/routes/User';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { removeHomeBase } from 'helpers/api/routes/Fleets';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import GoogleMapReact from 'google-map-react';
import styles from 'components/form/map/mapsStyle';
import Button__inline from 'components/button/inline/button__inline';
import { checkRate } from 'util/conditions/rate.condition';
import './homeBase__set.scss';
import Message__sucess from 'components/message/sucess/message__sucess';
import Grid__popup from 'components/grid/popup/grid__popup';
import { strapiMapPage } from 'util/strapi/content.starpi';

/**
 * styled components
 */

const Container = styled.div``;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MainCards = styled(Grid__card)`
  min-width: 800px;
  padding: 65px;
  position: relative;
`;

const AddressLine = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: #4D4D4D;
`;

const Tag = styled(Content__tag)`
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  height: auto;
  font-size: 16px;
  p {
    text-transform: initial;
  }
`;

const ButtonContainer = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-end', direction: 'row' })};
  width: 95px;
  margin-top: 30px;
`;

const Row = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', direction: 'row' })}
`;

const Column = styled.div``;


const HoursContainer = styled.div`
  display: table-row;
`;

const Marker = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${t.color.cyan};
  ${t.mixins.flex()};
  border-radius: 100%;
  transform: translate(-50%, -50%);
`;

const MarkerChild = styled.div`
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 100%;
`;

const Delete = styled.div`
  position: absolute;
  right: 30px;
  bottom: 20px;
`;

const MapContainer = styled.div`
  margin-right: 40px;
  height: 140px;
  width: 200px;
  border-radius: 10px;
  overflow: hidden;
`;

const Label = styled.span`
  font-size: 15px;
  font-weight: 500;
  color: #4D4D4D;
  margin-right: 10px;
  display: table-cell;
  border-right: 15px solid transparent;
  background-clip: padding-box;
`;

const Value = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: #4D4D4D;
  display: table-cell;
`;

const Table = styled.div`
  display: table;
`;

const Table_row = styled.div`
  display: table-row;
`;

/**
 * notset component is..
 */

const HomeBase__notset = ({ style, basePage, hours }) => {
  const { getField, getGlobal, getFieldGroup } = useContext(ContentContext);
  const { user, getUser } = useContext(AppContext);
  const { UserFleet } = user.user;
  const { UserWorkshop } = UserFleet;
  const { UserWorkshopAlias } = UserWorkshop;

  const { setHomeBase } = useContext(WorkshopContext);
  const [popUpHomeBase, setPopUpHomeBase] = useState(false);
  const [popUpHomeBaseSave, setPopUpHomeBaseSave] = useState(false);
  const [popUpDelete, setPopUpDelete] = useState(false);
  const [allowchange, setallowchange] = useState(true);

  const handleOpenDeletePop = () => {
    setPopUpDelete(true);
  };
  const handleCloseDeletePop = () => {
    setPopUpDelete(false);
  };

  const handleChangeHomeBase = async () => {
    await removeHomeBase();
    const resObligo = await resetObligo({
      fleetId: user.user.UserFleet.id,
    });
    if (!user.user.UserFleet.repairApproverUserTier) {
      await UpdateTier({
        userTier: false,
        email: null,
        tierId: user.user.id,
        userId: user.user.id,
      });
    }
    if (resObligo) {
      const resUser = await getUser();
      if (resUser.success === true) {
        setPopUpHomeBase(false);
        setPopUpHomeBaseSave(false);
      }
    }
  };

  const checkIfCanChange = async () => {
    const canChangeRes = await canChangeHomeBase({ userId: user.user.UserFleet.id });
    const formated = formatApiRes(canChangeRes);
    if (formated.success && (formated.data.paymentguarantee || formated.data.repairApproval)) {
      setallowchange(false);
    }
  };
  useEffect(() => {
    checkIfCanChange();
  }, []);

  const isClosed = (amStart, pmStart) => {
    const closed = {
      am: false,
      pm: false,
    };
    if (!amStart) {
      closed.am = true;
    }
    if (!pmStart) {
      closed.pm = true;
    }

    return closed;
  };

  const createHours = () => {
    const array = [
      {
        day: getField({ base: strapiMapPage, field: 'monday' }),
        amStart: UserWorkshop.mondayAmStart,
        amEnd: UserWorkshop.mondayAmEnd,
        pmStart: UserWorkshop.mondayPmStart,
        pmEnd: UserWorkshop.mondayPmEnd,
        closed: isClosed(UserWorkshop.mondayAmStart, UserWorkshop.mondayPmStart),
      },
      {
        day: getField({ base: strapiMapPage, field: 'tuesday' }),
        amStart: UserWorkshop.tuesdayAmStart,
        amEnd: UserWorkshop.tuesdayAmEnd,
        pmStart: UserWorkshop.tuesdayPmStart,
        pmEnd: UserWorkshop.tuesdayPmEnd,
        closed: isClosed(UserWorkshop.tuesdayAmStart, UserWorkshop.tuesdayPmStart),
      },
      {
        day: getField({ base: strapiMapPage, field: 'wednesday' }),
        amStart: UserWorkshop.wednesdayAmStart,
        amEnd: UserWorkshop.wednesdayAmEnd,
        pmStart: UserWorkshop.wednesdayPmStart,
        pmEnd: UserWorkshop.wednesdayPmEnd,
        closed: isClosed(UserWorkshop.wednesdayAmStart, UserWorkshop.wednesdayPmStart),
      },
      {
        day: getField({ base: strapiMapPage, field: 'thursday' }),
        amStart: UserWorkshop.thursdayAmStart,
        amEnd: UserWorkshop.thursdayAmEnd,
        pmStart: UserWorkshop.thursdayPmStart,
        pmEnd: UserWorkshop.thursdayPmEnd,
        closed: isClosed(UserWorkshop.thursdayAmStart, UserWorkshop.thursdayPmStart),
      },
      {
        day: getField({ base: strapiMapPage, field: 'friday' }),
        amStart: UserWorkshop.fridayAmStart,
        amEnd: UserWorkshop.fridayAmEnd,
        pmStart: UserWorkshop.fridayPmStart,
        pmEnd: UserWorkshop.fridayPmEnd,
        closed: isClosed(UserWorkshop.fridayAmStart, UserWorkshop.fridayPmStart),
      },
      {
        day: getField({ base: strapiMapPage, field: 'saturday' }),
        amStart: UserWorkshop.saturdayAmStart,
        amEnd: UserWorkshop.saturdayAmEnd,
        pmStart: UserWorkshop.saturdayPmStart,
        pmEnd: UserWorkshop.saturdayPmEnd,
        closed: isClosed(UserWorkshop.saturdayAmStart, UserWorkshop.saturdayPmStart),
      },
      {
        day: getField({ base: strapiMapPage, field: 'sunday' }),
        amStart: UserWorkshop.sundayAmStart,
        amEnd: UserWorkshop.sundayAmEnd,
        pmStart: UserWorkshop.sundayPmStart,
        pmEnd: UserWorkshop.sundayPmEnd,
        closed: isClosed(UserWorkshop.sundayAmStart, UserWorkshop.sundayPmStart),
      },
    ];

    return array;
  };

  const renderDay = (item) => {
    let morning = false;
    let afternoon = false;
    if (item.amStart && item.amEnd) {
      morning = `${item.amStart} - ${item.amEnd}`;
    }
    if (item.pmStart && item.pmEnd) {
      afternoon = `${item.pmStart} - ${item.pmEnd}`;
    }

    if (item.closed.pm === true && item.closed.am === true) {
      return getGlobal({ option: 'workshopInformation', field: 'closed_label' });
    }
    if (morning && afternoon) {
      return `${morning} / ${afternoon}`;
    }
    if (morning) {
      return `${morning}`;
    }
    return `${afternoon}`;
  };

  const renderHours = () => {
    hours = createHours();
    if (hours) {
      return hours.map((item, key) => (
        <Fragment key={key}>
          <HoursContainer key={key}>
            <Label>{item.day}</Label>
            <Value>
              {renderDay(item)}
            </Value>
          </HoursContainer>
          <Grid__separator size="13px" />
        </Fragment>
      ));
    }
    return null;
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'AlignCenter',
      }}
    >
      <CardContainer>
        <MainCards>
          <Container style={style}>
            <Row>
              <Column>
                <MapContainer>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API }}
                    zoomControl={false}
                    mapTypeControl={false}
                    scaleControl={false}
                    streetViewControl={false}
                    rotateControl={false}
                    fullscreenControl={false}
                    options={{ styles }}
                    zoom={15}
                    center={{
                      lat: parseFloat(UserWorkshopAlias.lat),
                      lng: parseFloat(UserWorkshopAlias.lng),
                    }}
                  >
                    <Marker
                      lat={UserWorkshopAlias.lat}
                      lng={UserWorkshopAlias.lng}
                    >
                      <MarkerChild />
                    </Marker>
                  </GoogleMapReact>
                </MapContainer>
              </Column>
              <Column>
                <Grid__separator size="10px" />
                <Content__cardTitle content={UserWorkshopAlias.companyName} />
                <Grid__separator size="20px" />
                {slugify(UserFleet.FavWorkshopStatus) === 'waiting' || slugify(UserFleet.FavWorkshopStatus) === 'error' ? (
                  <Tag
                    orange={
                      slugify(UserFleet.FavWorkshopStatus) === 'waiting'
                  || slugify(UserFleet.FavWorkshopStatus) === 'error'
                    }
                    content={getFieldGroup({
                      base: basePage,
                      groupe: 'status',
                      field: slugify(UserFleet.FavWorkshopStatus) === 'error' ? 'waiting' : slugify(UserFleet.FavWorkshopStatus),
                    })}
                  />
                ) : (
                  <div>
                    <AddressLine>{UserWorkshopAlias.adress}</AddressLine>
                    <AddressLine>{`${UserWorkshopAlias.zipcode}, ${UserWorkshopAlias.city}`}</AddressLine>
                    <AddressLine>{UserWorkshopAlias.region}</AddressLine>
                  </div>
                )}
              </Column>
            </Row>
            <Grid__separator size="55px" />
            <Row>
              <Column>
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'opening_hours' })}
                  content={(
                    <Table>
                      {renderHours()}
                    </Table>
                  )}
                />
                <ButtonContainer>
                  <Button__buttonCircle
                    href={`tel:${UserWorkshopAlias.phoneNumber}`}
                    noprevent
                    icon={<FontAwesomeIcon icon={faPhone} />}
                  />
                  <Button__buttonCircle
                    target="_blank"
                    href={`mailto:${UserWorkshopAlias.email}`}
                    noprevent
                    icon={<FontAwesomeIcon icon={faEnvelope} />}
                  />
                </ButtonContainer>
              </Column>
              <Grid__separator width="100px" />
              <Column>
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'hourly_rate' })}
                  content={(
                    <Table>
                      {checkRate(UserWorkshop.rate1)
                      && (
                        <Table_row>
                          <Label>{getGlobal({ option: 'workshopInformation', field: 'rate_1' })}</Label>
                          <Value>
                            {checkRate(UserWorkshop.rate1)}
                            {' '}
                            {getGlobal({ option: 'workshopInformation', field: 'rate_currency' })}
                          </Value>
                        </Table_row>
                      )}
                      <Grid__separator size="10px" />
                      {checkRate(UserWorkshop.rate2)
                      && (
                        <Table_row>
                          <Label>{getGlobal({ option: 'workshopInformation', field: 'rate_2' })}</Label>
                          <Value>
                            {checkRate(UserWorkshop.rate2)}
                            {' '}
                            {getGlobal({ option: 'workshopInformation', field: 'rate_currency' })}
                          </Value>
                        </Table_row>
                      )}
                      <Grid__separator size="10px" />
                      {checkRate(UserWorkshop.rate3)
                      && (
                        <Table_row>
                          <Label>{getGlobal({ option: 'workshopInformation', field: 'rate_3' })}</Label>
                          <Value>
                            {checkRate(UserWorkshop.rate3)}
                            {' '}
                            {getGlobal({ option: 'workshopInformation', field: 'rate_currency' })}
                          </Value>
                        </Table_row>
                      )}
                    </Table>
                  )}
                />
                <Grid__separator size="30px" />
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'email' })}
                  content={UserWorkshopAlias.email}
                />
                <Grid__separator size="30px" />
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })}
                  content={`${UserWorkshopAlias.phoneNumber}`}
                />
              </Column>
            </Row>
          </Container>
          <Delete>
            <Button__inline
              red
              event={handleOpenDeletePop}
              icon={<FontAwesomeIcon icon={faTimes} />}
              content={getField({ base: basePage, field: 'have_workshop_button' })}
            />
          </Delete>
        </MainCards>
      </CardContainer>
      <Grid__popup flex active={popUpDelete} closeEvent={handleCloseDeletePop}>
        <Message__sucess
          icon={t.image.illu.warning}
          title={!allowchange ? getField({ base: basePage, field: 'cant_change_homeBase' }) : getField({ base: basePage, field: 'alert_remove_title' })}
          text={!allowchange ? '' : getField({ base: basePage, field: 'alert_remove_text' })}
          buttonText={getField({ base: basePage, field: 'alert_remove_button' })}
          buttonEvent={handleChangeHomeBase}
          btnRed
          hideButton={!allowchange}
        />
      </Grid__popup>
    </div>
  );
};

export default HomeBase__notset;
