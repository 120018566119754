import React, { useEffect, useState, useContext } from 'react';
import t from 'theme';
import styled from 'styled-components';
import Grid__separator from 'components/grid/separator/grid__separator';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import Content__tag from 'components/content/tag/content__tag';
import { ContentContext } from 'context/contentContext';
import {
  Popup,
} from 'containers/workshopView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button__primary } from 'components/button/button';
import {
  faAlignJustify,
  faCalendarDay, faTruck, faWarehouse,
} from '@fortawesome/pro-regular-svg-icons';
import GoogleMapReact from 'google-map-react';
import { findEvent } from 'helpers/api/routes/event';
import styles from 'components/form/map/mapsStyle';
import Cards__warning from 'components/cards/warning/cards__warning';
import { sequelizeCustomDate } from 'util/date.util';
import { Form__select } from 'components/form/form';
// import moment from 'moment';
import moment from 'moment/min/moment-with-locales';
import { APPOINTMENT_STATUS } from '../../../util/constant/Appointment_status';
import {
  ApproveAppointment, ApproveRescheduleAppointment, DeclineAppointment, RescheduleAppointment,
} from '../../../helpers/api/routes/event';
import { parseAppointmentStatus } from '../../../util/conditions/statusProps.util';
import Form__datePicker from '../../../components/form/datePicker/form__datePicker';
import { CreateTimeArray, getClosedDay } from '../../../util/function/CreateTimeArray';

const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #01B5E2;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content__container = styled.div`
  max-width: 550px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 27px;
  line-height: 37px;
  color: #4D4D4D;
`;

const WarningContent = styled.div`
  font-size: 14px;
  color: #4D4D4D;
  margin-top: 20px;
`;

const TextContent = styled.div`
  font-weight: 400;
  color: #4D4D4D;
  line-height: 150%;
  font-size: ${({ big }) => (big ? '24px' : '16px')};
  text-decoration: ${({ crossed }) => (crossed ? 'line-through' : 'none')};
  opacity: ${({ crossed }) => (crossed ? 0.4 : 1)};
`;

const TextContent__Bold = styled.span`
  font-weight: 600;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 110px;
  margin-top: 15px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

const FieldContainer = styled.div`
  width: 100%;
`;

const Marker = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${t.color.cyan};
  ${t.mixins.flex()};
  border-radius: 100%;
  transform: translate(-50%, -50%);
`;

const MarkerChild = styled.div`
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 100%;
`;

const Warning__content = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #E74C3C;
`;

const RequestContent = styled.div`
  font-size: 14px;
  color: #4D4D4D;
  text-align: center;
`;

const ConfirmationCard = styled.div`
  background: #E6F8FC;
  border-radius: 10px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmationCard___Title = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 41px;
  max-width: 400px;
  text-align: center;
  margin-bottom: 45px;
`;

const ConfirmationCard___Title__workshop = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 41px;
  max-width: 400px;
  text-align: center;
  margin-bottom: 15px;
`;

const ConfirmationCard___content = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #5C6365;
`;

const ConfirmationCard___button = styled.div`
  display: flex;
  margin-top: 25px;
`;

const SeparatorChoice = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #4D4D4D;;
`;

const CardsWarning = styled(Cards__warning)`
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Tag = styled(Content__tag)`
  width: 100%;
`;


const Appointment__Single = ({
  active, id, closeEvent, refresh, isFleet, isWorkshop, basePage,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const slotChange = data && data.status === APPOINTMENT_STATUS.reschedule;
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(undefined);
  const [timeResult, setTimeResult] = useState({});
  const { getFieldGroup, getField } = useContext(ContentContext);


  const fetchEvent = async () => {
    if (id) {
      const res = await findEvent({
        id,
      });

      setLoading(false);
      const formated = formatApiRes(res);
      if (res && formated.data) {
        if (formated.success && formated.data) {
          setData(formated.data);
        }
      }
    }
  };

  useEffect(() => {
    fetchEvent();
  }, [id]);

  useEffect(() => {
    if (!active) {
      setData(undefined);
    }
  }, [active]);

  const formatMileage = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  const onDecline = async () => {
    if (data && data.id) {
      setLoading(true);
      const res = await DeclineAppointment({
        id,
      });

      const formated = formatApiRes(res);
      if (res && formated.data) {
        if (formated.success && formated.data && formated.data[1]) {
          setData({
            ...data,
            ...formated.data[1],
          });
        }
      }
      refresh();
      setLoading(false);
    }
  };
  const appointmentType = {
    maintenance: getField({ base: basePage, field: 'repair_type_maintenance' }),
    repair: getField({ base: basePage, field: 'repair_type_repair' }),
    urgentrepair: getField({ base: basePage, field: 'repair_type_repair_urgent' }),
  };
  const statusLabel = {
    refused: getFieldGroup({ base: basePage, groupe: 'appointment_status', field: 'error' }),
    done: getFieldGroup({ base: basePage, groupe: 'appointment_status', field: 'done' }),
    approved: getFieldGroup({ base: basePage, groupe: 'appointment_status', field: 'action' }),
    waiting: getFieldGroup({ base: basePage, groupe: 'appointment_status', field: 'waiting' }),
    reschedule: getField({ base: basePage, field: 'status_waiting_fleet_validation' }),
    cancel: getField({ base: basePage, field: 'status_canceled_by_fleet' }),
  };

  const onApproveWorkshop = async () => {
    if (data && data.id) {
      setLoading(true);
      const res = await ApproveAppointment({
        id,
      });
      const formated = formatApiRes(res);
      if (res && formated.data) {
        if (formated.success && formated.data && formated.data[1]) {
          setData({
            ...data,
            ...formated.data[1],
          });
        }
      }

      refresh();
      setLoading(false);
    }
  };

  const onRescheduleWorkshop = async () => {
    if (data && data.id) {
      setLoading(true);
      const res = await RescheduleAppointment({
        id,
        date: timeResult.slug,
        time: timeResult.label,
      });
      const formated = formatApiRes(res);
      if (res && formated.data) {
        if (formated.success && formated.data && formated.data[1]) {
          setData({
            ...data,
            ...formated.data[1],
          });
        }
      }

      refresh();
      setLoading(false);
    }
  };

  const onApprove = async () => {
    if (data && data.id) {
      setLoading(true);
      const res = await ApproveRescheduleAppointment({
        id,
      });
      const formated = formatApiRes(res);
      if (res && formated.data) {
        if (formated.success && formated.data && formated.data[1]) {
          setData({
            ...data,
            ...formated.data[1],
          });
        }
      }

      refresh();
      setLoading(false);
    }
  };

  const handleSelectTime = (payload) => {
    setTime(payload.value);
    setTimeResult(payload.result);
  };
  const handleDate = (payload) => {
    setDate(payload.value);
  };
  useEffect(() => {
    if (data && date) {
      const _date = moment(date).format('DD/MM/YYYY');
      setTime(CreateTimeArray({ UserWorkshop: data.UserWorkshop }, _date));
    }
  }, [data, date]);

  const Loader = () => <Loader__wrapper />;

  const Content = () => (
    <Container>
      <Content__container>
        <Header>
          <Title>
            {getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'title' })}
          </Title>

          {/* /* ---------------------------- SLOT CHANGE FLEET --------------------------- */}
          {slotChange && isFleet && (
            <>
              <Grid__separator size="35px" />
              <ConfirmationCard>
                <ConfirmationCard___Title>{getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'fleet_approvement_title' })}</ConfirmationCard___Title>
                <SectionTitle>
                  <Icon icon={faCalendarDay} />
                  {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_summary_title_time_slot' })}
                </SectionTitle>
                <Grid__separator size="15px" />
                <TextContent big crossed>
                  <TextContent__Bold>{sequelizeCustomDate({ date: data.date, format: 'DD MMMM YYYY' })}</TextContent__Bold>
                  {' '}
                  |
                  {' '}
                  {data.time}
                </TextContent>
                <TextContent big>
                  <TextContent__Bold>{data.suggestedDate ? sequelizeCustomDate({ date: data.suggestedDate, format: 'DD MMMM YYYY' }) : ''}</TextContent__Bold>
                  {' '}
                  |
                  {' '}
                  {data.suggestedTime}
                </TextContent>
                <Grid__separator size="45px" />
                <ConfirmationCard___content>
                  {getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'fleet_approvement_info' })}
                </ConfirmationCard___content>
                <ConfirmationCard___button>
                  <Button__primary red event={onDecline}>{getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'fleet_approvement_button_refuse' })}</Button__primary>
                  <Grid__separator width="25px" />
                  <Button__primary blue event={onApprove}>{getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'fleet_approvement_button_validate' })}</Button__primary>
                </ConfirmationCard___button>
              </ConfirmationCard>
            </>
          )}

          {/* /* --------------------- WORKSHOP VIEW ON WAITING EVENT --------------------- */}

          {isWorkshop && data.status === APPOINTMENT_STATUS.waiting && (
            <>
              <Grid__separator size="35px" />
              <ConfirmationCard>
                <ConfirmationCard___Title__workshop>{getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'workshop_approvement_title' })}</ConfirmationCard___Title__workshop>
                <ConfirmationCard___content>{getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'workshop_approvement_info' })}</ConfirmationCard___content>
                <ConfirmationCard___button>
                  <Button__primary red event={onDecline}>{getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'workshop_approvement_button_refuse' })}</Button__primary>
                  <Grid__separator width="25px" />
                  <Button__primary blue event={onApproveWorkshop}>{getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'workshop_approvement_button_validate' })}</Button__primary>
                </ConfirmationCard___button>
                <Grid__separator size="35px" />
                <SeparatorChoice>{getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'workshop_approvement_or' })}</SeparatorChoice>
                <Grid__separator size="25px" />
                <FieldContainer>
                  <Form__datePicker
                    value={date}
                    require
                    label={getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_4_field_1' })}
                    event={handleDate}
                    disabledDays={data && data.UserWorkshop ? getClosedDay(data.UserWorkshop) : []}
                  />
                  <Grid__separator size="30px" />
                  {
                    time && (
                      <Form__select
                        label={getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_4_field_2' })}
                        style={{ minWidth: 150 }}
                        value={time}
                        event={(res) => handleSelectTime({
                          value: res.value,
                          result: res.result,
                        })}
                      />
                    )
                  }
                </FieldContainer>
                <Grid__separator size="25px" />
                <Button__primary border blue event={onRescheduleWorkshop}>{getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'workshop_approvement_suggestion_button' })}</Button__primary>
                <WarningContent>{getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'workshop_approvement_suggestion_info' })}</WarningContent>
              </ConfirmationCard>
            </>
          )}
          <Grid__separator size="35px" />
          <Tag
            icon
            content={statusLabel[data.status]}
            {...parseAppointmentStatus({
              icon: true,
              status: data.status,
            })}
          />
        </Header>
        <Grid__separator size="35px" />
        <SectionTitle>
          <Icon icon={faCalendarDay} />
          {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_summary_title_time_slot' })}
        </SectionTitle>
        <Grid__separator size="15px" />
        { data.suggestedDate ? (
          <>
            <TextContent big crossed>
              <TextContent__Bold>{sequelizeCustomDate({ date: data.date, format: 'DD MMMM YYYY' })}</TextContent__Bold>
              {' '}
              |
              {' '}
              {data.time}
            </TextContent>
            <TextContent big>
              <TextContent__Bold>{data.suggestedDate ? sequelizeCustomDate({ date: data.suggestedDate, format: 'DD MMMM YYYY' }) : ''}</TextContent__Bold>
              {' '}
              |
              {' '}
              {data.suggestedTime}
            </TextContent>
          </>
        ) : (
          <TextContent big>
            <TextContent__Bold>{sequelizeCustomDate({ date: data.date, format: 'DD MMMM YYYY' })}</TextContent__Bold>
            {' '}
            |
            {' '}
            {data.time}
          </TextContent>
        )}
        <Grid__separator size="35px" />
        <SectionTitle>
          <Icon icon={faTruck} />
          {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_summary_title_vehicle' })}
        </SectionTitle>
        <Grid__separator size="15px" />
        <TextContent>
          <TextContent__Bold>{data.Fleet.brand}</TextContent__Bold>
          {' '}
          |
          {' '}
          {data.Fleet.registrationNumber}
          {' '}
          |
          {' '}
          {formatMileage(data.Fleet.mileage)}
          {' '}
          Km
        </TextContent>
        <Grid__separator size="35px" />
        { isFleet && (
          <>
            <SectionTitle>
              <Icon icon={faWarehouse} />
              {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_summary_title_workshop' })}
            </SectionTitle>
            <Grid__separator size="15px" />
            <TextContent>
              <TextContent__Bold>{data.UserWorkshop.UserWorkshopAlias.companyName}</TextContent__Bold>
              <br />
              {data.UserWorkshop.UserWorkshopAlias.adress}
              ,
              {' '}
              {data.UserWorkshop.UserWorkshopAlias.zipcode}
              {' '}
              {data.UserWorkshop.UserWorkshopAlias.adress}
            </TextContent>
            <MapContainer className="mapContainer">
              {
                data.UserWorkshop.UserWorkshopAlias.lat && data.UserWorkshop.UserWorkshopAlias.lng && (

                  <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API }}
                    zoomControl={false}
                    mapTypeControl={false}
                    scaleControl={false}
                    streetViewControl={false}
                    rotateControl={false}
                    fullscreenControl={false}
                    options={{ styles }}
                    defaultZoom={15}
                    center={{
                      lat: parseFloat(data.UserWorkshop.UserWorkshopAlias.lat),
                      lng: parseFloat(data.UserWorkshop.UserWorkshopAlias.lng),
                    }}
                  >
                    <Marker
                      lat={data.UserWorkshop.UserWorkshopAlias.lat}
                      lng={data.UserWorkshop.UserWorkshopAlias.lng}
                    >
                      <MarkerChild />
                    </Marker>
                  </GoogleMapReact>
                )
              }
            </MapContainer>
            <Grid__separator size="35px" />
          </>
        )}
        <SectionTitle>
          <Icon icon={faAlignJustify} />
          {' '}
          {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_summary_title_information' })}
        </SectionTitle>
        <Grid__separator size="15px" />
        <TextContent>
          <TextContent__Bold>{appointmentType[data.type]}</TextContent__Bold>
          <br />
          {data.description}
        </TextContent>
        <Grid__separator size="60px" />
        { isFleet && data.status !== APPOINTMENT_STATUS.done && data.status !== APPOINTMENT_STATUS.refused && (
          <>
            <CardsWarning>
              <Button__primary border red event={onDecline}>{getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'cancel_button' })}</Button__primary>
              <Grid__separator size="15px" />
              <Warning__content>
                {getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'cancel_information' })}
              </Warning__content>
            </CardsWarning>
            <Grid__separator size="35px" />
          </>
        )}
        <RequestContent>
          {getFieldGroup({ base: basePage, groupe: 'appointment_single', field: 'request_creation_label' })}
          {' '}
          <TextContent__Bold>
            {sequelizeCustomDate({ date: data.createdAt, format: 'DD MMMM YYYY | HH:mm' })}
            {' '}
            | ID
            {' '}
            {data.id}
          </TextContent__Bold>
        </RequestContent>
      </Content__container>
    </Container>
  );

  return (
    <Popup active={active} closeEvent={closeEvent}>
      {loading || !data ? <Loader /> : <Content />}
    </Popup>

  );
};

export default Appointment__Single;

