
import React, { useContext } from 'react';
import styled from 'styled-components';

import { List__table, List__content } from 'components/list/list';
import { Grid__card } from 'components/grid/grid';
import Cards__navTab from 'components/cards/navTab/cards__navTab';

import Content__tag from 'components/content/tag/content__tag';
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import { sequelizeDate } from 'util/date.util';
import { parseStatus } from 'util/conditions/statusProps.util';
import { strapiPaymentGuaranteeService, strapiServiceHeader, strapiWorkshopDashboardPaymentGuarantee } from 'util/strapi/content.starpi';
import { OBLIGO } from 'util/constant/obligo.constant';
import { getPaymentGuaranteeStatus } from 'util/service/paymentGuarenteeStatus';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';


const Container = styled.div`
  height: 100%;
  min-height: 400px;
`;

const Card = styled(Grid__card)`
  height: 100%;
`;

const Table = styled(List__table)`
  margin-top: 60px;
  height: calc(100% - 60px);
`;

const DashboardPaymentGuarantee__historic = ({
  style, tableEvent, data, scrollEndEvent, loadingNewPage, activeTab, setActiveTab, count, brut,
}) => {
  const { getFieldGroup, getField, getGlobal } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const haveCounter = (key) => {
    if (brut && brut.brute) {
      if (brut.brute[key] || brut.brute[key] === 0) {
        return `${brut.brute[key]}`;
      }
    }
    return false;
  };
  const received = haveCounter('received');
  const own = haveCounter('own');
  const navItems = [
    {
      label: `${getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'tab_navigation_requests_received' })} ${received ? `(${received})` : ''}`,
      active: activeTab === 0,
      id: 0,
    },
    {
      label: `${getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'tab_navigation_requests_send' })} ${own ? `(${own})` : ''}`,
      active: activeTab === 1,
      id: 1,
    },
  ];
  const handleTabChange = (id) => {
    setActiveTab(id);
  };
  const headerCol = [
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'id_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'vehicle_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'date_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'type_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'status_header' }),
    },
  ];


  const renderList = () => {
    const orderArray = data;
    const arrayOfLine = [];

    orderArray.forEach((element) => {
      arrayOfLine.push([
        {
          component: <List__content uppercase>{element.uid}</List__content>,
          type: 'node',
        },
        {
          component: (
            <List__content>{element.Fleet.registrationNumber}</List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>
              {sequelizeDate({ date: element.createdAt, format: 'd/m/y' })}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>
              {getGlobal({ option: 'obligo', field: OBLIGO.type[element.type] })}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <Content__tag
              content={getFieldGroup({
                base: strapiPaymentGuaranteeService,
                groupe: 'status',
                field: getPaymentGuaranteeStatus({
                  status: element.status,
                  user,
                  vehicle: element.Fleet,
                }),
              })}

              {...parseStatus({
                status: getPaymentGuaranteeStatus({
                  status: element.status,
                  user,
                  vehicle: element.Fleet,
                }),
              })}
            />
          ),
          type: 'node',
        },
      ]);
    });

    return arrayOfLine;
  };

  const handleLineClick = (vehicle) => {
    tableEvent(vehicle, data[vehicle.key]);
  };

  return (
    <Container style={style}>
      <Card padding="30px">
        <Cards__navTab
          items={navItems}
          event={handleTabChange}
        />
        <Table
          headerCol={headerCol}
          lineCol={data ? renderList() : []}
          noSelect
          lineEvent={handleLineClick}
          maxHeight="100%"
          scrollEndEvent={scrollEndEvent}
        />
        {
          loadingNewPage && (
            <Loader__wrapper />
          )
        }
      </Card>
    </Container>
  );
};

export default DashboardPaymentGuarantee__historic;
