import React, { Fragment } from 'react';
import styled from 'styled-components';
import Button__primary from 'components/button/primary/button__primary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Grid__separator } from 'components/grid/grid';
import { faTruck } from '@fortawesome/pro-solid-svg-icons';


const VehicleCard = styled.div`
  width: ${(props) => (props.noCard && props.noForm ? '100%' : props.noCard ? 'auto' : '340px')};
  background: #E6F8FC;
  border-radius: 10px;
  text-align: left;
  padding: ${(props) => (props.noCard ? 0 : '45px')};
`;

const VehicleTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #4C4C4C;
`;

const VehicleLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #4C4C4C;
`;

const ContainerItem = styled.div`
`;

const Container = styled.div`
  display: ${(props) => (props.noForm ? 'flex' : 'block')};
  justify-content: space-between;
`;

const VehicleContent = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #01B5E2;
  margin-top: 8px;
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : props.capitalize ? 'capitalize' : 'normal')};
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  line-height: 13px;
  color: #4C4C4C;
  margin-right: 15px;
`;


const Cards__vehicle = ({
  title,
  data,
  button,
  event,
  noCard,
  noForm,
}) => (
  <VehicleCard noCard={noCard} noForm={noForm}>
    <VehicleTitle>
      <Icon icon={faTruck} />
      {title}
    </VehicleTitle>
    <Grid__separator size="5px" />
    <Container noForm={noForm}>
      {data.map((item, key) => (
        <ContainerItem key={key}>
          <Grid__separator size="20px" />
          <VehicleLabel>{item.label}</VehicleLabel>
          <VehicleContent uppercase={item.uppercase} capitalize={item.capitalize}>{item.value}</VehicleContent>
        </ContainerItem>
      ))}
    </Container>
    {button && (
      <Fragment>
        <Grid__separator size="20px" />
        <Button__primary
          withIcon
          noprevent
          icon={<FontAwesomeIcon icon={faPlus} />}
          event={event}
        >
          {button}
        </Button__primary>
      </Fragment>
    )}
  </VehicleCard>
);

export default Cards__vehicle;
