import React, {
  useState, forwardRef, useImperativeHandle, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import Onboarding__step from 'components/onboarding/steps/onboarding__step';
import Grid__popup from 'components/grid/popup/grid__popup';
import Button__primary from 'components/button/primary/button__primary';
import { createResponseObj } from 'helpers/js/Form';
import {
  updateUserInformation,
} from 'helpers/api/Routes';
import { strapiHomePage } from 'util/strapi/content.starpi';
import { ContentContext } from 'context/contentContext';
import Transition__loader_controller from 'components/transition/loader_controller/transition__loader_controller';

const Button = styled(Button__primary)`
  margin-top: 35px;
  margin-bottom: 45px;
`;

const Popup = styled(Grid__popup)`
  display: flex;
  flex-direction: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: center;
  align-items: center;
  width: 100%;
`;

const Point = styled.div`
  width: 7px;
  height: 7px;
  background: ${(props) => (props.active ? '#000' : '#D9D9D9')};;
  margin: 0 7px;
  border-radius: 50%;
`;

const Onboarding__switcher = forwardRef(({
  visible,
  account,
}, ref) => {
  const {
    getGlobal, getGlobalWithNavigatorLang, loadPage, haveContentFor, loadGlobal,
  } = useContext(ContentContext);
  const [open, setOpen] = useState(visible);
  const [step, setstep] = useState(0);
  const [status, setstatus] = useState('init');

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const stepArray = [
    {
      slug: 'step1',
    },
    {
      slug: 'step2',
    },
    {
      slug: 'step3',
    },
    {
      slug: 'step4',
    },
    {
      slug: 'step5',
    },
  ];

  const isEnd = () => {
    if (step + 1 > stepArray.length - 1) {
      return true;
    }

    return false;
  };


  const saveEvent = async () => {
    const arrayOfField = [
      { value: { type: 'bool', result: true }, key: 'view_tuto' },
    ];

    const data = createResponseObj({ data: arrayOfField });
    await updateUserInformation({ field: data });
    setOpen(false);
  };

  const handleCloseEvent = () => {
    visible = false;
    saveEvent();
    setTimeout(() => {
      setstep(0);
    }, 600);
  };

  const handleNextStep = () => {
    if (!isEnd()) {
      setstatus('hide');
      const current = step;
      setTimeout(() => {
        setstep(current + 1);
        setstatus('show');
      }, 600);
    } else {
      handleCloseEvent();
    }
  };

  const renderStep = () => {
    if (stepArray[step].slug === 'step1') {
      return (
        <Onboarding__step
          image={t.image.illu.onboard1}
          title={account ? getGlobal({ option: 'onboarding', field: 'step1_title' }) : getGlobalWithNavigatorLang({ option: 'onboarding', field: 'step1_title' })}
          content={account ? getGlobal({ option: 'onboarding', field: 'step1_text' }) : getGlobalWithNavigatorLang({ option: 'onboarding', field: 'step1_text' })}
          step={stepArray[step].slug}
          status={status}
        />
      );
    }
    if (stepArray[step].slug === 'step2') {
      return (
        <Onboarding__step
          image={t.image.illu.onboard2}
          title={account ? getGlobal({ option: 'onboarding', field: 'step2_title' }) : getGlobalWithNavigatorLang({ option: 'onboarding', field: 'step2_title' })}
          content={account ? getGlobal({ option: 'onboarding', field: 'step2_text' }) : getGlobalWithNavigatorLang({ option: 'onboarding', field: 'step2_text' })}
          step={stepArray[step].slug}
          status={status}
        />
      );
    }
    if (stepArray[step].slug === 'step3') {
      return (
        <Onboarding__step
          image={t.image.illu.onboard3}
          title={account ? getGlobal({ option: 'onboarding', field: 'step3_title' }) : getGlobalWithNavigatorLang({ option: 'onboarding', field: 'step3_title' })}
          content={account ? getGlobal({ option: 'onboarding', field: 'step3_text' }) : getGlobalWithNavigatorLang({ option: 'onboarding', field: 'step3_text' })}
          step={stepArray[step].slug}
          status={status}
        />
      );
    }
    if (stepArray[step].slug === 'step4') {
      return (
        <Onboarding__step
          image={t.image.illu.onboard4}
          title={account ? getGlobal({ option: 'onboarding', field: 'step4_title' }) : getGlobalWithNavigatorLang({ option: 'onboarding', field: 'step4_title' })}
          content={account ? getGlobal({ option: 'onboarding', field: 'step4_text' }) : getGlobalWithNavigatorLang({ option: 'onboarding', field: 'step4_text' })}
          step={stepArray[step].slug}
          status={status}
        />
      );
    }
    if (stepArray[step].slug === 'step5') {
      return (
        <Onboarding__step
          image={t.image.illu.onboard5}
          title={account ? getGlobal({ option: 'onboarding', field: 'step5_title' }) : getGlobalWithNavigatorLang({ option: 'onboarding', field: 'step5_title' })}
          content={account ? getGlobal({ option: 'onboarding', field: 'step5_text' }) : getGlobalWithNavigatorLang({ option: 'onboarding', field: 'step5_text' })}
          step={stepArray[step].slug}
          status={status}
        />
      );
    }
    return null;
  };
  const loadContent = async () => {
    await loadGlobal(true);
    if (
      haveContentFor(strapiHomePage).success === false
    ) {
      await loadPage(strapiHomePage);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (
    haveContentFor(strapiHomePage).success === false
  ) {
    return <Transition__loader_controller active />;
  }
  return (
    <Popup active={open} center closeEvent={handleCloseEvent}>
      <Transition__loader_controller active={false} />
      <Container>
        {renderStep()}
        <Button event={handleNextStep}>
          {isEnd() ? getGlobalWithNavigatorLang({ option: 'onboarding', field: 'btn_end' }) : getGlobalWithNavigatorLang({ option: 'onboarding', field: 'btn_next' })}
        </Button>
        <div style={{
          display: 'flex',
        }}
        >
          {
            stepArray.map((el, key) => (
              <Point key={key} active={key === step ? true : false} />
            ))
          }
        </div>
      </Container>
    </Popup>
  );
});

export default Onboarding__switcher;
