export const isAdmin = (user) => {
  if (user && user.user && user.user.role && user.user.role === 'admin') {
    return true;
  }

  return false;
};

export const isFleet = (user) => {
  if (user && user.user && user.user.role && user.user.role === 'fleet') {
    return true;
  }

  return false;
};

export const isWorkshop = (user) => {
  if (user && user.user && user.user.role && user.user.role === 'workshop') {
    return true;
  }

  return false;
};

export const returnRouting = ({ user, fleet, workshop }) => {
  if (isWorkshop(user)) {
    return workshop;
  }

  return fleet;
};
