
import { format_apiobject } from 'util/formatContent.util';
/* --------------------------- GET FLEET FROM USER -------------------------- */

export const GetFleetFromUser = ({ user }) => {
  let hasValue = {
    success: false,
  };

  if (user && user.hasOwnProperty('UserFleet')) {
    if (user.UserFleet.hasOwnProperty('Fleets')) {
      if (user.UserFleet.Fleets !== null) {
        hasValue = {
          success: true,
          data: user.UserFleet.Fleets,
        };
      }
    }
  }

  return hasValue;
};

export const parseFleetFromUser = ({ user }) => {
  let hasValue = {
    success: false,
  };

  if (user.hasOwnProperty('UserFleet')) {
    if (user.UserFleet.hasOwnProperty('Fleets')) {
      if (user.UserFleet.Fleets !== null) {
        hasValue = {
          success: true,
          data: user.UserFleet.Fleets,

        };
      }
    }
  }

  return format_apiobject({ obj: hasValue });
};

/* --------------------------- GET HOMEBASE FROM USER -------------------------- */

export const GetHomeBaseFromUser = ({ user }) => {
  let hasValue = {
    success: false,
    data: {},
    extra: {},
  };

  if (user) {
    if (user.hasOwnProperty('UserFleet') && user.UserFleet) {
      if (user.UserFleet.hasOwnProperty('UserWorkshop')) {
        if (user.UserFleet.UserWorkshop !== null && user.UserFleet.favWorkshopValidation === true) {
          hasValue = {
            success: true,
            data: user.UserFleet.UserWorkshop.UserWorkshopAlias,
            extra: user.UserFleet.UserWorkshop,
          };
        }
      }
    }
  }

  return hasValue;
};
