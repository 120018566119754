export const getFleetInformation = ({ obj, key }) => {
  if (obj) {
    if (obj.hasOwnProperty('Fleet')) {
      if (obj.Fleet) {
        if (obj.Fleet.hasOwnProperty(key)) {
          if (obj.Fleet[key]) {
            return obj.Fleet[key];
          }
        }
      }
    }
  }

  return '';
};

export const getUserFleetInformation = ({ obj, key }) => {
  if (obj) {
    if (obj.hasOwnProperty('UserFleet')) {
      if (obj.UserFleet) {
        if (obj.UserFleet.hasOwnProperty(key)) {
          if (obj.UserFleet[key]) {
            return obj.UserFleet[key];
          }
        }
      }
    }
  }

  return '';
};

export const getFleetUserInformation = ({ obj, key }) => {
  if (obj) {
    if (obj.hasOwnProperty('Fleet')) {
      if (obj.Fleet) {
        if (obj.Fleet.hasOwnProperty('UserFleet')) {
          if (obj.Fleet.UserFleet) {
            if (obj.Fleet.UserFleet.hasOwnProperty('UserFleetAlias')) {
              if (obj.Fleet.UserFleet.UserFleetAlias) {
                if (obj.Fleet.UserFleet.UserFleetAlias.hasOwnProperty(key)) {
                  if (obj.Fleet.UserFleet.UserFleetAlias[key]) {
                    return obj.Fleet.UserFleet.UserFleetAlias[key];
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return '';
};

export const getFleetUserInformation_noAlias = ({ obj, key }) => {
  if (obj) {
    if (obj.hasOwnProperty('Fleet')) {
      if (obj.Fleet) {
        if (obj.Fleet.hasOwnProperty('UserFleet')) {
          if (obj.Fleet.UserFleet) {
            if (obj.Fleet.UserFleet.hasOwnProperty(key)) {
              if (obj.Fleet.UserFleet[key]) {
                return obj.Fleet.UserFleet[key];
              }
            }
          }
        }
      }
    }
  }

  return '';
};

export const getWorkshopInformation = ({ obj, key }) => {
  if (obj) {
    if (obj.hasOwnProperty('UserWorkshop')) {
      if (obj.UserWorkshop) {
        if (obj.UserWorkshop.hasOwnProperty('UserWorkshopAlias')) {
          if (obj.UserWorkshop.UserWorkshopAlias) {
            if (obj.UserWorkshop.UserWorkshopAlias.hasOwnProperty(key)) {
              if (obj.UserWorkshop.UserWorkshopAlias[key]) {
                return obj.UserWorkshop.UserWorkshopAlias[key];
              }
            }
          }
        }
      }
    }
  }

  return '';
};
