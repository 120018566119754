import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Form__textfield } from 'components/form/form';
import Form__checkbox from 'components/form/checkbox/form__checkbox';
import Grid__separator from 'components/grid/separator/grid__separator';

const Container = styled.div``;

const DayLine = styled.div`
  display: flex;
  align-items: center;
`;

const Day = styled.div`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #4D4D4D;
  width: 120px;
  min-width: 120px;
`;

const Separator = styled.div`
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #4D4D4D;
  margin-right: 5px;
  margin-left: 5px;
`;

const Form__hourly = ({ value, changeValue, submitHourly }) => {
  const renderValue = (unFormatedValue, prevValue) => {
    const regex = /^[0-9]+$/;
    let tempValue = unFormatedValue;
    if (unFormatedValue && !regex.test(unFormatedValue.replace(':', ''))) {
      return prevValue;
    }
    if (unFormatedValue.length > 5) {
      return prevValue;
    }

    tempValue = '';
    for (let i = 0; i < unFormatedValue.replace(':', '').length; i++) {
      if (i === 0 && parseInt(unFormatedValue[0], 10) > 2) {
        tempValue = `0${tempValue}`;
      }
      if (i === 2) {
        tempValue += ':';
      }
      tempValue += unFormatedValue.replace(':', '').charAt(i);
    }

    return tempValue;
  };

  const isAnError = (field) => {
    if (!field.value || field.value === '' || (field.value && field.value.length < 4)) {
      return true;
    }

    return false;
  };

  const handleCheckValues = (newValue) => {
    newValue.forEach((element) => {
      if (element.active) {
        element.amStart.error = isAnError(element.amStart);
        element.amEnd.error = isAnError(element.amEnd);
        element.pmStart.error = isAnError(element.pmStart);
        element.pmEnd.error = isAnError(element.pmEnd);
      }
    });

    return newValue;
  };


  const handleChangeValue = (newValue) => {
    changeValue(handleCheckValues(newValue));
  };

  const handleInput = (res, hours, key) => {
    const temp = [...value];
    temp[key][hours].value = renderValue(res.value, temp[key][hours].value);
    handleChangeValue(temp);
  };

  const handleCheckBox = (res, key) => {
    const temp = [...value];
    temp[key].active = res.value;
    if (!res.value) {
      temp[key].amStart.value = '';
      temp[key].amEnd.value = '';
      temp[key].pmStart.value = '';
      temp[key].pmEnd.value = '';
    }
    handleChangeValue(temp);
  };

  const renderInput = () => {
    if (value) {
      return value.map((item, key) => (
        <Fragment key={key}>
          <DayLine>
            <Form__checkbox
              square
              small
              value={item.active}
              callback={(res) => handleCheckBox(res, key)}
            />
            <Day>{item.day}</Day>
            <Form__textfield
              padding="10px"
              style={item.active ? { maxWidth: 63 } : { maxWidth: 63, pointerEvents: 'none', opacity: 0.4 }}
              lock={!item.active}
              require
              placeholder="00:00"
              value={item.amStart.value}
              error={submitHourly && item.active && item.amStart.error && item.pmStart.error ? { active: true } : false}
              change={(res) => handleInput(res, 'amStart', key)}
            />
            <Separator>-</Separator>
            <Form__textfield
              padding="10px"
              style={item.active ? { maxWidth: 63 } : { maxWidth: 63, pointerEvents: 'none', opacity: 0.4 }}
              lock={!item.active}
              require
              placeholder="00:00"
              value={item.amEnd.value}
              change={(res) => handleInput(res, 'amEnd', key)}
              error={submitHourly && !item.amStart.error && item.amEnd.error ? { active: true } : false}
            />
            <Separator> / </Separator>
            <Form__textfield
              padding="10px"
              style={item.active ? { maxWidth: 63 } : { maxWidth: 63, pointerEvents: 'none', opacity: 0.4 }}
              lock={!item.active}
              require
              placeholder="00:00"
              value={item.pmStart.value}
              change={(res) => handleInput(res, 'pmStart', key)}
              error={submitHourly && item.active && item.amStart.error && item.pmStart.error ? { active: true } : false}
            />
            <Separator>-</Separator>
            <Form__textfield
              padding="10px"
              style={item.active ? { maxWidth: 63 } : { maxWidth: 63, pointerEvents: 'none', opacity: 0.4 }}
              lock={!item.active}
              require
              placeholder="00:00"
              value={item.pmEnd.value}
              change={(res) => handleInput(res, 'pmEnd', key)}
              error={submitHourly && !item.pmStart.error && item.pmEnd.error ? { active: true } : false}
            />
          </DayLine>
          <Grid__separator size="15px" />
        </Fragment>
      ));
    }

    return null;
  };
  return (
    <Container>
      {renderInput()}
    </Container>
  );
};
export default Form__hourly;
