
import React, { useContext } from 'react';
import styled from 'styled-components';
/**
 * import containers & components
 */
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import { Content__sectionTitle } from 'components/content/content';

import Button__primary from 'components/button/primary/button__primary';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { faFileContract } from '@fortawesome/pro-solid-svg-icons';
import { strapiAccountPage } from 'util/strapi/content.starpi';
import { strapiContract } from '../../../../util/strapi/content.starpi';

/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const FormContainer = styled.div``;

const Account__password = ({ style }) => {
  const { getField, getFiles } = useContext(ContentContext);
  const { user } = useContext(AppContext);

  const renderContract = (role) => {
    if (role === 'fleet') {
      return getFiles({ base: strapiContract, field: 'fleet_contract', _lang: user.user.countryCode });
    }
    if (role === 'workshop') {
      return getFiles({ base: strapiContract, field: 'workshop_contract', _lang: user.user.countryCode });
    }
    return '';
  };
  /* ------------------------------- SAVE EVENT ------------------------------- */

  const saveEvent = async () => { };

  /* ------------------------------- RENDER FORM ------------------------------ */

  const renderContactInformationForm = () => (
    <FormContainer>
      <Button__primary
        noprevent
        target="_blank"
        withIcon
        download
        href={user.user && renderContract(user.user.role)}
        icon={<FontAwesomeIcon icon={faDownload} />}
        event={() => saveEvent()}
      >
        {getField({ base: strapiAccountPage, field: 'download_contract' })}
      </Button__primary>
    </FormContainer>
  );

  /* ------------------------------ GLOBAL RENDER ----------------------------- */

  return (
    <Container style={style}>
      <Content__sectionTitle
        title={getField({ base: strapiAccountPage, field: 'contract_title' })}
        icon={<FontAwesomeIcon icon={faFileContract} />}
      />
      {renderContactInformationForm()}
    </Container>
  );
};

export default Account__password;
