
/**
   * Import Lib
   */

import React, {
  useEffect, useContext, useState, Fragment,
} from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/pro-regular-svg-icons';
import Content__tag from 'components/content/tag/content__tag';
import Grid__separator from 'components/grid/separator/grid__separator';
import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import { AppContext } from 'context/appContext';

import { faUserCheck, faListUl } from '@fortawesome/pro-solid-svg-icons';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { List__table, List__content } from 'components/list/list';

import { parseStatus } from 'util/conditions/statusProps.util';
import { strapiPaymentGuaranteeService, strapiServiceHeader, strapiWorkshopDashboardPaymentGuarantee } from 'util/strapi/content.starpi';
import { ContentContext } from 'context/contentContext';
import { formatPrice } from 'util/format/price.format';
import DashboardPaymentGuarantee__obligoSinglePop from 'containers/workshopView/dashboardPaymentGuarantee/obligoSinglePop/dashboardPaymentGuarantee__obligoSinglePop';
import { sequelizeDate } from 'util/date.util';
import { OBLIGO } from 'util/constant/obligo.constant';
import DashboardPaymentGuarantee__singlePop from 'containers/workshopView/dashboardPaymentGuarantee/singlePop/dashboardPaymentGuarantee__singlePop';
import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import { getAction } from 'util/function/reloadAction.function';
import { getObligoTotal } from 'util/function/obligo';
import { getPaymentGuaranteeStatus } from 'util/service/paymentGuarenteeStatus';
import { GetObligoDetails } from 'helpers/api/routes/Workshop';
import {
  Title,
  Popup,
} from 'containers/workshopView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import { SERVICENAME } from 'util/service/serviceName';
import { useData } from 'components/data/getter/data__getter';


const InfoHeader = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #4D4D4D;
  max-width: 700px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DashboardPaymentGuarantee__obligoPop = ({
  style,
  active,
  closeEvent,
  setActivePopUp,
}) => {
  const { user } = useContext(AppContext);
  const {
    getFieldGroup, getGlobal, getField,
  } = useContext(ContentContext);
  const [actionDone, setactionDone] = useState(false);
  const [obligoarray, setobligoarray] = useState([]);
  const [activesinglePop, setactivesinglePop] = useState(false);
  const [settingsPop, setsettingsPop] = useState({ active: false, data: null, key: null });
  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      id: user.user.UserWorkshop.id,
      user: true,
      workshop: true,
      obligo: true,
    },
    service: SERVICENAME.paymentguarantee,
    type: 'service',
  });

  const autorizationHeaderCol = [
    {
      label: getField({ base: strapiPaymentGuaranteeService, field: 'obligoPop_autorisation_workshop_company' }),
    },
    {
      label: getField({ base: strapiPaymentGuaranteeService, field: 'obligoPop_autorisation_workshop_city' }),
    },
    {
      label: getField({ base: strapiPaymentGuaranteeService, field: 'obligoPop_autorisation_workshop_available' }),
    },
    {
      label: getField({ base: strapiPaymentGuaranteeService, field: 'obligoPop_autorisation_workshop_used' }),
    },
    {
      label: getField({ base: strapiPaymentGuaranteeService, field: 'obligoPop_autorisation_workshop_status' }),
    },
  ];

  const historicHeaderCol = [
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'id_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'vehicle_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'date_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'type_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'amount_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'status_header' }),
    },
  ];

  const renderList = () => {
    const orderArray = resData.data;
    const arrayOfLine = [];

    orderArray.forEach((element) => {
      arrayOfLine.push([
        {
          component: <List__content uppercase>{element.uid}</List__content>,
          type: 'node',
        },
        {
          component: (
            <List__content>{element.Fleet.registrationNumber}</List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>
              {sequelizeDate({ date: element.createdAt, format: 'd/m/y' })}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>
              {getGlobal({ option: 'obligo', field: OBLIGO.type[element.type] })}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content capitalize>
              {formatPrice(element.price)}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <Content__tag
              content={getFieldGroup({
                base: strapiPaymentGuaranteeService,
                groupe: 'status',
                field: getPaymentGuaranteeStatus({
                  status: element.status,
                  user,
                  vehicle: element.Fleet,
                }),
              })}

              {...parseStatus({
                status: getPaymentGuaranteeStatus({
                  status: element.status,
                  user,
                  vehicle: element.Fleet,
                }),
              })}
            />
          ),
          type: 'node',
        },
      ]);
    });

    return arrayOfLine;
  };

  const handleOpenSinglePop = ({ id }) => {
    setactivesinglePop({ active: true, id });
  };

  const handleLineClick = (vehicle) => {
    handleOpenSinglePop({ id: resData.data[vehicle.key].id });
  };

  const calculateUsed = (available, amount) => {
    if (available && amount) {
      return parseInt(amount, 10) - parseInt(available, 10);
    }

    if (amount && !available) {
      return parseInt(amount, 10);
    }

    return 0;
  };

  const autorizationLineEvent = (value) => {
    const curentLine = obligoarray[value.key];
    setsettingsPop({ active: true, data: curentLine, key: value.key });
  };

  const renderObligoStatus = (status) => {
    if (status === SERVICE_STATUS.waiting || status === SERVICE_STATUS.action) {
      return SERVICE_STATUS.action;
    }

    return status;
  };

  const renderAuthorizationLine = () => {
    const orderArray = obligoarray;
    const arrayOfLine = [];
    orderArray.forEach((element) => {
      arrayOfLine.push([
        {
          component: <List__content uppercase>{element.companyName}</List__content>,
          type: 'node',
        },
        {
          component: (
            <List__content>{element.city}</List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>{formatPrice(calculateUsed(element.UserFleet.obligoAvailable, element.UserFleet.obligoAmount))}</List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>{formatPrice(element.UserFleet.obligoAvailable)}</List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <Content__tag
              content={getFieldGroup({
                base: strapiPaymentGuaranteeService,
                groupe: 'status',
                field: renderObligoStatus(element.UserFleet.obligoStatus),
              })}

              {...parseStatus({ status: renderObligoStatus(element.UserFleet.obligoStatus) })}
            />
          ),
          type: 'node',
        },
      ]);
    });

    return arrayOfLine;
  };


  const handleCheckParams = (array) => {
    const actionParams = getAction({ key: 'obligoSettings' });
    if (actionParams.success === true) {
      const curentLine = array[actionParams.value];
      setactionDone(true);
      if (setActivePopUp) {
        setActivePopUp();
      }
      setsettingsPop({ active: true, data: curentLine, key: actionParams.value });
    }
  };

  const getObligoDetails = async () => {
    const res = await GetObligoDetails({
      workshopId: user.user.UserWorkshop.id,
    });
    const formated = formatApiRes(res);
    if (formated.data) {
      if (!actionDone) {
        handleCheckParams(formated.data);
      }
      setobligoarray(formated.data);
    }
  };

  useEffect(() => {
    getObligoDetails();
  }, []);

  const handleCloseSettings = () => {
    setsettingsPop({ active: false, data: null, key: null });
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({ active: false, id: null });
  };

  const obligoTotalAmount = getObligoTotal({ data: obligoarray });
  return (
    <Fragment>
      <Popup style={style} active={active} closeEvent={closeEvent}>
        <Container>
          <Title>{getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'obligoPop_title' })}</Title>
          <Grid__separator size="15px" />
          <InfoHeader>{getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'obligoPop_description' })}</InfoHeader>
          <Grid__separator size="80px" />
          <Content__sectionTitle
            line
            icon={<FontAwesomeIcon icon={faChartBar} />}
            title={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'obligoPop_title_global_informations' })}
          />
          <Row>
            <Content__bigTag
              column
              label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_used' })}
              value={formatPrice(obligoTotalAmount.used)}
            />
            <Content__bigTag
              style={{ marginLeft: 20, marginRight: 20 }}
              column
              label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_remaining' })}
              value={formatPrice(obligoTotalAmount.available)}
            />
            <Content__bigTag
              column
              label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_autorised' })}
              value={formatPrice(obligoTotalAmount.amount)}
            />
          </Row>
          <Grid__separator size="100px" />
          <Content__sectionTitle
            line
            icon={<FontAwesomeIcon icon={faUserCheck} />}
            title={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'obligoPop_title_fleets_autorisations' })}
          />
          <List__table
            headerCol={autorizationHeaderCol}
            lineCol={renderAuthorizationLine()}
            noSelect
            lineEvent={autorizationLineEvent}
          />
          <Grid__separator size="100px" />
          <Content__sectionTitle
            line
            icon={<FontAwesomeIcon icon={faListUl} />}
            title={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'obligoPop_title_global_historics' })}
          />
          <List__table
            headerCol={historicHeaderCol}
            lineCol={resData && resData.data ? renderList() : []}
            noSelect
            lineEvent={handleLineClick}
          />
        </Container>
      </Popup>
      <DashboardPaymentGuarantee__obligoSinglePop active={settingsPop} closeEvent={handleCloseSettings} />
      <DashboardPaymentGuarantee__singlePop
        active={activesinglePop.active}
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
    </Fragment>

  );
};

export default DashboardPaymentGuarantee__obligoPop;
