
/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

const Intro = styled.div`
  margin-top: 65px;
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-start', direction: 'row' })};
`;

const Intro__content = styled.div`
  max-width: 500px;
`;

const Content__title = styled.div`
  font-weight: normal;
  font-size: 36px;
  color: #4d4d4d;
`;

const Content__txt = styled.div`
  margin-top: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #999999;
  p {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
`;

const Intro__price = styled.div`
  background: #e6f8fc;
  border-radius: 10px;
  text-align: right;
  height: 75px;
  color: #01b5e2;
  padding-left: 25px;
  padding-right: 25px;
  ${t.mixins.flex({ justify: 'flex-end', align: 'center', direction: 'row' })};
`;

const Price__value = styled.span`
  font-weight: 600;
  font-size: 30px;
`;

const Price__label = styled.span`
  font-weight: normal;
  font-size: 16px;
  margin-left: 10px;
`;

const Brand = styled.div`
  display: flex;
  margin-top: 65px;
  margin-right: 70px;
  flex-direction: column;
  justify-content: center;
`;

const BrandLabel = styled.div`
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;

const Img = styled.img`
  margin-top: 5px;
  height: 45px;
`;

const SingleService__intro = ({
  style, title, txt, price, label, brandLabel,
}) => (
  <Intro style={style}>
    <Intro__content>
      <Content__title>{title}</Content__title>
      <Content__txt>{txt}</Content__txt>
    </Intro__content>
    { !brandLabel && price ? (
      <Intro__price>
        <Price__value>{price}</Price__value>
        {label && (<Price__label>{label}</Price__label>)}
      </Intro__price>
    ) : brandLabel ? (
      <Brand>
        <BrandLabel>Powered by</BrandLabel>
        <Img src={brandLabel} alt="brand partner" />
      </Brand>
    ) : null}
  </Intro>
);

export default SingleService__intro;
