
import React, { useContext } from 'react';
import styled from 'styled-components';

import { List__table, List__content } from 'components/list/list';
import { Grid__card } from 'components/grid/grid';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';

import { sequelizeDate } from 'util/date.util';
import t from 'theme';
import { ContentContext } from 'context/contentContext';
import { strapiStandartrepairService, strapiServiceHeader } from 'util/strapi/content.starpi';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { getFormService } from 'util/function/getter';

const Container = styled.div`
  height: 100%;
  min-height: 400px;
`;

const ListCardContainer = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'center' })};
  height: 40px;
  margin-bottom: 30px;
`;

const Card = styled(Grid__card)`
  height: 100%;
`;

const Table = styled(List__table)`
  height: calc(100% - 70px);
`;

const DashboardStandardRepair__historic = ({
  style, tableEvent, loadingData, data, scrollEndEvent, loadingNewPage,
}) => {
  const { getGlobal, getField } = useContext(ContentContext);
  const fairPrice = data;
  const headerCol = [
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'id_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'vehicle_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'date_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'repairprice_header' }),
    },
  ];

  const renderList = () => {
    const orderArray = fairPrice;
    const arrayOfLine = [];

    orderArray.forEach((element) => {
      arrayOfLine.push([
        {
          component: <List__content uppercase>{getFormService(element, 'uid')}</List__content>,
          type: 'node',
        },
        {
          component: <List__content>{getFormService(element, 'registrationNumber', 'vehicle')}</List__content>,
          type: 'node',
        },
        {
          component: <List__content>{sequelizeDate({ date: getFormService(element, 'createdAt'), format: 'd/m/y' })}</List__content>,
          type: 'node',
        },
        {
          component: <List__content>{`${getFormService(element, 'price')} €`}</List__content>,
          type: 'node',
        },
      ]);
    });

    return arrayOfLine;
  };

  const handleLineClick = (vehicle) => {
    tableEvent(vehicle, fairPrice[vehicle.key]);
  };

  return (
    <Container style={style}>
      <Card padding="30px">
        <ListCardContainer>
          <Content__cardTitle
            content={getField({ base: strapiStandartrepairService, field: 'dashboard_historic_title' })}
          />
        </ListCardContainer>
        <Table
          headerCol={headerCol}
          lineCol={data ? renderList() : []}
          noSelect
          lineEvent={handleLineClick}
          maxHeight="100%"
          scrollEndEvent={scrollEndEvent}
        />
        {
          loadingNewPage && (
            <Loader__wrapper />
          )
        }
        {loadingData && (<Loader__wrapper />)}
      </Card>
    </Container>
  );
};

export default DashboardStandardRepair__historic;
