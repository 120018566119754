
import React, {
  useContext, useState, useEffect, Fragment,
} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Content__sectionTitle, Content__text } from 'components/content/content';
import t from 'theme';
import { Grid__separator, Grid__popup } from 'components/grid/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShieldCheck, faBalanceScale, faTools, faHandshakeAlt, faUserHeadset, faStopwatch,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faTruck, faListUl, faHandshake,
} from '@fortawesome/pro-solid-svg-icons';
import Content__tag from 'components/content/tag/content__tag';
import { Form__textfield, Form__select, Form__date } from 'components/form/form';
import Button__primary from 'components/button/primary/button__primary';

import { List__table, List__content } from 'components/list/list';
import { FleetContext } from 'context/fleetUser/fleetContext';
import { ContentContext } from 'context/contentContext';
import Cards__warning from 'components/cards/warning/cards__warning';
import Cards__serviceAvailable from 'components/cards/serviceAvailable/cards__serviceAvailable';
import { slugify } from 'helpers/js/Utile';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { parseStatus, renderPriority90FleetStatus } from 'util/conditions/statusProps.util';
import { sequelizeDate } from 'util/date.util';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import { ROUTING } from 'util/constant/routing.constant';
import DashboardFairPrice__singlePop from 'containers/fleetView/dashboardFairPrice/singlePop/dashboardFairPrice__singlePop';
import DashboardStandardRepair__singlePop from 'containers/fleetView/dashboardStandardRepair/singlePop/dashboardStandardRepair__singlePop';
import {
  shouldRenderFairPrice,
  shouldRenderStandarRepair, shouldRenderRepairApproval, shouldRenderService24, shouldRenderPriotity90, souldRenderPaymentguarantee,
} from 'util/service/createHistoric';
import { handleLineClick_multiple } from 'util/function/openSingleService';
import { SERVICENAME } from 'util/service/serviceName';
import {
  strapiServiceHeader, strapiRepairApprovalService, strapiService24Service, strapiPriority90Service, strapiPaymentGuaranteeService,
  strapiMyFleetPage, strapiFairPriceService, strapiStandartrepairService,
} from 'util/strapi/content.starpi';
import DashboardRepairApproval__singlePop from 'containers/fleetView/dashboardRepairApproval/singlePop/dashboardRepairApproval__singlePop';
import DashboardPaymentGuarantee__singlePop from 'containers/fleetView/dashboardPaymentGuarantee/singlePop/dashboardPaymentGuarantee__singlePop';
import Message__sucess from 'components/message/sucess/message__sucess';
import { AppContext } from 'context/appContext';
import { repairApproval_isAction } from 'util/conditions/repairApproval.condition';
import DashboardService24__singlePop from 'containers/fleetView/dashboardService24/singlePop/dashboardService24__singlePop';
import DashboardPriority90__singlePop from 'containers/fleetView/dashboardPriority90/singlePop/dashboardPriority90__singlePop';

import { useData } from 'components/data/getter/data__getter';
import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';


const Container = styled.div``;

const Header = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'row' })}
`;

const Title = styled.div`
  font-weight: normal;
  font-size: 36px;
  line-height: 49px;
  color: #4d4d4d;
  margin-right: 20px;
`;

const ContainerCenter = styled.div`
  ${t.mixins.flex({ justify: 'center', align: 'center', direction: 'row' })}
`;

const RowButton = styled(Cards__warning)`
  padding: 30px 65px;
  ${t.mixins.flex({ justify: 'space-between', align: 'center', direction: 'column' })}
  display: inline-flex;
`;

const Fleet__singleVehicle = ({
  style, vehicle, active, closePop, baseSrvFairPrice, baseSrvStandar, basePage,
}) => {
  const {
    getField, getGlobal, getGlobalObj, getFieldGroup,
  } = useContext(ContentContext);
  const { UpdateVehicule } = useContext(FleetContext);
  const { getUser, user } = useContext(AppContext);
  const [country, setCountry] = useState([]);
  const [category, setCategory] = useState([]);
  const [first, setFirst] = useState('');
  const [mileage, setMileage] = useState('');
  const [brand, setBrand] = useState('');
  const [loading, setLoading] = useState(false);
  const [lockDelete, setlockDelete] = useState(false);
  const [successDelete, setsuccessDelete] = useState(false);
  const [hasUpdate, sethasUpdate] = useState(false);
  const [activesinglePop, setactivesinglePop] = useState({
    serviceName: '',
    service: null,
    active: false,
    id: null,
  });
  let pageNumber = 1;
  const [popActive, setpopActive] = useState(active); useEffect(() => {
    setTimeout(() => { setpopActive(active); }, 10);
  }, [active]);

  // eslint-disable-next-line no-unused-vars
  const [historic, refreshData, setPage, setSearch] = useData({
    removeFirstLoading: true,
    params: {
      fleetId: vehicle.id,
    },
    service: 'historic',
    type: 'historic',
  });

  const headerCol = [
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'service_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'id_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'date_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'performedby_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'status_header' }),
    },
  ];

  const renderInputValue = () => {
    setBrand(vehicle.brand);
    setFirst(sequelizeDate({ date: vehicle.firstDate, format: 'd/m/y' }));
    setMileage(vehicle.mileage);
  };

  const setDropDownValue = () => {
    const keyObject = Object.keys(getGlobalObj({ option: 'country' }));
    const keyObjectType = Object.keys(getGlobalObj({ option: 'vehicletype' }));
    const selectArray = [];
    const selectTypeArray = [];

    keyObject.forEach((element) => {
      const obj = {
        label: getGlobal({ option: 'country', field: element }),
        slug: slugify(element),
        active: vehicle.countryOfRegistration === slugify(element),
      };

      selectArray.push(obj);
    });

    keyObjectType.forEach((element) => {
      const obj = {
        label: getGlobal({ option: 'vehicletype', field: element }),
        slug: slugify(element),
        active: vehicle.type === slugify(element),
      };

      selectTypeArray.push(obj);
    });

    const checkActiveType = selectTypeArray.find((singleType) => singleType.active === true);
    if (!checkActiveType || checkActiveType.length < 0) {
      selectTypeArray[0].active = true;
    }

    const checkActiveCountry = selectArray.find((singleCountry) => singleCountry.active === true);
    if (!checkActiveCountry || checkActiveCountry.length < 0) {
      selectArray[0].active = true;
    }

    setCountry(selectArray);
    setCategory(selectTypeArray);
  };

  const createHistoric = () => {
    const newArray = [];
    if (historic && historic.data && Array.isArray(historic.data)) {
      historic.data.forEach((element) => {
        if (element.FairPrice) {
          newArray.push({
            vehicle: element.FairPrice.Fleet,
            workshop: element.UserWorkshop,
            data: element.FairPrice,
            serviceName: getField({ base: strapiFairPriceService, field: 'service_title' }),
            serviceSlug: SERVICENAME.fairPrice,
            content: strapiFairPriceService,
          });
        } else if (element.paymentguarantee) {
          newArray.push({
            vehicle: element.paymentguarantee.Fleet,
            workshop: element.UserWorkshop,
            data: element.paymentguarantee,
            serviceName: getField({ base: strapiPaymentGuaranteeService, field: 'service_title' }),
            serviceSlug: SERVICENAME.paymentguarantee,
            content: strapiPaymentGuaranteeService,
          });
        } else if (element.priority90) {
          newArray.push({
            vehicle: element.priority90.Fleet,
            workshop: element.UserWorkshop,
            data: element.priority90,
            serviceName: getField({ base: strapiPriority90Service, field: 'service_title' }),
            serviceSlug: SERVICENAME.priority90,
            content: strapiPriority90Service,
          });
        } else if (element.repairApproval) {
          newArray.push({
            vehicle: element.repairApproval.Fleet,
            workshop: element.UserWorkshop,
            data: element.repairApproval,
            serviceName: getField({ base: strapiRepairApprovalService, field: 'service_title' }),
            serviceSlug: SERVICENAME.repairapproval,
            content: strapiRepairApprovalService,
          });
        } else if (element.service24) {
          newArray.push({
            vehicle: element.service24.Fleet,
            workshop: element.UserWorkshop,
            data: element.service24,
            serviceName: getField({ base: strapiService24Service, field: 'service_title' }),
            serviceSlug: SERVICENAME.service24,
            content: strapiService24Service,
          });
        } else if (element.standardRepair) {
          newArray.push({
            vehicle: element.standardRepair.Fleet,
            workshop: element.UserWorkshop,
            data: element.standardRepair,
            serviceName: getField({ base: strapiStandartrepairService, field: 'service_title' }),
            serviceSlug: SERVICENAME.standardrepair,
            content: strapiStandartrepairService,
          });
        }
      });
    }
    return newArray;
  };

  useEffect(() => {
    if (active === true && vehicle !== false) {
      setDropDownValue();
      renderInputValue();
      refreshData({
        fleetId: vehicle.id,
      });
    }
  }, [active]);

  useEffect(() => {
    if (historic && historic.data && Array.isArray(historic.data)) {
      const hasWaiting = [];
      historic.data.forEach((element) => {
        if (element.paymentguarantee) {
          hasWaiting.push(element.paymentguarantee.status);
        } else if (element.priority90) {
          hasWaiting.push(element.priority90.status);
        } else if (element.repairApproval) {
          hasWaiting.push(element.repairApproval.status);
        } else if (element.service24) {
          hasWaiting.push(element.service24.status);
        } else if (element.standardRepair) {
          hasWaiting.push(element.standardRepair.status);
        }
      });
      const waitingFilter = hasWaiting.filter((e) => e === SERVICE_STATUS.waiting);
      setlockDelete(waitingFilter && waitingFilter.length > 0 ? true : false);
    }
  }, [historic]);

  if (vehicle === false || active === false) {
    return null;
  }

  const handleDate = (payload) => {
    setFirst(payload.value);
  };

  const handleMileage = (payload) => {
    setMileage(payload.value);
  };

  const handleBrand = (payload) => {
    setBrand(payload.value);
  };

  const handleCountry = (payload) => {
    setCountry(payload.value);
  };

  const handleCategory = (payload) => {
    setCategory(payload.value);
  };


  const handleSaveVehicle = async () => {
    setLoading(true);
    const getCountry = country.find((singleCountry) => singleCountry.active === true);
    const getType = category.find((singleCountry) => singleCountry.active === true);
    const params = {
      fleetId: vehicle.id,
      countryOfRegistration: getCountry.slug,
      type: getType.slug,
      brand,
      firstDate: new Date(moment(first, 'DD/MM/YYYY').format('YYYY-MM-DD')),
      mileage,
    };


    const res = await UpdateVehicule({ fleetObj: params });
    if (res) {
      sethasUpdate(true);
      setLoading(false);
    }
  };

  const renderList = () => {
    const orderArray = createHistoric();

    const arrayOfLine = [];
    orderArray.forEach((element) => {
      arrayOfLine.push([
        {
          component: <List__content>{element.serviceName}</List__content>,
          type: 'node',
        },
        {
          component: <List__content uppercase>{element.data.uid}</List__content>,
          type: 'node',
        },
        {
          component: <List__content>{sequelizeDate({ date: element.data.createdAt, format: 'd/m/y' })}</List__content>,
          type: 'node',
        },
        {
          component: <List__content capitalize>{element.workshop && element.workshop.UserWorkshopAlias && element.workshop.UserWorkshopAlias.companyName}</List__content>,
          type: 'node',
        },
        {
          component: (
            element.data.status ? (
              element.serviceSlug === SERVICENAME.repairapproval ? (
                <Content__tag
                  content={getFieldGroup({
                    base: element.content,
                    groupe: 'status',
                    field: repairApproval_isAction(element.data, user),
                  })}
                  {...parseStatus({ status: repairApproval_isAction(element.data, user) })}
                />
              ) : element.serviceSlug === SERVICENAME.priority90 ? (
                <Content__tag
                  content={
                    element.data.status === 'done'
                      ? getField({ base: strapiPriority90Service, field: 'status_fleet_done' })
                      : getFieldGroup({
                        base: strapiPriority90Service,
                        groupe: 'status',
                        field: renderPriority90FleetStatus(element.data.status),
                      })
                  }
                  {...parseStatus({ status: renderPriority90FleetStatus(element.data.status) })}
                />
              ) : (
                <Content__tag
                  content={getFieldGroup({
                    base: element.content,
                    groupe: 'status',
                    field: element.data.status,
                  })}
                  {...parseStatus({ status: element.data.status })}
                />
              )
            ) : (
              <List__content />
            )
          ),
          type: 'node',
        },
      ]);
    });

    return arrayOfLine;
  };

  const handleDelete = async () => {
    if (vehicle) {
      setLoading(true);
      const params = {
        deleted: true,
        vin: 'XXX',
        fleetId: vehicle.id,
      };


      const res = await UpdateVehicule({ fleetObj: params });
      if (res) {
        sethasUpdate(true);
        setsuccessDelete(true);
        setLoading(false);
      }
    }
  };

  const handleCloseSuccesDelete = async () => {
    setLoading(true);
    setsuccessDelete(false);
    const resUser = await getUser();
    if (resUser.success === true) {
      setLoading(false);
      closePop();
    }
  };

  const openPopUp = (serviceName, service) => {
    setactivesinglePop({
      serviceName,
      service,
      active: true,
      id: service.id,
    });
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({
      serviceName: '',
      service: null,
      active: false,
      id: null,
    });
  };

  const handleLine = (line) => {
    handleLineClick_multiple({
      line,
      array: createHistoric(),
      callback: (name, currentLine) => openPopUp(name, currentLine),
    });
  };

  const handleClose = async () => {
    if (hasUpdate) {
      setLoading(true);
      const resUser = await getUser();
      if (resUser.success === true) {
        setLoading(false);
        closePop();
      }
    }
    if (closePop) {
      closePop();
    }
  };

  const handleLoadNewPage = () => {
    if (historic.data && !historic.loading && pageNumber + 1 > 1 && historic.data.length < historic.count) {
      pageNumber += 1;
      setPage(pageNumber, historic.data);
    }
  };


  return (
    <Container style={style}>
      <Grid__popup active={popActive} flex closeEvent={handleClose}>
        {
          !successDelete ? (
            <div>
              <Header>
                <Title>{getField({ base: strapiMyFleetPage, field: 'single_vehicle_title' })}</Title>
                <Content__bigTag label={getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' })} value={vehicle.registrationNumber} />
                <Grid__separator width="15px" />
                <Content__bigTag label={getGlobal({ option: 'vehicleParameter', field: 'vincode' })} value={vehicle.vin} />
              </Header>
              <Grid__separator size="85px" />
              <Content__sectionTitle
                line
                title={getField({ base: strapiMyFleetPage, field: 'single_vehicle_update_title' })}
                icon={<FontAwesomeIcon icon={faTruck} />}
              />
              <ContainerCenter style={style}>
                <Form__select
                  value={country}
                  require
                  label={getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' })}
                  event={handleCountry}
                  widthAuto
                />
                <Grid__separator width="15px" />
                <Form__select
                  value={category}
                  require
                  label={getGlobal({ option: 'vehicleParameter', field: 'category' })}
                  event={handleCategory}
                  widthAuto
                />
                <Grid__separator width="15px" />
                <Form__textfield
                  textTransform="capitalize"
                  value={brand}
                  require
                  label={getGlobal({ option: 'vehicleParameter', field: 'brand' })}
                  change={handleBrand}
                  widthAuto
                />
                <Grid__separator width="15px" />
                <Form__date
                  value={first}
                  require
                  label={getGlobal({ option: 'vehicleParameter', field: 'firstregistration' })}
                  event={handleDate}
                />
                <Grid__separator width="15px" />
                <Form__textfield
                  value={mileage}
                  type="number"
                  label={getGlobal({ option: 'vehicleParameter', field: 'mileage' })}
                  change={handleMileage}
                  widthAuto
                />

              </ContainerCenter>
              <Grid__separator size="25px" />
              <ContainerCenter>
                <Button__primary event={handleSaveVehicle}>{getField({ base: strapiMyFleetPage, field: 'single_vehicle_update_button' })}</Button__primary>
              </ContainerCenter>
              <Grid__separator size="100px" />
              <Content__sectionTitle
                line
                title={getField({ base: strapiMyFleetPage, field: 'single_vehicle_service_title' })}
                icon={<FontAwesomeIcon icon={faHandshake} />}
              />
              {
                shouldRenderFairPrice(vehicle) ? (
                  <Fragment>
                    <Cards__serviceAvailable
                      icon={<FontAwesomeIcon icon={faBalanceScale} />}
                      title={getField({ base: baseSrvFairPrice, field: 'service_title' })}
                      buttonDashboard={getField({ base: strapiMyFleetPage, field: 'single_vehicle_service_dashboard' })}
                      buttonRequest={getField({ base: strapiMyFleetPage, field: 'single_vehicle_service_request' })}
                      buttonServiceHref={ROUTING.fleet.dashboardFairPrice.path}
                      buttonRequestHref={`${ROUTING.fleet.dashboardFairPrice.path}?launch=${vehicle.id}`}
                    />
                    <Grid__separator size="15px" />
                  </Fragment>
                ) : null
              }
              {
                shouldRenderStandarRepair(vehicle) ? (
                  <Fragment>
                    <Cards__serviceAvailable
                      icon={<FontAwesomeIcon icon={faTools} />}
                      title={getField({ base: baseSrvStandar, field: 'service_title' })}
                      buttonDashboard={getField({ base: strapiMyFleetPage, field: 'single_vehicle_service_dashboard' })}
                      buttonServiceHref={ROUTING.fleet.dashboardStandardRepair.path}
                    />
                    <Grid__separator size="15px" />
                  </Fragment>
                ) : null
              }
              {
                shouldRenderRepairApproval(vehicle) ? (
                  <Fragment>
                    <Cards__serviceAvailable
                      icon={<FontAwesomeIcon icon={faHandshakeAlt} />}
                      title={getField({ base: strapiRepairApprovalService, strapiRepairApprovalService, field: 'service_title' })}
                      buttonDashboard={getField({ base: strapiMyFleetPage, field: 'single_vehicle_service_dashboard' })}
                      buttonServiceHref={ROUTING.fleet.dashboardRepairApproval.path}
                    />
                    <Grid__separator size="15px" />
                  </Fragment>
                ) : null
              }
              {
                shouldRenderService24(vehicle) ? (
                  <Fragment>
                    <Cards__serviceAvailable
                      icon={<FontAwesomeIcon icon={faUserHeadset} />}
                      title={getField({ base: strapiService24Service, field: 'service_title' })}
                      buttonServiceHref={ROUTING.fleet.dashboardService24.path}
                      buttonDashboard={getField({ base: strapiMyFleetPage, field: 'single_vehicle_service_dashboard' })}
                      buttonRequest={getField({ base: strapiMyFleetPage, field: 'single_vehicle_service_request' })}
                      buttonRequestHref={`${ROUTING.fleet.dashboardService24.path}?launch=${vehicle.id}`}
                    />
                    <Grid__separator size="15px" />
                  </Fragment>
                ) : null
              }
              {
                shouldRenderPriotity90(vehicle) ? (
                  <Fragment>
                    <Cards__serviceAvailable
                      icon={<FontAwesomeIcon icon={faStopwatch} />}
                      title={getField({ base: strapiPriority90Service, field: 'service_title' })}
                      buttonDashboard={getField({ base: strapiMyFleetPage, field: 'single_vehicle_service_dashboard' })}
                      buttonServiceHref={ROUTING.fleet.dashboardPriority90.path}
                    />
                    <Grid__separator size="15px" />
                  </Fragment>
                ) : null
              }
              {
                souldRenderPaymentguarantee(vehicle) ? (
                  <Fragment>
                    <Cards__serviceAvailable
                      icon={<FontAwesomeIcon icon={faShieldCheck} />}
                      title={getField({ base: strapiPaymentGuaranteeService, field: 'service_title' })}
                      buttonDashboard={getField({ base: strapiMyFleetPage, field: 'single_vehicle_service_dashboard' })}
                      buttonServiceHref={ROUTING.fleet.dashboardPaymentGuarantee.path}
                    />
                    <Grid__separator size="15px" />
                  </Fragment>
                ) : null
              }
              <Grid__separator size="100px" />

              <Content__sectionTitle
                line
                title={getField({ base: strapiMyFleetPage, field: 'single_vehicle_history_title' })}
                icon={<FontAwesomeIcon icon={faListUl} />}
              />
              <Container style={style}>
                <List__table
                  style={{ maxHeight: 380 }}
                  headerCol={headerCol}
                  lineCol={renderList()}
                  noSelect
                  lineEvent={handleLine}
                  maxHeight="100%"
                  scrollEndEvent={handleLoadNewPage}
                />
                {
                  historic.loading && (
                    <Loader__wrapper />
                  )
                }
              </Container>
              {!lockDelete && (
                <Fragment>
                  <Grid__separator size="100px" />
                  <ContainerCenter>
                    <RowButton>
                      <Button__primary border red event={handleDelete}>
                        {getField({ base: basePage, field: 'single_vehicle_delete_button' })}
                      </Button__primary>
                      <Grid__separator size="15px" />
                      <Content__text
                        style={{
                          fontSize: 16,
                        }}
                        color="#E74C3C"
                      >
                        {getField({ base: basePage, field: 'single_vehicle_delete_text' })}
                      </Content__text>
                    </RowButton>
                  </ContainerCenter>
                </Fragment>
              )}
            </div>
          ) : (
            <Message__sucess
              icon={t.image.illu.check}
              title={getField({ base: basePage, field: 'success_popup_delete_title' })}
              text={getField({ base: basePage, field: 'success_popup_delete_text' })}
              buttonText={getField({ base: basePage, field: 'success_popup_delete_button' })}
              buttonEvent={handleCloseSuccesDelete}
            />
          )
        }
        {loading ? <Loader__wrapper /> : false }
      </Grid__popup>
      <DashboardFairPrice__singlePop
        active={activesinglePop.active && activesinglePop.serviceName === SERVICENAME.fairPrice}
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardStandardRepair__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.standardrepair
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardRepairApproval__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.repairapproval
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardService24__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.service24
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardPriority90__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.priority90
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardPaymentGuarantee__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.paymentguarantee
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
    </Container>
  );
};

export default Fleet__singleVehicle;
