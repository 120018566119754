/* eslint-disable */  
import React, {
  useEffect, useState, Fragment, useContext, useRef
} from 'react';
import t from 'theme';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { Grid__separator } from 'components/grid/grid';
import Content__statusAnwser from 'components/content/statusAnwser/content__statusAnwser';
import {
  faTruck, faTools, faWarehouse, faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import { SendMail } from 'helpers/api/routes/Mail';
import { ROUTING } from 'util/constant/routing.constant';
import { isWaiting, isError, isAction } from 'util/conditions/checkstatus.condition.js';
import Content__labelText from 'components/content/labelText/content__labelText';
import Content__labelTextarea from 'components/content/labelTextarea/content__labelTextarea';
import Content__serviceStatus from 'components/content/serviceStatus/content__serviceStatus';
import { GetSingleService, UpdateStatus } from 'helpers/api/routes/PaymentGuarentee';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import Content__tag from 'components/content/tag/content__tag';
import {
  getFleetInformation,
  getFleetUserInformation,
  getWorkshopInformation,
} from 'util/service/getDataFromService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { strapiPaymentGuaranteeService } from 'util/strapi/content.starpi';
import { parseStatus } from 'util/conditions/statusProps.util';
import { sequelizeDate, sequelizeDateTime } from 'util/date.util';
import { formatPrice } from 'util/format/price.format';
import { parseFile } from 'util/format/parseFile.format';
import { AppContext } from 'context/appContext';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import Button__inline from 'components/button/inline/button__inline';
import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import { getPaymentGuaranteeStatus } from 'util/service/paymentGuarenteeStatus';
import Message__sucess from 'components/message/sucess/message__sucess';
import { getWorkShopUser } from 'helpers/api/routes/User';
import {
  Header,
  Title,
  ItemContainer,
  Row,
  Popup,
} from 'containers/workshopView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import { SERVICENAME } from 'util/service/serviceName';
import { AskConfirmation } from 'helpers/api/routes/PaymentGuarentee';
import Contract__pdf from 'components/contract/pdf/contract__pdf';
import { slugify } from 'helpers/js/Utile';
import { strapiWorkshopDashboardPaymentGuarantee } from 'util/strapi/content.starpi';
import Button__primary from 'components/button/primary/button__primary';
import { setAction } from 'util/function/reloadAction.function';
import { formatApiRes } from '../../../../util/function/formatApiRes.function';

const Container = styled.div``;

const Column = styled.div``;

const ServiceStatusTitle = styled.div`
  font-weight: 300;
  font-size: 28px;
  line-height: 1.3;
  color: #000000;
`;

const Button = styled.div`
  position: absolute;
  right: 45px;
  bottom: 45px;
`;

const Subtitle = styled.div`
  font-size: 15px;
  margin-top: 10px;
`;

const IconDownloadButton = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
`;

const DownloadButtonContainer = styled.div`
  position: relative;
  transition: 0.3s ease all;
  a {
    height: 40px;
    border-radius: 30px;
    line-height: 40px;
    background-color: #01B5E2;
    color: white;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    transition: all cubic-bezier(0.525,0.005,0.045,1.005) 400ms;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    padding-left: 25px;
    padding-right: 25px;
    min-width: 150px;
    text-align: center;
    box-shadow: 0px 7px 14px rgba(1,181,226,0.2);
    transition: 0.3s ease all;
    padding-left: calc(25px + 16px + 8px);
  }
  &:hover {
      transform: translateY(-2px);
      transition: 0.3s ease all;
      a {
        box-shadow: 0px 7px 14px rgba(1, 181, 226, 0.35);
        transition: 0.3s ease all;
      }
    }
`;

const DashboardPaymentGuarantee__singlePop = ({
  style, active, closeEvent, id,
}) => {
  const [loading, setloading] = useState(false);
  const [loadingAction, setloadingAction] = useState(false);
  const [declineRequest, setdeclineRequest] = useState(false);
  const [acceptRequest, setacceptRequest] = useState(false);
  const [data, setdata] = useState(false);
  const [asHomeBase, setasHomeBase] = useState(false);
  const [asHomeBaseWrk, setasHomeBaseWrk] = useState(false);
  const {
    getFieldGroup, loadPage, haveContentFor, getField, getGlobal, page
  } = useContext(ContentContext);
  const { getUser, user } = useContext(AppContext);
  const contractRef = useRef(null);

  const getWorkshop = async (idWorkshop) => {
    const res = await getWorkShopUser({ userId: idWorkshop });
    if (res) {
      if(res.data && res.data.data && res.data.data.UserWorkshopAlias) {
        return {
          user: res.data.data.UserWorkshopAlias,
          wrk: res.data.data
        }
      }
    }

    return false;
  };

  const getWorkshopId = (data) => {
    if(data && data.validateUser) {
      return data.validateUser
    }

    if(data && data.Fleet && data.Fleet.UserFleet && data.Fleet.UserFleet.workshopId) {
      return data.Fleet.UserFleet.workshopId
    }
  }
  const getService = async () => {
    const res = await GetSingleService({ id });
    if (res) {
      const asHomeBaseFormated = await getWorkshop(getWorkshopId(res.data.data));
      setdata(res.data.data);
      setasHomeBase(asHomeBaseFormated.user);
      setasHomeBaseWrk(asHomeBaseFormated.wrk);
      setloading(false);
    }
  };

  const handleRefuse = async () => {
    setloadingAction(true);
    const updateRes = await UpdateStatus({
      status: SERVICE_STATUS.error,
      statusText: '',
      id: data.id,
      fleetid: data && (data.Fleet.UserFleet.UserFleetAlias.id),
    });
    if (updateRes) {
      setloadingAction(false);
      setdeclineRequest(true);
      if (!getWorkshopInformation({ obj: data, key: 'notificationDisable' })) {
        SendMail({
          template: 'notification',
          templateNotification: {
            status: 'error',
            statusLabel: getFieldGroup({
              select_lang: getWorkshopInformation({ obj: data, key: 'lang' }), base: strapiPaymentGuaranteeService, groupe: 'mail_gop_refused', field: 'status',
            }),
            content: `${user.user.companyName} ${getFieldGroup({
              select_lang: getWorkshopInformation({ obj: data, key: 'lang' }), base: strapiPaymentGuaranteeService, groupe: 'mail_gop_refused', field: 'content',
            })}`,
            btnContent: getFieldGroup({
              select_lang: getWorkshopInformation({ obj: data, key: 'lang' }), base: strapiPaymentGuaranteeService, groupe: 'mail_gop_refused', field: 'btn',
            }),
            btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.workshop.dashboardPaymentGuarantee.path}`,
          },
          subject: getFieldGroup({
            select_lang: getWorkshopInformation({ obj: data, key: 'lang' }), base: strapiPaymentGuaranteeService, groupe: 'mail_gop_refused', field: 'subject',
          }),
          destination: getWorkshopInformation({ obj: data, key: 'email' }),
        });
      }
    }
  };

  const handleAccept = async () => {
    let getPdfRef = null
    if (contractRef && contractRef.current) {
      getPdfRef = {...contractRef.current}
    }
    setloadingAction(true);
    const askRes = await AskConfirmation({
      id: data.id,
      fleetid: data && (data.Fleet.UserFleet.UserFleetAlias.id),
    });

    const formated = formatApiRes(askRes);
    if (formated && formated.success && formated.data) {
      setacceptRequest(true);
      if (getPdfRef) {
        const pdf = await getPdfRef.handleGetPdf();
        setloadingAction(false);
        SendMail({
          template: 'gop-supervisor',
          content: {
            title: getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_gop', field: 'title'}),
            subtitle: `${getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_gop', field: 'sub_title'})} ${user.user.lastName} ${user.user.firstName}`,
            sectionFleet_title: getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_gop', field: 'fleet_section'}),
            sectionFleet_1: [`${getGlobal({ option: 'workshopInformation', field: 'company_name' })}:`, getFleetUserInformation({ obj: data, key: 'companyName' })],
            sectionFleet_2: [`${getGlobal({ option: 'workshopInformation', field: 'city' })}:`, getFleetUserInformation({ obj: data, key: 'city' })],
            sectionFleet_3: [`${getGlobal({ option: 'workshopInformation', field: 'country' })}:`, getFleetUserInformation({ obj: data, key: 'region' })],
            sectionVehicle_title: getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_gop', field: 'vehicle_section'}),
            sectionVehicle_1: [`${getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' })}:`, getFleetInformation({ obj: data, key: 'registrationNumber' })],
            sectionVehicle_2: [`${getGlobal({ option: 'vehicleParameter', field: 'brand' })}:`, getFleetInformation({ obj: data, key: 'brand' })],
            sectionWorkshop_title: getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_gop', field: 'workshop_section'}),
            sectionWorkshop_1: [`${getGlobal({ option: 'workshopInformation', field: 'company_name' })}:`, user.user.companyName],
            sectionWorkshop_2: [`${getGlobal({ option: 'workshopInformation', field: 'city' })}:`, user.user.city],
            sectionWorkshop_3: [`${getGlobal({ option: 'workshopInformation', field: 'country' })}:`, user.user.region],
            sectionGop: [getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_gop', field: 'gop_amount'}), formatPrice(data.price)],
            info: getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_gop', field: 'info_text'}),
            btnAccept: [getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_gop', field: 'btn_accept'}), `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/confirmation/payment-guarantee/${formated.data.validationUrl}?accept=1`],
            btnRefused: [getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_gop', field: 'btn_refuse'}), `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/confirmation/payment-guarantee/${formated.data.validationUrl}?refused=1`],
          },
          subject: getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_gop', field: 'subject'}),
          destination: user.user.UserWorkshop.supervisormail,
          attachments: [
            {
              content: pdf.pdf,
              filename: pdf.name,
              type: pdf.type,
              disposition: "attachment"
            }
          ]
        })
      }
    }
  };

  const handleReload = async () => {
    setloadingAction(true);
    const resUser = await getUser();
    if (resUser) {
      setAction({ key: 'action', value: id });
      setloadingAction(false);
    }
  };

  const handleCloseEvent = async () => {
    if (acceptRequest || declineRequest) {
      handleReload();
    } else {
      closeEvent();
    }
  };

  useEffect(() => {
    if (haveContentFor(strapiPaymentGuaranteeService).success === false) {
      loadPage(strapiPaymentGuaranteeService);
    }
  }, []);

  useEffect(() => {
    if (active === true) {
      setloading(true);
      getService();
    }
  }, [active]);

  const renderLoader = () => <Loader__wrapper />;

  if (
    haveContentFor(strapiPaymentGuaranteeService).success === false
  ) {
    return (
      <Container style={style}>
        <Popup active={active} closeEvent={handleCloseEvent}>
          {renderLoader()}
        </Popup>
      </Container>
    );
  }

  const renderPDf = (noButton, noSignature) => (
    <Contract__pdf
      buttonText={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_downnload' })}
      loadingtext={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_download_active' })}
      name={`contract_gop_${data.uid}`}
      content={getFieldGroup({
        base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'contract_gop', field: 'contract_content',
      })}
      type="paymentGurantee"

      guarantor_company={asHomeBase ? asHomeBase.companyName : ''}
      guarantor_address={`${asHomeBase ? asHomeBase.adress : ''} ${asHomeBase ? asHomeBase.city : ''}, ${asHomeBase ? asHomeBase.zipcode : ''}`}
      guarantor_country={asHomeBase ? asHomeBase.region : ''}
      guarantor_contact={asHomeBase ? asHomeBase.email : ''}

      supervisor_name={`${asHomeBaseWrk ? asHomeBaseWrk.supervisorname : ''} ${asHomeBaseWrk ? asHomeBaseWrk.supervisorlastname : ''}`}
      supervisor_mail={asHomeBaseWrk ? asHomeBaseWrk.supervisormail : ''}
      supervisor_time={data.confirmationDate ? sequelizeDateTime({ date: data.confirmationDate, atContent: getGlobal({ option: 'globalcontent', field: 'at' }) }) : ''}

      workshop_company={getWorkshopInformation({ obj: data, key: 'companyName' })}
      workshop_address={`${getWorkshopInformation({ obj: data, key: 'adress' })} ${getWorkshopInformation({ obj: data, key: 'city' })}, ${getWorkshopInformation({ obj: data, key: 'zipcode' })}`}
      workshop_country={getWorkshopInformation({ obj: data, key: 'region' })}
      workshop_contact={getWorkshopInformation({ obj: data, key: 'email' })}

      amount={formatPrice(data.price)}

      fleet_company={getFleetUserInformation({ obj: data, key: 'companyName' })}
      fleet_address={getFleetUserInformation({ obj: data, key: 'adress' })}
      fleet_zip={getFleetUserInformation({ obj: data, key: 'zipcode' })}
      fleet_location={getFleetUserInformation({ obj: data, key: 'city' })}
      fleet_phone={getFleetUserInformation({ obj: data, key: 'phoneNumber' })}
      fleet_cmail={getFleetUserInformation({ obj: data, key: 'email' })}
      fleet_tva={getFleetUserInformation({ obj: data, key: 'vat' })}

      vehicle_tva={getFleetInformation({ obj: data, key: 'vin' })}
      vehicle_registration={getFleetInformation({ obj: data, key: 'registrationNumber' })}
      vehicle_type={getGlobal({ option: 'vehicletype', field: getFleetInformation({ obj: data, key: 'type' }) }) || ''}
      vehicle_brand={getFleetInformation({ obj: data, key: 'brand' })}
      vehicle_country={getGlobal({ option: 'country', field: getFleetInformation({ obj: data, key: 'countryOfRegistration' }) }) || ''}
      service_uid={data.uid}
      ref={contractRef}
      noButton={noButton}
      noSignature={noSignature}
    />
  )

  const renderContent = () => (
    !declineRequest && !acceptRequest ? (
      <Fragment>
        {renderPDf(true, true)}
        <Header>
          <Title>{getField({ base: strapiPaymentGuaranteeService, field: 'service_title' })}</Title>
          <Content__bigTag
            label={getGlobal({ option: 'service', field: 'single_request_id' })}
            value={data.uid}
          />
          <Grid__separator width="15px" />
          <Content__bigTag
            label={getGlobal({ option: 'service', field: 'single_request_date' })}
            value={sequelizeDate({ date: data.createdAt, format: 'd/m/y' })}
          />
        </Header>
        <Grid__separator size="80px" />
        <Content__serviceStatus
          tag={(
            <Content__tag
              style={{
                position: 'absolute',
                right: 45,
                top: 45,
              }}
              border
              content={getFieldGroup({
                base: strapiPaymentGuaranteeService,
                groupe: 'status',
                field: getPaymentGuaranteeStatus({
                  status: data.status,
                  user,
                  vehicle: data.Fleet,
                }),
              })}
              {...parseStatus({
                status: getPaymentGuaranteeStatus({
                  status: data.status,
                  user,
                  vehicle: data.Fleet,
                }),
              })}
            />
          )}
          {...parseStatus({
            status: getPaymentGuaranteeStatus({
              status: data.status,
              user,
              vehicle: data.Fleet,
            }),
          })}
        >
          {
            isError(getPaymentGuaranteeStatus({
              status: data.status,
              user,
              vehicle: data.Fleet,
            })) ? (
              <Fragment>
                <ServiceStatusTitle>
                  {getField({ base: strapiPaymentGuaranteeService, field: 'error_text' })}
                </ServiceStatusTitle>
                <Grid__separator size="25px" />
                <Content__statusAnwser
                  title={asHomeBase ? asHomeBase.companyName : ''}
                  subtitle1={`${asHomeBase ? asHomeBase.adress : ''}`}
                  subtitle2={`${asHomeBase ? asHomeBase.city : ''}, ${asHomeBase ? asHomeBase.zipcode : ''}`}
                  link1={`${asHomeBase ? asHomeBase.phoneNumber : ''}`}
                  link2={asHomeBase ? asHomeBase.email : ''}
                  link3={`${asHomeBase ? asHomeBase.adress : ''} ${asHomeBase ? asHomeBase.city : ''}, ${asHomeBase ? asHomeBase.zipcode : ''}`}
                />
              </Fragment>
            ) : isWaiting(getPaymentGuaranteeStatus({
              status: data.status,
              user,
              vehicle: data.Fleet,
            })) ? (
              <Fragment>
                <Grid__separator size="10px" />
                {getField({ base: strapiPaymentGuaranteeService, field: 'waiting_text' })}
              </Fragment>
            ) : isAction(getPaymentGuaranteeStatus({
              status: data.status,
              user,
              vehicle: data.Fleet,
            })) ? (
              <Fragment>
                {!data.askConfirmation ? (
                  <Fragment>
                    <ServiceStatusTitle>
                      {getField({ base: strapiPaymentGuaranteeService, field: 'statusanswer_title' })}
                    </ServiceStatusTitle>
                    <Grid__separator size="10px" />
                    <Content__statusAnwser
                      style={{ paddingRight: 200 }}
                      action
                      subtitle1={getField({ base: strapiPaymentGuaranteeService, field: 'statusanswer_subtitle' })}
                      buttonRefused={getField({ base: strapiPaymentGuaranteeService, field: 'statusanswer_button_refused' })}
                      buttonAccept={getField({ base: strapiPaymentGuaranteeService, field: 'statusanswer_button_accept' })}
                      buttonRefused_event={handleRefuse}
                      buttonAccept__event={handleAccept}
                    />
                    </Fragment>
                ) : (
                  <Fragment>
                    <ServiceStatusTitle>
                      {getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'info_waiting_supervisor' })}
                    </ServiceStatusTitle>
                    <Grid__separator size="10px" />
                    <Subtitle>
                      {getGlobal({ option: 'gender', field: user.user.UserWorkshop.supervisorgender })}
                      {' '}
                      {user.user.UserWorkshop.supervisorlastname}
                      {' '}
                      {user.user.UserWorkshop.supervisorname}
                    </Subtitle>
                    <Subtitle>
                      {user.user.UserWorkshop.supervisormail}
                    </Subtitle>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <ServiceStatusTitle>
                  {getField({ base: strapiPaymentGuaranteeService, field: 'response_text' })}
                </ServiceStatusTitle>
                <Grid__separator size="25px" />
                <Content__statusAnwser
                  title={asHomeBase ? asHomeBase.companyName : ''}
                  subtitle1={`${asHomeBase ? asHomeBase.adress : ''}`}
                  subtitle2={`${asHomeBase ? asHomeBase.city : ''}, ${asHomeBase ? asHomeBase.zipcode : ''}`}
                  link1={`${asHomeBase ? asHomeBase.phoneNumber : ''}`}
                  link2={asHomeBase ? asHomeBase.email : ''}
                  link3={`${asHomeBase ? asHomeBase.adress : ''} ${asHomeBase ? asHomeBase.city : ''}, ${asHomeBase ? asHomeBase.zipcode : ''}`}
                />
                {
                  asHomeBase.id === user.user.id
                  || asHomeBase.id === data.workshopId && (
                    <Button>
                      {renderPDf()}
                    </Button>
                  )
                }
              </Fragment>
            )
          }
        </Content__serviceStatus>
        <Grid__separator size="100px" />
        <Row>
          <ItemContainer>
            <Content__sectionTitle
              line
              title={getField({ base: strapiPaymentGuaranteeService, field: 'vehicle_title' })}
              icon={<FontAwesomeIcon icon={faTruck} />}
            />
            <Row>
              <Column>
                <Content__labelText
                  label={getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' })}
                  content={getFleetInformation({ obj: data, key: 'registrationNumber' })}
                />
                <Grid__separator size="30px" />
                <Content__labelText
                  capitalize
                  label={getGlobal({ option: 'vehicleParameter', field: 'category' })}
                  content={getGlobal({ option: 'vehicletype', field: getFleetInformation({ obj: data, key: 'type' }) }) || ''}
                />
                <Grid__separator size="30px" />
                <Content__labelText
                  capitalize
                  label={getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' })}
                  content={getGlobal({ option: 'country', field: getFleetInformation({ obj: data, key: 'countryOfRegistration' }) }) || ''}
                />
                <Grid__separator size="30px" />
                <Content__labelText
                  capitalize
                  label={getGlobal({ option: 'vehicleParameter', field: 'mileage' })}
                  content={getFleetInformation({ obj: data, key: 'mileage' })}
                />
              </Column>
              <Column>
                <Content__labelText
                  label={getGlobal({ option: 'vehicleParameter', field: 'vincode' })}
                  content={getFleetInformation({ obj: data, key: 'vin' })}
                />
                <Grid__separator size="30px" />
                <Content__labelText
                  capitalize
                  label={getGlobal({ option: 'vehicleParameter', field: 'brand' })}
                  content={getFleetInformation({ obj: data, key: 'brand' })}
                />
                <Grid__separator size="30px" />
                <Content__labelText
                  label={getGlobal({ option: 'vehicleParameter', field: 'fleet' })}
                  content={getFleetUserInformation({ obj: data, key: 'companyName' })}
                />
              </Column>
            </Row>
          </ItemContainer>
          <ItemContainer>
            <Content__sectionTitle
              line
              title={getField({ base: strapiPaymentGuaranteeService, field: 'workshop_title' })}
              icon={<FontAwesomeIcon icon={faWarehouse} />}
            />
            <Row>
              <Column>
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'name' })}
                  content={getWorkshopInformation({ obj: data, key: 'companyName' })}
                />
                <Grid__separator size="30px" />
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'address' })}
                  content={getWorkshopInformation({ obj: data, key: 'adress' })}
                />
                <Grid__separator size="30px" />
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })}
                  content={`${getWorkshopInformation({ obj: data, key: 'phoneNumber' })}`}
                />
              </Column>
              <Column>
                <Content__labelText
                  capitalize
                  label={getGlobal({ option: 'workshopInformation', field: 'country' })}
                  content={getWorkshopInformation({ obj: data, key: 'region' })}
                />
                <Grid__separator size="30px" />
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'city' })}
                  content={getWorkshopInformation({ obj: data, key: 'city' })}
                />
                <Grid__separator size="30px" />
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'zipcode' })}
                  content={getWorkshopInformation({ obj: data, key: 'zipcode' })}
                />
              </Column>
            </Row>
          </ItemContainer>
        </Row>
        <Grid__separator size="100px" />
        <Content__sectionTitle
          line
          title={getField({ base: strapiPaymentGuaranteeService, field: 'repair_title' })}
          icon={<FontAwesomeIcon icon={faTools} />}
        />
        <Row>
          <Column style={{ width: '60%' }}>
            <Content__labelTextarea
              label={getField({ base: strapiPaymentGuaranteeService, field: 'repair_description' })}
              lang={data.textLang}
              convert={user.user.lang}
              content={data.description}
              dataName={SERVICENAME.paymentguarantee}
              base={data.translate}
              id={data.id}
            />
            <Grid__separator size="30px" />
            {
              data.file && (
                <Content__labelText
                  label={getField({ base: strapiPaymentGuaranteeService, field: 'repair_file' })}
                  content={(
                    <Button__inline
                      content={getField({ base: strapiPaymentGuaranteeService, field: 'repair_file_button' })}
                      icon={<FontAwesomeIcon icon={faDownload} />}
                      download
                      href={parseFile(data.file).url}
                    />
                  )}
                />
              )
            }
          </Column>
          <Column style={{ width: '25%' }}>
            <Content__labelText
              label={getField({ base: strapiPaymentGuaranteeService, field: 'repair_price' })}
              content={formatPrice(data.price)}
            />
          </Column>
        </Row>
        {loadingAction && (renderLoader())}
      </Fragment>
    ) : declineRequest ? (
      <Fragment>
        <Message__sucess
          icon={t.image.illu.error}
          title={getField({ base: strapiPaymentGuaranteeService, field: 'msg_error_title' })}
          text={getField({ base: strapiPaymentGuaranteeService, field: 'msg_error_text' })}
          buttonText={getField({ base: strapiPaymentGuaranteeService, field: 'msg_error_button' })}
          buttonEvent={handleReload}
        />
        {loadingAction && (renderLoader())}
      </Fragment>
    ) : acceptRequest ? (
      <Fragment>
        <Message__sucess
          icon={t.image.illu.check}
          title={getFieldGroup({ base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'popup_success_to_supervisor', field: 'title' })}
          text={`${getFieldGroup({ base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'popup_success_to_supervisor', field: 'content' })} ${user.user.UserWorkshop.supervisormail}`}
          buttonText={getFieldGroup({ base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'popup_success_to_supervisor', field: 'button' })}
          buttonEvent={handleReload}
        />
        {loadingAction && (<Loader__wrapper opacity brand/>)}
      </Fragment>
    ) : null
  );

  return (
    <Container style={style}>
      <Popup active={active} closeEvent={handleCloseEvent}>
        {loading || !data ? renderLoader() : renderContent()}
      </Popup>
    </Container>
  );
};

export default DashboardPaymentGuarantee__singlePop;
