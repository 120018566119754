import React, {
  useEffect, useState, Fragment, useContext,
} from 'react';
import t from 'theme';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { SendMail } from 'helpers/api/routes/Mail';
import { ROUTING } from 'util/constant/routing.constant';
import {
  UpdatePublicStatus, GetSingleServiceByUrl,
} from 'helpers/api/routes/PaymentGuarentee';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import {
  getWorkshopInformation,
} from 'util/service/getDataFromService';
import { strapiPaymentGuaranteeService, strapiSingleServicePage, strapiWorkshopDashboardPaymentGuarantee } from 'util/strapi/content.starpi';
import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import Message__sucess from 'components/message/sucess/message__sucess';
import {
  Popup,
} from 'containers/workshopView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';

import { Transition__loader_controller } from 'components/transition/transition';
import { getParams } from 'util/get.util';
import { useParams, useHistory } from 'react-router-dom';
import { DateTime } from 'util/date.util';
import Contract__pdf from '../../components/contract/pdf/contract__pdf';
import { formatPrice } from '../../util/format/price.format';
import { getWorkShopUser } from '../../helpers/api/routes/User';
import { getFleetUserInformation } from '../../util/service/getDataFromService';
import { getPublicWorkShopUserGop } from '../../helpers/api/routes/Public';


const Container = styled.div``;

const PaymentGuaranteeConfirmation = () => {
  const [loading, setloading] = useState(false);
  const [loadingAction, setloadingAction] = useState(false);
  const [declineRequest, setdeclineRequest] = useState(false);
  const [acceptRequest, setacceptRequest] = useState(false);
  const [data, setdata] = useState(false);
  const [active, setactive] = useState(false);
  const [homeBase, sethomeBase] = useState(false);
  const [date, setdate] = useState(new Date());
  const { id } = useParams();
  const history = useHistory();
  const {
    getFieldGroup, getFieldGroupWithNavigatorLang, getGlobalWithNavigatorLang, loadPage, haveContentFor, getFieldWithNavigatorLang, page,
  } = useContext(ContentContext);
  const getFleetInformation = ({ obj, key }) => {
    if (obj && obj.Fleet && obj.Fleet[key]) {
      return obj.Fleet[key];
    }
    return '';
  };
  const getWorkshopApplyInformation = ({ obj, key }) => {
    if (obj && obj.UserWorkshop && obj.UserWorkshop.UserWorkshopAlias && obj.UserWorkshop.UserWorkshopAlias[key]) {
      return obj.UserWorkshop.UserWorkshopAlias[key];
    }
    return '';
  };
  const getHomeBaseInformation = ({ obj, key }) => {
    if (obj && obj.Fleet && obj.Fleet.UserFleet && obj.Fleet.UserFleet.UserWorkshop && obj.Fleet.UserFleet.UserWorkshop.UserWorkshopAlias[key]) {
      return obj.Fleet.UserFleet.UserWorkshop.UserWorkshopAlias[key];
    }
    return '';
  };

  const getWorkshop = async (idWorkshop) => {
    const res = await getPublicWorkShopUserGop({ userId: idWorkshop, tempUrl: id });
    if (res && res.data && res.data.data) {
      return res.data.data;
    }
    return false;
  };

  const handleRefuse = async (dataRes) => {
    setloadingAction(true);
    const thedate = new Date();
    setdate(thedate);
    const updateRes = await UpdatePublicStatus({
      status: SERVICE_STATUS.error,
      statusText: '',
      id: dataRes.id,
      fleetid: dataRes && (dataRes.Fleet.UserFleet.UserFleetAlias.id),
      confirmationDate: thedate,
      url: id,
    });
    if (updateRes) {
      setloadingAction(false);
      setdeclineRequest(true);
      setactive(true);
      if (!getWorkshopInformation({ obj: dataRes, key: 'notificationDisable' })) {
        SendMail({
          template: 'notification',
          templateNotification: {
            status: 'error',
            statusLabel: getFieldGroup({
              select_lang: getWorkshopInformation({ obj: dataRes, key: 'lang' }), base: strapiPaymentGuaranteeService, groupe: 'mail_gop_refused', field: 'status',
            }),
            content: `${getHomeBaseInformation({ obj: dataRes, key: 'companyName' })} ${getFieldGroup({
              select_lang: getWorkshopInformation({ obj: dataRes, key: 'lang' }), base: strapiPaymentGuaranteeService, groupe: 'mail_gop_refused', field: 'content',
            })}`,
            btnContent: getFieldGroup({
              select_lang: getWorkshopInformation({ obj: dataRes, key: 'lang' }), base: strapiPaymentGuaranteeService, groupe: 'mail_gop_refused', field: 'btn',
            }),
            btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.workshop.dashboardPaymentGuarantee.path}`,
          },
          subject: getFieldGroup({
            select_lang: getWorkshopInformation({ obj: dataRes, key: 'lang' }), base: strapiPaymentGuaranteeService, groupe: 'mail_gop_refused', field: 'subject',
          }),
          destination: getWorkshopInformation({ obj: dataRes, key: 'email' }),
        });
      }
    }
  };

  const handleAccept = async (dataRes) => {
    setloadingAction(true);
    const updateRes = await UpdatePublicStatus({
      status: SERVICE_STATUS.done,
      statusText: '',
      id: dataRes.id,
      fleetid: dataRes && (dataRes.Fleet.UserFleet.UserFleetAlias.id),
      confirmationDate: new Date(),
      url: id,
    });
    if (updateRes) {
      setloadingAction(false);
      setacceptRequest(true);
      setactive(true);
      if (!getWorkshopInformation({ obj: dataRes, key: 'notificationDisable' })) {
        SendMail({
          template: 'notification',
          templateNotification: {
            status: 'validate',
            statusLabel: getFieldGroup({
              select_lang: getWorkshopInformation({ obj: dataRes, key: 'lang' }), base: strapiPaymentGuaranteeService, groupe: 'mail_gop_accepted', field: 'status',
            }),
            content: `${getHomeBaseInformation({ obj: dataRes, key: 'companyName' })} ${getFieldGroup({
              select_lang: getWorkshopInformation({ obj: dataRes, key: 'lang' }), base: strapiPaymentGuaranteeService, groupe: 'mail_gop_accepted', field: 'content',
            })}`,
            btnContent: getFieldGroup({
              select_lang: getWorkshopInformation({ obj: dataRes, key: 'lang' }), base: strapiPaymentGuaranteeService, groupe: 'mail_gop_accepted', field: 'btn',
            }),
            btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.workshop.dashboardPaymentGuarantee.path}`,
          },
          subject: getFieldGroup({
            select_lang: getWorkshopInformation({ obj: dataRes, key: 'lang' }), base: strapiPaymentGuaranteeService, groupe: 'mail_gop_accepted', field: 'subject',
          }),
          destination: getWorkshopInformation({ obj: dataRes, key: 'email' }),
        });
      }
    }
  };

  const loadContent = async () => {
    if (haveContentFor(strapiPaymentGuaranteeService).success === false) {
      await loadPage(strapiPaymentGuaranteeService);
    }
    if (haveContentFor(strapiSingleServicePage).success === false) {
      await loadPage(strapiSingleServicePage);
    }
    if (haveContentFor(strapiWorkshopDashboardPaymentGuarantee).success === false) {
      await loadPage(strapiWorkshopDashboardPaymentGuarantee);
    }
  };

  const getParamsData = (dataRes) => {
    if (getParams('accept')) {
      handleAccept(dataRes);
    }

    if (getParams('declined')) {
      handleRefuse(dataRes);
    }
  };

  const getHomeBase = (dataObj) => {
    if (dataObj && dataObj.Fleet && dataObj.Fleet.UserFleet && dataObj.Fleet.UserFleet.workshopId) {
      return dataObj.Fleet.UserFleet.workshopId;
    }
  };

  const getService = async () => {
    const res = await GetSingleServiceByUrl({ url: id });
    if (
      res
      && res.data
      && res.data.success
      && res.data.data
      && res.data.data.askConfirmation
    ) {
      const homeBaseFormated = await getWorkshop(getHomeBase(res.data.data));
      setdata(res.data.data);
      sethomeBase(homeBaseFormated);
      setloading(false);

      getParamsData(res.data.data);
    } else {
      history.push('/login');
    }
  };

  // useEffect(() => {
  //   loadContent();
  //   getService();
  // }, []);
  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
    if (page && page[strapiPaymentGuaranteeService.view] && page[strapiSingleServicePage.view] && page[strapiWorkshopDashboardPaymentGuarantee.view] && !data) {
      getService();
    }
  }, [page, data]);

  const renderLoader = () => <Loader__wrapper />;

  if (
    haveContentFor(strapiPaymentGuaranteeService).success === false
    || haveContentFor(strapiSingleServicePage).success === false
    || haveContentFor(strapiWorkshopDashboardPaymentGuarantee).success === false
  ) {
    return <Transition__loader_controller active />;
  }
  const renderPDf = () => (
    <Contract__pdf
      buttonText={getFieldWithNavigatorLang({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_downnload' })}
      loadingtext={getFieldWithNavigatorLang({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_download_active' })}
      name={`contract_gop_${data.uid}`}
      content={getFieldGroupWithNavigatorLang({
        base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'contract_gop', field: 'contract_content',
      })}
      type="paymentGurantee"

      guarantor_company={homeBase ? homeBase.UserWorkshopAlias.companyName : ''}
      guarantor_address={`${homeBase ? homeBase.UserWorkshopAlias.adress : ''} ${homeBase ? homeBase.UserWorkshopAlias.city : ''}, ${homeBase ? homeBase.UserWorkshopAlias.zipcode : ''}`}
      guarantor_country={homeBase ? homeBase.UserWorkshopAlias.region : ''}
      guarantor_contact={homeBase ? homeBase.UserWorkshopAlias.email : ''}

      supervisor_name={`${homeBase ? homeBase.supervisorname : ''} ${homeBase ? homeBase.supervisorlastname : ''}`}
      supervisor_mail={homeBase ? homeBase.supervisormail : ''}
      supervisor_time={DateTime({ data: date, atContent: getGlobalWithNavigatorLang({ option: 'globalcontent', field: 'at' }) })}

      workshop_company={getWorkshopApplyInformation({ obj: data, key: 'companyName' })}
      workshop_address={`${getWorkshopApplyInformation({ obj: data, key: 'adress' })} ${getWorkshopApplyInformation({ obj: data, key: 'city' })}, ${getWorkshopApplyInformation({ obj: data, key: 'zipcode' })}`}
      workshop_country={getWorkshopApplyInformation({ obj: data, key: 'region' })}
      workshop_contact={getWorkshopApplyInformation({ obj: data, key: 'email' })}

      amount={formatPrice(data.price)}

      fleet_company={getFleetUserInformation({ obj: data, key: 'companyName' })}
      fleet_address={getFleetUserInformation({ obj: data, key: 'adress' })}
      fleet_zip={getFleetUserInformation({ obj: data, key: 'zipcode' })}
      fleet_location={getFleetUserInformation({ obj: data, key: 'city' })}
      fleet_phone={getFleetUserInformation({ obj: data, key: 'phoneNumber' })}
      fleet_cmail={getFleetUserInformation({ obj: data, key: 'email' })}
      fleet_tva={getFleetUserInformation({ obj: data, key: 'vat' })}

      vehicle_tva={getFleetInformation({ obj: data, key: 'vin' })}
      vehicle_registration={getFleetInformation({ obj: data, key: 'registrationNumber' })}
      vehicle_type={getFleetInformation({ obj: data, key: 'type' })}
      vehicle_brand={getFleetInformation({ obj: data, key: 'brand' })}
      vehicle_country={getFleetInformation({ obj: data, key: 'countryOfRegistration' })}
      service_uid={data.uid}
    />
  );

  const renderContent = () => (
    declineRequest ? (
      <Fragment>
        <Message__sucess
          icon={t.image.illu.error}
          title={getFieldWithNavigatorLang({ base: strapiPaymentGuaranteeService, field: 'msg_error_title' })}
          text={getFieldWithNavigatorLang({ base: strapiPaymentGuaranteeService, field: 'msg_error_text' })}
          hideButton
        />
        {loadingAction && (renderLoader())}
      </Fragment>
    ) : acceptRequest ? (
      <Fragment>
        <Message__sucess
          icon={t.image.illu.check}
          title={getFieldWithNavigatorLang({ base: strapiPaymentGuaranteeService, field: 'msg_success_title' })}
          text={getFieldWithNavigatorLang({ base: strapiPaymentGuaranteeService, field: 'msg_success_text' })}
          hideButton
          buttonNode={renderPDf(data)}
        />
        {loadingAction && (renderLoader())}
      </Fragment>
    ) : null
  );

  return (
    <Container>
      <Transition__loader_controller active={false} />
      <Popup active={active} noClose>
        {loading || !data ? renderLoader() : renderContent()}
      </Popup>
    </Container>
  );
};

export default PaymentGuaranteeConfirmation;
