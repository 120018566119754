/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

import { Grid__card } from 'components/grid/grid';
import Button__buttonCircle from 'components/button/buttonCircle/button__buttonCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/pro-solid-svg-icons';

const Container = styled.div`
  width: ${(props) => (props.small ? 'calc(50% - 15px)' : 'auto')};
`;

const Card = styled(Grid__card)`
  display: flex;
  flex-direction: ${(props) => (props.small ? 'column-reverse' : 'row')};
  align-items: center;
  justify-content: ${(props) => (props.small ? 'center' : 'space-between')};
  padding: ${(props) => (props.small ? '25px 20px' : '25px')};
`;

const Title = styled.div`
  font-weight: normal;
  font-size: ${(props) => (props.small ? '12px' : '14px')};
  color: ${(props) => (props.small ? '#01B5E2' : '#4d4d4d')};
  user-select: none;
  margin-top: ${(props) => (props.small ? '5px' : '0')};
  text-align: ${(props) => (props.small ? 'center' : 'left')};
  line-height: 1.3;
`;

const Cards__serviceInfo = ({
  style, title, event, small,
}) => (
  <Container style={style} small={small}>
    <Card style={style} padding="25px" small={small}>
      <Title small={small}>{title}</Title>
      <Button__buttonCircle event={event} small={small} icon={<FontAwesomeIcon icon={faInfo} />} />
    </Card>
  </Container>
);

export default Cards__serviceInfo;
