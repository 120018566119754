/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 1px;
  background-color: #bcbcbc;
  width: 100%;
`;

const Grid__separatorLine = ({
  style,
  className,
}) => <Container style={style} className={className} />;

export default Grid__separatorLine;
