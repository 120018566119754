
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'context/appContext';

import { FleetContext } from 'context/fleetUser/fleetContext';
import {
  Route, Redirect, useLocation,
} from 'react-router-dom';

import { Transition__loader_controller } from 'components/transition/transition';
import { ContentContext } from 'context/contentContext';

const PrivateRoute = ({
  component: Component, path, ...rest
}) => {
  const {
    checkAuth,
    logged,
    user,
  } = useContext(AppContext);
  const {
    initFleetContext,
  } = useContext(FleetContext);
  const {
    changeLang,
    lang,
  } = useContext(ContentContext);

  const location = useLocation();

  const [active, setActive] = useState(false);

  const getDataAsync = async () => {
    const fleetCall = await initFleetContext();
    if (fleetCall.success === true) {
      return {
        success: true,
      };
    }
    return {
      success: false,
    };
  };

  const setLang = (data) => {
    if (data && data.data && data.data.user && data.data.user.lang) {
      if (lang !== data.data.user.lang) {
        changeLang(data.data.user.lang);
      }
    }
  };

  const handleCheckAuth = async () => {
    const loginCall = await checkAuth();

    if (loginCall.success === true) {
      const getData = await getDataAsync();
      setLang(loginCall);
      if (getData.success === true) {
        setActive(true);
      } else {
        setActive(true);
      }
    } else {
      localStorage.setItem('from_url', `${location.pathname}${location.search}`);
      setActive(true);
    }
  };

  useEffect(() => {
    if (logged === false) {
      handleCheckAuth();
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        logged === true && user && user.user ? (
          <div>
            <Component {...props} />
          </div>
        ) : logged === false && active === true ? (
          <Redirect to={{
            pathname: '/login',
            state: { referrer: '/' },
          }}
          />
        ) : logged === false && !user.user && active === false ? (
          <Transition__loader_controller active={!active} />
        ) : logged === true && !user.user ? (
          <Transition__loader_controller active={!active} />
        ) : null
      )}
    />
  );
};

export default PrivateRoute;
