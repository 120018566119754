/**
 * Import Lib & assets
 */

import React, { Fragment } from 'react';
import styled from 'styled-components';
import t from 'theme';
import Content__titleIconService from 'components/content/titleIconService/content__titleIconService';
import Button__primary from 'components/button/primary/button__primary';
import { Grid__separator } from 'components/grid/grid';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faEnvelope } from '@fortawesome/pro-regular-svg-icons';

const Container = styled.div`
  background: #f8f8f8;
  width: 100%;
  border-radius: 5px;
  padding: 15px 30px;
  min-height: 70px;
  ${t.mixins.flex({ justify: 'space-between', align: 'center', direction: 'row' })}

  ${({ disable }) => disable && (`
    pointer-events: none;
    user-select: none;
    opacity: 0.6;
  `)};
`;

const ButtonContainer = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'row' })}
`;

const Cards__serviceAvailable = ({
  style, icon, title, buttonDashboard, buttonRequest, buttonServiceHref, buttonRequestHref, disable,
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (buttonServiceHref) {
      history.push(buttonServiceHref);
    }
  };

  const handleSecondClick = () => {
    if (buttonRequestHref) {
      history.push(buttonRequestHref);
    }
  };
  return (
    <Container style={style} disable={disable}>
      <Content__titleIconService icon={icon} content={title} />
      <ButtonContainer>
        {buttonRequest !== undefined ? (
          <Button__primary
            withIcon
            event={handleSecondClick}
            icon={<FontAwesomeIcon icon={faEnvelope} />}
            noHover={disable}
          >
            {buttonRequest}
          </Button__primary>
        ) : null}
        {buttonDashboard !== undefined ? (
          <Fragment>
            <Grid__separator width="15px" />
            <Button__primary
              border
              blue
              withIcon
              event={handleClick}
              icon={<FontAwesomeIcon icon={faChartBar} />}
              noHover={disable}
            >
              {buttonDashboard}
            </Button__primary>
          </Fragment>
        ) : null}
      </ButtonContainer>
    </Container>
  );
};

export default Cards__serviceAvailable;
