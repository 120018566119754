
/**
   * Import Lib & assets
   */

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #E6F8FC;
  border-radius: 10px;
  padding: 15px;
  display: ${(props) => (props.column ? 'flex' : null)};
  flex-direction: ${(props) => (props.column ? 'column-reverse' : null)};
  align-items: ${(props) => (props.column ? 'center' : null)};
`;

const Label = styled.span`
  font-size: 15px;
  color: #01B5E2;
  margin-right: ${(props) => (props.column ? null : '5px')};
  margin-top: ${(props) => (props.column ? '10px' : null)};
`;

const Value = styled.span`
  font-weight: 600;
  font-size: ${(props) => (props.column ? '20px' : '16px')};
  color: #01B5E2;
  text-transform: uppercase;
`;

const Content__bigTag = ({
  style, label, value, column,
}) => (
  <Container style={style} column={column}>
    <Label column={column}>{label}</Label>
    <Value column={column}>{value}</Value>
  </Container>
);

export default Content__bigTag;
