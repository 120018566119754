
/**
   * Import Lib & assets
   */

import React, { Fragment } from 'react';
import styled from 'styled-components';
import t from 'theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button__buttonCircle from 'components/button/buttonCircle/button__buttonCircle';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { Grid__separator } from 'components/grid/grid';
import { Button__primary } from 'components/button/button';

const Container = styled.div`
`;

const ServiceStatusAnswerTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #4D4D4D;
  text-align: left;
`;

const ServiceStatusAnswerSubtitle = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #333333;
  text-align: left;
`;

const ServiceStatusAnswerButton = styled.div`
  margin-top: 25px;
  ${t.mixins.flex({ justify: 'center', align: 'center' })}
  display: inline-flex;
`;

const Content__statusAnwser = ({
  style,
  title,
  subtitle1,
  subtitle2,
  subtitle3,
  link1,
  link2,
  link3,
  action,
  buttonRefused,
  buttonAccept,
  buttonRefused_event,
  buttonAccept__event,
}) => (
  <Container style={style}>
    <ServiceStatusAnswerTitle>
      {title}
    </ServiceStatusAnswerTitle>
    <Grid__separator size="5px" />
    <ServiceStatusAnswerSubtitle>
      {
        subtitle1 && (
          <Fragment>
            {subtitle1}
            <br />
          </Fragment>
        )
      }
      {
        subtitle2 && (
          <Fragment>
            {subtitle2}
            <br />
          </Fragment>
        )
      }
      {
        subtitle3 && (
          <Fragment>
            {subtitle3}
          </Fragment>
        )
      }
    </ServiceStatusAnswerSubtitle>
    {!action ? (
      <div>
        <ServiceStatusAnswerButton>
          {
            link1 && (
              <Button__buttonCircle
                href={link1}
                noprevent
                icon={<FontAwesomeIcon icon={faPhone} />}
                target="_blank"
                border
              />
            )
          }
          {
            link2 && (
              <Fragment>
                <Grid__separator width="15px" />
                <Button__buttonCircle
                  href={`mailto:${link2}`}
                  noprevent
                  icon={<FontAwesomeIcon icon={faEnvelope} />}
                  target="_blank"
                  border
                />
              </Fragment>
            )
          }
          {
            link3 && (
              <Fragment>
                <Grid__separator width="15px" />
                <Button__buttonCircle
                  href={link3}
                  noprevent
                  icon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
                  target="_blank"
                  border
                />
              </Fragment>
            )
          }

        </ServiceStatusAnswerButton>
      </div>
    ) : (
      <ServiceStatusAnswerButton>
        <Button__primary border red event={buttonRefused_event}>{buttonRefused}</Button__primary>
        <Grid__separator width="15px" />
        <Button__primary event={buttonAccept__event}>{buttonAccept}</Button__primary>
      </ServiceStatusAnswerButton>
    )}
  </Container>
);

export default Content__statusAnwser;
