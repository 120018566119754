
import moment from 'moment';
import { isEmail } from './isEmail.conditions';
import { autoScroll } from '../function/autoScroll';

/* ------------------------------ SIMPLE FIELDS ----------------------------- */
export const checkField_simple = (field, getField) => ({
  valide: field !== '' && field !== null && field !== undefined,
  message: getField && (getField({ option: 'error', field: 'empty_field' })),
});

/* ------------------------------ EMAIL FIELDS ------------------------------ */
export const checkField_mail = (field, getField) => ({
  valide: checkField_simple(field).valide && isEmail(field),
  message: getField && (getField({ option: 'error', field: 'mail_field' })),
});

/* ------------------------------ NAME FIELDS ------------------------------ */
export const checkField_text = (field, getField) => {
  const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ]*)*$/;
  return {
    valide: checkField_simple(field).valide && regex.test(field),
    message: getField && (getField({ option: 'error', field: 'onlytext_field' })),
  };
};

/* ------------------------------ TEL FIELDS ------------------------------ */
export const checkField_tel = (field, getField) => {
  const regex = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/;
  return {
    valide: checkField_simple(field).valide && regex.test(field),
    message: getField && (getField({ option: 'error', field: 'phone_field' })),
  };
};

/* ------------------------------ TEL FIELDS ------------------------------ */
export const checkField_telLib = (field, getField) => ({
  valide: checkField_simple(field.value).valide && field.valide,
  message: getField && (getField({ option: 'error', field: 'phone_field' })),
});

/* ------------------------------ NUMBER FIELDS ------------------------------ */
export const checkField_number = (field, getField) => {
  const regex = /^[0-9]+[0-9]*$/;
  return {
    valide: checkField_simple(field).valide && regex.test(field),
    message: getField && (getField({ option: 'error', field: 'number_field' })),
  };
};

/* ------------------------------ DATE FIELD  ------------------------------ */
export const checkField_date = (field, getField) => {
  const date = field;
  let valideDate = true;
  if (date) {
    const checkDate = moment(date, 'DD/MM/YYYY', true);
    if (checkDate && checkDate.isValid()) {
      //
    } else {
      valideDate = false;
    }
  }


  return {
    valide: checkField_simple(field).valide && valideDate,
    message: getField && (getField({ option: 'error', field: 'empty_field' })),
  };
};

/* ------------------------------ NO SPECIAL CHAR FIELDS ------------------------------ */
export const checkField_nospecialChar = (field, getField) => {
  const regex = /^[a-zA-Z0-9]*$/;
  return {
    valide: checkField_simple(field).valide && regex.test(field),
    message: getField && (getField({ option: 'error', field: 'nospecialchar_field' })),
  };
};

/* ------------------------------ NO SPECIAL CHAR FIELDS ------------------------------ */
export const checkField_nodashandspace = (field, getField) => {
  const space = /\s/;
  // eslint-disable-next-line no-useless-escape
  const dash = /[,\-]/;
  // äöüÄÖÜẞßąćśźżęńłóĄĆŚŹŻĘŃŁÓ
  const check = /^[a-zA-Z0-9äöüÄÖÜẞßąćśźżęńłóĄĆŚŹŻĘŃŁÓ]*$/;
  return {
    // valide: checkField_simple(field).valide && !space.test(field) && !dash.test(field),
    valide: checkField_simple(field).valide && check.test(field) && !space.test(field) && !dash.test(field),
    message: getField && (getField({ option: 'error', field: 'nospecialchar_field' })),
  };
};


/* --------------------------- CHECK FIELD BY TYPE -------------------------- */

export const isValidField = (field, type, getField) => {
  if (type === 'simple') {
    return checkField_simple(field, getField);
  }
  if (type === 'email') {
    return checkField_mail(field, getField);
  }
  if (type === 'name') {
    return checkField_nodashandspace(field, getField);
  }
  if (type === 'tel') {
    return checkField_tel(field, getField);
  }
  if (type === 'phoneLib') {
    return checkField_telLib(field, getField);
  }
  if (type === 'number') {
    return checkField_number(field, getField);
  }
  if (type === 'nospecialchar') {
    return checkField_nospecialChar(field, getField);
  }
  if (type === 'nospacedashchar') {
    return checkField_nodashandspace(field, getField);
  }
  if (type === 'date') {
    return checkField_date(field, getField);
  }
  if (type === 'boolean') {
    return {
      valide: field === true,
      message: getField && (getField({ option: 'error', field: 'empty_field' })),
    };
  }

  return false;
};

/* ----------------------------- SCROLL TO ERROR ---------------------------- */


export const ScrollToError = () => {
  setTimeout(() => {
    const error = document.querySelectorAll('.formError');
    if (error && error.length > 0) {
      autoScroll(error[0]);
    }
  }, 100);
};

/* --------------------------- CREATE ERROR OBJECT -------------------------- */

export const createErrorObject = (object, arrayOfField, error, getField) => {
  const newObj = object;
  let hasError = error || false;
  arrayOfField.forEach((field) => {
    const checker = isValidField(
      field.value || '',
      field.type || '',
      getField,
    );
    if (checker.valide === false) {
      if (field && field.hasOwnProperty('key')) {
        newObj[field.key] = checker;
        hasError = true;
      }
    }
  });

  if (hasError === true) {
    ScrollToError();
  }
  return {
    object: newObj,
    hasError,
  };
};
