
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid__separator } from 'components/grid/grid';
import { ContentContext } from 'context/contentContext';
import Button__primary from 'components/button/primary/button__primary';
import { List__table, List__content } from 'components/list/list';
import t from 'theme';

import { shouldRenderFairPrice, shouldRenderService24 } from 'util/service/createHistoric';
import { sequelizeDate } from 'util/date.util';
import { SERVICENAME } from 'util/service/serviceName';
import { AppContext } from 'context/appContext';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { useData } from 'components/data/getter/data__getter';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { getSingleVehicle } from 'helpers/api/routes/getData';
import Form__search from 'components/form/search/form__search';

const Container = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', direction: 'column' })}
`;

const InfoText = styled.div`
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #4d4d4d;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const formatMileage = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

const ServiceDashboard__vehicleList = ({
  nextEvent, serviceName, defaultSelected, withDisable, noText,
}) => {
  const { getGlobal } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [fleetFormatted, setFleetFormatted] = useState([]);
  const [selectedLine, setselectedLine] = useState(null);
  const [fleetNoFormatted, setFleetNoFormatted] = useState([]);
  const [searchValue, setsearchValue] = useState(null);
  let pageNumber = 1;
  // eslint-disable-next-line no-unused-vars
  const [fleets, refreshData, setPage, setSearch] = useData({
    params: {
      userId: user.user.UserFleet.id,
      service24: serviceName === SERVICENAME.service24,
      fairPrice: serviceName === SERVICENAME.fairPrice,
    },
    service: 'vehicle',
    type: 'vehicle',
  });
  const headerCol = [
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'vincode' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'category' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'brand' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'mileage' }),
    },
  ];

  const switchServiceName = (vehicle) => {
    if (serviceName === SERVICENAME.fairPrice) {
      return shouldRenderFairPrice(vehicle);
    }

    if (serviceName === SERVICENAME.service24) {
      return shouldRenderService24(vehicle);
    }
    return false;
  };

  const restrictedServiceArray = () => {
    const base = [];

    fleets.data.forEach((vehicle) => {
      if (switchServiceName(vehicle) === true) {
        base.push(vehicle);
      }
    });

    return base;
  };

  useEffect(() => {
    if (fleets.data && fleets.data.length > 0) {
      const obj = [];
      const noFormatedObj = [];
      const orderArray = restrictedServiceArray();
      orderArray.forEach((element) => {
        const childObj = [];

        childObj.push({
          label: <List__content uppercase>{element.registrationNumber}</List__content>,
        });
        childObj.push({ label: <List__content>{element.vin}</List__content> });
        childObj.push({
          label: (
            <List__content capitalize>
              {getGlobal({ option: 'vehicletype', field: element.type }) || element.type}
            </List__content>
          ),
        });
        childObj.push({ label: <List__content capitalize>{element.brand}</List__content> });
        childObj.push({
          label: (
            <List__content>{sequelizeDate({ date: element.firstDate, format: 'd/m/y' })}</List__content>
          ),
        });
        childObj.push({ label: <List__content>{formatMileage(element.mileage)}</List__content> });

        obj.push(childObj);
        noFormatedObj.push(element);
      });

      setFleetFormatted(obj);
      setFleetNoFormatted(noFormatedObj);
    }
  }, [fleets.data]);

  const handleNextStep = () => {
    if (selectedLine !== null) {
      if (nextEvent) {
        nextEvent(selectedLine);
      }
    }
  };

  const handleSelectVehicle = (selected) => {
    const key = parseInt(selected[0].key, 10);
    setselectedLine(fleetNoFormatted[key]);
  };

  const getSingleVehicleReq = async () => {
    const res = await getSingleVehicle({
      id: defaultSelected.id,
    });
    const formated = formatApiRes(res);
    setselectedLine(formated.data);
    if (nextEvent) {
      nextEvent(formated.data);
    }
  };

  useEffect(() => {
    if (defaultSelected.id) {
      getSingleVehicleReq();
    }
  }, [defaultSelected]);

  const handleLoadNewPage = () => {
    if (fleets.data && !fleets.loading && pageNumber + 1 > 1 && fleets.data.length < fleets.count) {
      pageNumber += 1;
      setPage(pageNumber, fleets.data);
    }
  };

  const doSearch = () => {
    setSearch(searchValue);
  };


  return (

    <Container>
      {!noText && (
        <>
          <InfoText>{getGlobal({ option: 'service', field: 'single_launch_text' })}</InfoText>
          <Grid__separator size="20px" />
        </>
      )}
      <Row>
        <Form__search
          property="searchValue"
          search
          placeholder={getGlobal({ option: 'service', field: 'single_launch_placeholder' })}
          change={(value) => setsearchValue(value.value)}
          searchValue={searchValue}
          event={doSearch}
        />
      </Row>
      <Grid__separator size="30px" />
      <List__table
        headerCol={headerCol}
        lineCol={fleetFormatted}
        maxHeight="250px"
        noSelectAll
        selectOne
        borderLine
        selectedLine={handleSelectVehicle}
        scrollEndEvent={handleLoadNewPage}
      />
      {
        fleets.loading && (
          <Loader__wrapper />
        )
      }
      <Grid__separator size="25px" />
      <Button__primary event={handleNextStep} disable={withDisable ? !selectedLine : false}>{getGlobal({ option: 'service', field: 'single_launch_button' })}</Button__primary>
    </Container>
  );
};

export default ServiceDashboard__vehicleList;
