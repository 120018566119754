
/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

/**
 * styled components
 */

const Container = styled.div`
  padding: 7px 12px;
  background: green;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${(props) => (props.orange ? '#FFAA29' : props.red ? '#E74C3C' : props.green ? '#2ECC71' : '#2ECC71')};
  background-color: ${(props) => (props.orange ? '#FFEED4' : props.red ? '#FADBD8' : props.green ? '#D5F5E3' : '#D5F5E3')};
`;

const Content = styled.p`
  color: red;
  font-size: 14px;
  line-height: normal;
  font-weight: 700;
  font-family: ${t.font.primary};
  display: inline-block;
  margin: 0;
  transition: color ease 0.5s;
  color: ${(props) => (props.orange ? '#FFAA29' : props.red ? '#E74C3C' : props.green ? '#2ECC71' : '#2ECC71')};
`;

/**
 * status component is..
 */

const Content__status = ({
  style, className, children, orange, red, green,
}) => (
  <Container style={style} className={className} green={green} orange={orange} red={red}>
    <Content type="p" green={green} orange={orange} red={red}>{children}</Content>
  </Container>
);

export default Content__status;
