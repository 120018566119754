import React from 'react';
import styled from 'styled-components';
import Breadcrumb from 'components/global/breadcrumb';

import { Grid__separator } from 'components/grid/grid';
import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';
import Content__counterInfo from 'components/content/counterInfo/content__counterInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Container = styled.div``;

const Title__container = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 28px;
  color: #313232;
  margin-right: 10px;
`;


const ServiceDashboard__header = ({
  style,
  breadCrumb_parent,
  breadCrumb_parentLink,
  page_title,
  actionCount,
  page_icon,
}) => (
  <Container style={style}>
    <Breadcrumb
      back
      parent={breadCrumb_parent}
      parentLink={breadCrumb_parentLink}
    />
    <Grid__separator size="15px" />
    <Title__container>
      <Icon icon={page_icon} />
      <Content__pageTitle content={page_title} />
      <Grid__separator width="25px" />
      {
        (actionCount) ? (
          <Content__counterInfo
            value={actionCount}
            label="Action Needed"
          />
        ) : null
      }
    </Title__container>
    <Grid__separator size="30px" />
  </Container>
);

export default ServiceDashboard__header;
