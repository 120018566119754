import { API_URL } from '../Utile';
import { CallGet, CallPost } from '../Helpers';

/* ---------------------------- GET ALL VEHICULE ---------------------------- */

export const GetSingleService = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}service/service24/${payload.id}`;

  const res = await CallGet(url, params, headers);

  return res;
};


export const LaunchServiceFairePrice = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/service24/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const UpdateStatus = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/service24/updateStatus`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const RegisterFleetToService = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const json = JSON.stringify(payload.fleetObj);
  const params = {
    fleetObj: json,
  };

  const url = `${API_URL}service/service24/registerFleet`;

  const res = await CallPost(url, params, headers);

  return res;
};
