import React, { useContext, useEffect, useState } from 'react';
import { ContentContext } from 'context/contentContext';
import Nav from 'components/global/nav';
import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Transition__loader_controller } from 'components/transition/transition';
import Cases__caseContainer from 'containers/workshopView/cases/cases/caseContainer/cases__caseContainer';
import { AppContext } from 'context/appContext';
import { useData } from 'components/data/getter/data__getter';
import {
  strapiCase,
  strapiAlertGasoilService,
  strapiProFleetAssistService,
  strapiRepairApprovalService,
  strapiService24Service,
  strapiSmartMaitenanceService,
  strapiPaymentGuaranteeService,
} from 'util/strapi/content.starpi';


let pageNumber = 1;
const cases = () => {
  const {
    loadPage, haveContentFor,
  } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState(0);
  const [resData, refreshData, setPage] = useData({
    params: {
      userId: user.user.UserWorkshop.id,
      status: 'opened',
      both_counter: true,
    },
    service: 'cases',
    type: 'cases',
    removeFirstLoading: true,
  });

  const loadContent = async () => {
    if (haveContentFor(strapiCase).success === false) {
      await loadPage(strapiCase);
    }
    if (haveContentFor(strapiAlertGasoilService).success === false) {
      await loadPage(strapiAlertGasoilService);
    }
    if (haveContentFor(strapiSmartMaitenanceService).success === false) {
      await loadPage(strapiSmartMaitenanceService);
    }
    if (haveContentFor(strapiPaymentGuaranteeService).success === false) {
      await loadPage(strapiPaymentGuaranteeService);
    }
    if (haveContentFor(strapiService24Service).success === false) {
      await loadPage(strapiService24Service);
    }
    if (haveContentFor(strapiProFleetAssistService).success === false) {
      await loadPage(strapiProFleetAssistService);
    }
    if (haveContentFor(strapiRepairApprovalService).success === false) {
      await loadPage(strapiRepairApprovalService);
    }
  };

  useEffect(() => {
    refreshData({
      userId: user.user.UserWorkshop.id,
      status: activeTab === 0 ? 'opened' : 'closed',
      both_counter: true,
    });
  }, [activeTab]);

  useEffect(() => {
    loadContent();
    return () => {
      pageNumber = 1;
    };
  }, []);

  if (haveContentFor(strapiCase).success === false
    || haveContentFor(strapiAlertGasoilService).success === false
    || haveContentFor(strapiSmartMaitenanceService).success === false
    || haveContentFor(strapiPaymentGuaranteeService).success === false
    || haveContentFor(strapiService24Service).success === false
    || haveContentFor(strapiProFleetAssistService).success === false
    || haveContentFor(strapiRepairApprovalService).success === false) {
    return <Transition__loader_controller active />;
  }

  const handleLoadNewPage = () => {
    if (resData.data && !resData.loading && pageNumber + 1 > 1 && resData.data.length < resData.count) {
      pageNumber += 1;
      setPage(pageNumber, resData.data);
    }
  };

  return (
    <Layout__container>
      <Nav workshop active="cases" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <Layout__content>
          <Cases__caseContainer
            basePage={strapiCase}
            data={resData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            loading={resData.loading}
            handleLoadNewPage={handleLoadNewPage}
          />
        </Layout__content>
      </Layout__wrapper>
    </Layout__container>
  );
};

export default cases;
