
import React, { useContext } from 'react';
import styled from 'styled-components';
import t from 'theme';
/**
 * Import Context
 */

import { ContentContext } from 'context/contentContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToBottom, faPlus } from '@fortawesome/pro-regular-svg-icons';
/**
 * import assets
 */

/**
 * import containers & components
 */
import Button__primary from 'components/button/primary/button__primary';

import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';

const HeaderPage = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-start' })}
`;

const AddFleetBtn = styled(Button__primary)`
  margin-left: 20px;
`;

const BtnContainer = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', direction: 'row' })}
`;

const HeaderPageFleet = ({ addVehiculeEvent, addVehiculeCsvEvent, basePage }) => {
  const { getField } = useContext(ContentContext);

  return (
    <HeaderPage>
      <div>
        <Content__pageTitle content={getField({ base: basePage, field: 'page_title' })} />
      </div>
      <BtnContainer>
        <AddFleetBtn
          border
          blue
          withIcon
          icon={<FontAwesomeIcon icon={faArrowToBottom} />}
          event={() => addVehiculeCsvEvent()}
        >
          {getField({ base: basePage, field: 'upload_button' })}
        </AddFleetBtn>
        <AddFleetBtn
          withIcon
          icon={<FontAwesomeIcon icon={faPlus} />}
          event={() => addVehiculeEvent()}
        >
          {getField({ base: basePage, field: 'Add_button' })}
        </AddFleetBtn>
      </BtnContainer>
    </HeaderPage>
  );
};

export default HeaderPageFleet;
