
import React, {
  useContext, useEffect, useState,
} from 'react';
import Nav from 'components/global/nav';
import t from 'theme';
import { ContentContext } from 'context/contentContext';

import {
  faListOl, faQuestion, faBookOpen, faShoppingCart,
} from '@fortawesome/pro-light-svg-icons';
import Layout__container from 'containers/layout/container/layout__container';
import { Transition__loader_controller } from 'components/transition/transition';
import DashboardRepairApproval__historic from 'containers/fleetView/dashboardRepairApproval/historic/dashboardRepairApproval__historic';
import DashboardRepairApproval__action from 'containers/fleetView/dashboardRepairApproval/action/dashboardRepairApproval__action';
import DashboardRepairApproval__infoPop from 'containers/fleetView/dashboardRepairApproval/infoPop/dashboardRepairApproval__infoPop';
import DashboardRepairApproval__singlePop from 'containers/fleetView/dashboardRepairApproval/singlePop/dashboardRepairApproval__singlePop';
import DashboardRepairApproval__settings from 'containers/fleetView/dashboardRepairApproval/settings/dashboardRepairApproval__settings';
import { strapiRepairApprovalService, strapiSingleServicePage } from 'util/strapi/content.starpi';
import ServiceDashboard__layout from 'containers/fleetView/serviceDashboard/layout/serviceDashboard__layout';
import { ROUTING } from 'util/constant/routing.constant';
import { getParams } from 'util/get.util';
import { getAction } from 'util/function/reloadAction.function';
import { SERVICENAME } from 'util/service/serviceName';
import { AppContext } from 'context/appContext';
import { updatefleetUserInformation } from 'helpers/api/routes/User';
import { formatApiCountRes } from 'util/function/formatApiRes.function';
import { getcoverage, getFleetRepairApprovalAction, getVehicleList } from 'helpers/api/routes/getData';
import { useData } from 'components/data/getter/data__getter';

/**
 * @name DashboardRepairApproval
 */

const DashboardRepairApproval = () => {
  const {
    loadPage, haveContentFor, getField, getGlobal,
  } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [activeinfoPop, setactiveinfoPop] = useState(false);
  const [activeSettingPop, setactiveSettingPop] = useState(
    user && user.user && user.user.UserFleet.repairApprovalSet !== true ? true : false,
  );
  const [activesinglePop, setactivesinglePop] = useState(false);
  const [actionCount, setactionCount] = useState(false);
  const [, setactiveLaunchPop] = useState(false);
  const [coverage, setcoverage] = useState(0);
  let pageNumber = 1;
  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      id: user.user.UserFleet.id,
      userid: user.user.id,
      user: true,
    },
    service: SERVICENAME.repairapproval,
    type: 'service',
  });


  const [fleetArray, setfleetArray] = useState([]);
  const getFleetList = async () => {
    const res = await getVehicleList({
      userId: user.user.UserFleet.id,
    });

    const formated = formatApiCountRes(res);
    if (formated.success) {
      setfleetArray(formated);
    }
  };

  const handleCheckParams = () => {
    const actionParams = getAction({ key: 'action' });
    if (actionParams.success === true) {
      setactivesinglePop({ active: true, id: actionParams.value });
    }
  };

  const loadContent = async () => {
    if (haveContentFor(strapiRepairApprovalService).success === false) {
      await loadPage(strapiRepairApprovalService);
    }
    if (haveContentFor(strapiSingleServicePage).success === false) {
      await loadPage(strapiSingleServicePage);
    }

    if (getParams('launch')) {
      setactiveLaunchPop(true);
      window.history.pushState({}, '', window.location.pathname);
    }

    handleCheckParams();
  };

  const loadCoverage = async () => {
    const res = await getcoverage({
      service: 'repairApprovalSubscription',
      userId: user.user.UserFleet.id,
    });

    const formatedCoverage = formatApiCountRes(res);

    setcoverage(formatedCoverage.count);
  };

  const getActionNeeded = async () => {
    const resAction = await getFleetRepairApprovalAction({
      page: 1,
      limit: 3,
      id: user.user.UserFleet.id,
      isApprover: user.user.UserFleet.repairApprovalUserId === user.user.id,
    });

    const formated = formatApiCountRes(resAction);
    setactionCount(formated.count);
  };

  useEffect(() => {
    loadContent();
    loadCoverage();
    getActionNeeded();
    getFleetList();
  }, []);

  const updateSetSettings = async () => {
    await updatefleetUserInformation({
      repairApprovalSet: true,
      userId: user.user.UserFleet.id,
    });
  };

  useEffect(() => {
    if (activeSettingPop) {
      if (user && user.user && user.user.UserFleet.repairApprovalSet !== true) {
        updateSetSettings();
      }
    }
  }, [activeSettingPop]);

  if (
    haveContentFor(strapiRepairApprovalService).success === false
    || haveContentFor(strapiSingleServicePage).success === false
  ) {
    return <Transition__loader_controller active />;
  }

  const handleCloseInfoPop = () => {
    setactiveinfoPop(false);
  };

  const handleOpenInfoPop = () => {
    setactiveinfoPop(true);
  };

  const handleCloseSettingPop = () => {
    setactiveSettingPop(false);
  };

  const handleOpenSettingPop = () => {
    setactiveSettingPop(true);
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({ active: false, id: null });
  };

  const handleOpenSinglePop = ({ id }) => {
    setactivesinglePop({ active: true, id });
  };

  const renderInfoPopProps = () => ({
    intro: {
      title: getField({ base: strapiRepairApprovalService, field: 'service_title' }),
      txt: getField({ base: strapiRepairApprovalService, field: 'description' }),
      price: `0,00 ${getField({ base: strapiSingleServicePage, field: 'currency' })}`,
      label: getField({ base: strapiSingleServicePage, field: 'price_detail' }),
      banner: t.image.global.repair_approval,
    },
    condition1: {
      content: getField({ base: strapiRepairApprovalService, field: 'registration_processs' }),
      title: getField({ base: strapiSingleServicePage, field: 'registration_processs' }),
      icon: faListOl,
    },
    condition2: {
      content: getField({ base: strapiRepairApprovalService, field: 'how' }),
      title: getField({ base: strapiSingleServicePage, field: 'how' }),
      icon: faQuestion,
    },
    condition3: {
      content: getField({ base: strapiRepairApprovalService, field: 'conditions' }),
      title: getField({ base: strapiSingleServicePage, field: 'conditions' }),
      icon: faBookOpen,
    },
    condition4: {
      // content: <div dangerouslySetInnerHTML={{ __html: getField({ base: strapiRepairApprovalService, field: 'payment' }) }} />,
      content: getField({ base: strapiRepairApprovalService, field: 'payment' }),
      title: getField({ base: strapiSingleServicePage, field: 'payment' }),
      icon: faShoppingCart,
    },
  });

  const handleTableClick = (line, vehicle) => {
    handleOpenSinglePop({ id: vehicle.id });
  };


  const handleLoadNewPage = () => {
    if (resData.data && !resData.loading && pageNumber + 1 > 1 && resData.data.length < resData.count) {
      pageNumber += 1;
      setPage(pageNumber, resData.data);
    }
  };

  return (
    <Layout__container>
      <Nav active="myservices" />
      <Transition__loader_controller active={false} />
      <ServiceDashboard__layout
        page_title={getField({ base: strapiRepairApprovalService, field: 'service_title' })}
        // breadCrumb
        breadCrumb_parent={getGlobal({ option: 'fleet_menu', field: 'my_services' })}
        breadCrumb_parentLink={ROUTING.fleet.myServices.path}
        breadCrumb_current={getField({ base: strapiRepairApprovalService, field: 'service_title' })}
        // Service history layout
        history={(
          <DashboardRepairApproval__historic
            tableEvent={handleTableClick}
            loadingData={resData.loading}
            data={resData.data}
            scrollEndEvent={handleLoadNewPage}
            loadingNewPage={resData.loading}
            countAction={actionCount}
          />
        )}
        // Action side element
        action={(
          <DashboardRepairApproval__action
            infoEvent={handleOpenInfoPop}
            settingsEvent={handleOpenSettingPop}
            coverage={coverage}
            fleet={fleetArray ? fleetArray.count : 0}
          />
        )}
        // Info pop up
        activeInfoPop={activeinfoPop}
        closeInfoPop={handleCloseInfoPop}
        infoPopProps={renderInfoPopProps()}
        infoPop={<DashboardRepairApproval__infoPop />}
        // Single pop up
        activeSinglePop={activesinglePop}
        closeSinglePop={handleCloseSinglePop}
        singlePop={(
          <DashboardRepairApproval__singlePop
            active={activesinglePop.active}
            id={activesinglePop.id}
            closeEvent={handleCloseSinglePop}
          />
        )}
        // Setting pop up
        settingPop={(
          <DashboardRepairApproval__settings activeSettingPop={activeSettingPop} closeSettingPop={handleCloseSettingPop} fleetArray={fleetArray} />
        )}
      />
    </Layout__container>
  );
};

export default DashboardRepairApproval;
