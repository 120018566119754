import { API_URL } from '../Utile';
import { CallGet } from '../Helpers';

/**
 * Base routes
 */

export const Home = async () => {
  const params = {
    q: 'rick-&-morty',
    embed: 'episodes',
  };

  const url = `${API_URL}`;

  const res = await CallGet(url, params);

  return res;
};
