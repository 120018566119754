/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

const Container = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'row' })};
`;

const Content = styled.div`
  font-weight: 600;
  font-size: 26px;
  text-transform: uppercase;
  color: #01b5e2;
`;

const Image = styled.img`
  margin-right: 20px;
`;

const Content__titleImage = ({ style, content, image }) => (
  <Container style={style}>
    <Image src={image} />
    <Content>{content}</Content>
  </Container>
);

export default Content__titleImage;
