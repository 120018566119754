/**
 * Import Lib & assets
 */

import { Button__primary } from 'components/button/button';
import React from 'react';
import styled from 'styled-components';
import { Grid__card, Grid__separator } from 'components/grid/grid';
import t from 'theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content__titleImage from 'components/content/titleImage/content__titleImage';

const Content = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  color: #4d4d4d;
`;

const Button = styled(Button__primary)`
  width: 100%;
`;

const SecondaryContainer = styled.div`
  text-align: center;
`;

const Separator = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #B3B3B3;
  position: relative;
  display: inline-block;
  &::after {
    content: '';
    margin-left: 4px;
    margin-bottom: 4px;
    display: inline-block;
    width: 20px;
    height: 0px;
    border: 1px solid #B3B3B3;
  }
  &::before {
    content: '';
    margin-right: 4px;
    margin-bottom: 4px;
    display: inline-block;
    width: 20px;
    height: 0px;
    border: 1px solid #B3B3B3;
  }
`;

const Cards__launchService = ({
  style,
  className,
  content,
  title,
  buttonBottomContent,
  buttonBottomEvent,
  buttonBottomIcon,
  buttonTopContent,
  buttonTopEvent,
  buttonTopIcon,
  conditionalWord,
}) => (
  <Grid__card style={style} className={className} padding="25px">
    <Content__titleImage image={t.image.illu.tools} content={title} />
    <Grid__separator size="20px" />
    <Content>{content}</Content>
    <Grid__separator size="20px" />
    <Button withIcon event={buttonTopEvent} icon={<FontAwesomeIcon icon={buttonTopIcon} />}>
      {buttonTopContent}
    </Button>
    {buttonBottomContent ? (
      <SecondaryContainer>
        <Grid__separator size="15px" />
        <Separator>{conditionalWord}</Separator>
        <Grid__separator size="15px" />
        <Button withIcon event={buttonBottomEvent} icon={<FontAwesomeIcon icon={buttonBottomIcon} />}>
          {buttonBottomContent}
        </Button>
      </SecondaryContainer>
    ) : null}
    <Grid__separator size="5px" />
  </Grid__card>
);

export default Cards__launchService;
