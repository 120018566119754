
import React, { useContext, useEffect } from 'react';
import Nav from 'components/global/nav';

import { ContentContext } from 'context/contentContext';

/**
 * import assets
 */

/**
 * import containers & components
 */

import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Transition__loader_controller } from 'components/transition/transition';
import ServicesContainer from 'containers/fleetView/addServices/servicesContainer/addServices__servicesContainer';
import {
  strapiAddServicePage,
  strapiPaymentGuaranteeService,
  strapiSingleServicePage,
} from 'util/strapi/content.starpi';
import { haveServiceContent, loadService } from 'util/function/loadContent.function';

/**
 * @name AddServices
 */

const AddServices = () => {
  const { loadPage, haveContentFor } = useContext(ContentContext);

  const loadContent = async () => {
    if (haveContentFor(strapiAddServicePage).success === false) {
      await loadPage(strapiAddServicePage);
    }
    loadService({ haveFunc: haveContentFor, loadFunc: loadPage });
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (
    haveContentFor(strapiAddServicePage).success === false
    || haveServiceContent({ haveFunc: haveContentFor }) === false
  ) {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container>
      <Nav active="add-services" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <Layout__content>
          <ServicesContainer
            basePage={strapiAddServicePage}
            baseSingleService={strapiSingleServicePage}
            basePaimentguarantee={strapiPaymentGuaranteeService}
          />
        </Layout__content>
      </Layout__wrapper>
    </Layout__container>
  );
};

export default AddServices;
