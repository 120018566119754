import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';


const Container = styled.div`
  display: flex;
  justify-content: ${(props) => (props.back ? 'flex-end' : 'flex-start')};
  flex-direction: ${(props) => (props.back ? 'row-reverse' : 'row')};
  cursor: ${(props) => (props.back ? 'pointer' : 'default')};
  transition: .3s ease all;
  ${(props) => (props.back ? `
    &:hover {
      transform: translateX(10px);
      & svg {
        transform: translateX(-5px) scaleX(-1);
      }
    }
  ` : '')};
`;

const Item = styled(Link)`
  font-weight: normal;
  font-size: 14px;
  color: #01b5e2;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-content: center;
  text-decoration: ${(props) => (props.back ? 'none' : 'underline')};
`;

const ItemCurrent = styled.p`
  font-weight: normal;
  font-size: 14px;
  color: #01b5e2;
  text-decoration: none;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  margin-left: ${(props) => (props.back ? 0 : '10px')};
  color: #01b5e2;
  transform: scaleX(${(props) => (props.back ? '-1' : '1')});
  transform-origin: center;
  transition: .3s ease all;
  font-size: 16px;
`;


const Breadcrumb = ({
  style, parent, parentLink, current, back,
}) => (
  <Container style={style} back={back}>
    <Item back={back} to={parentLink}>
      {back && (<Icon back={back} icon={faLongArrowRight} />)}
      {parent}
    </Item>
    {!back && (<Icon back={back} icon={faLongArrowRight} />)}
    {!back && (<ItemCurrent>{current}</ItemCurrent>)}
  </Container>
);

export default Breadcrumb;
