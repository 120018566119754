/* --------------------------------- GLOBAL --------------------------------- */

const sample = require('./sample.jpg');
const global_logo = require('./global/logo_simple.svg');
const global_logoBlue = require('./global/logo_blue.svg');
const global_bg = require('./global/background.svg');
const cardFront = require('./global/fleetcard_front.jpg');
const cardBack = require('./global/fleetcard_back.jpg');
const service_repair_approval = require('./global/service-repair-approval.jpg');
const service_payment_guarantee = require('./global/service-payment-guarantee.jpg');
const service_priority90 = require('./global/service-priority90.jpg');
const service_24 = require('./global/service-24.jpg');
const smart_maintenance = require('./global/smart-maintenance.jpg');
const service_image_1 = require('./global/service_image_1.jpg');
const service_image_2 = require('./global/service_image_2.jpg');
const service_image_3 = require('./global/service_image_3.jpg');
const workshop_marker = require('./global/workshop_maker.svg');
const workshop_marker_active = require('./global/workshop_marker_active.svg');
const workshop_image = require('./global/workshop_image.png');
const logo_total = require('./global/total_logo.png');

/* ---------------------------------- SMART MAINTENANCE ---------------------------------- */

const service_smart_1 = require('./services/smart-maintenance-1.png');
const service_smart_2 = require('./services/smart-maintenance-2.png');
const service_smart_3 = require('./services/smart-maintenance-3.png');
const service_smart_banner = require('./services/smart-maintenance-banner.jpg');
const service_smart_mod_1 = require('./services/smart-maintenance-mod-1.jpg');
const service_smart_mod_2 = require('./services/smart-maintenance-mod-2.jpg');
const service_smart_mod_3 = require('./services/smart-maintenance-mod-3.jpg');
const service_smart_logo = require('./services/smart-maintenance-logo.png');
const service_smart_what = require('./services/smart-maintenance-what.png');
const service_smart_why = require('./services/smart-maintenance-why.png');

/* ---------------------------------- ALERT GASOIL ---------------------------------- */

const service_alertgasoil_1 = require('./services/alertgasoil/alertgasoil-1.jpg');
const service_alertgasoil_2 = require('./services/alertgasoil/alertgasoil-2.jpg');
const service_alertgasoil_3 = require('./services/alertgasoil/alertgasoil-3.jpg');
const service_alertgasoil_banner = require('./services/alertgasoil/alertgasoil-banner.jpg');
const service_alertgasoil_mod_1 = require('./services/alertgasoil/alertgasoil-mod-1.jpg');
const service_alertgasoil_mod_2 = require('./services/alertgasoil/alertgasoil-mod-2.jpg');
const service_alertgasoil_mod_3 = require('./services/alertgasoil/alertgasoil-mod-3.png');
const service_alertgasoil_logo = require('./services/alertgasoil/alertgasoil-logo.png');
const service_alertgasoil_what = require('./services/alertgasoil/alertgasoil-what.png');
const service_alertgasoil_why = require('./services/alertgasoil/alertgasoil-why.jpg');

/* ---------------------------------- PRO FLEET ---------------------------------- */

const service_profleet_1 = require('./services/profleet/profleet-1.png');
const service_profleet_2 = require('./services/profleet/profleet-2.png');
const service_profleet_3 = require('./services/profleet/profleet-3.png');
const service_profleet_4 = require('./services/profleet/profleet-4.png');
const service_profleet_5 = require('./services/profleet/profleet-5.png');
const service_profleet_banner = require('./services/profleet/profleet-banner.jpg');
const service_profleet_mod_1 = require('./services/profleet/profleet-mod-1.jpg');
const service_profleet_mod_2 = require('./services/profleet/profleet-mod-2.jpg');
const service_profleet_logo = require('./services/profleet/profleet-logo.jpg');
const service_profleet_what = require('./services/profleet/profleet-what.png');
const service_profleet_why = require('./services/profleet/profleet-why.jpg');

/* ---------------------------------- ICON ---------------------------------- */

const icon_more_white = require('./icon/moreWhite.svg');
const icon_close_red = require('./icon/close_iconRed.svg');
const icon_chevron = require('./icon/chevron.svg');
const icon_save = require('./icon/save.svg');
const icon_view = require('./pass/view.svg');
const icon_hide = require('./pass/hide.svg');

/* ------------------------------ ILLUSTRATION ------------------------------ */

const illu_404 = require('./illu/404-illustration.svg');
const illu_check = require('./illu/check.svg');
const illu_warning = require('./illu/warning.svg');
const illu_tools = require('./illu/tools.svg');
const illu_error = require('./illu/error.svg');
const illu_clock = require('./illu/clock.svg');
const illu_send = require('./illu/send.svg');
const illu_onboard1 = require('./illu/onboarding-1.svg');
const illu_onboard2 = require('./illu/onboarding-2.svg');
const illu_onboard3 = require('./illu/onboarding-3.svg');
const illu_onboard4 = require('./illu/onboarding-4.svg');
const illu_onboard5 = require('./illu/onboarding-5.svg');
const illu_onboard_card = require('./illu/onboarding-card.svg');

/* ------------------------------ EMAIL ------------------------------ */

const mail_logo = require('./email/logo.png');
const mail_check = require('./email/check.png');

/* ------------------------------ PDF ------------------------------ */


const global = {
  logo: global_logo,
  logoBlue: global_logoBlue,
  gb: global_bg,
  repair_approval: service_repair_approval,
  payment_guarantee: service_payment_guarantee,
  priority90: service_priority90,
  service24: service_24,
  smartMaintenance: smart_maintenance,
  service_image_1,
  service_image_2,
  service_image_3,
  fleetCardBack: cardBack,
  fleetCardFront: cardFront,
  workshopMarker: workshop_marker,
  workshopMarkerActive: workshop_marker_active,
  workshopImage: workshop_image,
  totalLogo: logo_total,
};

const service = {
  smart_1: service_smart_1,
  smart_2: service_smart_2,
  smart_3: service_smart_3,
  smart_banner: service_smart_banner,
  smart_mod_1: service_smart_mod_1,
  smart_mod_2: service_smart_mod_2,
  smart_mod_3: service_smart_mod_3,
  smart_logo: service_smart_logo,
  smart_what: service_smart_what,
  smart_why: service_smart_why,
  alertgasoil_banner: service_alertgasoil_banner,
  alertgasoil_mod_1: service_alertgasoil_mod_1,
  alertgasoil_mod_2: service_alertgasoil_mod_2,
  alertgasoil_logo: service_alertgasoil_logo,
  alertgasoil_why: service_alertgasoil_why,
  alertgasoil_1: service_alertgasoil_1,
  alertgasoil_2: service_alertgasoil_2,
  alertgasoil_3: service_alertgasoil_3,
  alertgasoil_mod_3: service_alertgasoil_mod_3,
  alertgasoil_what: service_alertgasoil_what,
  profleet_banner: service_profleet_banner,
  profleet_mod_1: service_profleet_mod_1,
  profleet_mod_2: service_profleet_mod_2,
  profleet_logo: service_profleet_logo,
  profleet_why: service_profleet_why,
  profleet_what: service_profleet_what,
  profleet_1: service_profleet_1,
  profleet_2: service_profleet_2,
  profleet_3: service_profleet_3,
  profleet_4: service_profleet_4,
  profleet_5: service_profleet_5,
};

const icon = {
  more_white: icon_more_white,
  close_red: icon_close_red,
  chevron: icon_chevron,
  save: icon_save,
  view: icon_view,
  hide: icon_hide,
};

const illu = {
  check: illu_check,
  err404: illu_404,
  tools: illu_tools,
  error: illu_error,
  clock: illu_clock,
  warning: illu_warning,
  onboard1: illu_onboard1,
  onboard2: illu_onboard2,
  onboard3: illu_onboard3,
  onboard4: illu_onboard4,
  onboard5: illu_onboard5,
  onboardCard: illu_onboard_card,
  send: illu_send,
};

const mail = {
  mailLogo: mail_logo,
  mailCheck: mail_check,
};

export default {
  icon,
  global,
  sample,
  illu,
  mail,
  service,
};
