import { API_URL } from '../Utile';
import { CallGet, CallPost } from '../Helpers';

export const getStats = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = {};

  const url = `${API_URL}utile/getStats/`;

  const res = await CallGet(url, params, headers);

  return res;
};


export const MarkdownToPdf = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}utile/markdownToPdf`;

  const res = await CallPost(url, params, headers);

  return res;
};
