import React from 'react';
import styled from 'styled-components';
import Grid__separatorLine from 'components/grid/separatorLine/grid__separatorLine';


const Container = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.line ? '25px' : props.margin ? props.margin : '35px')};
`;

const MainTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  text-align: left;
`;

const Icon = styled.span`
  font-size: 16px;
  margin-right: 10px;
  color: #333333;
  position: relative;
  top: -1px;
`;

const Line = styled(Grid__separatorLine)`
  margin-top: 15px;
`;


const Content__sectionTitle = ({
  style, title, icon, line, margin,
}) => (
  <Container style={style} margin={margin}>
    <MainTitle type="h2">
      <Icon>{icon}</Icon>
      {title}
    </MainTitle>
    {line ? (
      <Line />
    ) : null}
  </Container>
);

export default Content__sectionTitle;
