import { SERVICENAME } from 'util/service/serviceName';
import { strapiTpmsService } from 'util/strapi/content.starpi';
import t from 'theme';
import { faTirePressureWarning } from '@fortawesome/pro-regular-svg-icons';
import {
  faTirePressureWarning as faTirePressureWarningSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { ROUTING } from 'util/constant/routing.constant';
import {
  addFleetServicesFromWorkshop, mailHaveService, addFleetServices, fleetHaveService, GetSingleServiceByUrl, registerWorkshopToLeadGeneration, UpdatePublicStatus, GetSingleService,
} from '../../../helpers/api/routes/tpms';

const mailStatus = {
  DONE: 'sent-to-service',
  WAITING: 'sent-to-fleet',
  ERROR: 'refused-by-fleet',
};
export const config = {
  serviceName: SERVICENAME.tpms,
  apiName: SERVICENAME.tpms,
  strapiPage: strapiTpmsService,
  dashboardPath: ROUTING.workshop.dashboardTpms.path,
  constant: {
    origin: {
      fleet: 'Fleet',
      workshop: 'Workshop',
    },
    mailStatus,
    mailContent: {
      strapiGroup: 'service_status',
      done: 'done',
      waiting: 'waiting',
      error: 'error',
    },
    mailStatusProps: {
      [mailStatus.DONE]: 'done',
      [mailStatus.WAITING]: 'waiting',
      [mailStatus.ERROR]: 'error',
    },
    salesStatus: {
      waiting: 'Ongoing',
      done: 'contracted',
    },
    salesStatusConstant: {
      ingoing: 'ingoing',
      stop: 'stop',
      cancel: 'cancel',
      done: 'done',
    },
  },
  api: {
    addFormWorkshop: async ({
      userId, fleetCompanyName, fleetContact, fleetEmail, fleetPhoneNumber,
    }) => addFleetServicesFromWorkshop({
      userId,
      origin: 'workshop',
      source: 'workshop',
      status: 'sent-to-fleet',
      fleetCompanyName,
      fleetContact,
      fleetEmail,
      fleetPhoneNumber,
    }),
    addFleetService: async ({
      userId, fleetCompanyName, fleetContact, fleetEmail, fleetPhoneNumber,
    }) => addFleetServices({
      userId,
      origin: 'fleet',
      source: 'fleet',
      status: 'sent-to-service',
      fleetCompanyName,
      fleetContact,
      fleetEmail,
      fleetPhoneNumber,
    }),
    checkMail: async (email) => mailHaveService({
      email,
    }),
    fleetHaveService: async () => fleetHaveService(),
    registerWorkshop: async () => registerWorkshopToLeadGeneration(),
    getSingleService: async (id) => GetSingleServiceByUrl({ url: id }),
    getSingleServiceById: async (id) => GetSingleService({ id }),
    update: async ({
      id, status, accepted, url,
    }) => UpdatePublicStatus({
      id,
      status,
      accepted,
      url,
    }),
  },
  check: {
    haveServicesWorkshop: (user) => {
      if (user.user.UserWorkshop.tpmsSubscription === true || user.user.UserWorkshop.tpmsSubscription === 'true') {
        return true;
      }
      return false;
    },
  },
  content: {
    service_title: 'service_title',
    service_description: 'service_description',
    service_description_workshop: 'service_description_workshop',
    single_service_title: 'single_service_title',
    single_service_label: 'single_service_label',
    single_service_mail_status: 'single_service_mail_status',
    single_service_origin: 'single_service_origin',
    dashboard_action_title: 'dashboard_action_title',
    dashboard_action_content: 'dashboard_action_content',
    dashboard_action_button: 'dashboard_action_button',
    dashboard_counter_label: 'dashboard_counter_label',
    dashboard_discover_label: 'dashboard_discover_label',
    dashboard_action_company: 'dashboard_action_company',
    dashboard_action_person: 'dashboard_action_person',
    dashboard_action_email: 'dashboard_action_email',
    dashboard_action_phone: 'dashboard_action_phone',
    dashboard_action_error: 'dashboard_action_error',
    service_description_short: 'service_description_short',
    service_description_short_workshop: 'service_description_short_workshop',
    new_label: 'new_label',
    popup_form_success: {
      group: 'popup_form_success',
      title: 'title',
      content: 'content',
      button: 'button',
    },
    workshop_subscription_success: {
      group: 'workshop_subscription_success',
      title: 'title',
      content: 'content',
      button: 'button',
    },
    fleet_subscription_success: {
      group: 'fleet_subscription_success',
      title: 'title',
      content: 'content',
      button: 'button',
    },
    lead_popup_refuse: {
      group: 'lead_popup_refuse',
      title: 'title',
      content: 'content',
      button: 'button',
    },
    lead_popup_accept: {
      group: 'lead_popup_accept',
      title: 'title',
      content: 'content',
      button: 'button',
    },
  },
  details: {
    logo: t.image.service.smart_logo,
    banner: t.image.service.smart_banner,
    icon: faTirePressureWarning,
    iconSolid: faTirePressureWarningSolid,
    blue_card_fleet_title: 'blue_card_fleet_title',
    blue_card_fleet_content: 'blue_card_fleet_content',
    blue_card_fleet_button: 'blue_card_fleet_button',
    blue_card_fleet_already_sent: 'blue_card_fleet_already_sent',
    blue_card_workshop_title: 'blue_card_workshop_title',
    blue_card_workshop_content: 'blue_card_workshop_content',
    blue_card_workshop_button: 'blue_card_workshop_button',
    service_presentation: {
      group: 'service_presentation',
      image: {
        image0: t.image.service.smart_what,
        image1: t.image.service.smart_what,
        image2: t.image.service.smart_1,
        image3: t.image.service.smart_2,
        image4: t.image.service.smart_3,
        image5: t.image.service.smart_mod_1,
        image6: t.image.service.smart_mod_2,
        image7: t.image.service.smart_mod_3,
      },
      section_1_title: 'section_1_title',
      section_1_content: 'section_1_content',
      section_2_title: 'section_2_title',
      section_2_content: 'section_2_content',
      section_3_1_title: 'section_3_1_title',
      section_3_1_content: 'section_3_1_content',
      section_3_2_title: 'section_3_2_title',
      section_3_2_content: 'section_3_2_content',
      section_3_3_title: 'section_3_3_title',
      section_3_3_content: 'section_3_3_content',
      section_3_4_title: 'section_3_4_title',
      section_3_4_content: 'section_3_4_content',
      section_3_5_title: 'section_3_5_title',
      section_3_5_content: 'section_3_5_content',
      section_4_title: 'section_4_title',
      section_4_content: 'section_4_content',
      section_5_1_title: 'section_5_1_title',
      section_5_1_content: 'section_5_1_content',
      section_5_2_title: 'section_5_2_title',
      section_5_2_content: 'section_5_2_content',
      section_5_3_title: 'section_5_3_title',
      section_5_3_content: 'section_5_3_content',
    },
    service_pricing: {
      group: 'service_pricing',
      title: 'title',
      description: 'description',
      label_1: 'label_1',
      label_2: 'label_2',
      column_1_1: 'column_1_1',
      column_2_1: 'column_2_1',
      column_1_2: 'column_1_2',
      column_2_2: 'column_2_2',
      column_1_3: 'column_1_3',
      column_1_4: 'column_1_4',
      column_2_3: 'column_2_3',
      column_1_5: 'column_1_5',
      column_1_6: 'column_1_6',
      column_2_4: 'column_2_4',
    },
  },
};
