import React from 'react';
import styled from 'styled-components';

import { Grid__separator, Grid__separatorLine, Grid__popup } from 'components/grid/grid';

import Content__serviceConditions from 'components/content/serviceConditions/content__serviceConditions';

import {
  SingleService__banner as Banner,
  SingleService__intro as Intro,
  SingleService__description,
} from 'components/singleService/singleService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div``;

const Description = styled(SingleService__description)`
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const Popup = styled(Grid__popup)`
  padding-bottom: 0px;
`;

const ServiceDashboard__infoPop = ({
  style,
  closeEvent,
  active,
  children,
  infoPopProps,
}) => (
  <Container style={style}>
    <Popup active={active} closeEvent={closeEvent}>
      <Banner imageUrl={infoPopProps.intro.banner} />
      <Intro
        title={infoPopProps.intro.title}
        txt={infoPopProps.intro.txt}
        price={infoPopProps.intro.price}
        label={infoPopProps.intro.label}
      />
      <Description>
        <Content__serviceConditions
          content={infoPopProps.condition1.content}
          title={infoPopProps.condition1.title}
          icon={<FontAwesomeIcon icon={infoPopProps.condition1.icon} />}
        />
        <Grid__separator size="50px" />
        <Grid__separatorLine />
        <Grid__separator size="50px" />
        <Content__serviceConditions
          content={infoPopProps.condition2.content}
          title={infoPopProps.condition2.title}
          icon={<FontAwesomeIcon icon={infoPopProps.condition2.icon} />}
        />
        <Grid__separator size="50px" />
        <Grid__separatorLine />
        <Grid__separator size="50px" />
        <Content__serviceConditions
          content={infoPopProps.condition3.content}
          title={infoPopProps.condition3.title}
          icon={<FontAwesomeIcon icon={infoPopProps.condition3.icon} />}
        />
        <Grid__separator size="50px" />
        <Grid__separatorLine />
        <Grid__separator size="50px" />
        <Content__serviceConditions
          content={infoPopProps.condition4.content}
          title={infoPopProps.condition4.title}
          icon={<FontAwesomeIcon icon={infoPopProps.condition4.icon} />}
        />
      </Description>
      {children}
    </Popup>
  </Container>
);

export default ServiceDashboard__infoPop;
