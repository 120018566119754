import React, {
  useContext, useState, useEffect, Fragment,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import { Grid__separator, Grid__popup } from 'components/grid/grid';
import { Cards__vehicle } from 'components/cards/cards';
import { Content__sectionTitle, Content__servicesTag } from 'components/content/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content__tag from 'components/content/tag/content__tag';
import { List__table, List__content } from 'components/list/list';
import { ContentContext } from 'context/contentContext';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { sequelizeDate } from 'util/date.util';
import { sequelizeMileage } from 'util/mileage.util';
import { ROUTING } from 'util/constant/routing.constant';
import {
  faCreditCard, faMoneyBillWave, faUniversity, faGasPump,
} from '@fortawesome/pro-light-svg-icons';
import {
  shouldRenderRepairApproval,
  souldRenderPaymentguarantee,
} from 'util/service/createHistoric';
import {
  strapiRepairApprovalService,
  strapiPriority90Service,
  strapiPaymentGuaranteeService,
  strapiWorkshopSingleVehicle,
  strapiRegister,
} from 'util/strapi/content.starpi';
import { AppContext } from 'context/appContext';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import {
  faWarehouse, faHouse, faListUl, faHandshake, faMoneyCheck,
} from '@fortawesome/pro-solid-svg-icons';
import Content__labelText from 'components/content/labelText/content__labelText';
import {
  faHandshakeAlt, faShieldCheck,
} from '@fortawesome/pro-regular-svg-icons';
import {
  getHomeBaseKeyFromVehicle, getFleetUserKeyFromVehicle, isVehicleHomeBase, isVehicleApprover,
} from 'util/workshop/getVehicleObjectInformation';
import { useData } from 'components/data/getter/data__getter';
import { Form__textfield } from 'components/form/form';
import Button__primary from 'components/button/primary/button__primary';
import Message__sucess from 'components/message/sucess/message__sucess';
import { SendMail } from 'helpers/api/routes/Mail';
import { useHistory } from 'react-router-dom';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { FleetContext } from 'context/fleetUser/fleetContext';
import { FLEET_PAYMENT, parsePaymentMethods, FLEET_EXTRA_PAYMENT } from 'util/constant/fleetPayment';
import Cards__iconCard from 'components/cards/iconCard/cards__iconCard';
import { asOpenedCase, CreateCase } from '../../../../helpers/api/routes/Cases';
import { renderCasesIcon } from '../../../../util/Case';


const Container = styled.div``;

const Header = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'row' })}
`;

const Title = styled.div`
  font-weight: normal;
  font-size: 36px;
  line-height: 49px;
  color: #4d4d4d;
  margin-right: 20px;
`;

const ServiceStatusTitle = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
`;

const ContainerCenter = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', direction: 'column' })}
`;

const Column = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', direction: 'column' })}
`;

const Row = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-start', direction: 'row' })}
`;

const Table = styled(List__table)`
  width: 100%;
  max-height: 360px;
`;

const FormSection = styled.div`
  width: 60%;
`;

const PaymentCard__info = styled.div`
  margin-top: 15px;
`;

const VehicleSection = styled.div`
  width: 40%;
  ${t.mixins.flex({
  justify: 'center',
  align: 'center',
})};
`;


const ErrorText = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  color: #E74C3C;
`;

const FormContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 45px;
  border-radius: 10px;
  background: #E6F8FC;
  text-align: left;
  ${t.mixins.flex({
  justify: 'space-between',
  align: 'flex-start',
})};
`;


let pageNumber = 1;
const CheckVehicle__singlePop = ({
  style, vehicle, active, closePop, baseCase,
}) => {
  const {
    getField, getGlobal, getFieldGroup,
  } = useContext(ContentContext);
  const { UpdateVehicule } = useContext(FleetContext);
  const { user } = useContext(AppContext);
  const [loading] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);
  const [reqId, setreqId] = useState(false);
  const [canLaunchCase, setCanlaunchCase] = useState(true);
  const [vehicleMileage, setvehicleMileage] = useState(null);
  const [customerDescription, setCustomerDescription] = useState(null);
  const [errorCustomerDescription, setErrorCustomerDescription] = useState(false);
  const [loadingaction, setloadingaction] = useState(false);
  const [errorMileage, seterrorMileage] = useState(false);
  const history = useHistory();

  // eslint-disable-next-line consistent-return
  const getCurrentPaymentMethods = (key, secondary) => {
    if (vehicle && vehicle.UserFleet) {
      const payment = parsePaymentMethods(vehicle.UserFleet.paymentMethod, vehicle.UserFleet.paymentMethodDetail);
      if (key) {
        if (secondary) {
          return payment.secondary[key];
        }

        return payment.main[key];
      }
    }
  };

  const fuelCards = [
    { label: 'DKV', slug: FLEET_EXTRA_PAYMENT.DKV, active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.DKV, true) },
    { label: 'UTA', slug: FLEET_EXTRA_PAYMENT.UTA, active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.UTA, true) },
    { label: 'Total', slug: FLEET_EXTRA_PAYMENT.TOTAL, active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.TOTAL, true) },
    { label: 'Shell', slug: FLEET_EXTRA_PAYMENT.SHELL, active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.SHELL, true) },
    { label: 'BP', slug: FLEET_EXTRA_PAYMENT.BP, active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.BP, true) },
    { label: getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'other_fuel_card' }), slug: FLEET_EXTRA_PAYMENT.OTHER, active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.OTHER, true) },
  ];

  const getActiveFuelCards = () => {
    const find = fuelCards.find((el) => el.active === true);
    if (find) {
      return ` ${find.label}`;
    }

    return ':';
  };

  const paymentMethods = [
    {
      slug: FLEET_PAYMENT.CASH, label: getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'method_1_cash' }), icon: faMoneyBillWave, active: getCurrentPaymentMethods(FLEET_PAYMENT.CASH),
    },
    {
      slug: FLEET_PAYMENT.CREDITCARDS, label: getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'method_2_card' }), icon: faCreditCard, active: getCurrentPaymentMethods(FLEET_PAYMENT.CREDITCARDS),
    },
    {
      slug: FLEET_PAYMENT.BANKTRANSFER, label: getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'method_3_transfer' }), icon: faUniversity, active: getCurrentPaymentMethods(FLEET_PAYMENT.BANKTRANSFER),
    },
    {
      slug: FLEET_PAYMENT.FUELCARD, label: `${getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'method_4_fuel' })} ${getActiveFuelCards()}`, icon: faGasPump, active: getCurrentPaymentMethods(FLEET_PAYMENT.FUELCARD),
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [historic, refreshData, setPage, setSearch] = useData({
    params: {
      fleetId: null,
    },
    service: 'cases',
    type: 'cases',
    removeFirstLoading: true,
  });
  const [popActive, setpopActive] = useState(active);

  useEffect(() => {
    setTimeout(() => { setpopActive(active); }, 10);
    if (active) {
      pageNumber = 1;
      setTimeout(() => { setpopActive(active); }, 10);
      refreshData({
        vehicle: vehicle.id,
        status: 'closed',
      });
    }
  }, [active]);


  const canOpenCase = async () => {
    if (vehicle) {
      const check = await asOpenedCase(vehicle.id);
      const formated = formatApiRes(check);
      if (formated.success && formated.data.result) {
        setCanlaunchCase(false);
      } else {
        setCanlaunchCase(true);
      }
    }
  };

  useEffect(() => {
    canOpenCase();
  }, [vehicle]);

  const headerCol = [
    { label: getFieldGroup({ base: baseCase, groupe: 'case_parameters', field: 'array_id' }) },
    { label: getFieldGroup({ base: baseCase, groupe: 'case_parameters', field: 'array_vehicle' }) },
    { label: getFieldGroup({ base: baseCase, groupe: 'case_parameters', field: 'array_open_date' }) },
    { label: getFieldGroup({ base: baseCase, groupe: 'case_parameters', field: 'array_services' }) },
    { label: getFieldGroup({ base: baseCase, groupe: 'case_parameters', field: 'array_case_status' }) },
  ];

  if (vehicle === false || active === false) {
    return null;
  }

  const renderList = () => {
    const orderArray = historic.data;
    const arrayOfLine = [];
    if (orderArray) {
      orderArray.forEach((element) => {
        arrayOfLine.push([
          {
            component: (<List__content uppercase>{element.uid}</List__content>),
            type: 'node',
          },
          {
            component: (<List__content uppercase>{element.Fleet.registrationNumber}</List__content>),
            type: 'node',
          },
          {
            component: (<List__content>{sequelizeDate({ date: element.caseOpeningDate, format: 'd/m/y' })}</List__content>),
            type: 'node',
          },
          {
            component: (
              <List__content>
                <Content__servicesTag
                  icons={renderCasesIcon(element)}
                />
              </List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <Content__tag
                content={element.status === 'opened' ? 'Open' : 'Closed'}
                blue={element.status === 'opened'}
                green={element.status === 'closed'}
              />
            ),
            type: 'node',
          },
        ]);
      });
    }

    return arrayOfLine;
  };

  const getVehicleInformation = (key) => {
    if (vehicle) {
      if (vehicle.UserFleet) {
        if (vehicle.UserFleet.UserFleetAlias) {
          if (vehicle.UserFleet.UserFleetAlias[key]) {
            return vehicle.UserFleet.UserFleetAlias[key];
          }
        }
      }
    }
    return '';
  };


  const handleLaunch = async () => {
    setloadingaction(true);
    const res = await CreateCase({
      userFleetId: vehicle.UserFleet.id,
      userWorkshopId: user.user.UserWorkshop.id,
      fleetId: vehicle.id,
      description: customerDescription,
      textLang: user.user.lang,
      mileage: vehicleMileage,
    });
    const formated = formatApiRes(res);
    if (res) {
      setloadingaction(false);
      if (formated.success && formated.data && formated.data.id) {
        closePop();
        history.push(`${ROUTING.workshop.cases.path}/${formated.data.id}`);
        // setMsgSuccess(true);
        // setreqId(res.data.data.id);
        // if (!getVehicleInformation('notificationDisable')) {
        //   SendMail({
        //     template: 'notification',
        //     templateNotification: {
        //       status: 'waiting',
        //       statusLabel: getFieldGroup({
        //         select_lang: getVehicleInformation('lang'), base: strapiPriority90Service, groupe: 'mail_start', field: 'status',
        //       }),
        //       content: `${getFieldGroup({
        //         select_lang: getVehicleInformation('lang'), base: strapiPriority90Service, groupe: 'mail_start', field: 'content',
        //       })} ${user.user.companyName}`,
        //       btnContent: getFieldGroup({
        //         select_lang: getVehicleInformation('lang'), base: strapiPriority90Service, groupe: 'mail_start', field: 'btn',
        //       }),
        //       btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.fleet.dashboardPriority90.path}`,
        //     },
        //     subject: getFieldGroup({
        //       select_lang: getVehicleInformation('lang'), base: strapiPriority90Service, groupe: 'mail_start', field: 'subject',
        //     }),
        //     destination: getVehicleInformation('email'),
        //   });
        // }
      }
    }
  };

  const launchCase = async (mileage) => {
    if (mileage) {
      const params = {
        fleetId: vehicle.id,
        mileage,
      };

      await UpdateVehicule({ fleetObj: params });
    }
    handleLaunch();
  };


  const handleLaunchRegister = () => {
    let canLaunch = true;
    let _mileage = false;
    setErrorCustomerDescription(false);
    seterrorMileage(false);
    if (vehicleMileage && parseInt(vehicleMileage, 10) && vehicle.mileage) {
      if (vehicle.mileage && parseInt(vehicleMileage, 10) > parseInt(vehicle.mileage, 10)) {
        _mileage = parseInt(vehicleMileage, 10);
      } else {
        canLaunch = false;
        seterrorMileage(true);
      }
    }

    if (!customerDescription || customerDescription === '') {
      canLaunch = false;
      setErrorCustomerDescription(true);
    }

    if (canLaunch) {
      launchCase(_mileage);
    }
  };

  const handleSuccesMessage = () => {
    const url = `${ROUTING.workshop.dashboardPriority90.path}?id=${reqId}`;
    history.push(url);
  };

  const handleLine = (line) => {
    if (historic.data) {
      const _linedata = historic.data[line.key];
      if (_linedata && _linedata.id) {
        history.push(`${ROUTING.workshop.cases.path}/${_linedata.id}`);
      }
    }
  };

  const handleLoadNewPage = () => {
    if (historic.data && !historic.loading && pageNumber + 1 > 1 && historic.data.length < historic.count) {
      pageNumber += 1;
      setPage(pageNumber, historic.data, {
        fleetId: vehicle.id,
      });
    }
  };


  const handleMileage = (e) => {
    setvehicleMileage(e.value);
  };

  const handleCustomerDescription = (e) => {
    setCustomerDescription(e.value);
  };

  const nextServicesData = () => {
    const array = [];

    if (souldRenderPaymentguarantee(vehicle) && !isVehicleHomeBase(vehicle, user)) {
      array.push({
        title: getField({ base: strapiPaymentGuaranteeService, field: 'service_title' }),
        icon: faShieldCheck,
        link: !isVehicleHomeBase(vehicle, user) && (`${ROUTING.workshop.dashboardPaymentGuarantee.path}?vehicle=${vehicle.registrationNumber}`),
      });
    }

    if (shouldRenderRepairApproval(vehicle) && !isVehicleApprover(vehicle, user)) {
      array.push({
        title: getField({ base: strapiRepairApprovalService, field: 'service_title' }),
        icon: faHandshakeAlt,
        link: !isVehicleApprover(vehicle, user) && (`${ROUTING.workshop.dashboardRepairApproval.path}?vehicle=${vehicle.registrationNumber}`),
      });
    }

    return array;
  };

  return (
    <Container style={style}>
      <Grid__popup active={popActive} flex closeEvent={closePop}>
        {!msgSuccess ? (
          <Fragment>

            {/*
              header
            */}

            <Header>
              <Title>{getField({ base: strapiWorkshopSingleVehicle, field: 'title' })}</Title>
              <Content__bigTag label={getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' })} value={vehicle.registrationNumber} />
              <Grid__separator width="15px" />
              <Content__bigTag label={getGlobal({ option: 'vehicleParameter', field: 'vincode' })} value={vehicle.vin} />
            </Header>

            <Grid__separator size="80px" />

            {/*
              launch register vehicle
            */}

            <ContainerCenter>
              <FormContainer>
                <FormSection>
                  <ServiceStatusTitle>
                    {getField({ base: strapiWorkshopSingleVehicle, field: 'register_title' })}
                  </ServiceStatusTitle>
                  <Grid__separator size="30px" />
                  <Form__textfield
                    type="number"
                    label={getField({ base: strapiWorkshopSingleVehicle, field: 'register_mileage_label' })}
                    contrastBorder
                    property="mileage"
                    value={vehicleMileage}
                    change={handleMileage}
                    error={{ active: errorMileage, message: getField({ base: strapiWorkshopSingleVehicle, field: 'register_mileage_error' }) }}
                    placeholder={vehicle.mileage}
                  />
                  <Grid__separator size="30px" />
                  <Form__textfield
                    type="textarea"
                    label={getField({ base: strapiWorkshopSingleVehicle, field: 'register_customer_request' })}
                    property="description"
                    value={customerDescription}
                    change={handleCustomerDescription}
                    error={{ active: errorCustomerDescription, message: getField({ base: strapiWorkshopSingleVehicle, field: 'register_mileage_error' }) }}
                  />
                  <Grid__separator size="30px" />
                  {
                    !canLaunchCase ? (
                      <Fragment>
                        <ErrorText>{getField({ base: strapiWorkshopSingleVehicle, field: 'register_error_case_open' })}</ErrorText>
                        <Grid__separator size="19px" />
                      </Fragment>
                    ) : null
                  }
                  <Row>
                    <Button__primary event={handleLaunchRegister} disable={!canLaunchCase}>{getField({ base: strapiWorkshopSingleVehicle, field: 'register_mileage_btn' })}</Button__primary>
                  </Row>
                </FormSection>
                <VehicleSection>
                  <Cards__vehicle
                    title={getGlobal({ option: 'workshopInformation', field: 'vehicle' })}
                    noCard
                    data={
                      [
                        {
                          label: getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
                          value: sequelizeDate({ date: vehicle.firstDate, format: 'd/m/y' }),
                        },
                        {
                          label: getGlobal({ option: 'vehicleParameter', field: 'category' }),
                          value: vehicle.type,
                          capitalize: true,
                        },
                        {
                          label: getGlobal({ option: 'vehicleParameter', field: 'mileage' }),
                          value: sequelizeMileage({ value: vehicle.mileage }),
                        },
                        {
                          label: getGlobal({ option: 'vehicleParameter', field: 'brand' }),
                          value: vehicle.brand,
                          capitalize: true,
                        },
                        {
                          label: getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' }),
                          value: getGlobal({ option: 'country', field: vehicle.countryOfRegistration }) || vehicle.countryOfRegistration,
                        },
                      ]
                    }
                  />
                </VehicleSection>
              </FormContainer>

              <Grid__separator size="80px" />

              {/*
                Service available
              */}

              <Content__sectionTitle
                title={getField({ base: strapiWorkshopSingleVehicle, field: 'service_label' })}
                icon={<FontAwesomeIcon icon={faHandshake} />}
              />
              {shouldRenderRepairApproval(vehicle) ? (
                <Cards__iconCard
                  content={getField({ base: strapiRepairApprovalService, field: 'service_title' })}
                  icon={faHandshakeAlt}
                />
              ) : null}
              {souldRenderPaymentguarantee(vehicle) ? (
                <Cards__iconCard
                  content={getField({ base: strapiPaymentGuaranteeService, field: 'service_title' })}
                  icon={faShieldCheck}
                />
              ) : null}

              <Grid__separator size="80px" />

              {/*
                Payment methods
              */}

              <Content__sectionTitle
                title={getField({ base: strapiWorkshopSingleVehicle, field: 'payment_methods_title' })}
                icon={<FontAwesomeIcon icon={faMoneyCheck} />}
              />
              {paymentMethods.map((item) => (
                item.active && (
                  <Cards__iconCard
                    content={item.label}
                    icon={item.icon}
                  />
                )
              ))}
              <PaymentCard__info>{getField({ base: strapiWorkshopSingleVehicle, field: 'payment_methods_description' })}</PaymentCard__info>

              <Grid__separator size="80px" />

              {/*
                Fleet information
              */}

              <Content__sectionTitle
                title={getField({ base: strapiWorkshopSingleVehicle, field: 'fleet_label' })}
                icon={<FontAwesomeIcon icon={faHouse} />}
              />
              <Row>
                <Column>
                  <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'name' })} content={getFleetUserKeyFromVehicle(vehicle, 'companyName')} />
                  <Grid__separator size="25px" />
                  <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'zipcode' })} content={getFleetUserKeyFromVehicle(vehicle, 'zipcode')} />
                  <Grid__separator size="25px" />
                  <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'fleet_contact' })} content={`${getFleetUserKeyFromVehicle(vehicle, 'lastName')} ${getFleetUserKeyFromVehicle(vehicle, 'firstName')}`} />
                </Column>
                <Column>
                  <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'vat_number' })} content={getFleetUserKeyFromVehicle(vehicle, 'vat')} />
                  <Grid__separator size="25px" />
                  <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'city' })} content={getFleetUserKeyFromVehicle(vehicle, 'city')} />
                  <Grid__separator size="25px" />
                  <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'email' })} content={getFleetUserKeyFromVehicle(vehicle, 'email')} />
                </Column>
                <Column>
                  <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'country' })} content={getFleetUserKeyFromVehicle(vehicle, 'region')} capitalize />
                  <Grid__separator size="25px" />
                  <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'address' })} content={getFleetUserKeyFromVehicle(vehicle, 'adress')} />
                  <Grid__separator size="25px" />
                  <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })} content={getFleetUserKeyFromVehicle(vehicle, 'phoneNumber')} />
                </Column>
              </Row>

              <Grid__separator size="80px" />

              {/*
                Home based workshop
              */}

              <Content__sectionTitle
                title={getField({ base: strapiWorkshopSingleVehicle, field: 'homebase_label' })}
                icon={<FontAwesomeIcon icon={faWarehouse} />}
              />
              { getHomeBaseKeyFromVehicle(vehicle, 'companyName') ? (
                <Row>
                  <Column>
                    <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'name' })} content={getHomeBaseKeyFromVehicle(vehicle, 'companyName')} />
                    <Grid__separator size="25px" />
                    <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'zipcode' })} content={getHomeBaseKeyFromVehicle(vehicle, 'zipcode')} />
                    <Grid__separator size="25px" />
                    <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'workshop_contact' })} content={`${getHomeBaseKeyFromVehicle(vehicle, 'lastName')} ${getHomeBaseKeyFromVehicle(vehicle, 'firstName')}`} />
                  </Column>
                  <Column>
                    <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'vat_number' })} content={getHomeBaseKeyFromVehicle(vehicle, 'vat')} />
                    <Grid__separator size="25px" />
                    <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'city' })} content={getHomeBaseKeyFromVehicle(vehicle, 'city')} />
                    <Grid__separator size="25px" />
                    <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'email' })} content={getHomeBaseKeyFromVehicle(vehicle, 'email')} />
                  </Column>
                  <Column>
                    <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'country' })} content={getHomeBaseKeyFromVehicle(vehicle, 'region')} capitalize />
                    <Grid__separator size="25px" />
                    <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'address' })} content={getHomeBaseKeyFromVehicle(vehicle, 'adress')} />
                    <Grid__separator size="25px" />
                    <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })} content={getHomeBaseKeyFromVehicle(vehicle, 'phoneNumber')} />
                  </Column>
                </Row>
              ) : (
                <Row>
                  <span>
                    {getField({ base: strapiWorkshopSingleVehicle, field: 'info_fleet_no_homebase' })}
                  </span>
                </Row>
              )}

              <Grid__separator size="80px" />

              {/*
                Service history
              */}

              <Content__sectionTitle
                title={getField({ base: strapiWorkshopSingleVehicle, field: 'history_label' })}
                icon={<FontAwesomeIcon icon={faListUl} />}
              />
              <Table
                headerCol={headerCol}
                lineCol={renderList()}
                noSelect
                lineEvent={handleLine}
                maxHeight="360px"
                scrollEndEvent={handleLoadNewPage}
              />
              {
                (historic && historic.loading) && (
                  <Loader__wrapper />
                )
              }
            </ContainerCenter>
            {loading ? <Loader__wrapper /> : false }
            {loadingaction ? <Loader__wrapper /> : false }
          </Fragment>
        ) : (
          <Message__sucess
            icon={t.image.illu.check}
            title={getField({ base: strapiPriority90Service, field: 'success_popup_title' })}
            text={getField({ base: strapiPriority90Service, field: 'success_popup_text' })}
            buttonText={getField({ base: strapiPriority90Service, field: 'success_popup_button' })}
            buttonEvent={handleSuccesMessage}
            nextServices={nextServicesData()}
          />
        )}
      </Grid__popup>
    </Container>
  );
};

export default CheckVehicle__singlePop;
