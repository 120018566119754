/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

const Value = styled.div`
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #4d4d4d;
`;

const Label = styled.div`
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #808080;
  margin-top: 2px;
`;

const Content__smallCounter = ({ style, value, label }) => (
  <Container style={style}>
    <Value>{value}</Value>
    <Label>{label}</Label>
  </Container>
);

export default Content__smallCounter;
