/* eslint-disable react/require-default-props */

import React, {
  useContext, useEffect, useState,
} from 'react';
import Nav from 'components/global/nav';
import { ContentContext } from 'context/contentContext';

import Layout__container from 'containers/layout/container/layout__container';
import { Transition__loader_controller } from 'components/transition/transition';
import {
  strapiStandartrepairService,
  strapiSingleServicePage,
} from 'util/strapi/content.starpi';
import ServiceDashboard__layout from 'containers/workshopView/serviceDashboard/layout/serviceDashboard__layout';
import { ROUTING } from 'util/constant/routing.constant';
import { getAction } from 'util/function/reloadAction.function';
import { useData } from 'components/data/getter/data__getter';
import ServicesDetails from 'components/services/LeadGeneration/ServiceDetails';
import LeadGenerationWorkshopAction from './Action';
import LeadGenerationWorkshopHistoric from './Historic';
import LeadGenerationWorkshopSingle from './Single';

const LeadGenerationWorkshopDasboard = ({
  config,
}) => {
  const {
    loadPage, haveContentFor, getField, getGlobal,
  } = useContext(ContentContext);
  const [activeinfoPop, setactiveinfoPop] = useState(false);
  const [activesinglePop, setactivesinglePop] = useState(false);
  let pageNumber = 1;
  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage] = useData({
    params: {},
    service: config.apiName,
    type: 'service',
  });


  const handleCheckParams = () => {
    const actionParams = getAction({ key: 'action' });
    if (actionParams.success === true && actionParams.value !== true) {
      setactivesinglePop({ active: true, id: actionParams.value });
    }
  };

  const loadContent = async () => {
    if (haveContentFor(strapiStandartrepairService).success === false) {
      await loadPage(strapiStandartrepairService);
    }
    if (haveContentFor(strapiSingleServicePage).success === false) {
      await loadPage(strapiSingleServicePage);
    }
    if (haveContentFor(config.strapiPage).success === false) {
      await loadPage(config.strapiPage);
    }

    handleCheckParams();
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (
    haveContentFor(strapiStandartrepairService).success === false
    || haveContentFor(strapiSingleServicePage).success === false
    || haveContentFor(config.strapiPage).success === false
  ) {
    return <Transition__loader_controller active />;
  }

  const handleCloseInfoPop = () => {
    setactiveinfoPop(false);
  };

  const handleOpenInfoPop = () => {
    setactiveinfoPop(true);
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({ active: false, id: null });
  };

  const handleOpenSinglePop = ({ id }) => {
    setactivesinglePop({ active: true, id });
  };

  const handleTableClick = (line, vehicle) => {
    handleOpenSinglePop({ id: vehicle.id });
  };

  const handleLoadNewPage = () => {
    if (resData.data && !resData.loading && pageNumber + 1 > 1 && resData.data.length < resData.count) {
      pageNumber += 1;
      setPage(pageNumber, resData.data);
    }
  };

  return (
    <Layout__container>
      <Nav workshop active="cases" />
      <Transition__loader_controller active={false} />
      <ServiceDashboard__layout
        small
        page_title={getField({ base: config.strapiPage, field: config.content.service_title })}
        breadCrumb_parent={getGlobal({ option: 'workshop_menu', field: 'my_cases' })}
        breadCrumb_parentLink={ROUTING.workshop.cases.path}
        history={(
          <LeadGenerationWorkshopHistoric
            tableEvent={handleTableClick}
            data={resData ? resData.data : []}
            scrollEndEvent={handleLoadNewPage}
            loadingNewPage={resData.loading}
            config={config}
          />
        )}
        // Action side element
        action={(
          <LeadGenerationWorkshopAction
            infoEvent={handleOpenInfoPop}
            count={resData.count}
            handleRefresh={refreshData}
            config={config}
          />
        )}
        // Info pop up
        activeInfoPop={activeinfoPop}
        closeInfoPop={handleCloseInfoPop}
        // Single pop up
        activeSinglePop={activesinglePop}
        closeSinglePop={handleCloseSinglePop}
        singlePop={(
          <LeadGenerationWorkshopSingle
            active={activesinglePop.active}
            id={activesinglePop.id}
            closeEvent={handleCloseSinglePop}
            config={config}
          />
        )}
      />
      <ServicesDetails
        workshop
        popupActive={activeinfoPop}
        closeEvent={handleCloseInfoPop}
        infoOnly
        config={config}
      />
    </Layout__container>
  );
};

export default LeadGenerationWorkshopDasboard;
