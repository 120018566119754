
/* eslint no-restricted-globals:0 */
export function getParams(param) {
  const vars = {};
  window.location.href.replace(location.hash, '').replace(
    /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
    (m, key, value) => {
      // callback
      vars[key] = value !== undefined ? value : '';
    },
  );

  if (param) {
    return vars[param] ? vars[param] : null;
  }

  return vars;
}

export function getParamsDecode(param) {
  const vars = {};
  const url = decodeURI(window.location.href);
  url.replace(location.hash, '').replace(
    /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
    (m, key, value) => {
      // callback
      vars[key] = value !== undefined ? value : '';
    },
  );

  if (param) {
    return vars[param] ? vars[param] : null;
  }

  return vars;
}


export function insertParam({ key, value }) {
  window.history.pushState({}, '', `?${key}=${value}`);
}
