import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Grid__card } from 'components/grid/grid';
import Button__primary from 'components/button/primary/button__primary';


const Value = styled.div`
  font-weight: normal;
  font-size: 30px;
  text-align: center;
  color: #4D4D4D;
  margin-bottom: 5px;
`;

const Label = styled.div`
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #999999;
  margin-bottom: 25px;
  line-height: 1.3;
`;

const Button = styled(Button__primary)`
  width: 100%;
`;

const Cards__obligo = ({
  style,
  obligoSet,
  value,
  label,
  notSetLabel,
  buttonEvent,
  buttonText,
}) => (
  <Grid__card style={style} padding="25px">
    {obligoSet ? (
      <Fragment>
        <Value>{value}</Value>
        <Label>{label}</Label>
      </Fragment>
    ) : (
      <Label>{notSetLabel}</Label>
    )}
    <Button event={buttonEvent} border blue>{buttonText}</Button>
  </Grid__card>
);

export default Cards__obligo;
