import PhoneDial from 'helpers/data/PhoneDial.json';

export const dialJsonToSelect = () => {
  const obj = [];

  PhoneDial.data.forEach((element, key) => {
    obj.push({
      label: element.dial_code,
      slug: element.dial_code.replace('+', ''),
      active: key === 0,
    });
  });

  return obj;
};
