
/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

const Banner = styled.div`
  background-image: ${({ imageUrl }) => `url('${imageUrl}')`};
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 310px;
  border-radius: 10px;
`;


const SingleService__banner = ({ style, imageUrl }) => (
  <Banner style={style} imageUrl={imageUrl} />
);

export default SingleService__banner;
