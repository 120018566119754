import React, {
  useContext, useEffect, Fragment, useState,
} from 'react';

import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';

import Cards__listCard from 'components/cards/listCard/cards__listCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/pro-regular-svg-icons';

import { sequelizeDate } from 'util/date.util';
import { strapiStandartrepairService, strapiServiceHeader } from 'util/strapi/content.starpi';
import { ROUTING } from 'util/constant/routing.constant';
import { handleLineClick_singleNew } from 'util/function/openSingleService';
import List__content from 'components/list/content/list__content';
import DashboardStandardRepair__singlePop from 'containers/fleetView/dashboardStandardRepair/singlePop/dashboardStandardRepair__singlePop';
import { SERVICENAME } from 'util/service/serviceName';
import { getFormService } from 'util/function/getter';
import { useData } from 'components/data/getter/data__getter';


const MyServices__fairPrice = ({ basePage }) => {
  const {
    loadPage, haveContentFor, getField, getGlobal,
  } = useContext(ContentContext);

  const { user } = useContext(AppContext);
  const [activesinglePop, setactivesinglePop] = useState({
    active: false,
    id: null,
  });
  // eslint-disable-next-line no-unused-vars
  // const [resData, refreshData, setPage, setSearch] = useData({
  //   params: {
  //     id: user.user.UserFleet.id,
  //     user: true,
  //   },
  //   service: SERVICENAME.standardrepair,
  //   type: 'service',
  //   limite: 3,
  // });

  const [resData, setPage] = useData({
    params: {
      userFleetId: user.user.UserFleet.id,
      both_counter: true,
      status: 'closed',
    },
    service: 'cases',
    type: 'cases',
    limite: 3,
    // removeFirstLoading: true,
  });

  useEffect(() => {
    if (haveContentFor(strapiStandartrepairService).success === false) {
      loadPage(strapiStandartrepairService);
    }
  }, []);

  const headerCol = [
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'vehicle_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'date_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'repairprice_header' }),
    },
  ];

  const renderList = () => {
    const orderArray = resData.data;
    const arrayOfLine = [];
    let count = 0;

    if (orderArray) {
      orderArray.forEach((element) => {
        if (count < 3) {
          arrayOfLine.push([
            {
              component: (
                <List__content uppercase>{getFormService(element, 'registrationNumber', 'vehicle')}</List__content>
              ),
              type: 'node',
            },
            {
              component: (
                <List__content>
                  {sequelizeDate({ date: getFormService(element, 'createdAt'), format: 'd/m/y' })}
                </List__content>
              ),
              type: 'node',
            },
            {
              component: <List__content>{`${getFormService(element, 'price')} €`}</List__content>,
              type: 'node',
            },
          ]);
          count += 1;
        }
      });
    }

    return arrayOfLine;
  };

  const handleLine = (line) => {
    handleLineClick_singleNew({
      line,
      array: resData.data,
      callback: ({ active, id }) => setactivesinglePop({
        active,
        id,
      }),
    });
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({
      active: false,
      id: null,
    });
  };

  return (
    <Fragment>
      <Cards__listCard
        countValue={0}
        headerCol={headerCol}
        serviceName="standardrepair"
        line={resData && resData.data ? renderList() : []}
        icon={<FontAwesomeIcon icon={faTools} />}
        title={getField({ base: strapiStandartrepairService, field: 'service_title' })}
        buttonRequest={getField({ base: basePage, field: 'dashboard_button' })}
        buttonRequestHref={`${ROUTING.fleet.dashboardStandardRepair.path}`}
        loading={!haveContentFor(strapiStandartrepairService).success || resData.loading}
        hasLoader
        lineEvent={handleLine}
        fleet
        width="380px"
      />
      <DashboardStandardRepair__singlePop
        active={activesinglePop.active}
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
    </Fragment>
  );
};

export default MyServices__fairPrice;
