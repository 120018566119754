
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { getPercent } from 'util/math.utile';

import Cards_serviceCoverage from 'components/cards/serviceCoverage/cards__serviceCoverage';
import Cards_serviceInfo from 'components/cards/serviceInfo/cards__serviceInfo';
import Cards__launchService from 'components/cards/launchService/cards__launchService';
import { Grid__separator } from 'components/grid/grid';
import { strapiFairPriceService } from 'util/strapi/content.starpi';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';

const Container = styled.div``;

const DashboardFairPrice__action = ({
  style, infoEvent, launchServicePop, coverage, fleet,
}) => {
  const { getGlobal, getField } = useContext(ContentContext);
  return (
    <Container style={style}>
      <Cards__launchService
        title={getGlobal({ option: 'service', field: 'single_action_title' })}
        content={(
          <div>
            {getField({ base: strapiFairPriceService, field: 'action_cards_text1' })}
            <br />
            <br />
            {getField({ base: strapiFairPriceService, field: 'action_cards_text2' })}
          </div>
        )}
        buttonTopContent={getField({ base: strapiFairPriceService, field: 'action_cards_button' })}
        buttonTopEvent={launchServicePop}
        buttonTopIcon={faEnvelope}
      />
      <Grid__separator size="20px" />
      <Cards_serviceCoverage
        paddingSmall
        progress={fleet ? getPercent({ total: fleet, number: coverage }) : 0}
        value={coverage}
        labelProgress={getGlobal({ option: 'service', field: 'single_fleetcoverage_title' })}
        labelCounter={getGlobal({ option: 'service', field: 'single_fleetcoverage_vehiclelabel' })}
      />
      <Grid__separator size="20px" />
      <Cards_serviceInfo title={`${getGlobal({ option: 'service', field: 'single_info_title' })} ${getField({ base: strapiFairPriceService, field: 'service_title' })}`} event={infoEvent} />
      <Grid__separator size="40px" />
    </Container>
  );
};

export default DashboardFairPrice__action;
