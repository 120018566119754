/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

import { Grid__card } from 'components/grid/grid/';
import Button__closeButton from 'components/button/closeButton/button__closeButton';

/**
 * import components
 */

/**
 * styled components
 */

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  user-select: none;
  pointer-events: none;
  transition: 0.3s ease transform;
  transform: translateY(100vh);
  will-change: transform;
  &.active {
    user-select: initial;
    pointer-events: initial;
    transform: translateY(0vh);
    transition: 0.4s ease transform;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
  }
  &::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(0, 0, 0, .85);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #01b5e2 !important;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  z-index: 1;
  transition: all ease .3s;
  &.active {
    opacity: .8;
    transition: all ease .4s;
  }
`;

const ContainerCard = styled.div`
  width: 100vw;
  height: auto;
  position: relative;
  z-index: 2;
  padding-top: 45px;
  padding-bottom: 45px;
  pointer-events: none;
`;

const Card = styled(Grid__card)`
  width: 100%;
  max-width: 1100px;
  height: auto;
  min-height: calc(100vh - 90px);
  padding: 65px;
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  pointer-events: all;


  ${({ flex }) => (flex ? (
    `
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    `
  ) : null)};
`;

const ScrollView = styled.div` 
  width: 100%;
  position: relative;
`;

/**
 * popup component is..
 */
const Grid__popup = ({
  style, children, closeEvent, active, className, flex, noClose,
}) => {
  if (active) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
  return (
    <Container style={style} className={active ? 'active inPopUp' : ''}>
      <ScrollView>
        <Close noClose={noClose} className={active ? 'active' : ''} onClick={() => (closeEvent ? closeEvent() : null)} />
        <ContainerCard className={active ? 'active' : ''}>
          <Card className={className} flex={flex}>
            { !noClose
              ? <Button__closeButton event={() => closeEvent()} />
              : null}
            {children}
          </Card>
        </ContainerCard>
      </ScrollView>
    </Container>
  );
};

export default Grid__popup;
