
/**
   * Import Lib & assets
   */

import { useEffect, useState } from 'react';
import { formatApiCountRes } from 'util/function/formatApiRes.function';
import { switchReq } from './switchReq';

const createFilterForService = (search) => JSON.stringify({
  '$Fleet.vin$': search,
  '$Fleet.registrationNumber$': search,
  '$UserFleet.UserFleetAlias.companyName$': search,
  '$UserWorkshop.UserWorkshopAlias.companyName$': search,
  uid: search,
});

const createFilterForVehicle = (search) => search;

const createFilterFormyFleetUser = (search) => search;


const switchBetweenFilter = (type, search) => {
  if (type === 'service') {
    return createFilterForService(search);
  }

  if (type === 'vehicle') {
    return createFilterForVehicle(search);
  }

  if (type === 'myFleetUser') {
    return createFilterFormyFleetUser(search);
  }

  if (type === 'cases') {
    return search;
  }


  return null;
};


export function useData({
  params, service, type, limite, baseFilter, removeFirstLoading,
}) {
  const [brute, setbrute] = useState(null);
  const [data, setdata] = useState(null);
  const [count, setcount] = useState(0);
  const [search, setsearch] = useState(false);
  const [loading, setloading] = useState(true);
  const [call, setcall] = useState(false);
  const getDataAsync = async (pagination, baseData, newParams) => {
    let filter = baseFilter || null;
    if (search) {
      filter = switchBetweenFilter(type, search);
    }

    const res = await switchReq({
      page: pagination || 1,
      limit: limite || 20,
      filter,
      params: newParams || params || {},
      service,
    });


    setcall(true);
    setcount(formatApiCountRes(res).count);
    setbrute(formatApiCountRes(res).brute);
    if (pagination) {
      let tempData = baseData;
      const newData = formatApiCountRes(res);
      tempData = tempData.concat(newData.data);
      setdata(tempData);
      setloading(false);
    } else {
      const resData = formatApiCountRes(res);
      setdata(resData.data);
    }
    setloading(false);
  };
  useEffect(() => {
    if (!removeFirstLoading) {
      getDataAsync();
    }
  }, []);

  useEffect(() => {
    if (search !== false) {
      getDataAsync();
    }
  }, [search]);

  return ([{
    data,
    brute,
    loading,
    count,
    call,
  }, (newParams) => {
    setloading(true);
    getDataAsync(false, false, newParams);
  }, (pageNumber, baseData, newParams) => {
    if (baseData && pageNumber > 1 && baseData.length < count) {
      setloading(true);
      getDataAsync(pageNumber, baseData, newParams);
    }
  }, (searchValue) => {
    setsearch(searchValue);
  }]);
}
