/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  width: auto;
  height: 33px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  opacity: 0.7;
  color: ${({ color }) => (color ? color : '#000000')};
`;

const Content__tableContent = ({
  style,
  children,
  red,
  green,
  orange,
}) => (
  <Container
    style={style}
    color={
      red
        ? '#E74C3C'
        : orange
          ? '#FFAA29'
          : green
            ? '#2ECC71'
            : '#000000'
    }
  >
    {children}
  </Container>
);

export default Content__tableContent;
