/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-weight: 600;
  font-size: 30px;
  color: #191919;
`;

const Content__pageTitle = ({ style, content }) => <Container style={style}>{content}</Container>;

export default Content__pageTitle;
