
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { getPercent } from 'util/math.utile';

import Cards_serviceCoverage from 'components/cards/serviceCoverage/cards__serviceCoverage';
import Cards_serviceInfo from 'components/cards/serviceInfo/cards__serviceInfo';
import { Grid__separator } from 'components/grid/grid';
import { strapiPriority90Service } from 'util/strapi/content.starpi';

const Container = styled.div``;

const DashboardPriority90__action = ({
  style, infoEvent, coverage, fleet,
}) => {
  const { getGlobal, getField } = useContext(ContentContext);

  return (
    <Container style={style}>
      <Cards_serviceCoverage
        paddingSmall
        to="./test"
        progress={fleet ? getPercent({ total: fleet, number: coverage }) : 0}
        value={coverage}
        labelProgress={getGlobal({ option: 'service', field: 'single_fleetcoverage_title' })}
        labelCounter={getGlobal({ option: 'service', field: 'single_fleetcoverage_vehiclelabel' })}
      />
      <Grid__separator size="20px" />
      <Cards_serviceInfo title={`${getGlobal({ option: 'service', field: 'single_info_title' })} ${getField({ base: strapiPriority90Service, field: 'service_title' })}`} event={infoEvent} />
      <Grid__separator size="40px" />
    </Container>
  );
};

export default DashboardPriority90__action;
