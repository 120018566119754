import XLSX from 'xlsx';

const UseCreateXLSXFile = ({ headers, row, name }) => {
  const createXLSX = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([
      headers,
      row,
    ]);
    XLSX.utils.book_append_sheet(workbook, worksheet, name);
    XLSX.writeFile(workbook, 'Report.xlsx');
    return workbook;
  };

  return createXLSX;
};

export default UseCreateXLSXFile;
