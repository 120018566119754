
import { OBLIGO } from 'util/constant/obligo.constant';
import { getUserWorkshop } from '../workshop/getWorkshopUser';

export const workshopHaveStandarRepair = ({ user }) => {
  const serviceDetails = {
    have: false,
    count: null,
    data: null,
    completehistoric: null,
  };

  const serviceArray = [];
  const historicArray = [];
  const workshop = getUserWorkshop(user);
  if (workshop.success === true) {
    if (workshop.data.standardRepairs && workshop.data.standardRepairs.length > 0) {
      workshop.data.standardRepairs.forEach((element) => {
        serviceArray.push({
          service: element,
          vehicle: element.Fleet,
        });
        historicArray.push({
          service: element,
          vehicle: element.Fleet,
        });
      });

      serviceDetails.have = true;
      serviceDetails.historic = serviceArray;
      serviceDetails.completehistoric = historicArray;
    }
  }

  return serviceDetails;
};


export const homeBaseHaveRepairApproval = ({ user, fullUser }) => {
  const serviceDetails = {
    have: false,
    count: 0,
    data: [],
    historic: [],
    completehistoric: [],
    latestDate: [],
  };
  const arrayOfDate = [];

  const serviceArray = [];
  const historicArray = [];
  const workshopId = fullUser.id;
  if (user.hasOwnProperty('UserFleets')) {
    user.UserFleets.forEach((singleFleet) => {
      if (singleFleet.hasOwnProperty('Fleets')) {
        if (singleFleet.Fleets.length > 0) {
          const fleets = singleFleet.Fleets;
          const haveServiceFleet = fleets.filter((el) => {
            if (el !== null) {
              if (el.hasOwnProperty('repairApprovalSubscription')) {
                if (el.repairApprovalSubscription === true) {
                  if (el.repairApprovalSubscriptionDate) {
                    arrayOfDate.push(new Date(el.repairApprovalSubscriptionDate.replace(' ', 'T')));
                  }
                  Object.entries(el.repairApprovals).forEach((element) => {
                    const serviceObj = element[1];
                    if (singleFleet.repairApprovalUserId === workshopId || (serviceObj && serviceObj.approvedById === workshopId)) {
                      serviceObj.fleetUser_repairApprovalUserId = singleFleet.repairApprovalUserId;
                      serviceArray.push({
                        service: serviceObj,
                        vehicle: el,
                      });
                      historicArray.push({
                        service: serviceObj,
                        vehicle: el,
                      });
                    }
                  });
                  return el;
                }
              }
              Object.entries(el.repairApprovals).forEach((element) => {
                const serviceObj = element[1];
                if (singleFleet.repairApprovalUserId === workshopId || (serviceObj && serviceObj.approvedById === workshopId)) {
                  serviceObj.fleetUser_repairApprovalUserId = singleFleet.repairApprovalUserId;
                  historicArray.push({
                    service: serviceObj,
                    vehicle: el,
                  });
                }
              });
            }
            return false;
          });

          if (haveServiceFleet.length > 0) {
            serviceDetails.have = true;
            serviceDetails.count += haveServiceFleet.length;
            serviceDetails.data = haveServiceFleet;
            serviceDetails.historic = serviceArray;
            serviceDetails.completehistoric = historicArray;
          }
        }
      }
    });
  }

  return serviceDetails;
};

export const workshopHaveRepairApproval = ({ user }) => {
  const serviceDetails = {
    have: false,
    count: null,
    data: null,
    completehistoric: null,
  };

  const serviceArray = [];
  const historicArray = [];
  const workshop = getUserWorkshop(user);
  const homeBaseFleetService = homeBaseHaveRepairApproval({ user: workshop.data, fullUser: user.user });
  if (workshop.success === true) {
    if (workshop.data.repairApprovals && workshop.data.repairApprovals.length > 0) {
      workshop.data.repairApprovals.forEach((element) => {
        serviceArray.push({
          service: element,
          vehicle: element.Fleet,
        });
        historicArray.push({
          service: element,
          vehicle: element.Fleet,
        });
      });

      serviceDetails.have = true;
    }
  }

  serviceDetails.have = serviceDetails.have ? true : homeBaseFleetService.have ? true : false;
  serviceDetails.historic = serviceArray.concat(homeBaseFleetService.historic);
  serviceDetails.completehistoric = historicArray.concat(homeBaseFleetService.completehistoric);

  return serviceDetails;
};

export const workshopHavePriority90 = ({ user }) => {
  const serviceDetails = {
    have: false,
    count: null,
    data: null,
    completehistoric: null,
  };

  const serviceArray = [];
  const historicArray = [];
  const workshop = getUserWorkshop(user);
  if (workshop.success === true) {
    if (workshop.data.priority90s && workshop.data.priority90s.length > 0) {
      workshop.data.priority90s.forEach((element) => {
        serviceArray.push({
          service: element,
          vehicle: element.Fleet,
        });
        historicArray.push({
          service: element,
          vehicle: element.Fleet,
        });
      });

      serviceDetails.have = true;
      serviceDetails.historic = serviceArray;
      serviceDetails.completehistoric = historicArray;
    }
  }

  return serviceDetails;
};


export const homeBaseHavePaymentGuarantee = ({ user }) => {
  const serviceDetails = {
    have: false,
    count: 0,
    data: [],
    historic: [],
    completehistoric: [],
    obligoHistoric: [],
    latestDate: [],
  };
  const arrayOfDate = [];

  const serviceArray = [];
  const historicArray = [];
  const obligoArray = [];

  if (user.hasOwnProperty('UserFleets')) {
    user.UserFleets.forEach((singleFleet) => {
      if (singleFleet.hasOwnProperty('Fleets')) {
        if (singleFleet.Fleets.length > 0) {
          const fleets = singleFleet.Fleets;
          const haveServiceFleet = fleets.filter((el) => {
            if (el !== null) {
              if (el.hasOwnProperty('paymentguaranteeSubscription')) {
                if (el.paymentguaranteeSubscription === true) {
                  if (el.paymentguaranteeSubscriptionDate) {
                    arrayOfDate.push(new Date(el.paymentguaranteeSubscriptionDate.replace(' ', 'T')));
                  }
                  Object.entries(el.paymentguarantees).forEach((element) => {
                    const vehicle = el;
                    vehicle.UserFleet = singleFleet;
                    serviceArray.push({
                      service: element[1],
                      vehicle,
                    });
                    historicArray.push({
                      service: element[1],
                      vehicle,
                    });
                    if (element[1].type === OBLIGO.type.obligo) {
                      obligoArray.push({
                        service: element[1],
                        vehicle,
                      });
                    }
                  });
                  return el;
                }
                Object.entries(el.paymentguarantees).forEach((element) => {
                  const vehicle = el;
                  vehicle.UserFleet = singleFleet;
                  historicArray.push({
                    service: element[1],
                    vehicle,
                  });
                  if (element[1].type === OBLIGO.type.obligo) {
                    obligoArray.push({
                      service: element[1],
                      vehicle,
                    });
                  }
                });
              }
            }
            return false;
          });

          if (haveServiceFleet.length > 0) {
            serviceDetails.have = true;
            serviceDetails.count += haveServiceFleet.length;
            serviceDetails.data = haveServiceFleet;
            serviceDetails.historic = serviceArray;
            serviceDetails.completehistoric = historicArray;
            serviceDetails.obligoHistoric = obligoArray;
          }
        }
      }
    });
  }

  return serviceDetails;
};

export const workshopHavePaymentguarantee = ({ user, withId }) => {
  const serviceDetails = {
    have: false,
    count: null,
    data: [],
    completehistoric: [],
    obligoHistoric: [],
    historic: [],
  };

  const serviceArray = [];
  const historicArray = [];
  const workshop = getUserWorkshop(user);
  const obligoHistoric = [];
  const homeBaseFleetService = homeBaseHavePaymentGuarantee({ user: workshop.data });
  if (workshop.success === true) {
    if (workshop.data.paymentguarantees && workshop.data.paymentguarantees.length > 0) {
      workshop.data.paymentguarantees.forEach((element) => {
        serviceArray.push({
          service: element,
          vehicle: element.Fleet,
        });
        historicArray.push({
          service: element,
          vehicle: element.Fleet,
        });
        if (element.type === OBLIGO.type.obligo) {
          if (withId) {
            if (element && element.Fleet && element.Fleet.UserFleet && element.Fleet.UserFleet.UserFleetAlias && element.Fleet.UserFleet.UserFleetAlias.id) {
              if (element.Fleet.UserFleet.UserFleetAlias.id === withId) {
                obligoHistoric.push({
                  service: element,
                  vehicle: element.Fleet,
                });
              }
            }
          } else {
            obligoHistoric.push({
              service: element,
              vehicle: element.Fleet,
            });
          }
        }
      });

      serviceDetails.have = true;
    }
  }

  serviceDetails.have = serviceDetails.have ? true : homeBaseFleetService.have ? true : false;
  serviceDetails.historic = serviceArray.concat(homeBaseFleetService.historic);
  serviceDetails.completehistoric = historicArray.concat(homeBaseFleetService.completehistoric);
  serviceDetails.obligoHistoric = obligoHistoric.concat(homeBaseFleetService.obligoHistoric);

  return serviceDetails;
};
