import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import t from 'theme';


const DurationCard = styled.div`
  width: 490px;
  background: ${(props) => (props.red ? '#FFEEEE' : props.green ? '#EAFAF1' : props.orange ? '#FFF6EA' : '#EAFAF1')};
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  color: #4D4D4D;
  border-radius: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  ${t.mixins.flex({
    align: 'center',
    justify: 'flex-start',
  })}
`;

const ClockIconContainer = styled.div`
  width: 43px;
  height: 43px;
  background: ${(props) => (props.red ? '#FFDBDB' : props.green ? '#CEF3DE' : props.orange ? '#FFEED7' : '#CEF3DE')};
  display: block;
  margin-right: 15px;
  border-radius: 50%;
  ${t.mixins.flex({
    align: 'center',
    justify: 'center',
  })}
`;

const ClockIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${(props) => (props.red ? '#FF5C5C' : props.green ? '#2ECC71' : props.orange ? '#FF9800' : '#2ECC71')};
  margin-top: -2px;
`;


const Cards__duration = ({
  content, red, green, orange,
}) => (
  <DurationCard red={red} green={green} orange={orange}>
    <ClockIconContainer red={red} green={green} orange={orange}>
      <ClockIcon red={red} green={green} orange={orange} icon={faStopwatch} />
    </ClockIconContainer>
    {content}
  </DurationCard>
);

export default Cards__duration;
