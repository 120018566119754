/**
 * GET FLEET FOR SERVICE
 */

const getVehicleFromService = (service, key) => {
  if (service && key) {
    if (service.Fleet && service.Fleet[key]) {
      return service.Fleet[key];
    }
  }

  return '';
};
/**
 * GET USER FLEET FOR SERVICE
 */

const getFleetFromService = (service, key) => {
  if (service && key) {
    if (service.UserFleet && service.UserFleet[key]) {
      return service.UserFleet[key];
    }
  }

  return '';
};

const getUserFleetFromService = (service, key) => {
  if (service && key) {
    if (service.UserFleet && service.UserFleet.UserFleetAlias && service.UserFleet[key]) {
      return service.UserFleet.UserFleetAlias[key];
    }
  }

  return '';
};
/**
 * GET USER WORKSHOP FOR SERVICE
 */

const getWorkshopFromService = (service, key) => {
  if (service && key) {
    if (service.UserWorkshop && service.UserWorkshop[key]) {
      return service.UserWorkshop[key];
    }
  }

  return '';
};

/**
 * GET USER WORKSHOP SUER FOR SERVICE
 */

const getUserWorkshopFromService = (service, key) => {
  if (service && key) {
    if (service.UserWorkshop && service.UserWorkshop.UserWorkshopAlias && service.UserWorkshop.UserWorkshopAlias[key]) {
      return service.UserWorkshop.UserWorkshopAlias[key];
    }
  }

  return '';
};

export const getFormService = (service, key, type) => {
  if (type === 'vehicle') {
    return getVehicleFromService(service, key);
  }

  if (type === 'fleet') {
    return getFleetFromService(service, key);
  }

  if (type === 'userfleet') {
    return getUserFleetFromService(service, key);
  }
  if (type === 'workshop') {
    return getWorkshopFromService(service, key);
  }
  if (type === 'userworkshop') {
    return getUserWorkshopFromService(service, key);
  }

  if (service && key && service[key]) {
    return service[key];
  }

  return '';
};
