
import React, { useContext } from 'react';
import styled from 'styled-components';

import { ContentContext } from 'context/contentContext';

import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';

const Container = styled.div`
  padding-top: 40px;
  padding-left: 40px;
`;

const HomeBase__header = ({ style, basePage }) => {
  const { getField } = useContext(ContentContext);
  return (
    <Container style={style}>
      <Content__pageTitle content={getField({ base: basePage, field: 'page_title' })} />
    </Container>
  );
};

export default HomeBase__header;
