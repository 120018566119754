
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { List__table, List__content } from 'components/list/list';
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import Content__tag from 'components/content/tag/content__tag';
import { parseStatus, renderPriority90FleetStatus } from 'util/conditions/statusProps.util';
import { sequelizeDate } from 'util/date.util';
import DashboardFairPrice__singlePop from 'containers/fleetView/dashboardFairPrice/singlePop/dashboardFairPrice__singlePop';
import {
  strapiFairPriceService,
  strapiStandartrepairService,
  strapiRepairApprovalService,
  strapiService24Service,
  strapiPriority90Service,
  strapiPaymentGuaranteeService,
  strapiServiceHeader,
} from 'util/strapi/content.starpi';
import DashboardStandardRepair__singlePop from 'containers/fleetView/dashboardStandardRepair/singlePop/dashboardStandardRepair__singlePop';
import DashboardPaymentGuarantee__singlePop from 'containers/fleetView/dashboardPaymentGuarantee/singlePop/dashboardPaymentGuarantee__singlePop';
import { handleLineClick_multiple } from 'util/function/openSingleService';
import { SERVICENAME } from 'util/service/serviceName';
import { repairApproval_isAction } from 'util/conditions/repairApproval.condition';

import DashboardRepairApproval__singlePop from 'containers/fleetView/dashboardRepairApproval/singlePop/dashboardRepairApproval__singlePop';
import DashboardService24__singlePop from 'containers/fleetView/dashboardService24/singlePop/dashboardService24__singlePop';
import DashboardPriority90__singlePop from 'containers/fleetView/dashboardPriority90/singlePop/dashboardPriority90__singlePop';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';

const Container = styled.div`
  height: calc(100% - 80px);
  position: relative;
`;

const Table = styled(List__table)`
  height: 100%;
`;

const Home__list = ({
  style, historic, handleLoadNewPage, loading,
}) => {
  const { getField, getFieldGroup, getGlobal } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [activesinglePop, setactivesinglePop] = useState({
    serviceName: '',
    service: null,
    active: false,
    id: null,
  });
  const headerCol = [
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'service_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'vehicle_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'date_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'status_header' }),
    },
  ];

  const createHistoric = () => {
    const newArray = [];
    if (historic && Array.isArray(historic)) {
      historic.forEach((element) => {
        if (element.FairPrice) {
          newArray.push({
            vehicle: element.FairPrice.Fleet,
            data: element.FairPrice,
            serviceName: getField({ base: strapiFairPriceService, field: 'service_title' }),
            serviceSlug: SERVICENAME.fairPrice,
            content: strapiFairPriceService,
          });
        } else if (element.paymentguarantee) {
          newArray.push({
            vehicle: element.paymentguarantee.Fleet,
            data: element.paymentguarantee,
            serviceName: getField({ base: strapiPaymentGuaranteeService, field: 'service_title' }),
            serviceSlug: SERVICENAME.paymentguarantee,
            content: strapiPaymentGuaranteeService,
          });
        } else if (element.priority90) {
          newArray.push({
            vehicle: element.priority90.Fleet,
            data: element.priority90,
            serviceName: getField({ base: strapiPriority90Service, field: 'service_title' }),
            serviceSlug: SERVICENAME.priority90,
            content: strapiPriority90Service,
          });
        } else if (element.repairApproval) {
          newArray.push({
            vehicle: element.repairApproval.Fleet,
            data: element.repairApproval,
            serviceName: getField({ base: strapiRepairApprovalService, field: 'service_title' }),
            serviceSlug: SERVICENAME.repairapproval,
            content: strapiRepairApprovalService,
          });
        } else if (element.service24) {
          newArray.push({
            vehicle: element.service24.Fleet,
            data: element.service24,
            serviceName: getField({ base: strapiService24Service, field: 'service_title' }),
            serviceSlug: SERVICENAME.service24,
            content: strapiService24Service,
          });
        } else if (element.standardRepair) {
          newArray.push({
            vehicle: element.standardRepair.Fleet,
            data: element.standardRepair,
            serviceName: getField({ base: strapiStandartrepairService, field: 'service_title' }),
            serviceSlug: SERVICENAME.standardrepair,
            content: strapiStandartrepairService,
          });
        }
      });
    }

    return newArray;
  };

  const renderServiceHistoric = () => {
    const orderArray = createHistoric();
    const arrayOfLine = [];
    orderArray.forEach((element) => {
      arrayOfLine.push([
        {
          component: <List__content>{element.serviceName}</List__content>,
          type: 'node',
        },
        {
          component: <List__content uppercase>{ element && element.vehicle ? element.vehicle.registrationNumber : ''}</List__content>,
          type: 'node',
        },
        {
          component: (
            <List__content>
              {sequelizeDate({ date: element.data.createdAt, format: 'd/m/y' })}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            element.data.status ? (
              element.serviceSlug === SERVICENAME.repairapproval ? (
                <Content__tag
                  content={getFieldGroup({
                    base: element.content,
                    groupe: 'status',
                    field: repairApproval_isAction(element.data, user),
                  })}
                  {...parseStatus({ status: repairApproval_isAction(element.data, user) })}
                />
              ) : element.serviceSlug === SERVICENAME.priority90 ? (
                <Content__tag
                  content={
                    element.data.status === 'done'
                      ? getField({ base: strapiPriority90Service, field: 'status_fleet_done' })
                      : getFieldGroup({
                        base: strapiPriority90Service,
                        groupe: 'status',
                        field: renderPriority90FleetStatus(element.data.status),
                      })
                  }
                  {...parseStatus({ status: renderPriority90FleetStatus(element.data.status) })}
                />
              ) : (
                <Content__tag
                  content={getFieldGroup({
                    base: element.content,
                    groupe: 'status',
                    field: element.data.status,
                  })}
                  {...parseStatus({ status: element.data.status })}
                />
              )
            ) : (
              <List__content />
            )
          ),
          type: 'node',
        },
      ]);
    });

    return arrayOfLine;
  };

  const openPopUp = (serviceName, service) => {
    setactivesinglePop({
      serviceName,
      service,
      active: true,
      id: service.id,
    });
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({
      serviceName: '',
      service: null,
      active: false,
      id: null,
    });
  };

  const handleLine = (line) => {
    handleLineClick_multiple({
      line,
      array: createHistoric(),
      callback: (name, currentLine) => openPopUp(name, currentLine),
    });
  };

  return (
    <Container style={style}>
      <Table
        headerCol={headerCol}
        lineCol={renderServiceHistoric()}
        noSelect
        lineEvent={handleLine}
        maxHeight="100%"
        scrollEndEvent={handleLoadNewPage}
      />
      {
        loading && (
          <Loader__wrapper absolute />
        )
      }
      <DashboardFairPrice__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.fairPrice
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardStandardRepair__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.standardrepair
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardRepairApproval__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.repairapproval
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardService24__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.service24
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardPriority90__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.priority90
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardPaymentGuarantee__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.paymentguarantee
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
    </Container>
  );
};

export default Home__list;
