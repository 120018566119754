import {
  strapiPaymentGuaranteeService,
  strapiSingleServicePage,
  strapiRepairApprovalService,
  strapiService24Service,
  strapiPriority90Service,
  strapiFairPriceService,
  strapiStandartrepairService,
  strapiSmartMaitenanceService,
} from 'util/strapi/content.starpi';

export const loadService = ({ haveFunc, loadFunc }) => {
  if (haveFunc(strapiSingleServicePage).success === false) {
    loadFunc(strapiSingleServicePage);
  }
  if (haveFunc(strapiFairPriceService).success === false) {
    loadFunc(strapiFairPriceService);
  }
  if (haveFunc(strapiStandartrepairService).success === false) {
    loadFunc(strapiStandartrepairService);
  }
  if (haveFunc(strapiPaymentGuaranteeService).success === false) {
    loadFunc(strapiPaymentGuaranteeService);
  }
  if (haveFunc(strapiRepairApprovalService).success === false) {
    loadFunc(strapiRepairApprovalService);
  }
  if (haveFunc(strapiService24Service).success === false) {
    loadFunc(strapiService24Service);
  }
  if (haveFunc(strapiPriority90Service).success === false) {
    loadFunc(strapiPriority90Service);
  }
  if (haveFunc(strapiSmartMaitenanceService).success === false) {
    loadFunc(strapiSmartMaitenanceService);
  }
};

export const haveServiceContent = ({ haveFunc }) => {
  if (
    haveFunc(strapiSingleServicePage).success === false
    || haveFunc(strapiPaymentGuaranteeService).success === false
    || haveFunc(strapiRepairApprovalService).success === false
    || haveFunc(strapiService24Service).success === false
    || haveFunc(strapiPriority90Service).success === false
    || haveFunc(strapiSmartMaitenanceService).success === false
  ) {
    return false;
  }

  return true;
};
