
import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import t from 'theme';

/**
 * import containers & components
 */
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import { Account__setting, Account__contract } from 'containers/fleetView/account/account';
import { Grid__separator, Grid__card } from 'components/grid/grid';
import { Content__text } from 'components/content/content';
import { Button__primary } from 'components/button/button';
import Cards__warning from 'components/cards/warning/cards__warning';
import Cards__tuto from 'components/cards/tuto/cards__tuto';
import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMoneyCheck, faTags } from '@fortawesome/pro-solid-svg-icons';
import { Form__textfield } from 'components/form/form';
import Form__hourly from 'components/form/hourly/form__hourly';
import { faSave } from '@fortawesome/pro-regular-svg-icons';
import { checkRate } from 'util/conditions/rate.condition';
import { updateWorkshopUserPrivate, updatefleetUserInformation } from 'helpers/api/routes/User';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { strapiMapPage, strapiRegister } from 'util/strapi/content.starpi';
import {
  faCreditCard, faMoneyBillWave, faUniversity, faGasPump,
} from '@fortawesome/pro-light-svg-icons';
import Form__checkBoxCard from 'components/form/checkBoxCard/form__checkBoxCard';
import {
  FLEET_EXTRA_PAYMENT, FLEET_PAYMENT, formatPaymentMethods, parsePaymentMethods,
} from '../../../../util/constant/fleetPayment';

/**
 * styled components
 */

const Container = styled.div`
  width: calc(50% - 20px);
  height: auto;
`;

const MainCards = styled(Grid__card)`
  width: 100%;
  padding: 40px;
`;

const Button = styled(Button__primary)`
  min-width: 150px;
  text-align: center;
`;

const RowButton = styled(Cards__warning)`
  padding: 20px 40px;
  ${t.mixins.flex({ justify: 'space-between', align: 'center' })}
`;

const InfoContent = styled.div`
  max-width: 600px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 25px;
`;

/**
 * information component is..
 */

const Account__sideInformation = ({ style, basePage }) => {
  const { getField, getGlobal, getFieldGroup } = useContext(ContentContext);
  const { user, logout, getUser } = useContext(AppContext);
  const getWorkshopInformation = (key) => {
    if (user && user.user && user.user.UserWorkshop) {
      if (user.user.UserWorkshop[key] && user.user.UserWorkshop[key] !== '') {
        return {
          value: user.user.UserWorkshop[key],
          error: true,
        };
      }
    }

    return {
      value: '',
      error: true,
    };
  };

  const [rate1, setrate1] = useState(checkRate(getWorkshopInformation('rate1').value));
  const [rate2, setrate2] = useState(checkRate(getWorkshopInformation('rate2').value));
  const [rate3, setrate3] = useState(checkRate(getWorkshopInformation('rate3').value));

  const getActive = (start, end) => {
    if (user && user.user && user.user.UserWorkshop) {
      if ((user.user.UserWorkshop[start] && user.user.UserWorkshop[start] !== '') || (user.user.UserWorkshop[end] && user.user.UserWorkshop[end] !== '')) {
        return true;
      }
    }

    return false;
  };
  const [loading, setloading] = useState(false);
  const [submitHourly, setsubmitHourly] = useState(false);
  const [hourValue, sethourValue] = useState([
    {
      day: getField({ base: strapiMapPage, field: 'monday' }),
      amStart: {
        key: 'mondayAmStart',
        value: getWorkshopInformation('mondayAmStart').value,
        error: getWorkshopInformation('mondayAmStart').error,
      },
      amEnd: {
        key: 'mondayAmEnd',
        value: getWorkshopInformation('mondayAmEnd').value,
        error: getWorkshopInformation('mondayAmEnd').error,
      },
      pmStart: {
        key: 'mondayPmStart',
        value: getWorkshopInformation('mondayPmStart').value,
        error: getWorkshopInformation('mondayPmStart').error,
      },
      pmEnd: {
        key: 'mondayPmEnd',
        value: getWorkshopInformation('mondayPmEnd').value,
        error: getWorkshopInformation('mondayPmEnd').error,
      },
      active: getActive('mondayAmStart', 'mondayPmStart'),
    },
    {
      day: getField({ base: strapiMapPage, field: 'tuesday' }),
      amStart: {
        key: 'tuesdayAmStart',
        value: getWorkshopInformation('tuesdayAmStart').value,
        error: getWorkshopInformation('tuesdayAmStart').error,
      },
      amEnd: {
        key: 'tuesdayAmEnd',
        value: getWorkshopInformation('tuesdayAmEnd').value,
        error: getWorkshopInformation('tuesdayAmEnd').error,
      },
      pmStart: {
        key: 'tuesdayPmStart',
        value: getWorkshopInformation('tuesdayPmStart').value,
        error: getWorkshopInformation('tuesdayPmStart').error,
      },
      pmEnd: {
        key: 'tuesdayPmEnd',
        value: getWorkshopInformation('tuesdayPmEnd').value,
        error: getWorkshopInformation('tuesdayPmEnd').error,
      },
      active: getActive('tuesdayAmStart', 'tuesdayPmStart'),
    },
    {
      day: getField({ base: strapiMapPage, field: 'wednesday' }),
      amStart: {
        key: 'wednesdayAmStart',
        value: getWorkshopInformation('wednesdayAmStart').value,
        error: getWorkshopInformation('wednesdayAmStart').error,
      },
      amEnd: {
        key: 'wednesdayAmEnd',
        value: getWorkshopInformation('wednesdayAmEnd').value,
        error: getWorkshopInformation('wednesdayAmEnd').error,
      },
      pmStart: {
        key: 'wednesdayPmStart',
        value: getWorkshopInformation('wednesdayPmStart').value,
        error: getWorkshopInformation('wednesdayPmStart').error,
      },
      pmEnd: {
        key: 'wednesdayPmEnd',
        value: getWorkshopInformation('wednesdayPmEnd').value,
        error: getWorkshopInformation('wednesdayPmEnd').error,
      },
      active: getActive('wednesdayAmStart', 'wednesdayPmStart'),
    },
    {
      day: getField({ base: strapiMapPage, field: 'thursday' }),
      amStart: {
        key: 'thursdayAmStart',
        value: getWorkshopInformation('thursdayAmStart').value,
        error: getWorkshopInformation('thursdayAmStart').error,
      },
      amEnd: {
        key: 'thursdayAmEnd',
        value: getWorkshopInformation('thursdayAmEnd').value,
        error: getWorkshopInformation('thursdayAmEnd').error,
      },
      pmStart: {
        key: 'thursdayPmStart',
        value: getWorkshopInformation('thursdayPmStart').value,
        error: getWorkshopInformation('thursdayPmStart').error,
      },
      pmEnd: {
        key: 'thursdayPmEnd',
        value: getWorkshopInformation('thursdayPmEnd').value,
        error: getWorkshopInformation('thursdayPmEnd').error,
      },
      active: getActive('thursdayAmStart', 'thursdayPmStart'),
    },
    {
      day: getField({ base: strapiMapPage, field: 'friday' }),
      amStart: {
        key: 'fridayAmStart',
        value: getWorkshopInformation('fridayAmStart').value,
        error: getWorkshopInformation('fridayAmStart').error,
      },
      amEnd: {
        key: 'fridayAmEnd',
        value: getWorkshopInformation('fridayAmEnd').value,
        error: getWorkshopInformation('fridayAmEnd').error,
      },
      pmStart: {
        key: 'fridayPmStart',
        value: getWorkshopInformation('fridayPmStart').value,
        error: getWorkshopInformation('fridayPmStart').error,
      },
      pmEnd: {
        key: 'fridayPmEnd',
        value: getWorkshopInformation('fridayPmEnd').value,
        error: getWorkshopInformation('fridayPmEnd').error,
      },
      active: getActive('fridayAmStart', 'fridayPmStart'),
    },
    {
      day: getField({ base: strapiMapPage, field: 'saturday' }),
      amStart: {
        key: 'saturdayAmStart',
        value: getWorkshopInformation('saturdayAmStart').value,
        error: getWorkshopInformation('saturdayAmStart').error,
      },
      amEnd: {
        key: 'saturdayAmEnd',
        value: getWorkshopInformation('saturdayAmEnd').value,
        error: getWorkshopInformation('saturdayAmEnd').error,
      },
      pmStart: {
        key: 'saturdayPmStart',
        value: getWorkshopInformation('saturdayPmStart').value,
        error: getWorkshopInformation('saturdayPmStart').error,
      },
      pmEnd: {
        key: 'saturdayPmEnd',
        value: getWorkshopInformation('saturdayPmEnd').value,
        error: getWorkshopInformation('saturdayPmEnd').error,
      },
      active: getActive('saturdayAmStart', 'saturdayPmStart'),
    },
    {
      day: getField({ base: strapiMapPage, field: 'sunday' }),
      amStart: {
        key: 'sundayAmStart',
        value: getWorkshopInformation('sundayAmStart').value,
        error: getWorkshopInformation('sundayAmStart').error,
      },
      amEnd: {
        key: 'sundayAmEnd',
        value: getWorkshopInformation('sundayAmEnd').value,
        error: getWorkshopInformation('sundayAmEnd').error,
      },
      pmStart: {
        key: 'sundayPmStart',
        value: getWorkshopInformation('sundayPmStart').value,
        error: getWorkshopInformation('sundayPmStart').error,
      },
      pmEnd: {
        key: 'sundayPmEnd',
        value: getWorkshopInformation('sundayPmEnd').value,
        error: getWorkshopInformation('sundayPmEnd').error,
      },
      active: getActive('sundayAmStart', 'sundayPmStart'),
    },
  ]);

  const getCurrentPaymentMethods = (key, secondary) => {
    if (user && user.user && user.user.UserFleet) {
      const payment = parsePaymentMethods(user.user.UserFleet.paymentMethod, user.user.UserFleet.paymentMethodDetail);
      if (key) {
        if (secondary) {
          if (!user.user.UserFleet.paymentMethodDetail && key === FLEET_EXTRA_PAYMENT.DKV) {
            return true;
          }
          return payment.secondary[key];
        }

        return payment.main[key];
      }
    }
  };

  const [paymentMethod, setPaymentMethod] = useState({
    main: [
      {
        slug: FLEET_PAYMENT.CASH,
        label: getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'method_1_cash' }),
        icon: faMoneyBillWave,
        active: getCurrentPaymentMethods(FLEET_PAYMENT.CASH),
      },
      {
        slug: FLEET_PAYMENT.CREDITCARDS, label: getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'method_2_card' }), icon: faCreditCard, active: getCurrentPaymentMethods(FLEET_PAYMENT.CREDITCARDS),
      },
      {
        slug: FLEET_PAYMENT.BANKTRANSFER, label: getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'method_3_transfer' }), icon: faUniversity, active: getCurrentPaymentMethods(FLEET_PAYMENT.BANKTRANSFER),
      },
      {
        slug: FLEET_PAYMENT.FUELCARD, label: getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'method_4_fuel' }), icon: faGasPump, active: getCurrentPaymentMethods(FLEET_PAYMENT.FUELCARD),
      },
    ],
    secondary: [
      { label: 'DKV', slug: FLEET_EXTRA_PAYMENT.DKV, active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.DKV, true) },
      { label: 'UTA', slug: FLEET_EXTRA_PAYMENT.UTA, active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.UTA, true) },
      { label: 'Total', slug: FLEET_EXTRA_PAYMENT.TOTAL, active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.TOTAL, true) },
      { label: 'Shell', slug: FLEET_EXTRA_PAYMENT.SHELL, active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.SHELL, true) },
      { label: 'BP', slug: FLEET_EXTRA_PAYMENT.BP, active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.BP, true) },
      { label: getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'fuel_card_other' }), slug: FLEET_EXTRA_PAYMENT.OTHER, active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.OTHER, true) },
    ],
  });

  const handleHourly = (value) => {
    setsubmitHourly(false);
    sethourValue(value);
  };

  const saveHourly = async () => {
    setsubmitHourly(true);
    if (user.user.role === 'workshop') {
      const obj = {};
      let hasError = false;
      hourValue.forEach((element) => {
        if (element.active && (element.amStart.error && element.pmStart.error)) {
          hasError = true;
        }
        if ((!element.amStart.error && element.amEnd.error) || (!element.pmStart.error && element.pmEnd.error)) {
          hasError = true;
        }
        obj[element.amStart.key] = element.amStart.value === '' ? '' : element.amStart.value || '';
        obj[element.amEnd.key] = element.amEnd.value === '' ? '' : element.amEnd.value || '';
        obj[element.pmStart.key] = element.pmStart.value === '' ? '' : element.pmStart.value || '';
        obj[element.pmEnd.key] = element.pmEnd.value === '' ? '' : element.pmEnd.value || '';
      });

      obj.userId = user.user.UserWorkshop.id;

      if (!hasError) {
        setloading(true);
        await updateWorkshopUserPrivate(obj);
        await getUser();
        setloading(false);
        return true;
      }
      return false;
    }

    return true;
  };

  const saveRate = async () => {
    if (user.user.role === 'workshop') {
      setloading(true);
      const obj = {
        rate1: rate1 ? parseFloat(rate1, 10) : -1,
        rate2: rate2 ? parseFloat(rate2, 10) : -1,
        rate3: rate3 ? parseFloat(rate3, 10) : -1,
        userId: user.user.UserWorkshop.id,
      };

      await updateWorkshopUserPrivate(obj);
      await getUser();
      setloading(false);
      return true;
    }

    return true;
  };

  const handlePaymentChange = async (res) => {
    setPaymentMethod({
      main: res.choices,
      secondary: res.fuel,
    });
  };

  const savePaymentMethods = async () => {
    const payment = formatPaymentMethods(paymentMethod.main, paymentMethod.secondary);
    setloading(true);
    await updatefleetUserInformation({
      userId: user.user.UserFleet.id,
      paymentMethod: payment.main === '' ? null : payment.main,
      paymentMethodDetail: payment.secondary === '' ? null : payment.secondary,
    });

    setloading(false);
  };

  return (
    <Container style={style}>
      <MainCards style={style}>
        <Account__setting basePage={basePage} />
        <Grid__separator size="65px" />
        <Account__contract basePage={basePage} />
      </MainCards>
      <Grid__separator size="40px" />
      {
        user.user.role === 'workshop' ? (
          <MainCards style={style}>
            <Content__sectionTitle
              title={getGlobal({ option: 'workshopInformation', field: 'opening_hours' })}
              icon={<FontAwesomeIcon icon={faClock} />}
            />
            <Form__hourly value={hourValue} changeValue={handleHourly} submitHourly={submitHourly} />
            <Grid__separator size="15px" />
            <Button__primary
              withIcon
              event={saveHourly}
              icon={<FontAwesomeIcon icon={faSave} />}
            >
              {getField({ base: basePage, field: 'save_opening' })}
            </Button__primary>
            <Grid__separator size="65px" />
            <Content__sectionTitle
              title={getGlobal({ option: 'workshopInformation', field: 'hourly_rate' })}
              icon={<FontAwesomeIcon icon={faTags} />}
            />
            <Form__textfield
              style={{ maxWidth: 250 }}
              value={rate1}
              label={getGlobal({ option: 'workshopInformation', field: 'rate_1' })}
              placeholder="00"
              type="price"
              change={(res) => setrate1(res.value)}
            />
            <Grid__separator size="25px" />
            <Form__textfield
              style={{ maxWidth: 250 }}
              value={rate2}
              placeholder="00"
              label={getGlobal({ option: 'workshopInformation', field: 'rate_2' })}
              type="price"
              change={(res) => setrate2(res.value)}
            />
            <Grid__separator size="25px" />
            <Form__textfield
              style={{ maxWidth: 250 }}
              value={rate3}
              placeholder="00"
              label={getGlobal({ option: 'workshopInformation', field: 'rate_3' })}
              type="price"
              change={(res) => setrate3(res.value)}
            />
            <Grid__separator size="25px" />
            <Button__primary
              withIcon
              icon={<FontAwesomeIcon icon={faSave} />}
              event={saveRate}
            >
              {getField({ base: basePage, field: 'save_hourly_rate' })}
            </Button__primary>
          </MainCards>
        ) : (
          <Fragment>
            <Cards__tuto
              title={getField({ base: basePage, field: 'onboarding_title' })}
              button={getField({ base: basePage, field: 'onboarding_btn' })}
            />
            <Grid__separator size="40px" />
            <MainCards style={style}>
              <Content__sectionTitle
                title={getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'title' })}
                icon={<FontAwesomeIcon icon={faMoneyCheck} />}
              />
              <InfoContent>{getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'description' })}</InfoContent>
              <Form__checkBoxCard
                choices={paymentMethod.main}
                choicesSecondary={paymentMethod.secondary}
                handleChange={handlePaymentChange}
                inputSelect={3}
              />
              <Grid__separator size="25px" />
              <Button__primary
                withIcon
                icon={<FontAwesomeIcon icon={faMoneyCheck} />}
                event={savePaymentMethods}
              >
                {getFieldGroup({ base: strapiRegister, groupe: 'payment_methods', field: 'save_button' })}
              </Button__primary>
            </MainCards>
          </Fragment>
        )
      }
      <Grid__separator size="40px" />
      <RowButton>
        <Content__text
          style={{
            fontSize: 16,
          }}
          color="#E74C3C"
        >
          {getField({ base: basePage, field: 'logout_text' })}
        </Content__text>
        <Button border red event={() => logout()}>
          {getField({ base: basePage, field: 'logout_button' })}
        </Button>
      </RowButton>
      {loading && (<Loader__wrapper fixedContainer fullWidth />)}
    </Container>
  );
};

export default Account__sideInformation;
