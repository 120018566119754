/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';
import Content__tag from 'components/content/tag/content__tag';
import { Grid__separator } from 'components/grid/grid';
import Button__primary from 'components/button/primary/button__primary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';

const ServiceTag = styled(Content__tag)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const ServiceCard = styled.div`
  position: relative;
  width: 490px;
  background: ${(props) => (props.orange
    ? '#FFF6EA'
    : props.green
      ? '#EAFAF1'
      : props.red
        ? '#FDEDEB'
        : props.blue
          ? '#E6F8FC'
          : '#E6F8FC')};
  border-radius: 20px;
  padding: 30px;
  font-weight: 600;
  font-size: 16px;
  color: #4D4D4D;
  ${t.mixins.flex({
    direction: 'column',
    align: 'flex-start',
  })}
`;

const Cards__serviceBookmark = ({
  style,
  icon,
  title,
  button,
  tag,
  green,
  red,
  blue,
  orange,
  buttonEvent,
}) => (
  <ServiceCard
    style={style}
    green={green}
    red={red}
    blue={blue}
    orange={orange}
  >
    <span>
      <FontAwesomeIcon icon={icon} />
      {' '}
      {title}
    </span>
    <Grid__separator size="25px" />
    <Button__primary
      iconEnd
      withIcon
      iconRight
      icon={<FontAwesomeIcon icon={faLongArrowRight} />}
      event={buttonEvent}
    >
      {button}
    </Button__primary>
    <ServiceTag
      content={tag}
      darken
      green={green}
      red={red}
      blue={blue}
      orange={orange}
    />
  </ServiceCard>
);

export default Cards__serviceBookmark;
