
/**
 * Import Lib & assets
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';

/**
 * import components
 */


/**
 * styled components
 */

const Container = styled.div`
  display: none;
  user-select: none;
  pointer-events: none;
`;

/**
 * mover component is..
 */

const Transition__controller = ({ active, nextPageCallback }) => {
  const activeAnimation = () => {
    if (active === true) {
      const wrapper = document.querySelector('.transition__container');
      const OldPageContainer = document.querySelector('.mainPageContainer');
      const body = document.querySelector('body');
      body.classList.add('inNavigation');

      wrapper.classList.add('active');
      if (document.querySelector('.mainPageContainer')) {
        OldPageContainer.style.opacity = '0.3';
        OldPageContainer.style.transform = 'translateY(-200px)';
        OldPageContainer.style.transition = 'transform cubic-bezier(0.785, 0.135, 0.150, 0.860) 0.7s, opacity cubic-bezier(0.785, 0.135, 0.150, 0.860) 1.5s';
      }

      setTimeout(() => {
        if (nextPageCallback !== null) {
          nextPageCallback();
          window.scroll(0, 0);
        }
      }, 700);

      setTimeout(() => {
        wrapper.classList.add('hide');
      }, 1000);

      setTimeout(() => {
        wrapper.classList.add('noTrans');
        setTimeout(() => {
          wrapper.classList.add('noTrans');
          wrapper.classList.remove('active');
          wrapper.classList.remove('hide');
          setTimeout(() => {
            wrapper.classList.remove('noTrans');
          }, 50);
        }, 50);
      }, 2000);
    }
  };

  useEffect(() => {
    activeAnimation();
  }, [active]);
  return (
    <Container />
  );
};

export default Transition__controller;
