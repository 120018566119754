import React from 'react';
import styled from 'styled-components';
import { faClock } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Container = styled.div`
  height: 33px;
  background: ${({ color }) => (color ? color : 'transparent')};
  filter: brightness(${({ darken }) => (darken ? '95%' : '100%')});
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border: ${({ border }) => (border ? '1px solid' : 'none')};
  border-color: ${({ border, borderColor }) => (border && borderColor ? borderColor : 'transparent')};
`;

const Text = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: ${({ color }) => (color ? color : 'black')};
  text-align: center;
  text-transform: capitalize;
`;


const Content__tag = ({
  className,
  style,
  content,
  blue,
  red,
  green,
  orange,
  darken,
  color,
  background,
  timeIcon,
  customIcon,
  border,
}) => (
  <Container
    darken={darken}
    border={border}
    style={style}
    className={className}
    color={
      background
        ? background
        : blue
          ? '#E6F8FC'
          : red
            ? '#FDEDEB'
            : orange
              ? '#FFF6EA'
              : green
                ? '#EAFAF1'
                : '#EAFAF1'
    }
    borderColor={
      color
        ? color
        : blue
          ? '#01B5E2'
          : red
            ? '#E74C3C'
            : orange
              ? '#FFAA29'
              : green
                ? '#2ECC71'
                : '#2ECC71'
    }
  >
    {timeIcon ? (
      <FontAwesomeIcon
        icon={faClock}
        style={{ marginRight: 5 }}
        color={
          color
            ? color
            : blue
              ? '#01B5E2'
              : red
                ? '#E74C3C'
                : orange
                  ? '#FFAA29'
                  : green
                    ? '#2ECC71'
                    : '#2ECC71'
        }
      />
    ) : null}
    {customIcon ? (
      <FontAwesomeIcon
        icon={customIcon}
        style={{ marginRight: 5 }}
        color={
          color
            ? color
            : blue
              ? '#01B5E2'
              : red
                ? '#E74C3C'
                : orange
                  ? '#FFAA29'
                  : green
                    ? '#2ECC71'
                    : '#2ECC71'
        }
      />
    ) : null}
    <Text
      color={
        color
          ? color
          : blue
            ? '#01B5E2'
            : red
              ? '#E74C3C'
              : orange
                ? '#FFAA29'
                : green
                  ? '#2ECC71'
                  : '#2ECC71'
      }
    >
      {content}
    </Text>
  </Container>
);

export default Content__tag;
