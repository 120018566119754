/**
 * Import Lib & assets
 */

import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';

/**
 * import components
 */

import { ContentContext } from 'context/contentContext';

/**
 * styled components
 */

const Container = styled.thead`
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  background: #f7f7f7;
  z-index: 2;
  border-radius: 10px;
  th:first-child,
  th:last-child {
    background: #f7f7f7;
    border-radius: 10px;
  }
`;

const Line = styled.tr`
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
`;

const ColItem = styled.th`
  display: table-cell;
  vertical-align: bottom;
  padding-right: 10px;
  padding-left: 10px;
  text-align: left;
  line-break: inherit;
  word-wrap: break-word;
  width: auto;
  padding-bottom: 10px;
  padding-top: 10px;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.9;
`;

const SelectAll = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #01b5e2;
  text-transform: capitalize;
  cursor: pointer;
  white-space: nowrap;
`;

/**
 * header component is..
 */

const List__header = ({
  style, col, handleAllCheck, noSelect, noSelectAll, headerRef, initial,
}) => {
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const { getGlobal } = useContext(ContentContext);
  const handleCheck = (e) => {
    e.preventDefault();
    const newValue = !checkBoxValue;

    setCheckBoxValue(newValue);
    handleAllCheck(newValue);
  };

  useEffect(() => {
    if (initial && Object.keys(initial).length > 0) {
      setCheckBoxValue(true);
    }
  }, [initial]);

  return (
    <Container style={style} ref={headerRef}>
      <Line style={style}>
        {!noSelect && !noSelectAll ? (
          <ColItem number={col.length + 1}>
            <SelectAll onClick={(e) => handleCheck(e)}>
              {!checkBoxValue
                ? getGlobal({ option: 'globalcontent', field: 'selectall' })
                : getGlobal({ option: 'globalcontent', field: 'deselect' })}
            </SelectAll>
          </ColItem>
        ) : null}
        {noSelectAll ? <ColItem number={col.length + 1} /> : null}
        {col.map((item, key) => (
          <ColItem key={key} number={col.length + 1}>
            <span>{item.label}</span>
          </ColItem>
        ))}
      </Line>
    </Container>
  );
};

export default List__header;
