
/**
   * Import Lib & assets
   */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';
import { Content__text } from 'components/content/content';

/**
 * import components
 */
import Form__label from 'components/form/label/form__label';

/**
 * styled components
 */

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
`;

const ContainerChoice = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Btn = styled.a`
  cursor: pointer;
  /* padding: ${(props) => (props.multiple ? '15px 52.5px' : '25px 65px')}; */
  padding: ${(props) => (props.multiple ? '15px 0' : '25px 0')};
  width: 160px;
  border: 1px solid #D8D8D8;
  border-color: ${(props) => (props.active ? '#f36e34' : '#D8D8D8')};
  border-radius: 10px;
  display: inline-block;
  background-color: ${(props) => (props.active ? '#fde2d6' : 'transparent')};
  transition: all ease 0.3s;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-right: 40px;
  margin-bottom: 50px;
  &:last-child {
    margin-right: 0px;
  }
  &:hover {
    border-color: #f36e34;
    transition: all ease 0.3s;
  }
`;

const Tooltips = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
`;


const TooltipsContent = styled.div`
  position: absolute;
  bottom: calc(8px);
  left: calc(100%);
  z-index: 99;
  user-select: none;
  pointer-events: none;
  width: 203px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(60, 60, 60, 0.05);
  background-color: #fafcff;
  padding: 24px 25px;
  opacity: 0;
  transform: translateY(10px) scale(0.95);
  transition: all ${t.ease.slow_fast_slow1} 300ms;
`;

const TooltipsContainer = styled.div`
  width: 21px;
  height: 21px;
  position: absolute;
  top: 15px;
  right: 15px;
  &:hover {
    ${TooltipsContent}{
      opacity: 1;
      transform: translateY(0px) scale(0.95);
      transition: all ${t.ease.slow_fast_slow1} 300ms;
    }
  }
`;


const Form__choice = ({
  style, choices, clickCallBack, multiple, labelStyle, label, btnStyle,
}) => {
  const resetChoice = () => {
    const newObj = choices;
    newObj.forEach((element) => {
      element.active = false;
    });

    return newObj;
  };

  const clickEvent = (e, item, key) => {
    e.preventDefault();
    let newObj = null;

    if (multiple) {
      newObj = choices;
      if (newObj[key].active === true) {
        newObj[key].active = false;
      } else {
        newObj[key].active = true;
      }
    } else {
      newObj = resetChoice();
      newObj[key].active = true;
    }

    if (clickCallBack) return clickCallBack(newObj);
    return true;
  };

  const renderSimple = (item, key) => (
    <Btn
      style={btnStyle}
      multiple={multiple}
      key={key}
      active={item.active}
      onClick={(e) => clickEvent(e, item, key)}
    >
      <Content__text
        style={{ fontSize: 15, fontWeight: 600 }}
        color={item.active ? '#f36e34' : '#B2B2B2'}
        align="center"
      >
        {item.label}
      </Content__text>
    </Btn>
  );

  const renderIcon = (Item, key) => (
    <Btn
      style={btnStyle}
      multiple={multiple}
      key={key}
      active={Item.active}
      onClick={(e) => clickEvent(e, Item, key)}
    >
      <Item.icon color={Item.active ? '#f36e34' : '#818e9c'} width="25px" />
      <Content__text
        style={{ fontSize: 15, fontWeight: 600, marginTop: 15 }}
        color={Item.active ? '#f36e34' : '#B2B2B2'}
        align="center"
      >
        {Item.label}
      </Content__text>
    </Btn>
  );

  const renderSimple_with_toolTips = (item, key) => (
    <Btn
      style={btnStyle}
      multiple={multiple}
      key={key}
      active={item.active}
      onClick={(e) => clickEvent(e, item, key)}
    >
      <Content__text
        style={{ fontSize: 15, fontWeight: 600 }}
        color={item.active ? '#f36e34' : '#B2B2B2'}
        align="center"
      >
        {item.label}
      </Content__text>
      <TooltipsContainer>
        <Tooltips src={t.image.icon.info_grey} />
        <TooltipsContent>
          <Content__text
            type="body1"
            color="#818e9c"
            align="left"
          >
            {item.tooltips}
          </Content__text>
        </TooltipsContent>
      </TooltipsContainer>
    </Btn>
  );

  const renderIcon_with_toolTips = (Item, key) => (
    <Btn
      style={btnStyle}
      multiple={multiple}
      key={key}
      active={Item.active}
      onClick={(e) => clickEvent(e, Item, key)}
    >
      <Item.icon color={Item.active ? '#f36e34' : '#818e9c'} width="25px" />
      <Content__text
        style={{ fontSize: 15, fontWeight: 600, marginTop: 15 }}
        color={Item.active ? '#f36e34' : '#B2B2B2'}
        align="center"
      >
        {Item.label}
      </Content__text>
      <TooltipsContainer>
        <Tooltips src={t.image.icon.info_grey} />
        <TooltipsContent>
          <Content__text
            type="body1"
            color="#818e9c"
            align="left"
          >
            {Item.tooltips}
          </Content__text>
        </TooltipsContent>
      </TooltipsContainer>
    </Btn>
  );

  const renderChoice = (key, item) => {
    if (item.type === 'icon' && item.asTooltips === false) return renderIcon(item, key);
    if (item.type === 'simple' && item.asTooltips === true) return renderSimple_with_toolTips(item, key);
    if (item.type === 'icon' && item.asTooltips === true) return renderIcon_with_toolTips(item, key);

    return renderSimple(item, key);
  };

  return (
    <Container style={style}>
      <Form__label style={labelStyle}>{label}</Form__label>
      <ContainerChoice style={style}>
        {
          choices.map((key, item) => renderChoice(item, key))
        }
      </ContainerChoice>
    </Container>
  );
};

export default Form__choice;
