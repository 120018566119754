import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import t from 'theme';

import { List__table, List__content } from 'components/list/list';
import Button__primary from 'components/button/primary/button__primary';
import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';
import Content__tag from 'components/content/tag/content__tag';
import Cards__serviceMini from 'components/cards/serviceMini/cards__serviceMini';
import Cards__navTab from 'components/cards/navTab/cards__navTab';
import { ROUTING } from 'util/constant/routing.constant';
import { ContentContext } from 'context/contentContext';
import {
  faShieldCheck, faHandshakeAlt, faUserShield, faPlus, faLeaf, faSatelliteDish,
} from '@fortawesome/pro-regular-svg-icons';
import { Grid__separator, Grid__card } from 'components/grid/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Content__servicesTag } from 'components/content/content';
import { sequelizeDate } from 'util/date.util';
import { useHistory } from 'react-router-dom';
import { getActionNeededPaymentGuarantee, getActionNeededWorkshopRepairApproval } from 'helpers/api/routes/getData';
import { formatApiCountRes } from 'util/function/formatApiRes.function';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { AppContext } from 'context/appContext';
import { renderCasesIcon } from 'util/Case';
import {
  strapiAlertGasoilService,
  strapiProFleetAssistService,
  strapiRepairApprovalService,
  strapiService24Service,
  strapiSmartMaitenanceService,
  strapiPaymentGuaranteeService,
  strapiCase,
} from 'util/strapi/content.starpi';

const Container = styled.div``;

const Content_container = styled.div`
  ${t.mixins.flex({
    justify: 'flex-start',
    align: 'flex-start',
  })}
  width: 100%;
  margin-top: 40px;
`;

const Cards__container = styled.div`
  ${t.mixins.flex({
    direction: 'column',
    justify: 'flex-start',
    align: 'flex-start',
    wrap: 'wrap',
  })}
`;

const Illu = styled.img`
  height: 190px;
`;

const Section__title = styled.div`
  font-weight: 600;
  font-size: 25px;
  line-height: 1.4;
  color: #01B5E2;
  margin-bottom: 20px;
`;

const Table__container = styled(Grid__card)`
  width: 100%;
  height: calc(100vh - 150px);
  min-height: 500px;
  text-align: center;
`;

const Table = styled(List__table)`
  margin-top: 60px;
  height: calc(100% - 135px);
`;

const cases__serviceContainer = ({
  data, activeTab,
  setActiveTab,
  loading,
  handleLoadNewPage,
  basePage,
}) => {
  const history = useHistory();
  const { user } = useContext(AppContext);
  const { getFieldGroup, getField } = useContext(ContentContext);
  const [paymentGuaranteeCount, setPaymentGuaranteeCount] = useState(0);
  const [repairApprovalCount, setRepairApprovalCount] = useState(0);
  const haveCounter = (key) => {
    if (data && data.brute) {
      if (data.brute[key] || data.brute[key] === 0) {
        return `${data.brute[key]}`;
      }
    }
    return false;
  };
  const openCounter = haveCounter('openCounter');
  const closedCounter = haveCounter('closeCounter');
  const navItems = [
    {
      label: `${getFieldGroup({ base: basePage, groupe: 'cases_dashboard', field: 'open_case_tab' })} ${openCounter ? `(${openCounter})` : ''}`,
      active: activeTab === 0,
      id: 0,
    },
    {
      label: `${getFieldGroup({ base: basePage, groupe: 'cases_dashboard', field: 'closed_case_tab' })} ${closedCounter ? `(${closedCounter})` : ''}`,
      active: activeTab === 1,
      id: 1,
    },
  ];

  const handleTabChange = (id) => {
    setActiveTab(id);
  };

  const handleCreateCase = () => {
    history.push(ROUTING.workshop.checkVehicle.path);
  };

  const getPaymentGuaranteeNeeded = async () => {
    const res = await getActionNeededPaymentGuarantee({
      filter: null,
      limit: 10,
      page: 1,
      id: user.user.UserWorkshop.id,
    });
    const formated = formatApiCountRes(res);
    setPaymentGuaranteeCount(formated.count);
  };

  const getRepairApprovalNeeded = async () => {
    const res = await getActionNeededWorkshopRepairApproval({
      filter: null,
      limit: 10,
      page: 1,
      id: user.user.UserWorkshop.id,
      userWorkshopid: user.user.id,
    });
    const formated = formatApiCountRes(res);
    setRepairApprovalCount(formated.count);
  };

  const headerCol = [
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_id' }) },
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_vehicle' }) },
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_open_date' }) },
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_services' }) },
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_case_status' }) },
  ];

  const renderList = () => {
    const arrayOfLine = [];
    if (data && data.data) {
      data.data.forEach((element) => {
        arrayOfLine.push([
          {
            component: (<List__content uppercase>{element.uid}</List__content>),
            type: 'node',
          },
          {
            component: (<List__content uppercase>{element.Fleet.registrationNumber}</List__content>),
            type: 'node',
          },
          {
            component: (<List__content>{sequelizeDate({ date: element.caseOpeningDate, format: 'd/m/y' })}</List__content>),
            type: 'node',
          },
          {
            component: (
              <List__content>
                <Content__servicesTag
                  icons={renderCasesIcon(element)}
                />
              </List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <Content__tag
                content={element.status === 'opened' ? getFieldGroup({ base: basePage, groupe: 'case_parameters', field: 'status_open' }) : getFieldGroup({ base: basePage, groupe: 'case_parameters', field: 'status_close' })}
                blue={element.status === 'opened'}
                green={element.status === 'closed'}
              />
            ),
            type: 'node',
          },
        ]);
      });
    }

    return arrayOfLine;
  };

  const handleLineClick = (payload) => {
    history.push(`${ROUTING.workshop.cases.path}/${data.data[payload.key].id}`);
  };

  useEffect(() => {
    getPaymentGuaranteeNeeded();
    getRepairApprovalNeeded();
  }, []);

  return (
    <Container>
      <Content__pageTitle content={getFieldGroup({ base: basePage, groupe: 'cases_dashboard', field: 'title' })} />
      <Content_container>
        <Table__container>
          <Cards__navTab
            items={navItems}
            event={handleTabChange}
          />
          <Table
            headerCol={headerCol}
            lineCol={renderList()}
            lineEvent={handleLineClick}
            noSelect
            scrollEndEvent={handleLoadNewPage}
            emptyComponent={(
              <>
                <Illu src={t.image.illu.onboard5} alt="" />
                <Grid__separator size="25px" />
                <Button__primary
                  withIcon
                  noprevent
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  event={handleCreateCase}
                >
                  {getFieldGroup({ base: basePage, groupe: 'cases_dashboard', field: 'open_case_button' })}
                </Button__primary>
              </>
            )}
          />
          {data && data.data && data.data.length > 0 && (
            <>
              <Grid__separator size="30px" />
              <Button__primary
                withIcon
                noprevent
                icon={<FontAwesomeIcon icon={faPlus} />}
                event={handleCreateCase}
              >
                {getFieldGroup({ base: basePage, groupe: 'cases_dashboard', field: 'open_case_button' })}
              </Button__primary>
            </>
          )}
          {
            loading && (
              <Loader__wrapper />
            )
          }
        </Table__container>
        <Grid__separator width="55px" />
        <Cards__container>
          <Section__title>{getFieldGroup({ base: basePage, groupe: 'cases_dashboard', field: 'service_proposed_label' })}</Section__title>

          <Cards__serviceMini
            icon={faShieldCheck}
            content={getField({ base: strapiPaymentGuaranteeService, field: 'service_title' })}
            url={ROUTING.workshop.dashboardPaymentGuarantee.path}
            notificationCount={paymentGuaranteeCount}
          />
          <Grid__separator size="20px" />
          <Cards__serviceMini
            icon={faHandshakeAlt}
            content={getField({ base: strapiRepairApprovalService, field: 'service_title' })}
            url={ROUTING.workshop.dashboardRepairApproval.path}
            notificationCount={repairApprovalCount}
          />

          <Grid__separator size="30px" />
          <Section__title>{getFieldGroup({ base: basePage, groupe: 'cases_dashboard', field: 'service_proposed_label_2' })}</Section__title>

          <Cards__serviceMini
            icon={faHandshakeAlt}
            small
            content={getField({ base: strapiService24Service, field: 'service_title' })}
            url={ROUTING.workshop.dashboardService24.path}
          />

          {(user.user.UserWorkshop.proFleetAssistSubscription === true || user.user.UserWorkshop.proFleetAssistSubscription === 'true') && (
            <>
              <Grid__separator size="20px" />
              <Cards__serviceMini
                icon={faUserShield}
                small
                content={getField({ base: strapiProFleetAssistService, field: 'service_title' })}
                url={ROUTING.workshop.dashboardProFleetAssist.path}
              />
            </>
          )}

          {(user.user.UserWorkshop.smartMaitenanceSubscription === true || user.user.UserWorkshop.smartMaitenanceSubscription === 'true') && (
            <>
              <Grid__separator size="20px" />
              <Cards__serviceMini
                icon={faSatelliteDish}
                small
                content={getField({ base: strapiSmartMaitenanceService, field: 'service_title' })}
                url={ROUTING.workshop.dashboardSmartMaintenance.path}
              />
            </>
          )}

          {(user.user.UserWorkshop.alertGasoilSubscription === true || user.user.UserWorkshop.alertGasoilSubscription === 'true') && (
            <>
              <Grid__separator size="20px" />
              <Cards__serviceMini
                icon={faLeaf}
                small
                content={getField({ base: strapiAlertGasoilService, field: 'service_title' })}
                url={ROUTING.workshop.dashboardAlertGasoil.path}
              />
            </>
          )}

        </Cards__container>
      </Content_container>
    </Container>
  );
};

export default cases__serviceContainer;
