import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const RouteChangeTracker = () => {
  const location = useLocation();
  const pushLocation = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      if (typeof window.gtag === 'function') {
        /* ============= GA Tracking ============= */
        window.gtag('event', 'page_view', {
          page_title: location.pathname,
          page_location: location.pathname,
          send_to: 'G-RVBKDPLC9Y',
        });
      }
    }
  };

  useEffect(() => {
    pushLocation();
  }, [location]);

  return <div />;
};

export default RouteChangeTracker;
