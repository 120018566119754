
/**
   * Import Lib & assets
   */

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SignaturePad from 'signature_pad';

const Container = styled.div`
  display: inline-block;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;
const CanvasStyled = styled.canvas`
  width: 534px;
  height: 110px;
`;

const Form__sign = ({
  style, width, height, event,
}) => {
  const canvas = useRef(null);
  const [pad, setPad] = useState(null);
  let signaturePad;

  const handleEnd = (padEl) => {
    const data = padEl.toDataURL('image/png');
    if (padEl) {
      if (data) {
        if (event) {
          event(data);
        }
      }
    }
  };

  const initCanvas = () => {
    signaturePad = new SignaturePad(canvas.current, {
      minWidth: 1,
      maxWidth: 1,
      penColor: 'black',
      backgroundColor: 'transparent',
      onBegin: () => {},
      onEnd: () => handleEnd(signaturePad),
    });

    // eslint-disable-next-line no-undef
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.current.width = canvas.current.offsetWidth * ratio;
    canvas.current.height = canvas.current.offsetHeight * ratio;
    canvas.current.getContext('2d').scale(ratio, ratio);
    setPad(signaturePad);
  };

  useEffect(() => {
    if (canvas && pad === null) {
      initCanvas();
    }
  }, [canvas]);

  return (
    <Container style={style}>
      <CanvasStyled ref={canvas} width={width || 534} height={height || 100} />
      <button type="button" onClick={() => pad.clear()}>Clear</button>
    </Container>
  );
};

export default Form__sign;
