import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import t from 'theme';

import { ContentContext } from 'context/contentContext';
import { WorkshopContext } from 'context/fleetUser/workshopContext';
import { ROUTING } from 'util/constant/routing.constant';

import { Grid__separator, Grid__card } from 'components/grid/grid';
import Button__primary from 'components/button/primary/button__primary';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';
import { HomeBase__workshopList } from 'containers/fleetView/homeBase/homeBase';
import Cards__focusCard from 'components/cards/focusCard/cards__focusCard';
import { SendMail } from 'helpers/api/routes/Mail';
import { AppContext } from 'context/appContext';


const Container = styled.div``;

const MainCards = styled(Grid__card)`
  width: 850px;
  padding: 65px;
`;

const CardFocus = styled(Cards__focusCard)`
  padding: 30px;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #4d4d4d;
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', direction: 'column' })};
  b {
    font-weight: bold;
  }
`;
/**
 * notset component is..
 */

const HomeBase__notset = ({ style, basePage }) => {
  const { user, getUser } = useContext(AppContext);
  const { getField, getFieldGroup } = useContext(ContentContext);
  const { setHomeBase } = useContext(WorkshopContext);
  const [popUpHomeBase, setPopUpHomeBase] = useState(false);
  const [popUpHomeBaseSave, setPopUpHomeBaseSave] = useState(false);
  const [loadingSetHomeBase, setLoadingSetHomeBase] = useState(false);


  const saveHomeBase = async ({ userWorkshop }) => {
    setLoadingSetHomeBase(true);
    const res = await setHomeBase({ id: userWorkshop.UserWorkshop.id });
    if (res.success === true) {
      const resUserWorkshop = await getUser();
      if (resUserWorkshop) {
        setLoadingSetHomeBase(false);
      }
      if (resUserWorkshop.success === true) {
        setPopUpHomeBaseSave(true);
        if (!userWorkshop.notificationDisable) {
          SendMail({
            template: 'notification',
            templateNotification: {
              status: 'action',
              statusLabel: getFieldGroup({
                select_lang: userWorkshop.lang, base: basePage, groupe: 'mail_to_workshop', field: 'status',
              }),
              content: `${user.user.companyName} ${getFieldGroup({
                select_lang: userWorkshop.lang, base: basePage, groupe: 'mail_to_workshop', field: 'content',
              })}`,
              btnContent: getFieldGroup({
                select_lang: userWorkshop.lang, base: basePage, groupe: 'mail_to_workshop', field: 'btn',
              }),
              btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.workshop.myFleets.path}?userWorkshop=${userWorkshop.id}`,
            },
            subject: getFieldGroup({
              select_lang: userWorkshop.lang, base: basePage, groupe: 'mail_to_workshop', field: 'btn',
            }),
            destination: userWorkshop.email,
          });
        }
      }
    }
  };

  const handleCloseSave = () => {
    setPopUpHomeBase(false);
    setPopUpHomeBaseSave(false);
  };

  return (
    <div style={{ display: 'flex', width: '100%', flex: 1 }}>
      <MainCards>
        <Container style={style}>
          <Content__cardTitle content={getField({ base: basePage, field: 'no_workshop_title' })} />
          <Grid__separator size="30px" />
          <CardFocus>
            {getField({ base: basePage, field: 'no_workshop_text1' })}
            <br />
            <br />
            {getField({ base: basePage, field: 'no_workshop_text2' })}
            <br />
            <br />
            <b>{getField({ base: basePage, field: 'no_workshop_text3' })}</b>
            <Grid__separator size="25px" />
            <Button__primary event={() => setPopUpHomeBase(true)}>
              {getField({ base: basePage, field: 'no_workshop_button' })}
            </Button__primary>
          </CardFocus>
          <Grid__separator size="20px" />
        </Container>
      </MainCards>
      <HomeBase__workshopList
        sucessScreen={popUpHomeBaseSave}
        activePop={popUpHomeBase}
        closeEvent={() => setPopUpHomeBase(false)}
        closeEventSave={() => handleCloseSave()}
        saveEvent={(payload) => saveHomeBase({ userWorkshop: payload })}
        basePage={basePage}
        loading={loadingSetHomeBase}
      />
    </div>
  );
};

export default HomeBase__notset;
