import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from 'context/appContext';
import { ContentContext } from 'context/contentContext';
import t from 'theme';
import Cards__serviceCard from 'components/cards/serviceCard/cards__serviceCard';
import SingleAddService from 'containers/fleetView/addServices/singleAddService/addServices__singleAddService';
import {
  faListOl, faQuestion, faBookOpen, faShoppingCart,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserHeadset } from '@fortawesome/pro-regular-svg-icons';
import { formatPrice, isFree } from 'util/format/price.format';
import {
  strapiService24Service,
  strapiAddServicePage,
} from 'util/strapi/content.starpi';
import { RegisterFleetToService } from 'helpers/api/routes/Service24';
import { formatApiRes } from 'util/function/formatApiRes.function';
import Message__sucess from 'components/message/sucess/message__sucess';
import { Grid__popup } from 'components/grid/grid';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { useHistory } from 'react-router-dom';
import { ROUTING } from 'util/constant/routing.constant';
import marked from 'marked';


const Container = styled.div``;

const SucessPop = styled(Grid__popup)`
  display: flex;
  justify-content:center;
  align-items:center;
`;

const Service = ({ baseSingleService }) => {
  const { getField } = useContext(ContentContext);
  const { getUser } = useContext(AppContext);
  const [popUpActive, setPopUpActive] = useState(false);
  const [successPop, setsuccessPop] = useState(false);
  const [seletedVehicle, setseletedVehicle] = useState([]);
  const [totalPrice, settotalPrice] = useState(0);
  const [loading, setloading] = useState(false);
  const [loadingUser, setloadingUser] = useState(false);
  const [condition, setcondition] = useState(false);
  const [formError, setformError] = useState({ active: false, message: '' });
  const history = useHistory();
  const price = getField({ base: strapiService24Service, field: 'price' });

  const handleSelectLine = (value) => {
    const total = price * value.length;
    setseletedVehicle(value);
    settotalPrice(total);
  };

  const perfomSave = async () => {
    setloading(true);
    const data = {
      data: [],
    };

    seletedVehicle.forEach((vehicle) => {
      data.data.push({
        id: vehicle.id,
        subscription: true,
      });
    });

    if (data.data.length > 0) {
      const registerFleetRes = await RegisterFleetToService({
        fleetObj: data,
      });
      if (registerFleetRes) {
        setloading(false);
        if (formatApiRes(registerFleetRes).success === true) {
          setPopUpActive(false);
          setloading(false);
          setsuccessPop(true);
        }
      } else {
        setloading(false);
      }
    } else {
      setloading(false);
    }
  };


  const checkError = () => {
    if (seletedVehicle.length <= 0) {
      setformError({ active: true, message: 'vehicle' });
      return false;
    }
    if (!condition) {
      setformError({ active: true, message: 'cgv' });
      return false;
    }

    return true;
  };

  const handleSave = () => {
    if (checkError()) {
      if (condition) {
        perfomSave();
      }
    }
  };

  const handleCloseSucess = async () => {
    setloadingUser(true);
    const resUser = await getUser();
    if (resUser.success === true) {
      setloadingUser(false);
      setsuccessPop(false);
    }
  };

  const handleDashboardSucess = async () => {
    setloadingUser(true);
    const resUser = await getUser();
    if (resUser.success === true) {
      setloadingUser(false);
      setsuccessPop(false);
      history.push(ROUTING.fleet.dashboardService24.path);
    }
  };

  const handleCgv = (value) => {
    if (value) {
      setcondition(value.value);
    }
  };

  const renderPaymentGuarantee__pop = () => (
    <SingleAddService
      handleSelectLine={handleSelectLine}
      popupActive={popUpActive}
      handleSave={handleSave}
      closeEvent={() => setPopUpActive(false)}
      loading={loading}
      handleCgv={handleCgv}
      cgv={condition}
      cgvPath={ROUTING.fleet.conditionService24.path}
      formError={formError}
      intro={{
        title: getField({ base: strapiService24Service, field: 'service_title' }),
        txt: <div dangerouslySetInnerHTML={{ __html: marked(getField({ base: strapiService24Service, field: 'description' })) }} />,
        label: getField({ base: baseSingleService, field: 'price_detail' }),
        banner: t.image.global.service24,
      }}
      condition1={{
        content: <div dangerouslySetInnerHTML={{ __html: marked(getField({ base: strapiService24Service, field: 'registration_processs' })) }} />,
        title: getField({ base: baseSingleService, field: 'registration_processs' }),
        icon: faListOl,
      }}
      condition2={{
        content: <div dangerouslySetInnerHTML={{ __html: marked(getField({ base: strapiService24Service, field: 'how' })) }} />,
        title: getField({ base: baseSingleService, field: 'how' }),
        icon: faQuestion,
      }}
      condition3={{
        content: <div dangerouslySetInnerHTML={{ __html: marked(getField({ base: strapiService24Service, field: 'conditions' })) }} />,
        title: getField({ base: baseSingleService, field: 'conditions' }),
        icon: faBookOpen,
      }}
      condition4={{
        content: <div dangerouslySetInnerHTML={{ __html: marked(getField({ base: strapiService24Service, field: 'payment' })) }} />,
        title: getField({ base: baseSingleService, field: 'payment' }),
        icon: faShoppingCart,
      }}
      list={{
        title: getField({ base: baseSingleService, field: 'title_for_list' }),
      }}
      rate={{
        button: getField({ base: baseSingleService, field: 'save_button' }),
      }}
      customCGV={getField({ base: strapiService24Service, field: 'subscription_checkbox_content' })}
    />
  );

  const renderCardsPrice = () => {
    const free = isFree(price);
    if (free) {
      return getField({ base: strapiAddServicePage, field: 'free' });
    }

    return `${formatPrice(price)} ${getField({ base: baseSingleService, field: 'price_detail' })}`;
  };

  return (
    <Container>
      {renderPaymentGuarantee__pop()}
      <SucessPop active={successPop} closeEvent={handleCloseSucess}>
        <Message__sucess
          icon={t.image.illu.check}
          title={getField({ base: strapiAddServicePage, field: 'success_popup_title' })}
          text={getField({ base: strapiAddServicePage, field: 'success_popup_text' })}
          buttonText={getField({ base: strapiAddServicePage, field: 'success_popup_button' })}
          buttonEvent={handleDashboardSucess}
        />
        {loadingUser ? <Loader__wrapper /> : false}
      </SucessPop>
      <Cards__serviceCard
        event={() => setPopUpActive(true)}
        imageUrl={t.image.global.service24}
        icon={<FontAwesomeIcon icon={faUserHeadset} color="#4D4D4D" />}
        title={getField({ base: strapiService24Service, field: 'service_title' })}
        description={getField({ base: strapiService24Service, field: 'short_description' })}
        price={renderCardsPrice()}
        btnText={getField({ base: strapiAddServicePage, field: 'more_details_button' })}
      />
    </Container>
  );
};

export default Service;
