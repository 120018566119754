/* eslint-disable */
const formatId = (id) => {
  const charsetArray = [
    ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'],
    ['i', 'j', 'k', 'l', 'm', 'n', 'o', 'p'],
    ['q', 'r', 's', 't', 'u', 'v', 'w', 'x'],
  ];
  let newString = '';
  Array.from(id).forEach((element) => {
    const random = Math.floor(Math.random() * Math.floor(4));

    if (random === 0) {
      newString += charsetArray[0][parseInt(element)];
    } else if (random === 1) {
      newString += charsetArray[1][parseInt(element)];
    } else if (random === 2) {
      newString += charsetArray[2][parseInt(element)];
    } else if (random === 3) {
      newString += element;
    }
  });

  return newString;
};

const generateURL = () => {
  let d = new Date().getTime();
  return 'xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 9;
    r = (d + r) % 9 | 0;
    d = Math.floor(d / 9);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(8);
  });
};

export const createRandomUrl = () => {
  const numberId = generateURL();
  const result = formatId(numberId);

  return result;
};
