import { API_URL } from '../Utile';
import { CallGet, CallPost } from '../Helpers';

/* ---------------------------- GET ALL WORKSHOP ---------------------------- */

export const GetCasesHistoric = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}cases/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const GetSingleCase = async (id) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}cases/${id}`;

  const res = await CallGet(url, {}, headers);

  return res;
};

export const CreateCase = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}cases/create/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const asOpenedCase = async (vehicleId) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}cases/asOpenedCase/${vehicleId}`;

  const res = await CallGet(url, {}, headers);

  return res;
};

export const CloseCase = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}cases/close/`;

  const res = await CallPost(url, params, headers);

  return res;
};


export const createCaseFile = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const formData = new FormData();

  formData.append('file', payload.file.file);
  formData.append('serviceUid', payload.serviceUid);
  formData.append('fileId', payload.fileId);
  formData.append('extension', payload.extension);

  const url = `${API_URL}cases/file`;

  const res = await CallPost(url, formData, headers);

  return res;
};
