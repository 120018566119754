import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import { APPOINTMENT_STATUS } from '../constant/Appointment_status';

export const parseStatus = ({ status, time, hand }) => {
  if (status === 'done') {
    return {
      green: true,
    };
  }

  if (status === 'waiting') {
    if (time) {
      return {
        orange: true,
        timeIcon: true,
      };
    }
    return {
      orange: true,
    };
  }

  if (status === 'error') {
    return {
      red: true,
    };
  }

  if (status === 'cancel') {
    return {
      red: true,
    };
  }

  if (status === 'action') {
    if (hand) {
      return {
        blue: true,
        handIcon: true,
      };
    }
    return {
      blue: true,
    };
  }

  return false;
};

export const renderPriority90FleetStatus = (status) => {
  if (status && status === SERVICE_STATUS.error) {
    return SERVICE_STATUS.waiting;
  }
  return status;
};

export const parseAppointmentStatus = ({ status, icon }) => {
  if (status === APPOINTMENT_STATUS.done) {
    return {
      green: true,
    };
  }

  if (status === APPOINTMENT_STATUS.waiting) {
    if (icon) {
      return {
        orange: true,
        timeIcon: true,
      };
    }
    return {
      orange: true,
    };
  }

  if (status === APPOINTMENT_STATUS.refused) {
    return {
      red: true,
    };
  }

  if (status === APPOINTMENT_STATUS.cancel) {
    return {
      red: true,
    };
  }

  if (status === APPOINTMENT_STATUS.reschedule) {
    if (icon) {
      return {
        orange: true,
        timeIcon: true,
      };
    }
    return {
        orange: true,
    };
  }

  if (status === APPOINTMENT_STATUS.approved) {
    if (icon) {
      return {
        blue: true,
        handIcon: true,
      };
    }
    return {
      blue: true,
    };
  }

  return false;
};
