
/**
 * Import Lib & assets
 */

import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import t from 'theme';

/**
 * import components
 */

import { Content__text } from 'components/content/content';
import { Grid__separator } from 'components/grid/grid';
import Button__primary from 'components/button/primary/button__primary';
import { ContentContext } from 'context/contentContext';
import Cards__serviceMini from '../../cards/serviceMini/cards__serviceMini';


/**
 * styled components
 */

const Container = styled.div`
  text-align: center;
  width: 100%;
  ${t.mixins.flex({ direction: 'column' })};
  flex: 1;
`;

const Content = styled.div`
  width: 600px;
  max-width: 100%;
  text-align: center;
`;

const IconComponents = styled.img`
  width: 200px;
  height: auto;
`;

const TitleComponents = styled(Content__text)`
  font-weight: 600;
  font-size: 26px;
  line-height: 1.5;
  text-align: center;
  color: #333333;
`;

const TextComponents = styled(Content__text)`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #4D4D4D;
  display: block;
`;

const NextServ = styled.div`
  background: rgba(1, 181, 226, 0.1);
  padding: 20px 35px 25px 35px;
  border-radius: 20px;
  margin-top: 45px;
`;

const NextServ__title = styled.div`
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  color: #01B5E2;
  margin-bottom: 20px;
`;

const NextServ__container = styled.div`
  display: flex;
  justify-content: center;
`;


/**
 * sucess component is..
 */

const Message__sucess = ({
  style, className, icon, title, text, buttonText, buttonEvent, btnRed, hideButton, nextServices, buttonNode, infoNode, buttonSecondary,
}) => {
  const { getGlobal } = useContext(ContentContext);
  return (
    <Container style={style} className={className}>
      <Content>
        <IconComponents src={icon} />
        <Grid__separator size="15px" />
        <TitleComponents type="h3">
          {title}
        </TitleComponents>
        <Grid__separator size="15px" />
        <TextComponents type="body2">
          {text}
        </TextComponents>
        {
          infoNode && (infoNode)
        }
        {
          !hideButton && (
            <Fragment>
              <Grid__separator size="25px" />
              <Button__primary border={nextServices || buttonSecondary ? true : false} blue={btnRed ? false : true} red={btnRed ? true : false} event={() => buttonEvent()}>
                {buttonText}
              </Button__primary>
            </Fragment>
          )
        }
        {buttonNode && (
          <Fragment>
            <Grid__separator size="10px" />
            {buttonNode}
          </Fragment>
        )}
      </Content>
      {nextServices && nextServices.length > 0 ? (
        <NextServ>
          <NextServ__title>{getGlobal({ option: 'globalcontent', field: 'quick_launch_title' })}</NextServ__title>
          <NextServ__container>
            {nextServices.map((item, key) => (
              <Cards__serviceMini
                blue
                key={key}
                icon={item.icon}
                content={item.title}
                url={item.link}
              />
            ))}
          </NextServ__container>
        </NextServ>
      ) : null}
    </Container>
  );
};

export default Message__sucess;
