/* eslint-disable no-useless-escape */
import { useContext } from 'react';
import { ContentContext } from 'context/contentContext';
import jschardet from 'jschardet';
import moment from 'moment';
import XlsxFile from './UseXls';

export const UseParseFile = (onCallback) => {
  const {
    getGlobal, getGlobalObj,
  } = useContext(ContentContext);


  const slugify = (string) => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '') // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  };

  const getKeyFromContent = ({ content }) => {
    const keyObject = Object.keys(getGlobalObj({ option: 'vehicleParameter' }));
    let groupValue = null;
    keyObject.forEach((element) => {
      const optionParams = getGlobal({ option: 'vehicleParameter', field: element });
      if (slugify(content) === slugify(optionParams)) {
        groupValue = element;
      }
    });

    return groupValue;
  };

  const parseCsv = (content) => {
    const lines = content.split('\n');
    const result = [];
    let hasHeaderError = false;
    let separator = '|';

    if (lines[0].toString().includes(';')) {
      separator = ';';
    } else if (lines[0].toString().includes(',')) {
      separator = ',';
    }

    const headers = lines[0].split(separator);
    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentline = lines[i].split(separator);
      let hasOnlyEmpty = true;
      for (let j = 0; j < headers.length; j++) {
        if (currentline[j] !== '' && currentline[j]) {
          hasOnlyEmpty = false;
        }
        const headerContent = getKeyFromContent({ content: headers[j] });
        if (headerContent) {
          obj[headerContent] = currentline[j];
        } else {
          hasHeaderError = true;
        }
      }

      if (!hasOnlyEmpty && !hasHeaderError) {
        result.push(obj);
      }
    }

    if (hasHeaderError) {
      result.push('error');
    }

    return result;
  };

  const handleFileLoad = (event) => {
    const content = event.target.result;
    const json = parseCsv(content);

    /* -------------------------------- CALLBACK -------------------------------- */
    onCallback({ json });
  };

  const parseCsvFile = ({ file }) => {
    const reader2 = new FileReader();
    reader2.readAsBinaryString(file[0]);
    let loaded = false;
    reader2.onload = () => {
      if (!loaded) {
        const reader = new FileReader();
        loaded = true;
        const encoding = jschardet.detect(reader2.result);
        reader.onload = handleFileLoad;
        reader.readAsText(file[0], encoding.encoding);
      }
    };
  };

  const parseFiles = ({ file }) => {
    if (file && file[0] && file[0].type === 'text/csv') {
      parseCsvFile({ file });
    } else {
      const xlsxReader = new XlsxFile(file[0]);
      xlsxReader.start().then((result) => {
        const json = parseCsv(result);
        const checkDate = [];
        if (json.length > 0) {
          json.forEach((element) => {
            if (element.firstregistration) {
              const ddmmyy = moment(element.firstregistration, 'DD/MM/YYYY', true);
              const mmddyy = moment(element.firstregistration, 'M/D/YY', true);
              if (ddmmyy && ddmmyy.isValid()) {
                checkDate.push({
                  ...element,
                });
              } else if (mmddyy && mmddyy.isValid()) {
                checkDate.push({
                  ...element,
                  firstregistration: mmddyy.format('DD/MM/YYYY'),
                });
              } else {
                checkDate.push({
                  ...element,
                });
              }
            }
          });
        }
        onCallback({ json: checkDate });
      }).catch(() => {
        //
      });
    }
  };

  return [parseFiles];
};
