/* ------------------------------- Get percent ------------------------------ */

export const getPercent = ({ total, number }) => {
  const totalValue = parseInt(total, 10);
  const numberValue = parseInt(number, 10);
  if (Number.isNaN(totalValue) || Number.isNaN(numberValue)) {
    return 0;
  }

  if (number === 0 && total === 0) {
    return 0;
  }

  return Math.round((numberValue / totalValue) * 100);
};
