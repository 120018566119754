import React, { createContext } from 'react';
import { GetAllWorkshop as GetAllWorkshopApi, setHomeBase as setHomeBaseApi } from 'helpers/api/Routes';

/**
 * initial state
 */
export const WorkshopContext = createContext({
  workshop: null,
  initial: false,
  error: null,
  getAllWorkshop: () => {},
  setHomeBase: () => {},
});

/**
 * Provider
 */

export class WorkshopProvider extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    workshop: null,
    initial: false,
    error: null,
    getAllWorkshop: (value) => this.getAllWorkshop(value),
    setHomeBase: (value) => this.setHomeBase(value),
  };

  getAllWorkshop = async () => {
    const res = await GetAllWorkshopApi();
    if (res.hasOwnProperty('data')) {
      if (res.data.success === true) {
        this.setState((state) => ({ ...state, workshop: res.data.data, initial: true }));
        return 'sucess';
      }
      this.setState((state) => ({ ...state, error: { type: 'getAllWorkshop', error: res.data.message } }));
      return 'error';
    }
    this.setState((state) => ({ ...state, error: { type: 'getAllWorkshop', error: 'error' } }));
    return 'error';
  };

  setHomeBase = async ({ id }) => {
    const res = await setHomeBaseApi({ id });
    if (res.hasOwnProperty('data')) {
      if (res.data.success === true) {
        return {
          success: true,
        };
      }
      this.setState((state) => ({ ...state, error: { type: 'setHomeBase', error: res.data.message } }));
      return {
        success: false,
      };
    }
    this.setState((state) => ({ ...state, error: { type: 'setHomeBase', error: 'error' } }));
    return {
      success: false,
    };
  };

  render() {
    const { children } = this.props;
    return (
      <WorkshopContext.Provider value={this.state}>
        {children}
      </WorkshopContext.Provider>
    );
  }
}

/**
 * consumer
 */

export const WorkshopConsumer = WorkshopContext.Consumer;
