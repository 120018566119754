/**
 * Import Lib & assets
 */

import React, { Fragment } from 'react';
import styled from 'styled-components';
import t from 'theme';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid__card } from 'components/grid/grid';
import Content__titleIconService from 'components/content/titleIconService/content__titleIconService';
import Content__progressBar from 'components/content/progressBar/content__progressBar';
import Content__smallCounter from 'components/content/smallCounter/content__smallCounter';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Loader__small from 'components/loader/small/loader__small';

const LinkStyled = styled(Link)`
  text-decoration: none;
  transition: .3s ease all;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.06);
  }
`;

const ProgressContainer = styled.div`
  padding-right: 80px;
  padding-right: ${(props) => (props.paddingSmall ? '35px' : '80px')};
  width: 100%;
`;

const ValueContainer = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'center' })};
  margin-top: ${(props) => (props.content === undefined ? '0' : '20px')};
`;

const ButtonContainer = styled.a`
  margin-top: 15px;
  ${t.mixins.flex({ justify: 'flex-end' })};
  margin-bottom: -10px;
  text-decoration: none;
`;

const LoadingCards = styled(Grid__card)`
  height: 124px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Cards__serviceCoverage = ({
  style,
  className,
  icon,
  progress,
  value,
  title,
  labelProgress,
  labelCounter,
  content,
  button,
  link,
  to,
  paddingSmall,
  hover,
  loading,
}) => {
  const renderContent = () => (
    <Grid__card hover={hover} style={style} className={className} padding="25px">
      {content === undefined ? (
        <Content__titleIconService
          icon={icon && (<FontAwesomeIcon icon={icon} color="#4D4D4D" />)}
          content={title}
        />
      ) : null}
      <ValueContainer content={title}>
        <ProgressContainer paddingSmall={paddingSmall}>
          <Content__progressBar progress={progress} label={labelProgress} />
        </ProgressContainer>
        <Content__smallCounter value={value} label={labelCounter} />
      </ValueContainer>
      {button !== undefined ? <ButtonContainer href="#" to={to}>{button}</ButtonContainer> : null}
    </Grid__card>
  );

  return (

    !loading ? (
      link && to ? (
        <LinkStyled to={to}>
          {renderContent()}
        </LinkStyled>
      ) : (
        <Fragment>
          {renderContent()}
        </Fragment>
      )
    ) : (
      <LoadingCards hover={hover} style={style} className={className} padding="25px">
        <Loader__small />
      </LoadingCards>
    )
  );
};

export default Cards__serviceCoverage;
