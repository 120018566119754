
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  checkFieldValue,
  createArrayOfData,
  createResponseObj,
} from 'helpers/js/Form';
import { slugify } from 'helpers/js/Utile';
/**
 * import containers & components
 */
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';

import Button__primary from 'components/button/primary/button__primary';
import { Grid__separator } from 'components/grid/grid';
import { Content__sectionTitle } from 'components/content/content';
import {
  Form__textfield,
} from 'components/form/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse } from '@fortawesome/pro-solid-svg-icons';
import { faSave } from '@fortawesome/pro-regular-svg-icons';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import Form__map from 'components/form/map/form__map';

/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const FormContainer = styled.div``;

/**
 * information component is..
 */

const Account__companyInformation = ({ style, basePage }) => {
  const { getField, getGlobalObj, getGlobal } = useContext(ContentContext);
  const { user, updateUser } = useContext(AppContext);
  const [activeButton, setActiveButton] = useState(false);
  const [loading, setloading] = useState(false);

  /* ------------------------------ DEFINE STATE ------------------------------ */

  const [countrySelect, setCountrySelect] = useState([]);

  const [state, setState] = useState({
    name: { value: '' },
    vat: { value: '' },
    zip: { value: '' },
    city: { value: '' },
    adress: { value: '' },
    address: { value: '', result: '' },
    country: countrySelect,
    result: {
      name: { result: '', type: 'text' },
      vat: { result: '', type: 'text' },
      zip: { result: '', type: 'text' },
      city: { result: '', type: 'text' },
      adress: { result: '', type: 'text' },
      address: { value: '', result: '' },
      country: { result: countrySelect[0], type: 'select' },
    },
  });

  /* ---------------------------- SET DATA FROM API --------------------------- */

  const setinitialDate = () => {
    setState((prevState) => ({
      ...prevState,
      name: checkFieldValue({ field: user.user.companyName, type: 'text' }).base,
      vat: checkFieldValue({ field: user.user.vat, type: 'text' }).base,
      zip: checkFieldValue({ field: user.user.zipcode, type: 'text' }).base,
      city: checkFieldValue({ field: user.user.city, type: 'text' }).base,
      adress: checkFieldValue({ field: user.user.adress, type: 'text' }).base,
      address: { value: `${user.user.adress}, ${user.user.zipcode} ${user.user.city}` },
      country: checkFieldValue({
        field: user.user.region,
        type: 'select',
        additionalCondition: 'slug',
        base: countrySelect,
      }).base,
      result: {
        ...prevState.result,
        name: checkFieldValue({ field: user.user.companyName, type: 'text' }).result,
        vat: checkFieldValue({ field: user.user.vat, type: 'text' }).result,
        zip: checkFieldValue({ field: user.user.zipcode, type: 'text' }).result,
        city: checkFieldValue({ field: user.user.city, type: 'text' }).result,
        adress: checkFieldValue({ field: user.user.adress, type: 'text' }).result,
        country: checkFieldValue({
          field: user.user.region,
          type: 'select',
          additionalCondition: 'slug',
          base: countrySelect,
        }).result,
      },
    }));
  };

  /* --------------------- USE EFFECT FORM POPULATE STATE --------------------- */

  useEffect(() => {
    const keyObject = Object.keys(getGlobalObj({ option: 'country' }));
    const selectArray = [];

    keyObject.forEach((element) => {
      const obj = {
        label: getGlobal({ option: 'country', field: element }),
        slug: slugify(element),
        active: false,
      };

      selectArray.push(obj);
    });

    selectArray[0].active = true;
    setCountrySelect(selectArray);

    setState((prevState) => ({
      ...prevState,
      country: selectArray,
      result: {
        ...prevState.result,
        country: { result: selectArray[0], type: 'select' },
      },
    }));
  }, []);

  useEffect(() => {
    if (countrySelect.length > 0) {
      setinitialDate();
    }
  }, [countrySelect]);

  /* -------------------------- CHECK STATUS OF FIELD ------------------------- */

  const checkSaveStatus = () => {
    const arrayOfField = [
      state.result.name,
      state.result.vat,
      state.result.zip,
      state.result.city,
      state.result.country,
      state.result.adress,
    ];

    const data = createArrayOfData({ array: arrayOfField });

    if (data.filter((el) => el === null || el === '').length <= 0) {
      setActiveButton(true);
    } else if (activeButton) {
      setActiveButton(false);
    }
  };

  useEffect(() => {
    if (countrySelect.length > 0) {
      checkSaveStatus();
    }
  }, [state]);

  /* --------------------------- STATE CHANGE EVENT --------------------------- */

  const handleStateChange = ({
    property, value, result, type,
  }) => {
    const currentState = state;

    currentState.result[property] = { result, type };

    setState((prevState) => ({
      ...prevState,
      [property]: type === 'text' ? { value } : value,
      result: {
        ...prevState.result,
        [property]: { result, type },
      },
    }));
  };

  const handleMapField = ({
    property, formated, place, coord, id, address,
  }) => {
    setState((prevState) => ({
      ...prevState,
      [property]: {
        value: place.description || '',
        result: formated,
        coords: coord,
        id,
      },
    }));
  };

  /* ------------------------------- SAVE EVENT ------------------------------- */

  const saveEvent = async () => {
    setloading(true);
    const addressField = state.address.result;
    const region = addressField ? addressField.country || '' : '';
    const city = addressField ? addressField.city || '' : '';
    const zipcode = addressField ? addressField.zipCode || '' : '';
    const adress = addressField ? addressField.description || `${addressField.number || ''} ${addressField.address || ''}` || '' : '';
    const { coords } = state.address;
    const regionCode = addressField ? addressField.countryCode || '' : '';

    const arrayOfField = [
      { value: state.result.name, key: 'companyName' },
      { value: state.result.vat, key: 'vat' },
      { value: { result: zipcode, type: 'text' }, key: 'zipcode' },
      { value: { result: regionCode.toLowerCase(), type: 'text' }, key: 'countryCode' },
      { value: { result: coords ? coords.lat : '', type: 'text' }, key: 'lat' },
      { value: { result: coords ? coords.lng : '', type: 'text' }, key: 'lng' },
      { value: { result: city, type: 'text' }, key: 'city' },
      { value: { result: region, type: 'text' }, key: 'region' },
      { value: { result: adress, type: 'text' }, key: 'adress' },
    ];

    const data = createResponseObj({ data: arrayOfField });

    await updateUser({ field: data });
    setloading(false);
  };

  /* ------------------------------- RENDER FORM ------------------------------ */

  const renderContactInformationForm = () => (
    <FormContainer>
      <Form__textfield
        change={(res) => handleStateChange({
          property: 'name',
          value: res.value,
          result: res.value,
          type: 'text',
        })}
        value={state.name.value}
        property="name"
        label={`${getField({ base: basePage, field: 'company_name' })}`}
        placeholder={getField({ base: basePage, field: 'company_name' })}
        require
      />
      <Grid__separator size="25px" />
      <Form__textfield
        change={(res) => handleStateChange({
          property: 'vat',
          value: res.value,
          result: res.value,
          type: 'text',
        })}
        value={state.vat.value}
        property="vat"
        label={`${getField({ base: basePage, field: 'vat' })}`}
        placeholder={getField({ base: basePage, field: 'vat' })}
        require
      />
      <Grid__separator size="25px" />
      <Form__map
        event={({
          formated, place, coord, id, address,
        }) => handleMapField({
          property: 'address',
          formated,
          place,
          type: 'text',
          coord,
          id,
          address,
        })}
        value={state.address.value || null}
        property="address"
        label={getGlobal({ option: 'workshopInformation', field: 'address' })}
        style={{ maxWidth: '100%' }}
        require
      />
      <Grid__separator size="25px" />
      <Button__primary
        withIcon
        icon={<FontAwesomeIcon icon={faSave} />}
        event={() => saveEvent()}
        style={
          activeButton === false
            ? {
              cursor: 'intial',
              pointerEvents: 'none',
              userSelect: 'none',
              opacity: 0.3,
            }
            : {}
        }
      >
        {getField({ base: basePage, field: 'save_button_company' })}
      </Button__primary>
    </FormContainer>
  );

  /* ------------------------------ GLOBAL RENDER ----------------------------- */

  return (
    <Container style={style}>
      <Content__sectionTitle
        title={getField({ base: basePage, field: 'company_title' })}
        icon={<FontAwesomeIcon icon={faWarehouse} />}
      />
      {renderContactInformationForm()}
      {loading && (<Loader__wrapper fixedContainer fullWidth />)}
    </Container>
  );
};

export default Account__companyInformation;
