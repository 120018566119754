
/**
   * Import Lib
   */

import React, {
  Fragment, useContext, useState, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import { List__table, List__content } from 'components/list/list';
import { SendMail } from 'helpers/api/routes/Mail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/pro-regular-svg-icons';
import Content__labelText from 'components/content/labelText/content__labelText';
import Content__tag from 'components/content/tag/content__tag';
import { strapiServiceHeader, strapiPaymentGuaranteeService, strapiWorkshopDashboardPaymentGuarantee } from 'util/strapi/content.starpi';
import { ContentContext } from 'context/contentContext';
import { orderObligoHistoric } from 'util/arrayMutation.util';
import Grid__separator from 'components/grid/separator/grid__separator';
import Cards__focusCard from 'components/cards/focusCard/cards__focusCard';
import Button__primary from 'components/button/primary/button__primary';
import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import { AppContext } from 'context/appContext';
import { Form__textfield } from 'components/form/form';
import Content__serviceStatus from 'components/content/serviceStatus/content__serviceStatus';

import Message__sucess from 'components/message/sucess/message__sucess';
import { parseStatus } from 'util/conditions/statusProps.util';
import { sequelizeDate, sequelizeDateWithOneMonths, sequelizeDateTime } from 'util/date.util';
import { formatPrice } from 'util/format/price.format';
import { OBLIGO } from 'util/constant/obligo.constant';
import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import { faUserCheck, faListUl } from '@fortawesome/pro-solid-svg-icons';
import { hasFieldError } from 'util/conditions/haveErrorField.conditions';
import { setAction } from 'util/function/reloadAction.function';

import DashboardPaymentGuarantee__singlePop from 'containers/workshopView/dashboardPaymentGuarantee/singlePop/dashboardPaymentGuarantee__singlePop';
import { revokeObligo } from 'helpers/api/routes/Workshop';
import { calculateRemain } from 'util/function/getRemainingPriceObligo';
import { getPaymentGuaranteeStatus } from 'util/service/paymentGuarenteeStatus';
import { workshopHavePaymentguarantee } from 'util/conditions/workshopHaveService';
import {
  Title,
  Popup,
} from 'containers/workshopView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import { updatefleetUserInformation } from 'helpers/api/routes/User';
import Contract__pdf from 'components/contract/pdf/contract__pdf';
import { slugify } from 'helpers/js/Utile';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { createRandomUrl } from '../../../../util/randomUrl.util';


const InfoHeader = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #4D4D4D;
  max-width: 700px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const RowLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const Subtitle = styled.div`
  font-size: 15px;
  margin-top: 10px;
`;

const Button = styled.div`
  position: absolute;
  right: 45px;
  bottom: 45px;
`;

const ServiceStatusTitle = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
  text-align: left;
`;

const RefusedCard = styled(Cards__focusCard)`
  text-align: center;
  max-width: 700px;
`;

const RefusedCardContent = styled.div`
  line-height: 1.5;
  color: #E74C3C;
`;

const DashboardPaymentGuarantee__obligoPop = ({
  style,
  active,
  closeEvent,
}) => {
  const getUserId = () => {
    if (active && active.data && active.data.id) {
      return active.data.id;
    }

    return -1;
  };

  const { user, getUser } = useContext(AppContext);
  const {
    getGlobal, getFieldGroup, getField,
  } = useContext(ContentContext);
  const [activesinglePop, setactivesinglePop] = useState(false);
  const [loading, setloading] = useState(null);
  const [amount, setamount] = useState(null);
  const [success, setsuccess] = useState(false);
  const [refused, setrefused] = useState(false);
  const [error] = useState({});
  const contractRef = useRef(null);
  const paymentguarantee = orderObligoHistoric(workshopHavePaymentguarantee({ user, withId: getUserId() }));
  const historicHeaderCol = [
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'id_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'vehicle_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'date_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'type_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'amount_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'status_header' }),
    },
  ];

  const renderList = () => {
    const orderArray = paymentguarantee;
    const arrayOfLine = [];

    orderArray.forEach((element) => {
      arrayOfLine.push([
        {
          component: <List__content uppercase>{element.service.uid}</List__content>,
          type: 'node',
        },
        {
          component: (
            <List__content>{element.vehicle.registrationNumber}</List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>
              {sequelizeDate({ date: element.service.createdAt, format: 'd/m/y' })}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>
              {getGlobal({ option: 'obligo', field: OBLIGO.type[element.service.type] })}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content capitalize>
              {formatPrice(element.service.price)}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <Content__tag
              content={getFieldGroup({
                base: strapiPaymentGuaranteeService,
                groupe: 'status',
                field: getPaymentGuaranteeStatus({
                  status: element.service.status,
                  user,
                  vehicle: element.vehicle,
                }),
              })}

              {...parseStatus({
                status: getPaymentGuaranteeStatus({
                  status: element.service.status,
                  user,
                  vehicle: element.vehicle,
                }),
              })}
            />
          ),
          type: 'node',
        },
      ]);
    });

    return arrayOfLine;
  };

  const getFleetUserInformation = (key) => {
    if (active && active.data && active.data.UserFleet && active.data.UserFleet[key]) {
      return active.data.UserFleet[key];
    }
    return '';
  };

  const getFleetInformation = (key) => {
    if (active && active.data && active.data[key]) {
      return active.data[key];
    }
    return '';
  };

  const handleamount = (value) => {
    setamount(value.value);
  };

  const handleObligoAmount = async () => {
    if (amount && parseInt(amount, 10) >= 100) {
      let getPdfRef = null;
      if (contractRef && contractRef.current) {
        getPdfRef = { ...contractRef.current };
      }
      setloading(true);
      // const urlValidation = getFleetUserInformation('obligoValidationUrl') ? getFleetUserInformation('obligoValidationUrl') : createRandomUrl();
      const urlValidation = createRandomUrl();
      const res = await updatefleetUserInformation({
        userId: getFleetUserInformation('id'),
        obligoAskConfirmation: true,
        obligoTempAmout: parseInt(amount, 10),
        obligoValidationUrl: urlValidation,
      });

      if (res) {
        if (getPdfRef) {
          getPdfRef.handleChangeValue('amount', formatPrice(amount));
          const pdf = await getPdfRef.handleGetPdf();

          setsuccess(true);
          setloading(false);

          SendMail({
            template: 'gop-supervisor',
            content: {
              title: getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_obligo', field: 'title' }),
              subtitle: `${getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_obligo', field: 'sub_title' })} ${user.user.lastName} ${user.user.firstName}`,
              sectionFleet_title: getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_obligo', field: 'fleet_section' }),
              sectionFleet_1: ['Company:', getFleetInformation('companyName')],
              sectionFleet_2: ['City:', getFleetInformation('city')],
              sectionFleet_3: ['Country:', getFleetInformation('region')],
              sectionGop: [getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_obligo', field: 'gop_amount' }), formatPrice(amount)],
              info: getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_obligo', field: 'info_text' }),
              btnAccept: [getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_obligo', field: 'btn_accept' }), `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/confirmation/obligo/${urlValidation}?accept=1`],
              btnRefused: [getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_obligo', field: 'btn_refuse' }), `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/confirmation/obligo/${urlValidation}?declined=1`],
            },
            subject: getFieldGroup({ base: strapiPaymentGuaranteeService, groupe: 'mail_supervisor_validation_obligo', field: 'subject' }),
            destination: user.user.UserWorkshop.supervisormail,
            attachments: [
              {
                content: pdf.pdf,
                filename: pdf.name,
                type: pdf.type,
                disposition: 'attachment',
              },
            ],
          });
        }
      }
    }
  };

  const handleRevokeObligo = async () => {
    setloading(true);
    const res = await revokeObligo({
      fleetId: getFleetUserInformation('id'),
    });
    setloading(false);
    if (res) {
      setrefused(true);
    }
  };

  const handleSuccess = async () => {
    setloading(true);
    setAction({ key: 'obligoSettings', value: `${active.key}` });
    const resUser = await getUser();
    if (resUser) {
      setloading(false);
    }
  };


  const handleOpenSinglePop = ({ id }) => {
    setactivesinglePop({ active: true, id });
  };

  const handleLineClick = (vehicle) => {
    handleOpenSinglePop({ id: paymentguarantee[vehicle.key].service.id });
  };


  const handleClosePop = () => {
    if (success || refused) {
      handleSuccess();
    }
    closeEvent();
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({ active: false, id: null });
  };

  const renderPDfMail = () => (
    <Contract__pdf
      buttonText={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_downnload' })}
      loadingtext={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_download_active' })}
      content={getFieldGroup({
        base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'contract_obligo', field: 'contract_content',
      })}
      type="obligo"
      name={`contract_obligo_${slugify(getFleetInformation('companyName'))}`}
      guarantor_company={user.user.companyName}
      guarantor_address={`${user.user.adress} ${user.user.city}, ${user.user.zipcode}`}
      guarantor_country={user.user.region}
      guarantor_contact={user.user.email}

      supervisor_name={`${user.user.UserWorkshop.supervisorname} ${user.user.UserWorkshop.supervisorlastname}`}
      supervisor_mail={user.user.UserWorkshop.supervisormail}
      supervisor_time={sequelizeDateTime({ date: getFleetUserInformation('confirmationDate'), atContent: getGlobal({ option: 'globalcontent', field: 'at' }) })}

      amount={formatPrice(getFleetUserInformation('obligoAmount'))}

      fleet_company={getFleetInformation('companyName')}
      fleet_address={getFleetInformation('adress')}
      fleet_zip={getFleetInformation('zipcode')}
      fleet_location={getFleetInformation('city')}
      fleet_phone={getFleetInformation('phoneNumber')}
      fleet_cmail={getFleetInformation('email')}
      fleet_tva={getFleetInformation('vat')}

      ref={contractRef}
      noButton
      noSignature
    />
  );

  const renderPDf = () => (
    <Contract__pdf
      buttonText={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_downnload' })}
      loadingtext={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_download_active' })}
      content={getFieldGroup({
        base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'contract_obligo', field: 'contract_content',
      })}
      type="obligo"
      name={`contract_obligo_${slugify(getFleetInformation('companyName'))}`}
      guarantor_company={user.user.companyName}
      guarantor_address={`${user.user.adress} ${user.user.city}, ${user.user.zipcode}`}
      guarantor_country={user.user.region}
      guarantor_contact={user.user.email}

      supervisor_name={`${user.user.UserWorkshop.supervisorname} ${user.user.UserWorkshop.supervisorlastname}`}
      supervisor_mail={user.user.UserWorkshop.supervisormail}
      supervisor_time={sequelizeDateTime({ date: getFleetUserInformation('confirmationDate'), atContent: getGlobal({ option: 'globalcontent', field: 'at' }) })}

      amount={formatPrice(getFleetUserInformation('obligoAmount'))}

      fleet_company={getFleetInformation('companyName')}
      fleet_address={getFleetInformation('adress')}
      fleet_zip={getFleetInformation('zipcode')}
      fleet_location={getFleetInformation('city')}
      fleet_phone={getFleetInformation('phoneNumber')}
      fleet_cmail={getFleetInformation('email')}
      fleet_tva={getFleetInformation('vat')}
    />
  );

  useEffect(() => {
    setamount(getFleetUserInformation('obligoAmount'));
  }, [active]);


  return (
    <Fragment>
      {renderPDfMail()}
      <Popup style={style} active={active.active} closeEvent={handleClosePop}>
        {
          !success && !refused && !loading && (
            <Container>
              <Title>{getFleetInformation('companyName')}</Title>
              <Grid__separator size="15px" />
              <InfoHeader>{getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'obligoPop_description' })}</InfoHeader>
              <Grid__separator size="80px" />
              { getFleetUserInformation('obligoStatus') === SERVICE_STATUS.done ? (
                <Fragment>
                  <Content__sectionTitle
                    line
                    icon={<FontAwesomeIcon icon={faChartBar} />}
                    title={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'obligoPopSingle_title_informations' })}
                  />
                  <Row>
                    <Content__bigTag
                      column
                      label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_used' })}
                      value={formatPrice(getFleetUserInformation('obligoAvailable'))}
                    />
                    <Content__bigTag
                      style={{ marginLeft: 20, marginRight: 20 }}
                      column
                      label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_remaining' })}
                      value={formatPrice(calculateRemain(getFleetUserInformation('obligoAvailable'), getFleetUserInformation('obligoAmount')))}
                    />
                    <Content__bigTag
                      column
                      label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_autorised' })}
                      value={formatPrice(getFleetUserInformation('obligoAmount'))}
                    />
                  </Row>
                  <Grid__separator size="25px" />
                  <RowLabel>
                    <Content__labelText
                      label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_date' })}
                      content={sequelizeDateWithOneMonths({ date: getFleetUserInformation('obligoSetDate'), format: 'd/m/y' })}
                    />
                  </RowLabel>

                  <Grid__separator size="100px" />
                  <Content__sectionTitle
                    line
                    icon={<FontAwesomeIcon icon={faUserCheck} />}
                    title={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'obligoPopSingle_title_autorisation' })}
                  />
                  {
                    !getFleetUserInformation('obligoAskConfirmation') ? (
                      <Content__serviceStatus
                        tag={(
                          <Content__tag
                            style={{ position: 'absolute', top: 25, right: 25 }}
                            border
                            content={getFieldGroup({
                              base: strapiPaymentGuaranteeService,
                              groupe: 'status',
                              field: 'done',
                            })}
                            green
                          />
                        )}
                        green
                      >
                        <div style={{ textAlign: 'center' }}>
                          <ServiceStatusTitle>
                            {getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_autorised_statustitle' })}
                            {' '}
                            {getFleetInformation('companyName')}
                          </ServiceStatusTitle>
                          <Grid__separator size="25px" />
                          <Form__textfield
                            style={{ width: 400 }}
                            label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_label' })}
                            labelSecondary={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_min_amount' })}
                            contrastBorder
                            require
                            property="amount"
                            value={amount}
                            change={handleamount}
                            type="price"
                            error={hasFieldError(error, 'amount')}
                          />
                          <Grid__separator size="25px" />
                          <Row>
                            <Button__primary event={handleObligoAmount}>{getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_modify_button' })}</Button__primary>
                          </Row>
                          <Button>
                            {renderPDf()}
                          </Button>
                        </div>
                      </Content__serviceStatus>
                    ) : (
                      <Content__serviceStatus
                        tag={(
                          <Content__tag
                            style={{ position: 'absolute', top: 25, right: 25 }}
                            border
                            content={getFieldGroup({
                              base: strapiPaymentGuaranteeService,
                              groupe: 'status',
                              field: 'action',
                            })}
                            blue
                          />
                        )}
                        blue
                      >
                        <ServiceStatusTitle>
                          {getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'info_waiting_supervisor' })}
                        </ServiceStatusTitle>
                        <Grid__separator size="10px" />
                        <Subtitle>
                          {getGlobal({ option: 'gender', field: user.user.UserWorkshop.supervisorgender })}
                          {' '}
                          {user.user.UserWorkshop.supervisorlastname}
                          {' '}
                          {user.user.UserWorkshop.supervisorname}
                        </Subtitle>
                        <Subtitle>
                          {user.user.UserWorkshop.supervisormail}
                        </Subtitle>
                      </Content__serviceStatus>
                    )
                  }
                  <Grid__separator size="100px" />
                  <Content__sectionTitle
                    line
                    icon={<FontAwesomeIcon icon={faListUl} />}
                    title={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'obligoPopSingle_title_history' })}
                  />

                  <List__table
                    headerCol={historicHeaderCol}
                    lineCol={renderList()}
                    noSelect
                    lineEvent={handleLineClick}
                  />

                  <Grid__separator size="100px" />
                  <RefusedCard red>
                    <Button__primary red border event={handleRevokeObligo}>
                      {getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'obligoPopSingle_delete_button' })}
                    </Button__primary>
                    <Grid__separator size="15px" />
                    <RefusedCardContent>
                      {getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'obligoPopSingle_delete_warning' })}
                    </RefusedCardContent>
                  </RefusedCard>
                </Fragment>
              ) : getFleetUserInformation('obligoStatus') === SERVICE_STATUS.waiting || getFleetUserInformation('obligoStatus') === SERVICE_STATUS.action ? (
                <Fragment>
                  {
                    !getFleetUserInformation('obligoAskConfirmation') ? (
                      <Content__serviceStatus
                        tag={(
                          <Content__tag
                            style={{ position: 'absolute', top: 25, right: 25 }}
                            border
                            content={getFieldGroup({
                              base: strapiPaymentGuaranteeService,
                              groupe: 'status',
                              field: 'action',
                            })}
                            blue
                          />
                        )}
                        blue
                      >
                        <div style={{ textAlign: 'center' }}>
                          <ServiceStatusTitle>
                            {getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_title' })}
                            {' '}
                            {getFleetInformation('companyName')}
                          </ServiceStatusTitle>
                          <Grid__separator size="25px" />
                          <Form__textfield
                            style={{ width: 400 }}
                            label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_label' })}
                            labelSecondary={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_min_amount' })}
                            contrastBorder
                            require
                            property="amount"
                            value={amount}
                            change={handleamount}
                            type="price"
                            error={hasFieldError(error, 'amount')}
                          />
                          <Grid__separator size="25px" />
                          <Row>
                            <Button__primary event={handleObligoAmount}>{getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_button' })}</Button__primary>
                          </Row>
                        </div>
                      </Content__serviceStatus>
                    ) : (
                      <Content__serviceStatus
                        tag={(
                          <Content__tag
                            style={{ position: 'absolute', top: 25, right: 25 }}
                            border
                            content={getFieldGroup({
                              base: strapiPaymentGuaranteeService,
                              groupe: 'status',
                              field: 'action',
                            })}
                            blue
                          />
                        )}
                        blue
                      >
                        <ServiceStatusTitle>
                          {getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'info_waiting_supervisor' })}
                        </ServiceStatusTitle>
                        <Grid__separator size="10px" />
                        <Subtitle>
                          {getGlobal({ option: 'gender', field: user.user.UserWorkshop.supervisorgender })}
                          {' '}
                          {user.user.UserWorkshop.supervisorlastname}
                          {' '}
                          {user.user.UserWorkshop.supervisorname}
                        </Subtitle>
                        <Subtitle>
                          {user.user.UserWorkshop.supervisormail}
                        </Subtitle>
                      </Content__serviceStatus>
                    )
                  }
                  <Grid__separator size="100px" />
                  <RefusedCard red>
                    <Button__primary red border event={handleRevokeObligo}>{getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_refusedbutton' })}</Button__primary>
                    <Grid__separator size="15px" />
                    <RefusedCardContent>
                      {getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_refusedtext' })}
                    </RefusedCardContent>
                  </RefusedCard>
                </Fragment>
              ) : getFleetUserInformation('obligoStatus') === SERVICE_STATUS.error ? (
                <Fragment>
                  <Content__serviceStatus
                    tag={(
                      <Content__tag
                        style={{ position: 'absolute', top: 25, right: 25 }}
                        border
                        content={getFieldGroup({
                          base: strapiPaymentGuaranteeService,
                          groupe: 'status',
                          field: 'error',
                        })}
                        red
                      />
                    )}
                    red
                  >
                    <ServiceStatusTitle>
                      {getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_refusedtitle' })}
                      {' '}
                      {getFleetInformation('companyName')}
                    </ServiceStatusTitle>
                    <Grid__separator size="15px" />
                    {getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_refusedsubtitle' })}
                  </Content__serviceStatus>
                </Fragment>
              ) : null}
            </Container>
          )
        }
        { success ? (
          <Message__sucess
            icon={t.image.illu.check}
            title={getFieldGroup({ base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'popup_success_to_supervisor', field: 'title' })}
            text={`${getFieldGroup({ base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'popup_success_to_supervisor', field: 'content' })} ${user.user.UserWorkshop.supervisormail}`}
            buttonText={getFieldGroup({ base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'popup_success_to_supervisor', field: 'button' })}
            buttonEvent={handleSuccess}
          />
        ) : refused ? (
          <Message__sucess
            icon={t.image.illu.error}
            title={`${getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_error_title' })} ${getFleetInformation('companyName')}`}
            buttonText={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_error_button' })}
            buttonEvent={handleSuccess}
          />
        ) : loading ? (
          <Loader__wrapper opacity brand />
        ) : null}
      </Popup>
      <DashboardPaymentGuarantee__singlePop
        active={activesinglePop.active}
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
    </Fragment>
  );
};

export default DashboardPaymentGuarantee__obligoPop;
