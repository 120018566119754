
/**
   * Import Lib & assets
   */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';


const Card = styled.a`
  display: block;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  color: #041e3a;
  transition: .3s ease all;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.06);
  }
`;

const Container = styled.span`
  ${t.mixins.flex({ justify: 'space-between', align: 'center' })};
`;

const Title = styled.span`
  font-weight: normal;
  font-size: 25px;
  color: #4D4D4D;
`;

const Counter = styled.span`
  ${t.mixins.flex({ justify: 'center', align: 'center', direction: 'column' })};
`;

const Value = styled.span`
  font-weight: normal;
  font-size: 30px;
  text-align: center;
  color: #4D4D4D;
`;

const Label = styled.span`
  font-weight: normal;
  font-size: 14px;
  color: #B3B3B3;
  margin-top: 5px;
`;

const Cards__fleetsCounter = ({
  style, title, label, value, url,
}) => (
  <Card style={style} href={url}>
    <Container>
      <Title>{title}</Title>
      <Counter>
        <Value>{value}</Value>
        <Label>{label}</Label>
      </Counter>
    </Container>
  </Card>
);

export default Cards__fleetsCounter;
