import React, { useContext, useEffect, useState } from 'react';
import { ContentContext } from 'context/contentContext';
import styled from 'styled-components';
import {
  strapiAppointment,
} from 'util/strapi/content.starpi';
import t from 'theme';
import { AppContext } from 'context/appContext';
import { formatApiCountRes, formatApiRes } from 'util/function/formatApiRes.function';
import { List__table, List__content } from 'components/list/list';
import { sequelizeCustomDate } from 'util/date.util';
import { Button__primary } from 'components/button/button';
import moment from 'moment';
import { findTodayAppointment, closeTodayAppointment } from '../../../helpers/api/routes/event';
import Grid__popup from '../../grid/popup/grid__popup';
import Content__text from '../../content/text/content__text';
import Content__tag from '../../content/tag/content__tag';
import { parseAppointmentStatus } from '../../../util/conditions/statusProps.util';
import Appointment__Single from '../../../containers/appointment/Single/appointment__Single';


const PopUp = styled(Grid__popup)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Table = styled(List__table)`
  height: auto;
  flex: initial;
  margin-bottom: 45px;
`;

const Title = styled(Content__text)`
  width: 100%;
  margin-bottom: 65px;
`;

const Modal__TodayAppointment = ({
  style,
  userObject,
  isWorkshop,
  basePage,
  isFleet,
}) => {
  const { user } = useContext(AppContext);
  const [activeSinglePop, setActiveSinglePop] = useState({
    active: false,
    id: undefined,
  });
  const [open, setOpen] = useState({
    open: false,
    data: [],
  });
  const { getFieldGroup, getField } = useContext(ContentContext);

  const headerCol = [
    {
      label: getField({ base: basePage, field: 'table_field_date' }),
    },
    {
      label: getField({ base: basePage, field: 'table_field_reason' }),
    },
    {
      label: getField({ base: basePage, field: 'table_field_workshop' }),
    },
    {
      label: getField({ base: basePage, field: 'table_field_vehicle' }),
    },
    {
      label: getField({ base: basePage, field: 'table_field_status' }),
    },
  ];
  const appointmentType = {
    maintenance: getField({ base: basePage, field: 'repair_type_maintenance' }),
    repair: getField({ base: basePage, field: 'repair_type_repair' }),
    urgentrepair: getField({ base: basePage, field: 'repair_type_repair_urgent' }),
  };
  const statusLabel = {
    refused: getFieldGroup({ base: basePage, groupe: 'appointment_status', field: 'error' }),
    done: getFieldGroup({ base: basePage, groupe: 'appointment_status', field: 'done' }),
    approved: getFieldGroup({ base: basePage, groupe: 'appointment_status', field: 'action' }),
    waiting: getFieldGroup({ base: basePage, groupe: 'appointment_status', field: 'waiting' }),
    reschedule: getField({ base: basePage, field: 'status_waiting_fleet_validation' }),
    cancel: getField({ base: basePage, field: 'status_canceled_by_fleet' }),
  };

  const fetchTodayEvents = async () => {
    const res = await findTodayAppointment({});

    const formated = formatApiCountRes(res);
    if (res) {
      if (formated.success && formated.data) {
        if (formated.data.length > 0) {
          setOpen({
            open: true,
            data: formated.data || [],
          });
        }
      }
    }
  };


  useEffect(() => {
    if (user) {
      if (!user.user[userObject].eventReminder || !moment(user.user[userObject].eventReminder).isSame(new Date(), 'day')) {
        fetchTodayEvents();
      }
    }
  }, [user]);

  const handleClosePopup = () => {
    setOpen({
      data: [],
      open: false,
    });
  };

  const closeTodayEvent = async () => {
    const res = await closeTodayAppointment({});

    const formated = formatApiRes(res);
    if (res) {
      if (formated.success) {
        handleClosePopup();
      }
    }
  };


  const renderServiceHistoric = () => {
    const arrayOfLine = [];
    open.data.forEach((element) => {
      arrayOfLine.push([
        {
          component: <List__content>{sequelizeCustomDate({ date: element.date, format: 'DD/MM/YYYY - HH:mm' })}</List__content>,
          type: 'node',
        },
        {
          component: <List__content>{appointmentType[element.type]}</List__content>,
          type: 'node',
        },
        {
          component: (
            <List__content>
              {element.UserWorkshop.UserWorkshopAlias.companyName}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>
              {element.Fleet.registrationNumber}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <Content__tag
              content={statusLabel[element.status]}
              {...parseAppointmentStatus({
                icon: true,
                status: element.status,
              })}
            />
          ),

          type: 'node',
        },
      ]);
    });

    return arrayOfLine;
  };

  const closeSinglePop = () => {
    setActiveSinglePop({
      id: undefined,
      active: false,
    });
  };


  const handleLineClick = (event) => {
    if (open.data[event.key]) {
      setActiveSinglePop({
        id: open.data[event.key].id,
        active: true,
      });
    }
  };

  return (
    <>
      <PopUp active={open.open} closeEvent={handleClosePopup}>
        <Title
          type="p"
          color="#4d4d4d"
          align="center"
          style={{ fontWeight: 600, fontSize: 26 }}
        >
          {getField({ base: basePage, field: 'popup_daily_title' })}
        </Title>
        <Table
          headerCol={headerCol}
          lineCol={renderServiceHistoric()}
          noSelect
          lineEvent={handleLineClick}
          maxHeight="100%"
        />
        <Button__primary
          event={closeTodayEvent}
        >
          {getField({ base: basePage, field: 'popup_daily_button' })}
        </Button__primary>
      </PopUp>
      <Appointment__Single basePage={strapiAppointment} isWorkshop={isWorkshop} isFleet={isFleet} id={activeSinglePop.id} active={activeSinglePop.active} closeEvent={closeSinglePop} refresh={() => null} />
    </>
  );
};

export default Modal__TodayAppointment;

