import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Container = styled.a`
  display: ${(props) => (props.small ? 'flex' : 'block')};
  position: relative;
  padding: 15px 15px 15px 15px;
  width: 250px;
  background: #01B5E2;
  box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  transition: .3s ease all;
  text-decoration: none;
  text-align: left;
  align-items: center;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 5px 24px rgba(1, 181, 226, 0.4);
  }
`;

const Content = styled.div`
  margin-top: ${(props) => (props.small ? '0' : '7px')};
  margin-left: ${(props) => (props.small ? '10px' : '0')};
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #FFFFFF;
`;

const Notification = styled.span`
  width: 25px;
  height: 25px;
  position: absolute;
  background: #E6F8FC;
  border-radius: 6px;
  top: 8px;
  right: 8px;
  font-size: 16px;
  line-height: 25px;
  color: #01B5E2;
  text-align: center;
  font-weight: bold;
`;


const Cards__serviceMini = ({
  style,
  icon,
  content,
  url,
  key,
  notificationCount,
  event,
  small,
}) => {
  const hasEvent = () => {
    if (event) {
      return {
        onClick: (e) => {
          e.preventDefault();
          event();
        },
      };
    }
    return {};
  };
  return (
    <Container
      style={style}
      href={url}
      key={key}
      small={small}
      {...hasEvent()}
    >
      {notificationCount && notificationCount > 0 ? (
        <Notification>{notificationCount}</Notification>
      ) : <></>}
      <Icon icon={icon} />
      <Content small={small}>
        {content}
      </Content>
    </Container>
  );
};

export default Cards__serviceMini;
