
/**
 * Import Lib
 */

import React, {
  Fragment, useContext, useState, useEffect,
} from 'react';
import t from 'theme';
import { SendMail } from 'helpers/api/routes/Mail';
import { ROUTING } from 'util/constant/routing.constant';
import { strapiPaymentGuaranteeService, strapiWorkshopDashboardPaymentGuarantee, strapiSingleServicePage } from 'util/strapi/content.starpi';
import { ContentContext } from 'context/contentContext';
import Message__sucess from 'components/message/sucess/message__sucess';
import { revokeObligo } from 'helpers/api/routes/Workshop';
import { Transition__loader_controller } from 'components/transition/transition';
import { Popup } from 'containers/workshopView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import { getParams } from 'util/get.util';
import { useParams, useHistory } from 'react-router-dom';
import {
  getUserFleetByObligoUrl,
} from 'helpers/api/routes/User';
import { sequelizeDateTime } from 'util/date.util';
import Contract__pdf from 'components/contract/pdf/contract__pdf';
import { formatPrice } from 'util/format/price.format';
import { slugify } from 'helpers/js/Utile';
import { getPublicWorkShopUserObligo, updatePublicfleetUserInformationObligo, setObligoAmount } from '../../helpers/api/routes/Public';
import { revokePublicObligo } from '../../helpers/api/routes/Workshop';


const ObligConfirmation = () => {
  const [active, setactive] = useState(false);
  const [workShopUser, setworkShopUser] = useState(false);
  const [data, setdata] = useState(false);
  const {
    getFieldGroup, getFieldGroupWithNavigatorLang, loadPage, haveContentFor, getFieldWithNavigatorLang, getGlobalWithNavigatorLang, page, getFieldGroupLang,
  } = useContext(ContentContext);
  const [, setloading] = useState(null);
  const [success, setsuccess] = useState(false);
  const [refused, setrefused] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const getFleetInformation = (key, dataRes) => {
    if (dataRes && dataRes.UserFleetAlias && dataRes.UserFleetAlias[key]) {
      return dataRes.UserFleetAlias[key];
    }
    return '';
  };


  const handleObligoAmount = async (dataRes, theWorkshopUser) => {
    setloading(true);
    const res = await setObligoAmount({
      amount: dataRes.obligoTempAmout,
      fleetId: dataRes.id,
      url: id,
    });

    setloading(false);
    if (res) {
      setsuccess(true);
      setactive(true);
      if (!dataRes.UserFleetAlias.notificationDisable) {
        SendMail({
          template: 'notification',
          templateNotification: {
            status: 'validate',
            statusLabel: getFieldGroup({
              select_lang: dataRes.UserFleetAlias.lang, base: strapiPaymentGuaranteeService, groupe: 'mail_obligo_accepted', field: 'status',
            }),
            content: `${theWorkshopUser.UserWorkshopAlias.companyName} ${getFieldGroup({
              select_lang: dataRes.UserFleetAlias.lang, base: strapiPaymentGuaranteeService, groupe: 'mail_obligo_accepted', field: 'content',
            })}`,
            btnContent: getFieldGroup({
              select_lang: dataRes.UserFleetAlias.lang, base: strapiPaymentGuaranteeService, groupe: 'mail_obligo_accepted', field: 'btn',
            }),
            btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.fleet.dashboardPaymentGuarantee.path}`,
          },
          subject: getFieldGroup({
            select_lang: dataRes.UserFleetAlias.lang, base: strapiPaymentGuaranteeService, groupe: 'mail_obligo_accepted', field: 'subject',
          }),
          destination: dataRes.UserFleetAlias.email,
        });
      }
    }
  };

  const handleRevokeObligo = async (dataRes) => {
    setloading(true);
    const res = await revokePublicObligo({
      fleetId: dataRes.UserFleetAlias.id,
      url: id,
    });
    setloading(false);
    if (res) {
      setactive(true);
      setrefused(true);
    }
  };

  const loadContent = async () => {
    if (haveContentFor(strapiPaymentGuaranteeService).success === false) {
      await loadPage(strapiPaymentGuaranteeService);
    }
    if (haveContentFor(strapiSingleServicePage).success === false) {
      await loadPage(strapiSingleServicePage);
    }
    if (haveContentFor(strapiWorkshopDashboardPaymentGuarantee).success === false) {
      await loadPage(strapiWorkshopDashboardPaymentGuarantee);
    }
  };

  const getParamsData = async (dataRes, theWorkshopUser) => {
    if (getParams('accept')) {
      handleObligoAmount(dataRes, theWorkshopUser);
    }

    if (getParams('declined')) {
      if (dataRes.obligoAmount && parseInt(dataRes.obligoAmount, 10) && parseInt(dataRes.obligoAmount, 10) > 1) {
        await updatePublicfleetUserInformationObligo({
          userId: dataRes.id,
          obligoAskConfirmation: false,
          obligoTempAmout: null,
          obligoValidationUrl: null,
          confirmationDate: new Date(),
          url: id,
        });
        setactive(true);
        setrefused(true);
      } else {
        handleRevokeObligo(dataRes);
      }
    }
  };

  const getWorkshop = async (idWorkshop) => {
    const res = await getPublicWorkShopUserObligo({ userId: idWorkshop, tempUrl: id });
    if (res && res.data && res.data.data) {
      return res.data.data;
    }
    return false;
  };

  const getData = async () => {
    const res = await getUserFleetByObligoUrl({ url: id });
    if (
      res
      && res.data
      && res.data.success
      && res.data.data.obligoAskConfirmation
    ) {
      const resUserWorkshop = await getWorkshop(res.data.data.workshopId);
      if (resUserWorkshop) {
        setworkShopUser(resUserWorkshop);
        setloading(false);
        getParamsData(res.data.data, resUserWorkshop);
        setdata(res.data.data);
      }
    } else {
      history.push('/login');
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
    if (page && page[strapiPaymentGuaranteeService.view] && page[strapiSingleServicePage.view] && page[strapiWorkshopDashboardPaymentGuarantee.view] && !data) {
      getData();
    }
  }, [page, data]);

  if (
    haveContentFor(strapiPaymentGuaranteeService).success === false
    || haveContentFor(strapiSingleServicePage).success === false
    || haveContentFor(strapiWorkshopDashboardPaymentGuarantee).success === false
  ) {
    return <Transition__loader_controller active />;
  }

  const renderPDf = (dataRes) => (
    workShopUser && (
      <Contract__pdf
        buttonText={getFieldWithNavigatorLang({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_downnload' })}
        loadingtext={getFieldWithNavigatorLang({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'btn_contract_download_active' })}
        content={getFieldGroupWithNavigatorLang({
          base: strapiWorkshopDashboardPaymentGuarantee, groupe: 'contract_obligo', field: 'contract_content',
        })}
        type="obligo"
        name={`contract_obligo_${slugify(getFleetInformation('companyName', dataRes))}`}
        guarantor_company={workShopUser.UserWorkshopAlias.companyName}
        guarantor_address={`${workShopUser.UserWorkshopAlias.adress} ${workShopUser.UserWorkshopAlias.city}, ${workShopUser.UserWorkshopAlias.zipcode}`}
        guarantor_country={workShopUser.UserWorkshopAlias.region}
        guarantor_contact={workShopUser.UserWorkshopAlias.email}

        supervisor_name={`${workShopUser.supervisorname} ${workShopUser.supervisorlastname}`}
        supervisor_mail={workShopUser.supervisormail}
        supervisor_time={sequelizeDateTime({ date: dataRes.confirmationDate, atContent: getGlobalWithNavigatorLang({ option: 'globalcontent', field: 'at' }) })}

        amount={formatPrice(dataRes.obligoTempAmout)}

        fleet_company={getFleetInformation('companyName', dataRes)}
        fleet_address={getFleetInformation('adress', dataRes)}
        fleet_zip={getFleetInformation('zipcode', dataRes)}
        fleet_location={getFleetInformation('city', dataRes)}
        fleet_phone={getFleetInformation('phoneNumber', dataRes)}
        fleet_cmail={getFleetInformation('email', dataRes)}
        fleet_tva={getFleetInformation('vat', dataRes)}
      />
    )
  );

  return (
    <Fragment>
      <Transition__loader_controller active={false} />
      <Popup active={active} noClose>
        {
          success ? (
            <Message__sucess
              icon={t.image.illu.check}
              title={`${getFieldWithNavigatorLang({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_success_title' })} ${getFleetInformation('companyName', data)}`}
              hideButton
              buttonNode={renderPDf(data)}
            />
          ) : refused ? (
            <Message__sucess
              icon={t.image.illu.error}
              title={getFieldWithNavigatorLang({ base: strapiPaymentGuaranteeService, field: 'obligo_singlepop_action_error_title' })}
              hideButton
            />
          ) : null
        }
      </Popup>
    </Fragment>
  );
};

export default ObligConfirmation;
