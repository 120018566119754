
/**
   * Import Lib & assets
   */

import { useEffect, useState } from 'react';
import { SERVICENAME } from 'util/service/serviceName';
import { googleTranslate } from 'util/constant/translate.constant';
import {
  fairPriceUpdateTranslate, paymentGuaranteeUpdateTranslate, standardRepairUpdateTranslate, repairApprovalUpdateTranslate,
  service24UpdateTranslate, priority90UpdateTranslate, service24UpdateTranslate2, caseClientUpdateTranslate,
  caseCloseUpdateTranslate,
} from 'helpers/api/routes/Translate';


const createJson = ({ content, lang, base }) => {
  if (!base) {
    return JSON.stringify({
      [lang]: {
        lang,
        translation: content,
      },
    });
  }
  const parsed = JSON.parse(base);
  if (parsed) {
    return JSON.stringify({
      ...parsed,
      [lang]: {
        lang,
        translation: content,
      },
    });
  }
  return JSON.stringify({
    [lang]: {
      lang,
      translation: content,
    },
  });
};
const switchBetweenData = async ({
  dataName, newContent, id, base, lang,
}) => {
  const createJsonRes = createJson({
    content: newContent, lang, base,
  });
  if (dataName === SERVICENAME.fairPrice) {
    const res = await fairPriceUpdateTranslate({
      translate: createJsonRes,
      id,
    });
    return res;
  }
  if (dataName === SERVICENAME.paymentguarantee) {
    const res = await paymentGuaranteeUpdateTranslate({
      translate: createJsonRes,
      id,
    });
    return res;
  }
  if (dataName === SERVICENAME.service24) {
    const res = await service24UpdateTranslate({
      translate: createJsonRes,
      id,
    });
    return res;
  }
  if (dataName === `${SERVICENAME.service24}_2`) {
    const res = await service24UpdateTranslate2({
      translate: createJsonRes,
      id,
    });
    return res;
  }
  if (dataName === SERVICENAME.standardrepair) {
    const res = await standardRepairUpdateTranslate({
      translate: createJsonRes,
      id,
    });
    return res;
  }
  if (dataName === SERVICENAME.repairapproval) {
    const res = await repairApprovalUpdateTranslate({
      translate: createJsonRes,
      id,
    });
    return res;
  }
  if (dataName === SERVICENAME.priority90) {
    const res = await priority90UpdateTranslate({
      translate: createJsonRes,
      id,
    });
    return res;
  }
  if (dataName === 'caseClient') {
    const res = await caseClientUpdateTranslate({
      translate: createJsonRes,
      id,
    });
    return res;
  }
  if (dataName === 'caseClose') {
    const res = await caseCloseUpdateTranslate({
      translate: createJsonRes,
      id,
    });
    return res;
  }

  return false;
};

export function useTranslate({
  lang, dataName, base, content, id, baseLang,
}) {
  const [data, setdata] = useState(null);
  const [loading, setloading] = useState(true);
  const [call, setcall] = useState(false);

  const parseCurrentTranslation = () => {
    if (base) {
      const parsed = JSON.parse(base);
      if (parsed && parsed.hasOwnProperty(lang) && parsed[lang]) {
        return parsed[lang].translation;
      }
    }
    return false;
  };

  const setTranslattion = async (newContent) => {
    if (newContent) {
      setdata(newContent);
      setloading(false);
      await switchBetweenData({
        dataName, newContent, id, base, lang,
      });
    }
  };

  const getTranslation = () => {
    googleTranslate.translate(content, lang, (err, translation) => {
      if (translation) {
        setTranslattion(translation.translatedText);
      }
    });
  };

  const getDataAsync = async () => {
    setloading(true);
    setcall(true);
    if (lang !== baseLang) {
      const current = parseCurrentTranslation();
      if (!current) {
        getTranslation();
      } else {
        setdata(current);
        setloading(false);
      }
    }
  };

  useEffect(() => {
    getDataAsync();
  }, []);

  return ([
    {
      data,
      loading,
      call,
    }, (newParams) => {
      getDataAsync(false, false, newParams);
    },
  ]);
}
