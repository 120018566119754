import {
  getFairPrice, getStandard, getPriority90, getService24,
  getRepairApproval,
  getPaymentGuarentee,
} from 'helpers/api/routes/getData';

export const FairePriceReq = async ({
  page, filter, limit, params,
}) => {
  const res = await getFairPrice({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};

export const StandardRepairReq = async ({
  page, filter, limit, params,
}) => {
  const res = await getStandard({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};

export const Priority90Req = async ({
  page, filter, limit, params,
}) => {
  const res = await getPriority90({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};

export const Service24Req = async ({
  page, filter, limit, params,
}) => {
  const res = await getService24({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};

export const RepairApprovalReq = async ({
  page, filter, limit, params,
}) => {
  const res = await getRepairApproval({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};

export const PaymentguaranteeReq = async ({
  page, filter, limit, params,
}) => {
  const res = await getPaymentGuarentee({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};
