
/**
 * Import Lib & assets
 */

import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';

import 'rc-datepicker/lib/style.css';
// import 'moment/locale/fr';

import 'react-day-picker/lib/style.css';
import './form__date.scss';
import t from 'theme';

/**
 * import components
 */

import Form__textfield from 'components/form/textfield/form__textfield';
import { ContentContext } from 'context/contentContext';


/**
 * styled components
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: ${t.font.primary};
  position: relative;
`;

/**
 * date component is..
 */


const Form__date = ({
  style, event, label, value, className, error, property,
}) => {
  const { getGlobal } = useContext(ContentContext);
  const [dateValue, setdateValue] = useState(value);
  const handleInputChange = (fieldValue) => {
    let field = fieldValue.value;
    if (field.length === 2 && dateValue[2] !== '/') {
      field += '/';
    } else if (field.length === 5 && dateValue[5] !== '/') {
      field += '/';
    } else if (field.length === 2) {
      field = field.slice(0, 1) + field.slice(2);
    } else if (field.length === 5) {
      field = field.slice(0, 4) + field.slice(5);
    }
    event({
      property,
      value: field,
    });
  };

  useEffect(() => {
    setdateValue(value);
  }, [value]);
  return (
    <Container style={style} className={className}>
      <Form__textfield
        change={handleInputChange}
        value={dateValue}
        placeholder={getGlobal({ option: 'vehicleParameter', field: 'first_registration_format' })}
        require
        label={label}
        error={error}
      />
    </Container>
  );
};

export default Form__date;
