/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

/**
 * import components
 */

/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  height: ${(props) => props.size || 0};
  width: ${(props) => props.width || 0};

  ${(props) => props.responsiveSize && props.responsiveSize.map((responsive) => t.mixins.custom(
    `${responsive.breakpoint}px`,
    `
      height: ${responsive.size}
  `,
  ))}
`;

/**
 * separator component is..
 */

const Grid__separator = ({
  style, size, width, xxs, xs, s, m, l, xl, responsiveSize,
}) => {
  const parseSize = () => {
    if (size) {
      return size;
    }
    if (xxs) {
      return t.grid.xxs;
    }
    if (xs) {
      return t.grid.xs;
    }
    if (s) {
      return t.grid.s;
    }
    if (m) {
      return t.grid.m;
    }
    if (l) {
      return t.grid.l;
    }
    if (xl) {
      return t.grid.xl;
    }

    return 0;
  };
  return (
    <Container style={style} width={width} size={parseSize()} responsiveSize={responsiveSize} />
  );
};

export default Grid__separator;
