/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

/**
 * import components
 */

/**
 * styled components
 */

const Container = styled.div`
  width: ${(props) => (props.small ? 'auto' : '100%')};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Check = styled.a`
  width: ${(props) => (props.small ? '18px' : '22px')};
  height: ${(props) => (props.small ? '18px' : '22px')};
  min-width: ${(props) => (props.small ? '18px' : '22px')};
  min-height: ${(props) => (props.small ? '18px' : '22px')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-right: 15px;
`;

const CheckContent = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  border-radius: ${(props) => (props.square ? '4px' : '100%')};
  background-color: ${t.color.cyan};
  border: solid 2px ${(props) => (props.active ? t.color.cyan : props.small ? '#5F6368' : '#C9C9C9')};
  background-color: ${(props) => (props.active ? t.color.cyan : 'transparent')};
  position: relative;
  transition: all ease 0.3s;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: ${(props) => (props.small ? '12px' : '14px')};
  color: ${(props) => (props.active === 1 ? '#fff' : 'transparent')};;
  position: absolute;
  top: ${(props) => (props.small ? '1px' : '3px')};
  left: ${(props) => (props.small ? '1px' : '2px')};
`;

const TextContainer = styled.div`
  flex: 1;
`;

/**
 * checkbox component is..
 */

const Form__checkbox = ({
  style, className, value, callback, children, property, square, small, disable,
}) => (
  <Container style={style} className={className} small={small}>
    <Check
      href="#"
      active={value}
      small={small}
      disable={disable}
      onClick={(e) => {
        e.preventDefault();
        if (callback) {
          callback({ property, value: !value });
        }
      }}
    >
      <CheckContent
        active={value}
        square={square}
        small={small}
        disable={disable}
      >
        <Icon
          small={small ? 1 : 0}
          icon={faCheck}
          active={value ? 1 : 0}
        />
      </CheckContent>
    </Check>
    <TextContainer>{children}</TextContainer>
  </Container>
);

export default Form__checkbox;
