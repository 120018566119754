
import { workshopHaveRepairApproval } from 'util/conditions/workshopHaveService';
import { homeBaseHavePaymentGuarantee } from '../conditions/workshopHaveService';

export const shouldRenderFairPrice = (vehicle) => {
  if (vehicle) {
    if (vehicle.fairPriceSubscription === true) {
      return true;
    }
  }
  return false;
};

export const shouldRenderStandarRepair = (vehicle) => {
  if (vehicle) {
    if (vehicle.standarRepairSubscription === true) {
      return true;
    }
  }
  return false;
};

export const shouldRenderRepairApproval = (vehicle) => {
  if (vehicle) {
    if (vehicle.repairApprovalSubscription === true) {
      return true;
    }
  }
  return false;
};

export const shouldRenderService24 = (vehicle) => {
  if (vehicle) {
    if (vehicle.service24Subscription === true) {
      return true;
    }
  }
  return false;
};

export const shouldRenderPriotity90 = (vehicle) => {
  if (vehicle) {
    if (vehicle.priority90Subscription === true) {
      return true;
    }
  }
  return false;
};

export const souldRenderPaymentguarantee = (vehicle) => {
  if (vehicle) {
    if (vehicle.paymentguaranteeSubscription === true) {
      return true;
    }
  }
  return false;
};

export const createSingleHistoric = ({
  vehicle, fairPrices, standarRepair, repairApproval, service24, priority90, paymentguarantee,
}) => {
  const array = [];

  if (shouldRenderFairPrice(vehicle) && fairPrices && vehicle.FairPrices) {
    vehicle.FairPrices.forEach((element) => {
      array.push({
        serviceName: fairPrices.name,
        serviceSlug: fairPrices.serviceSlug || '',
        data: element,
        content: fairPrices.content,
      });
    });
  }

  if (shouldRenderStandarRepair(vehicle) && standarRepair && vehicle.standardRepairs) {
    vehicle.standardRepairs.forEach((element) => {
      array.push({
        serviceName: standarRepair.name,
        serviceSlug: standarRepair.serviceSlug || '',
        data: element,
        content: standarRepair.content,
      });
    });
  }

  if (shouldRenderRepairApproval(vehicle) && repairApproval && vehicle.repairApprovals) {
    vehicle.repairApprovals.forEach((element) => {
      array.push({
        serviceName: repairApproval.name,
        serviceSlug: repairApproval.serviceSlug || '',
        data: element,
        content: repairApproval.content,
      });
    });
  }

  if (shouldRenderService24(vehicle) && service24 && vehicle.service24s) {
    vehicle.service24s.forEach((element) => {
      array.push({
        serviceName: service24.name,
        serviceSlug: service24.serviceSlug || '',
        data: element,
        content: service24.content,
      });
    });
  }

  if (shouldRenderPriotity90(vehicle) && priority90 && vehicle.priority90s) {
    vehicle.priority90s.forEach((element) => {
      array.push({
        serviceName: priority90.name,
        serviceSlug: priority90.serviceSlug || '',
        data: element,
        content: priority90.content,
      });
    });
  }

  if (souldRenderPaymentguarantee(vehicle) && paymentguarantee && vehicle.paymentguarantees) {
    vehicle.paymentguarantees.forEach((element) => {
      array.push({
        serviceName: paymentguarantee.name,
        serviceSlug: paymentguarantee.serviceSlug || '',
        data: element,
        content: paymentguarantee.content,
      });
    });
  }

  return array;
};

export const createCompleteServiceHistoric = ({
  vehicles, fairPrices, standarRepair, repairApproval, service24, priority90, paymentguarantee,
}) => {
  const array = [];

  vehicles.forEach((vehicle) => {
    if (shouldRenderFairPrice(vehicle) && fairPrices && vehicle.FairPrices) {
      vehicle.FairPrices.forEach((element) => {
        array.push({
          serviceSlug: fairPrices.serviceSlug || '',
          serviceName: fairPrices.name,
          data: element,
          content: fairPrices.content,
          vehicle,
        });
      });
    }

    if (shouldRenderStandarRepair(vehicle) && standarRepair && vehicle.standardRepairs) {
      vehicle.standardRepairs.forEach((element) => {
        array.push({
          serviceName: standarRepair.name,
          serviceSlug: standarRepair.serviceSlug || '',
          data: element,
          content: standarRepair.content,
          vehicle,
        });
      });
    }

    if (shouldRenderRepairApproval(vehicle) && repairApproval && vehicle.repairApprovals) {
      vehicle.repairApprovals.forEach((element) => {
        array.push({
          serviceName: repairApproval.name,
          serviceSlug: repairApproval.serviceSlug || '',
          data: element,
          content: repairApproval.content,
          vehicle,
        });
      });
    }

    if (shouldRenderService24(vehicle) && service24 && vehicle.service24s) {
      vehicle.service24s.forEach((element) => {
        array.push({
          serviceName: service24.name,
          serviceSlug: service24.serviceSlug || '',
          data: element,
          content: service24.content,
          vehicle,
        });
      });
    }

    if (shouldRenderPriotity90(vehicle) && priority90 && vehicle.priority90s) {
      vehicle.priority90s.forEach((element) => {
        array.push({
          serviceName: priority90.name,
          serviceSlug: priority90.serviceSlug || '',
          data: element,
          content: priority90.content,
          vehicle,
        });
      });
    }


    if (souldRenderPaymentguarantee(vehicle) && paymentguarantee && vehicle.paymentguarantees) {
      vehicle.paymentguarantees.forEach((element) => {
        array.push({
          serviceName: paymentguarantee.name,
          serviceSlug: paymentguarantee.serviceSlug || '',
          data: element,
          content: paymentguarantee.content,
          vehicle,
        });
      });
    }
  });

  return array;
};

export const createWorkshopHistoric = ({
  fairPrices, standarRepair, repairApproval, priority90, paymentguarantee, isWorkshop, user, fullUser,
}) => {
  const array = [];

  if (fairPrices && fairPrices.data) {
    fairPrices.data.forEach((element) => {
      array.push({
        serviceSlug: fairPrices.serviceSlug || '',
        serviceName: fairPrices.name,
        data: element,
        content: fairPrices.content,
      });
    });
  }
  if (standarRepair && standarRepair.data) {
    standarRepair.data.forEach((element) => {
      array.push({
        serviceSlug: standarRepair.serviceSlug || '',
        serviceName: standarRepair.name,
        data: element,
        content: standarRepair.content,
      });
    });
  }
  if (repairApproval && repairApproval.data) {
    const data = workshopHaveRepairApproval({ user: fullUser });
    data.completehistoric.forEach((element) => {
      const dataService = element.service;
      dataService.Fleet = element.vehicle;
      array.push({
        serviceSlug: repairApproval.serviceSlug || '',
        serviceName: repairApproval.name,
        data: dataService,
        content: repairApproval.content,
      });
    });
  }
  if (priority90 && priority90.data) {
    priority90.data.forEach((element) => {
      array.push({
        serviceSlug: priority90.serviceSlug || '',
        serviceName: priority90.name,
        data: element,
        content: priority90.content,
      });
    });
  }
  if (paymentguarantee && paymentguarantee.data) {
    if (isWorkshop) {
      const homeBaseFleetService = homeBaseHavePaymentGuarantee({ user: user.UserWorkshop });
      homeBaseFleetService.completehistoric.forEach((element) => {
        array.push({
          serviceSlug: paymentguarantee.serviceSlug || '',
          serviceName: paymentguarantee.name,
          data: { ...element.service, Fleet: element.vehicle },
          content: paymentguarantee.content,
        });
      });
    }
    paymentguarantee.data.forEach((element) => {
      array.push({
        serviceSlug: paymentguarantee.serviceSlug || '',
        serviceName: paymentguarantee.name,
        data: element,
        content: paymentguarantee.content,
      });
    });
  }

  return array;
};
