
import React from 'react';
import styled from 'styled-components';

/**
 * import containers & components
 */
import {
  Account__information,
  Account__companyInformation,
  Account__password,
} from 'containers/fleetView/account/account';
import { Grid__separator, Grid__card } from 'components/grid/grid';

/**
 * styled components
 */

const Container = styled.div`
  width: calc(50% - 20px);
  height: auto;
`;

const MainCards = styled(Grid__card)`
  width: 100%;
  padding: 40px;
`;

/**
 * information component is..
 */

const Account__mainInformation = ({ style, basePage }) => (
  <Container style={style}>
    <MainCards style={style}>
      <Account__information basePage={basePage} />
      <Grid__separator size="75px" />
      <Account__companyInformation basePage={basePage} />
      <Grid__separator size="75px" />
      <Account__password basePage={basePage} />
      <Grid__separator size="25px" />
    </MainCards>
  </Container>
);

export default Account__mainInformation;
