
import React from 'react';
import styled from 'styled-components';


const Container = styled.div``;

const DashboardPaymentGuarantee__infoPop = ({ style }) => (
  <Container style={style} />
);

export default DashboardPaymentGuarantee__infoPop;
