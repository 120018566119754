import React, { Fragment } from 'react';
import styled from 'styled-components';
import t from 'theme';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import ServiceDashboard__header from 'containers/fleetView/serviceDashboard/header/serviceDashboard__header';
import ServiceDashboard__infoPop from 'containers/fleetView/serviceDashboard/infoPop/serviceDashboard__infoPop';
import ServiceDashboard__selectPop from 'containers/fleetView/serviceDashboard/selectPop/serviceDashboard__selectPop';
import ServiceDashboard__selectPop__fairPrice from 'containers/fleetView/serviceDashboard/selectPop/serviceDashboard__selectPop_fairePrice';
import { SERVICENAME } from 'util/service/serviceName';


const ContentContainer = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-start', direction: 'row' })};
`;

const ContentTable = styled.div`
  width: 100%;
  margin-right: 40px;
  height: calc(100vh - 160px);
`;

const ContentAction = styled.div`
  min-width: 330px;
  width: 30%;
`;

const ServiceDashboard__layout = ({
  breadCrumb_parent,
  breadCrumb_parentLink,
  breadCrumb_current,
  activeInfoPop,
  closeInfoPop,
  infoPop,
  history,
  action,
  singlePop,
  infoPopProps,
  activeLaunchPop,
  closeLaunchPop,
  settingPop,
  formPop,
  nextEventLaunchPop,
  launchRequest,
  launchRef,
  launchTitle,
  page_title,
  Step1Title,
  Step2Title,
  Step3Title,
  serviceName,
  requestSucces,
}) => (
  <Layout__wrapper>
    <Layout__content>
      <ServiceDashboard__header
        breadCrumb_parent={breadCrumb_parent}
        breadCrumb_parentLink={breadCrumb_parentLink}
        breadCrumb_current={breadCrumb_current}
        page_title={page_title}
      />
      <ContentContainer>
        {history ? <ContentTable>{history}</ContentTable> : null}
        {action ? <ContentAction>{action}</ContentAction> : null}
      </ContentContainer>
    </Layout__content>
    {/* =====popup===== */}
    {infoPopProps ? (
      <ServiceDashboard__infoPop
        active={activeInfoPop}
        closeEvent={closeInfoPop}
        infoPopProps={infoPopProps}
      >
        {infoPop ? infoPop : null}
      </ServiceDashboard__infoPop>
    ) : null}
    {singlePop ? <Fragment>{singlePop}</Fragment> : null}
    {settingPop ? (
      settingPop
    ) : null}
    {launchRequest && formPop ? (
      serviceName && serviceName === SERVICENAME.fairPrice ? (
        <ServiceDashboard__selectPop__fairPrice
          active={activeLaunchPop}
          closeEvent={closeLaunchPop}
          nextEvent={nextEventLaunchPop}
          serviceName={serviceName}
          ref={launchRef}
          launchTitle={launchTitle}
          Step1Title={Step1Title}
          Step2Title={Step2Title}
          Step3Title={Step3Title}
          requestSucces={requestSucces}
        >
          {formPop}
        </ServiceDashboard__selectPop__fairPrice>
      ) : (
        <ServiceDashboard__selectPop
          active={activeLaunchPop}
          closeEvent={closeLaunchPop}
          nextEvent={nextEventLaunchPop}
          serviceName={serviceName}
          ref={launchRef}
          launchTitle={launchTitle}
          Step1Title={Step1Title}
          Step2Title={Step2Title}
          Step3Title={Step3Title}
          requestSucces={requestSucces}
        >
          {formPop}
        </ServiceDashboard__selectPop>
      )
    ) : null}
  </Layout__wrapper>
);

export default ServiceDashboard__layout;
