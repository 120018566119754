
/**
   * Import Lib & assets
   */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';


const Container = styled.div`

`;

const renderColor = (props) => {
  if (props.orange) {
    return '#FFAA29';
  }
  if (props.blue) {
    return '#E6F8FC';
  }
  if (props.red) {
    return '#FF5C5C';
  }
  if (props.green) {
    return '#2ECC71';
  }
};

const Icon = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-right: 15px;
  color: ${(props) => renderColor(props)};
  &:last-child {
    margin-right: 0;
  }
`;


const Content__serviceTag = ({
  style, icons,
}) => (
  <Container style={style}>
    {icons.map((item, key) => (
      <Icon
        key={key}
        color={item.color}
        icon={item.icon}
        orange={item.orange ? 1 : 0}
        green={item.green ? 1 : 0}
        red={item.red ? 1 : 0}
        blue={item.blue ? 1 : 0}
      />
    ))}
  </Container>
);

export default Content__serviceTag;
