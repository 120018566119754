/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

/**
 * import components
 */

/**
 * styled components
 */

const textbase = ({
  color, line, weight, align, size, format,
} = {}) => `
  color: ${color || t.color.cyan};
  font-size: ${size || '14px'};
  line-height: ${line || 'normal'};
  font-weight: ${weight ? t.font[weight] : 400};
  font-family: ${t.font.primary};
  text-align: ${align || 'left'};
  text-transform: ${format || 'initial'};
  display: inline-block;
  margin: 0;
  transition: color ease 0.5s;
`;

const Text = styled.p`
  ${(props) => textbase({
    color: props.color,
    line: props.line,
    weight: props.weight,
    align: props.align,
    size: props.size,
    format: props.format,
  })};
`;

const H1 = styled.h1`
  ${(props) => textbase({
    color: props.color || t.color.dark,
    line: props.line,
    weight: props.weight,
    align: props.align,
    size: props.size,
    format: props.format,
  })};
`;

const PStyled = styled.p`
  ${(props) => textbase({
    color: props.color || t.color.dark,
    line: props.line,
    weight: props.weight,
    align: props.align,
    size: props.size,
    format: props.format,
  })};
`;

const H2 = styled.h2`
  ${(props) => textbase({
    color: props.color || t.color.dark,
    line: props.line,
    weight: props.weight,
    align: props.align,
    size: props.size,
    format: props.format,
  })};
`;

const H3 = styled.h3`
  ${(props) => textbase({
    color: props.color || t.color.dark,
    line: props.line,
    weight: props.weight,
    align: props.align,
    size: props.size,
    format: props.format,
  })};
`;

const Body1 = styled.p`
  ${(props) => textbase({
    color: props.color,
    line: props.line,
    weight: props.weight,
    align: props.align,
    size: props.size,
    format: props.format,
  })};
`;

const Body2 = styled.p`
  ${(props) => textbase({
    color: props.color,
    line: props.line,
    weight: props.weight,
    align: props.align,
    size: props.size,
    format: props.format,
  })};
  opacity: 0.78;
`;

/**
 * All text types
 */

class Typo extends React.Component {
  renderH1() {
    const {
      style, children, color, align, format, className,
    } = this.props;

    return (
      <H1
        style={style}
        color={color}
        size="27px"
        line="1.4"
        weight="bold"
        align={align}
        format={format}
        className={className}
      >
        {children}
      </H1>
    );
  }

  renderP() {
    const {
      style, children, color, align, format, className,
    } = this.props;

    return (
      <PStyled
        style={style}
        color={color}
        size="27px"
        line="1.4"
        weight="bold"
        align={align}
        format={format}
        className={className}
      >
        {children}
      </PStyled>
    );
  }

  renderH2() {
    const {
      style, children, color, align, format, className,
    } = this.props;

    return (
      <H2
        style={style}
        color={color}
        size="24px"
        line="initial"
        weight="600"
        align={align}
        format={format}
        className={className}
      >
        {children}
      </H2>
    );
  }

  renderH3() {
    const {
      style, children, color, align, format, className,
    } = this.props;

    return (
      <H3
        style={style}
        color={color}
        size="18px"
        line="initial"
        weight="600"
        align={align}
        format={format}
        className={className}
      >
        {children}
      </H3>
    );
  }

  renderBody1() {
    const {
      style, children, color, align, format, className,
    } = this.props;

    return (
      <Body1
        style={style}
        color={color}
        size="18px"
        line="initial"
        weight="600"
        align={align}
        format={format}
        className={className}
      >
        {children}
      </Body1>
    );
  }

  renderBody2() {
    const {
      style, children, color, align, format, className,
    } = this.props;

    return (
      <Body2
        style={style}
        color={color}
        size="16px"
        line="24px"
        weight="400"
        align={align}
        format={format}
        className={className}
      >
        {children}
      </Body2>
    );
  }

  renderNewFormat() {
    const {
      style, children, type, color, align, format, className,
    } = this.props;

    return (
      <Text
        style={style}
        color={color}
        size={t.font[type] ? t.font[type].size : 14}
        line={t.font[type] ? t.font[type].line : '1.5'}
        weight={t.font[type] ? t.font[type].weight : 'normal'}
        align={align}
        format={format}
        className={className}
      >
        {children}
      </Text>
    );
  }

  renderCustom() {
    const {
      style, children, color, align, format, className,
    } = this.props;

    return (
      <Text
        style={style}
        color={color}
        align={align}
        size="14px"
        line="1.4"
        weight="400"
        format={format}
        className={className}
      >
        {children}
      </Text>
    );
  }

  renderTextElement() {
    const { type } = this.props;
    if (type === 'h1') {
      return this.renderH1();
    }
    if (type === 'p') {
      return this.renderP();
    }
    if (type === 'h2') {
      return this.renderH2();
    }
    if (type === 'h3') {
      return this.renderH3();
    }
    if (type === 'body1') {
      return this.renderBody1();
    }
    if (type === 'body2') {
      return this.renderBody2();
    }
    if (type !== null && type !== '') {
      return this.renderNewFormat();
    }
    return this.renderCustom();
  }

  render() {
    return this.renderTextElement();
  }
}

export default Typo;
