
import React, { useEffect } from 'react';

/**
 * import containers & components
 */
import { useParams, useHistory } from 'react-router-dom';
import { ROUTING } from '../util/constant/routing.constant';

/**
 * @name LogAs
 */

const LogAs = () => {
  const { token } = useParams();
  const history = useHistory();
  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('logAs', true);
      history.push(ROUTING.fleet.login.path);
    }
  }, [token]);
  return <div />;
};

export default LogAs;
