
export const createObj = ({ data, page }) => {
  const newObj = {};
  if (data) {
    if (data.length > 0) {
      data.forEach((element) => {
        if (element.hasOwnProperty('lang')) {
          if (element.lang) {
            if (element.lang.hasOwnProperty('lang')) {
              if (element.lang.lang && element.lang.lang !== null) {
                newObj[element.lang.lang] = element;
              }
            }
          }
        } else if (page && page === 'glbcontracts') {
          if (element.country) {
            if (element.country.hasOwnProperty('country')) {
              if (element.country.country && element.country.country !== null) {
                newObj[element.country.country] = element;
              }
            }
          }
        }
      });
    }
  }

  return newObj;
};

export const createOption = ({ data }) => {
  const newObj = {};

  if (data) {
    if (data.length > 0) {
      data.forEach((element) => {
        if (element.hasOwnProperty('lang')) {
          if (element.lang) {
            if (element.lang.hasOwnProperty('lang')) {
              if (element.lang.lang && element.lang.lang !== null) {
                newObj[element.lang.lang] = element;
              }
            }
          }
        }
      });
    }
  }

  return newObj;
};
