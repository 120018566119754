import { API_URL } from '../Utile';
import { CallGet, CallPost } from '../Helpers';

export const addFleetServices = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/proFleetAssist/addFleetServices/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const addFleetServicesFromWorkshop = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/proFleetAssist/addFleetServicesFromWorkshop/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const fleetHaveService = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}service/proFleetAssist/fleetHaveService/`;

  const res = await CallGet(url, params, headers);

  return res;
};

export const mailHaveService = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/proFleetAssist/mailHaveService/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const registerWorkshopToLeadGeneration = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}service/proFleetAssist/registerWorkshop/`;

  const res = await CallPost(url, params, headers);

  return res;
};


export const GetSingleService = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}service/proFleetAssist/${payload.id}`;

  const res = await CallGet(url, params, headers);

  return res;
};

export const GetSingleServiceByUrl = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}service/proFleetAssist/getSingleServiceByUrl/${payload.url}`;

  const res = await CallGet(url, params, headers);

  return res;
};


export const UpdatePublicStatus = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/public/proFleetAssist/updateStatus/${payload.url}`;

  const res = await CallPost(url, params, headers);

  return res;
};
