import React, { Fragment } from 'react';
import styled from 'styled-components';
import t from 'theme';
import Button__buttonCircle from 'components/button/buttonCircle/button__buttonCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid__separator } from 'components/grid/grid';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';
import { ROUTING } from 'util/constant/routing.constant';
import { Button__primary } from 'components/button/button';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import Content__tag from 'components/content/tag/content__tag';
import { useHistory } from 'react-router-dom';


const Card = styled.div`
  text-decoration: none;
  padding: 30px;
  height: 170px;
  width: 100%;
  display: block;
  border-radius: 10px;
  box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  color: '#041e3a';
  padding: '25px';
  transition: .3s ease all;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.06);
  }
`;

const ButtonContainer = styled.div`
  ${t.mixins.flex({ justify: 'space-between' })};
  width: 135px;
`;

const SubContent = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #808080;
`;

const CardTitle = styled(Content__cardTitle)`
  transition: 0.3s ease color;
`;

const TitleLink = styled.span`
  text-decoration: none;
`;

const SubContainer = styled.div`
  margin-top: 13px;
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-end' })};
`;

const Tag = styled(Content__tag)`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
  font-size: 16px;
  p {
    text-transform: none;
  }
`;

const Cards__homeBased = ({
  asHomeBase,
  title,
  contentL1,
  contentL2,
  contentL3,
  phoneNumber,
  email,
  adressLink,
  status,
  waitingMsg,
  buttonContent,
}) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(ROUTING.fleet.homeBase.path);
  };

  return (
    <Card onClick={handleClick}>
      <TitleLink>
        <CardTitle content={title} />
      </TitleLink>
      {asHomeBase ? (
        <SubContainer>
          <SubContent>
            <p>{contentL1}</p>
            <p>{contentL2}</p>
            <p>{contentL3}</p>
          </SubContent>
          <ButtonContainer>
            <Button__buttonCircle
              href={phoneNumber}
              noprevent
              icon={<FontAwesomeIcon icon={faPhone} />}
              target="_blank"
            />
            <Button__buttonCircle
              href={email}
              noprevent
              icon={<FontAwesomeIcon icon={faEnvelope} />}
              target="_blank"
            />
            <Button__buttonCircle
              href={adressLink}
              noprevent
              icon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
              target="_blank"
            />
          </ButtonContainer>
        </SubContainer>
      ) : (
        status === 'waiting' ? (
          <Fragment>
            <Grid__separator size="25px" />
            <Tag
              orange
              content={waitingMsg}
            />
          </Fragment>
        ) : (
          <Fragment>
            <Grid__separator size="25px" />
            <Button__primary
              withIcon
              noprevent
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              {buttonContent}
            </Button__primary>
          </Fragment>
        )
      )}
    </Card>
  );
};

export default Cards__homeBased;
