/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

const Container = styled.div`
  border-radius: 10px;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
  padding-left: 50px;
  background: ${({ color }) => (color ? color : '#e6f8fc')};
  ${t.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'column' })};
`;

const Cards__focusCard = ({
  style, color, children, className, blue, red, orange, green,
}) => (
  <Container
    color={
      color
        ? color
        : blue
          ? '#E6F8FC'
          : red
            ? '#FDEDEB'
            : orange
              ? '#FFF6EA'
              : green
                ? '#EAFAF1'
                : '#E6F8FC'
    }
    className={className}
    style={style}
  >
    {children}
  </Container>
);

export default Cards__focusCard;
