import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import t from 'theme';
import { Grid__separator } from 'components/grid/grid';
import Cards__serviceCard from 'components/cards/serviceCard/cards__serviceCard';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { formatPrice } from 'util/format/price.format';
import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import Cards__obligoProvider from 'components/cards/obligoProvider/cards__obligoProvider';
import { GetObligoDetails } from 'helpers/api/routes/Workshop';
import { getObligoTotal } from 'util/function/obligo';
import { strapiWorkshopDashboardPaymentGuarantee, strapiPaymentGuaranteeService } from 'util/strapi/content.starpi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshakeAlt } from '@fortawesome/pro-regular-svg-icons';


const Container = styled.div``;

const DashboardPaymentGuarantee__action = ({
  style, infoEvent, obligoEvent,
}) => {
  const { getField } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [obligocount, setobligocount] = useState(0);
  const [obligoarray, setobligoarray] = useState([]);

  const getObligoDetails = async () => {
    const res = await GetObligoDetails({
      workshopId: user.user.UserWorkshop.id,
    });
    const formated = formatApiRes(res);

    if (formated.data) {
      setobligocount(formated.data.filter((el) => el.UserFleet.obligoStatus === SERVICE_STATUS.waiting || el.UserFleet.obligoStatus === SERVICE_STATUS.action).length);
      setobligoarray(formated.data);
    }
  };

  useEffect(() => {
    getObligoDetails();
  }, []);
  const obligoTotalAmount = getObligoTotal({ data: obligoarray });


  return (
    <Container style={style}>
      <Cards__serviceCard
        dashboard
        event={infoEvent}
        imageUrl={t.image.global.service24}
        icon={<FontAwesomeIcon icon={faHandshakeAlt} color="#4D4D4D" />}
        title={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'card_discover_title' })}
        description={getField({ base: strapiPaymentGuaranteeService, field: 'short_description' })}
      />
      <Grid__separator size="30px" />
      <Cards__obligoProvider
        actionNeededCount={obligocount}
        amount={formatPrice(obligoTotalAmount.amount)}
        event={obligoEvent}
        title={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'card_obligo_title' })}
        label={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'card_obligo_text' })}
        buttonTxt={getField({ base: strapiWorkshopDashboardPaymentGuarantee, field: 'card_obligo_button' })}
      />
      <Grid__separator size="30px" />
    </Container>
  );
};

export default DashboardPaymentGuarantee__action;
