
import React, {
  useContext, useEffect, useState, useRef, Fragment,
} from 'react';
import { Grid__popup, Grid__separator } from 'components/grid/grid';
import styled from 'styled-components';
import t from 'theme';
import ServiceDashboard__vehicleList from 'containers/fleetView/serviceDashboard/vehicleList/serviceDashboard__vehicleList';
import ServiceDashboard__workshopList from 'containers/fleetView/serviceDashboard/workshopList/serviceDashboard__workshopList';
import { SERVICENAME } from 'util/service/serviceName';
import Message__sucess from 'components/message/sucess/message__sucess';
import { Content__text } from 'components/content/content';
import Global__stepsIndicator from 'components/global/stepsIndicator/global__stepsIndicator';
import { hasFieldError } from 'util/conditions/haveErrorField.conditions';
import { Form__textfield, Form__date, Form__select } from 'components/form/form';
import { sequelizeCustomDate } from 'util/date.util';
import { slugify } from 'helpers/js/Utile';
import { AppContext } from 'context/appContext';
import { formatApiRes } from 'util/function/formatApiRes.function';
import {
  faAlignJustify,
  faCalendarDay, faTruck, faWarehouse,
} from '@fortawesome/pro-regular-svg-icons';
import Button__primary from 'components/button/primary/button__primary';
import { CreateTimeArray } from 'util/function/CreateTimeArray';
import { addEvent } from 'helpers/api/routes/event';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { ContentContext } from 'context/contentContext';
import Form__checkCondition from 'components/form/checkCondition/form__checkCondition';
import Form__datePicker from '../../../components/form/datePicker/form__datePicker';
import { getClosedDay } from '../../../util/function/CreateTimeArray';
import Appointment__map from './appointment__map';


const Container = styled.div``;
const ContainerForm = styled.div`
  max-width: 550px;
  margin: auto;
`;
const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #01B5E2;
`;
const TextContent = styled.div`
  font-weight: 400;
  color: #4D4D4D;
  line-height: 150%;
  font-size: ${({ big }) => (big ? '24px' : '16px')};
  text-decoration: ${({ crossed }) => (crossed ? 'line-through' : 'none')};
  opacity: ${({ crossed }) => (crossed ? 0.4 : 1)};
`;
const TextContent__Bold = styled.span`
  font-weight: 600;
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'default')};
`;
const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;
const Button__container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;
const Title = styled.div`
  text-align: center;
  color: #4D4D4D;
  font-weight: 600;
  font-size: 26px;
`;
const Popup = styled(Grid__popup)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TimeSlotTitle_subTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 25px;
  line-height: 1.5;
  color: #4D4D4D;
`;
const TimeSlotTitle = styled.div`
  font-weight: 600;
  color: #4D4D4D;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 140%;
`;


const Appointment__formPop = ({
  style, active, closeEvent, closeAndAdd, isFleet, isWorkshop, basePage,
}) => {
  const { getGlobal, getFieldGroup, getField } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [step, setstep] = useState('vehicle');
  const [requestSucces, setRequestSucces] = useState(false);
  const [formData, setFormData] = useState(null);
  const [error, seterror] = useState(false);

  /* ------------------------------- Form state ------------------------------- */

  /** vehicle */
  const [selectedVehicle, setselectedVehicle] = useState(null);

  /** workshop */
  const [selectedWorkShop, setselectedWorkShop] = useState(null);

  /** information */
  const [acceptCondition, setacceptCondition] = useState(null);
  const [vehicleMileage, setvehicleMileage] = useState(null);
  const [success, setsuccess] = useState(false);
  const [description, setdescription] = useState('');
  const [repairType, setRepairType] = useState([]);
  const [repairTypeResult, setRepairTypeResult] = useState('');

  /** Times */
  const [date, setDate] = useState(undefined);
  const [time, setTime] = useState('');
  const [timeResult, setTimeResult] = useState({});
  /* ----------------------------- Form navigation ---------------------------- */

  const nextEvent = (data) => {
    setFormData(data);
  };

  const handlePrev = (to) => {
    setstep(to);
  };


  const getCurrentStep = () => {
    if (step === 'information') {
      return 2;
    }

    if (step === 'workshop') {
      return 3;
    }

    if (step === 'time') {
      return 4;
    }

    if (step === 'summary') {
      return 5;
    }

    return 1;
  };

  /* -------------------------------- Set data -------------------------------- */

  /** Vehicle step */
  const handleSelectVehicle = (vehicle) => {
    setselectedVehicle(vehicle);
    nextEvent({
      ...formData,
      vehicle,
    });
    setstep('information');
  };


  /** workshop steps */
  const handleSelectWorkshop = (workshop) => {
    setselectedWorkShop(workshop);
    nextEvent({
      ...formData,
      workshop,
    });
    setstep('time');
  };

  /** Information steps */
  const handleSetInformation = () => {
    nextEvent({
      ...formData,
      information: {
        description,
        repairType: repairTypeResult,
        mileage: vehicleMileage,
      },
    });
    setstep('workshop');
  };

  /** time steps */
  const handleSetTime = async () => {
    nextEvent({
      ...formData,
      time: {
        date,
        time: timeResult,
      },
    });
    setstep('summary');
  };


  /** summary steps */
  const handleSummary = async () => {
    const res = await addEvent({
      date: timeResult.slug,
      time: timeResult.label,
      description: formData.information.description,
      type: formData.information.repairType,
      mileage: formData.information.mileage,
      fleetId: user.user.UserFleet.id,
      workshopId: formData.workshop.UserWorkshop.id,
      vehicleId: formData.vehicle.id,
    });

    const formated = formatApiRes(res);
    if (res) {
      if (formated.success && formated.data && formated.data.id) {
        setsuccess(true);
      }
    }
  };

  /* ------------------------------ Input binding ----------------------------- */

  const handleDescription = (value) => {
    setdescription(value.value);
  };

  const handleMileage = (e) => {
    setvehicleMileage(e.value);
  };

  const handleSelectRepairType = (payload) => {
    setRepairType(payload.value);
    setRepairTypeResult(payload.result.slug);
  };

  const handleSelectTime = (payload) => {
    setTime(payload.value);
    setTimeResult(payload.result);
  };

  const handleDate = (payload) => {
    setDate(payload.value);
  };

  const appointmentType = {
    maintenance: getField({ base: basePage, field: 'repair_type_maintenance' }),
    repair: getField({ base: basePage, field: 'repair_type_repair' }),
    urgentrepair: getField({ base: basePage, field: 'repair_type_repair_urgent' }),
  };

  /* --------------------------------- Action --------------------------------- */

  /** set vehicle mileage */
  useEffect(() => {
    if (selectedVehicle && selectedVehicle.mileage) {
      setvehicleMileage(selectedVehicle.mileage);
    }
  }, [selectedVehicle]);


  /** create select */
  useEffect(() => {
    // const keyObjectRepair = Object.keys(getGlobalObj({ option: 'vehiRepair' }));
    const fakeObject = appointmentType;
    const keyObjectRepair = Object.keys(fakeObject);
    const selectTypeArray = [];

    keyObjectRepair.forEach((element) => {
      const obj = {
        // label: getGlobal({ option: 'vehicletype', field: element }),
        // slug: slugify(element),
        slug: slugify(element),
        label: fakeObject[element],
        active: false,
      };

      selectTypeArray.push(obj);
    });

    selectTypeArray[0].active = true;
    setRepairType(selectTypeArray);
    setRepairTypeResult(selectTypeArray[0].slug);
  }, []);

  useEffect(() => {
    const _date = moment(date).format('DD/MM/YYYY');
    setTime(CreateTimeArray(selectedWorkShop, _date));
  }, [selectedWorkShop, date]);

  return (
    <Popup active={active} closeEvent={closeEvent}>
      {success ? (
        <Message__sucess
          icon={t.image.illu.check}
          title={getFieldGroup({ base: basePage, groupe: 'confirmation_popup', field: 'title' })}
          text={getFieldGroup({ base: basePage, groupe: 'confirmation_popup', field: 'content' })}
          buttonText={getFieldGroup({ base: basePage, groupe: 'confirmation_popup', field: 'button' })}
          buttonEvent={closeAndAdd}
        />
      ) : (
        <Container>
          {
            !requestSucces && (
              <Fragment>
                <Title>
                  {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'title' })}
                </Title>
                <Grid__separator size="35px" />
                <Global__stepsIndicator
                  step1Name={getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_1_title' })}
                  step2Name={getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_2_title' })}
                  step3Name={getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_3_title' })}
                  step4Name={getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_4_title' })}
                  currentStep={getCurrentStep()}
                />
                <Grid__separator size="55px" />
              </Fragment>
            )
          }
          {
            step === 'vehicle' ? (
              <ServiceDashboard__vehicleList
                serviceName={SERVICENAME.fairPrice}
                nextEvent={handleSelectVehicle}
                base={undefined}
                defaultSelected={active}
                withDisable
                noText
              />
            ) : step === 'workshop' ? (
              <>
                <Appointment__map
                  basePage={basePage}
                  saveEvent={handleSelectWorkshop}
                  prevEvent={() => handlePrev('information')}
                />
              </>
            ) : step === 'information' ? (
              <ContainerForm>
                <Form__select
                  label={getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_2_field_1' })}
                  style={{ minWidth: 150 }}
                  require
                  value={repairType}
                  event={(res) => handleSelectRepairType({
                    value: res.value,
                    result: res.result,
                  })}
                />
                <Grid__separator size="30px" />
                <Form__textfield
                  type="number"
                  unit
                  label={getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_2_field_2' })}
                  contrastBorder
                  require
                  property="mileage"
                  value={vehicleMileage}
                  change={handleMileage}
                />
                <Grid__separator size="30px" />
                <Form__textfield
                  type="textarea"
                  label={getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_2_field_3' })}
                  require
                  property="description"
                  value={description}
                  change={handleDescription}
                  error={hasFieldError(error, 'description')}
                />
                <Grid__separator size="30px" />
                <Button__container>
                  <Button__primary border blue event={() => handlePrev('vehicle')}>{getGlobal({ option: 'service', field: 'single_launch_prevbutton' })}</Button__primary>
                  <Grid__separator width="25px" />
                  <Button__primary
                    noprevent
                    event={handleSetInformation}
                    disable={
                      !repairType
                      || !vehicleMileage
                      || !description
                      || repairType === ''
                      || vehicleMileage === ''
                      || description === ''
                    }
                  >
                    {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'validation_button' })}
                  </Button__primary>
                </Button__container>
              </ContainerForm>
            ) : step === 'time' ? (
              <ContainerForm>
                <TimeSlotTitle>{getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_4_info_title' })}</TimeSlotTitle>
                <TimeSlotTitle_subTitle>{getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_4_info' })}</TimeSlotTitle_subTitle>
                <Form__datePicker
                  value={date}
                  require
                  label={getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_4_field_1' })}
                  event={handleDate}
                  disabledDays={selectedWorkShop && selectedWorkShop.UserWorkshop ? getClosedDay(selectedWorkShop.UserWorkshop) : []}
                />
                <Grid__separator size="30px" />
                <Form__select
                  label={getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_4_field_2' })}
                  require
                  style={{ minWidth: 150 }}
                  value={time}
                  disabled={!date}
                  event={(res) => handleSelectTime({
                    value: res.value,
                    result: res.result,
                  })}
                />
                <Grid__separator size="30px" />
                <Button__container>
                  <Button__primary border blue event={() => handlePrev('workshop')}>{getGlobal({ option: 'service', field: 'single_launch_prevbutton' })}</Button__primary>
                  <Grid__separator width="25px" />
                  <Button__primary
                    noprevent
                    event={handleSetTime}
                    disable={
                      !date
                      || !time
                      || !timeResult
                      || !timeResult.label
                      || !timeResult.slug
                      || date === ''
                      || timeResult === ''
                      || time === ''
                    }
                  >
                    {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'next_button' })}
                  </Button__primary>
                </Button__container>
              </ContainerForm>
            ) : step === 'summary' ? (
              <ContainerForm>
                <SectionTitle>
                  <Icon icon={faCalendarDay} />
                  {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_summary_title_time_slot' })}
                </SectionTitle>
                <Grid__separator size="10px" />
                <TextContent>
                  <TextContent__Bold>{sequelizeCustomDate({ date: timeResult.slug, format: 'DD MMMM YYYY' })}</TextContent__Bold>
                  {' '}
                  |
                  {' '}
                  {timeResult.label}
                </TextContent>
                <Grid__separator size="25px" />
                <SectionTitle>
                  <Icon icon={faTruck} />
                  {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_summary_title_vehicle' })}
                </SectionTitle>
                <Grid__separator size="10px" />
                <TextContent>
                  <TextContent__Bold>{formData.vehicle.brand}</TextContent__Bold>
                  {' '}
                  |
                  {' '}
                  {formData.vehicle.registrationNumber}
                  {' '}
                  |
                  {' '}
                  {formData.information.mileage}
                  {' '}
                  Km
                </TextContent>
                <Grid__separator size="25px" />
                <SectionTitle>
                  <Icon icon={faWarehouse} />
                  {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_summary_title_workshop' })}
                </SectionTitle>
                <Grid__separator size="10px" />
                <TextContent>
                  <TextContent__Bold uppercase>{formData.workshop.companyName}</TextContent__Bold>
                  <br />
                  {formData.workshop.adress}
                  ,
                  {' '}
                  {formData.workshop.zipcode}
                  {' '}
                  {formData.workshop.adress}
                </TextContent>
                <Grid__separator size="25px" />
                <SectionTitle>
                  <Icon icon={faAlignJustify} />
                  {' '}
                  {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_summary_title_information' })}
                </SectionTitle>
                <Grid__separator size="10px" />
                <TextContent>
                  <TextContent__Bold>{appointmentType[formData.information.repairType]}</TextContent__Bold>
                  <br />
                  {formData.information.description}
                </TextContent>
                <Grid__separator size="35px" />
                <Form__checkCondition
                  largeContent
                  bold
                  value={acceptCondition}
                  content={getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'step_summary_checkbox_condition' })}
                  callback={(event) => {
                    setacceptCondition(event.value);
                  }}
                />
                <Grid__separator size="35px" />
                <Button__container>
                  <Button__primary border blue event={() => handlePrev('time')}>{getGlobal({ option: 'service', field: 'single_launch_prevbutton' })}</Button__primary>
                  <Grid__separator width="25px" />
                  <Button__primary
                    noprevent
                    event={handleSummary}
                    disable={!acceptCondition}
                  >
                    {getFieldGroup({ base: basePage, groupe: 'appointment_form', field: 'validation_button' })}
                  </Button__primary>
                </Button__container>
              </ContainerForm>
            ) : null
          }
        </Container>
      )}
    </Popup>
  );
};

export default Appointment__formPop;

