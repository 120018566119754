
import React from 'react';
import LeadGenerationWorkshopDasboard from 'components/services/LeadGeneration/workshop/dashboard/Dashboard';
import { config } from 'util/constant/leadGeneration/tpms.config';

/**
 * @name DashboardTpms
 */

const DashboardTpms = () => (
  <LeadGenerationWorkshopDasboard
    config={config}
  />
);

export default DashboardTpms;
