
/**
   * Import Lib
   */

import React, { useContext, useEffect, useState } from 'react';
import t from 'theme';
import styled from 'styled-components';
import { sequelizeDate } from 'util/date.util';
import { sequelizeMileage } from 'util/mileage.util';

import { AppContext } from 'context/appContext';
import { ContentContext } from 'context/contentContext';
import { List__table, List__content } from 'components/list/list';
import { Grid__separator } from 'components/grid/grid';
import { RegisterFleetToService } from 'helpers/api/routes/Service24';
import { Content__text } from 'components/content/content';
import Content__titleLight from 'components/content/titleLight/content__titleLight';
import { Button__primary } from 'components/button/button';
import {
  strapiService24Service,
  strapiSingleServicePage,
} from 'util/strapi/content.starpi';

import Form__totalCount from 'components/form/totalCount/form__totalCount';
import Cards__warning from 'components/cards/warning/cards__warning';
import {
  shouldRenderService24,
} from 'util/service/createHistoric';
import { formatApiRes } from 'util/function/formatApiRes.function';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { useHistory } from 'react-router-dom';
import { ROUTING } from 'util/constant/routing.constant';
import Message__sucess from 'components/message/sucess/message__sucess';
import ServiceDashboard__settingPop from 'containers/workshopView/serviceDashboard/settingPop/serviceDashboard__settingPop';


const Container = styled.div``;

const CardContainer = styled.div`
  width:100%;
  ${t.mixins.flex({ justify: 'center', align: 'center' })}
`;

const CardsDelete = styled(Cards__warning)`
  padding:  30px 65px;
  ${t.mixins.flex({ justify: 'space-between', align: 'center', direction: 'column' })}
  display: inline-flex;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #4D4D4D;
`;


const DashboardService24__settings = ({
  style, activeSettingPop, closeSettingPop, fleetArray, fleetArrayNotHave,
}) => {
  const { getField, getGlobal } = useContext(ContentContext);
  const { getUser } = useContext(AppContext);
  const [loading, setloading] = useState(false);
  const [successUnsubscribePop, setsuccessUnsubscribePop] = useState(false);
  const [successUpdatePop, setsuccessUpdatePop] = useState(false);
  const [checkArrayHaveArray, setCheckHaveArray] = useState([]);
  const [checkArrayNotHaveArray, setCheckNotHaveArray] = useState([]);
  const [initialCheckBoxValue, setinitialCheckBoxValue] = useState(false);
  const history = useHistory();
  const [headerCol] = useState([
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'vincode' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'category' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'brand' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'mileage' }),
    },
  ]);
  const [fleetFormatted, setFleetFormatted] = useState({
    have: [],
    notHave: [],
  });
  const [fleetNoFormatted, setFleetNoFormatted] = useState({
    have: [],
    notHave: [],
  });

  const initialCheckBox = (array) => {
    const obj = {};
    const haveArray = [];
    array.forEach((element, key) => {
      obj[key] = true;
      haveArray.push({
        key: `${key}`,
        value: true,
      });
    });

    setinitialCheckBoxValue(obj);
    setCheckHaveArray(haveArray);
  };

  useEffect(() => {
    const concatFleetArray = {
      data: [],
    };
    if (fleetArray && fleetArray.data) {
      concatFleetArray.data = concatFleetArray.data.concat(fleetArray.data);
    }
    if (fleetArrayNotHave && fleetArrayNotHave.data) {
      concatFleetArray.data = concatFleetArray.data.concat(fleetArrayNotHave.data);
    }
    if (concatFleetArray && concatFleetArray.data && concatFleetArray.data.length > 0) {
      const nothaveobj = [];
      const haveobj = [];
      const nothaveobj_noFormat = [];
      const haveobj_noFormat = [];
      concatFleetArray.data.forEach((element) => {
        const childObj = [];
        if (shouldRenderService24(element)) {
          childObj.push({
            label: (
              <List__content capitalize>
                {element.registrationNumber}
              </List__content>
            ),
          });
          childObj.push({
            label: (
              <List__content capitalize>
                {getGlobal({ option: 'country', field: element.countryOfRegistration }) || element.countryOfRegistration}
              </List__content>
            ),
          });
          childObj.push({
            label: (
              <List__content capitalize>
                {element.vin}
              </List__content>
            ),
          });
          childObj.push({
            label: (
              <List__content capitalize>
                {element.type}
              </List__content>
            ),
          });
          childObj.push({
            label: (
              <List__content capitalize>
                {element.brand}
              </List__content>
            ),
          });
          childObj.push({
            label: (
              <List__content capitalize>
                {sequelizeDate({ date: element.createdAt, format: 'd/m/y' })}
              </List__content>
            ),
          });
          childObj.push({
            label: (
              <List__content capitalize>
                {sequelizeMileage({ value: element.mileage })}
              </List__content>
            ),
          });
          haveobj.push(childObj);
          haveobj_noFormat.push(element);
        } else {
          childObj.push({
            label: (
              <List__content capitalize>
                {element.registrationNumber}
              </List__content>
            ),
          });
          childObj.push({
            label: (
              <List__content capitalize>
                {getGlobal({ option: 'country', field: element.countryOfRegistration }) || element.countryOfRegistration}
              </List__content>
            ),
          });
          childObj.push({
            label: (
              <List__content capitalize>
                {element.vin}
              </List__content>
            ),
          });
          childObj.push({
            label: (
              <List__content capitalize>
                {element.type}
              </List__content>
            ),
          });
          childObj.push({
            label: (
              <List__content capitalize>
                {element.brand}
              </List__content>
            ),
          });
          childObj.push({
            label: (
              <List__content capitalize>
                {sequelizeDate({ date: element.createdAt, format: 'd/m/y' })}
              </List__content>
            ),
          });
          childObj.push({
            label: (
              <List__content capitalize>
                {sequelizeMileage({ value: element.mileage })}
              </List__content>
            ),
          });
          nothaveobj.push(childObj);
          nothaveobj_noFormat.push(element);
        }
      });


      initialCheckBox(haveobj);

      setFleetFormatted({
        have: haveobj,
        notHave: nothaveobj,
      });

      setFleetNoFormatted({
        have: haveobj_noFormat,
        notHave: nothaveobj_noFormat,
      });
    }
  }, [fleetArrayNotHave.data, fleetArray.data]);

  const handleRemoveVehicle = (value) => {
    setCheckHaveArray(value);
  };

  const handleAddVehicle = (value) => {
    setCheckNotHaveArray(value);
  };

  const getTotalValue = () => checkArrayNotHaveArray.length + checkArrayHaveArray.length;

  const unsubscrib = async () => {
    setloading(true);
    const data = {
      data: [],
    };

    fleetNoFormatted.have.forEach((vehicle) => {
      data.data.push({
        id: vehicle.id,
        subscription: false,
      });
    });

    fleetNoFormatted.notHave.forEach((vehicle) => {
      data.data.push({
        id: vehicle.id,
        subscription: false,
      });
    });


    if (data.data.length > 0) {
      const registerFleetRes = await RegisterFleetToService({
        fleetObj: data,
      });
      if (registerFleetRes) {
        setloading(false);
        if (formatApiRes(registerFleetRes).success === true) {
          setsuccessUnsubscribePop(true);
        }
      } else {
        setloading(false);
      }
    } else {
      setloading(false);
    }
  };

  const perfomSave = async () => {
    setloading(true);
    const data = {
      data: [],
    };

    fleetNoFormatted.have.forEach((vehicle, key) => {
      if (checkArrayHaveArray.filter((check) => check.key === `${key}`).length <= 0) {
        data.data.push({
          id: vehicle.id,
          subscription: false,
        });
      }
    });
    fleetNoFormatted.notHave.forEach((vehicle, key) => {
      if (checkArrayNotHaveArray.filter((check) => check.key === `${key}`).length > 0) {
        data.data.push({
          id: vehicle.id,
          subscription: true,
        });
      }
    });

    if (data.data.length > 0) {
      const registerFleetRes = await RegisterFleetToService({
        fleetObj: data,
      });
      if (registerFleetRes) {
        setloading(false);
        if (formatApiRes(registerFleetRes).success === true) {
          setsuccessUpdatePop(true);
        }
      } else {
        setloading(false);
      }
    } else {
      setloading(false);
      setsuccessUpdatePop(true);
    }
  };


  const handleSave = () => {
    perfomSave();
  };

  const handleCloseSucessUnsubscribe = async () => {
    setloading(true);
    const resUser = await getUser();
    if (resUser.success === true) {
      setloading(false);
      setsuccessUnsubscribePop(false);
      history.push(ROUTING.fleet.addServices.path);
    }
  };

  const handleCloseSucessUpdate = async () => {
    setloading(true);
    const resUser = await getUser();
    if (resUser.success === true) {
      setloading(false);
      setsuccessUpdatePop(false);
    }
  };


  const handleCloseEvent = () => {
    if (successUnsubscribePop) {
      handleCloseSucessUnsubscribe();
    } else if (successUpdatePop) {
      handleCloseSucessUpdate();
    } else {
      closeSettingPop();
    }
  };

  return (
    <ServiceDashboard__settingPop active={activeSettingPop} closeEvent={handleCloseEvent} flex>
      {
        successUnsubscribePop ? (
          <Message__sucess
            icon={t.image.illu.check}
            title={getField({ base: strapiSingleServicePage, field: 'success_unsubscribe_popup_title' })}
            text={getField({ base: strapiSingleServicePage, field: 'success_unsubscribe_popup_text' })}
            buttonText={getField({ base: strapiSingleServicePage, field: 'success_unsubscribe_popup_button' })}
            buttonEvent={handleCloseSucessUnsubscribe}
          />
        ) : successUpdatePop ? (
          <Message__sucess
            icon={t.image.illu.check}
            title={getField({ base: strapiSingleServicePage, field: 'success_update_popup_title' })}
            text={getField({ base: strapiSingleServicePage, field: 'success_update_popup_text' })}
            buttonText={getField({ base: strapiSingleServicePage, field: 'success_update_popup_button' })}
            buttonEvent={handleCloseSucessUpdate}
          />
        ) : (
          <Container style={style}>
            <Content__text
              type="p"
              color="#4d4d4d"
              align="center"
              style={{ fontWeight: 600, fontSize: 26, display: 'block' }}
            >
              {getField({ base: strapiService24Service, field: 'service_title' })}
              {' '}
              {getField({ base: strapiSingleServicePage, field: 'settings_suffix' })}
            </Content__text>
            <Grid__separator size="65px" />
            <Content__titleLight content={`${getField({ base: strapiSingleServicePage, field: 'count_vehicle_have_services' })} (${fleetFormatted.have.length})`} />
            <Grid__separator size="15px" />
            <SubTitle>{getField({ base: strapiSingleServicePage, field: 'remove_array_title' })}</SubTitle>
            <Grid__separator size="30px" />
            <List__table
              style={{ maxHeight: 400 }}
              headerCol={headerCol}
              lineCol={fleetFormatted.have}
              selectedLine={handleRemoveVehicle}
              square
              isCheck={initialCheckBoxValue}
            />
            <Grid__separator size="65px" />
            <Content__titleLight content={`${getField({ base: strapiSingleServicePage, field: 'count_vehicle_not_have_services' })} (${fleetFormatted.notHave.length})`} />
            <Grid__separator size="15px" />
            <SubTitle>{getField({ base: strapiSingleServicePage, field: 'add_array_title' })}</SubTitle>
            <Grid__separator size="30px" />
            <List__table
              style={{ maxHeight: 400 }}
              headerCol={headerCol}
              lineCol={fleetFormatted.notHave}
              selectedLine={handleAddVehicle}
              square
            />
            <Grid__separator size="65px" />
            <Form__totalCount
              buttonText={getField({ base: strapiSingleServicePage, field: 'update_button' })}
              handleSave={handleSave}
            />
            <Grid__separator size="65px" />
            <CardContainer>
              <CardsDelete>
                <Button__primary border red event={unsubscrib}>
                  {getField({ base: strapiSingleServicePage, field: 'unsubscribe_button' })}
                </Button__primary>
                <Grid__separator size="15px" />
                <Content__text
                  style={{
                    fontSize: 16,
                  }}
                  color="#E74C3C"
                >
                  {getField({ base: strapiSingleServicePage, field: 'unsubscribe_text' })}
                </Content__text>
              </CardsDelete>
            </CardContainer>
          </Container>
        )
      }
      {loading ? <Loader__wrapper /> : false}
    </ServiceDashboard__settingPop>
  );
};

export default DashboardService24__settings;
