
import React, { forwardRef, useState, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { Grid__popup, Grid__separator } from 'components/grid/grid';
import t from 'theme';
import { Content__text } from 'components/content/content';
import Global__stepsIndicator from 'components/global/stepsIndicator/global__stepsIndicator';
import ServiceDashboard__vehicleList from '../vehicleList/serviceDashboard__vehicleList';
import ServiceDashboard__workshopList from '../workshopList/serviceDashboard__workshopList';


const Container = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', direction: 'column' })}
`;

const Popup = styled(Grid__popup)`
  padding-top: 45px;
  padding-bottom: 45px;
`;

// eslint-disable-next-line react/display-name
const ServiceDashboard__selectPop = forwardRef(({
  style,
  active,
  closeEvent,
  nextEvent,
  serviceName,
  children,
  launchTitle,
  Step1Title,
  Step2Title,
  Step3Title,
}, ref) => {
  const [step, setstep] = useState('vehicle');
  // eslint-disable-next-line no-unused-vars
  const [selectedWorkShop, setselectedWorkShop] = useState(null);
  const [selectedVehicle, setselectedVehicle] = useState(null);

  const handleSelectVehicle = (vehicle) => {
    setselectedVehicle(vehicle);
    if (Step3Title) {
      setstep('workshop');
    } else {
      nextEvent({ vehicle });
      setstep('claim');
    }
  };

  const handleSelectWorkshop = (workshop) => {
    setselectedWorkShop(workshop);
    nextEvent({ vehicle: selectedVehicle, workshop });
    setstep('claim');
  };

  const getCurrentStep = () => {
    if (step === 'workshop') {
      return 2;
    }

    if (step === 'claim' && Step3Title) {
      return 3;
    }

    if (step === 'claim' && Step3Title === undefined) {
      return 2;
    }

    return 1;
  };


  const handlePrev = (to) => {
    setstep(to);
  };

  useImperativeHandle(ref, () => ({

    handlePrevHook(to) {
      setstep(to);
    },

  }));


  return (
    <Container style={style}>
      <Popup active={active} closeEvent={closeEvent}>
        <Container>
          <Content__text
            type="p"
            color="#4d4d4d"
            align="center"
            style={{ fontWeight: 600, fontSize: 26 }}
          >
            {launchTitle}
          </Content__text>
          <Grid__separator size="25px" />
          <Global__stepsIndicator
            step1Name={Step1Title}
            step2Name={Step2Title}
            step3Name={Step3Title}
            currentStep={getCurrentStep()}
          />
          <Grid__separator size="65px" />
          {
            step === 'vehicle' ? (
              <ServiceDashboard__vehicleList
                serviceName={serviceName}
                nextEvent={handleSelectVehicle}
              />
            ) : step === 'workshop' ? (
              <ServiceDashboard__workshopList
                serviceName={serviceName}
                saveEvent={handleSelectWorkshop}
                prevEvent={() => handlePrev('vehicle')}
              />
            ) : step === 'claim' ? (
              children
            ) : null
          }
        </Container>
      </Popup>
    </Container>
  );
});

export default ServiceDashboard__selectPop;
