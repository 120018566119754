import React, {
  useContext, useEffect, Fragment, useState,
} from 'react';

import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import Content__tag from 'components/content/tag/content__tag';

import Cards__listCard from 'components/cards/listCard/cards__listCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshakeAlt } from '@fortawesome/pro-regular-svg-icons';

import { sequelizeDate } from 'util/date.util';
import { parseStatus } from 'util/conditions/statusProps.util';
import { strapiRepairApprovalService, strapiServiceHeader } from 'util/strapi/content.starpi';
import { ROUTING } from 'util/constant/routing.constant';
import { handleLineClick_singleNew } from 'util/function/openSingleService';
import DashboardRepairApproval__singlePop from 'containers/fleetView/dashboardRepairApproval/singlePop/dashboardRepairApproval__singlePop';
import { SERVICENAME } from 'util/service/serviceName';
import List__content from 'components/list/content/list__content';
import { repairApproval_isAction } from 'util/conditions/repairApproval.condition';
import { getFormService } from 'util/function/getter';
import { formatApiCountRes } from 'util/function/formatApiRes.function';
import { useData } from 'components/data/getter/data__getter';
import { getFleetRepairApprovalAction } from 'helpers/api/routes/getData';


const MyServices__repairApproval = ({ basePage }) => {
  const {
    loadPage, haveContentFor, getField, getFieldGroup, getGlobal,
  } = useContext(ContentContext);

  const { user } = useContext(AppContext);
  const [activesinglePop, setactivesinglePop] = useState({
    active: false,
    id: null,
  });
  const [actionCount, setactionCount] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      id: user.user.UserFleet.id,
      userid: user.user.id,
      user: true,
    },
    service: SERVICENAME.repairapproval,
    type: 'service',
    limite: 3,
  });


  const getActionNeeded = async () => {
    const resAction = await getFleetRepairApprovalAction({
      page: 1,
      limit: 3,
      id: user.user.UserFleet.id,
      isApprover: user.user.UserFleet.repairApprovalUserId === user.user.id,
    });

    const formated = formatApiCountRes(resAction);
    setactionCount(formated.count);
  };

  useEffect(() => {
    if (haveContentFor(strapiRepairApprovalService).success === false) {
      loadPage(strapiRepairApprovalService);
    }
    getActionNeeded();
  }, []);

  const headerCol = [
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'date_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'status_header' }),
    },
  ];

  const renderList = () => {
    const orderArray = resData.data;
    const arrayOfLine = [];

    if (orderArray) {
      orderArray.forEach((element) => {
        arrayOfLine.push([
          {
            component: (
              <List__content>
                {sequelizeDate({ date: getFormService(element, 'createdAt'), format: 'd/m/y' })}
              </List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <Content__tag
                content={getFieldGroup({
                  base: strapiRepairApprovalService,
                  groupe: 'status',
                  field: repairApproval_isAction(element, user),
                })}
                {...parseStatus({ status: repairApproval_isAction(element, user) })}
              />
            ),
            type: 'node',
          },
        ]);
      });
    }

    return arrayOfLine;
  };


  const handleLine = (line) => {
    handleLineClick_singleNew({
      line,
      array: resData.data,
      callback: ({ active, id }) => setactivesinglePop({
        active,
        id,
      }),
    });
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({
      active: false,
      id: null,
    });
  };

  return (
    <Fragment>
      <Cards__listCard
        width="380px"
        countValue={actionCount}
        headerCol={headerCol}
        line={resData && resData.data ? renderList() : []}
        serviceName="repairapproval"
        icon={<FontAwesomeIcon icon={faHandshakeAlt} />}
        title={getField({ base: strapiRepairApprovalService, field: 'service_title' })}
        buttonRequest={getField({ base: basePage, field: 'dashboard_button' })}
        buttonRequestHref={`${ROUTING.fleet.dashboardRepairApproval.path}`}
        loading={!haveContentFor(strapiRepairApprovalService).success || resData.loading}
        hasLoader
        lineEvent={handleLine}
        fleet
      />
      <DashboardRepairApproval__singlePop
        active={activesinglePop.active}
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
    </Fragment>
  );
};

export default MyServices__repairApproval;
