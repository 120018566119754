import React, {
  useContext, useEffect,
} from 'react';

import { ContentContext } from 'context/contentContext';
import { faUserHeadset } from '@fortawesome/pro-light-svg-icons';
import { strapiService24Service } from 'util/strapi/content.starpi';
import { ROUTING } from 'util/constant/routing.constant';
import { Cards__tool } from 'components/cards/cards';

const MyServices__repairApproval = ({ basePage }) => {
  const {
    loadPage, haveContentFor, getField,
  } = useContext(ContentContext);

  useEffect(() => {
    if (haveContentFor(strapiService24Service).success === false) {
      loadPage(strapiService24Service);
    }
  }, []);

  return (
    <Cards__tool
      icon={faUserHeadset}
      title={getField({ base: strapiService24Service, field: 'service_title' })}
      btnService={getField({ base: basePage, field: 'launch_button' })}
      btnServiceHref={`${ROUTING.fleet.dashboardService24.path}?launch=-1`}
      btnRequest={getField({ base: basePage, field: 'dashboard_button' })}
      btnRequestHref={`${ROUTING.fleet.dashboardService24.path}`}
    />
  );
};

export default MyServices__repairApproval;
