
import React, { useContext, useEffect, useState } from 'react';
import Nav from 'components/global/nav';

import { ContentContext } from 'context/contentContext';

import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Transition__loader_controller } from 'components/transition/transition';
import { strapiAppointment, strapiMapPage } from 'util/strapi/content.starpi';
import { Appointment__calendar } from 'containers/appointment/appointment';

/**
 * @name Appointment
 */

const Appointment = () => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    loadPage, haveContentFor,
  } = useContext(ContentContext);

  const loadContent = async () => {
    if (haveContentFor(strapiMapPage).success === false) {
      await loadPage(strapiMapPage);
    }
    if (haveContentFor(strapiAppointment).success === false) {
      await loadPage(strapiAppointment);
    }
  };


  useEffect(() => {
    loadContent();
  }, []);

  if (haveContentFor(strapiAppointment).success === false || haveContentFor(strapiMapPage).success === false) {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container>
      <Nav active="appointment" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <Layout__content>
          <Appointment__calendar
            basePage={strapiAppointment}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isFleet
          />
        </Layout__content>
      </Layout__wrapper>
    </Layout__container>
  );
};

export default Appointment;
