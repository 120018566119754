
import React, {
  useEffect, useState, Fragment, useContext,
} from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { Grid__separator } from 'components/grid/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTruck, faTools, faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import Content__labelText from 'components/content/labelText/content__labelText';
import Content__labelTextarea from 'components/content/labelTextarea/content__labelTextarea';
import Content__serviceStatus from 'components/content/serviceStatus/content__serviceStatus';
import { GetSingleService } from 'helpers/api/routes/FairPrice';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { Button__inline } from 'components/button/button';
import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import Content__tag from 'components/content/tag/content__tag';
import {
  strapiFairPriceService, strapiPaymentGuaranteeService, strapiPriority90Service, strapiRepairApprovalService, strapiService24Service, strapiStandartrepairService,
} from 'util/strapi/content.starpi';
import { parseStatus } from 'util/conditions/statusProps.util';
import { sequelizeDate } from 'util/date.util';
import { parseFile } from 'util/format/parseFile.format';
import { isWaiting } from 'util/conditions/checkstatus.condition.js';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import {
  Header,
  Title,
  ItemContainer,
  Row,
  Popup,
} from 'containers/fleetView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import Button__primary from 'components/button/primary/button__primary';
import DashboardStandardRepair__singlePop from 'containers/fleetView/dashboardStandardRepair/singlePop/dashboardStandardRepair__singlePop';
import DashboardRepairApproval__singlePop from 'containers/fleetView/dashboardRepairApproval/singlePop/dashboardRepairApproval__singlePop';
import DashboardService24__singlePop from 'containers/fleetView/dashboardService24/singlePop/dashboardService24__singlePop';
import DashboardPriority90__singlePop from 'containers/fleetView/dashboardPriority90/singlePop/dashboardPriority90__singlePop';
import DashboardPaymentGuarantee__singlePop from 'containers/fleetView/dashboardPaymentGuarantee/singlePop/dashboardPaymentGuarantee__singlePop';
import { SERVICENAME } from 'util/service/serviceName';
import { AppContext } from 'context/appContext';

const Container = styled.div``;

const Column = styled.div``;

const ServiceStatusTitle = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
`;

const ServiceStatusContent = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  border-radius: 5px;
`;

const DashboardFairPrice__singlePop = ({
  style, active, closeEvent, id,
}) => {
  const { user } = useContext(AppContext);
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(false);
  const [activesinglePop, setactivesinglePop] = useState({
    serviceName: '',
    service: null,
    active: false,
    id: null,
  });
  const {
    getFieldGroup, loadPage, haveContentFor, getField, getGlobal,
  } = useContext(ContentContext);
  const getService = async () => {
    const res = await GetSingleService({ id });
    if (res) {
      setdata(res.data.data);
      setloading(false);
    }
  };

  useEffect(() => {
    if (haveContentFor(strapiFairPriceService).success === false) {
      loadPage(strapiFairPriceService);
    }
  }, []);

  useEffect(() => {
    if (active === true) {
      setloading(true);
      getService();
    }
  }, [active]);

  const renderLoader = () => <Loader__wrapper />;

  if (
    haveContentFor(strapiFairPriceService).success === false
  ) {
    return (
      <Container style={style}>
        <Popup active={active} closeEvent={closeEvent}>
          {renderLoader()}
        </Popup>
      </Container>
    );
  }

  const getName = () => {
    if (data.paymentguarantee) {
      return {
        name: getField({ base: strapiPaymentGuaranteeService, field: 'service_title' }),
        uid: data.paymentguarantee.uid,
        id: data.paymentguarantee.id,
        date: sequelizeDate({ date: data.paymentguarantee.createdAt, format: 'd/m/y' }),
        slug: SERVICENAME.paymentguarantee,
      };
    }
    if (data.priority90) {
      return {
        name: getField({ base: strapiPriority90Service, field: 'service_title' }),
        uid: data.priority90.uid,
        id: data.priority90.id,
        date: sequelizeDate({ date: data.priority90.createdAt, format: 'd/m/y' }),
        slug: SERVICENAME.priority90,
      };
    }
    if (data.repairApproval) {
      return {
        name: getField({ base: strapiRepairApprovalService, field: 'service_title' }),
        uid: data.repairApproval.uid,
        id: data.repairApproval.id,
        date: sequelizeDate({ date: data.repairApproval.createdAt, format: 'd/m/y' }),
        slug: SERVICENAME.repairapproval,
      };
    }
    if (data.service24) {
      return {
        name: getField({ base: strapiService24Service, field: 'service_title' }),
        uid: data.service24.uid,
        id: data.service24.id,
        date: sequelizeDate({ date: data.service24.createdAt, format: 'd/m/y' }),
        slug: SERVICENAME.service24,
      };
    }
    if (data.standardRepair) {
      return {
        name: getField({ base: strapiStandartrepairService, field: 'service_title' }),
        uid: data.standardRepair.uid,
        id: data.standardRepair.id,
        date: sequelizeDate({ date: data.standardRepair.createdAt, format: 'd/m/y' }),
        slug: SERVICENAME.standardrepair,
      };
    }

    return {
      name: null,
      uid: null,
      date: null,
    };
  };

  const openPopUp = () => {
    setactivesinglePop({
      serviceName: getName().slug,
      service: null,
      active: true,
      id: getName().id,
    });
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({
      serviceName: '',
      service: null,
      active: false,
      id: null,
    });
  };

  const renderContent = () => (
    <Fragment>
      <Header>
        <Title>{getField({ base: strapiFairPriceService, field: 'service_title' })}</Title>
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_id' })}
          value={data.uid}
        />
        <Grid__separator width="15px" />
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_date' })}
          value={sequelizeDate({ date: data.createdAt, format: 'd/m/y' })}
        />
      </Header>
      <Grid__separator size="80px" />
      <Content__serviceStatus
        tag={(
          <Content__tag
            border
            content={getFieldGroup({
              base: strapiFairPriceService,
              groupe: 'status',
              field: data.status,
            })}
            {...parseStatus({ status: data.status })}
          />
        )}
        {...parseStatus({ status: data.status })}
      >
        {!isWaiting(data.status) && (<ServiceStatusTitle>{getField({ base: strapiFairPriceService, field: 'response_text' })}</ServiceStatusTitle>)}
        <Grid__separator size="10px" />
        <ServiceStatusContent>
          {!isWaiting(data.status) ? data.statusExtra : getField({ base: strapiFairPriceService, field: 'waiting_text' })}
        </ServiceStatusContent>
      </Content__serviceStatus>
      <Grid__separator size="100px" />
      <Row>
        <ItemContainer>
          <Content__sectionTitle
            line
            title={getField({ base: strapiFairPriceService, field: 'single_service_vehicleTitle' })}
            icon={<FontAwesomeIcon icon={faTruck} />}
          />
          <Row>
            <Column>
              <Content__labelText
                label={getField({ base: strapiFairPriceService, field: 'single_service_serviceName' })}
                content={getName().name}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getField({ base: strapiFairPriceService, field: 'single_service_requestId' })}
                content={getName().uid}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getField({ base: strapiFairPriceService, field: 'single_service_requestDate' })}
                content={getName().date}
              />
              <Grid__separator size="30px" />
              <Button__primary border blue event={openPopUp}>{getField({ base: strapiFairPriceService, field: 'open_request_button' }) || 'Open request'}</Button__primary>
            </Column>
          </Row>
        </ItemContainer>
      </Row>
      <Grid__separator size="100px" />
      <Content__sectionTitle
        line
        title={getField({ base: strapiFairPriceService, field: 'single_service_repairTitle' })}
        icon={<FontAwesomeIcon icon={faTools} />}
      />
      <Row>
        <Column style={{ width: '60%' }}>
          <Content__labelTextarea
            label={getField({ base: strapiFairPriceService, field: 'Single_service_description_title' })}
            lang={data.textLang}
            convert={user.user.lang}
            content={data.description}
            dataName={SERVICENAME.fairPrice}
            base={data.translate}
            id={data.id}
          />
          <Grid__separator size="30px" />
          {
            data.file && (
              <Content__labelText
                label={getField({ base: strapiFairPriceService, field: 'single_service_filePriceLabel' })}
                content={(
                  <Button__inline
                    content={getField({ base: strapiFairPriceService, field: 'single_service_filePrice_button' })}
                    icon={<FontAwesomeIcon icon={faDownload} />}
                    download
                    href={parseFile(data.file).url}
                  />
                )}
              />
            )
          }
        </Column>
      </Row>
    </Fragment>
  );

  return (
    <Container style={style}>
      <Popup active={active} closeEvent={closeEvent}>
        {loading || !data ? renderLoader() : renderContent()}
      </Popup>
      <DashboardStandardRepair__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.standardrepair
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardRepairApproval__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.repairapproval
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardService24__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.service24
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardPriority90__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.priority90
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
      <DashboardPaymentGuarantee__singlePop
        active={
          activesinglePop.active && activesinglePop.serviceName === SERVICENAME.paymentguarantee
        }
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
    </Container>
  );
};

export default DashboardFairPrice__singlePop;
