import { API_URL } from '../Utile';
import { CallGet, CallPost } from '../Helpers';

export const addEvent = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = payload;

  const url = `${API_URL}appointment/create/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const listEvent = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = payload;

  const url = `${API_URL}appointment/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const findEvent = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = payload;

  const url = `${API_URL}appointment/${payload.id}`;

  const res = await CallGet(url, {}, headers);

  return res;
};

export const findTodayAppointment = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = payload;

  const url = `${API_URL}appointment/find_today_appointment`;

  const res = await CallGet(url, {}, headers);

  return res;
};

export const findScheduleAppointment = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = payload;

  const url = `${API_URL}appointment/find_schedule_appointment`;

  const res = await CallGet(url, {}, headers);

  return res;
};

export const closeTodayAppointment = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = payload;

  const url = `${API_URL}appointment/close_today_appointment`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const DeclineAppointment = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = payload;

  const url = `${API_URL}appointment/${payload.id}/refuse`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const ApproveAppointment = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = payload;

  const url = `${API_URL}appointment/${payload.id}/approve`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const ApproveRescheduleAppointment = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = payload;

  const url = `${API_URL}appointment/${payload.id}/approve_reschedule`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const RescheduleAppointment = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const params = payload;

  const url = `${API_URL}appointment/${payload.id}/reschedule`;

  const res = await CallPost(url, params, headers);

  return res;
};
