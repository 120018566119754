import mixins from './styled';
import config from './config';
import image from './image';

/**
 * Color
 */

export default {
  color: config.color,
  mixins,
  break: config.breakpoint,
  font: config.font,
  ease: config.ease,
  grid: config.grid,
  image,
};
