import {
  SmartMaintenanceReq,
  AlertGasoilReq,
  ProFleetAssistReq,
  TpmsReq,
} from './request/LeadGeneration';
import { SERVICENAME } from '../../../util/service/serviceName';
import {
  VehicleReq,
  ServiceHistoryReq,
  MyFleetUserReq,
  GetActionWorkshopReq,
} from './request/Data';

import {
  FairePriceReq,
  StandardRepairReq,
  Priority90Req,
  Service24Req,
  RepairApprovalReq,
  PaymentguaranteeReq,
} from './request/Services';
import { GetCasesReq } from './request/Cases';

export const switchReq = async ({
  service, page, filter, limit, params,
}) => {
  if (service === SERVICENAME.fairPrice) {
    const res = await FairePriceReq({
      page, filter, limit, params,
    });

    return res;
  } if (service === SERVICENAME.standardrepair) {
    const res = await StandardRepairReq({
      page, filter, limit, params,
    });

    return res;
  } if (service === SERVICENAME.priority90) {
    const res = await Priority90Req({
      page, filter, limit, params,
    });

    return res;
  } if (service === SERVICENAME.service24) {
    const res = await Service24Req({
      page, filter, limit, params,
    });

    return res;
  } if (service === SERVICENAME.smartmaintenance) {
    const res = await SmartMaintenanceReq({
      page, filter, limit, params,
    });

    return res;
  } if (service === SERVICENAME.alertgasoil) {
    const res = await AlertGasoilReq({
      page, filter, limit, params,
    });

    return res;
  } if (service === SERVICENAME.profleetassist) {
    const res = await ProFleetAssistReq({
      page, filter, limit, params,
    });

    return res;
  } if (service === SERVICENAME.tpms) {
    const res = await TpmsReq({
      page, filter, limit, params,
    });

    return res;
  } if (service === SERVICENAME.repairapproval) {
    const res = await RepairApprovalReq({
      page, filter, limit, params,
    });

    return res;
  } if (service === SERVICENAME.paymentguarantee) {
    const res = await PaymentguaranteeReq({
      page, filter, limit, params,
    });

    return res;
  } if (service === 'vehicle') {
    const res = await VehicleReq({
      page, filter, limit, params,
    });

    return res;
  } if (service === 'historic') {
    const res = await ServiceHistoryReq({
      page, filter, limit, params,
    });

    return res;
  } if (service === 'myFleetUser') {
    const res = await MyFleetUserReq({
      page, filter, limit, params,
    });

    return res;
  } if (service === 'getActionWorkshop') {
    const res = await GetActionWorkshopReq({
      page, filter, limit, params,
    });

    return res;
  } if (service === 'cases') {
    const res = await GetCasesReq({
      page, filter, limit, params,
    });

    return res;
  }

  return null;
};
