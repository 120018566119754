export const ROUTING = {

  /** Fleet VIEW */
  fleet: {
    account: {
      pageName: 'account',
      path: '/my-account',
    },
    addServices: {
      pageName: 'AddServices',
      path: '/add-services',
    },
    map: {
      pageName: 'workshops-map',
      path: '/workshops-map',
    },
    appointment: {
      pageName: 'appointment',
      path: '/appointment',
    },
    dashboardFairPrice: {
      pageName: 'DashboardFairPrice',
      path: '/fair-price',
    },
    dashboardStandardRepair: {
      pageName: 'DashboardStandardRepair',
      path: '/standard-repair',
    },
    dashboardRepairApproval: {
      pageName: 'DashboardRepairApproval',
      path: '/repair-approval',
    },
    dashboardPriority90: {
      pageName: 'DashboardPriority90',
      path: '/priority-90',
    },
    dashboardService24: {
      pageName: 'DashboardService24',
      path: '/service-24',
    },
    dashboardPaymentGuarantee: {
      pageName: 'DashboardPaymentGuarantee',
      path: '/payment-guarantee',
    },
    dashboardSmartMaintenance: {
      pageName: 'DashboardSmartMaintenance',
      path: '/smart-maintenance',
    },
    dashboardAlertGasoil: {
      pageName: 'DashboardAlertGasoil',
      path: '/alert-gasoil',
    },
    dashboardProFleetAssist: {
      pageName: 'DashboardProFleetAssist',
      path: '/profleet-assist',
    },
    dashboardTpms: {
      pageName: 'DashboardTpms',
      path: '/tpms',
    },
    home: {
      pageName: 'home',
      path: '/',
    },
    homeBase: {
      pageName: 'homeBase',
      path: '/home-based',
    },
    login: {
      pageName: 'login',
      path: '/login',
    },
    logAs: {
      pageName: 'dime-typist-farmland-bookend',
      path: '/dime-typist-farmland-bookend/:token',
    },
    memberCard: {
      pageName: 'memberCard',
      path: '/member-card',
    },
    myFleet: {
      pageName: 'myFleet',
      path: '/my-fleet',
    },
    myServices: {
      pageName: 'myServices',
      path: '/my-services',
    },
    passwordRecovery: {
      pageName: 'passwordRecovery',
      path: '/password-recovery',
    },
    resetPassword: {
      pageName: 'resetPassword',
      path: '/reset-password/:passToken',
      withoutparams: '/reset-password',
    },
    register: {
      pageName: 'register',
      path: '/register',
    },
    registerFinal: {
      pageName: 'registerFinal',
      path: '/registration-finalization/:validationUrl',
      withoutparams: '/registration-finalization',
    },
    generalCondition: {
      pageName: 'generalCondition',
      path: '/general-condition',
    },
    gdpr: {
      pageName: 'gdpr',
      path: '/gdpr',
    },
    conditionPaymentGuarantee: {
      pageName: 'conditionPaymentGuarantee',
      path: '/condition-payment-guarantee',
    },
    conditionPriority90: {
      pageName: 'conditionPriority90',
      path: '/condition-priority-90',
    },
    conditionRepairApproval: {
      pageName: 'conditionRepairApproval',
      path: '/condition-repair-approval',
    },
    conditionService24: {
      pageName: 'conditionService24',
      path: '/condition-service-24',
    },
  },

  workshop: {
    home: {
      pageName: 'home',
      path: '/',
    },
    checkVehicle: {
      pageName: 'checkVehicle',
      path: '/check-vehicle',
    },
    cases: {
      pageName: 'cases',
      path: '/my-cases',
    },
    singleCases: {
      pageName: 'singleCase',
      path: '/my-cases/:id',
    },
    myFleets: {
      pageName: 'myFleets',
      path: '/my-fleets',
    },
    joinServices: {
      pageName: 'joinServices',
      path: '/join-services',
    },
    myAccount: {
      pageName: 'myAccount',
      path: '/my-account',
    },
    dashboardStandardRepair: {
      pageName: 'DashboardStandardRepair',
      path: '/standard-repair',
    },
    dashboardRepairApproval: {
      pageName: 'DashboardRepairApproval',
      path: '/repair-approval',
    },
    dashboardPriority90: {
      pageName: 'DashboardPriority90',
      path: '/priority-90',
    },
    dashboardPaymentGuarantee: {
      pageName: 'DashboardPaymentGuarantee',
      path: '/payment-guarantee',
    },
    dashboardSmartMaintenance: {
      pageName: 'DashboardSmartMaintenance',
      path: '/smart-maintenance',
    },
    dashboardAlertGasoil: {
      pageName: 'DashboardAlertGasoil',
      path: '/alert-gasoil',
    },
    dashboardProFleetAssist: {
      pageName: 'DashboardProFleetAssist',
      path: '/profleet-assist',
    },
    dashboardTpms: {
      pageName: 'DashboardTpms',
      path: '/tpms',
    },
    dashboardService24: {
      pageName: 'DashboardService24',
      path: '/service-24',
    },
  },

  ext: {
    repairApprovalConfirmation: {
      pageName: 'repairApprovalConfirmation',
      path: '/confirmation/repair-approval/:id',
      withoutparams: '/confirmation/repair-approval',
    },
    smartMaintenanceConfirmation: {
      pageName: 'smartMaintenanceConfirmation',
      path: '/confirmation/smart-maintenance/:id',
      withoutparams: '/confirmation/smart-maintenance/',
    },
    alertGasoilConfirmation: {
      pageName: 'alertGasoilConfirmation',
      path: '/confirmation/alert-gasoil/:id',
      withoutparams: '/confirmation/alert-gasoil/',
    },
    proFleetAssistConfirmation: {
      pageName: 'proFleetAssistConfirmation',
      path: '/confirmation/profleet-assist/:id',
      withoutparams: '/confirmation/profleet-assist/',
    },
    tpmsConfirmation: {
      pageName: 'tpmsConfirmation',
      path: '/confirmation/tpms/:id',
      withoutparams: '/confirmation/tpms/',
    },
    paymentGuaranteeConfirmation: {
      pageName: 'paymentGuaranteeConfirmation',
      path: '/confirmation/payment-guarantee/:id',
      withoutparams: '/confirmation/payment-guarantee',
    },
    obligoConfirmation: {
      pageName: 'obligoConfirmation',
      path: '/confirmation/obligo/:id',
      withoutparams: '/confirmation/obligo',
    },
  },
};
