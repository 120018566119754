
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
/**
 * Import Context
 */

import { ContentContext } from 'context/contentContext';
/**
 * import containers & components
 */
import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';
import Nav from 'components/global/nav';
import { Transition__loader_controller } from 'components/transition/transition';
import { Layout__wrapper, Layout__content, Layout__container } from 'containers/layout/layout';
import { Grid__separator } from 'components/grid/grid';
import {
  strapiAccountPage, strapiLogin, strapiMapPage, strapiRegister,
} from 'util/strapi/content.starpi';
import { AppContext } from 'context/appContext';
import { isWorkshop } from 'util/conditions/role.condition';
import {
  Account__mainInformation,
  Account__sideInformation,
} from 'containers/fleetView/account/account';
import Content__externalLinks from 'components/content/externalLinks/content__externalLinks';
import { strapiContract } from '../../util/strapi/content.starpi';


const LayoutContent = styled(Layout__content)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
`;

const PageLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 65px;
`;

/**
 * @name Account
 */

const Account = () => {
  const { loadPage, haveContentFor, getField } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const loadContent = async () => {
    if (haveContentFor(strapiAccountPage).success === false) {
      await loadPage(strapiAccountPage);
    }
    if (haveContentFor(strapiLogin).success === false) {
      await loadPage(strapiLogin);
    }
    if (haveContentFor(strapiMapPage).success === false) {
      await loadPage(strapiMapPage);
    }
    if (haveContentFor(strapiContract).success === false) {
      await loadPage(strapiContract);
    }
    if (haveContentFor(strapiRegister).success === false) {
      await loadPage(strapiRegister);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (
    haveContentFor(strapiAccountPage).success === false
    || haveContentFor(strapiLogin).success === false
    || haveContentFor(strapiMapPage).success === false
    || haveContentFor(strapiRegister).success === false
    || haveContentFor(strapiContract).success === false
  ) {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container>
      <Transition__loader_controller active={false} />
      {isWorkshop(user) ? (
        <Nav active="myAccount" workshop />
      ) : (
        <Nav active="account" />
      )}
      <Layout__wrapper>
        <LayoutContent>
          <Content__pageTitle
            content={getField({ base: strapiAccountPage, field: 'Page_title' })}
          />
          <Grid__separator size="40px" />
          <PageLayout>
            <Account__mainInformation basePage={strapiAccountPage} />
            <Account__sideInformation basePage={strapiAccountPage} />
          </PageLayout>
          <Grid__separator size="55px" />
          <Content__externalLinks blue />
        </LayoutContent>
      </Layout__wrapper>
    </Layout__container>
  );
};

export default Account;
