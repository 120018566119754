import React, {
  useContext, useEffect, Fragment, useState,
} from 'react';

import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import Content__tag from 'components/content/tag/content__tag';

import Cards__listCard from 'components/cards/listCard/cards__listCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons';

import { sequelizeDate } from 'util/date.util';
import { parseStatus } from 'util/conditions/statusProps.util';
import { strapiPaymentGuaranteeService, strapiServiceHeader } from 'util/strapi/content.starpi';
import { ROUTING } from 'util/constant/routing.constant';
import { handleLineClick_singleNew } from 'util/function/openSingleService';
import DashboardPaymentGuarantee__singlePop from 'containers/fleetView/dashboardPaymentGuarantee/singlePop/dashboardPaymentGuarantee__singlePop';
import List__content from 'components/list/content/list__content';
import { SERVICENAME } from 'util/service/serviceName';
import { getFormService } from 'util/function/getter';
import { useData } from 'components/data/getter/data__getter';


const MyServices__paymentGuarantee = ({ basePage }) => {
  const {
    loadPage, haveContentFor, getField, getFieldGroup, getGlobal,
  } = useContext(ContentContext);

  const { user } = useContext(AppContext);
  const [activesinglePop, setactivesinglePop] = useState({
    active: false,
    id: null,
  });


  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      id: user.user.UserFleet.id,
      user: true,
    },
    service: SERVICENAME.paymentguarantee,
    type: 'service',
    limite: 3,
  });

  useEffect(() => {
    if (haveContentFor(strapiPaymentGuaranteeService).success === false) {
      loadPage(strapiPaymentGuaranteeService);
    }
  }, []);

  const headerCol = [
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'vehicle_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'date_header' }),
    },
    {
      label: getGlobal({ option: strapiServiceHeader, field: 'status_header' }),
    },
  ];

  const renderList = () => {
    const orderArray = resData.data;
    const arrayOfLine = [];

    if (orderArray) {
      orderArray.forEach((element) => {
        arrayOfLine.push([
          {
            component: (
              <List__content uppercase>{getFormService(element, 'registrationNumber', 'vehicle')}</List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <List__content>
                {sequelizeDate({ date: getFormService(element, 'createdAt'), format: 'd/m/y' })}
              </List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <Content__tag
                content={getFieldGroup({
                  base: strapiPaymentGuaranteeService,
                  groupe: 'status',
                  field: getFormService(element, 'status'),
                })}
                {...parseStatus({ status: getFormService(element, 'status') })}
              />
            ),
            type: 'node',
          },
        ]);
      });
    }

    return arrayOfLine;
  };


  const handleLine = (line) => {
    handleLineClick_singleNew({
      line,
      array: resData.data,
      callback: ({ active, id }) => setactivesinglePop({
        active,
        id,
      }),
    });
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({
      active: false,
      id: null,
    });
  };

  return (
    <Fragment>
      <Cards__listCard
        countValue={0}
        headerCol={headerCol}
        line={resData && resData.data ? renderList() : []}
        serviceName="paymentguarantee"
        icon={<FontAwesomeIcon icon={faShieldCheck} />}
        title={getField({ base: strapiPaymentGuaranteeService, field: 'service_title' })}
        buttonRequest={getField({ base: basePage, field: 'dashboard_button' })}
        buttonRequestHref={`${ROUTING.fleet.dashboardPaymentGuarantee.path}`}
        loading={!haveContentFor(strapiPaymentGuaranteeService).success || resData.loading}
        hasLoader
        lineEvent={handleLine}
        fleet
        width="380px"
      />
      <DashboardPaymentGuarantee__singlePop
        active={activesinglePop.active}
        id={activesinglePop.id}
        closeEvent={handleCloseSinglePop}
      />
    </Fragment>
  );
};

export default MyServices__paymentGuarantee;
