import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';

export const getObligoTotal = ({ data }) => {
  let obligoAmount = 0;
  let obligoUsed = 0;

  data.forEach((element) => {
    if (element && element.UserFleet) {
      if (element.UserFleet.obligoStatus === SERVICE_STATUS.done) {
        if (element.UserFleet.obligoAmount) {
          if (element.UserFleet.obligoAmount) {
            obligoAmount += parseInt(element.UserFleet.obligoAmount, 10);
          }
          if (element.UserFleet.obligoAvailable) {
            obligoUsed += parseInt(element.UserFleet.obligoAvailable, 10);
          }
        }
      }
    }
  });

  return {
    available: obligoAmount - obligoUsed,
    used: obligoUsed,
    amount: obligoAmount,
  };
};
