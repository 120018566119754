
import React, { createContext } from 'react';
import { GetPageContent } from 'helpers/api/Routes';
import { strapiType } from 'util/strapi/type.strapi';
import { createObj, createOption } from 'util/strapi/createObj.strapi';
import { getNavigatorLang } from 'util/function/getNavigatorLang.function';
import moment from 'moment/min/moment-with-locales';
/**
   * initial state
   */
export const ContentContext = createContext({
  content: '',
  page: {},
  availableLang: [],
  global: {},
  changeLang: () => {},
  service: () => {},
  haveContentFor: () => {},
  loadPage: () => {},
  loadGlobal: () => {},
  getAvailable: () => {},
  getPage: () => {},
  getField: () => {},
  getFiles: () => {},
  getFieldWithNavigatorLang: () => {},
  getGlobal: () => {},
  getGlobalAllLang: () => {},
  getGlobalObj: () => {},
  getFieldGroup: () => {},
  getFieldGroupLang: () => {},
  getFieldGroupWithNavigatorLang: () => {},
});

let asGlobal = false;

/**
 * Provider
 */
export class ContentProvider extends React.Component {
    // eslint-disable-next-line react/state-in-constructor
    state = {
      content: {},
      base: {},
      lang: 'en',
      defaultLang: 'en',
      langOld: 'fr',
      page: {},
      availableLang: [],
      global: {},
      changeLang: (value) => this.changeLang(value),
      haveContentFor: (key) => this.haveContentFor(key),
      loadPage: (key) => this.loadPage(key),
      loadGlobal: (ignore) => this.loadGlobal(ignore),
      getAvailable: () => this.getAvailable(),
      getPage: (key) => this.getPage(key),
      getField: (key) => this.getField(key),
      getFiles: (key) => this.getFiles(key),
      getFieldWithNavigatorLang: (key) => this.getFieldWithNavigatorLang(key),
      getGlobalWithNavigatorLang: (key) => this.getGlobalWithNavigatorLang(key),
      getGlobal: (key) => this.getGlobal(key),
      getGlobalAllLang: (key) => this.getGlobalAllLang(key),
      getGlobalObj: (key) => this.getGlobalObj(key),
      getFieldGroup: (key) => this.getFieldGroup(key),
      getFieldGroupLang: (key) => this.getFieldGroupLang(key),
      getFieldGroupWithNavigatorLang: (key) => this.getFieldGroupWithNavigatorLang(key),
    };

    changeLang = async (lang) => {
      this.setState({ lang });
      moment.locale([lang, 'en']);
    };

    /* --------------------------------- STRAPI --------------------------------- */

    haveContentFor = ({ pageName, view }) => {
      const { page } = this.state;
      if (page) {
        if (page.hasOwnProperty(view)) {
          if (page[view]) {
            if (page[view].hasOwnProperty(pageName)) {
              return {
                success: true,
                data: page[view][pageName],
              };
            }
          }
        }
      }

      return {
        success: false,
        data: null,
      };
    }

    /* -------------------------- LOADER GLOBAL CONTENT ------------------------- */

    loadGlobal = async (ignore) => {
      const { global } = this.state;
      if (asGlobal === false || ignore) {
        const res = await GetPageContent({ pageName: strapiType.page.global.main });
        if (res.success === true) {
          const strapiContent = createOption({ data: res.res.data });
          this.setState((prevState) => ({
            ...prevState,
            global: strapiContent,
          }));

          return {
            sucess: true,
            data: strapiContent,
          };
        }
        return {
          sucess: false,
          data: null,
        };
      }

      return {
        sucess: true,
        data: global,
      };
    }

    /* ---------------------------- LOAD PAGE CONTENT --------------------------- */

    loadPage = async ({ pageName, view }) => {
      const resOption = await this.loadGlobal();
      const { page } = this.state;
      asGlobal = true;
      if (resOption.sucess === true) {
        const res = await GetPageContent({ pageName: strapiType.page[view][pageName] });
        if (res.success === true) {
          const strapiContent = createObj({ data: res.res.data, page: strapiType.page[view][pageName] });
          this.setState((prevState) => ({
            ...prevState,
            page: {
              ...prevState.page,
              [view]: {
                ...prevState.page[view],
                [pageName]: strapiContent,
              },
            },
          }), () => {
            this.setAvailable(page);
          });


          return {
            sucess: true,
            data: strapiContent,
          };
        }
      }
      return {
        sucess: false,
        data: {},
      };
    }

    /* ---------------------------- GET PAGE CONTENT ---------------------------- */

    getPage = ({ pageName, view }) => {
      const { page, lang } = this.state;
      if (page) {
        if (page.hasOwnProperty(view)) {
          if (page[view]) {
            if (page[view].hasOwnProperty(pageName)) {
              return {
                success: true,
                data: page[view][pageName][lang],
              };
            }
          }
        }
      }

      return {
        success: false,
        data: null,
      };
    }

    capitalize = (field) => {
      if (field) {
        return field.toLowerCase().charAt(0).toUpperCase() + field.slice(1);
      }

      return field;
    }

    /* ------------------------------- GET FIELDS ------------------------------- */

    getField = ({ base, field }) => {
      const { page, lang, defaultLang } = this.state;
      if (page) {
        if (page.hasOwnProperty(base.view)) {
          if (page[base.view]) {
            if (page[base.view].hasOwnProperty(base.pageName)) {
              let langVar = lang;
              if (!page[base.view][base.pageName].hasOwnProperty(lang)) {
                langVar = defaultLang;
              }
              if (page[base.view][base.pageName].hasOwnProperty(langVar)) {
                if (page[base.view][base.pageName][langVar].hasOwnProperty(field)) {
                  return page[base.view][base.pageName][langVar][field];
                } if (page[base.view][base.pageName][langVar].hasOwnProperty(this.capitalize(field))) {
                  return page[base.view][base.pageName][langVar][this.capitalize(field)];
                }
              }
            }
          }
        }
      }

      return '';
    }

    /* ------------------------------- GET FILES ------------------------------- */

    getFiles = ({ base, field, _lang }) => {
      const { page, lang, defaultLang } = this.state;
      if (page) {
        if (page.hasOwnProperty(base.view)) {
          if (page[base.view]) {
            if (page[base.view].hasOwnProperty(base.pageName)) {
              let langVar = _lang;
              if (!page[base.view][base.pageName].hasOwnProperty(_lang)) {
                langVar = lang;
              }
              if (!page[base.view][base.pageName].hasOwnProperty(langVar)) {
                langVar = defaultLang;
              }
              if (page[base.view][base.pageName].hasOwnProperty(langVar)) {
                if (page[base.view][base.pageName][langVar].hasOwnProperty(field)) {
                  if (page[base.view][base.pageName][langVar][field]) {
                    return page[base.view][base.pageName][langVar][field].url;
                  }
                } else if (page[base.view][base.pageName][langVar].hasOwnProperty(this.capitalize(field))) {
                  if (page[base.view][base.pageName][langVar][this.capitalize(field)]) {
                    return page[base.view][base.pageName][langVar][this.capitalize(field)].url;
                  }
                }
              }
            }
          }
        }
      }

      return '';
    }

    /* ------------- ------------------ GET getFieldWithNavigatorLang ------------------------------- */

    getFieldWithNavigatorLang = ({ base, field }) => {
      const { page, defaultLang } = this.state;
      if (page) {
        if (page.hasOwnProperty(base.view)) {
          if (page[base.view]) {
            if (page[base.view].hasOwnProperty(base.pageName)) {
              let langVar = getNavigatorLang().lang;
              if (!page[base.view][base.pageName].hasOwnProperty(getNavigatorLang().lang)) {
                langVar = defaultLang;
              }
              if (page[base.view][base.pageName].hasOwnProperty(langVar)) {
                if (page[base.view][base.pageName][langVar].hasOwnProperty(field)) {
                  return page[base.view][base.pageName][langVar][field];
                } if (page[base.view][base.pageName][langVar].hasOwnProperty(this.capitalize(field))) {
                  return page[base.view][base.pageName][langVar][this.capitalize(field)];
                }
              }
            }
          }
        }
        //       }
        //     }
        //   }
        // }
      }

      return '';
    }

    /* ------------------------------- GET get fild group with navigator lang ------------------------------- */

    getFieldGroupWithNavigatorLang = ({
      base, groupe, field,
    }) => {
      const { page, defaultLang } = this.state;
      if (page) {
        if (page.hasOwnProperty(base.view)) {
          if (page[base.view]) {
            if (page[base.view].hasOwnProperty(base.pageName)) {
              let langVar = getNavigatorLang().lang;
              if (!page[base.view][base.pageName].hasOwnProperty(getNavigatorLang().lang)) {
                langVar = defaultLang;
              }
              if (page[base.view][base.pageName].hasOwnProperty(langVar)) {
                if (page[base.view][base.pageName][langVar].hasOwnProperty(groupe)) {
                  if (page[base.view][base.pageName][langVar][groupe] && page[base.view][base.pageName][langVar][groupe].hasOwnProperty(field)) {
                    return page[base.view][base.pageName][langVar][groupe][field];
                  } if (page[base.view][base.pageName][langVar][groupe] && page[base.view][base.pageName][langVar][groupe].hasOwnProperty(this.capitalize(field))) {
                    return page[base.view][base.pageName][langVar][groupe][this.capitalize(field)];
                  }
                } else if (page[base.view][base.pageName][langVar].hasOwnProperty(this.capitalize(groupe))) {
                  if (page[base.view][base.pageName][langVar][this.capitalize(groupe)] && page[base.view][base.pageName][langVar][this.capitalize(groupe)].hasOwnProperty(field)) {
                    return page[base.view][base.pageName][langVar][this.capitalize(groupe)][field];
                  } if (page[base.view][base.pageName][langVar][this.capitalize(groupe)] && page[base.view][base.pageName][langVar][this.capitalize(groupe)].hasOwnProperty(this.capitalize(field))) {
                    return page[base.view][base.pageName][langVar][groupe][this.capitalize(field)];
                  }
                }
              }
            }
          }
        }
      }
      return '';
    }

    /* ------------------------------- GET FIELDS GROUP ------------------------------- */

    getFieldGroup = ({
      base, groupe, field, select_lang,
    }) => {
      const { page, lang, defaultLang } = this.state;
      let langVar = lang;
      if (select_lang) {
        langVar = select_lang;
      }
      if (page) {
        if (page.hasOwnProperty(base.view)) {
          if (page[base.view]) {
            if (page[base.view].hasOwnProperty(base.pageName)) {
              if (!page[base.view][base.pageName].hasOwnProperty(langVar)) {
                langVar = defaultLang;
              }
              if (page[base.view][base.pageName].hasOwnProperty(langVar)) {
                if (page[base.view][base.pageName][langVar].hasOwnProperty(groupe)) {
                  if (page[base.view][base.pageName][langVar][groupe] && page[base.view][base.pageName][langVar][groupe].hasOwnProperty(field)) {
                    return page[base.view][base.pageName][langVar][groupe][field];
                  } if (page[base.view][base.pageName][langVar][groupe] && page[base.view][base.pageName][langVar][groupe].hasOwnProperty(this.capitalize(field))) {
                    return page[base.view][base.pageName][langVar][groupe][this.capitalize(field)];
                  }
                } else if (page[base.view][base.pageName][langVar].hasOwnProperty(this.capitalize(groupe))) {
                  if (page[base.view][base.pageName][langVar][this.capitalize(groupe)] && page[base.view][base.pageName][langVar][this.capitalize(groupe)].hasOwnProperty(field)) {
                    return page[base.view][base.pageName][langVar][this.capitalize(groupe)][field];
                  } if (page[base.view][base.pageName][langVar][this.capitalize(groupe)] && page[base.view][base.pageName][langVar][this.capitalize(groupe)].hasOwnProperty(this.capitalize(field))) {
                    return page[base.view][base.pageName][langVar][this.capitalize(groupe)][this.capitalize(field)];
                  }
                }
              }
            }
          }
        }
      }

      return '';
    }

    getFieldGroupLang = ({
      base, groupe, field,
    }) => {
      const { page, defaultLang } = this.state;
      let langVar = defaultLang;
      if (page) {
        if (page.hasOwnProperty(base.view)) {
          if (page[base.view]) {
            if (page[base.view].hasOwnProperty(base.pageName)) {
              if (!page[base.view][base.pageName].hasOwnProperty(langVar)) {
                langVar = getNavigatorLang().lang;
              }
              if (page[base.view][base.pageName].hasOwnProperty(langVar)) {
                if (page[base.view][base.pageName][langVar].hasOwnProperty(groupe)) {
                  if (page[base.view][base.pageName][langVar][groupe] && page[base.view][base.pageName][langVar][groupe].hasOwnProperty(field)) {
                    return page[base.view][base.pageName][langVar][groupe][field];
                  } if (page[base.view][base.pageName][langVar][groupe] && page[base.view][base.pageName][langVar][groupe].hasOwnProperty(this.capitalize(field))) {
                    return page[base.view][base.pageName][langVar][groupe][this.capitalize(field)];
                  }
                } else if (page[base.view][base.pageName][langVar].hasOwnProperty(this.capitalize(groupe))) {
                  if (page[base.view][base.pageName][langVar][this.capitalize(groupe)] && page[base.view][base.pageName][langVar][this.capitalize(groupe)].hasOwnProperty(field)) {
                    return page[base.view][base.pageName][langVar][this.capitalize(groupe)][field];
                  } if (page[base.view][base.pageName][langVar][this.capitalize(groupe)] && page[base.view][base.pageName][langVar][this.capitalize(groupe)].hasOwnProperty(this.capitalize(field))) {
                    return page[base.view][base.pageName][langVar][this.capitalize(groupe)][this.capitalize(field)];
                  }
                }
              }
            }
          }
        }
      }

      return '';
    }

    /* ------------------------------- GET OPTION ------------------------------- */

    getGlobal = ({ option, field }) => {
      const { global, lang, defaultLang } = this.state;
      if (global) {
        if (global) {
          let langVar = lang;
          if (!global.hasOwnProperty(lang)) {
            langVar = defaultLang;
          }
          if (global.hasOwnProperty(langVar)) {
            if (global[langVar][option]) {
              if (global[langVar][option].hasOwnProperty(field)) {
                return global[langVar][option][field];
              } if (global[langVar][option].hasOwnProperty(this.capitalize(field))) {
                return global[langVar][option][this.capitalize(field)];
              }
            } else if (global[langVar][this.capitalize(option)]) {
              if (global[langVar][this.capitalize(option)].hasOwnProperty(field)) {
                return global[langVar][this.capitalize(option)][field];
              } if (global[langVar][this.capitalize(option)].hasOwnProperty(this.capitalize(field))) {
                return global[langVar][this.capitalize(option)][this.capitalize(field)];
              }
            }
          }
        }
      }

      return '';
    }

    getGlobalAllLang = ({ option, field }) => {
      const { global } = this.state;
      const translateArray = [];
      if (global) {
        if (global) {
          const langKey = Object.keys(global);
          langKey.forEach((singleLang) => {
            const element = global[singleLang];
            if (element.hasOwnProperty(option) && element[option]) {
              if (element[option].hasOwnProperty(field) && element[option][field]) {
                translateArray.push(element[option][field]);
              }
            }
          });

          return translateArray;
        }
      }

      return [];
    }

    /* ------------------------------- GET OPTION NAVIGATOR LANG ------------------------------- */

    getGlobalWithNavigatorLang = ({ option, field }) => {
      const { global, lang, defaultLang } = this.state;
      if (global) {
        if (global) {
          let langVar = getNavigatorLang().lang;
          if (!global.hasOwnProperty(lang)) {
            langVar = defaultLang;
          }
          if (global.hasOwnProperty(langVar)) {
            if (global[langVar][option]) {
              if (global[langVar][option].hasOwnProperty(field)) {
                return global[langVar][option][field];
              } if (global[langVar][option].hasOwnProperty(this.capitalize(field))) {
                return global[langVar][option][this.capitalize(field)];
              }
            } else if (global[langVar][this.capitalize(option)]) {
              if (global[langVar][this.capitalize(option)].hasOwnProperty(field)) {
                return global[langVar][this.capitalize(option)][field];
              } if (global[langVar][this.capitalize(option)].hasOwnProperty(this.capitalize(field))) {
                return global[langVar][this.capitalize(option)][this.capitalize(field)];
              }
            }
          }
        }
      }

      return '';
    }
    /* ------------------------------- GET OPTION OBJECT ------------------------------- */

    getGlobalObj = ({ option }) => {
      const { global, lang, defaultLang } = this.state;
      if (global) {
        if (global) {
          let langVar = lang;
          if (!global.hasOwnProperty(lang)) {
            langVar = defaultLang;
          }
          if (global.hasOwnProperty(langVar)) {
            if (global[langVar][option]) {
              const object = {};
              const keysObj = Object.entries(global[langVar][option]);

              keysObj.forEach((element) => {
                if (element[0] !== 'id') {
                  // eslint-disable-next-line prefer-destructuring
                  object[element[0]] = element[1];
                }
              });
              return object;
            } if (global[langVar][this.capitalize(option)]) {
              const object = {};
              const keysObj = Object.entries(global[langVar][this.capitalize(option)]);

              keysObj.forEach((element) => {
                if (element[0] !== 'id') {
                  // eslint-disable-next-line prefer-destructuring
                  object[element[0]] = element[1];
                }
              });
              return object;
            }
          }
        }
      }

      return '';
    }

    /**
     * Get lang available
     */

    setAvailable = (page) => {
      const { availableLang } = this.state;
      const langArray = availableLang;
      Object.keys(page).forEach((single) => {
        Object.keys(page[single]).forEach((singlePage) => {
          Object.keys(page[single][singlePage]).forEach((singleLang) => {
            if (!langArray.find((asLang) => singleLang === asLang)) {
              langArray.push(singleLang);
              this.setState({
                availableLang: langArray,
              });
            }
          });
        });
      });
    }

    render() {
      const { children } = this.props;
      return (
        <ContentContext.Provider value={this.state}>
          {children}
        </ContentContext.Provider>
      );
    }
}

/**
   * consumer
   */

export const ContentConsumer = ContentContext.Consumer;
