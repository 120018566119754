import styled from 'styled-components';
import t from 'theme';
import { Content__text } from 'components/content/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content__tag from 'components/content/tag/content__tag';
import { Grid__popup } from 'components/grid/grid';

export const MainTitle = styled(Content__text)`
  font-size: 18px;
  font-weight: bold;
  color: #4d4d4d;
`;

export const Header = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'row' })}
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin-right: 10px;
  color: #4d4d4d;
`;

export const Tag = styled(Content__tag)`
  margin-right: 15px;
`;

export const Title = styled.div`
  font-weight: normal;
  font-size: 36px;
  line-height: 49px;
  color: #4d4d4d;
  margin-right: 20px;
`;

export const ItemContainer = styled.div`
  width: calc(50% - 50px);
`;

export const Row = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-start', direction: 'row' })}
`;

export const Popup = styled(Grid__popup)`
  display: flex;
  flex-direction: column;
`;
