/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';
import Cards__focusCard from 'components/cards/focusCard/cards__focusCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

const Card = styled(Cards__focusCard)`
  padding: 20px 35px 20px 35px;
  display: block;
  width: 100%;
  position: relative;
  ${t.mixins.flex({ justify: 'flex-start', direction: 'row', align: 'center' })}
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #01B5E2;
`;

const Content = styled.div`
  font-weight: normal;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 10px;
`;

const Icon = styled.div`
  font-size: 22px;
  margin-right: 35px;
  color: #01B5E2;
`;

const IconCheck = styled(FontAwesomeIcon)`
  font-size: 13px;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #01B5E2;
`;

const Cards__infoCheck = ({
  style,
  title,
  content,
  icon,
}) => (
  <Card style={style} blue>
    <Icon>{icon}</Icon>
    <div>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </div>
    <IconCheck icon={faCheck} />
  </Card>
);

export default Cards__infoCheck;
