export const calculateRemain = (available, amountprice) => {
  if (available && amountprice && parseInt(available, 10) <= parseInt(amountprice, 10)) {
    return parseInt(amountprice, 10) - parseInt(available, 10);
  }

  if (amountprice && !available) {
    return parseInt(amountprice, 10);
  }
  return 0;
};
