
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import t from 'theme';

import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';
import Service__paymentGuarentee from 'containers/fleetView/addServices/service/service__paymentGuarentee';
import Service__service24 from 'containers/fleetView/addServices/service/service__service24';
import Service__smartMaintenance from 'containers/fleetView/addServices/service/service__smartMaintenance';
import {
  strapiAddServicePage,
} from 'util/strapi/content.starpi';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { getHaveService } from 'helpers/api/routes/getData';
import { config as smartMaintenanceConfig } from 'util/constant/leadGeneration/smartMaintenance.config';
import { config as alertGasoilConfig } from 'util/constant/leadGeneration/alertGasoil.config';
import { config as proFleetAssistConfig } from 'util/constant/leadGeneration/proFleetAssist.config';
import { config as tpmsConfig } from 'util/constant/leadGeneration/tpms.config';
import LeadGeneration from 'components/services/LeadGeneration/JoinServices';

const Container = styled.div``;

const ServiceContainer = styled.div`
  margin-top: 40px;
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', wrap: 'wrap' })};
`;

const EmptyService = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.2);
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 310px;
  height: 360px;
  ${t.mixins.flex({ justify: 'center', align: 'center' })};
`;

const AddServices__servicesContainer = ({
  style,
  basePage,
  baseSingleService,
  basePaimentguarantee,
}) => {
  const { getField } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [service24, setservice24] = useState('loading');
  const [paymentGurantee, setpaymentGurantee] = useState('loading');


  const haveService24 = async () => {
    const res = await getHaveService({
      service: 'service24Subscription',
      userId: user.user.UserFleet.id,
    });
    const formated = formatApiRes(res);

    if (formated.success && formated.data) {
      setservice24('have');
    } else {
      setservice24(false);
    }

    return true;
  };

  const havePaymentGuarantee = async () => {
    const res = await getHaveService({
      service: 'paymentguaranteeSubscription',
      userId: user.user.UserFleet.id,
    });
    const formated = formatApiRes(res);

    if (formated.success && formated.data) {
      setpaymentGurantee('have');
    } else {
      setpaymentGurantee(false);
    }

    return true;
  };
  const startReq = async () => {
    await haveService24();
    await havePaymentGuarantee();
  };
  useEffect(() => {
    startReq();
  }, []);

  return (
    <Container style={style}>
      <Content__pageTitle content={getField({ base: basePage, field: 'page_title' })} />
      <ServiceContainer>
        {service24 === false ? (
          <Service__service24
            baseSingleService={baseSingleService}
          />
        ) : null}
        {paymentGurantee === false ? (
          <Service__paymentGuarentee
            baseSingleService={baseSingleService}
            basePaimentguarantee={basePaimentguarantee}
          />
        ) : null}
        <LeadGeneration basePage={basePage} config={smartMaintenanceConfig} />
        <LeadGeneration basePage={basePage} config={proFleetAssistConfig} />
        <LeadGeneration basePage={basePage} config={alertGasoilConfig} />
        {/* <LeadGeneration basePage={basePage} config={tpmsConfig} /> */}
        <EmptyService>
          <span>{getField({ base: strapiAddServicePage, field: 'more_service_placeholder' })}</span>
        </EmptyService>
      </ServiceContainer>
    </Container>
  );
};

export default AddServices__servicesContainer;
