import { API_URL } from '../Utile';
import { CallGet, CallPost } from '../Helpers';

/* ---------------------------- GET ALL VEHICULE ---------------------------- */

export const GetSingleService = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}service/standardrepair/${payload.id}`;

  const res = await CallGet(url, params, headers);

  return res;
};

export const CreateService = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/standardrepair/`;

  const res = await CallPost(url, params, headers);

  return res;
};


export const LaunchServiceFairePrice = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/fairprice/`;

  const res = await CallPost(url, params, headers);

  return res;
};


export const createFairPriceFile = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const formData = new FormData();

  formData.append('file', payload.file.file);
  formData.append('serviceUid', payload.serviceUid);
  formData.append('fileId', payload.fileId);
  formData.append('extension', payload.extension);

  const url = `${API_URL}service/fairprice/file`;

  const res = await CallPost(url, formData, headers);

  return res;
};
