import React, {
  Fragment, useContext, useState, useEffect,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import { SendMail } from 'helpers/api/routes/Mail';
import { Grid__separator, Grid__popup } from 'components/grid/grid';
import { Content__sectionTitle } from 'components/content/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTING } from 'util/constant/routing.constant';
import {
  faTruck, faWarehouse,
} from '@fortawesome/pro-solid-svg-icons';
import Content__labelText from 'components/content/labelText/content__labelText';
import Content__tag from 'components/content/tag/content__tag';
import Content__serviceStatus from 'components/content/serviceStatus/content__serviceStatus';
import Content__statusAnwser from 'components/content/statusAnwser/content__statusAnwser';
import MyFleets__vehicle from 'containers/workshopView/myFleets/vehicle/myFleets__vehicle';
import { parseStatus } from 'util/conditions/statusProps.util';
import { ContentContext } from 'context/contentContext';
import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { AppContext } from 'context/appContext';
import { strapiStandartrepairService, strapiWorkshopMyFleet } from 'util/strapi/content.starpi';
import Message__sucess from 'components/message/sucess/message__sucess';
import { homeBaseResponse } from 'helpers/api/routes/Workshop';
import { useData } from 'components/data/getter/data__getter';


const Container = styled.div``;

const Header = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'row' })}
`;

const Title = styled.div`
  font-weight: normal;
  font-size: 36px;
  line-height: 49px;
  color: #4d4d4d;
  margin-right: 20px;
`;

const ContainerCenter = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', direction: 'column' })}
`;

const ServiceStatusTitle = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
`;

const Column = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', direction: 'column' })}
`;

const Row = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-start', direction: 'row' })}
`;

let pageNumber = 1;
const MyFleets__singlePop = ({
  style, popActive, closePop, currentFleetUser, selectVehicle,
}) => {
  const { getFieldGroup, getField, getGlobal } = useContext(ContentContext);
  const { user, getUser } = useContext(AppContext);
  const [loading, setloading] = useState(false);
  const [successAccept, setsuccessAccept] = useState(false);
  const [successRefused, setsuccessRefused] = useState(false);
  const [fleetArray, refreshData, setPage] = useData({
    params: {
      userId: null,
    },
    service: 'vehicle',
    type: 'vehicle',
  });

  const handleAccept = async () => {
    setloading(true);
    const res = await homeBaseResponse({
      fleetUserId: currentFleetUser.UserFleet.id,
      accept: true,
    });

    if (res) {
      setloading(false);
      setsuccessAccept(true);
      if (!currentFleetUser.notificationDisable) {
        SendMail({
          template: 'notification',
          templateNotification: {
            status: 'validate',
            statusLabel: getFieldGroup({
              select_lang: currentFleetUser.lang, base: strapiWorkshopMyFleet, groupe: 'mail_accepted', field: 'status',
            }),
            content: `${user.user.companyName} ${getFieldGroup({
              select_lang: currentFleetUser.lang, base: strapiWorkshopMyFleet, groupe: 'mail_accepted', field: 'content',
            })}`,
            btnContent: getFieldGroup({
              select_lang: currentFleetUser.lang, base: strapiWorkshopMyFleet, groupe: 'mail_accepted', field: 'btn',
            }),
            btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.fleet.homeBase.path}`,
          },
          subject: getFieldGroup({
            select_lang: currentFleetUser.lang, base: strapiWorkshopMyFleet, groupe: 'mail_accepted', field: 'subject',
          }),
          destination: currentFleetUser.email,
        });
      }
    }
  };

  const handleRefused = async () => {
    setloading(true);
    const res = await homeBaseResponse({
      fleetUserId: currentFleetUser.UserFleet.id,
      accept: false,
      workshopSide: true,
    });

    if (res) {
      setloading(false);
      setsuccessRefused(true);
    }
  };

  const reloadUser = async () => {
    setloading(true);
    const resUser = await getUser();
    if (resUser.success === true) {
      setloading(false);
    }
  };

  useEffect(() => {
    if (popActive === true) {
      pageNumber = 1;
      refreshData({
        userId: currentFleetUser.UserFleet.id,
      });
    }
  }, [popActive]);

  useEffect(() => () => {
    pageNumber = 1;
  }, [popActive]);

  const handleLoadNewPage = () => {
    if (fleetArray.data && !fleetArray.loading && pageNumber + 1 > 1 && fleetArray.data.length < fleetArray.count) {
      pageNumber += 1;
      setPage(pageNumber, fleetArray.data, {
        userId: currentFleetUser.UserFleet.id,
      });
    }
  };

  const handleClose = () => {
    if (successAccept || successRefused) {
      reloadUser();
    }
    if (closePop) {
      closePop();
    }
  };

  return (
    <Container style={style}>
      <Grid__popup active={popActive} flex closeEvent={handleClose}>
        {
          !successAccept && !successRefused ? (
            popActive && currentFleetUser && (
              <Fragment>
                <Header>
                  <Title>{currentFleetUser.companyName}</Title>
                </Header>
                <Grid__separator size="65px" />
                {
                  !currentFleetUser.UserFleet.favWorkshopValidation && (currentFleetUser.UserFleet.FavWorkshopStatus === SERVICE_STATUS.waiting || currentFleetUser.UserFleet.FavWorkshopStatus === SERVICE_STATUS.action) ? (
                    <Content__serviceStatus
                      tag={(
                        <Content__tag
                          style={{ position: 'absolute', top: 25, right: 25 }}
                          border
                          content={getFieldGroup({
                            base: strapiWorkshopMyFleet,
                            groupe: 'status',
                            field: 'action',
                          })}
                          blue
                        />
                      )}
                      blue
                    >
                      <div>
                        <ServiceStatusTitle>
                          {getField({ base: strapiWorkshopMyFleet, field: 'single_action_title' })}
                        </ServiceStatusTitle>
                        <Grid__separator size="10px" />
                        <Content__statusAnwser
                          action
                          subtitle2={`${getField({ base: strapiWorkshopMyFleet, field: 'single_action_subTitle' })} ${currentFleetUser.companyName}.`}
                          buttonRefused={getField({ base: strapiWorkshopMyFleet, field: 'single_action_btn_refused' })}
                          buttonAccept={getField({ base: strapiWorkshopMyFleet, field: 'single_action_btn_validate' })}
                          buttonRefused_event={handleRefused}
                          buttonAccept__event={handleAccept}
                        />
                      </div>
                    </Content__serviceStatus>
                  ) : (
                    <Content__serviceStatus
                      tag={(
                        <Content__tag
                          border
                          content={getFieldGroup({
                            base: strapiStandartrepairService,
                            groupe: 'status',
                            field: currentFleetUser.UserFleet.FavWorkshopStatus,
                          })}
                          {...parseStatus({ status: currentFleetUser.UserFleet.FavWorkshopStatus })}
                        />
                      )}
                      {...parseStatus({ status: currentFleetUser.UserFleet.FavWorkshopStatus })}
                    >
                      <Grid__separator size="8px" />
                      {currentFleetUser.companyName}
                      {' '}
                      {getField({ base: strapiWorkshopMyFleet, field: 'single_action_validate' })}
                    </Content__serviceStatus>
                  )
                }
                <Grid__separator size="65px" />
                <ContainerCenter>
                  <Content__sectionTitle
                    line
                    title={getField({ base: strapiWorkshopMyFleet, field: 'fleet_label' })}
                    icon={<FontAwesomeIcon icon={faWarehouse} />}
                  />
                  <Row>
                    <Column>
                      <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'city' })} content={currentFleetUser.city} />
                      <Grid__separator size="25px" />
                      <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'address' })} content={currentFleetUser.adress} />
                      <Grid__separator size="25px" />
                      <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'fleet_contact' })} content={`${currentFleetUser.firstName} ${currentFleetUser.lastName}`} />
                    </Column>
                    <Column>
                      <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'country' })} content={currentFleetUser.region} />
                      <Grid__separator size="25px" />
                      <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'zipcode' })} content={currentFleetUser.zipcode} />
                    </Column>
                    <Column>
                      <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })} content={`${currentFleetUser.phoneNumber}`} />
                      <Grid__separator size="25px" />
                      <Content__labelText label={getGlobal({ option: 'workshopInformation', field: 'email' })} content={currentFleetUser.email} />
                    </Column>
                  </Row>
                  <Grid__separator size="65px" />
                  <Content__sectionTitle
                    line
                    title={`${getField({ base: strapiWorkshopMyFleet, field: 'vehicle_label' })} (${fleetArray ? fleetArray.count : 0})`}
                    icon={<FontAwesomeIcon icon={faTruck} />}
                  />
                  <MyFleets__vehicle
                    vehicles={(fleetArray && Array.isArray(fleetArray.data)) ? fleetArray.data || [] : []}
                    lineEvent={selectVehicle}
                    scrollEndEvent={handleLoadNewPage}
                    loadingNewPage={fleetArray.loading}
                  />
                </ContainerCenter>
              </Fragment>
            )
          ) : successAccept ? (
            <Message__sucess
              icon={t.image.illu.check}
              title={getField({ base: strapiWorkshopMyFleet, field: 'single_pop_success_title' })}
              text={`${currentFleetUser.companyName} ${getField({ base: strapiWorkshopMyFleet, field: 'single_pop_success_text' })}`}
              buttonText={getField({ base: strapiWorkshopMyFleet, field: 'single_pop_success_btn' })}
              buttonEvent={reloadUser}
            />
          ) : successRefused ? (
            <Message__sucess
              icon={t.image.illu.error}
              title={getField({ base: strapiWorkshopMyFleet, field: 'single_pop_refused_title' })}
              text={`${getField({ base: strapiWorkshopMyFleet, field: 'single_pop_refused_text' })} ${currentFleetUser.companyName}`}
              buttonText={getField({ base: strapiWorkshopMyFleet, field: 'single_pop_refused_btn' })}
              buttonEvent={reloadUser}
            />
          ) : null
        }
        {loading && (<Loader__wrapper />)}
      </Grid__popup>
    </Container>
  );
};

export default MyFleets__singlePop;
