
/**
   * Import Lib & assets
   */

import React, { useState } from 'react';
import styled from 'styled-components';
import t from 'theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Form__select } from 'components/form/form';

const Container = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', direction: 'column' })};
`;

const IndicatorSquare = styled.span`
  display: block;
  width: 25px;
  height: 25px;
  background: #FFFFFF;
  border: 2px solid #CCCCCC;
  border-color: ${({ current, error }) => (current ? '#FFFFFF' : error ? t.color.red : '#CCCCCC')};
  border-radius: 4px;
  margin-right: 20px;
  z-index: 1;
  position: relative;
  transition: all ease 0.3s;
`;

const Content = styled.span`
  z-index: 1;
  position: relative;
  font-weight: 600;
  font-size: 18px;
  color: ${({ current, error }) => (current ? '#FFFFFF' : error ? t.color.red : '#4D4D4D')};
  transition: all ease 0.3s;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ current, error }) => (current ? '#01B5E2' : error ? '#FFFFFF' : '#FFFFFF')};
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 16px;
  transition: all ease 0.3s;
`;

const ItemIcon = styled(FontAwesomeIcon)`
  font-size: 27px;
  position: absolute;
  right: 30px;
  top: 19px;
  color: ${({ current, error }) => (current ? '#FFFFFF' : error ? t.color.red : '#4D4D4D')};
`;

const ContentContainer = styled.div`
  width:100%;
  ${t.mixins.flex({ justify: 'flex-start', align: 'center' })};
`;

const Card = styled.label`
  margin-top: 20px;
  background: ${({ current, error }) => (current ? '#01B5E2' : error ? '#FFFFFF' : '#FFFFFF')};
  box-shadow: 0px 5px 20px rgba(58, 74, 78, 0.1);
  border-radius: 10px;
  padding: 20px;
  position: relative;
  min-width: 420px;
  border: ${({ error }) => (error ? `2px solid ${t.color.red} !important` : '0')};
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', direction: 'column' })};
  cursor: pointer;
  transition: all ease 0.3s;
  &:not(:last-child) {
    margin-right: 25px;
  }
  &:hover ${IndicatorSquare} {
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
  }
  &:hover ${ItemIcon} {
    color: #FFFFFF;
    transition: all ease 0.3s;
  }
  &:hover {
    background: #01B5E2;
    transition: all ease 0.3s;
  }
  &:hover ${Content} {
    color: #FFFFFF;
    transition: all ease 0.3s;
  }
`;

const Input = styled.input`
  position: absolute;
  z-index: -1;
  top:0;
  left: 0;
`;


const Form__checkBoxCard = ({
  style, choices, choicesSecondary, inputSelect, handleChange, error,
}) => {
  const [fuel, setfuel] = useState(choicesSecondary);
  const handleCheck = (value) => {
    choices.forEach((item) => {
      if (item.slug === value) {
        if (!item.active) {
          item.active = true;
        } else {
          item.active = false;
        }
      }
    });
    handleChange({
      choices,
      fuel,
    });
  };
  const handleFuelChange = (value) => {
    setfuel(value.value);
    handleChange({
      choices,
      fuel: value.value,
    });
  };


  return (
    <Container style={style} className={error ? 'formError' : ''}>
      {choices.map((item, index) => (
        <Card current={item.active ? 1 : 0} error={error} key={index}>
          <ContentContainer>
            <IndicatorSquare current={item.active ? 1 : 0} error={error}>
              <Icon icon={faCheck} current={item.active ? 1 : 0} error={error} />
            </IndicatorSquare>
            <Content current={item.active ? 1 : 0} error={error}>{item.label}</Content>
            <ItemIcon icon={item.icon} current={item.active ? 1 : 0} error={error} />
          </ContentContainer>
          <Input
            type="checkbox"
            id={index}
            value={item.slug}
            onChange={() => handleCheck(item.slug)}
          />
          { index === inputSelect ? (
            <Form__select
              value={fuel}
              style={{ width: 250, marginTop: 20, marginLeft: 45 }}
              event={(res) => handleFuelChange({
                property: 'payment',
                value: res.value,
                result: res.result,
                type: 'select',
              })}
            />
          ) : null}
        </Card>
      ))}
    </Container>
  );
};

export default Form__checkBoxCard;
