
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { strapiService24Service } from 'util/strapi/content.starpi';
import Cards_serviceInfo from 'components/cards/serviceInfo/cards__serviceInfo';
import { Grid__separator } from 'components/grid/grid';
import Cards__launchService from 'components/cards/launchService/cards__launchService';
import { faPhone, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import Cards__counterSimple from 'components/cards/counterSimple/cards__counterSimple';
import t from 'theme';

const Container = styled.div``;

const CardContainer = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-start' })}
`;

const DashboardService24__action = ({
  style, infoEvent, launchServicePop, handleCallButton, serviceCount,
}) => {
  const { getGlobal, getField } = useContext(ContentContext);

  return (
    <Container style={style}>
      <Cards__launchService
        title={getField({ base: strapiService24Service, field: 'launch_title' })}
        content={getField({ base: strapiService24Service, field: 'launch_text' })}
        buttonTopContent={getField({ base: strapiService24Service, field: 'launch_call_button' })}
        buttonTopEvent={handleCallButton}
        buttonTopIcon={faPhone}
        buttonBottomContent={getField({ base: strapiService24Service, field: 'launch_request_button' })}
        buttonBottomEvent={launchServicePop}
        buttonBottomIcon={faEnvelope}
        conditionalWord={getGlobal({ option: 'globalcontent', field: 'uploader_or' })}
      />
      <Grid__separator size="20px" />
      <CardContainer>
        <Cards__counterSimple
          value={serviceCount}
          label={getField({ base: strapiService24Service, field: 'card_counter_label' })}
        />
        <Cards_serviceInfo
          title={getField({ base: strapiService24Service, field: 'card_discover_title' })}
          event={infoEvent}
          smallLayout
          small
        />
      </CardContainer>
      <Grid__separator size="40px" />
    </Container>
  );
};

export default DashboardService24__action;
