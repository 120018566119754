
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Grid__separator } from 'components/grid/grid';
import { ContentContext } from 'context/contentContext';
import Button__primary from 'components/button/primary/button__primary';
import t from 'theme';
import Form__select from 'components/form/select/form__select';
import { createNewArray } from 'helpers/js/Form';
import { SERVICENAME } from 'util/service/serviceName';
import {
  strapiSingleServicePage, strapiService24Service, strapiStandartrepairService, strapiPaymentGuaranteeService, strapiPriority90Service, strapiRepairApprovalService,
} from 'util/strapi/content.starpi';

const Container = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', direction: 'column' })}
`;

const InfoText = styled.div`
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #4d4d4d;
`;
const ServiceDashboard__serviceList = ({
  nextEvent,
}) => {
  const { getGlobal, getField } = useContext(ContentContext);
  const servicesList = [
    {
      label: getField({ base: strapiRepairApprovalService, field: 'service_title' }),
      slug: SERVICENAME.repairapproval,
      active: true,
    },
    {
      label: getField({ base: strapiPaymentGuaranteeService, field: 'service_title' }),
      slug: SERVICENAME.paymentguarantee,
      active: false,
    },
    {
      label: getField({ base: strapiPriority90Service, field: 'service_title' }),
      slug: SERVICENAME.priority90,
      active: false,
    },
    {
      label: getField({ base: strapiService24Service, field: 'service_title' }),
      slug: SERVICENAME.service24,
      active: false,
    },
    {
      label: getField({ base: strapiStandartrepairService, field: 'service_title' }),
      slug: SERVICENAME.standardrepair,
      active: false,
    },
  ];

  const [service, setService] = useState(createNewArray({ base: servicesList }));
  const [serviceValue, setServiceValue] = useState(createNewArray({ base: servicesList })[0]);

  const handleNextStep = () => {
    if (serviceValue !== null) {
      if (nextEvent) {
        nextEvent(serviceValue);
      }
    }
  };


  const handleServiceChange = (value) => {
    setServiceValue(value.result);
    setService(value.value);
  };


  return (
    <Container>
      <InfoText>{getField({ base: strapiSingleServicePage, field: 'service_list_infoText' })}</InfoText>
      <Grid__separator size="30px" />
      <Form__select
        value={service}
        label={getField({ base: strapiSingleServicePage, field: 'service_list_label_selector' })}
        style={{ width: 350 }}
        event={(res) => handleServiceChange({
          property: 'country',
          value: res.value,
          result: res.result,
          type: 'select',
        })}
        require
      />
      <Grid__separator size="25px" />
      <Button__primary event={handleNextStep}>{getGlobal({ option: 'service', field: 'single_launch_button' })}</Button__primary>
    </Container>
  );
};

export default ServiceDashboard__serviceList;
