import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Nav from 'components/global/nav';
import { AppContext } from 'context/appContext';
import { ContentContext } from 'context/contentContext';
import { Transition__loader_controller } from 'components/transition/transition';
import { Layout__wrapper, Layout__content, Layout__container } from 'containers/layout/layout';
import { HomeBase__notset, HomeBase__set } from 'containers/fleetView/homeBase/homeBase';
import HomeBase__header from 'containers/fleetView/homeBase/header/homeBase__header';
import {
  strapiHomeBasePage,
  strapiMapPage,
} from 'util/strapi/content.starpi';

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
`;

const LayoutContent = styled(Layout__content)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: auto;
`;

/**
 * @name HomeBase
 */

const HomeBase = () => {
  const { loadPage, haveContentFor } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const { UserFleet } = user.user;

  const loadContent = async () => {
    if (haveContentFor(strapiHomeBasePage).success === false) {
      await loadPage(strapiHomeBasePage);
    }
    if (haveContentFor(strapiMapPage).success === false) {
      await loadPage(strapiMapPage);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (
    haveContentFor(strapiHomeBasePage).success === false
    || haveContentFor(strapiMapPage).success === false
  ) {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container>
      <Nav active="homebase" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <HomeBase__header basePage={strapiHomeBasePage} />
        <LayoutContent>
          <CardContainer>
            {UserFleet.workshopId === null ? (
              <HomeBase__notset basePage={strapiHomeBasePage} />
            ) : (
              <HomeBase__set basePage={strapiHomeBasePage} />
            )}
          </CardContainer>
        </LayoutContent>
      </Layout__wrapper>
    </Layout__container>
  );
};

export default HomeBase;
