
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import t from 'theme';

import { ContentContext } from 'context/contentContext';

import { List__table, List__content } from 'components/list/list';
import { Grid__card } from 'components/grid/grid';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';
import { Fleet__singleVehicle } from 'containers/fleetView/fleet/fleet';
import { sequelizeDate } from 'util/date.util';
/**
 * import containers & components
 */import { AppContext } from 'context/appContext';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { useData } from 'components/data/getter/data__getter';
import Form__search from 'components/form/search/form__search';


/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 40px;
`;

const ListCardContainer = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'center' })};
  height: 40px;
  margin-bottom: 30px;
`;

const ListCardInner = styled.div`
  ${t.mixins.flex({ justify: 'flex-end', align: 'center' })};
`;

const Card = styled(Grid__card)`
  height: calc(100vh - 160px);
  position: relative;
`;

const Table = styled(List__table)`
  height: calc(100% - 70px);
`;

const Fleet__list = ({
  style, basePage, baseSrvFairPrice, baseSrvStandar,
}) => {
  const { getField, getGlobal } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [searchValue, setsearchValue] = useState(null);
  let pageNumber = 1;
  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      userId: user.user.UserFleet.id,
    },
    service: 'vehicle',
    type: 'vehicle',
  });
  const headerCol = [
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'vincode' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'category' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'brand' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'mileage' }),
    },
  ];

  const [fleetFormatted, setFleetFormatted] = useState([]);
  const [fleetNoFormatted, setFleetNoFormatted] = useState([]);
  const [currentVehicle, setCurrentVehicle] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);

  const formatMileage = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  useEffect(() => {
    if (resData.data) {
      const obj = [];
      const noFormatedObj = [];
      const orderArray = resData.data;
      orderArray.forEach((element) => {
        const childObj = [];

        childObj.push({ label: <List__content uppercase>{element.registrationNumber}</List__content> });
        childObj.push({
          label: (
            <List__content capitalize>
              {getGlobal({ option: 'country', field: element.countryOfRegistration })
                || element.countryOfRegistration}
            </List__content>
          ),
        });
        childObj.push({ label: <List__content uppercase>{element.vin}</List__content> });
        childObj.push({
          label: (
            <List__content capitalize>
              {getGlobal({ option: 'vehicletype', field: element.type }) || element.type}
            </List__content>
          ),
        });
        childObj.push({ label: <List__content capitalize>{element.brand}</List__content> });
        childObj.push({
          label: (
            <List__content>{sequelizeDate({ date: element.firstDate, format: 'd/m/y' })}</List__content>
          ),
        });
        childObj.push({ label: <List__content>{formatMileage(element.mileage)}</List__content> });

        obj.push(childObj);
        noFormatedObj.push(element);
      });

      setFleetFormatted(obj);
      setFleetNoFormatted(noFormatedObj);
    }
  }, [resData.data]);


  const doSearch = () => {
    setSearch(searchValue);
  };

  const handleLineClick = (payload) => {
    if (fleetNoFormatted) {
      if (fleetNoFormatted[payload.key]) {
        setCurrentVehicle(fleetNoFormatted[payload.key]);
        setOpenPopUp(true);
      }
    }
  };

  const handleClosePop = () => {
    setCurrentVehicle(false);
    setOpenPopUp(false);
  };

  const handleLoadNewPage = () => {
    if (resData.data && !resData.loading && pageNumber + 1 > 1 && resData.data.length < resData.count) {
      pageNumber += 1;
      setPage(pageNumber, resData.data);
    }
  };

  return (
    <Container style={style}>
      <Card padding="30px">
        <ListCardContainer>
          <Content__cardTitle
            content={`${getField({ base: basePage, field: 'Table_title' })} (${resData ? resData.count : 0})`}
          />
          <ListCardInner>
            <Form__search
              property="searchValue"
              search
              placeholder={getField({ base: basePage, field: 'search_placeholder' })}
              change={(value) => setsearchValue(value.value)}
              searchValue={searchValue}
              event={doSearch}
            />
          </ListCardInner>
        </ListCardContainer>
        <Table
          headerCol={headerCol}
          lineCol={fleetFormatted}
          noSelect
          lineEvent={handleLineClick}
          maxHeight="100%"
          scrollEndEvent={handleLoadNewPage}
        />
        {
          resData.loading && (
            <Loader__wrapper />
          )
        }
      </Card>
      <Fleet__singleVehicle
        active={openPopUp}
        vehicle={currentVehicle}
        closePop={handleClosePop}
        basePage={basePage}
        baseSrvFairPrice={baseSrvFairPrice}
        baseSrvStandar={baseSrvStandar}
      />
    </Container>
  );
};

export default Fleet__list;
