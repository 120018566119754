/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const Container = styled.a`
  width: 35px;
  height: 35px;
  background: #ffe8e8;
  border-radius: 50%;
  ${t.mixins.flex({ justify: 'center', align: 'center' })};
  color: #ff8d8d;
  text-decoration: none;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 25px;
  transition: .3s ease all;
  &:hover {
    background: #ff8d8d;
    color: #fff;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  pointer-events: none;
`;

const Button__closeButton = ({
  style, event, noprevent, className,
}) => {
  const eventHandler = (e) => {
    if (!noprevent) {
      e.preventDefault();
    }
    if (event) {
      event();
    }
  };
  return (
    <Container href="#" className={className} style={style} onClick={(e) => eventHandler(e)}>
      <Icon icon={faTimes} />
    </Container>
  );
};

export default Button__closeButton;
