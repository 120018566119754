const Mail__notification = ({
  content,
}) => (`
<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${content.title}</title>
  </head>
  <body>
    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; background-color: #f9f9f9;" id="bodyTable">
      <tbody>
        <tr>
          <td align="center" valign="top" style="padding-right: 10px; padding-left: 10px;" id="bodyCell">
            <table border="0" cellpadding="0" cellspacing="0" style="max-width: 600px;" width="100%">
              <tbody>
                <tr>
                  <td align="center" valign="top">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                      <tbody>
                        <tr>
                          <td align="center" valign="middle" style="padding-top: 40px; padding-bottom: 40px;">
                            <img src="https://alltrucks-assets.s3.eu-west-3.amazonaws.com/logo.png" alt="" width="300" border="0" style="width: 100%; max-width: 300px; height: auto; display: block;" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table border="0" cellpadding="0" cellspacing="0" style="max-width: 600px;" width="100%">
              <tbody>
                <tr>
                  <td align="center" valign="top">
                    <table border="0" cellpadding="0" cellspacing="0" style="background-color: #ffffff; border-color: #e5e5e5; border-style: solid; border-width: 0 1px 1px 1px;" width="100%">
                      <tbody>
                        <tr>
                          <td height="3" style="background-color: rgb(1, 181, 226); font-size: 1px; line-height: 3px;">&nbsp;</td>
                        </tr>
                        <tr>
                          <td height="65">&nbsp;</td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" style="padding-bottom: 5px; padding-left: 20px; padding-right: 20px;">
                            <h2
                              style="
                                color: #000000;
                                font-family: Helvetica, Arial, sans-serif;
                                font-size: 26px;
                                font-weight: 500;
                                font-style: normal;
                                letter-spacing: normal;
                                line-height: 36px;
                                text-transform: none;
                                text-align: center;
                                padding: 0;
                                margin: 0;
                              "
                            >
                              ${content.title}
                            </h2>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" style="padding-bottom: 40px; padding-left: 30px; padding-right: 30px;">
                            <h4
                              style="
                                color: #666666;
                                font-family: Helvetica, Arial, sans-serif;
                                font-size: 16px;
                                font-weight: 500;
                                font-style: normal;
                                letter-spacing: normal;
                                line-height: 24px;
                                text-transform: none;
                                text-align: center;
                                padding: 0;
                                margin: 0;
                              "
                            >
                            ${content.subtitle}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" style="padding-left: 60px; padding-right: 20px;">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%">
                              <tbody>
                                <tr>
                                  <td align="center" valign="top" style="padding-top: 0px; padding-bottom: 20px;">
                                    <table align="left" border="0" cellpadding="0" cellspacing="0">
                                      <tbody>
                                        <!-- section -->
                                        <tr>
                                          <td align="left" style="padding-bottom: 10px; font-family: Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 600; color:#2e2e2e; font-style: normal; line-height: 20px;">
                                            ${content.sectionFleet_title}
                                          </td>
                                        <tr>
                                          <td align="left" style="padding-bottom: 5px; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; font-style: normal; line-height: 20px;">
                                            ${content.sectionFleet_1[0]} ${content.sectionFleet_1[1]}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="left" style="padding-bottom: 5px; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; font-style: normal; line-height: 20px;">
                                            ${content.sectionFleet_2[0]} ${content.sectionFleet_2[1]}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="left" style="font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; font-style: normal; line-height: 20px;">
                                            ${content.sectionFleet_3[0]} ${content.sectionFleet_3[1]}
                                          </td>
                                        </tr>
                                        <!-- section -->
                                        ${'sectionVehicle_title' in content ? `
                                          <tr>
                                            <td align="left" style="padding-bottom: 10px;padding-top: 25px; font-family: Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 600; color:#2e2e2e; font-style: normal; line-height: 20px;">
                                              ${content.sectionVehicle_title}
                                            </td>
                                          <tr>
                                            <td align="left" style="padding-bottom: 5px; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; font-style: normal; line-height: 20px;">
                                              ${content.sectionVehicle_1[0]} ${content.sectionVehicle_1[1]}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="left" style="font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; font-style: normal; line-height: 20px;">
                                              ${content.sectionVehicle_2[0]} ${content.sectionVehicle_2[1]}
                                            </td>
                                          </tr>
                                        ` : ''}
                                        <!-- section -->
                                        ${'sectionWorkshop_title' in content ? `
                                        <tr>
                                          <td align="left" style="padding-bottom: 10px;padding-top: 25px; font-family: Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 600; color:#2e2e2e; font-style: normal; line-height: 20px;">
                                            ${content.sectionWorkshop_title}
                                          </td>
                                        <tr>
                                          <td align="left" style="padding-bottom: 5px; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; font-style: normal; line-height: 20px;">
                                            ${content.sectionWorkshop_1[0]} ${content.sectionWorkshop_1[1]}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="left" style="padding-bottom: 5px; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; font-style: normal; line-height: 20px;">
                                            ${content.sectionWorkshop_2[0]} ${content.sectionWorkshop_2[1]}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="left" style="font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; font-style: normal; line-height: 20px;">
                                            ${content.sectionWorkshop_3[0]} ${content.sectionWorkshop_3[1]}
                                          </td>
                                        </tr>
                                        ` : ''}
                                        <tr>
                                          <td align="left" style="padding-top: 40px; padding-bottom: 10px; font-family: Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 600; color:#2e2e2e; font-style: normal; line-height: 20px;">
                                            ${content.sectionGop[0]} ${content.sectionGop[1]}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" style="padding-left: 20px; padding-right: 20px;">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%">
                              <tbody>
                                <tr>
                                  <td align="center" style="padding-bottom: 25px; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; font-style: normal; line-height: 20px; text-align:center;">
                                    ${content.info}
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" valign="top" style="padding-top: 0px; padding-bottom: 20px;">
                                    <table align="center" border="0" cellpadding="0" cellspacing="0">
                                      <tbody>
                                        <tr>
                                          <td align="center" style="background-color: #2ECC71; padding: 12px 35px; border-radius: 50px;">
                                            <a
                                              href="${content.btnAccept[1]}"
                                              target="_top"
                                              style="color: #ffffff; font-family: Helvetica, Arial, sans-serif; font-size: 13px; font-weight: 600; font-style: normal; line-height: 20px; text-decoration: none; display: block;"
                                            >
                                              ${content.btnAccept[0]}
                                            </a>
                                          </td>
                                          <td align="center" style="padding-left: 25px;"></td>
                                          <td align="center" style="background-color: #E74C3C; padding: 12px 35px; border-radius: 50px;">
                                            <a
                                              href="${content.btnRefused[1]}"
                                              target="_top"
                                              style="color: #ffffff; font-family: Helvetica, Arial, sans-serif; font-size: 13px; font-weight: 600; font-style: normal; line-height: 20px; text-decoration: none; display: block;"
                                            >
                                            ${content.btnRefused[0]}
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="middle" style="padding-bottom: 65px;"></td>
                        </tr>
                      </tbody>
                    </table>
                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                      <tbody>
                        <tr>
                          <td height="30" style="font-size: 1px; line-height: 1px;">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table border="0" cellpadding="0" cellspacing="0" style="max-width: 600px;" width="100%">
              <tbody>
                <tr>
                  <td align="center" valign="top">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                      <tbody>
                        <tr>
                          <td align="center" valign="top" style="padding-top: 10px; padding-bottom: 5px; padding-left: 10px; padding-right: 10px;">
                            <p
                              style="
                                color: #777777;
                                font-family: 'Open Sans', Helvetica, Arial, sans-serif;
                                font-size: 12px;
                                font-weight: 400;
                                font-style: normal;
                                letter-spacing: normal;
                                line-height: 20px;
                                text-transform: none;
                                text-align: center;
                                padding: 0;
                                margin: 0;
                              "
                            >
                              Alltrucks GmbH & Co. KG | Perchtinger Strasse 6 | 81379 Munich, Allemagne
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" style="padding-top: 0px; padding-bottom: 10px; padding-left: 10px; padding-right: 10px;">
                            <p
                              style="
                                color: #777777;
                                font-family: 'Open Sans', Helvetica, Arial, sans-serif;
                                font-size: 12px;
                                font-weight: 400;
                                font-style: normal;
                                letter-spacing: normal;
                                line-height: 20px;
                                text-transform: none;
                                text-align: center;
                                padding: 0;
                                margin: 0;
                              "
                            >
                              <a href="mailto:support@alltrucks-fleet-platform.com" style="color: #777777; text-decoration: underline;" target="_blank">support@alltrucks-fleet-platform.com</a>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td height="30" style="font-size: 1px; line-height: 1px;">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td height="30" style="font-size: 1px; line-height: 1px;">&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
</html>
`);

export default Mail__notification;
