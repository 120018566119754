export const orderServiceObject = (fleetObj) => {
  const { have, historic } = fleetObj;
  let list = [];

  if (have === true && historic) {
    list = historic.slice().sort((a, b) => new Date(b.service.createdAt.replace(' ', 'T')) - new Date(a.service.createdAt.replace(' ', 'T')));
  }

  return list;
};

export const orderFullHistoricServiceObject = (fleetObj) => {
  const { have, completehistoric } = fleetObj;
  let list = [];

  if (have === true && completehistoric) {
    list = completehistoric.slice().sort((a, b) => new Date(b.service.createdAt.replace(' ', 'T')) - new Date(a.service.createdAt.replace(' ', 'T')));
  }

  return list;
};

export const orderObligoHistoric = (fleetObj) => {
  const { have, obligoHistoric } = fleetObj;
  let list = [];

  if (have === true && obligoHistoric) {
    list = obligoHistoric.slice().sort((a, b) => new Date(b.service.createdAt.replace(' ', 'T')) - new Date(a.service.createdAt.replace(' ', 'T')));
  }

  return list;
};


export const orderFleetArray = (fleetObj) => {
  const list = [];

  if (fleetObj) {
    const tempList = fleetObj.slice().sort((a, b) => new Date(b.updatedAt.replace(' ', 'T')) - new Date(a.updatedAt.replace(' ', 'T')));

    tempList.forEach((element) => {
      if (!element.deleted) {
        list.push(element);
      }
    });
  }

  return list;
};


export const orderGlobalHistorique = (fleetObj) => {
  let list = [];

  if (fleetObj) {
    list = fleetObj.slice().sort((a, b) => new Date(b.data.updatedAt.replace(' ', 'T')) - new Date(a.data.updatedAt.replace(' ', 'T')));
  }

  return list;
};

export const arrayLimit = (array, limit) => {
  const list = [];

  if (array) {
    array.forEach((element, key) => {
      if (key <= limit) {
        list.push(element);
      }
    });
  }

  return list;
};
