import React, { useContext, useEffect, useState } from 'react';
import { ContentContext } from 'context/contentContext';
import Nav from 'components/global/nav';
import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Transition__loader_controller } from 'components/transition/transition';
import SingleCase__content from 'containers/workshopView/cases/singleCase/content/singleCase__content';
import {
  strapiMyServicePage,
  strapiStandartrepairService,
  strapiRepairApprovalService,
  strapiMyFleetPage,
  strapiFairPriceService,
  strapiWorkshopSingleVehicle,
  strapiPaymentGuaranteeService,
  strapiCase,
} from 'util/strapi/content.starpi';
import { useParams } from 'react-router-dom';
import { GetSingleCase } from 'helpers/api/routes/Cases';


const cases = () => {
  const {
    loadPage, haveContentFor,
  } = useContext(ContentContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [caseData, setCaseData] = useState(undefined);

  const getCase = async () => {
    const res = await GetSingleCase(id);
    if (res) {
      setCaseData(res.data.data);
      setLoading(false);
    }
  };

  const loadContent = async () => {
    if (haveContentFor(strapiMyServicePage).success === false) {
      await loadPage(strapiMyServicePage);
    }
    if (haveContentFor(strapiStandartrepairService).success === false) {
      await loadPage(strapiStandartrepairService);
    }
    if (haveContentFor(strapiMyFleetPage).success === false) {
      await loadPage(strapiMyFleetPage);
    }
    if (haveContentFor(strapiFairPriceService).success === false) {
      await loadPage(strapiFairPriceService);
    }
    if (haveContentFor(strapiWorkshopSingleVehicle).success === false) {
      await loadPage(strapiWorkshopSingleVehicle);
    }
    if (haveContentFor(strapiPaymentGuaranteeService).success === false) {
      await loadPage(strapiPaymentGuaranteeService);
    }
    if (haveContentFor(strapiCase).success === false) {
      await loadPage(strapiCase);
    }
    if (haveContentFor(strapiRepairApprovalService).success === false) {
      await loadPage(strapiRepairApprovalService);
    }
  };

  useEffect(() => {
    loadContent();
    getCase();
  }, []);
  if (haveContentFor(strapiRepairApprovalService).success === false
  || haveContentFor(strapiPaymentGuaranteeService).success === false
  || haveContentFor(strapiMyFleetPage).success === false
  || haveContentFor(strapiWorkshopSingleVehicle).success === false
  || haveContentFor(strapiMyServicePage).success === false
  || haveContentFor(strapiStandartrepairService).success === false
  || haveContentFor(strapiCase).success === false
  || haveContentFor(strapiFairPriceService).success === false
  || loading) {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container>
      <Nav workshop active="cases" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <Layout__content>
          {caseData && <SingleCase__content basePage={strapiCase} data={caseData} refresh={getCase} />}
        </Layout__content>
      </Layout__wrapper>
    </Layout__container>
  );
};

export default cases;
