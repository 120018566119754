
/**
   * Import Lib & assets
   */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import marked from 'marked';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Check = styled.a`
  margin-top: 2px;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const CheckContent = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  border-radius: 4px;
  background-color: ${t.color.cyan};
  border: solid 2px #2E2E2E;
  border-color: ${(props) => (props.active && props.red ? '#E74C3C' : props.active && !props.red ? t.color.cyan : '#5F6368')};
  background-color: ${(props) => (props.active && props.red ? '#E74C3C' : props.active && !props.red ? t.color.cyan : 'transparent')};
  position: relative;
  transition: all ease 0.3s;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${(props) => (props.active === 1 ? '#fff' : 'transparent')};
  position: absolute;
  top: 1px;
  left: 1px;
`;

const Content = styled.label`
  font-weight: ${({ bold }) => (bold ? '600' : 'normal')};;
  color: ${({ error }) => (error ? t.color.red : '#000000')};
  margin-left: 10px;
  line-height: 1.4;
  font-size: ${(props) => (props.largeContent ? '14px' : '16px')};
  cursor: pointer;
  a {
    font-weight: 600;
  }
  p {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
  strong {
    font-size: 15px;
  }
  li {
    list-style-type: disc;
    margin-left: 18px;
    margin-top: 5px;
  }
`;

const Form__checkCondition = ({
  style, content, value, callback, property, square, error, red, largeContent, bold,
}) => {
  const renderMarked = (markdown) => {
    if (markdown && markdown !== '') {
      return <div dangerouslySetInnerHTML={{ __html: marked(markdown) }} />;
    }
    return null;
  };

  return (
    <Container style={style} className={error ? 'formError' : ''}>
      <Check
        href="#"
        active={value}
        onClick={(e) => {
          e.preventDefault();
          callback && (callback({ property, value: !value }));
        }}
      >
        <CheckContent active={value} square={square} red={red} error={error ? error.active : false}>
          <Icon icon={faCheck} active={value ? 1 : 0} />
        </CheckContent>
      </Check>
      <Content
        bold={bold}
        largeContent={largeContent}
        error={error ? error.active : false}
        onClick={(e) => {
          callback && (callback({ property, value: !value }));
        }}
      >
        {largeContent ? renderMarked(content) : content}
      </Content>
    </Container>
  );
};

export default Form__checkCondition;
