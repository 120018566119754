
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import t from 'theme';

/**
 * import components
 */
import Cards__blueCard from 'components/cards/blueCard/cards__blueCard';
import HomeBasedCard from 'components/cards/homeBased/cards__homeBased';
import { Grid__card, Grid__separator } from 'components/grid/grid';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';
import Content__counterInfo from 'components/content/counterInfo/content__counterInfo';
import Home__list from 'containers/fleetView/home/list/home__list';

import { AppContext } from 'context/appContext';
import { slugify } from 'helpers/js/Utile';
import { ContentContext } from 'context/contentContext';

import { format_apiobject } from 'util/formatContent.util';
import { GetHomeBaseFromUser } from 'util/fleet/getfleetuserdata.util';
import {
  strapiHomePage,
} from 'util/strapi/content.starpi';
import { ROUTING } from 'util/constant/routing.constant';
import { formatApiCountRes } from 'util/function/formatApiRes.function';
import { useData } from 'components/data/getter/data__getter';
import { getFleetRepairApprovalAction } from 'helpers/api/routes/getData';
import { strapiRepairApprovalService } from '../../../../util/strapi/content.starpi';


const Container = styled.div`
  width: 70%;
  height: 100%;
`;

const CardsContainer = styled.div`
  ${t.mixins.flex({ align: 'left', direction: 'row' })};
`;

const BlueCards = styled(Cards__blueCard)`
  margin-right: 30px;
`;

const ListCard = styled(Grid__card)`
  height: calc(100% - 200px);
  min-height: 330px;
`;

const ListCardContainer = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'center' })};
  height: 40px;
  margin-bottom: 30px;
`;

const Home__info = ({
  style, historic, handleLoadNewPage, loading,
}) => {
  const { user } = useContext(AppContext);
  const { getField, getFieldGroup } = useContext(ContentContext);
  const { UserFleet } = user.user;

  const homeBaseWorkshop = GetHomeBaseFromUser({ user: user.user });
  const asHomeBase = format_apiobject({ obj: homeBaseWorkshop });
  const [actionCount, setactionCount] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      userId: user.user.UserFleet.id,
    },
    service: 'vehicle',
    type: 'vehicle',
  });

  const getActionNeeded = async () => {
    const resAction = await getFleetRepairApprovalAction({
      page: 1,
      limit: 3,
      id: user.user.UserFleet.id,
      isApprover: user.user.UserFleet.repairApprovalUserId === user.user.id,
    });

    const formated = formatApiCountRes(resAction);
    setactionCount(formated.count);
  };


  useEffect(() => {
    getActionNeeded();
  }, []);

  return (
    <Container style={style}>
      <CardsContainer>
        <BlueCards
          title={getField({ base: strapiHomePage, field: 'fleet_title' })}
          value={resData ? resData.count : 0}
          label={getField({ base: strapiHomePage, field: 'fleet_label' })}
          link
          to={ROUTING.fleet.myFleet.path}
          loading={resData.loading}
        />
        <HomeBasedCard
          status={UserFleet.FavWorkshopStatus ? slugify(UserFleet.FavWorkshopStatus) : null}
          asHomeBase={asHomeBase}
          title={getField({ base: strapiHomePage, field: 'homebase' })}
          waitingMsg={getField({ base: strapiHomePage, field: 'homebase_waiting' })}
          buttonContent={getField({ base: strapiHomePage, field: 'homebase_button' })}
          contentL1={asHomeBase && (asHomeBase.companyName)}
          contentL2={asHomeBase && (asHomeBase.adress)}
          contentL3={`${asHomeBase && (asHomeBase.zipcode)} ${asHomeBase && (asHomeBase.city)}`}
          phoneNumber={`tel:${asHomeBase && (asHomeBase.phoneNumber)}`}
          email={`mailto:${asHomeBase && (asHomeBase.email)}`}
          adressLink={`https://www.google.com/maps/search/${asHomeBase && (asHomeBase.adress)}, ${asHomeBase && (asHomeBase.zipcode)}, ${asHomeBase && (asHomeBase.city)}`}
        />
      </CardsContainer>
      <Grid__separator size="30px" />
      <ListCard padding="30px">
        <ListCardContainer>
          <Content__cardTitle content={getField({ base: strapiHomePage, field: 'table_title' })} />
          {
            (actionCount && actionCount > 0) ? (
              <Content__counterInfo
                value={actionCount}
                label={getFieldGroup({
                  base: strapiRepairApprovalService,
                  groupe: 'status',
                  field: 'action',
                })}
              />
            ) : null
          }
        </ListCardContainer>

        <Home__list historic={historic} handleLoadNewPage={handleLoadNewPage} loading={loading} />
      </ListCard>
      <Grid__separator size="30px" />
    </Container>
  );
};

export default Home__info;
