
import React, {
  useContext, useEffect, useState, Fragment,
} from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import t from 'theme';
import Grid__infoMsg from 'components/grid/infoMsg/grid__infoMsg';
import Grid__separator from 'components/grid/separator/grid__separator';

import { AppContext } from 'context/appContext';
import { ContentContext } from 'context/contentContext';

import { format_str } from 'util/formatContent.util';
import { getStats } from 'helpers/api/routes/util';
import { formatApiRes, formatApiCountRes } from 'util/function/formatApiRes.function';
import {
  faShieldCheck, faHandshakeAlt, faUserHeadset, faArrowRight,
} from '@fortawesome/pro-regular-svg-icons';
import { Button__primary } from 'components/button/button';
import Cards__serviceMini from 'components/cards/serviceMini/cards__serviceMini';
import { ROUTING } from 'util/constant/routing.constant';
import {
  strapiWorkshopHome,
  strapiPaymentGuaranteeService,
  strapiRepairApprovalService,
  strapiService24Service,
} from 'util/strapi/content.starpi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getActionNeededPaymentGuarantee, getActionNeededWorkshopRepairApproval } from 'helpers/api/routes/getData';


const Container = styled.div`
  padding-bottom: 25px;
  ${t.mixins.flex({ justify: 'space-between', direction: 'row', align: 'flex-start' })};
`;

const TitlePrimary = styled.span`
  font-family: ${t.font.primary};
  font-weight: 600;
  font-size: 27px;
  line-height: 37px;
  margin: 0;
  color: #2f2f30;
`;

const TitleSecondary = styled.span`
  font-family: ${t.font.primary};
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #808080;
  margin-top: 5px;
`;

const InfoMsg = styled(Grid__infoMsg)`
  margin-top: -10px;
`;

const ServiceContainer = styled.div`
  ${t.mixins.flex({ align: 'flex-end', justify: 'flex-start', direction: 'row' })};
  display: inline-flex;
  padding-bottom: 30px;
`;

const TitleContainer = styled.div`
  ${t.mixins.flex({ align: 'left', direction: 'column' })};
`;

const Home__header = ({ style }) => {
  const { user } = useContext(AppContext);
  const { getField, getGlobal } = useContext(ContentContext);
  const [statistic, setstatistic] = useState(null);
  const { gender, lastName, companyName } = user.user;
  const history = useHistory();
  const [paymentGuaranteeCount, setPaymentGuaranteeCount] = useState(0);
  const [repairApprovalCount, setRepairApprovalCount] = useState(0);

  const getPaymentGuaranteeNeeded = async () => {
    const res = await getActionNeededPaymentGuarantee({
      filter: null,
      limit: 10,
      page: 1,
      id: user.user.UserWorkshop.id,
    });
    const formated = formatApiCountRes(res);
    setPaymentGuaranteeCount(formated.count);
  };

  const getRepairApprovalNeeded = async () => {
    const res = await getActionNeededWorkshopRepairApproval({
      filter: null,
      limit: 10,
      page: 1,
      id: user.user.UserWorkshop.id,
      userWorkshopid: user.user.id,
    });
    const formated = formatApiCountRes(res);
    setRepairApprovalCount(formated.count);
  };

  const getGlobalState = async () => {
    const res = await getStats();
    const formatedRes = formatApiRes(res);
    if (formatedRes.success) {
      setstatistic(formatedRes.data);
    }
  };

  const handleClickService = () => {
    history.push(ROUTING.workshop.cases.path);
  };

  useEffect(() => {
    getGlobalState();
    getPaymentGuaranteeNeeded();
    getRepairApprovalNeeded();
  }, []);

  return (
    <Fragment>
      <Container style={style}>
        <TitleContainer>
          <TitlePrimary>
            {getField({ base: strapiWorkshopHome, field: 'welcome' })}
            {' '}
            {getGlobal({ option: 'gender', field: gender })}
            {' '}
            {format_str({ string: lastName, format: 'capital' })}
          </TitlePrimary>
          <TitleSecondary>
            {getField({ base: strapiWorkshopHome, field: 'from' })}
            {' '}
            {format_str({ string: companyName, format: 'capital' })}
          </TitleSecondary>
        </TitleContainer>
        {
          statistic && (
            <InfoMsg
              content={`${getField({ base: strapiWorkshopHome, field: 'notification_part_1' })} ${statistic.userWorkshop} ${getField({ base: strapiWorkshopHome, field: 'notification_part_2' })} ${statistic.userfleet} ${getField({ base: strapiWorkshopHome, field: 'notification_part_3' })} ${statistic.fleet} ${getField({ base: strapiWorkshopHome, field: 'notification_part_4' })}`}
            />
          )
        }
      </Container>
      <ServiceContainer>
        <Cards__serviceMini
          icon={faShieldCheck}
          content={getField({ base: strapiPaymentGuaranteeService, field: 'service_title' })}
          url={ROUTING.workshop.dashboardPaymentGuarantee.path}
          notificationCount={paymentGuaranteeCount}
        />
        <Grid__separator width="25px" />
        <Cards__serviceMini
          icon={faHandshakeAlt}
          content={getField({ base: strapiRepairApprovalService, field: 'service_title' })}
          url={ROUTING.workshop.dashboardRepairApproval.path}
          notificationCount={repairApprovalCount}
        />
        <Grid__separator width="25px" />
        <Cards__serviceMini
          icon={faUserHeadset}
          content={getField({ base: strapiService24Service, field: 'service_title' })}
          url={ROUTING.workshop.dashboardService24.path}
        />
        <Grid__separator width="25px" />
        <Button__primary
          event={handleClickService}
          border
          blue
          withIcon
          iconEnd
          icon={<FontAwesomeIcon icon={faArrowRight} />}
        >
          {getField({ base: strapiWorkshopHome, field: 'more_service' })}
        </Button__primary>
      </ServiceContainer>
    </Fragment>
  );
};

export default Home__header;
