import { API_URL } from '../Utile';
import { CallGet, CallPost } from '../Helpers';

/* ---------------------------- GET ALL VEHICULE ---------------------------- */

export const GetSingleService = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}service/paymentguarantee/${payload.id}`;

  const res = await CallGet(url, params, headers);

  return res;
};

export const UpdateStatus = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/paymentguarantee/updateStatus`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const UpdatePublicStatus = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/public/paymentguarantee/updateStatus/${payload.url}`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const AskConfirmation = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/paymentguarantee/askConfirmation`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const RegisterFleetToService = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const json = JSON.stringify(payload.fleetObj);
  const params = {
    fleetObj: json,
  };

  const url = `${API_URL}service/paymentguarantee/registerFleet`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const CreateService = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = payload;

  const url = `${API_URL}service/paymentguarantee/`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const createFile = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const formData = new FormData();

  formData.append('file', payload.file.file);
  formData.append('serviceUid', payload.serviceUid);
  formData.append('fileId', payload.fileId);
  formData.append('extension', payload.extension);

  const url = `${API_URL}service/paymentguarantee/file`;

  const res = await CallPost(url, formData, headers);

  return res;
};


export const canRemoveService = async (id) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}service/paymentguarantee/canRemoveService/${id}`;

  const res = await CallGet(url, params, headers);

  return res;
};

export const GetSingleServiceByUrl = async (payload) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}service/paymentguarantee/getSingleServiceByUrl/${payload.url}`;

  const res = await CallGet(url, params, headers);

  return res;
};
