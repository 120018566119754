
import React, { useContext, useEffect } from 'react';
import Nav from 'components/global/nav';

import { ContentContext } from 'context/contentContext';

/**
 * import assets
 */

/**
 * import containers & components
 */

import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Transition__loader_controller } from 'components/transition/transition';
import MyServices__serviceContainer from 'containers/fleetView/myServices/serviceContainer/myServices__serviceContainer';
import { strapiMyServicePage, strapiRepairApprovalService, strapiCase } from 'util/strapi/content.starpi';

/**
 * @name MyServices
 */

const MyServices = () => {
  const {
    loadPage, haveContentFor,
  } = useContext(ContentContext);

  const loadContent = async () => {
    if (haveContentFor(strapiMyServicePage).success === false) {
      await loadPage(strapiMyServicePage);
    }

    if (haveContentFor(strapiRepairApprovalService).success === false) {
      await loadPage(strapiRepairApprovalService, strapiCase);
    }
    if (haveContentFor(strapiCase).success === false) {
      await loadPage(strapiCase);
    }
  };


  useEffect(() => {
    loadContent();
  }, []);

  if (haveContentFor(strapiMyServicePage).success === false || haveContentFor(strapiRepairApprovalService).success === false || haveContentFor(strapiCase).success === false) {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container>
      <Nav active="myservices" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <Layout__content>
          <MyServices__serviceContainer basePage={strapiMyServicePage} />
        </Layout__content>
      </Layout__wrapper>
    </Layout__container>
  );
};

export default MyServices;
