
import React, { useContext, useEffect } from 'react';
import Nav from 'components/global/nav';
import styled from 'styled-components';
import t from 'theme';
/**
 * Import Context
 */

import { ContentContext } from 'context/contentContext';
/**
 * import containers & components
 */
import { Transition__loader_controller } from 'components/transition/transition';
import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import Home__info from 'containers/fleetView/home/info/home__info';
import Home__service from 'containers/fleetView/home/service/home__service';
import Home__header from 'containers/fleetView/home/header/home__header';
import Modal__TodayAppointment from 'components/modal/TodayAppointment/modal__TodayAppointment';

import {
  strapiHomePage,
  strapiAppointment,
  strapiWorkshopDashboardPaymentGuarantee,
} from 'util/strapi/content.starpi';
import { loadService, haveServiceContent } from 'util/function/loadContent.function';
import { AppContext } from 'context/appContext';
import { useData } from 'components/data/getter/data__getter';

/**
 * @name Home
 */
const Container = styled.span`
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start' })};
  height: calc(100vh - 170px);
`;

const Home = () => {
  const {
    loadPage, haveContentFor,
  } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  let pageNumber = 1;
  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      userFleetId: user.user && user.user.UserFleet ? user.user.UserFleet.id : -1,
    },
    service: 'historic',
    type: 'historic',
  });

  const loadContent = async () => {
    if (
      haveContentFor(strapiHomePage).success === false
    ) {
      await loadPage(strapiHomePage);
    }
    if (
      haveContentFor(strapiAppointment).success === false
    ) {
      await loadPage(strapiAppointment);
    }
    if (
      haveContentFor(strapiWorkshopDashboardPaymentGuarantee).success === false
    ) {
      await loadPage(strapiWorkshopDashboardPaymentGuarantee);
    }
    loadService({ haveFunc: haveContentFor, loadFunc: loadPage });
  };
  useEffect(() => {
    loadContent();
  }, []);

  if (
    haveContentFor(strapiHomePage).success === false
    || haveServiceContent({ haveFunc: haveContentFor }) === false
    || haveContentFor(strapiWorkshopDashboardPaymentGuarantee).success === false
    || haveContentFor(strapiAppointment).success === false
  ) {
    return <Transition__loader_controller active />;
  }

  const handleLoadNewPage = () => {
    if (resData.data && !resData.loading && pageNumber + 1 > 1 && resData.data.length < resData.count) {
      pageNumber += 1;
      setPage(pageNumber, resData.data);
    }
  };

  return (
    <Layout__container>
      <Nav active="home" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <Layout__content>
          <Home__header />
          <Container>
            <Home__info
              historic={resData.data || []}
              loading={resData.loading}
              handleLoadNewPage={handleLoadNewPage}
            />
            <Home__service
              historic={resData.data || []}
            />
          </Container>
        </Layout__content>
      </Layout__wrapper>
      <Modal__TodayAppointment userObject="UserFleet" isFleet basePage={strapiAppointment} />
    </Layout__container>
  );
};

export default Home;
