
/**
   * Import Lib
   */

import React, { useContext } from 'react';
import styled from 'styled-components';
import { Grid__separator } from 'components/grid/grid';
import Cards__checkPlate from 'components/cards/checkPlate/cards__checkPlate';
import Cards__fleetsCounter from 'components/cards/fleetsCounter/cards__fleetsCounter';
import { ROUTING } from 'util/constant/routing.constant';
import { ContentContext } from 'context/contentContext';
import { strapiWorkshopHome } from 'util/strapi/content.starpi';


const Card = styled.div`
  width: 100%;
`;

const Container = styled.div`
  width: 370px;
`;

const Home__check = ({ style, fleets }) => {
  const { getField } = useContext(ContentContext);
  return (
    <Container>
      <Card style={style}>
        <Cards__checkPlate
          small
          title={getField({ base: strapiWorkshopHome, field: 'check_vehicle_title' })}
          content={getField({ base: strapiWorkshopHome, field: 'check_vehicle_text' })}
        />
      </Card>
      <Grid__separator size="30px" />
      <Cards__fleetsCounter
        title={getField({ base: strapiWorkshopHome, field: 'fleet_cards_title' })}
        label={getField({ base: strapiWorkshopHome, field: 'fleet_cards_label' })}
        value={fleets ? fleets : '0'}
        url={ROUTING.workshop.myFleets.path}
      />
      <Grid__separator size="30px" />
    </Container>
  );
};

export default Home__check;
