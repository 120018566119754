import { flex } from './flex';
import { background } from './background';
import { toRgba } from './toRgba';
import { fit } from './object-fit';
import {
  sm, md, lg, xl, custom,
} from './media-query';

export default {
  flex,
  bg: background,
  fit,
  sm,
  md,
  lg,
  xl,
  custom,
  toRgba,
};
