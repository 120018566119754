/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

const Container = styled.div`
  ${t.mixins.flex({ justify: 'flex-start' })};
  font-size: 18px;
`;

const Title = styled.div`
  font-weight: normal;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  color: #4d4d4d;
  margin-left: ${(props) => (props.icon === undefined ? '0' : '10px')};
`;

const Content__titleIconService = ({
  style, content, icon, fontSize,
}) => (
  <Container style={style}>
    {icon}
    <Title icon={icon} fontSize={fontSize}>{content}</Title>
  </Container>
);

export default Content__titleIconService;
