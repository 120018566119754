/* eslint-disable react/display-name */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
  PDFDocument,
} from 'pdf-lib';
import download from 'downloadjs';
import { MarkdownToPdf } from 'helpers/api/routes/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract, faSync } from '@fortawesome/pro-regular-svg-icons';
import Button__inline from 'components/button/inline/button__inline';


const Contract__pdf = forwardRef(({
  buttonText,
  loadingtext,
  content,
  type,
  name,
  guarantor_company,
  guarantor_address,
  guarantor_country,
  guarantor_contact,
  workshop_company,
  workshop_address,
  workshop_country,
  workshop_contact,
  amount,
  fleet_company,
  fleet_address,
  fleet_zip,
  fleet_location,
  fleet_phone,
  fleet_cmail,
  fleet_tva,
  vehicle_tva,
  vehicle_registration,
  vehicle_type,
  vehicle_brand,
  vehicle_country,

  supervisor_name,
  supervisor_mail,
  supervisor_time,
  service_uid,
  noButton,
  noSignature,
}, ref) => {
  const [loading, setLoading] = useState(false);
  let _amount = amount;
  const toArrayBuffer = (buffer) => {
    const ab = new ArrayBuffer(buffer.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buffer.length; ++i) {
      view[i] = buffer[i];
    }
    return ab;
  };


  const parsePaymentGurantee = () => {
    let newContent = content;
    newContent = newContent.replace('{{guarantor_company}}', guarantor_company);
    newContent = newContent.replace('{{guarantor_address}}', guarantor_address);
    newContent = newContent.replace('{{guarantor_country}}', guarantor_country);
    newContent = newContent.replace('{{guarantor_contact}}', guarantor_contact);

    newContent = newContent.replace('{{workshop_company}}', workshop_company);
    newContent = newContent.replace('{{workshop_address}}', workshop_address);
    newContent = newContent.replace('{{workshop_country}}', workshop_country);
    newContent = newContent.replace('{{workshop_contact}}', workshop_contact);

    newContent = newContent.replace('{{amount}}', amount);


    newContent = newContent.replace('{{fleet_company}}', fleet_company);
    newContent = newContent.replace('{{fleet_address}}', fleet_address);
    newContent = newContent.replace('{{fleet_zip}}', fleet_zip);
    newContent = newContent.replace('{{fleet_location}}', fleet_location);
    newContent = newContent.replace('{{fleet_phone}}', fleet_phone);
    newContent = newContent.replace('{{fleet_cmail}}', fleet_cmail);
    newContent = newContent.replace('{{fleet_tva}}', fleet_tva);


    newContent = newContent.replace('{{vehicle_tva}}', vehicle_tva);
    newContent = newContent.replace('{{vehicle_registration}}', vehicle_registration);
    newContent = newContent.replace('{{vehicle_type}}', vehicle_type);
    newContent = newContent.replace('{{vehicle_brand}}', vehicle_brand);
    newContent = newContent.replace('{{vehicle_country}}', vehicle_country);

    if (noSignature) {
      newContent = newContent.replace('{{supervisor_name}}', '');
      newContent = newContent.replace('{{supervisor_mail}}', '');
      newContent = newContent.replace('{{supervisor_time}}', '');
    } else {
      newContent = newContent.replace('{{supervisor_name}}', supervisor_name);
      newContent = newContent.replace('{{supervisor_mail}}', supervisor_mail);
      newContent = newContent.replace('{{supervisor_time}}', supervisor_time);
    }

    newContent = newContent.replace('{{service_uid}}', service_uid);

    return newContent;
  };


  const parseObligo = () => {
    let newContent = content;
    newContent = newContent.replace('{{guarantor_company}}', guarantor_company);
    newContent = newContent.replace('{{guarantor_address}}', guarantor_address);
    newContent = newContent.replace('{{guarantor_country}}', guarantor_country);
    newContent = newContent.replace('{{guarantor_contact}}', guarantor_contact);

    if (noSignature) {
      newContent = newContent.replace('{{supervisor_name}}', '');
      newContent = newContent.replace('{{supervisor_mail}}', '');
      newContent = newContent.replace('{{supervisor_time}}', '');
    } else {
      newContent = newContent.replace('{{supervisor_name}}', supervisor_name);
      newContent = newContent.replace('{{supervisor_mail}}', supervisor_mail);
      newContent = newContent.replace('{{supervisor_time}}', supervisor_time);
    }

    newContent = newContent.replace('{{amount}}', _amount);
    newContent = newContent.replace('{{fleet_company}}', fleet_company);
    newContent = newContent.replace('{{fleet_address}}', fleet_address);
    newContent = newContent.replace('{{fleet_zip}}', fleet_zip);
    newContent = newContent.replace('{{fleet_location}}', fleet_location);
    newContent = newContent.replace('{{fleet_phone}}', fleet_phone);
    newContent = newContent.replace('{{fleet_cmail}}', fleet_cmail);
    newContent = newContent.replace('{{fleet_tva}}', fleet_tva);


    return newContent;
  };

  const parseContent = () => {
    if (type === 'paymentGurantee') {
      return parsePaymentGurantee();
    }

    if (type === 'obligo') {
      return parseObligo();
    }

    return content;
  };

  const createPdf = async (noDownload) => {
    // if (!noDownload) {
    setLoading(true);
    // }
    const res = await MarkdownToPdf({
      content: parseContent(),
    });

    const pdfDoc = await PDFDocument.load(toArrayBuffer(res.data.data.data));

    const logoImage = await pdfDoc.embedPng(toArrayBuffer(res.data.dataLogo.data));
    const logoImageDims = logoImage.scale(0.5);

    const bottomImage = await pdfDoc.embedPng(toArrayBuffer(res.data.dataBottom.data));
    const bottomImageDims = bottomImage.scale(0.5);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();

    firstPage.drawImage(logoImage, {
      x: width - logoImageDims.width - 25,
      y: height - logoImageDims.height - 25,
      width: logoImageDims.width,
      height: logoImageDims.height,
    });

    firstPage.drawImage(bottomImage, {
      x: 0,
      y: 0,
      width: bottomImageDims.width,
      height: bottomImageDims.height,
    });


    let pdfBytes = null;

    if (!noDownload) {
      pdfBytes = await pdfDoc.save();
    } else {
      pdfBytes = await pdfDoc.saveAsBase64();
    }

    if (!noDownload) {
      download(pdfBytes, `${name}.pdf`, 'application/pdf');
    } else {
      return {
        pdf: pdfBytes,
        name: `${name}.pdf`,
        type: 'application/pdf',
      };
    }

    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    async handleGetPdf() {
      const pdf = await createPdf(true);
      return pdf;
    },
    async handleChangeValue(varName, value) {
      if (varName === 'amount') {
        _amount = value;
      }
    },
  }));

  return (!noButton ? (
    <Button__inline
      bigIcon
      icon={loading ? <FontAwesomeIcon icon={faSync} spin /> : <FontAwesomeIcon icon={faFileContract} />}
      disable={loading}
      event={createPdf}
      content={loading ? loadingtext : buttonText}
    />
  ) : null
  );
});

export default Contract__pdf;
