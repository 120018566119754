
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Illu = styled.img`
  height: 270px;
  transition: .5s ease all;
  opacity: 0;
  transform: translateY(-20px);
  ${({ active }) => active && `
    opacity: 1;
    transform: translateY(0);
  `};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 24px;
  text-align: center;
  color: #4D4D4D;
  margin-top: 40px;
  margin-bottom: 25px;
  transition: .5s 0.05s ease all;
  opacity: 0;
  transform: translateY(-20px);
  ${({ active }) => active && `
    opacity: 1;
    transform: translateY(0);
  `};
`;

const Content = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: #4D4D4D;
  max-width: 470px;
  transition: .5s 0.1s ease all;
  opacity: 0;
  transform: translateY(-20px);
  ${({ active }) => active && `
    opacity: 1;
    transform: translateY(0);
  `};
`;

const Onboarding__step = ({
  title, content, image, status,
}) => {
  const [active, setactive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setactive(true);
    }, 10);
  }, []);

  useEffect(() => {
    if (status === 'hide') {
      setactive(false);
    } else if (status === 'show') {
      setactive(true);
    }
  }, [status]);

  return (
    <Container>
      <Illu active={active} src={image} />
      <Title active={active}>{title}</Title>
      <Content active={active}>{content}</Content>
    </Container>
  );
};

export default Onboarding__step;
