
import React from 'react';
import LeadGenerationWorkshopDasboard from 'components/services/LeadGeneration/workshop/dashboard/Dashboard';
import { config } from 'util/constant/leadGeneration/alertGasoil.config';

/**
 * @name DashboardAlertGasoil
 */

const DashboardAlertGasoil = () => (
  <LeadGenerationWorkshopDasboard
    config={config}
  />
);

export default DashboardAlertGasoil;
