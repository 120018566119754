import { GetCasesHistoric } from '../../../../helpers/api/routes/Cases';

export const GetCasesReq = async ({
  page, filter, limit, params,
}) => {
  const res = await GetCasesHistoric({
    ...params,
    page,
    limit,
    filter,
  });
  return res;
};
