import Mail__confirmRegistration from 'components/mail/confirmRegistration/mail__confirmRegistration';
import Mail__notification from 'components/mail/notification/mail__notification';
import Mail__supervisor from 'components/mail/notification/mail__supervisor';
import { API_URL } from '../Utile';
import { CallPost } from '../Helpers';

// export const SendMailToAdmin = async (mailObj) => {
//   const headers = {
//     Authorization: `Bearer ${localStorage.getItem('token')}`,
//   };

//   const params = mailObj;

//   const url = `${API_URL}utile/sendAdminMail/`;

//   const res = await CallPost(url, params, headers);

//   return res;
// };

export const SendMail = async (mailObj) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  let templateHtml = '';

  if (mailObj.template === 'welcome') {
    templateHtml = Mail__confirmRegistration({
      statusLabel: mailObj.templateWelcome.statusLabel,
      content: mailObj.templateWelcome.content,
      btnContent: mailObj.templateWelcome.btnContent,
      btnLink: mailObj.templateWelcome.btnLink,
      title: mailObj.subject,
    });
  } else if (mailObj.template === 'notification') {
    templateHtml = Mail__notification({
      status: mailObj.templateNotification.status,
      statusLabel: mailObj.templateNotification.statusLabel,
      content: mailObj.templateNotification.content,
      btnContent: mailObj.templateNotification.btnContent,
      btnLink: mailObj.templateNotification.btnLink,
      title: mailObj.subject,
    });
  } else if (mailObj.template === 'gop-supervisor') {
    templateHtml = Mail__supervisor({
      content: mailObj.content,
    });
  } else if (mailObj.template === 'admin') {
    const params = {
      html: Mail__notification({
        status: mailObj.templateNotification.status,
        statusLabel: mailObj.templateNotification.statusLabel,
        content: mailObj.templateNotification.content,
        btnContent: mailObj.templateNotification.btnContent,
        btnLink: mailObj.templateNotification.btnLink,
        title: mailObj.subject,
      }),
      subject: mailObj.subject,
    };
    const url = `${API_URL}utile/sendAdminMail/`;
    const res = await CallPost(url, params, headers);
    return res;
  } else if (mailObj.template === 'raw') {
    templateHtml = mailObj.content;
  } else {
    templateHtml = 'other';
  }

  const params = {
    html: templateHtml,
    subject: mailObj.subject,
    destination: mailObj.destination,
  };

  if (mailObj.attachments) {
    params.attachments = JSON.stringify(mailObj.attachments);
  }

  const url = `${API_URL}utile/sendmail/`;

  const res = await CallPost(url, params, headers);

  return res;
};
