import React, { useContext, useEffect } from 'react';
import Nav from 'components/global/nav';
import styled from 'styled-components';
import t from 'theme';
import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Transition__loader_controller } from 'components/transition/transition';
import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';
import Grid__separator from 'components/grid/separator/grid__separator';
import { strapiWorkshopJoinServices } from 'util/strapi/content.starpi';
import { ContentContext } from 'context/contentContext';
import { config as smartMaintenanceConfig } from 'util/constant/leadGeneration/smartMaintenance.config';
import { config as alertGasoilConfig } from 'util/constant/leadGeneration/alertGasoil.config';
import { config as proFleetAssistConfig } from 'util/constant/leadGeneration/proFleetAssist.config';
// import { config as tpmsConfig } from 'util/constant/leadGeneration/tpms.config';
import LeadGeneration from 'components/services/LeadGeneration/JoinServices';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EmptyService = styled.div`
  font-size: 14px;
  line-height: 19px;
  padding: 35px;
  box-sizing: border-box;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  border: 2px dashed rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 310px;
  height: 360px;
  ${t.mixins.flex({ justify: 'center', align: 'center', direction: 'column' })};
`;

const Strong = styled.div`
  font-weight: bold;
`;

/**
 * @name MyServices
 */

const JoinServices = () => {
  const { getField, haveContentFor, loadPage } = useContext(ContentContext);
  const loadContent = async () => {
    if (haveContentFor(strapiWorkshopJoinServices).success === false) {
      await loadPage(strapiWorkshopJoinServices);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (
    haveContentFor(strapiWorkshopJoinServices).success === false
  ) {
    return <Transition__loader_controller active />;
  }


  return (
    <Layout__container>
      <Nav workshop active="joinServices" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <Layout__content>
          <Content__pageTitle
            content={getField({ base: strapiWorkshopJoinServices, field: 'title' })}
          />
          <Grid__separator size="40px" />
          <Row>
            <LeadGeneration workshop config={smartMaintenanceConfig} />
            <LeadGeneration workshop config={proFleetAssistConfig} />
            <LeadGeneration workshop config={alertGasoilConfig} />
            {/* <LeadGeneration workshop config={tpmsConfig} /> */}
            <EmptyService>
              <div>{getField({ base: strapiWorkshopJoinServices, field: 'cards_text' })}</div>
              <Grid__separator size="25px" />
              <Strong>{getField({ base: strapiWorkshopJoinServices, field: 'comming_soon' })}</Strong>
            </EmptyService>
          </Row>
        </Layout__content>
      </Layout__wrapper>
    </Layout__container>
  );
};

export default JoinServices;
