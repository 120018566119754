import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AppContext } from 'context/appContext';
import { ContentContext } from 'context/contentContext';
import t from 'theme';
import Cards__serviceCard from 'components/cards/serviceCard/cards__serviceCard';
import ServicesDetails from 'components/services/LeadGeneration/ServiceDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatApiRes } from 'util/function/formatApiRes.function';
import Message__sucess from 'components/message/sucess/message__sucess';
import { Grid__popup } from 'components/grid/grid';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  position: relative;
`;

const SucessPop = styled(Grid__popup)`
  display: flex;
  justify-content:center;
  align-items:center;
`;

const Service = ({ workshop, config }) => {
  const {
    getField, getFieldGroup, haveContentFor, loadPage,
  } = useContext(ContentContext);

  const { getUser, user } = useContext(AppContext);
  const [popUpActive, setPopUpActive] = useState(false);
  const [successPop, setsuccessPop] = useState(false);
  const [alreadyHave, setalreadyHave] = useState(true);
  const [loading, setloading] = useState(false);
  const [loadingUser, setloadingUser] = useState(false);
  const history = useHistory();

  const checkhaveSmartMaintenance = async () => {
    const res = await config.api.fleetHaveService();

    const formated = formatApiRes(res);
    if (!formated.success) {
      const resMail = await config.api.checkMail(user.user.email);
      const formatedMail = formatApiRes(resMail);
      if (workshop) {
        setalreadyHave(formatedMail.success || false);
      } else if (formatedMail.data.status === config.constant.mailStatus.ERROR) {
        setalreadyHave(false);
      } else {
        setalreadyHave(formatedMail.success || false);
      }
    } else {
      setalreadyHave(formated.success || false);
    }
  };

  const perfomSave = async () => {
    setloading(true);
    const addServices = await config.api.addFleetService({
      userId: user.user.id,
      origin: 'fleet',
      source: 'fleet',
      status: 'sent-to-stratio',
      fleetCompanyName: user.user.companyName,
      fleetContact: `${user.user.lastName} ${user.user.firstName}`,
      fleetEmail: user.user.email,
      fleetPhoneNumber: user.user.phoneNumber,
    });
    if (addServices) {
      setloading(false);
      if (formatApiRes(addServices).success === true) {
        checkhaveSmartMaintenance();
        setPopUpActive(false);
        setloading(false);
        setsuccessPop(true);
      }
    } else {
      setloading(false);
    }
  };


  const handleSave = () => {
    perfomSave();
  };

  const handleCloseSucess = async () => {
    setloadingUser(true);
    const resUser = await getUser();
    if (resUser.success === true) {
      setloadingUser(false);
      setsuccessPop(false);
    }
  };

  const handleClose = async () => {
    setsuccessPop(false);
  };

  const handleDashboardSucess = async () => {
    setloadingUser(true);
    const resUser = await getUser();
    if (resUser.success === true) {
      setloadingUser(false);
      setsuccessPop(false);
      history.push(config.dashboardPath);
    }
  };

  useEffect(() => {
    if (popUpActive) {
      checkhaveSmartMaintenance();
    }
  }, [popUpActive]);

  const handleClickSubscribe = async () => {
    if (workshop) {
      setloading(true);
      const res = await config.api.registerWorkshop();
      if (formatApiRes(res).success === true) {
        setsuccessPop(true);
      }
      setloading(false);
    }
  };

  const renderSmartMaintenance__pop = () => (
    <ServicesDetails
      workshop={workshop}
      clickSubscribe={handleClickSubscribe}
      popupActive={popUpActive}
      handleSave={handleSave}
      alreadyHave={alreadyHave}
      closeEvent={() => setPopUpActive(false)}
      loading={loading}
      config={config}
    />
  );

  const loadContent = async () => {
    if (haveContentFor(config.strapiPage).success === false) {
      await loadPage(config.strapiPage);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (workshop && config.check.haveServicesWorkshop(user) === true) {
    return null;
  }

  return (
    <Container>
      {renderSmartMaintenance__pop()}
      <SucessPop active={successPop} closeEvent={handleCloseSucess}>
        {workshop ? (
          <Message__sucess
            icon={t.image.illu.check}
            title={getFieldGroup({ base: config.strapiPage, groupe: config.content.workshop_subscription_success.group, field: config.content.workshop_subscription_success.title })}
            text={getFieldGroup({ base: config.strapiPage, groupe: config.content.workshop_subscription_success.group, field: config.content.workshop_subscription_success.content })}
            buttonText={getFieldGroup({ base: config.strapiPage, groupe: config.content.workshop_subscription_success.group, field: config.content.workshop_subscription_success.button })}
            buttonEvent={handleDashboardSucess}
          />
        ) : (
          <Message__sucess
            icon={t.image.illu.check}
            title={getFieldGroup({ base: config.strapiPage, groupe: config.content.fleet_subscription_success.group, field: config.content.fleet_subscription_success.title })}
            text={getFieldGroup({ base: config.strapiPage, groupe: config.content.fleet_subscription_success.group, field: config.content.fleet_subscription_success.content })}
            buttonText={getFieldGroup({ base: config.strapiPage, groupe: config.content.fleet_subscription_success.group, field: config.content.fleet_subscription_success.button })}
            buttonEvent={handleClose}
          />
        )}
        {loadingUser ? <Loader__wrapper /> : false}
      </SucessPop>
      <Cards__serviceCard
        event={() => setPopUpActive(true)}
        imageUrl={config.details.banner}
        icon={<FontAwesomeIcon icon={config.details.icon} color="#4D4D4D" />}
        title={getField({ base: config.strapiPage, field: config.content.service_title })}
        description={
          workshop
            ? getField({ base: config.strapiPage, field: config.content.service_description_short_workshop })
            : getField({ base: config.strapiPage, field: config.content.service_description_short })
        }
        label={getField({ base: config.strapiPage, field: config.content.new_label })}
      />
      {!haveContentFor(config.strapiPage).success && <Loader__wrapper absolute />}
    </Container>
  );
};

export default Service;
