
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ContentContext } from 'context/contentContext';

import { List__table, List__content } from 'components/list/list';
import { sequelizeDate } from 'util/date.util';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';


/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 40px;
`;

const Table = styled(List__table)`
  height: calc(100% - 70px);
`;

const Fleet__list = ({
  style, vehicles, lineEvent, scrollEndEvent, loadingNewPage,
}) => {
  const { getGlobal } = useContext(ContentContext);
  const headerCol = [
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'vincode' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'category' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'brand' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'mileage' }),
    },
  ];

  const [fleetFormatted, setFleetFormatted] = useState([]);
  const [fleetNoFormatted, setFleetNoFormatted] = useState([]);

  const formatMileage = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  useEffect(() => {
    if (vehicles.length > 0) {
      const obj = [];
      const noFormatedObj = [];
      const orderArray = vehicles;
      orderArray.forEach((element) => {
        const childObj = [];

        childObj.push({ label: <List__content uppercase>{element.registrationNumber}</List__content> });
        childObj.push({
          label: (
            <List__content capitalize>
              {getGlobal({ option: 'country', field: element.countryOfRegistration })
                || element.countryOfRegistration}
            </List__content>
          ),
        });
        childObj.push({ label: <List__content uppercase>{element.vin}</List__content> });
        childObj.push({
          label: (
            <List__content capitalize>
              {getGlobal({ option: 'vehicletype', field: element.type }) || element.type}
            </List__content>
          ),
        });
        childObj.push({ label: <List__content capitalize>{element.brand}</List__content> });
        childObj.push({
          label: (
            <List__content>{sequelizeDate({ date: element.firstDate, format: 'd/m/y' })}</List__content>
          ),
        });
        childObj.push({ label: <List__content>{formatMileage(element.mileage)}</List__content> });

        obj.push(childObj);
        noFormatedObj.push(element);
      });

      setFleetFormatted(obj);
      setFleetNoFormatted(noFormatedObj);
    }
  }, [vehicles]);

  const handleLineClick = (payload) => {
    if (fleetNoFormatted) {
      if (fleetNoFormatted[payload.key]) {
        lineEvent(fleetNoFormatted[payload.key]);
      }
    }
  };

  return (
    <Container style={style}>
      <Table
        headerCol={headerCol}
        lineCol={fleetFormatted}
        noSelect
        lineEvent={handleLineClick}
        maxHeight="500px"
        scrollEndEvent={scrollEndEvent}
      />
      {
        loadingNewPage && (
          <Loader__wrapper />
        )
      }
    </Container>
  );
};

export default Fleet__list;
