
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import t from 'theme';
import { slugify } from 'helpers/js/Utile';

import { ContentContext } from 'context/contentContext';
import { WorkshopContext } from 'context/fleetUser/workshopContext';


/**
 * import containers & components
 */

import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { Grid__separator } from 'components/grid/grid';
import Button__primary from 'components/button/primary/button__primary';
import { Form__select, Form__textfield } from 'components/form/form';
import { List__table, List__content } from 'components/list/list';
/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
`;

const RowPopUp = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'center', align: 'flex-end' })}
`;

const InfoText = styled.div`
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #4d4d4d;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * notset component is..
 */

const ServiceDashboard__workshopList = ({
  saveEvent,
  loading,
  prevEvent,
  withDisable,
  noText,
}) => {
  const { getGlobal, getGlobalObj } = useContext(ContentContext);
  const { getAllWorkshop, workshop, initial } = useContext(WorkshopContext);
  const [countrySelect, setCountrySelect] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [selectedWorkShop, setSelectedWorkShop] = useState(null);

  const headerCol = [
    {
      label: getGlobal({ option: 'workshopInformation', field: 'name' }),
    },
    {
      label: getGlobal({ option: 'workshopInformation', field: 'country' }),
    },
    {
      label: getGlobal({ option: 'workshopInformation', field: 'address' }),
    },
    {
      label: getGlobal({ option: 'workshopInformation', field: 'city' }),
    },
    {
      label: getGlobal({ option: 'workshopInformation', field: 'zipcode' }),
    },
  ];

  const [workshopCol, setWorkshopCol] = useState([]);
  const [workshopColNoFormat, setWorkshopColNoFormat] = useState([]);
  const [baseworkshopNoFormat, setbaseWorkshopNoFormat] = useState([]);

  useEffect(() => {
    const keyObject = Object.keys(getGlobalObj({ option: 'countryCode' }));
    const selectArray = [];

    keyObject.forEach((element) => {
      const obj = {
        label: getGlobal({ option: 'countryCode', field: element }),
        slug: slugify(element),
        active: false,
      };

      selectArray.push(obj);
    });

    selectArray[0].active = true;
    setCountrySelect(selectArray);
  }, []);

  const parseWorkshopCol = () => {
    const array = [];
    const arrayNoFormat = [];
    workshop.forEach((element) => {
      array.push([
        {
          label: (<List__content>{element.companyName}</List__content>),
        },
        {
          label: (<List__content capitalize>{element.region}</List__content>),
        },
        {
          label: (<List__content>{element.adress}</List__content>),
        },
        {
          label: (<List__content>{element.city}</List__content>),
        },
        {
          label: (<List__content>{element.zipcode}</List__content>),
        },
      ]);
      arrayNoFormat.push(element);
    });

    setWorkshopColNoFormat(arrayNoFormat);
    setWorkshopCol(array);
    setbaseWorkshopNoFormat(arrayNoFormat);
  };

  const filterArray = ({ value }) => {
    const filtered = baseworkshopNoFormat.filter((single) => single.countryCode === slugify(value.slug));
    const array = [];
    const arrayNoFormat = [];
    filtered.forEach((element) => {
      array.push([
        {
          label: (<List__content>{element.companyName}</List__content>),
        },
        {
          label: (<List__content capitalize>{element.region}</List__content>),
        },
        {
          label: (<List__content>{element.adress}</List__content>),
        },
        {
          label: (<List__content>{element.city}</List__content>),
        },
        {
          label: (<List__content>{element.zipcode}</List__content>),
        },
      ]);
      arrayNoFormat.push(element);
    });

    setWorkshopColNoFormat(arrayNoFormat);
    setWorkshopCol(array);
  };

  const searchArray = ({ value }) => {
    const filtered = baseworkshopNoFormat.filter(
      (single) => slugify(single.city).includes(slugify(value))
        || slugify(single.companyName).includes(slugify(value)) || slugify(single.region).includes(slugify(value)),
    );
    const array = [];
    const arrayNoFormat = [];
    filtered.forEach((element) => {
      array.push([
        {
          label: (<List__content>{element.companyName}</List__content>),
        },
        {
          label: (<List__content capitalize>{element.region}</List__content>),
        },
        {
          label: (<List__content>{element.adress}</List__content>),
        },
        {
          label: (<List__content>{element.city}</List__content>),
        },
        {
          label: (<List__content>{element.zipcode}</List__content>),
        },
      ]);
      arrayNoFormat.push(element);
    });

    setWorkshopColNoFormat(arrayNoFormat);

    setWorkshopCol(array);
  };

  useEffect(() => {
    if (workshop === null || initial === false) {
      getAllWorkshop();
    } else if (workshopCol.length === 0) {
      parseWorkshopCol();
    }
  }, [workshop]);

  const handleSelect = ({ value, result }) => {
    filterArray({ value: result });
    setCountrySelect(value);
  };

  const handleSearch = ({ value }) => {
    searchArray({ value });
    setSearchValue(value);
  };

  const handleSave = () => {
    if (selectedWorkShop !== null) {
      if (saveEvent) {
        saveEvent(selectedWorkShop);
      }
    }
  };

  return (
    <React.Fragment>
      <Container>
        {!noText && (
          <>
            <InfoText>{getGlobal({ option: 'service', field: 'select_workshop_text' })}</InfoText>
            <Grid__separator size="20px" />
          </>
        )}
        {countrySelect !== false ? (
          <RowPopUp>
            <Form__select
              value={countrySelect}
              style={{ width: 220 }}
              event={(res) => handleSelect({
                property: 'countrySelect',
                value: res.value,
                result: res.result,
                type: 'select',
              })}
            />
            <Grid__separator width="15px" />
            <Form__textfield
              change={handleSearch}
              value={searchValue}
              style={{ width: 350 }}
              property="searchValue"
              label=""
              search
              placeholder={getGlobal({ option: 'service', field: 'select_workshop_search' })}
            />
          </RowPopUp>
        ) : null}
        <Grid__separator size="30px" />
        <List__table
          headerCol={headerCol}
          lineCol={workshopCol}
          maxHeight="250px"
          noSelectAll
          selectOne
          borderLine
          selectedLine={(value) => setSelectedWorkShop(workshopColNoFormat[parseInt(value[0].key, 10)])}
        />
        <Grid__separator size="35px" />
        <Row>
          <Button__primary border blue event={prevEvent}>{getGlobal({ option: 'service', field: 'single_launch_prevbutton' })}</Button__primary>
          <Button__primary disable={withDisable ? !selectedWorkShop : false} style={{ marginLeft: 25 }} event={handleSave}>{getGlobal({ option: 'service', field: 'select_workshop_button' })}</Button__primary>
        </Row>
      </Container>
      {loading ? <Loader__wrapper /> : false}
    </React.Fragment>
  );
};

export default ServiceDashboard__workshopList;
