import React, { createContext } from 'react';
import LogRocket from 'logrocket';
import {
  Login, CheckAuth, UserInformation, updateUserInformation, updatePassword,
} from 'helpers/api/Routes';
import { ContentProvider } from 'context/contentContext';
import Onboarding__switcher from 'components/onboarding/switcher/onboarding__switcher';

/**
 * initial state
 */
export const AppContext = createContext({
  test: '',
  error: null,
  logged: false,
  resetContext: () => {},
  resetError: () => {},
  checkAuth: () => {},
  getUser: () => {},
  login: () => {},
  storeToken: () => {},
  updateUser: () => {},
  changePassword: () => {},
  logout: () => {},
});

/**
 * Provider
 */

export class AppProvider extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    user: 'test',
    error: null,
    logged: false,
    sessionUrl: null,
    login: (payload) => this.login(payload),
    resetContext: () => this.resetContext(),
    resetError: () => this.resetError(),
    checkAuth: () => this.checkAuth(),
    getUser: () => this.getUser(),
    storeToken: (token) => this.storeToken(token),
    updateUser: (payload) => this.updateUser(payload),
    changePassword: (payload) => this.changePassword(payload),
    logout: (payload) => this.logout(payload),
  };

  resetContext = () => {
    this.setState((state) => ({ ...state, test: 'test' }));
  };

  resetError = () => {
    this.setState((state) => ({ ...state, error: null }));
  };

  updateTest = async (newValue) => {
    this.setState((state) => ({ ...state, test: newValue }));
  };

  /* --------------------------------- LOGOUT --------------------------------- */

  logout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('logAs');
    window.location.reload();
  };

  /* ------------------------------- CHECK AUTH ------------------------------- */
  checkAuth = async () => {
    const res = await CheckAuth();
    if (res.hasOwnProperty('data')) {
      if (res.data.success === true) {
        if (process.env.REACT_APP_LOGROCKET_ID && process.env.REACT_APP_ENV === 'staging') {
          LogRocket.identify(`user${res.data.data.id}`, {
            name: `${res.data.data.user.firstName} ${res.data.data.user.lastName}`,
            email: res.data.data.user.email,
          });
        }
        /* ============= GA Tracking ============= */
        if (process.env.REACT_APP_ENV === 'production') {
          window.gtag('set', 'user_properties', {
            user_type: res.data.data.user.UserFleet ? 'Fleet' : 'Workshop',
            user_country: res.data.data.user.countryCode,
            user_id: res.data.data.user.id,
            user_language: res.data.data.user.lang,
          });
        }

        this.setState((state) => ({ ...state, user: res.data.data, logged: true }));
        return {
          success: true,
          data: res.data.data,
        };
      }


      return {
        success: false,
        data: null,
      };
    }



    return {
      success: false,
      data: null,
    };
  }

  /* -------------------------- GET USER INFORMATION -------------------------- */

  getUser = async () => {
    const res = await UserInformation();
    if (res.hasOwnProperty('data')) {
      if (res.data.success === true) {
        this.setState((state) => ({ ...state, user: res.data.data }));
        return {
          success: true,
        };
      }
      return {
        success: false,
      };
    }
    return {
      success: false,
    };
  }

  /* ------------------------- UPDATE USER INFORMATION ------------------------ */

  updateUser = async ({ field }) => {
    const res = await updateUserInformation({ field });
    if (res.hasOwnProperty('data')) {
      if (res.data.success === true) {
        await this.getUser();
        return {
          success: true,
        };
      }
      return {
        success: false,
      };
    }
    return {
      success: false,
    };
  }

  /* ------------------------- UPDATE PASSWORD ------------------------ */

  changePassword = async ({ field }) => {
    const res = await updatePassword({ field });
    if (res.hasOwnProperty('data')) {
      if (res.data.success === true) {
        return {
          sucess: true,
        };
      }
      return {
        sucess: false,
        message: res.data.message,
        differente: res.data.different || false,
      };
    }
    return {
      sucess: false,
    };
  }

  /* ---------------------------------- LOGIN --------------------------------- */

  login = async (payload) => {
    const res = await Login(payload);
    if (res.hasOwnProperty('data')) {
      if (res.data.success === true) {
        if (process.env.REACT_APP_LOGROCKET_ID && process.env.REACT_APP_ENV === 'staging') {
          LogRocket.identify(`user${res.data.data.id}`, {
            name: `${res.data.data.user.firstName} ${res.data.data.user.lastName}`,
            email: res.data.data.user.email,
          });
        }
        this.setState((state) => ({ ...state, user: res.data.data, logged: true }));
        this.storeToken(res.data.data.token);
        return {
          success: true,
          data: res.data,
        };
      }
      return {
        success: false,
        data: res.data.message,
      };
    }
    return {
      success: false,
      data: null,
    };
  };

  /* ------------------------------- STORE TOKEN ------------------------------ */

  storeToken = (token) => {
    localStorage.setItem('token', token);
  }

  sessionUrl = () => {
    const { sessionUrl } = this.state;
    if (!sessionUrl) {
      LogRocket.getSessionURL((sessionURL) => {
        this.setState({
          sessionURL,
        });
      });
    }
  }

  shouldRender_Onboarding = () => {
    const { user } = this.state;
    if (this.state && user && user.user && user.user.role === 'fleet' && user.user.view_tuto !== true) {
      return (<ContentProvider><Onboarding__switcher visible /></ContentProvider>);
    }

    return null;
  }

  render() {
    const { children } = this.props;
    return (
      <AppContext.Provider value={this.state}>
        {children}

        {this.shouldRender_Onboarding()}
      </AppContext.Provider>
    );
  }
}

/**
 * consumer
 */

export const AppConsumer = AppContext.Consumer;
