
import React, { createContext } from 'react';

import {
  AddVehicule as AddVehiculeApi,
  DeleteVehicule as DeleteVehiculeApi,
  UpdateVehicule as UpdateVehiculeApi,
} from 'helpers/api/Routes';

/**
 * initial state
 */
export const FleetContext = createContext({
  fleets: '',
  loaded: false,
  resetContext: () => {},
  initFleetContext: () => {},
  AddVehicule: () => {},
  UpdateVehicule: () => {},
  deleteVehicule: () => {},
});

/**
 * Provider
 */

export class FleetProvider extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    fleets: [],
    loaded: false,
    initFleetContext: () => this.initFleetContext(),
    AddVehicule: (value) => this.AddVehicule(value),
    UpdateVehicule: (value) => this.UpdateVehicule(value),
    deleteVehicule: (value) => this.deleteVehicule(value),
    resetContext: () => this.resetContext(),
  };

  resetContext = () => {
    this.setState({ fleet: 'fleet' });
  };

  initFleetContext = async () => ({
    success: true,
  })

  AddVehicule = async ({ fleetObj, userId }) => {
    const res = await AddVehiculeApi({ fleetObj, userId });
    if (res.hasOwnProperty('data')) {
      if (res.data.success === true) {
        return {
          success: true,
        };
      }
      this.setState((state) => ({ ...state, error: { type: 'addVehicule', error: res.data.message } }));
      return {
        success: false,
      };
    }
    this.setState((state) => ({ ...state, error: { type: 'addVehicule', error: 'error' } }));
    return {
      success: false,
    };
  };

  UpdateVehicule = async ({ fleetObj }) => {
    const res = await UpdateVehiculeApi({ fleetObj });
    if (res.hasOwnProperty('data')) {
      if (res.data.success === true) {
        return {
          success: true,
        };
      }
      this.setState((state) => ({ ...state, error: { type: 'addVehicule', error: res.data.message } }));
      return {
        success: false,
      };
    }
    this.setState((state) => ({ ...state, error: { type: 'addVehicule', error: 'error' } }));
    return {
      success: false,
    };
  };

  deleteVehicule = async ({ id }) => {
    const res = await DeleteVehiculeApi({ id });
    if (res.hasOwnProperty('data')) {
      if (res.data.success === true) {
        return {
          success: true,
        };
      }
      this.setState((state) => ({ ...state, error: { type: 'addVehicule', error: res.data.message } }));
      return {
        success: false,
      };
    }
    this.setState((state) => ({ ...state, error: { type: 'addVehicule', error: 'error' } }));
    return {
      success: false,
    };
  };

  render() {
    const { children } = this.props;
    return (
      <FleetContext.Provider value={this.state}>
        {children}
      </FleetContext.Provider>
    );
  }
}

/**
 * consumer
 */

export const FleetConsumer = FleetContext.Consumer;
