
import React, { useContext, Fragment } from 'react';
import t from 'theme';
import styled from 'styled-components';

/**
 * import containers & components
 */

import { Grid__separator } from 'components/grid/grid/';
import { Form__textfield } from 'components/form/form';
import { Button__primary, Button__inline } from 'components/button/button';
import { Content__text } from 'components/content/content';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { ROUTING } from 'util/constant/routing.constant';
import { createErrorObject } from 'util/conditions/checkField.condition';
import { ContentContext } from 'context/contentContext';
import { hasFieldError } from 'util/conditions/haveErrorField.conditions';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { requestPassword } from 'helpers/api/routes/User';
import { strapiLogin } from 'util/strapi/content.starpi';

/**
 * styled components
 */

const FormContent = styled.div`
  width: 100%;
  text-align: center;
`;

const SaveBtn = styled(Button__primary)`
  width: 100%;
  text-align: center;
`;

const InfoText = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #1a1a1a;
  text-align: center;
`;

const TitleForm = styled(Content__text)`
  text-align: center;
  width: 100%;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: #1a1a1a;
`;

const BtnSecondary = styled(Button__inline)`
  text-align: center;
`;

const Error = styled.p`
  font-size: 12px;
  font-weight: normal;
  color: #e74c3c;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
`;

const Success = styled.div`
  margin-top: 10px;
`;

const SuccessLogo = styled.img`
  width: 110px;
`;

const SuccessContent = styled.div`
  margin-top: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #000000;
`;

const initialState = {
  mailValue: '',
  activeScreen: false,
};

const PasswordRecovery__form = () => {
  const { getGlobal, getFieldWithNavigatorLang } = useContext(ContentContext);

  const [error, setError] = React.useState({});
  const [loading, setloading] = React.useState(false);
  const [errormessage, setErrormessage] = React.useState(false);
  const [success, setsuccess] = React.useState();
  const [formData, setformData] = React.useState(initialState);
  const { mailValue } = formData;

  const handleInputChange = ({ property, value }) => {
    setformData({
      ...formData,
      [property]: value,
    });
  };

  const checkField = () => {
    let errorObj = {};
    let errorArray = {};
    const baseArrayField = [
      {
        key: 'mail',
        type: 'email',
        value: mailValue,
      },
    ];
    errorObj = createErrorObject(errorArray, baseArrayField, errorObj.hasError, getGlobal);
    errorArray = errorObj.object;
    setError(errorArray);
    return errorObj.hasError;
  };

  const handleSave = async () => {
    if (!checkField()) {
      setloading(true);
      const obj = {
        mail: mailValue.toLowerCase(),
      };

      const res = await requestPassword({
        mail: obj.mail,
        fromUrl: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.fleet.resetPassword.withoutparams}/`,
      });

      const formated = formatApiRes(res);

      if (res) {
        setloading(false);
      }
      if (!formated.success) {
        if (formated.data && formated.data === 'no user found') {
          setErrormessage(`${getFieldWithNavigatorLang({ base: strapiLogin, field: 'passRecovery_error' })} ${obj.mail}`);
        } else {
          setErrormessage('Error, please try later');
        }
      } else {
        setsuccess(true);
      }
    }
  };
  return (
    <FormContent>
      <div>
        <TitleForm type="h1">{getFieldWithNavigatorLang({ base: strapiLogin, field: 'passRecovery_title' })}</TitleForm>
        {errormessage && <Error>{errormessage}</Error>}
        {!success ? (
          <Fragment>
            <InfoText>
              {getFieldWithNavigatorLang({ base: strapiLogin, field: 'passRecovery_info' })}
              <Grid__separator size="35px" />
            </InfoText>
            <Form__textfield
              change={handleInputChange}
              value={mailValue}
              property="mailValue"
              label={getFieldWithNavigatorLang({ base: strapiLogin, field: 'email' })}
              placeholder=""
              require
              error={hasFieldError(error, 'mail')}
            />
            <Grid__separator size="25px" />
            <SaveBtn orange event={() => handleSave()}>{getFieldWithNavigatorLang({ base: strapiLogin, field: 'passRecovery_button' })}</SaveBtn>
          </Fragment>
        ) : (
          <Success>
            <SuccessLogo src={t.image.illu.check} />
            <SuccessContent>
              {getFieldWithNavigatorLang({ base: strapiLogin, field: 'passRecovery_success' })}
              {' '}
              {mailValue && (mailValue.toLowerCase())}
            </SuccessContent>
          </Success>
        )}
        <Grid__separator size="15px" />
        <BtnSecondary
          href={ROUTING.fleet.login.path}
          content={getFieldWithNavigatorLang({ base: strapiLogin, field: 'backToLogin' })}
        />
        {loading ? <Loader__wrapper /> : false}
        <Grid__separator size="25px" />
      </div>
    </FormContent>
  );
};

export default PasswordRecovery__form;
