import { Home as homeAPI } from './routes/Sample';

import {
  GetPageContent as GetPageContentApi,
} from './routes/strapi';

import {
  Login as LoginApi,
  CheckAuth as CheckAuthApi,
  UserInformation as UserInformationApi,
  updateUserInformation as updateUserInformationApi,
  updatePassword as updatePasswordApi,
} from './routes/User';

import {
  AddVehicule as AddVehiculeApi,
  UpdateVehicule as UpdateVehiculeApi,
  DeleteVehicule as DeleteVehiculeApi,
  setHomeBase as setHomeBaseApi,
} from './routes/Fleets';

import {
  GetAllWorkshop as GetAllWorkshopApi,
} from './routes/Workshop';

/**
 * Base routes
 */

export const Home = homeAPI;

/**
 * Get strapi content
 */

export const GetPageContent = GetPageContentApi;

/**
 * User API
 */

export const Login = LoginApi;
export const CheckAuth = CheckAuthApi;
export const UserInformation = UserInformationApi;
export const updateUserInformation = updateUserInformationApi;
export const updatePassword = updatePasswordApi;

/**
 * Fleets API
 */
export const AddVehicule = AddVehiculeApi;
export const UpdateVehicule = UpdateVehiculeApi;
export const DeleteVehicule = DeleteVehiculeApi;
export const setHomeBase = setHomeBaseApi;

/**
 * Workshop API
 */
export const GetAllWorkshop = GetAllWorkshopApi;
