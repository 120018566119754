import React, { Fragment } from 'react';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';

/* -------------------------- COMPONENT && CONTEXT -------------------------- */

import { AppProvider, AppContext } from 'context/appContext';
import { FleetProvider } from 'context/fleetUser/fleetContext';
import { WorkshopProvider } from 'context/fleetUser/workshopContext';
import { ContentProvider } from 'context/contentContext';
import { GlobalStyle } from 'theme/styled/reset';
import { Transition__mover, Transition__loader } from 'components/transition/transition';
import RouteChangeTracker from 'util/tracking/routeChangeTracker';

/* --------------------------------- GLOBAL --------------------------------- */

import PrivateRoute from 'containers/Useful/PrivateRoute';
import Login from 'pages/login';
import Page404 from 'pages/Page404';
import PasswordRecovery from 'pages/passwordRecovery';
import ResetPassword from 'pages/resetPassword';
import Register from 'pages/register';
import RegisterFinal from 'pages/registerFinal';
import GeneralCondition from 'pages/generalCondition';
import Gdpr from 'pages/gdpr';

/* ---------------------------------- FLEET --------------------------------- */

import Home from 'pages/fleet/home';
import MyFleet from 'pages/fleet/myFleet';
import HomeBase from 'pages/fleet/homeBase';
import Account from 'pages/fleet/account';
import AddServices from 'pages/fleet/addServices';
import MemberCard from 'pages/fleet/memberCard';
import MyServices from 'pages/fleet/myServices';
import Map from 'pages/fleet/map';
import Appointment from 'pages/fleet/appointment';
import AppointmentWorkshop from 'pages/workshop/appointment';
import DashboardFairPrice from 'pages/fleet/dashboardFairPrice';
import DashboardStandardRepair from 'pages/fleet/dashboardStandardRepair';
import DashboardRepairApproval from 'pages/fleet/dashboardRepairApproval';
import DashboardPriority90 from 'pages/fleet/dashboardPriority90';
import DashboardService24 from 'pages/fleet/dashboardService24';
import Workshop_DashboardService24 from 'pages/workshop/dashboardService24';
import DashboardPaymentGuarantee from 'pages/fleet/dashboardPaymentGuarantee';
import { ROUTING } from 'util/constant/routing.constant';

import RepairApprovalConfirmation from 'pages/ext/repairApprovalConfirmation';
import PaymentGuaranteeConfirmation from 'pages/ext/paymentGuaranteeConfirmation';

import ConditionPaymentGuarantee from 'pages/fleet/ConditionPaymentGuarantee';
import ConditionPriority90 from 'pages/fleet/ConditionPriority90';
import ConditionRepairApproval from 'pages/fleet/ConditionRepairApproval';
import ConditionService24 from 'pages/fleet/ConditionService24';

/* --------------- WORKSHOP CASES --------------- */
import Workshop__cases from 'pages/workshop/cases';
import Workshop__singleCase from 'pages/workshop/singleCase';

/* ---------------------------------- WORKSHOP --------------------------------- */

import Workshop__Home from 'pages/workshop/home';
import Workshop__CheckVehicle from 'pages/workshop/checkVehicle';
import Workshop__MyFleets from 'pages/workshop/myFleets';
import Workshop__JoinServices from 'pages/workshop/joinServices';
import Workshop_DashboardRepairApproval from 'pages/workshop/dashboardRepairApproval';
import Workshop_DashboardPaymentGuarantee from 'pages/workshop/dashboardPaymentGuarantee';
import Workshop_DashboardSmartMaintenance from 'pages/workshop/dashboardSmartMaintenance';
import Global__smallScreen from 'components/global/smallScreen/global__smallScreen';
import { returnRouting } from './util/conditions/role.condition';
import ObligConfirmation from './pages/ext/obligoConfirmation';
import SmartMaintenanceConfirmation from './pages/ext/smartMaintenanceConfirmation';
import AlertGasoilConfirmation from './pages/ext/alertGasoilConfirmation';
import TpmsConfirmation from './pages/ext/tpmsConfirmation';
import ProFleetAssistConfirmation from './pages/ext/proFleetAssistConfirmation';
import Workshop_DashboardAlertGasoil from './pages/workshop/dashboardAlertGasoil';
import Workshop_DashboardProFleetAssist from './pages/workshop/dashboardProFleetAssist';
import Workshop_DashboardTpms from './pages/workshop/dashboardTpms';
import LogAs from './pages/logAs';


function App() {
  const checkLocation = () => {
    let currentUrl = window.location.href;
    let hasToChange = false;
    if (currentUrl.includes('http://prod-alltrucks-fleet-front.herokuapp.com') || currentUrl.includes('https://prod-alltrucks-fleet-front.herokuapp.com') || currentUrl.includes('http://www.prod-alltrucks-fleet-front.herokuapp.com') || currentUrl.includes('https://www.prod-alltrucks-fleet-front.herokuapp.com')) {
      currentUrl = currentUrl.replace('http://prod-alltrucks-fleet-front.herokuapp.com', 'https://alltrucks-fleet-platform.com');
      currentUrl = currentUrl.replace('https://prod-alltrucks-fleet-front.herokuapp.com', 'https://alltrucks-fleet-platform.com');
      currentUrl = currentUrl.replace('http://www.prod-alltrucks-fleet-front.herokuapp.com', 'https://alltrucks-fleet-platform.com');
      currentUrl = currentUrl.replace('https://www.prod-alltrucks-fleet-front.herokuapp.com', 'https://alltrucks-fleet-platform.com');
      hasToChange = true;
    }
    if (currentUrl.includes('www.alltrucks-fleet-platform.com')) {
      currentUrl = currentUrl.replace('www.alltrucks-fleet-platform.com', 'alltrucks-fleet-platform.com');
      hasToChange = true;
    }
    if (!currentUrl.includes('https') && currentUrl.includes('http') && (currentUrl.includes('alltrucks-fleet-platform.com') || currentUrl.includes('prod-alltrucks-fleet-front.herokuapp.com') || currentUrl.includes('prod-alltrucks-fleet-front.herokuapp.com') || currentUrl.includes('staging-alltrucks-fleet-front.herokuapp.com'))) {
      currentUrl = currentUrl.replace('http', 'https');
      hasToChange = true;
    }
    if (hasToChange) {
      window.location.href = currentUrl;
    }
  };
  checkLocation();

  return (
    <AppProvider>
      <ContentProvider>
        <FleetProvider>
          <WorkshopProvider>
            <GlobalStyle whiteColor />
            <Transition__mover />
            <Transition__loader />
            <AppContext.Consumer>
              {(AppContextConsumer) => (
                <Fragment>
                  <Router>
                    <RouteChangeTracker />
                    <Switch>
                      {/* ======================
                        fleet routes
                      ====================== */}
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.conditionPaymentGuarantee.path,
                            workshop: ROUTING.fleet.conditionPaymentGuarantee.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <ConditionPaymentGuarantee />,
                          workshop: <Page404 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.conditionPriority90.path,
                            workshop: ROUTING.fleet.conditionPriority90.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <ConditionPriority90 />,
                          workshop: <Page404 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.conditionRepairApproval.path,
                            workshop: ROUTING.fleet.conditionRepairApproval.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <ConditionRepairApproval />,
                          workshop: <Page404 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.conditionService24.path,
                            workshop: ROUTING.fleet.conditionService24.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <ConditionService24 />,
                          workshop: <Page404 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.home.path,
                            workshop: ROUTING.workshop.home.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Home />,
                          workshop: <Workshop__Home />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.myFleet.path,
                            workshop: ROUTING.fleet.myFleet.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <MyFleet />,
                          workshop: <Page404 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.homeBase.path,
                            workshop: ROUTING.fleet.homeBase.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <HomeBase />,
                          workshop: <Page404 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.addServices.path,
                            workshop: ROUTING.fleet.addServices.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <AddServices />,
                          workshop: <Page404 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.memberCard.path,
                            workshop: ROUTING.fleet.memberCard.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <MemberCard />,
                          workshop: <Page404 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.myServices.path,
                            workshop: ROUTING.fleet.myServices.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <MyServices />,
                          workshop: <Page404 />,
                        })}

                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.account.path,
                            workshop: ROUTING.workshop.myAccount.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Account />,
                          workshop: <Account />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.map.path,
                            workshop: ROUTING.fleet.map.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Map />,
                          workshop: <Page404 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.appointment.path,
                            workshop: ROUTING.fleet.appointment.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Appointment />,
                          workshop: <AppointmentWorkshop />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.dashboardFairPrice.path,
                            workshop: ROUTING.fleet.dashboardFairPrice.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <DashboardFairPrice />,
                          workshop: <Page404 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.dashboardStandardRepair.path,
                            workshop: ROUTING.workshop.dashboardStandardRepair.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <DashboardStandardRepair />,
                          workshop: <Page404 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.dashboardRepairApproval.path,
                            workshop: ROUTING.workshop.dashboardRepairApproval.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <DashboardRepairApproval />,
                          workshop: <Workshop_DashboardRepairApproval />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.dashboardPriority90.path,
                            workshop: ROUTING.workshop.dashboardPriority90.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <DashboardPriority90 />,
                          workshop: <Page404 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.dashboardService24.path,
                            workshop: ROUTING.workshop.dashboardService24.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <DashboardService24 />,
                          workshop: <Workshop_DashboardService24 />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.dashboardPaymentGuarantee.path,
                            workshop: ROUTING.workshop.dashboardPaymentGuarantee.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <DashboardPaymentGuarantee />,
                          workshop: <Workshop_DashboardPaymentGuarantee />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.dashboardSmartMaintenance.path,
                            workshop: ROUTING.workshop.dashboardSmartMaintenance.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Page404 />,
                          workshop: <Workshop_DashboardSmartMaintenance />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.dashboardAlertGasoil.path,
                            workshop: ROUTING.workshop.dashboardAlertGasoil.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Page404 />,
                          workshop: <Workshop_DashboardAlertGasoil />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.dashboardProFleetAssist.path,
                            workshop: ROUTING.workshop.dashboardProFleetAssist.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Page404 />,
                          workshop: <Workshop_DashboardProFleetAssist />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.fleet.dashboardTpms.path,
                            workshop: ROUTING.workshop.dashboardTpms.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Page404 />,
                          workshop: <Workshop_DashboardTpms />,
                        })}
                      />
                      {/* ======================
                        workshop routes
                      ====================== */}
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.workshop.checkVehicle.path,
                            workshop: ROUTING.workshop.checkVehicle.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Page404 />,
                          workshop: <Workshop__CheckVehicle />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.workshop.cases.path,
                            workshop: ROUTING.workshop.cases.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Page404 />,
                          workshop: <Workshop__cases />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.workshop.singleCases.path,
                            workshop: ROUTING.workshop.singleCases.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Page404 />,
                          workshop: <Workshop__singleCase />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.workshop.myFleets.path,
                            workshop: ROUTING.workshop.myFleets.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Page404 />,
                          workshop: <Workshop__MyFleets />,
                        })}
                      />
                      <PrivateRoute
                        logged={AppContextConsumer.logged}
                        exact
                        path={
                          returnRouting({
                            user: AppContextConsumer.user,
                            fleet: ROUTING.workshop.joinServices.path,
                            workshop: ROUTING.workshop.joinServices.path,
                          })
                        }
                        component={() => returnRouting({
                          user: AppContextConsumer.user,
                          fleet: <Page404 />,
                          workshop: <Workshop__JoinServices />,
                        })}
                      />
                      {/*
                        Global
                      */}
                      <Route
                        exact
                        path={ROUTING.fleet.login.path}
                        component={() => <Login />}
                      />
                      <Route
                        exact
                        path={ROUTING.fleet.logAs.path}
                        component={() => <LogAs />}
                      />
                      <Route
                        exact
                        path={ROUTING.fleet.passwordRecovery.path}
                        component={() => <PasswordRecovery />}
                      />
                      <Route
                        exact
                        path={ROUTING.fleet.resetPassword.path}
                        component={() => <ResetPassword />}
                      />
                      <Route
                        exact
                        path={ROUTING.fleet.register.path}
                        component={() => <Register />}
                      />
                      <Route
                        exact
                        path={ROUTING.fleet.generalCondition.path}
                        component={() => <GeneralCondition />}
                      />
                      <Route
                        exact
                        path={ROUTING.fleet.gdpr.path}
                        component={() => <Gdpr />}
                      />
                      <Route
                        exact
                        path={ROUTING.fleet.registerFinal.path}
                        component={() => <RegisterFinal />}
                      />
                      <Route
                        exact
                        path={ROUTING.ext.repairApprovalConfirmation.path}
                        component={() => <RepairApprovalConfirmation />}
                      />
                      <Route
                        exact
                        path={ROUTING.ext.smartMaintenanceConfirmation.path}
                        component={() => <SmartMaintenanceConfirmation />}
                      />
                      <Route
                        exact
                        path={ROUTING.ext.alertGasoilConfirmation.path}
                        component={() => <AlertGasoilConfirmation />}
                      />
                      <Route
                        exact
                        path={ROUTING.ext.tpmsConfirmation.path}
                        component={() => <TpmsConfirmation />}
                      />
                      <Route
                        exact
                        path={ROUTING.ext.proFleetAssistConfirmation.path}
                        component={() => <ProFleetAssistConfirmation />}
                      />
                      <Route
                        exact
                        path={ROUTING.ext.paymentGuaranteeConfirmation.path}
                        component={() => <PaymentGuaranteeConfirmation />}
                      />
                      <Route
                        exact
                        path={ROUTING.ext.obligoConfirmation.path}
                        component={() => <ObligConfirmation />}
                      />
                      <Route component={Page404} />
                    </Switch>
                  </Router>
                </Fragment>
              )}
            </AppContext.Consumer>
          </WorkshopProvider>
        </FleetProvider>
        <Global__smallScreen />
      </ContentProvider>
    </AppProvider>
  );
}

export default App;
