export const handleLineClick_multiple = ({ line, array, callback }) => {
  const currentLine = array[line.key];
  const contentName = currentLine.serviceSlug;
  callback(contentName, currentLine.data);
};

export const handleLineClick_single = ({ line, array, callback }) => {
  const currentLine = array[line.key];
  callback({
    active: true,
    id: currentLine.service.id,
  });
};

export const handleLineClick_singleNew = ({ line, array, callback }) => {
  const currentLine = array[line.key];
  callback({
    active: true,
    id: currentLine.id,
  });
};

export const handleLineClick_open = ({ line, array, callback }) => {
  const currentLine = array[line.key];
  callback({
    active: true,
    id: currentLine.service.id,
  });
};
