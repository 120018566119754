
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import t from 'theme';
import Layout__container from 'containers/layout/container/layout__container';
import { Grid__separator } from 'components/grid/grid/';
import Button__primary from 'components/button/primary/button__primary';
import { useHistory } from 'react-router-dom';
import { ContentContext } from 'context/contentContext';
import Transition__loader_controller from 'components/transition/loader_controller/transition__loader_controller';


const SubTitle = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  opacity: 0.7;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const PageContent = styled.div`
  text-align: center;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.9;
  text-align: center;
`;

const Illu = styled.img`
  height: 380px;
`;

/**
 * @name Page404
 */

const Page404 = () => {
  const history = useHistory();
  const { getGlobal, loadGlobal } = useContext(ContentContext);

  useEffect(() => {
    loadGlobal();
  }, []);

  if (getGlobal({ option: 'page404', field: 'title' }) === '') {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container>
      <Transition__loader_controller active={false} />
      <Content>
        <PageContent>
          <Illu src={t.image.illu.err404} />
          <Grid__separator size="60px" />
          <Title>
            {getGlobal({ option: 'page404', field: 'title' })}
          </Title>
          <Grid__separator size="15px" />
          <SubTitle>
            {getGlobal({ option: 'page404', field: 'subTitle' })}
          </SubTitle>
          <Grid__separator size="25px" />
          <Button__primary event={() => history.push('/')}>
            {getGlobal({ option: 'page404', field: 'button' })}
          </Button__primary>
        </PageContent>
      </Content>
    </Layout__container>
  );
};

export default Page404;
