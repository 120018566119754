
import React, { useContext } from 'react';
import styled from 'styled-components';
import t from 'theme';
import Cards_serviceInfo from 'components/cards/serviceInfo/cards__serviceInfo';
import { Grid__separator } from 'components/grid/grid';
import Cards__counterSimple from 'components/cards/counterSimple/cards__counterSimple';
import Form from './Form';
import { ContentContext } from '../../../../../context/contentContext';

const Container = styled.div``;

const CardContainer = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-start' })}
`;

const Action = ({
  style, infoEvent, count, handleRefresh, config,
}) => {
  const { getField } = useContext(ContentContext);
  return (

    <Container style={style}>
      <Form
        title={getField({ base: config.strapiPage, field: config.content.dashboard_action_title })}
        content={getField({ base: config.strapiPage, field: config.content.dashboard_action_content })}
        buttonContent={getField({ base: config.strapiPage, field: config.content.dashboard_action_button })}
        serviceName={config.serviceName}
        handleRefresh={handleRefresh}
        config={config}
      />
      <Grid__separator size="30px" />
      <CardContainer>
        <Cards__counterSimple
          value={count}
          label={getField({ base: config.strapiPage, field: config.content.dashboard_counter_label })}
        />
        <Cards_serviceInfo
          small
          title={getField({ base: config.strapiPage, field: config.content.dashboard_discover_label })}
          event={infoEvent}
        />
      </CardContainer>
      <Grid__separator size="40px" />
    </Container>
  );
};

export default Action;
