
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';

import { getPercent } from 'util/math.utile';
import { strapiPaymentGuaranteeService } from 'util/strapi/content.starpi';
import Cards_serviceCoverage from 'components/cards/serviceCoverage/cards__serviceCoverage';
import Cards_serviceInfo from 'components/cards/serviceInfo/cards__serviceInfo';
import { Grid__separator } from 'components/grid/grid';
import Button__inline from 'components/button/inline/button__inline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import { formatPrice } from 'util/format/price.format';
import Cards__obligo from 'components/cards/obligo/cards__obligo';


const Container = styled.div``;

const DashboardPaymentGuarantee__action = ({
  style, infoEvent, settingsEvent, obligoEvent, coverage, fleet,
}) => {
  const { getGlobal, getField } = useContext(ContentContext);
  const { user } = useContext(AppContext);

  const renderObligo = () => {
    if (user.user.UserFleet.obligoRequest && user.user.UserFleet.obligoStatus === SERVICE_STATUS.done) {
      return true;
    }

    return false;
  };

  const renderObligoBalance = () => {
    if (user && user.user && user.user.UserFleet) {
      const used = user.user.UserFleet.obligoAvailable || 0;
      const total = user.user.UserFleet.obligoAmount;

      return total - used;
    }

    return 0;
  };
  return (
    <Container style={style}>
      <Cards_serviceCoverage
        paddingSmall
        button={
          <Button__inline event={settingsEvent} content={getGlobal({ option: 'service', field: 'service_settings_button' })} icon={<FontAwesomeIcon icon={faCog} />} />
        }
        to="./test"
        progress={fleet ? getPercent({ total: fleet, number: coverage }) : 0}
        value={coverage}
        labelProgress={getGlobal({ option: 'service', field: 'single_fleetcoverage_title' })}
        labelCounter={getGlobal({ option: 'service', field: 'single_fleetcoverage_vehiclelabel' })}
      />
      <Grid__separator size="20px" />
      <Cards__obligo
        value={formatPrice(renderObligoBalance() || 0)}
        label={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_cards_amount' })}
        obligoSet={renderObligo()}
        notSetLabel={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_cards_title' })}
        buttonEvent={obligoEvent}
        buttonText={getField({ base: strapiPaymentGuaranteeService, field: 'obligo_cards_button' })}
      />
      <Grid__separator size="20px" />
      <Cards_serviceInfo title={`${getGlobal({ option: 'service', field: 'single_info_title' })} ${getField({ base: strapiPaymentGuaranteeService, field: 'service_title' })}`} event={infoEvent} />
      <Grid__separator size="40px" />
    </Container>
  );
};

export default DashboardPaymentGuarantee__action;
