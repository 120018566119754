/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const Container = styled.a`
  width: 35px;
  height: 35px;
  background: #E6F8FC;
  border-radius: 50%;
  ${t.mixins.flex({ justify: 'center', align: 'center' })};
  color: #01B5E2;
  text-decoration: none;
  font-size: 25px;
  
  transition: 0.3s ease all;
  transform-origin: center;
  color: #01b5e2;
  cursor: pointer;
  &:hover {
    background-color: #01b5e2;
    color: #fff;
  }
`;

const Button__add = ({
  style, event, noprevent, className,
}) => {
  const eventHandler = (e) => {
    if (!noprevent) {
      e.preventDefault();
    }
    if (event) {
      event();
    }
  };
  return (
    <Container href="#" className={className} style={style} onClick={(e) => eventHandler(e)}>
      <FontAwesomeIcon icon={faPlus} />
    </Container>
  );
};

export default Button__add;
