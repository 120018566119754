
import React, { useContext, useEffect } from 'react';
import Nav from 'components/global/nav';
import { ContentContext } from 'context/contentContext';

/**
 * import containers & components
 */

import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Transition__loader_controller } from 'components/transition/transition';
import MemberCard__cardContainer from 'containers/fleetView/memberCard/cardContainer/memberCard__cardContainer';
import { strapiMemberCardsPage } from 'util/strapi/content.starpi';

/**
 * @name MemberCard
 */

const MemberCard = () => {
  const { loadPage, haveContentFor } = useContext(ContentContext);

  const loadContent = async () => {
    if (haveContentFor(strapiMemberCardsPage).success === false) {
      await loadPage(strapiMemberCardsPage);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (haveContentFor(strapiMemberCardsPage).success === false) {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container>
      <Nav active="membercard" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <Layout__content>
          <MemberCard__cardContainer basePage={strapiMemberCardsPage} />
        </Layout__content>
      </Layout__wrapper>
    </Layout__container>
  );
};

export default MemberCard;
