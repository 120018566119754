/* --------------------------- HOME BASE WORKSHOP --------------------------- */

export const getHomeBaseObjectFromVehicle = (vehicle) => {
  if (vehicle && vehicle.UserFleet && vehicle.UserFleet.UserWorkshop && vehicle.UserFleet.UserWorkshop.UserWorkshopAlias) {
    return {
      workshop: vehicle.UserFleet.UserWorkshop,
      user: vehicle.UserFleet.UserWorkshop.UserWorkshopAlias,
    };
  }

  return {
    workshop: {},
    user: {},
  };
};

export const getHomeBaseKeyFromVehicle = (vehicle, key) => {
  const homeBase = getHomeBaseObjectFromVehicle(vehicle);
  if (homeBase && homeBase.user && homeBase.user[key]) {
    return homeBase.user[key];
  }

  return false;
};

/* ------------------------------- FLEET USER ------------------------------- */
export const getFleetUserObjectFromVehicle = (vehicle) => {
  if (vehicle && vehicle.UserFleet && vehicle.UserFleet.UserFleetAlias) {
    return {
      fleet: vehicle.UserFleet,
      user: vehicle.UserFleet.UserFleetAlias,
    };
  }

  return {
    workshop: {},
    user: {},
  };
};

export const getFleetUserKeyFromVehicle = (vehicle, key) => {
  const fleetUser = getFleetUserObjectFromVehicle(vehicle);
  if (fleetUser && fleetUser.user && fleetUser.user[key]) {
    return fleetUser.user[key];
  }

  return false;
};

/* -------------------------- IS VEHICLE HOME BASE -------------------------- */

export const isVehicleHomeBase = (vehicle, user) => {
  const homeBaseId = getHomeBaseKeyFromVehicle(vehicle, 'id');
  const userId = user.user.id;
  if (homeBaseId === userId) {
    return true;
  }

  return false;
};
/* -------------------------- IS VEHICLE HOME BASE -------------------------- */

export const isVehicleApprover = (vehicle, user) => {
  const userfleet = getFleetUserObjectFromVehicle(vehicle);
  const userId = user.user.id;
  if (userfleet && userfleet.fleet && userfleet.fleet.repairApprovalUserId && userfleet.fleet.repairApprovalUserId === userId) {
    return true;
  }

  return false;
};
