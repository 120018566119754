
/**
   * Import Lib & assets
   */

import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import Button__buttonCircle from 'components/button/buttonCircle/button__buttonCircle';
import Content__labelText from 'components/content/labelText/content__labelText';
import { Grid__separator } from 'components/grid/grid/';
import {
  faPhone,
  faEnvelope,
  faUserShield,
  faGasPump,
  faSatelliteDish,
  faTirePressureWarning,
} from '@fortawesome/pro-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'theme';
import { checkRate } from 'util/conditions/rate.condition';
import { ContentContext } from 'context/contentContext';
import {
  strapiProFleetAssistService,
} from 'util/strapi/content.starpi';
import { config as smartMaintenanceConfig } from 'util/constant/leadGeneration/smartMaintenance.config';
import { config as alertGasoilConfig } from 'util/constant/leadGeneration/alertGasoil.config';
import { config as proFleetAssistConfig } from 'util/constant/leadGeneration/proFleetAssist.config';
import { config as tpmsConfig } from 'util/constant/leadGeneration/tpms.config';

const Container = styled.div`
  height: 100%;
  width: 550px;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 100;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: .3s ease-in transform;
  transform: ${(props) => (props.open ? 'translateX(0px)' : 'translateX(-100%)')};
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
  &::-webkit-scrollbar{
    width: 6px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #888;
    }
    &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 40px 34px 40px 40px;
`;

const Image = styled.div`
  height: 145px;
  width: 100%;
  border-radius: 10px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${(props) => (props.image ? props.image : null)});
`;

const Title = styled.div`
  font-size: 25px;
  color: #000000;
  line-height: 1.3;
`;

const Address = styled.div`
  width: 100%;
  line-height: 1.5;
`;

const Label = styled.span`
  min-width: 110px;
  font-size: 15px;
  font-weight: 500;
  color: #4D4D4D;
  display: table-cell;
  border-right: 15px solid transparent;
  background-clip: padding-box;
`;

const Value = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: #4D4D4D;
  display: table-cell;
`;

const Button = styled.span`
  display: flex;
`;

const SubTitleContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const CloseButton = styled.a`
  background: #01B5E2;
  position: absolute;
  top: 30px;
  right: -24px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 40px;
  width: 24px;
  display: block;
  display: ${(props) => (props.buttonOpen ? 'block' : 'none')};
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: #FFFFFF;
  position: absolute;
  top: 12px;
  left: 7px;
  transform: scaleX(${(props) => (props.open ? null : -1)});
`;

const ServiceIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  font-size: 12px;
`;

const Table = styled.div`
  display: table;
`;

const Table_row = styled.div`
  display: table-row;
`;


const Cards__mapCard = ({
  style,
  open,
  title,
  addressLine1,
  addressLine2,
  addressLine3,
  hours,
  email,
  contact,
  phone,
  hourlyRate1,
  hourlyRate2,
  hourlyRate3,
  event,
  buttonOpen,
  workshopinfo,
}) => {
  const { getGlobal, getField } = useContext(ContentContext);
  const eventHandler = () => {
    if (event) {
      event();
    }
  };

  const renderDay = (item) => {
    let morning = false;
    let afternoon = false;
    if (item.amStart && item.amEnd) {
      morning = `${item.amStart} - ${item.amEnd}`;
    }
    if (item.pmStart && item.pmEnd) {
      afternoon = `${item.pmStart} - ${item.pmEnd}`;
    }

    if (item.closed.pm === true && item.closed.am === true) {
      return getGlobal({ option: 'workshopInformation', field: 'closed_label' });
    }
    if (morning && afternoon) {
      return `${morning} / ${afternoon}`;
    }
    if (morning) {
      return `${morning}`;
    }
    return `${afternoon}`;
  };

  const renderHours = () => {
    if (hours) {
      return hours.map((item, key) => (
        <Fragment key={key}>
          <div key={key}>
            <Label>{item.day}</Label>
            <Value>
              {renderDay(item)}
            </Value>
          </div>
          <Grid__separator size="10px" />
        </Fragment>
      ));
    }
    return null;
  };


  const renderServices = (config, last) => {
    if (!workshopinfo || !workshopinfo.UserWorkshop) return null;
    if (config.check.haveServicesWorkshop({ user: workshopinfo })) {
      return (
        <>
          <Table_row>
            <Value>
              <ServiceIcon icon={config.details.iconSolid} />
              {getField({ base: config.strapiPage, field: config.content.service_title })}
            </Value>
          </Table_row>
          {!last && <Grid__separator size="10px" />}
        </>
      );
    }

    return null;
  };

  return (
    <Container style={style} open={open}>
      <CloseButton href="#" onClick={eventHandler} buttonOpen={buttonOpen}>
        <Icon icon={faChevronLeft} open={open} />
      </CloseButton>
      <Content>
        <Wrapper>
          <Image image={t.image.global.workshopImage} />
          <Grid__separator size="30px" />
          <Title>{title}</Title>
          <SubTitleContainer>
            <Address>
              {addressLine1}
              <br />
              {addressLine2}
              <br />
              {addressLine3}
            </Address>
            <Button>
              <Button__buttonCircle
                icon={<FontAwesomeIcon icon={faPhone} />}
                href={`tel:${phone}`}
                noprevent
              />
              <Grid__separator width="15px" />
              <Button__buttonCircle
                icon={<FontAwesomeIcon icon={faEnvelope} />}
                href={`mailto:${email}`}
                noprevent
                target="_blank"
              />
            </Button>
          </SubTitleContainer>
          <Content__labelText
            label={getGlobal({ option: 'workshopInformation', field: 'opening_hours' })}
            content={(
              <div>
                {renderHours()}
              </div>
            )}
          />
          <Grid__separator size="40px" />
          <Content__labelText
            label={getGlobal({ option: 'workshopInformation', field: 'fleet_contact' })}
            content={contact}
          />
          <Grid__separator size="40px" />
          <Content__labelText
            label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })}
            content={phone}
          />
          <Grid__separator size="40px" />
          <Content__labelText
            label={getGlobal({ option: 'workshopInformation', field: 'email' })}
            content={email}
          />
          <Grid__separator size="40px" />
          <Content__labelText
            label={getGlobal({ option: 'workshopInformation', field: 'hourly_rate' })}
            content={(
              <Table>
                {checkRate(hourlyRate1)
                  && (
                    <Table_row>
                      <Label>{getGlobal({ option: 'workshopInformation', field: 'rate_1' })}</Label>
                      <Value>
                        {checkRate(hourlyRate1)}
                        {' '}
                        {getGlobal({ option: 'workshopInformation', field: 'rate_currency' })}
                      </Value>
                    </Table_row>
                  )}
                <Grid__separator size="10px" />
                {checkRate(hourlyRate2)
                  && (
                    <Table_row>
                      <Label>{getGlobal({ option: 'workshopInformation', field: 'rate_2' })}</Label>
                      <Value>
                        {checkRate(hourlyRate2)}
                        {' '}
                        {getGlobal({ option: 'workshopInformation', field: 'rate_currency' })}
                      </Value>
                    </Table_row>
                  )}
                <Grid__separator size="10px" />
                {checkRate(hourlyRate3)
                  && (
                    <Table_row>
                      <Label>{getGlobal({ option: 'workshopInformation', field: 'rate_3' })}</Label>
                      <Value>
                        {checkRate(hourlyRate3)}
                        {' '}
                        {getGlobal({ option: 'workshopInformation', field: 'rate_currency' })}
                      </Value>
                    </Table_row>
                  )}
              </Table>
            )}
          />
          <Grid__separator size="40px" />
          <Content__labelText
            label="Installation services"
            content={(
              <Table>
                {renderServices(proFleetAssistConfig)}
                {renderServices(alertGasoilConfig)}
                {renderServices(tpmsConfig)}
                {renderServices(smartMaintenanceConfig, true)}
              </Table>
            )}
          />
        </Wrapper>
      </Content>
    </Container>
  );
};

export default Cards__mapCard;
