/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 11px;
  border: 1px solid #01b5e2;
  border-radius: 20px;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #01b5e2;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 150px;
  text-decoration: none;
  background-color: transparent;
  transition: 0.3s ease all;
  &:hover {
    background-color: #01b5e2;
    color: #fff;
  }
`;

const Button__secondary = ({ style, content, href }) => (
  <Container href={href} style={style}>
    {content}
  </Container>
);

export default Button__secondary;
