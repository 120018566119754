
import React from 'react';
import Confirmation from '../../components/services/LeadGeneration/confirmation';
import { config } from '../../util/constant/leadGeneration/smartMaintenance.config';


/**
 * @name SmartMaintenanceConfirmation
 */

const SmartMaintenanceConfirmation = () => (
  <Confirmation config={config} />
);

export default SmartMaintenanceConfirmation;
