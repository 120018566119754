
/**
   * Import Lib
   */

import React, {
  useState, useContext, Fragment, useEffect,
} from 'react';
import { ContentContext } from 'context/contentContext';
import { strapiRegister } from 'util/strapi/content.starpi';
import { dialJsonToSelect } from 'util/function/dialJsonToSelect.function';
import styled from 'styled-components';
import { Grid__card } from 'components/grid/grid/';
import { Content__text } from 'components/content/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWarehouse, faUser, faTruck,
} from '@fortawesome/pro-solid-svg-icons';
import {
  Form__textfield, Form__label, Form__select, Form__combined,
} from 'components/form/form';
import { slugify } from 'helpers/js/Utile';
import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import { Grid__separator } from 'components/grid/grid';
import Button__primary from 'components/button/primary/button__primary';
import { createNewArray } from 'helpers/js/Form';
import { hasFieldError } from 'util/conditions/haveErrorField.conditions';
import { getNavigatorLang } from 'util/function/getNavigatorLang.function';
import { registerUser } from 'helpers/api/routes/User';
import { formatApiRes } from 'util/function/formatApiRes.function';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { SendMail } from 'helpers/api/routes/Mail';
import Form__map from 'components/form/map/form__map';
import Form__checkCondition from 'components/form/checkCondition/form__checkCondition';
import { ROUTING } from 'util/constant/routing.constant';
import { createErrorObject } from 'util/conditions/checkField.condition';
import 'react-intl-tel-input/dist/main.css';
import { getParams } from 'util/get.util';


const RowField = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  justify-content: flex-start;
`;

const Container = styled(Grid__card)`
  width: 500px;
  padding: 50px;
`;

const TitleForm = styled(Content__text)`
  text-align: center;
  width: 100%;
  margin-bottom: 45px;
  font-weight: 600;
  font-size: 30px;
  line-height: auto;
  text-align: center;
  color: #1a1a1a;
`;

const ValidateBtn = styled(Button__primary)`
  text-align: center;
  width: 100%;
`;

const ErrorText = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #E74C3C;
`;

const Register__form = ({ style, setmodal }) => {
  const { getFieldWithNavigatorLang, getGlobalWithNavigatorLang } = useContext(ContentContext);

  const [genderSelector] = useState([
    {
      label: getGlobalWithNavigatorLang({ option: 'gender', field: 'mr' }),
      slug: 'mr',
      active: true,
    },
    {
      label: getGlobalWithNavigatorLang({ option: 'gender', field: 'mrs' }),
      slug: 'mrs',
      active: false,
    },
  ]);

  const mainRouteSelector = [
    {
      label: getFieldWithNavigatorLang({ base: strapiRegister, field: 'main_route_option1' }),
      slug: slugify(getFieldWithNavigatorLang({ base: strapiRegister, field: 'main_route_option1' })),
      active: true,
    },
    {
      label: getFieldWithNavigatorLang({ base: strapiRegister, field: 'main_route_option2' }),
      slug: slugify(getFieldWithNavigatorLang({ base: strapiRegister, field: 'main_route_option2' })),
      active: false,
    },
    {
      label: getFieldWithNavigatorLang({ base: strapiRegister, field: 'main_route_option3' }),
      slug: slugify(getFieldWithNavigatorLang({ base: strapiRegister, field: 'main_route_option3' })),
      active: false,
    },
  ];

  const serviceSelector = [
    {
      label: getFieldWithNavigatorLang({ base: strapiRegister, field: 'service_option_1' }),
      slug: slugify(getFieldWithNavigatorLang({ base: strapiRegister, field: 'service_option_1' })),
      active: true,
    },
    {
      label: getFieldWithNavigatorLang({ base: strapiRegister, field: 'service_option_2' }),
      slug: slugify(getFieldWithNavigatorLang({ base: strapiRegister, field: 'service_option_2' })),
      active: false,
    },
    {
      label: getFieldWithNavigatorLang({ base: strapiRegister, field: 'service_option_3' }),
      slug: slugify(getFieldWithNavigatorLang({ base: strapiRegister, field: 'service_option_3' })),
      active: false,
    },
    {
      label: getFieldWithNavigatorLang({ base: strapiRegister, field: 'service_option_4' }),
      slug: slugify(getFieldWithNavigatorLang({ base: strapiRegister, field: 'service_option_4' })),
      active: false,
    },
  ];

  const [phoneDialSelector, setphoneDialSelector] = useState([]);
  const [typeOfAccount, settypeOfAccount] = useState(1);
  const [error, seterror] = useState({});
  const [loading, setloading] = useState(false);
  const [alreadyHaveUser, setalreadyHaveUser] = useState(false);

  const [state, setState] = useState({
    gdpr: false,
    name: { value: '', result: '' },
    lastname: { value: '', result: '' },
    mail: { value: '', result: '' },
    tel: { value: '', result: '' },
    company: { value: '', result: '' },
    address: { value: '', result: '' },
    vat: { value: '', result: '' },
    trucks: { value: '', result: '' },
    trailer: { value: '', result: '' },
    bus: { value: '', result: '' },
    lcv: { value: '', result: '' },
    route: { value: createNewArray({ base: mainRouteSelector }), result: slugify(getFieldWithNavigatorLang({ base: strapiRegister, field: 'main_route_option1' })) },
    service: { value: createNewArray({ base: serviceSelector }), result: slugify(getFieldWithNavigatorLang({ base: strapiRegister, field: 'service_option_1' })) },
    gender: { value: createNewArray({ base: genderSelector }), result: 'mr' },
    phoneDial: { value: createNewArray({ base: phoneDialSelector }), result: '+33' },
  });

  const DisableAutoFill = () => {
    const inputMap = document.querySelector('.google-places-autocomplete__input');
    inputMap.setAttribute('autocomplete', 'chrome-off');
  };

  const setDialJson = () => {
    const obj = dialJsonToSelect();

    setState((prevState) => ({
      ...prevState,
      phoneDial: { value: obj, result: obj[0].slug },
    }));
    setphoneDialSelector(obj);
  };
  const getParamsData = () => {
    const params = {
      firstName: getParams('firstname'),
      lastName: getParams('lastname'),
      mail: getParams('mail'),
    };
    const obj = {};
    if (params && params.mail) {
      obj.mail = { value: params.mail, result: params.mail };
    }
    if (params && params.firstName) {
      obj.name = { value: params.firstName, result: params.firstName };
    }
    if (params && params.lastName) {
      obj.lastname = { value: params.lastName, result: params.lastName };
    }

    setState((prevState) => ({
      ...prevState,
      ...obj,
    }));
  };

  useEffect(() => {
    setDialJson();
    DisableAutoFill();
    getParamsData();
  }, []);

  const handleStateChange = ({
    property, value, result,
  }) => {
    setState((prevState) => ({
      ...prevState,
      [property]: { value, result },
    }));
  };

  const handleMapField = ({
    property, formated, place, coord,
  }) => {
    setState((prevState) => ({
      ...prevState,
      [property]: {
        value: place.description || '',
        result: formated,
        coords: coord,
      },
    }));
  };

  const handlegdpr = (value) => {
    setState((prevState) => ({
      ...prevState,
      gdpr: value.value,
    }));
  };

  const checkField = () => {
    let errorObj = {};
    let errorArray = {};

    const baseArrayField = [
      {
        key: 'mail',
        type: 'email',
        value: state.mail.result,
      },
      {
        key: 'gender',
        type: 'simple',
        value: state.gender.result,
      },
      {
        key: 'name',
        type: 'name',
        value: state.name.result,
      },
      {
        key: 'lastName',
        type: 'name',
        value: state.lastname.result,
      },
      {
        key: 'tel',
        type: 'phoneLib',
        value: state.tel.result,
      },
      {
        key: 'company',
        type: 'simple',
        value: state.company.result,
      },
      {
        key: 'address',
        type: 'simple',
        value: state.address.result,
      },
      {
        key: 'vat',
        type: 'simple',
        value: state.vat.result,
      },
      {
        key: 'gdpr',
        type: 'boolean',
        value: state.gdpr,
      },
    ];

    errorObj = createErrorObject(errorArray, baseArrayField, errorObj.hasError, getGlobalWithNavigatorLang);
    errorArray = errorObj.object;
    if (typeOfAccount === 1) {
      const fleetArrayField = [
        {
          key: 'trucks',
          type: 'number',
          value: state.trucks.result,
        },
        {
          key: 'trailer',
          type: 'number',
          value: state.trailer.result,
        },
        {
          key: 'bus',
          type: 'number',
          value: state.bus.result,
        },
        {
          key: 'lcv',
          type: 'number',
          value: state.lcv.result,
        },
        {
          key: 'route',
          type: 'simple',
          value: state.route.result,
        },
        {
          key: 'service',
          type: 'simple',
          value: state.service.result,
        },
      ];
      errorObj = createErrorObject(errorArray, fleetArrayField, errorObj.hasError, getGlobalWithNavigatorLang);
      errorArray = errorObj.object;
    }
    seterror(errorArray);
    return errorObj.hasError;
  };

  const resetState = () => {
    setState({
      gdpr: false,
      name: { value: '', result: '' },
      lastname: { value: '', result: '' },
      mail: { value: '', result: '' },
      tel: { value: '', result: '' },
      company: { value: '', result: '' },
      address: { value: '', result: '' },
      vat: { value: '', result: '' },
      trucks: { value: '', result: '' },
      trailer: { value: '', result: '' },
      bus: { value: '', result: '' },
      lcv: { value: '', result: '' },
      route: { value: createNewArray({ base: mainRouteSelector }), result: slugify(getFieldWithNavigatorLang({ base: strapiRegister, field: 'main_route_option1' })) },
      service: { value: createNewArray({ base: serviceSelector }), result: slugify(getFieldWithNavigatorLang({ base: strapiRegister, field: 'service_option_1' })) },
      gender: { value: createNewArray({ base: genderSelector }), result: 'mr' },
      phoneDial: { value: createNewArray({ base: phoneDialSelector }), result: '+33' },
    });
  };

  const createApiObj = () => {
    const addressField = state.address.result;
    const { coords } = state.address;
    const region = addressField ? addressField.country || '' : '';
    const regionCode = addressField ? addressField.countryCode || '' : '';
    const city = addressField ? addressField.city || '' : '';
    const zipcode = addressField ? addressField.zipCode || '' : '';
    const adress = addressField ? addressField.description || `${addressField.number || ''} ${addressField.address || ''}` || '' : '';
    const fleetObj = {
      firstName: state.name.result,
      lastName: state.lastname.result,
      gender: state.gender.result,
      email: state.mail.result.toLowerCase(),
      tel: state.tel.result.value,
      phoneDial: '',
      region: region ? region.toLowerCase() : '',
      countryCode: regionCode ? regionCode.toLowerCase() : '',
      lat: coords ? coords.lat : '',
      lng: coords ? coords.lng : '',
      companyName: state.company.result,
      adress,
      city,
      zipcode,
      gdpr: state.gdpr,
      vat: state.vat.result,
      lang: getNavigatorLang().lang,
      numberOfTruck: state.trucks.result,
      numberOfBus: state.bus.result,
      numberOfLCV: state.lcv.result,
      servicesExpected: state.service.result,
      mainRoutes: state.route.result,
      legalMention: true,
      numberOfTrailer: state.trailer.result,
      typeOfUser: 'fleet',
    };

    const workshopObj = {
      firstName: state.name.result,
      lastName: state.lastname.result,
      gender: state.gender.result,
      email: state.mail.result,
      tel: state.tel.result.value,
      phoneDial: '',
      companyName: state.company.result,
      gdpr: state.gdpr,
      region: region ? region.toLowerCase() : '',
      countryCode: regionCode ? regionCode.toLowerCase() : '',
      lat: coords ? coords.lat : '',
      lng: coords ? coords.lng : '',
      adress,
      city,
      zipcode,
      vat: state.vat.result,
      lang: getNavigatorLang().lang,
      legalMention: true,
      typeOfUser: 'workshop',
    };

    return typeOfAccount === 1 ? fleetObj : workshopObj;
  };

  const handleSubmit = async () => {
    if (!checkField()) {
      setloading(true);
      const field = createApiObj();

      const res = await registerUser({ field });
      const formatedRes = formatApiRes(res);

      if (res) {
        setloading(false);
      }

      if (formatedRes.success === true) {
        SendMail({
          template: 'admin',
          templateNotification: {
            status: 'action',
            statusLabel: 'Action needed',
            content: 'New user registration, see admin panel to validate user',
            btnContent: 'See user',
            btnLink: 'https://prod-alltrucks-fleet-admin.herokuapp.com/user-fleet',
          },
          subject: 'Admin Notification : Action Needed',
        });
        resetState();
        setmodal(true);
      } else if (formatedRes.data === 'user already exist') {
        setalreadyHaveUser(true);
      }
    }
  };

  return (
    <Container style={style}>
      <TitleForm type="h1">{getFieldWithNavigatorLang({ base: strapiRegister, field: 'form_title' })}</TitleForm>
      <Content__sectionTitle
        title={getFieldWithNavigatorLang({ base: strapiRegister, field: 'contact_title' })}
        icon={<FontAwesomeIcon icon={faUser} />}
      />
      <Form__textfield
        label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'mail_label' })}
        require
        value={state.mail.value}
        change={(res) => handleStateChange({
          property: 'mail',
          value: res.value,
          result: res.value,
          type: 'text',
        })}
        error={hasFieldError(error, 'mail')}
      />
      <Grid__separator size="25px" />
      <Form__textfield
        require
        style={{ width: '100%' }}
        change={(res) => handleStateChange({
          property: 'name',
          value: res.value,
          result: res.value,
          type: 'text',
        })}
        value={state.name.value}
        label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'firstname_label' })}
        property="name"
        error={hasFieldError(error, 'name')}
      />
      <Grid__separator size="25px" />
      <Form__label require>{getFieldWithNavigatorLang({ base: strapiRegister, field: 'lastname_label' })}</Form__label>
      <RowField>
        <Form__select
          value={state.gender.value}
          style={{ width: 140, maxWidth: '100%', marginRight: 10 }}
          event={(res) => handleStateChange({
            property: 'gender',
            value: res.value,
            result: res.result.slug,
            type: 'select',
          })}
          error={hasFieldError(error, 'gender')}
        />
        <Form__textfield
          require
          value={state.lastname.value}
          change={(res) => handleStateChange({
            property: 'lastname',
            value: res.value,
            result: res.value,
            type: 'text',
          })}
          error={hasFieldError(error, 'lastName')}
        />

      </RowField>
      <Grid__separator size="25px" />
      <Form__combined
        handleTextChange={(isValide, res, final) => handleStateChange({
          property: 'tel',
          value: { value: final, valide: isValide },
          result: { value: final, valide: isValide },
          type: 'tel',
        })}
        textValue={state.tel.value}
        property="tel"
        label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'phone_label' })}
        require
        defaultCountry={getNavigatorLang().lang}
        error={hasFieldError(error, 'tel')}
      />
      <Grid__separator size="65px" />
      <Content__sectionTitle
        title={getFieldWithNavigatorLang({ base: strapiRegister, field: 'company_title' })}
        icon={<FontAwesomeIcon icon={faWarehouse} />}
      />
      <Form__textfield
        label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'company_label' })}
        require
        value={state.company.value}
        change={(res) => handleStateChange({
          property: 'company',
          value: res.value,
          result: res.value,
          type: 'text',
        })}
        error={hasFieldError(error, 'company')}
      />
      <Grid__separator size="25px" />
      <Form__map
        event={({ formated, place, coord }) => handleMapField({
          property: 'address',
          formated,
          place,
          type: 'text',
          coord,
        })}
        value={state.address.value || null}
        property="address"
        label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'address_label' })}
        style={{ maxWidth: '100%' }}
        error={hasFieldError(error, 'address')}
        require
      />
      <Grid__separator size="25px" />
      <Form__textfield
        label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'vat_label' })}
        require
        value={state.vat.value}
        change={(res) => handleStateChange({
          property: 'vat',
          value: res.value,
          result: res.value,
          type: 'text',
        })}
        error={hasFieldError(error, 'vat')}
      />
      {
        typeOfAccount === 1 && (
          <Fragment>
            <Grid__separator size="65px" />
            <Content__sectionTitle
              title={getFieldWithNavigatorLang({ base: strapiRegister, field: 'fleet_information_label' })}
              icon={<FontAwesomeIcon icon={faTruck} />}
            />
            <Form__textfield
              label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'number_of_trucks_label' })}
              require
              value={state.trucks.value}
              change={(res) => handleStateChange({
                property: 'trucks',
                value: res.value,
                result: res.value,
                type: 'text',
              })}
              type="text"
              error={hasFieldError(error, 'trucks')}
            />
            <Grid__separator size="25px" />
            <Form__textfield
              label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'number_of_trailer_label' })}
              require
              value={state.trailer.value}
              change={(res) => handleStateChange({
                property: 'trailer',
                value: res.value,
                result: res.value,
                type: 'text',
              })}
              type="text"
              error={hasFieldError(error, 'trailer')}
            />
            <Grid__separator size="25px" />
            <Form__textfield
              label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'number_of_bus_label' })}
              require
              value={state.bus.value}
              change={(res) => handleStateChange({
                property: 'bus',
                value: res.value,
                result: res.value,
                type: 'text',
              })}
              type="text"
              error={hasFieldError(error, 'bus')}
            />
            <Grid__separator size="25px" />
            <Form__textfield
              label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'number_of_lcv_label' })}
              require
              value={state.lcv.value}
              change={(res) => handleStateChange({
                property: 'lcv',
                value: res.value,
                result: res.value,
                type: 'text',
              })}
              type="text"
              error={hasFieldError(error, 'lcv')}
            />
            <Grid__separator size="25px" />
            <Form__select
              label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'main_route_label' })}
              value={state.route.value}
              require
              event={(res) => handleStateChange({
                property: 'route',
                value: res.value,
                result: res.result.slug,
                type: 'select',
              })}
              error={hasFieldError(error, 'route')}
            />
            <Grid__separator size="25px" />
            <Form__select
              label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'service_label' })}
              value={state.service.value}
              require
              event={(res) => handleStateChange({
                property: 'service',
                value: res.value,
                result: res.result.slug,
                type: 'select',
              })}
              error={hasFieldError(error, 'service')}
            />
          </Fragment>
        )
      }
      <Grid__separator size="45px" />
      <Form__checkCondition
        value={state.gdpr}
        property="gdpr"
        content={(
          <span>
            {getFieldWithNavigatorLang({ base: strapiRegister, field: 'gdpr_label_1' })}
            {' '}
            <a target="_blank" rel="noopener noreferrer" href={ROUTING.fleet.gdpr.path}>{getFieldWithNavigatorLang({ base: strapiRegister, field: 'gdpr_label_2' })}</a>
          </span>
        )}
        error={hasFieldError(error, 'gdpr')}
        callback={handlegdpr}
      />
      <Grid__separator size="25px" />
      <ValidateBtn event={handleSubmit}>{getFieldWithNavigatorLang({ base: strapiRegister, field: 'submit_button' })}</ValidateBtn>
      {
        alreadyHaveUser && (
          <Fragment>
            <Grid__separator size="25px" />
            <ErrorText>{getFieldWithNavigatorLang({ base: strapiRegister, field: 'error_email_exist' })}</ErrorText>
          </Fragment>
        )
      }
      <Grid__separator size="15px" />
      {loading ? <Loader__wrapper /> : false}
    </Container>
  );
};

export default Register__form;
