/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

const Content = styled.span`
  font-weight: 600;
  margin-left: ${(props) => (props.icon && props.bigIcon ? '8px' : props.icon ? '6px' : null)};
`;

const Icon = styled.span`
  font-size: ${(props) => (props.bigIcon ? '20px' : null)};
`;

const Container = styled.a`
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => (props.red ? '#FF5C5C' : '#01b5e2')};
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  display: inline-flex;
  align-items: center;
  &::after {
    content: '';
    transition: .3s ease all;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 3px;
    left: 0;
    right: 0;
    background-color: ${(props) => (props.red ? '#FF5C5C' : '#01b5e2')};
    border-radius: 1px;
    transform-origin: left;
    transform: scaleX(0);
  }
  &:hover::after {
    transform: ${(props) => (props.disable ? null : 'scaleX(1)')};;
  }
`;

const Button__inline = ({
  style, icon, content, className, href, download, event, noprevent, red, bigIcon, disable,
}) => {
  const eventHandler = (e) => {
    if (event) {
      if (!noprevent) {
        e.preventDefault();
      }
      if (event) {
        event();
      }
    }
  };
  return (
    <Container
      style={style}
      onClick={eventHandler}
      className={className}
      href={href}
      download={download}
      red={red}
      bigIcon={bigIcon}
      disable={disable}
    >
      <Icon bigIcon={bigIcon}>{icon}</Icon>
      <Content bigIcon={bigIcon} icon={icon}>{content}</Content>
    </Container>
  );
};

export default Button__inline;
