/* eslint-disable no-mixed-operators */
export const coords = (elem, container) => {
  const box = elem.getBoundingClientRect();

  const { body } = document;
  const docEl = document.documentElement;

  const scrollTop = container ? container.scrollTop : window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
};

const easeInOutQuad = (tP, bP, cP, dP) => {
  let t = tP;
  const b = bP;
  const c = cP;
  const d = dP;

  t /= d / 2;
  if (t < 1) return c / 2 * t * t + b;
  t -= 1;
  return -c / 2 * (t * (t - 2) - 1) + b;
};

const smoothScroll = (dur, section, container) => {
  const page = container || window;


  // init variable

  const to = section;
  const duration = dur || 300;
  const start = page.scrollTop || page.pageYOffset;
  const change = to - start;
  let currentTime = 0;
  const increment = 20;


  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    page.scrollTo(0, val);

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

export const autoScroll = (section) => {
  if (document.querySelector('.inPopUp')) {
    const popUp = document.querySelector('.inPopUp');
    const top = coords(section, popUp).top || 0;
    smoothScroll(300, top - 50, popUp);
  } else {
    const top = coords(section).top || 0;
    smoothScroll(300, top - 50);
  }
};
