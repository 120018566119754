
/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';
import './transition__loader.scss';

/**
 * import components
 */


/**
 * styled components
 */

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: ${t.color.dark};
  ${t.mixins.flex({ direction: 'column' })}
`;

const Image = styled.img`
  width: 150px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const Loader__container = styled.div`
  position: relative;
  height: 200px;
  width: 200px;
`;

/**
 * mover component is..
 */

const Transition__mover = ({ style }) => (
  <Container className="transition__loader" style={style}>
    <Loader__container>
      <Image src={t.image.global.logo} />
      <Loader className="loader-1 center">
        <span />
      </Loader>
    </Loader__container>
  </Container>
);

export default Transition__mover;
