
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import t from 'theme';

import { slugify } from 'helpers/js/Utile';
import { FleetContext } from 'context/fleetUser/fleetContext';
import { ContentContext } from 'context/contentContext';
import Button__closeButton from 'components/button/closeButton/button__closeButton';
import Button__add from 'components/button/add/button__add';
import moment from 'moment';
import { AppContext } from 'context/appContext';
import Button__primary from 'components/button/primary/button__primary';
import { Grid__popup } from 'components/grid/grid';
import { Content__text } from 'components/content/content';
import { Form__textfield, Form__select, Form__date } from 'components/form/form';
import Content__counterInfo from 'components/content/counterInfo/content__counterInfo';
import { List__header } from 'components/list/list';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { createNewArray } from 'helpers/js/Form';
import { getUserFleetInformation } from 'util/service/getDataFromService';
import Message__sucess from 'components/message/sucess/message__sucess';
import { createErrorObject } from 'util/conditions/checkField.condition';
import { hasFieldError, hasFieldErroArray } from 'util/conditions/haveErrorField.conditions';
import { checkVin } from 'helpers/api/routes/Fleets';
import { formatApiRes } from 'util/function/formatApiRes.function';
/**
 * styled components
 */

const Container = styled.div``;

const UploadPop = styled(Grid__popup)`
  width: 80%;
  padding: 40px;
  position: relative;
  max-width: 1400px;
`;

const Button = styled(Button__primary)`
  min-width: 170px;
  text-align: center;
`;

const UploadPopHeader = styled.div`
  width: 100%;
  text-align: center;
`;

const CounterContainer = styled.div`
  margin-top: 35px;
  margin-bottom: 25px;
  ${t.mixins.flex({ justify: 'center' })}
`;

const ButtonContainer = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'center' })}
  margin-top: 15px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ContainerTable = styled.table`
  min-width: 100%;
  border: 0;
  border-collapse: collapse;
  table-layout: fixed;
  display: table;
  border-spacing: 2px;
  max-width: 100%;
  border-color: grey;
`;

const ContainerLine = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
  tr:last-child {
    border-bottom: none;
  }
`;

const Line = styled.tr`
  display: table-row;
  position: relative;
  vertical-align: inherit;
  border-color: inherit;
  border-radius: 5px;
  cursor: ${({ hasEvent }) => (hasEvent ? 'pointer' : 'initial')};
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  &:hover {
    background-color: ${({ hasEvent }) => (hasEvent ? '#E6F8FC' : 'transparent')};
    transition: background ease 0.5s;
  }
`;

const ColItem = styled.td`
  display: table-cell;
  vertical-align: top;
  padding-right: ${(props) => (!props.end ? '5px' : '0px')};
  padding-left: 5px;
  text-align: left;
  line-break: inherit;
  word-wrap: break-word;
  width: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
  & input {
    padding: 0 10px;
    padding-left: 10px;
  }
`;

const LastColContainer = styled.div`
  padding-right: 36px;
  position: relative;
`;

const DeleteLine = styled(Button__closeButton)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  font-size: 17px;
`;

const ButtonAddContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Add = styled(Button__add)`
  width: 25px;
  height: 25px;
  font-size: 17px;
`;


const ErrorText = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #E74C3C;
  margin-bottom: 10px;
`;

const initialState = {
  Registration: '',
  Country: [],
  Vin: '',
  Cat: [],
  Brand: '',
  First: '',
  Mileage: '',
};

/**
 * upload component is..
 */

const Fleet__upload = ({
  style, uploadActive,
  closeEvent, basePage,
}) => {
  const { getGlobal, getGlobalObj, getField } = useContext(ContentContext);
  const { AddVehicule } = useContext(FleetContext);
  const { getUser, user } = useContext(AppContext);
  const [countrySelect, setCountrySelect] = useState([]);
  const [typeSelect, setTypeSelect] = useState([]);
  const [error, setError] = useState([{}]);
  const [line, setLine] = useState([initialState]);
  const [loading, setLoading] = useState(false);
  const [successAdd, setsuccessAdd] = useState(false);
  const headerCol = [
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'vincode' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'category' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'brand' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'mileage' }),
    },
  ];

  useEffect(() => {
    const keyObject = Object.keys(getGlobalObj({ option: 'country' }));
    const keyObjectType = Object.keys(getGlobalObj({ option: 'vehicletype' }));
    const selectArray = [];
    const selectTypeArray = [];
    const temp = [...line];
    keyObject.forEach((element) => {
      const obj = {
        label: getGlobal({ option: 'country', field: element }),
        slug: slugify(element),
        active: false,
      };

      selectArray.push(obj);
    });

    keyObjectType.forEach((element) => {
      const obj = {
        label: getGlobal({ option: 'vehicletype', field: element }),
        slug: slugify(element),
        active: false,
      };

      selectTypeArray.push(obj);
    });

    selectArray[0].active = true;
    selectTypeArray[0].active = true;
    temp[0].Country = createNewArray({ base: selectArray });
    temp[0].Cat = createNewArray({ base: selectTypeArray });
    setCountrySelect(selectArray);
    setTypeSelect(selectTypeArray);
    setLine(temp);
  }, []);

  const resetLine = () => {
    setLine([{
      Registration: '',
      Country: countrySelect,
      Vin: '',
      Cat: typeSelect,
      Brand: '',
      First: '',
      Mileage: '',
    }]);
  };

  const handleInputChange = ({ property, value }) => {
    const keys = property.split('[-]');
    const temp = [...line];
    temp[parseInt(keys[0], 10)][keys[1]] = value;
    setLine(temp);
  };

  const handleInputDateChange = ({
    property, value,
  }) => {
    const keys = property.split('[-]');
    const temp = [...line];
    temp[parseInt(keys[0], 10)][keys[1]] = value;
    setLine(temp);
  };

  const handleSelect = ({
    property, value, key,
  }) => {
    const temp = [...line];
    temp[key][property] = value;
    setLine(temp);
  };

  const addLine = () => {
    const temp = [...line];
    temp.push({
      Registration: '',
      Country: createNewArray({ base: countrySelect }),
      Vin: '',
      Cat: createNewArray({ base: typeSelect }),
      Brand: '',
      First: '',
      Mileage: '',
    });
    const newArrayError = error;
    newArrayError.push({});
    setError(newArrayError);
    setLine(temp);
  };


  const checkVinError = (vinError) => {
    const errorArray = [];
    let hasError = false;
    line.forEach((element, key) => {
      errorArray.push({});
      const errorObj = {};
      if (vinError[key] === true) {
        errorObj.VinSyntax = { active: true };
      }

      errorArray[key] = errorObj;
      hasError = errorObj.hasError === true ? true : hasError;
    });

    setError(errorArray);
    return hasError;
  };

  const handleAddVehicule = async () => {
    const data = {
      data: [],
    };

    line.forEach((element) => {
      const countrySelectRes = element.Country.filter((el) => el.active === true)[0];
      const typeSelectRes = element.Cat.filter((el) => el.active === true)[0];
      const tempObject = {
        countryOfRegistration: countrySelectRes.slug,
        registrationNumber: element.Registration,
        vin: element.Vin,
        type: typeSelectRes.slug,
        brand: element.Brand,
        firstDate: new Date(moment(element.First, 'DD/MM/YYYY').format('YYYY-MM-DD')),
        mileage: element.Mileage,
      };
      data.data.push(tempObject);
    });

    const resCheck = await checkVin({ fleetObj: data });
    const formatCheck = formatApiRes(resCheck);
    if (formatCheck && formatCheck.success) {
      const haveError = formatCheck.data.filter((el) => el === true);
      if (haveError.length > 0) {
        setLoading(false);
        checkVinError(formatCheck.data);
      } else {
        const resAdd = await AddVehicule({ fleetObj: data, userId: getUserFleetInformation({ obj: user.user, key: 'id' }) });

        if (resAdd) {
          setLoading(false);
          setsuccessAdd(true);
        }
      }
    } else {
      setLoading(false);
    }
  };

  const handleCloseSuccesAdd = async () => {
    setLoading(true);
    const resUser = await getUser();
    if (resUser.success === true) {
      setsuccessAdd(false);
      resetLine();
      closeEvent();
    }
  };

  const checkField = () => {
    const errorArray = [];
    let hasError = false;
    line.forEach((element, key) => {
      errorArray.push({});
      const baseArrayField = [
        {
          key: 'Registration',
          type: 'nospacedashchar',
          value: element.Registration,
        },
        {
          key: 'Vin',
          type: 'nospacedashchar',
          value: element.Vin,
        },
        {
          key: 'Brand',
          type: 'simple',
          value: element.Brand,
        },
        {
          key: 'First',
          type: 'date',
          value: element.First,
        },
        {
          key: 'Mileage',
          type: 'number',
          value: element.Mileage,
        },
      ];

      const errorObj = createErrorObject(errorArray[key], baseArrayField, hasError, getGlobal);
      errorArray[key] = errorObj.object;
      hasError = errorObj.hasError === true ? true : hasError;
    });

    setError(errorArray);
    return hasError;
  };

  const deleteLineFn = ({ key }) => {
    const temp = [...line];
    temp.splice(key, 1);
    setLine(temp);
  };

  const handleSave = () => {
    if (!checkField()) {
      setLoading(true);
      handleAddVehicule();
    }
  };

  const renderErrorText = (type) => {
    if (type === 'Vin') {
      return getField({ base: basePage, field: 'upload_error_vin_syntax' });
    }
    if (type === 'VinSyntax') {
      return getField({ base: basePage, field: 'upload_error_vin' });
    }
    if (type === 'Registration') {
      return getField({ base: basePage, field: 'upload_error_registration' });
    }
    if (type === 'Brand') {
      return getField({ base: basePage, field: 'upload_error_brand' });
    }
    if (type === 'Country') {
      return getField({ base: basePage, field: 'upload_error_country' });
    }
    if (type === 'Mileage') {
      return getField({ base: basePage, field: 'upload_error_mileage' });
    }
    if (type === 'type') {
      return getField({ base: basePage, field: 'upload_error_type' });
    }
    if (type === 'First') {
      return getField({ base: basePage, field: 'upload_error_date' });
    }
    return '';
  };

  const shouldeRenderErroMessage = () => {
    if (error) {
      const errorTypeArray = [];
      error.forEach((element) => {
        const keys = Object.keys(element);

        keys.forEach((child) => {
          if (!errorTypeArray.find((el) => el === child)) {
            errorTypeArray.push(child);
          }
        });
      });


      if (errorTypeArray.length > 0) {
        const errorComponents = errorTypeArray.map((item, key) => (
          <ErrorText key={key}>{renderErrorText(item)}</ErrorText>
        ));

        return {
          should: true,
          error: errorComponents,
        };
      }
    }

    return {
      should: false,
      error: '',
    };
  };

  useEffect(() => {
    if (uploadActive) {
      resetLine();
    }
  }, [uploadActive]);

  const handleCloseEvent = () => {
    if (successAdd) {
      handleCloseSuccesAdd();
      if (closeEvent) {
        closeEvent();
      }
    } else if (closeEvent) {
      closeEvent();
    }
  };

  return (
    <Container style={style}>
      <UploadPop active={uploadActive === true} flex closeEvent={handleCloseEvent} width="760px">
        {
          !successAdd ? (
            <div>
              <UploadPopHeader>
                <Content__text
                  type="p"
                  color="#4d4d4d"
                  align="center"
                  style={{ fontWeight: 600, fontSize: 26 }}
                >
                  {getField({ base: basePage, field: 'add_popup_title' })}
                </Content__text>
              </UploadPopHeader>
              <CounterContainer>
                <Content__counterInfo
                  value={line.length}
                  label={getField({ base: basePage, field: 'add_popup_counter' })}
                />
              </CounterContainer>
              {
                shouldeRenderErroMessage().should && (
                  <div>
                    {shouldeRenderErroMessage().error}
                  </div>
                )
              }
              <Wrapper>
                <ContainerTable>
                  <List__header noSelect noSelectAll={false} col={headerCol} />
                  <ContainerLine style={style}>
                    {line.map((item, key) => (
                      <Line key={key} borderLine hasEvent={false}>
                        <ColItem number={headerCol.length}>
                          <Form__textfield
                            textTransform="uppercase"
                            change={handleInputChange}
                            value={item.Registration}
                            property={`${key}[-]Registration`}
                            placeholder="CL034TN"
                            error={hasFieldError(error[key], 'Registration', false, true)}
                          />
                        </ColItem>
                        <ColItem number={headerCol.length}>
                          <Form__select
                            value={item.Country}
                            style={{ minWidth: 150 }}
                            event={(res) => handleSelect({
                              property: 'Country',
                              value: res.value,
                              result: res.result,
                              type: 'select',
                              key,
                            })}
                            error={hasFieldError(error[key], 'Country', false, true)}
                          />
                        </ColItem>
                        <ColItem number={headerCol.length}>
                          <Form__textfield
                            textTransform="uppercase"
                            change={handleInputChange}
                            value={item.Vin}
                            property={`${key}[-]Vin`}
                            placeholder="1FAPP1283PWXXXXXX"
                            error={hasFieldErroArray(error[key], ['VinSyntax', 'Vin'], false, true)}
                          />
                        </ColItem>
                        <ColItem number={headerCol.length}>
                          <Form__select
                            style={{ minWidth: 150 }}
                            value={item.Cat}
                            event={(res) => handleSelect({
                              property: 'Cat',
                              value: res.value,
                              result: res.result,
                              type: 'select',
                              key,
                            })}
                            error={hasFieldError(error[key], 'Cat', false, true)}
                          />
                        </ColItem>
                        <ColItem number={headerCol.length}>
                          <Form__textfield
                            textTransform="capitalize"
                            change={handleInputChange}
                            value={item.Brand}
                            property={`${key}[-]Brand`}
                            placeholder="Mercedes"
                            error={hasFieldError(error[key], 'Brand', false, true)}
                          />
                        </ColItem>
                        <ColItem number={headerCol.length}>
                          <Form__date
                            value={item.First}
                            require
                            property={`${key}[-]First`}
                            event={handleInputDateChange}
                            error={hasFieldError(error[key], 'First', false, true)}
                          />
                        </ColItem>
                        <ColItem number={headerCol.length} end="true">
                          <LastColContainer>
                            <Form__textfield
                              change={handleInputChange}
                              value={item.Mileage}
                              property={`${key}[-]Mileage`}
                              placeholder="1 000 000"
                              error={hasFieldError(error[key], 'Mileage', false, true)}
                            />
                          </LastColContainer>
                          {key > 0 ? <DeleteLine event={() => deleteLineFn({ key })} /> : null}
                        </ColItem>
                      </Line>
                    ))}
                  </ContainerLine>
                </ContainerTable>
                <ButtonAddContainer>
                  <Add event={addLine} />
                </ButtonAddContainer>
              </Wrapper>
              <ButtonContainer>
                <Button event={handleSave}>
                  {getField({ base: basePage, field: 'add_popup_validate' })}
                </Button>
              </ButtonContainer>
            </div>
          ) : (
            <Message__sucess
              icon={t.image.illu.check}
              title={getField({ base: basePage, field: 'success_popup_title' })}
              text={getField({ base: basePage, field: 'success_popup_text' })}
              buttonText={getField({ base: basePage, field: 'success_popup_button' })}
              buttonEvent={handleCloseSuccesAdd}
            />
          )
        }
        {loading ? <Loader__wrapper /> : false}
      </UploadPop>
    </Container>
  );
};

export default Fleet__upload;
