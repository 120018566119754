
import React, {
  Fragment, useState, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import { Grid__separator } from 'components/grid/grid';
import { Content__sectionTitle } from 'components/content/content';
import Button__primary from 'components/button/primary/button__primary';
import { Form__textfield, Form__uploader } from 'components/form/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SendMail } from 'helpers/api/routes/Mail';
import { ROUTING } from 'util/constant/routing.constant';
import { faTools, faShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import { Message__sucess } from 'components/message/message';
import Grid__popup from 'components/grid/popup/grid__popup';
import { createErrorObject } from 'util/conditions/checkField.condition';
import { ContentContext } from 'context/contentContext';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { setAction } from 'util/function/reloadAction.function';
import { AppContext } from 'context/appContext';
import { hasFieldError } from 'util/conditions/haveErrorField.conditions';
import { sizeLimit } from 'util/conditions/sizeFile.util';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';
import { CreateService, createRepairFile } from 'helpers/api/routes/RepairApproval';
import { strapiRepairApprovalService, strapiStandartrepairService, strapiPaymentGuaranteeService } from 'util/strapi/content.starpi';
import { isVehicleHomeBase } from 'util/workshop/getVehicleObjectInformation';
import { souldRenderPaymentguarantee, shouldRenderStandarRepair } from 'util/service/createHistoric';

const Container = styled(Grid__popup)`
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
`;

const ContainerForm = styled.div`
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
  width: 500px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DashboardRepairApproval__formPop = ({
  style, active, closePop, currentVehicle, caseId, onSuccess,
}) => {
  const { user, getUser } = useContext(AppContext);
  const { getGlobal, getField, getFieldGroup } = useContext(ContentContext);
  const [description, setdescription] = useState('');
  const [price, setprice] = useState('');
  const [file, setfile] = useState(null);
  const [labourprice, setlabourprice] = useState('');
  const [hours, sethours] = useState('');
  const [cost, setcost] = useState('');
  const [totalcost, settotalcost] = useState('');
  const [error, seterror] = useState(false);
  const [loading, setloading] = useState(false);
  const [sucessScreen, setsucessScreen] = useState({ active: false });

  const handleDescription = (value) => {
    setdescription(value.value);
  };

  const handlePrice = (value) => {
    setprice(value.value);
  };

  const handlelabourprice = (value) => {
    setlabourprice(value.value);
  };
  const handlehours = (value) => {
    sethours(value.value);
  };
  const handlecost = (value) => {
    setcost(value.value);
  };
  const handletotalcost = (value) => {
    settotalcost(value.value);
  };

  const getVehicleInformation = (key) => {
    if (currentVehicle) {
      if (currentVehicle) {
        if (currentVehicle[key]) {
          return currentVehicle[key];
        }
      }
    }
    return '';
  };

  const getVehicleUserInformation = (key) => {
    if (currentVehicle) {
      if (currentVehicle.UserFleet && currentVehicle.UserFleet.UserFleetAlias) {
        if (currentVehicle.UserFleet.UserFleetAlias[key]) {
          return currentVehicle.UserFleet.UserFleetAlias[key];
        }
      }
    }
    return '';
  };

  const checkField = () => {
    let errorObj = {};
    let errorArray = {};

    const baseArrayField = [
      {
        key: 'description',
        type: 'simple',
        value: description,
      },
      {
        key: 'price',
        type: 'simple',
        value: price,
      },
      {
        key: 'labourprice',
        type: 'simple',
        value: labourprice,
      },
      {
        key: 'hours',
        type: 'simple',
        value: hours,
      },
      {
        key: 'cost',
        type: 'simple',
        value: cost,
      },
      {
        key: 'totalcost',
        type: 'simple',
        value: totalcost,
      },
    ];

    errorObj = createErrorObject(errorArray, baseArrayField, errorObj.hasError, getGlobal);
    errorArray = errorObj.object;

    if (file === null || sizeLimit(file)) {
      if (sizeLimit(file)) {
        errorArray.file = { active: true, message: 'File limite' };
      } else {
        errorArray.file = { active: true };
      }
      errorObj.hasError = true;
    }

    seterror(errorArray);
    return errorObj.hasError;
  };

  const sendMailProcess = (url) => {
    let destinationMail = '';
    let requestPath = '';
    let notificationDisable = null;
    if (currentVehicle) {
      if (currentVehicle.UserFleet) {
        const base = currentVehicle.UserFleet;
        if (base.repairApproverUserTier === false) {
          if (base.UserApproverAlias && base.UserApproverAlias.UserFleet) {
            requestPath = ROUTING.fleet.dashboardRepairApproval.path;
          } else {
            requestPath = ROUTING.workshop.dashboardRepairApproval.path;
          }
          destinationMail = base.UserApproverAlias.email;
          notificationDisable = base.UserFleetAlias.notificationDisable;
        } else {
          destinationMail = base.repairApprovalUserEmail;
          notificationDisable = false;
          requestPath = `${ROUTING.ext.repairApprovalConfirmation.withoutparams}/${url}`;
        }
      }
    }

    if (!notificationDisable) {
      SendMail({
        template: 'notification',
        templateNotification: {
          status: 'action',
          statusLabel: getFieldGroup({
            select_lang: currentVehicle.UserFleet.UserApproverAlias ? currentVehicle.UserFleet.UserApproverAlias.lang : currentVehicle.UserFleet.UserFleetAlias.lang, base: strapiRepairApprovalService, groupe: 'mail_start', field: 'status',
          }),
          content: `${user.user.companyName} ${getFieldGroup({
            select_lang: currentVehicle.UserFleet.UserApproverAlias ? currentVehicle.UserFleet.UserApproverAlias.lang : currentVehicle.UserFleet.UserFleetAlias.lang, base: strapiRepairApprovalService, groupe: 'mail_start', field: 'content',
          })}`,
          btnContent: getFieldGroup({
            select_lang: currentVehicle.UserFleet.UserApproverAlias ? currentVehicle.UserFleet.UserApproverAlias.lang : currentVehicle.UserFleet.UserFleetAlias.lang, base: strapiRepairApprovalService, groupe: 'mail_start', field: 'btn',
          }),
          btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${requestPath}`,
        },
        subject: getFieldGroup({
          select_lang: currentVehicle.UserFleet.UserApproverAlias ? currentVehicle.UserFleet.UserApproverAlias.lang : currentVehicle.UserFleet.UserFleetAlias.lang, base: strapiRepairApprovalService, groupe: 'mail_start', field: 'subject',
        }),
        destination: destinationMail,
      });
    }
  };

  const handleLaunch = async () => {
    if (!checkField()) {
      setloading(true);
      const fileParams = {
        file,
        extension: file.extension,
        serviceUid: '',
        fileId: '',
      };

      const res = await CreateService({
        fleetId: getVehicleInformation('id'),
        workshopId: user.user.UserWorkshop.id,
        caseId,
        textLang: user.user.lang,
        description,
        partprice: price,
        labourprice,
        hour: hours,
        additionalcost: cost,
        totalCost: totalcost,
      });
      const formated = formatApiRes(res);
      if (res) {
        if (formated.success) {
          if (res.data.success === true) {
            fileParams.serviceUid = res.data.data.uid;
            fileParams.fileId = res.data.data.id;
            const fileRes = await createRepairFile(fileParams);
            if (fileRes) {
              setloading(false);
              setsucessScreen({ active: true, id: res.data.data.id });
              sendMailProcess(res.data.data.validationUrl);
            }
          } else {
            setloading(false);
          }
          setsucessScreen({ active: true, id: res.data.data.id });
        } else {
          setloading(false);
        }
      }
    }
  };

  const handleSuccesMessage = async () => {
    if (onSuccess) {
      onSuccess(sucessScreen.id);
    } else {
      setloading(true);
      const resUser = await getUser();
      if (resUser) {
        setAction({ key: 'action', value: sucessScreen.id });
        setloading(false);
      } else {
        setloading(false);
      }
    }
  };


  const parseFiles = (fileArray) => {
    setfile(fileArray[0]);
  };

  const reloadUser = async () => {
    setloading(true);
    await getUser();
    setloading(false);
  };

  const handleClose = () => {
    if (sucessScreen.active) {
      reloadUser();
    }
    if (closePop) {
      closePop();
    }
  };

  useEffect(() => {
    if (active) {
      setdescription('');
      setprice('');
      setfile(null);
      setlabourprice('');
      sethours('');
      setcost('');
      settotalcost('');
    }
  }, [active]);


  return (
    <Container style={style} active={active} closeEvent={handleClose}>
      {!sucessScreen.active ? (
        <Fragment>
          <Content__cardTitle content={getField({ base: strapiRepairApprovalService, field: 'request_title' })} />
          <Grid__separator size="45px" />
          <ContainerForm>
            <Content__sectionTitle
              title={getField({ base: strapiRepairApprovalService, field: 'repair_title' })}
              icon={<FontAwesomeIcon icon={faTools} />}
            />
            <Form__textfield
              type="textarea"
              label={getField({ base: strapiRepairApprovalService, field: 'repair_description' })}
              require
              property="description"
              value={description}
              change={handleDescription}
              error={hasFieldError(error, 'description')}
            />
            <Grid__separator size="35px" />
            <Form__textfield
              label={getField({ base: strapiRepairApprovalService, field: 'parts_price' })}
              require
              value={price}
              property="price"
              change={handlePrice}
              error={hasFieldError(error, 'price')}
              type="price"
            />
            <Grid__separator size="35px" />
            <Form__textfield
              label={getField({ base: strapiRepairApprovalService, field: 'labour_price' })}
              require
              value={labourprice}
              property="labourprice"
              change={handlelabourprice}
              error={hasFieldError(error, 'labourprice')}
              type="price"
            />
            <Grid__separator size="35px" />
            <Form__textfield
              label={getField({ base: strapiRepairApprovalService, field: 'hours' })}
              require
              value={hours}
              property="hours"
              change={handlehours}
              error={hasFieldError(error, 'hours')}
              type="hours"
            />
            <Grid__separator size="35px" />
            <Form__textfield
              label={getField({ base: strapiRepairApprovalService, field: 'additional_cost' })}
              require
              value={cost}
              property="cost"
              change={handlecost}
              error={hasFieldError(error, 'cost')}
              type="price"
            />
            <Grid__separator size="35px" />
            <Form__textfield
              label={getField({ base: strapiRepairApprovalService, field: 'total_cost' })}
              require
              value={totalcost}
              property="totalcost"
              change={handletotalcost}
              error={hasFieldError(error, 'totalcost')}
              type="price"
            />
            <Grid__separator size="35px" />
            <Form__uploader
              label={getField({ base: strapiRepairApprovalService, field: 'attachement_label' })}
              onFilesAddedCallBack={parseFiles}
              error={error.hasOwnProperty('file') ? { active: true, message: error.file.message } : false}
            />
            <Grid__separator size="35px" />
            <Row>
              <Button__primary event={handleLaunch}>
                {getField({ base: strapiRepairApprovalService, field: 'launch_request_button' })}
              </Button__primary>
            </Row>
            <Grid__separator size="25px" />
          </ContainerForm>
        </Fragment>
      ) : (
        <Message__sucess
          icon={t.image.illu.check}
          title={getField({ base: strapiRepairApprovalService, field: 'success_popup_title' })}
          text={getField({ base: strapiRepairApprovalService, field: 'success_popup_text' })}
          buttonText={getField({ base: strapiRepairApprovalService, field: 'success_popup_button' })}
          buttonEvent={handleSuccesMessage}
        />
      )}
      {loading ? <Loader__wrapper /> : false}
    </Container>
  );
};

export default DashboardRepairApproval__formPop;
