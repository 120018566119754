
/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';


const TotalVehicle = styled.div`
  margin-top: 10px;
  font-weight: normal;
  font-size: 14px;
  color: #5c6365;
  margin-bottom: 35px;
`;

const TotalVehicle__value = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const SingleService__totalVehicle = ({ style, number, label }) => (
  <TotalVehicle style={style}>
    <TotalVehicle__value>{number}</TotalVehicle__value>
    {' '}
    {label}
  </TotalVehicle>
);

export default SingleService__totalVehicle;
