/**
 * Import Lib & assets
 */

import React, { Fragment } from 'react';
import styled from 'styled-components';
import t from 'theme';

import { Button__primary } from 'components/button/button';
import { Grid__card, Grid__separator } from 'components/grid/grid';
import Content__titleIconService from 'components/content/titleIconService/content__titleIconService';
import { List__table } from 'components/list/list';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faPlus } from '@fortawesome/pro-regular-svg-icons';
import Content__counterInfo from 'components/content/counterInfo/content__counterInfo';
import Loader__small from 'components/loader/small/loader__small';


const Card = styled(Grid__card)`
  width: ${(props) => (props.width ? props.width : '520px')};
  height: 380px;
  padding: 30px;
  margin-bottom: 40px;
  position: relative;
  &:nth-child(2n + 1) {
    margin-right: 40px;
  }
`;

const Button = styled(Button__primary)`
  margin-right: 15px;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${t.mixins.flex()}
`;

const Header = styled.div`
  position: relative;
  width: 100%;
`;

const CounterInfo = styled(Content__counterInfo)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Cards__listCard = ({
  width,
  style,
  headerCol,
  line,
  icon,
  title,
  buttonService,
  buttonRequest,
  loading,
  hasLoader,
  buttonServiceHref,
  buttonRequestHref,
  lineEvent,
  countValue,
  actionLabel,
  fleet,
}) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(buttonServiceHref);
  };

  const handleSecondClick = () => {
    history.push(buttonRequestHref);
  };

  return (
    <Card style={style} width={width}>
      {!hasLoader || (hasLoader && !loading) ? (
        <Fragment>
          <Header>
            <Content__titleIconService fontSize="19px" icon={icon} content={title} />
            {countValue > 0 ? (
              <CounterInfo
                value={countValue}
                label={actionLabel}
              />
            ) : null}
          </Header>
          <Grid__separator size="30px" />
          <List__table noScroll headerCol={headerCol} lineCol={line} noSelect lineEvent={lineEvent} />
          <Grid__separator size="25px" />

          {buttonService !== undefined ? (
            <Button
              border
              blue
              withIcon
              event={handleClick}
              icon={<FontAwesomeIcon icon={faChartBar} />}
              iconEnd
            >
              {buttonService}
            </Button>
          ) : null}
          {buttonRequest !== undefined ? (
            <Button__primary
              withIcon
              iconEnd
              event={handleSecondClick}
              icon={fleet ? <FontAwesomeIcon icon={faChartBar} /> : <FontAwesomeIcon icon={faPlus} />}
            >
              {buttonRequest}
            </Button__primary>
          ) : null}
        </Fragment>
      ) : hasLoader && loading ? (
        <LoaderContainer>
          <Loader__small />
        </LoaderContainer>
      ) : null}
    </Card>
  );
};

export default Cards__listCard;
