
/**
   * Import Lib & assets
   */

import React, { useContext, Fragment } from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';

const Container = styled.div`
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: ${(props) => (props.blue ? '#01B5E2' : '#fff')};
  position: absolute;
  left: 50%;
  bottom: 25px;
  transform: translateX(-50%);
`;

const Link = styled.a`
  text-decoration: none;
`;

const Content__externalLinks = ({ style, blue }) => {
  const { getGlobalWithNavigatorLang } = useContext(ContentContext);
  return (
    <Container style={style} blue={blue}>
      { getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_1_label' }) ? (
        <Fragment>
          <Link target="_blank" rel="noopener noreferrer" href={getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_1_url' })}>{getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_1_label' })}</Link>
        </Fragment>
      ) : null }
      { getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_2_label' }) ? (
        <Fragment>
          &nbsp;&nbsp;-&nbsp;&nbsp;
          <Link target="_blank" rel="noopener noreferrer" href={getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_2_url' })}>{getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_2_label' })}</Link>
        </Fragment>
      ) : null }
      { getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_3_label' }) ? (
        <Fragment>
          &nbsp;&nbsp;-&nbsp;&nbsp;
          <Link target="_blank" rel="noopener noreferrer" href={getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_3_url' })}>{getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_3_label' })}</Link>
        </Fragment>
      ) : null }
      { getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_4_label' }) ? (
        <Fragment>
          &nbsp;&nbsp;-&nbsp;&nbsp;
          <Link target="_blank" rel="noopener noreferrer" href={getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_4_url' })}>{getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_4_label' })}</Link>
        </Fragment>
      ) : null }
      { getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_5_label' }) ? (
        <Fragment>
          &nbsp;&nbsp;-&nbsp;&nbsp;
          <Link target="_blank" rel="noopener noreferrer" href={getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_5_url' })}>{getGlobalWithNavigatorLang({ option: 'external_links', field: 'link_5_label' })}</Link>
        </Fragment>
      ) : null }
    </Container>
  );
};

export default Content__externalLinks;
