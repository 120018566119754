import React, {
  createRef, useState, Fragment, useContext,
} from 'react';
import styled from 'styled-components';
import t from 'theme';

import { Grid__separator } from 'components/grid/grid/';
import { Content__text } from 'components/content/content';
import { Button__primary } from 'components/button/button';
import Form__label from 'components/form/label/form__label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/pro-light-svg-icons';
import { ContentContext } from 'context/contentContext';

const Container = styled.div`
  width: 100%;
  height: auto;
  cursor: pointer;
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.div`
  user-select: none;
  ${t.mixins.flex()};
  position: relative;
`;

const LabelContainer = styled.div`
  width: 100%;
  height: 147px;
  background: #f7f7f7;
  border-radius: 5px;
  border: 2px dashed #b9b9b9;
  border-color: ${(props) => (props.error ? t.color.red : props.hightlight ? t.color.cyan : '#E1E1E1')};
  padding: 35px 0;
  ${t.mixins.flex({ direction: 'column' })}
  position: relative;
  pointer-events: none;
  transition: all ease 0.3s;
`;

const FooterPop = styled.div`
  ${t.mixins.flex({ justify: 'center' })}
  margin-top: 40px;
`;

const Form__labelError = styled(Form__label)`
  color: ${t.color.red};
  text-transform: initial;
  font-size: 12px;
  display: ${({ active }) => (active ? 'block' : 'none')};
  text-align: center;
  margin-top: 20px;
`;


const Form__uploader = ({
  style,
  disabled,
  onFilesAddedCallBack,
  validateEvent,
  editFileName,
  withBtn,
  title,
  btnText,
  label,
  error,
  require,
  conditionalWord,
  text,
  accept,
}) => {
  const { getGlobal } = useContext(ContentContext);
  const fileInputRef = createRef();
  const [hightlight, setHightlight] = useState(false);
  const [step, setStep] = useState('upload');
  const [fileArray, setFileArray] = useState([]);


  const openFileDialog = (e) => {
    if (disabled) return;
    if (e.target.classList.contains('uploaderClick')) {
      fileInputRef.current.click();
    }
  };

  const fileListToArray = (list) => {
    const array = [];
    for (let i = 0; i < 1; i++) {
      array.push({
        file: list.item(i),
        name: list.item(i).name.split('.')[0],
        extension: list.item(i).name.split('.')[1],
      });
    }
    setFileArray(array);
    return array;
  };

  const onFilesAdded = (evt) => {
    if (disabled) return;
    const { files } = evt.target;
    if (onFilesAddedCallBack) {
      const array = fileListToArray(files);
      setStep('preview');
      onFilesAddedCallBack(array);
    } else {
      fileListToArray(files);
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
    if (disabled) return;
    setHightlight(true);
  };

  const onDragLeave = () => {
    setHightlight(false);
  };

  const onDrop = (event) => {
    event.preventDefault();
    if (disabled) return;
    const { files } = event.dataTransfer;
    if (onFilesAddedCallBack) {
      const array = fileListToArray(files);
      setStep('preview');
      onFilesAddedCallBack(array);
    } else {
      fileListToArray(files);
    }
    setHightlight(false);
  };

  const handleSave = () => {
    const arrayToSend = [];
    if (!editFileName) {
      fileArray.forEach((element) => {
        arrayToSend.push(element.file);
      });
    }

    validateEvent(arrayToSend);
  };

  return (
    <Container
      style={style}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={openFileDialog}
      className={step === 'upload' ? 'uploaderClick' : ''}
    >
      {label ? (
        <Form__label
          require={require === false ? false : true}
          labelSecondary={getGlobal({ option: 'globalcontent', field: 'max_file_size' })}
        >
          {label}
        </Form__label>
      ) : null}
      <FileInput
        ref={fileInputRef}
        type="file"
        name="fileInput"
        onChange={onFilesAdded}
        style={{ pointerEvents: 'none' }}
        // accept="application/pdf, image/jpeg, text/csv"
        accept={accept ? `application/pdf,image/jpeg,text/csv,${accept}` : 'application/pdf,image/jpeg,text/csv'}
      />
      <div className={error ? 'formError' : ''}>
        <FileLabel className="uploaderClick">
          <LabelContainer hightlight={hightlight} error={error ? error.active : false}>
            {fileArray.length > 0 ? (
              <Fragment>
                <FontAwesomeIcon style={{ fontSize: 40 }} icon={faFileInvoice} />
                <Grid__separator size="20px" />
                <Content__text
                  type="p"
                  color={hightlight ? t.color.cyan : t.color.dark}
                  align="center"
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    width: '100%',
                  }}
                >
                  {fileArray[0].file.name}
                </Content__text>
              </Fragment>
            ) : (
              <Fragment>
                <Content__text
                  type="p"
                  color={
                    error && error.active ? t.color.red : hightlight ? t.color.cyan : t.color.dark
                  }
                  align="center"
                  style={{ fontWeight: 600, fontSize: 18, transition: 'all ease 0.3s' }}
                >
                  {title || getGlobal({ option: 'globalcontent', field: 'uploader_title' })}
                </Content__text>
                <Grid__separator size="5px" />
                <Content__text
                  type="p"
                  color={hightlight ? t.color.cyan : t.color.dark}
                  align="center"
                  style={{ fontWeight: 300, fontSize: 14, transition: 'all ease 0.3s' }}
                >
                  {conditionalWord || getGlobal({ option: 'globalcontent', field: 'uploader_or' })}
                </Content__text>
                <Grid__separator size="5px" />
                <Content__text
                  type="p"
                  color={hightlight ? '#FAFAFA' : t.color.cyan}
                  align="center"
                  style={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    textDecoration: 'underline',
                    transition: 'all ease 0.3s',
                  }}
                >
                  {text || getGlobal({ option: 'globalcontent', field: 'uploader_btn' })}
                </Content__text>
              </Fragment>
            )}
          </LabelContainer>
        </FileLabel>
      </div>
      {withBtn ? (
        <FooterPop>
          <Button__primary
            event={() => handleSave()}
            style={fileArray.length === 0 ? { opacity: 0.2, pointerEvents: 'none' } : {}}
          >
            {btnText}
          </Button__primary>
        </FooterPop>
      ) : null}
      {
        error && error.message ? (
          <Form__labelError active={error ? error.active : false}>
            {error && error.message ? error.message : ''}
          </Form__labelError>
        ) : null
      }
    </Container>
  );
};

export default Form__uploader;
