/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

/**
 * import components
 */

import { Form__label } from 'components/form/form';

import IntlTelInput from 'react-intl-tel-input';
import './form__combined.scss';

/**
 * styled components
 */

const Container = styled.div``;

const InputContainer = styled.div`
  
`;

/**
 * combined component is..
 */

const Form__combined = ({
  style,
  textValue,
  handleTextChange,
  label,
  require,
  defaultCountry,
  error,
  allCountry,
  styleLabel,
}) => {
  const countryCode = [
    'de',
    'at',
    'ad',
    'ch',
    'it',
    'es',
    'pt',
    'nl',
    'be',
    'lu',
    'fr',
    'pl',
    'no',
    'lt',
    'fi',
    'dk',
    'se',
    'hu',
    'cz',
    'gb',
  ];


  const checkCode = () => {
    if (defaultCountry) {
      if (countryCode.find((el) => el === defaultCountry)) {
        return defaultCountry;
      }
    }

    return 'de';
  };
  return (
    <Container style={style} className={error ? 'errorFieldTel error formError' : 'container'}>
      <Form__label style={styleLabel} require={require}>{label}</Form__label>
      <InputContainer>
        <IntlTelInput
          defaultValue={textValue}
          preferredCountries={defaultCountry ? [checkCode()] : false}
          onlyCountries={!allCountry ? countryCode : false}
          format
          onPhoneNumberChange={(isValide, res, object, final) => handleTextChange(isValide, res, final)}
          onSelectFlag={(res, object, final, isValide) => handleTextChange(isValide, res, final)}
          inputClassName="intl-tel-input-phone"
        />
      </InputContainer>
    </Container>
  );
};

export default Form__combined;
