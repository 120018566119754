
import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import t from 'theme';
/**
 * import containers & components
 */

import { Grid__separator } from 'components/grid/grid/';
import { Form__textfield } from 'components/form/form';
import { Button__primary, Button__inline } from 'components/button/button';
import { Content__text } from 'components/content/content';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { ROUTING } from 'util/constant/routing.constant';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { resetPassword } from 'helpers/api/routes/User';
import { ContentContext } from 'context/contentContext';
import { strapiLogin } from 'util/strapi/content.starpi';


/**
 * styled components
 */

const FormContent = styled.div`
  width: 100%;
`;

const SaveBtn = styled(Button__primary)`
  width: 100%;
  text-align: center;
`;

const TitleForm = styled(Content__text)`
  text-align: center;
  width: 100%;
  margin-bottom: 35px;
  font-weight: 600;
  font-size: 30px;
  line-height: auto;
  text-align: center;
  color: #1a1a1a;
`;

const BtnSecondary = styled(Button__inline)`
  text-align: center;
  text-decoration: none;
  margin: 0 auto;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  text-align: left;
`;

const Error = styled.p`
  font-size: 12px;
  font-weight: normal;
  color: #e74c3c;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
`;


const Success = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const SuccessLogo = styled.img`
  width: 110px;
`;

const SuccessContent = styled.div`
  margin-top: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #000000;
`;

const ResetPassword__form = () => {
  const { passToken } = useParams();
  const { getFieldWithNavigatorLang } = useContext(ContentContext);

  const [loading, setloading] = React.useState(false);
  const [errormessage, setErrormessage] = React.useState(false);

  const [pass, setpass] = React.useState('');
  const [passValidation, setpassValidation] = React.useState({ valide: false });
  const [passError, setpassError] = React.useState(false);
  const [passConfirm, setpassConfirm] = React.useState('');
  const [passConfirmError, setpassConfirmError] = React.useState(false);
  const [success, setsuccess] = React.useState(false);


  const handleSave = async () => {
    const obj = {
      pass,
      secondPass: passConfirm,
    };

    const res = await resetPassword({
      token: passToken,
      newPassword: obj.pass,
    });

    if (res) {
      setloading(false);
    }

    const formated = formatApiRes(res);
    if (formated.success) {
      setsuccess(true);
    } else if (formated.data && formated.data === 'wrong passToken') {
      setErrormessage('wrong token');
    } else {
      setErrormessage('Error please try later');
    }
  };

  const handleSubmit = () => {
    if (passValidation.valide === false) {
      setpassError(true);
      setpassConfirmError(false);
    } else if (pass !== passConfirm) {
      setpassConfirmError(true);
      setpassError(false);
    } else {
      setloading(true);
      setpassError(false);
      setpassConfirmError(false);
      handleSave();
    }
  };

  const handlePassConfirm = (value) => {
    setpassConfirm(value.value);
  };

  const handlePass = (value) => {
    setpass(value.value);
  };

  const handlePassValidation = (value) => {
    setpassValidation(value);
  };

  return (
    <FormContent>
      <Container>
        <TitleForm type="h1">{getFieldWithNavigatorLang({ base: strapiLogin, field: 'resetPass_title' })}</TitleForm>
        {errormessage && <Error>{errormessage}</Error>}
        {!success ? (
          <Fragment>
            <Form__textfield
              change={handlePass}
              value={pass}
              property="newPass"
              labelSecondary={getFieldWithNavigatorLang({ base: strapiLogin, field: 'resetPass_label_info' })}
              label={getFieldWithNavigatorLang({ base: strapiLogin, field: 'resetPass_label' })}
              type="password"
              passWordValidation={handlePassValidation}
              require
              error={{ active: passError }}
            />
            <Grid__separator size="25px" />
            <Form__textfield
              change={handlePassConfirm}
              value={passConfirm}
              property="passConfirm"
              label={getFieldWithNavigatorLang({ base: strapiLogin, field: 'resetPass_labelConfirm' })}
              type="password"
              require
              error={{ active: passConfirmError }}
            />
            <Grid__separator size="25px" />
            <SaveBtn orange event={handleSubmit}>
              {getFieldWithNavigatorLang({ base: strapiLogin, field: 'resetPass__button' })}
            </SaveBtn>
            <Grid__separator size="15px" />
            <BtnContainer>
              <BtnSecondary
                href={ROUTING.fleet.login.path}
                content={getFieldWithNavigatorLang({ base: strapiLogin, field: 'backToLogin' })}
              />
            </BtnContainer>
          </Fragment>
        ) : (
          <Success>
            <SuccessLogo src={t.image.illu.check} />
            <SuccessContent>{getFieldWithNavigatorLang({ base: strapiLogin, field: 'resetPass__success' })}</SuccessContent>
            <BtnContainer>
              <BtnSecondary
                href={ROUTING.fleet.login.path}
                content={getFieldWithNavigatorLang({ base: strapiLogin, field: 'backToLogin' })}
              />
            </BtnContainer>
          </Success>
        )}
        {loading ? <Loader__wrapper /> : false}
      </Container>

    </FormContent>
  );
};

export default ResetPassword__form;
