import React, { useEffect, useContext } from 'react';
import Nav from 'components/global/nav';
import styled from 'styled-components';
import t from 'theme';
import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Transition__loader_controller } from 'components/transition/transition';
import Home__header from 'containers/workshopView/home/header/home__header';
import Home__historic from 'containers/workshopView/home/historic/home__historic';
import { ContentContext } from 'context/contentContext';
import Home__check from 'containers/workshopView/home/check/home__check';
import { loadService, haveServiceContent } from 'util/function/loadContent.function';
import {
  strapiWorkshopHome,
  strapiWorkshopSingleVehicle,
  strapiWorkshopDashboardPriority90,
  strapiWorkshopDashboardPaymentGuarantee,
  strapiPaymentGuaranteeService,
  strapiRegister,
  strapiCase,
  strapiAppointment,
} from 'util/strapi/content.starpi';
import { AppContext } from 'context/appContext';
import { useData } from 'components/data/getter/data__getter';
import Modal__TodayAppointment from 'components/modal/TodayAppointment/modal__TodayAppointment';

const Container = styled.div`
  height: calc(100vh - 280px);
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-start' })};
`;

/**
 * @name Home
 */
let pageNumber = 1;
const Home = () => {
  const { loadPage, haveContentFor } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [historic, refreshData, setHistoric, setSearch] = useData({
    params: {
      userId: user.user.UserWorkshop.id,
      status: 'opened',
    },
    service: 'cases',
    type: 'cases',
  });
  // eslint-disable-next-line no-unused-vars
  const [fleetUser, refreshFleetUser, setFleetUser, setSearchFleetUser] = useData({
    params: {
      role: 'fleet',
      userWorkshopId: user.user.UserWorkshop.id,
    },
    service: 'myFleetUser',
    type: 'myFleetUser',
  });

  const loadContent = async () => {
    if (
      haveContentFor(strapiWorkshopHome).success === false
    ) {
      await loadPage(strapiWorkshopHome);
    }
    if (
      haveContentFor(strapiWorkshopDashboardPriority90).success === false
    ) {
      await loadPage(strapiWorkshopDashboardPriority90);
    }
    if (
      haveContentFor(strapiWorkshopDashboardPaymentGuarantee).success === false
    ) {
      await loadPage(strapiWorkshopDashboardPaymentGuarantee);
    }
    if (
      haveContentFor(strapiPaymentGuaranteeService).success === false
    ) {
      await loadPage(strapiPaymentGuaranteeService);
    }
    if (
      haveContentFor(strapiWorkshopSingleVehicle).success === false
    ) {
      await loadPage(strapiWorkshopSingleVehicle);
    }
    if (
      haveContentFor(strapiCase).success === false
    ) {
      await loadPage(strapiCase);
    }
    if (
      haveContentFor(strapiRegister).success === false
    ) {
      await loadPage(strapiRegister);
    }
    if (
      haveContentFor(strapiAppointment).success === false
    ) {
      await loadPage(strapiAppointment);
    }
    loadService({ haveFunc: haveContentFor, loadFunc: loadPage });
  };
  useEffect(() => {
    loadContent();

    return () => {
      pageNumber = 1;
    };
  }, []);

  if (
    haveContentFor(strapiWorkshopHome).success === false
    || haveServiceContent({ haveFunc: haveContentFor }) === false
    || haveContentFor(strapiWorkshopSingleVehicle).success === false
    || haveContentFor(strapiAppointment).success === false
    || haveContentFor(strapiWorkshopDashboardPriority90).success === false
    || haveContentFor(strapiCase).success === false
    || haveContentFor(strapiWorkshopDashboardPaymentGuarantee).success === false
    || haveContentFor(strapiPaymentGuaranteeService).success === false
    || haveContentFor(strapiRegister).success === false
  ) {
    return <Transition__loader_controller active />;
  }


  const handleLoadNewPage = () => {
    if (historic.data && !historic.loading && pageNumber + 1 > 1 && historic.data.length < historic.count) {
      pageNumber += 1;
      setHistoric(pageNumber, historic.data);
    }
  };

  return (
    <Layout__container>
      <Nav workshop active="home" />
      <Transition__loader_controller active={false} />
      <Layout__wrapper>
        <Layout__content>
          <Home__header />
          <Container>
            <Home__historic
              historic={historic.data || []}
              loading={historic.loading}
              handleLoadNewPage={handleLoadNewPage}
            />
            <Home__check fleets={fleetUser ? fleetUser.count : 0} />
          </Container>
        </Layout__content>
      </Layout__wrapper>
      <Modal__TodayAppointment userObject="UserWorkshop" isWorkshop basePage={strapiAppointment} />
    </Layout__container>
  );
};

export default Home;
