
import React, {
  useContext, Fragment, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import {
  faHandshakeAlt,
  faTools,
} from '@fortawesome/pro-regular-svg-icons';
import { AppContext } from 'context/appContext';
import { ContentContext } from 'context/contentContext';
import { getPercent } from 'util/math.utile';

/**
 * import components
 */
import { Grid__separator } from 'components/grid/grid';
import Button__secondary from 'components/button/secondary/button__secondary';
import Cards_serviceCoverage from 'components/cards/serviceCoverage/cards__serviceCoverage';
import {
  strapiHomePage, strapiRepairApprovalService, strapiPriority90Service, strapiPaymentGuaranteeService,
  strapiStandartrepairService,
} from 'util/strapi/content.starpi';
import { ROUTING } from 'util/constant/routing.constant';

import { formatApiRes, formatApiCountRes } from 'util/function/formatApiRes.function';
import { useData } from 'components/data/getter/data__getter';
import { getcoverage, getHaveService } from 'helpers/api/routes/getData';

const Container = styled.div`
  margin-left: 40px;
  width: 30%;
  text-align: center;
  min-width: 375px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex-wrap: nowrap;
`;

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 25px;
  color: #4a4a4c;
  margin-bottom: 20px;
  text-align: left;
`;

const Home__service = ({ style }) => {
  const { user } = useContext(AppContext);
  const { getField } = useContext(ContentContext);

  const [repairApproval, setrepairApproval] = useState('loading');
  const [priority90, setpriority90] = useState('loading');
  const [paymentGuarantee, setpaymentGuarantee] = useState('loading');

  const [coverageForrepairApproval, setcoverageForrepairApproval] = useState('loading');
  const [coverageForstandar, setcoverageForstandar] = useState('loading');
  const [coverageForpriority90, setcoverageForpriority90] = useState('loading');
  const [coverageForpaymentGuarantee, setcoverageForpaymentGuarantee] = useState('loading');
  // eslint-disable-next-line no-unused-vars
  const [vehicleList, refreshData, setPage, setSearch] = useData({
    params: {
      userId: user.user.UserFleet.id,
    },
    service: 'vehicle',
    type: 'vehicle',
  });

  const haveRepairApproval = async () => {
    const res = await getHaveService({
      service: 'repairApprovalSubscription',
      userId: user.user.UserFleet.id,
    });
    const formated = formatApiRes(res);

    if (formated.success && formated.data) {
      setrepairApproval('have');
    } else {
      setrepairApproval(false);
    }

    return true;
  };

  const havePriority90 = async () => {
    const res = await getHaveService({
      service: 'priority90Subscription',
      userId: user.user.UserFleet.id,
    });
    const formated = formatApiRes(res);

    if (formated.success && formated.data) {
      setpriority90('have');
    } else {
      setpriority90(false);
    }

    return true;
  };

  const havePaymentGuarantee = async () => {
    const res = await getHaveService({
      service: 'paymentguaranteeSubscription',
      userId: user.user.UserFleet.id,
    });
    const formated = formatApiRes(res);

    if (formated.success && formated.data) {
      setpaymentGuarantee('have');
    } else {
      setpaymentGuarantee(false);
    }

    return true;
  };

  const coverageRepairApproval = async () => {
    const res = await getcoverage({
      service: 'repairApprovalSubscription',
      userId: user.user.UserFleet.id,
    });

    const formated = formatApiCountRes(res);
    if (formated && formated.success) {
      setcoverageForrepairApproval(formated.count);
    }
  };

  const coverageStandar = async () => {
    const res = await getcoverage({
      service: 'standarRepairSubscription',
      userId: user.user.UserFleet.id,
    });

    const formated = formatApiCountRes(res);
    if (formated && formated.success) {
      setcoverageForstandar(formated.count);
    }
  };

  const coveragePriority90 = async () => {
    const res = await getcoverage({
      service: 'priority90Subscription',
      userId: user.user.UserFleet.id,
    });


    const formated = formatApiCountRes(res);
    if (formated && formated.success) {
      setcoverageForpriority90(formated.count);
    }
  };

  const coveragePaymentGuarantee = async () => {
    const res = await getcoverage({
      service: 'paymentguaranteeSubscription',
      userId: user.user.UserFleet.id,
    });


    const formated = formatApiCountRes(res);
    if (formated && formated.success) {
      setcoverageForpaymentGuarantee(formated.count);
    }
  };

  const startReq = async () => {
    await haveRepairApproval();
    await havePriority90();
    await havePaymentGuarantee();
  };

  useEffect(() => {
    startReq();
    coverageStandar();
  }, []);

  useEffect(() => {
    coverageRepairApproval();
  }, [repairApproval]);

  useEffect(() => {
    coveragePriority90();
  }, [priority90]);

  useEffect(() => {
    coveragePaymentGuarantee();
  }, [paymentGuarantee]);

  return (
    <Container style={style}>
      <SectionTitle>{getField({ base: strapiHomePage, field: 'service_title' })}</SectionTitle>
      {
        coverageForstandar !== 'loading' ? (
          <Fragment>
            <Cards_serviceCoverage
              paddingSmall
              hover
              icon={faTools}
              progress={
                vehicleList ? getPercent({ total: vehicleList && vehicleList.count ? vehicleList.count : 0, number: coverageForstandar || 0 }) : 0
              }
              value={coverageForstandar || 0}
              title={getField({ base: strapiStandartrepairService, field: 'service_title' })}
              labelProgress={getField({ base: strapiHomePage, field: 'coverage_label' })}
              labelCounter={getField({ base: strapiHomePage, field: 'vehicle_label' })}
              link
              to={ROUTING.fleet.dashboardStandardRepair.path}
            />
            <Grid__separator size="15px" />
          </Fragment>
        ) : coverageForstandar === 'loading' ? (
          <Fragment>
            <Cards_serviceCoverage loading />
            <Grid__separator size="15px" />
          </Fragment>
        ) : null
      }

      {
        repairApproval === 'have' && coverageForrepairApproval !== 'loading' ? (
          <Fragment>
            <Cards_serviceCoverage
              paddingSmall
              hover
              icon={faHandshakeAlt}
              progress={vehicleList ? getPercent({ total: vehicleList && vehicleList.count ? vehicleList.count : 0, number: coverageForrepairApproval || 0 }) : 0}
              value={coverageForrepairApproval || 0}
              title={getField({ base: strapiRepairApprovalService, field: 'service_title' })}
              labelProgress={getField({ base: strapiHomePage, field: 'coverage_label' })}
              labelCounter={getField({ base: strapiHomePage, field: 'vehicle_label' })}
              link
              to={ROUTING.fleet.dashboardRepairApproval.path}
            />
            <Grid__separator size="15px" />
          </Fragment>
        ) : repairApproval === 'loading' || coverageForrepairApproval === 'landing' ? (
          <Fragment>
            <Cards_serviceCoverage loading />
            <Grid__separator size="15px" />
          </Fragment>
        ) : null
      }

      {
        priority90 === 'have' && coverageForpriority90 !== 'loading' ? (
          <Fragment>
            <Cards_serviceCoverage
              paddingSmall
              hover
              icon={faHandshakeAlt}
              progress={vehicleList ? getPercent({ total: vehicleList && vehicleList.count ? vehicleList.count : 0, number: coverageForpriority90 || 0 }) : 0}
              value={coverageForpriority90 || 0}
              title={getField({ base: strapiPriority90Service, field: 'service_title' })}
              labelProgress={getField({ base: strapiHomePage, field: 'coverage_label' })}
              labelCounter={getField({ base: strapiHomePage, field: 'vehicle_label' })}
              link
              to={ROUTING.fleet.dashboardPriority90.path}
            />
            <Grid__separator size="15px" />
          </Fragment>
        ) : priority90 === 'loading' || coverageForpriority90 === 'landing' ? (
          <Fragment>
            <Cards_serviceCoverage loading />
            <Grid__separator size="15px" />
          </Fragment>
        ) : null
      }

      {
        paymentGuarantee === 'have' && coverageForpaymentGuarantee !== 'loading' ? (
          <Fragment>
            <Cards_serviceCoverage
              paddingSmall
              hover
              icon={faHandshakeAlt}
              progress={vehicleList ? getPercent({ total: vehicleList && vehicleList.count ? vehicleList.count : 0, number: coverageForpaymentGuarantee || 0 }) : 0}
              value={coverageForpaymentGuarantee || 0}
              title={getField({ base: strapiPaymentGuaranteeService, field: 'service_title' })}
              labelProgress={getField({ base: strapiHomePage, field: 'coverage_label' })}
              labelCounter={getField({ base: strapiHomePage, field: 'vehicle_label' })}
              link
              to={ROUTING.fleet.dashboardPaymentGuarantee.path}
            />
            <Grid__separator size="15px" />
          </Fragment>
        ) : paymentGuarantee === 'loading' || coverageForpaymentGuarantee === 'landing' ? (
          <Fragment>
            <Cards_serviceCoverage loading />
            <Grid__separator size="15px" />
          </Fragment>
        ) : null
      }
      <div>
        <Button__secondary
          content={getField({ base: strapiHomePage, field: 'button_see_all' })}
          href={ROUTING.fleet.myServices.path}
        />
      </div>
      <Grid__separator size="25px" />
    </Container>
  );
};

export default Home__service;
