// Page
export const strapiHomePage = { view: 'fleet', pageName: 'home' };
export const strapiAddServicePage = { view: 'fleet', pageName: 'addservice' };
export const strapiAccountPage = { view: 'fleet', pageName: 'account' };
export const strapiSingleServicePage = { view: 'fleet', pageName: 'singleservice' };
export const strapiHomeBasePage = { view: 'fleet', pageName: 'homebase' };
export const strapiMemberCardsPage = { view: 'fleet', pageName: 'membership' };
export const strapiMyFleetPage = { view: 'fleet', pageName: 'myfleet' };
export const strapiMapPage = { view: 'fleet', pageName: 'map' };
export const strapiMyServicePage = { view: 'fleet', pageName: 'myService' };

// service
export const strapiFairPriceService = { view: 'service', pageName: 'fairprice' };
export const strapiRepairApprovalService = { view: 'service', pageName: 'repairapprovals' };
export const strapiService24Service = { view: 'service', pageName: 'service24' };
export const strapiPriority90Service = { view: 'service', pageName: 'priority90' };
export const strapiStandartrepairService = { view: 'service', pageName: 'standardrepair' };
export const strapiPaymentGuaranteeService = { view: 'service', pageName: 'paymentguarantee' };
export const strapiSmartMaitenanceService = { view: 'service', pageName: 'smartmaintenance' };
export const strapiAlertGasoilService = { view: 'service', pageName: 'alertgasoil' };
export const strapiProFleetAssistService = { view: 'service', pageName: 'profleetassist' };
export const strapiTpmsService = { view: 'service', pageName: 'tpms' };

// other
export const strapiRegister = { view: 'other', pageName: 'register' };
export const strapiLogin = { view: 'other', pageName: 'login' };
export const strapiConditions = { view: 'other', pageName: 'conditions' };
export const strapiContract = { view: 'other', pageName: 'contract' };
export const strapiCase = { view: 'other', pageName: 'case' };
export const strapiAppointment = { view: 'other', pageName: 'appointment' };

// Global
export const strapiServiceHeader = 'service_table';

// workshop
export const strapiWorkshopHome = { view: 'workshop', pageName: 'home' };
export const strapiWorkshopCheck = { view: 'workshop', pageName: 'check' };
export const strapiWorkshopSingleVehicle = { view: 'workshop', pageName: 'singlevehicle' };
export const strapiWorkshopMyFleet = { view: 'workshop', pageName: 'myfleets' };
export const strapiWorkshopJoinServices = { view: 'workshop', pageName: 'joinservice' };
export const strapiWorkshopDashboardPaymentGuarantee = { view: 'workshop', pageName: 'dashboardpaymentguarantee' };
export const strapiWorkshopDashboardPriority90 = { view: 'workshop', pageName: 'dashboardpriority90' };
export const strapiWorkshopDashboardRepairApproval = { view: 'workshop', pageName: 'dashboardrepairapproval' };
export const strapiWorkshopDashboardStandardRepair = { view: 'workshop', pageName: 'dashboardstandardrepair' };
