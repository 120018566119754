/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

const Container = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'center', direction: 'row' })};
`;

const Title__container = styled.div``;

const Icon = styled.div`
  font-weight: 300;
  font-size: 25px;
  color: #4b4b4b;
`;

const Content = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #4b4b4b;
  max-width: 550px;
  padding-right: 10px;
  width: 100%;
  p {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 25px;
  line-height: 21px;
  color: #4b4b4b;
  margin-top: 20px;
`;

const Content__serviceConditions = ({
  style, icon, title, content,
}) => (
  <Container style={style}>
    <Title__container>
      <Icon>{icon}</Icon>
      <Title>{title}</Title>
    </Title__container>
    <Content>{content}</Content>
  </Container>
);

export default Content__serviceConditions;
