import React, {
  useContext, useEffect,
} from 'react';

import { ContentContext } from 'context/contentContext';
import { faBalanceScale } from '@fortawesome/pro-light-svg-icons';

import { strapiFairPriceService } from 'util/strapi/content.starpi';
import { ROUTING } from 'util/constant/routing.constant';
import { Cards__tool } from 'components/cards/cards';


const MyServices__fairPrice = ({ basePage }) => {
  const {
    loadPage, haveContentFor, getField,
  } = useContext(ContentContext);

  useEffect(() => {
    if (haveContentFor(strapiFairPriceService).success === false) {
      loadPage(strapiFairPriceService);
    }
  }, []);

  return (
    <Cards__tool
      icon={faBalanceScale}
      title={getField({ base: strapiFairPriceService, field: 'service_title' })}
      btnService={getField({ base: basePage, field: 'launch_button' })}
      btnServiceHref={`${ROUTING.fleet.dashboardFairPrice.path}?launch=-1`}
      btnRequest={getField({ base: basePage, field: 'dashboard_button' })}
      btnRequestHref={`${ROUTING.fleet.dashboardFairPrice.path}`}
    />
  );
};

export default MyServices__fairPrice;
