import axios from 'axios';

/**
 * Call get
 * @param {*} url
 * @param {*} params
 */

const refreshToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('logAs');
  window.location.reload();
};

const parseResolve = (res) => {
  if (res && res.data) {
    if (res.data.success === false && res.data.message === 'wrong token') {
      refreshToken();
    }
  }
};

export const CallGet = async (url, params, headers) => new Promise((resolve) => {
  axios.get(`${url}`, {
    params,
    headers,
  }).then((res) => {
    parseResolve(res);
    resolve(res);
  }).catch((err) => {
    resolve(err);
  });
});


/**
 * Call post
 * @param {*} url
 * @param {*} params
 */


export const CallPost = async (url, params, headers) => new Promise((resolve) => {
  axios.post(`${url}`, params, {
    headers,
  }).then((res) => {
    parseResolve(res);
    resolve(res);
  }).catch((err) => {
    resolve(err);
  });
});
/**
 * Call delete
 * @param {*} url
 * @param {*} params
 */


export const CallDelete = async (url, data, headers) => new Promise((resolve) => {
  axios.delete(`${url}`,
    {
      headers,
      data,
    }).then((res) => {
    parseResolve(res);
    resolve(res);
  }).catch((err) => {
    resolve(err);
  });
});


/**
 * Strapi get
 * @param {*} url
 * @param {*} params
 */

export const StrapiGet = async (url, params, headers) => new Promise((resolve) => {
  axios.get(`${url}`, {
    params,
    headers,
  }).then((res) => {
    resolve({
      success: true,
      res,
    });
  }).catch((err) => {
    if (err.hasOwnProperty('response')) {
      resolve({
        success: false,
        err: err.response,
      });
    } else {
      resolve({
        success: false,
        err,
      });
    }
  });
});
