/**
 * Import Lib & assets
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background: #e6f8fc;
  border-radius: 10px;
  display: inline-block;
  color: #01b5e2;
  transform: ${({ active }) => (active ? 'translateY(0)' : 'translateY(40px)')};
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: all ease 0.8s;
`;

const IconBtn = styled.a`
  margin-left: 20px;
  cursor: pointer;
  display: inline-block;
  transition: .3s ease all;
  transform: scale(1);
  transform-origin: center;
  &:hover {
    transform: scale(1.2);
  }
`;

const Grid__infoMsg = ({ style, content, className }) => {
  const [active, setactive] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setactive(true);
    }, 200);
  }, []);

  return (
    <Container style={style} active={active} className={className}>
      {content}
      <IconBtn onClick={(e) => {
        e.preventDefault();
        setactive(false);
      }}
      >
        <FontAwesomeIcon icon={faTimes} color="#01B5E2" />
      </IconBtn>
    </Container>
  );
};

export default Grid__infoMsg;
