
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import t from 'theme';
import { ContentContext } from 'context/contentContext';
import { formatApiRes } from 'util/function/formatApiRes.function';

/**
 * import containers & components
 */
import { useHistory, useParams } from 'react-router-dom';
import { Transition__loader_controller } from 'components/transition/transition';
import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Grid__separator, Grid__popup } from 'components/grid/grid';
import { strapiRegister, strapiMapPage } from 'util/strapi/content.starpi';
import Message__sucess from 'components/message/sucess/message__sucess';
import Content__externalLinks from 'components/content/externalLinks/content__externalLinks';
import Grid__ShapeBackground from 'components/grid/ShapeBackground/grid__ShapeBackground';
import RegisterFinal__form from 'containers/registerFinal/form/registerFinal__form';
import { getUserByUrl } from 'helpers/api/routes/User';
import { AppContext } from 'context/appContext';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { FleetContext } from 'context/fleetUser/fleetContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { strapiContract } from '../util/strapi/content.starpi';


const Wrapper = styled(Layout__wrapper)`
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 0;
`;

const Content = styled(Layout__content)`
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
`;

const ExternalLinks = styled(Content__externalLinks)`
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 50px;
`;

const Pop = styled(Grid__popup)`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Fleet_contact = styled.div`
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #01B5E2;
    margin-top: 25px;
    margin-bottom: 10px;
    display: inline-block;
    text-align: left;
    padding: 20px 30px;
    background: rgba(1,181,226,0.1);
    border-radius: 15px;
`;

const Fleet_contact_mail = styled.div`
    font-weight: 600;
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
    color: #333;
`;

const Icon = styled(FontAwesomeIcon)`
    margin-right: 10px;
`;

/**
 * @name RegisterFinal;
 */

const RegisterFinal = () => {
  const {
    loadPage, haveContentFor, getFieldWithNavigatorLang, changeLang, lang, getGlobalWithNavigatorLang,
  } = useContext(ContentContext);
  const { login } = useContext(AppContext);
  const { initFleetContext } = useContext(FleetContext);
  const [modal, setmodal] = useState(false);
  const [loading, setloading] = useState(false);
  const [credential, setcredential] = useState({ user: '', pass: '' });
  const [currentUser, setcurrentUser] = useState(false);
  const [step, setStep] = useState(false);

  const history = useHistory();
  const { validationUrl } = useParams();

  const getUserByUrlRes = async () => {
    const getUser = await getUserByUrl({ urlId: validationUrl });
    const formatedData = formatApiRes(getUser);
    if (formatedData.success === true) {
      setStep(formatedData.data.type);
      if (formatedData.data.user.PasswordSet === true || (formatedData.data.user.role === 'workshop' && formatedData.data.user.contract === true && formatedData.data.type === 'firststep')) {
        history.push('/login');
      } else {
        setcurrentUser(formatedData.data.user);
      }
    } else {
      history.push('/login');
    }
  };

  const loadContent = async () => {
    if (haveContentFor(strapiRegister).success === false) {
      await loadPage(strapiRegister);
    }
    if (haveContentFor(strapiMapPage).success === false) {
      await loadPage(strapiMapPage);
    }
    if (haveContentFor(strapiContract).success === false) {
      await loadPage(strapiContract);
    }
    getUserByUrlRes();
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (
    (haveContentFor(strapiRegister).success === false
    || haveContentFor(strapiMapPage).success === false
    || haveContentFor(strapiContract).success === false)
    && !currentUser
  ) {
    return <Transition__loader_controller active />;
  }


  const getDataAsync = async () => {
    const fleetCall = await initFleetContext();
    if (fleetCall.success === true) {
      return {
        success: true,
      };
    }
    return {
      success: false,
    };
  };
  const setLang = (data) => {
    if (data && data.data && data.data.data && data.data.data.user && data.data.data.user.lang) {
      if (lang !== data.data.data.user.lang) {
        changeLang(data.data.data.user.lang);
      }
    }
  };

  const handleLogin = async () => {
    if (step === 'firststep' && currentUser && currentUser.role === 'workshop') {
      setmodal(false);
      history.push('/');
    } else {
      setloading(true);
      const loginCall = await login({ mail: credential.user, pass: credential.pass });

      if (loginCall.success === true) {
        setLang(loginCall);
        const getData = await getDataAsync();
        if (getData.success === true) {
          history.push('/');
          // setloading(false);
        } else {
          setloading(false);
        }
      } else {
        setloading(false);
      }
    }
  };

  return (
    <Layout__container width="100%">
      <Transition__loader_controller active={false} />
      <Wrapper>
        <Content>
          <MainContainer>
            <Grid__separator size="70px" />
            <Logo src={t.image.global.logoBlue} />
            <RegisterFinal__form step={step} setmodal={() => setmodal(true)} setcredential={(value) => setcredential(value)} currentUser={currentUser} />
            <Grid__separator size="75px" />
            <ExternalLinks />
            <Grid__separator size="25px" />
            <Grid__ShapeBackground />
          </MainContainer>
        </Content>
      </Wrapper>
      <Pop active={modal} closeEvent={() => history.push('/')}>
        <Message__sucess
          icon={t.image.illu.check}
          title={step === 'firststep' && currentUser.role === 'workshop'
            ? getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_workshop_confirmation_title' })
            : getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_confirmation_title' })}
          text={step === 'firststep' && currentUser.role === 'workshop'
            ? getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_workshop_confirmation_text' })
            : getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_confirmation_text' })}
          buttonText={step === 'firststep' && currentUser.role === 'workshop'
            ? getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_workshop_confirmation_button' })
            : getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_confirmation_button' })}
          buttonEvent={handleLogin}
          buttonSecondary
          infoNode={(
            step === 'firststep' && currentUser.role === 'workshop' ? (
              <Fleet_contact>
                <Icon icon={faEnvelope} />
                {getGlobalWithNavigatorLang({ option: 'workshopInformation', field: 'fleet_contact' })}
                <Fleet_contact_mail>{currentUser.email}</Fleet_contact_mail>
              </Fleet_contact>
            ) : false
          )}
        />
        {loading ? <Loader__wrapper /> : false}
      </Pop>
    </Layout__container>
  );
};

export default RegisterFinal;
