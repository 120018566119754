/* eslint-disable react/button-has-type */

import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import Nav from 'components/global/nav';
import t from 'theme';
import { ContentContext } from 'context/contentContext';

import {
  faListOl, faQuestion, faBookOpen, faShoppingCart,
} from '@fortawesome/pro-light-svg-icons';
import Layout__container from 'containers/layout/container/layout__container';
import { Transition__loader_controller } from 'components/transition/transition';
import DashboardFairPrice__historic from 'containers/fleetView/dashboardFairPrice/historic/dashboardFairPrice__historic';
import DashboardFairPrice__action from 'containers/fleetView/dashboardFairPrice/action/dashboardFairPrice__action';
import DashboardFairPrice__infoPop from 'containers/fleetView/dashboardFairPrice/infoPop/dashboardFairPrice__infoPop';
import DashboardFairPrice__singlePop from 'containers/fleetView/dashboardFairPrice/singlePop/dashboardFairPrice__singlePop';
import DashboardFairPrice__formPop from 'containers/fleetView/dashboardFairPrice/formPop/dashboardFairPrice__formPop';
import { strapiFairPriceService, strapiSingleServicePage } from 'util/strapi/content.starpi';
import ServiceDashboard__layout from 'containers/fleetView/serviceDashboard/layout/serviceDashboard__layout';
import { ROUTING } from 'util/constant/routing.constant';
import { SERVICENAME } from 'util/service/serviceName';
import { AppContext } from 'context/appContext';
import { loadService, haveServiceContent } from 'util/function/loadContent.function';
import { getAction } from 'util/function/reloadAction.function';
import { formatApiCountRes } from 'util/function/formatApiRes.function';
import { getcoverage, getVehicleList } from 'helpers/api/routes/getData';
import { useData } from 'components/data/getter/data__getter';


/**
 * @name DashboardFairPrice
 */

const DashboardFairPrice = () => {
  const {
    loadPage, haveContentFor, getField, getGlobal,
  } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [activeinfoPop, setactiveinfoPop] = useState(false);
  const [activesinglePop, setactivesinglePop] = useState(false);
  const [activeLaunchPop, setactiveLaunchPop] = useState(false);
  const [coverage, setcoverage] = useState(0);
  const [, setactiveFormPop] = useState(false);
  const [requestSucces, setrequestSucces] = useState(false);
  const [selectLaunchInformation, setselectLaunchInformation] = useState(null);
  const launchRef = useRef(null);
  const formRef = useRef(null);
  let pageNumber = 1;
  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      id: user.user.UserFleet.id,
      user: true,
    },
    service: SERVICENAME.fairPrice,
    type: 'service',
  });


  const [fleetArray, setfleetArray] = useState([]);
  const getFleetList = async () => {
    const res = await getVehicleList({
      userId: user.user.UserFleet.id,
    });

    const formated = formatApiCountRes(res);
    if (formated.success) {
      setfleetArray(formated);
    }
  };

  const handleCheckParams = () => {
    const actionParams = getAction({ key: 'action' });
    if (actionParams.success === true) {
      setactivesinglePop({ active: true, id: actionParams.value });
    }
  };
  const loadContent = async () => {
    if (haveContentFor(strapiFairPriceService).success === false) {
      await loadPage(strapiFairPriceService);
    }
    if (haveContentFor(strapiSingleServicePage).success === false) {
      await loadPage(strapiSingleServicePage);
    }
    loadService({ haveFunc: haveContentFor, loadFunc: loadPage });

    const params = getAction({ key: 'launch' });
    if (params.success) {
      if (params.value === '-1' || params.value === -1) {
        setactiveLaunchPop(true);
      } else {
        setactiveLaunchPop({ active: true, id: params.value });
      }
    }
    handleCheckParams();
  };

  const loadCoverage = async () => {
    const res = await getcoverage({
      service: 'fairPriceSubscription',
      userId: user.user.UserFleet.id,
    });

    const formatedCoverage = formatApiCountRes(res);

    setcoverage(formatedCoverage.count);
  };
  useEffect(() => {
    loadContent();
    loadCoverage();
    getFleetList();
  }, []);


  useEffect(() => {
    if (activeLaunchPop === true) {
      if (formRef && formRef.current) {
        formRef.current.handleCleanHook();
        setselectLaunchInformation(null);
        setactiveFormPop(false);
      }
      if (launchRef && launchRef.current) {
        launchRef.current.handleClean();
      }
    }
  }, [activeLaunchPop]);

  if (
    haveContentFor(strapiFairPriceService).success === false
    || haveContentFor(strapiSingleServicePage).success === false
    || haveServiceContent({ haveFunc: haveContentFor }) === false
  ) {
    return <Transition__loader_controller active />;
  }

  const handleCloseInfoPop = () => {
    setactiveinfoPop(false);
  };

  const handleOpenInfoPop = () => {
    setactiveinfoPop(true);
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({ active: false, id: null });
  };

  const handleOpenSinglePop = ({ id }) => {
    setactivesinglePop({ active: true, id });
  };

  const handleCloseLaunchPop = () => {
    if (requestSucces) {
      if (formRef && formRef.current) {
        formRef.current.handleReloadHook();
      }
    } else {
      setactiveLaunchPop(false);
    }
  };

  const handleOpenLaunchPop = () => {
    setactiveLaunchPop(true);
  };

  const handleOpenFormPop = (selectedInformation) => {
    setselectLaunchInformation(selectedInformation);
    setactiveFormPop(true);
  };

  const handlePrevAction = () => {
    if (launchRef && launchRef.current) {
      launchRef.current.handlePrevHook('workshop');
    }
  };

  const renderInfoPopProps = () => ({
    intro: {
      title: getField({ base: strapiFairPriceService, field: 'service_title' }),
      txt: getField({ base: strapiFairPriceService, field: 'description' }),
      price: `0,00 ${getField({ base: strapiSingleServicePage, field: 'currency' })}`,
      label: getField({ base: strapiSingleServicePage, field: 'price_detail' }),
      banner: t.image.global.service_image_1,
    },
    condition1: {
      content: getField({ base: strapiFairPriceService, field: 'registration_processs' }),
      title: getField({ base: strapiSingleServicePage, field: 'registration_processs' }),
      icon: faListOl,
    },
    condition2: {
      content: getField({ base: strapiFairPriceService, field: 'how' }),
      title: getField({ base: strapiSingleServicePage, field: 'how' }),
      icon: faQuestion,
    },
    condition3: {
      content: getField({ base: strapiFairPriceService, field: 'conditions' }),
      title: getField({ base: strapiSingleServicePage, field: 'conditions' }),
      icon: faBookOpen,
    },
    condition4: {
      content: getField({ base: strapiFairPriceService, field: 'payment' }),
      title: getField({ base: strapiSingleServicePage, field: 'payment' }),
      icon: faShoppingCart,
    },
  });

  const handleTableClick = (line, vehicle) => {
    handleOpenSinglePop({ id: vehicle.id });
  };

  const handleisRequestSucces = () => {
    setrequestSucces(true);
  };

  const handleLoadNewPage = () => {
    if (resData.data && !resData.loading && pageNumber + 1 > 1 && resData.data.length < resData.count) {
      pageNumber += 1;
      setPage(pageNumber, resData.data);
    }
  };

  return (
    <Layout__container>
      <Nav active="myservices" />
      <Transition__loader_controller active={false} />
      <ServiceDashboard__layout
        page_title={getField({ base: strapiFairPriceService, field: 'service_title' })}
        // breadCrumb
        breadCrumb_parent={getGlobal({ option: 'fleet_menu', field: 'my_services' })}
        breadCrumb_parentLink={ROUTING.fleet.myServices.path}
        breadCrumb_current={getField({ base: strapiFairPriceService, field: 'service_title' })}
        // Service history layout
        history={(
          <DashboardFairPrice__historic
            tableEvent={handleTableClick}
            loadingData={resData.loading}
            data={resData.data}
            scrollEndEvent={handleLoadNewPage}
            loadingNewPage={resData.loading}
          />
        )}
        // Action side element
        action={(
          <DashboardFairPrice__action
            infoEvent={handleOpenInfoPop}
            launchServicePop={handleOpenLaunchPop}
            coverage={coverage}
            fleet={fleetArray ? fleetArray.count : 0}
          />
        )}
        // Info pop up
        activeInfoPop={activeinfoPop}
        closeInfoPop={handleCloseInfoPop}
        infoPopProps={renderInfoPopProps()}
        infoPop={<DashboardFairPrice__infoPop />}
        // Single pop up
        activeSinglePop={activesinglePop}
        closeSinglePop={handleCloseSinglePop}
        singlePop={(
          <DashboardFairPrice__singlePop
            active={activesinglePop.active}
            id={activesinglePop.id}
            closeEvent={handleCloseSinglePop}
          />
        )}
        // Launch request pop up
        launchRequest
        launchTitle={getField({ base: strapiFairPriceService, field: 'request_form_title' })}
        launchRef={launchRef}
        activeLaunchPop={activeLaunchPop}
        closeLaunchPop={handleCloseLaunchPop}
        nextEventLaunchPop={handleOpenFormPop}
        serviceName={SERVICENAME.fairPrice}
        requestSucces={requestSucces}
        // launch request form pop uo
        formPop={(
          <DashboardFairPrice__formPop
            vehicle={selectLaunchInformation}
            closeEvent={handleCloseLaunchPop}
            prevEvent={handlePrevAction}
            isRequestSucces={handleisRequestSucces}
            ref={formRef}
          />
        )}
        // step indicator
        Step1Title={getField({ base: strapiFairPriceService, field: 'step1_title' })}
        Step2Title={getField({ base: strapiFairPriceService, field: 'step2_title' })}
        Step3Title={getField({ base: strapiFairPriceService, field: 'step3_title' })}
      />
    </Layout__container>
  );
};

export default DashboardFairPrice;
