
/**
   * Import Lib & assets
   */

import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Button__primary from 'components/button/primary/button__primary';


const Container = styled.div`
  font-weight: normal;
  font-size: 14px;
`;

const Btn = styled(Button__primary)`
  margin-left: 15px;
  min-width: 110px;
  &:hover {
    color: #01b5e2;
  }
`;

const Content__signUpLink = ({
  style, content, buttonContent, link,
}) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(link);
  };

  return (
    <Container style={style}>
      {content}
      <Btn
        event={handleClick}
        border
        white
      >
        {buttonContent}
      </Btn>
    </Container>
  );
};

export default Content__signUpLink;
