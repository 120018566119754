/* eslint-disable */
import FullCalendar from "@fullcalendar/react";
import styled from 'styled-components';
import dayGridPlugin from "@fullcalendar/daygrid";
// import frLocale from "@fullcalendar/core/locales/fr";
import { ContentContext } from 'context/contentContext'
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useRef, useState, useMemo, useContext } from "react";
import allLocales from '@fullcalendar/core/locales-all';
import {
  sendViewType,
  sendDateRange,
} from "./Utils";

const Calendar = styled.div`
  height: 100%;
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 15px;
}
  .fc .fc-button {
    height: auto;
    padding: 5px 9px;
  }
  .fc .fc-timegrid-event {
    background-color: #01B5E2;
    border: 1px solid #01B5E2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fc .fc-timegrid-main {
    font-weight: 500;
  }
  .fc .fc-button {
    background-color: #01B5E2;
    border: none;
  }
  .fc .fc-button-primary {
    background-color: #01B5E2;
  }
  .fc .fc-today-button:disabled {
    background: #01B5E2;
  }
  .fc .fc-timegrid-col.fc-day-today {
    background-color: rgba(1,181,226, 0.1) !important;
  }
  .fc .fc-day-today {
    background-color: rgba(1,181,226, 0.1) !important;
  }
  .fc .fc-event {
    cursor: pointer;
  }
  .fc .fc-toolbar-title {
    font-weight: 600;
    font-size: 19px;
  }
  .fc .fc-event-main {
    font-weight: 600;
    line-height: normal;
  }
  .fc .fc-button-active {
    background-color: #0395B9 !important;
  }
  .fc .fc-daygrid-event {
    background-color: #01B5E2;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    &.event-done {
      border-color: #2ECC71;
      background-color: #2ECC71;
    }
    &.event-waiting {
      border-color: #FFAA29;
      background-color: #FFAA29;
    }
    &.event-refused {
      border-color: #E74C3C;
      background-color: #E74C3C;
    }
    &.event-approved {
      border-color: #01B5E2;
      background-color: #01B5E2;
    }
  }
`;

const useCalendar = ({
  externalToolBar,
  toolBarCustomActions,
  showToolBarDate,
  className,
  ...calendarOptions
}) => {
  const { lang } = useContext(ContentContext);
  const calendarRef = useRef(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const onDatesSet = ({ start: _start, end: _end }) => {
    start?.getTime() !== _start.getTime() && setStart(_start);
    end?.getTime() !== _end.getTime() && setEnd(_end);
  };

  const renderCalendar = () => (
    <Calendar>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          start: 'prev,next today',
          center: 'title',
          end: 'timeGridWeek,dayGridMonth'
        }}
        initialView="timeGridWeek"
        locales={allLocales}
        locale={lang}
        allDaySlot={false}
        height="100%"
        expandRows={true}
        selectable={true}
        longPressDelay={500}
        unselectAuto={false}
        selectMirror={true}
        selectOverlap={false}
        slotLabelInterval={{ hours: 1 }}
        datesSet={onDatesSet}
        slotMinTime={"7:00"}
        slotMaxTime={"19:00"}
        eventAdd={calendarOptions.eventAdd}
        {...calendarOptions}
      />
      </Calendar>
  );

  const SafeRenderCalendar = useMemo(renderCalendar, [calendarOptions, start, end]);

  return [calendarRef, SafeRenderCalendar, sendViewType(calendarRef), sendDateRange(calendarRef)];
};

export default useCalendar;
