
/**
   * Import Lib & assets
   */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

const Container = styled.img`
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
  user-select: none;
  pointer-events: none;
`;

const Grid__ShapeBackground = ({ style }) => (
  <Container style={style} src={t.image.global.gb} />
);

export default Grid__ShapeBackground;
