export const hasFieldError = (base, field, message, noMessage) => {
  if (base) {
    if (base.hasOwnProperty(field)) {
      if (noMessage) {
        return { active: true };
      }
      if (message) {
        return { active: true, message };
      }
      if (base[field].hasOwnProperty('message')) {
        return { active: true, message: base[field].message };
      }
      return { active: true };
    }
  }

  return false;
};

export const hasFieldErroArray = (base, field, message, noMessage) => {
  let toReturn = false;
  if (base) {
    field.forEach((singleField) => {
      if (base.hasOwnProperty(singleField)) {
        if (noMessage) {
          toReturn = { active: true };
        } else if (message) {
          toReturn = { active: true, message };
        } else if (base[singleField].hasOwnProperty('message')) {
          toReturn = { active: true, message: base[singleField].message };
        } else { toReturn = { active: true }; }
      }
    });
  }

  return toReturn;
};
