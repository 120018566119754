
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { checkFieldValue, createArrayOfData, createResponseObj } from 'helpers/js/Form';
/**
 * import containers & components
 */
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import PhoneDial from 'helpers/data/PhoneDial.json';

import Button__primary from 'components/button/primary/button__primary';
import { Grid__separator } from 'components/grid/grid';
import { Content__sectionTitle } from 'components/content/content';
import {
  Form__textfield, Form__label, Form__select, Form__combined,
} from 'components/form/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { faSave } from '@fortawesome/pro-regular-svg-icons';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { createErrorObject } from 'util/conditions/checkField.condition';
import { hasFieldError } from 'util/conditions/haveErrorField.conditions';


/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const FormContainer = styled.div``;

const RowField = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  justify-content: flex-start;
`;

/**
 * information component is..
 */

const Account__information = ({ style, basePage }) => {
  const { getField, getGlobal } = useContext(ContentContext);
  const { user, updateUser } = useContext(AppContext);
  const [activeButton, setActiveButton] = useState(false);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);

  /* ------------------------------ DEFINE STATE ------------------------------ */
  const [genderSelector] = useState([
    {
      label: getGlobal({ option: 'gender', field: 'mr' }),
      slug: 'mr',
      active: true,
    },
    {
      label: getGlobal({ option: 'gender', field: 'mrs' }),
      slug: 'mrs',
      active: false,
    },
  ]);
  const [phoneDialSelector, setPhoneDialSelector] = useState([]);

  const [state, setState] = useState({
    name: { value: '' },
    lastname: { value: '' },
    mail: { value: '' },
    tel: { value: '' },
    gender: genderSelector,
    phoneDial: phoneDialSelector,
    result: {
      name: { result: '', type: 'text' },
      lastname: { result: '', type: 'text' },
      mail: { result: '', type: 'text' },
      tel: { result: '', type: 'text' },
      gender: { result: genderSelector[0], type: 'select' },
      phoneDial: { result: phoneDialSelector[0], type: 'select' },
    },
  });

  /* --------------------------- SET DIAL FROM JSON --------------------------- */

  const setDialJson = () => {
    const obj = [];

    PhoneDial.data.forEach((element, key) => {
      obj.push({
        label: element.dial_code,
        slug: element.dial_code.replace('+', ''),
        active: key === 0,
      });
    });

    setState((prevState) => ({
      ...prevState,
      phoneDial: obj,
      result: {
        ...prevState.result,
        phoneDial: { result: obj[0], type: 'select' },
      },
    }));
    setPhoneDialSelector(obj);
  };

  /* ---------------------------- SET DATA FROM API --------------------------- */

  const setinitialDate = () => {
    setState((prevState) => ({
      ...prevState,
      name: checkFieldValue({ field: user.user.firstName, type: 'text' }).base,
      lastname: checkFieldValue({ field: user.user.lastName, type: 'text' }).base,
      mail: checkFieldValue({ field: user.user.email, type: 'text' }).base,
      tel: checkFieldValue({ field: user.user.phoneNumber, type: 'text' }).base,
      gender: checkFieldValue({
        field: user.user.gender,
        type: 'select',
        additionalCondition: 'slug',
        base: genderSelector,
      }).base,
      phoneDial: checkFieldValue({
        field: user.user.phoneCode,
        type: 'select',
        additionalCondition: 'slug',
        base: phoneDialSelector,
      }).base,
      result: {
        ...prevState.result,
        name: checkFieldValue({ field: user.user.firstName, type: 'text' }).result,
        lastname: checkFieldValue({ field: user.user.lastName, type: 'text' }).result,
        mail: checkFieldValue({ field: user.user.email, type: 'text' }).result,
        tel: checkFieldValue({ field: user.user.phoneNumber, type: 'text' }).result,
        gender: checkFieldValue({
          field: user.user.gender,
          type: 'select',
          additionalCondition: 'slug',
          base: genderSelector,
        }).result,
        phoneDial: checkFieldValue({
          field: user.user.phoneCode,
          type: 'select',
          additionalCondition: 'slug',
          base: phoneDialSelector,
        }).result,
      },
    }));
  };

  /* --------------------- USE EFFECT FORM POPULATE STATE --------------------- */

  useEffect(() => {
    setDialJson();
  }, []);

  useEffect(() => {
    if (phoneDialSelector.length > 0) {
      setinitialDate();
    }
  }, [phoneDialSelector]);

  /* -------------------------- CHECK STATUS OF FIELD ------------------------- */

  const checkSaveStatus = () => {
    const arrayOfField = [
      state.result.name,
      state.result.lastname,
      state.result.mail,
      state.result.tel,
      state.result.gender,
      state.result.phoneDial,
    ];

    const data = createArrayOfData({ array: arrayOfField });

    if (data.filter((el) => el === null || el === '').length <= 0) {
      setActiveButton(true);
    } else if (activeButton) {
      setActiveButton(false);
    }
  };

  useEffect(() => {
    if (phoneDialSelector.length > 0) {
      checkSaveStatus();
    }
  }, [state]);

  /* --------------------------- STATE CHANGE EVENT --------------------------- */

  const handleStateChange = ({
    property, value, result, type,
  }) => {
    const currentState = state;

    currentState.result[property] = { result, type };

    setState((prevState) => ({
      ...prevState,
      [property]: type === 'text' ? { value } : value,
      result: {
        ...prevState.result,
        [property]: { result, type },
      },
    }));
  };

  /* ------------------------------- SAVE EVENT ------------------------------- */


  const checkField = () => {
    let errorObj = {};
    let errorArray = {};

    const baseArrayField = [
      {
        key: 'email',
        type: 'email',
        value: state.result.mail.result,
      },
      {
        key: 'gender',
        type: 'simple',
        value: state.result.gender.result,
      },
      {
        key: 'firstName',
        type: 'name',
        value: state.result.name.result,
      },
      {
        key: 'lastName',
        type: 'name',
        value: state.result.lastname.result,
      },
      {
        key: 'tel',
        type: 'phoneLib',
        value: state.tel,
      },
    ];


    errorObj = createErrorObject(errorArray, baseArrayField, errorObj.hasError, getGlobal);
    errorArray = errorObj.object;
    seterror(errorArray);
    return errorObj.hasError;
  };

  const saveEvent = async () => {
    if (!checkField()) {
      setloading(true);
      const arrayOfField = [
        { value: state.result.name, key: 'firstName' },
        { value: state.result.lastname, key: 'lastName' },
        { value: state.result.mail, key: 'email' },
        { value: state.result.tel, key: 'phoneNumber' },
        { value: state.result.gender, key: 'gender' },
      ];


      const data = createResponseObj({ data: arrayOfField });

      await updateUser({ field: data });
      setloading(false);
    }
  };

  /* ------------------------------- RENDER FORM ------------------------------ */

  const renderContactInformationForm = () => (
    <FormContainer>
      <Form__textfield
        change={(res) => handleStateChange({
          property: 'name',
          value: res.value,
          result: res.value,
          type: 'text',
        })}
        require
        label={getField({ base: basePage, field: 'firstname' })}
        placeholder={getField({ base: basePage, field: 'firstname' })}
        value={state.name.value}
        property="name"
        style={{ width: '100%' }}
        error={hasFieldError(error, 'name')}
      />
      <Grid__separator size="25px" />
      <Form__label require>{getField({ base: basePage, field: 'lastname' })}</Form__label>
      <RowField>
        <Form__select
          value={state.gender}
          style={{ width: 130, maxWidth: '100%', marginRight: 10 }}
          event={(res) => handleStateChange({
            property: 'gender',
            value: res.value,
            result: res.result,
            type: 'select',
          })}
          error={hasFieldError(error, 'gender')}
        />
        <Form__textfield
          change={(res) => handleStateChange({
            property: 'lastname',
            value: res.value,
            result: res.value,
            type: 'text',
          })}
          value={state.lastname.value}
          property="lastname"
          placeholder={getField({ base: basePage, field: 'lastname' })}
          require
          error={hasFieldError(error, 'lastname')}
        />
      </RowField>
      <Grid__separator size="25px" />
      <Form__textfield
        change={(res) => handleStateChange({
          property: 'mail',
          value: res.value,
          result: res.value,
          type: 'text',
        })}
        value={state.mail.value}
        property="mail"
        label={`${getField({ base: basePage, field: 'email' })}`}
        placeholder={getField({ base: basePage, field: 'email' })}
        require
        error={hasFieldError(error, 'mail')}
      />
      <Grid__separator size="25px" />
      <Form__combined
        handleTextChange={(isValide, res, final) => handleStateChange({
          property: 'tel',
          value: { value: final, valide: isValide },
          result: { value: final, valide: isValide },
          type: 'tel',
        })}
        textValue={state.tel.value}
        property="tel"
        label={getField({ base: basePage, field: 'phone' })}
        require
        error={hasFieldError(error, 'tel')}
      />
      <Grid__separator size="25px" />
      <Button__primary
        withIcon
        icon={<FontAwesomeIcon icon={faSave} />}
        event={() => saveEvent()}
        style={
          activeButton === false
            ? {
              cursor: 'intial',
              pointerEvents: 'none',
              userSelect: 'none',
              opacity: 0.3,
            }
            : {}
        }
      >
        {getField({ base: basePage, field: 'save_button_contact' })}
      </Button__primary>
    </FormContainer>
  );

  /* ------------------------------ GLOBAL RENDER ----------------------------- */

  return (
    <Container style={style}>
      <Content__sectionTitle
        title={getField({ base: basePage, field: 'contact_title' })}
        icon={<FontAwesomeIcon icon={faUser} />}
      />
      {renderContactInformationForm()}
      {loading && (<Loader__wrapper fixedContainer fullWidth />)}
    </Container>
  );
};

export default Account__information;
