
/**
   * Import Lib & assets
   */

import React, {
  Fragment, useState, useEffect, useContext,
} from 'react';
import styled from 'styled-components';
import Form__textfield from 'components/form/textfield/form__textfield';
import Button__primary from 'components/button/primary/button__primary';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid__separator from 'components/grid/separator/grid__separator';
import t from 'theme';
import { CheckVehicle } from 'helpers/api/routes/Fleets';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { AppContext } from 'context/appContext';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { isVehicleApprover, isVehicleHomeBase } from 'util/workshop/getVehicleObjectInformation';
import Grid__card from 'components/grid/card/grid__card';
import { ContentContext } from 'context/contentContext';
import { List__table } from 'components/list/list';
import { sequelizeDate } from 'util/date.util';
import Grid__popup from 'components/grid/popup/grid__popup';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';
import List__content from 'components/list/content/list__content';


const Cards = styled(Grid__card)`
    width: 100%;
    text-align: center;
    position: relative;
    padding: 30px;
  `;

const Container = styled.div``;

const Illu = styled.img`
    width: 70px;
  `;

const Header = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'row' })}
`;

const Title = styled.div`
  margin-left: 20px;
  font-weight: 600;
  font-size: 26px;
  text-align: left;
  text-transform: uppercase;
  color: #01B5E2;
`;

const ErrorText = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #E74C3C;
`;

const Content = styled.div`
  margin-top: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: #4D4D4D;
  margin-bottom: 25px;
`;

const Button = styled(Button__primary)`
  width: 100%;
`;


const Table = styled(List__table)`
  height: calc(100% - 70px);
`;

const ContainerPopUpContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;


const Cards__checkLaunchService = ({
  style, title, content, handleCheck, buttonContent, base, serviceName,
}) => {
  const { user } = useContext(AppContext);
  const { getGlobal } = useContext(ContentContext);
  const [loading, setloading] = useState(false);
  const [, setOpenPopUp] = useState(false);
  const [error, seterror] = useState(false);
  const [errorSubscription, seterrorSubscription] = useState(false);
  const [errorAction, seterrorAction] = useState(false);
  const [registration, setregistration] = useState('');
  const [shouldRenderSelectModal, setshouldRenderSelectModal] = useState(false);
  const [vehicleList, setvehicleList] = useState(false);
  const [vehicleListFormated, setvehicleListFormated] = useState([]);

  const handleInputChange = (value) => {
    setregistration(value.value);
  };

  const headerCol = [
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'vincode' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'category' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'brand' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
    },
    {
      label: '',
    },
  ];


  const checkService = (data) => {
    if (serviceName) {
      if (serviceName === 'priority90' && data.priority90Subscription) {
        return true;
      }
      if (serviceName === 'payment' && data.paymentguaranteeSubscription) {
        return true;
      }
      if (serviceName === 'repairApproval' && data.repairApprovalSubscription) {
        return true;
      }
      if (serviceName === 'standarRepair' && data.standarRepairSubscription) {
        return true;
      }

      return false;
    }

    return true;
  };

  const checkAction = (data) => {
    if (serviceName === 'repairApproval' && data.repairApprovalSubscription) {
      if (isVehicleApprover(data, user)) {
        return false;
      }
    }
    if (serviceName === 'payment' && data.paymentguaranteeSubscription) {
      if (isVehicleHomeBase(data, user)) {
        return false;
      }
    }

    return true;
  };

  const handleSelect = (key) => {
    if (handleCheck) {
      if (checkAction(vehicleList[key])) {
        if (checkService(vehicleList[key])) {
          handleCheck(vehicleList[key]);
          setOpenPopUp(true);
          setshouldRenderSelectModal(false);
        } else {
          seterrorSubscription(true);
        }
      } else {
        seterrorAction(true);
      }
    }
  };


  const renderChoiceModal = (data) => {
    const array = [];
    data.forEach((element) => {
      if (!element.deleted) {
        array.push(element);
      }
    });
    setshouldRenderSelectModal(true);
    setvehicleList(data);
  };

  const handleCheckVehicle = async (registrationNumber) => {
    seterror(false);
    seterrorSubscription(false);
    seterrorAction(false);
    if (registrationNumber && registrationNumber !== '') {
      setloading(true);
      seterror(false);
      const res = await CheckVehicle(registrationNumber.toUpperCase());
      const formated = formatApiRes(res);
      if (formated) {
        setloading(false);
        if (formated.success === true) {
          if (formated.success === true && formated.data && formated.data.length > 0) {
            if (formated.data && formated.data.length > 1) {
              renderChoiceModal(formated.data);
            } else if (formated.data && formated.data.length === 1) {
              if (formated.data[0].deleted) {
                seterror(true);
              } else if (handleCheck) {
                if (checkAction(formated.data[0])) {
                  if (checkService(formated.data[0])) {
                    handleCheck(formated.data[0]);
                  } else {
                    seterrorSubscription(true);
                  }
                } else {
                  seterrorAction(true);
                }
              }
              setOpenPopUp(true);
            }
          } else {
            seterror(true);
          }
        }
      } else {
        seterror(true);
      }
    }
  };

  const handleCheckClick = async () => {
    handleCheckVehicle(registration);
  };


  const handleCloseSelectModal = () => {
    setshouldRenderSelectModal(false);
  };


  useEffect(() => {
    if (base) {
      handleCheckVehicle(base);
    }
  }, [base]);

  useEffect(() => {
    if (vehicleList.length > 0) {
      const obj = [];
      const noFormatedObj = [];
      const orderArray = vehicleList;
      orderArray.forEach((element, key) => {
        const childObj = [];

        childObj.push({ label: <List__content uppercase>{element.registrationNumber}</List__content> });
        childObj.push({
          label: (
            <List__content capitalize>
              {getGlobal({ option: 'country', field: element.countryOfRegistration })
                || element.countryOfRegistration}
            </List__content>
          ),
        });
        childObj.push({ label: <List__content uppercase>{element.vin}</List__content> });
        childObj.push({
          label: (
            <List__content capitalize>
              {getGlobal({ option: 'vehicletype', field: element.type }) || element.type}
            </List__content>
          ),
        });
        childObj.push({ label: <List__content capitalize>{element.brand}</List__content> });
        childObj.push({
          label: (
            <List__content>{sequelizeDate({ date: element.firstDate, format: 'd/m/y' })}</List__content>
          ),
        });

        childObj.push({ label: <Button__primary event={() => handleSelect(key)} withIcon iconEnd icon={<FontAwesomeIcon icon={faArrowRight} />}>{getGlobal({ option: 'vehicleParameter', field: 'button_select' })}</Button__primary> });

        obj.push(childObj);
        noFormatedObj.push(element);
      });

      setvehicleListFormated(obj);
    }
  }, [vehicleList]);

  return (
    <Fragment>
      <Cards>
        <Container style={style}>
          <Header>
            <Illu src={t.image.illu.tools} />
            <Title>{title}</Title>
          </Header>
          <Content>{content}</Content>
          <Form__textfield
            change={handleInputChange}
            value={registration}
            placeholder="EF547GY"
            require
            label={getGlobal({ option: 'check_vehicle', field: 'input_label' })}
          />
          {
            errorSubscription && (
              <Fragment>
                <Grid__separator size="10px" />
                <ErrorText>{getGlobal({ option: 'check_vehicle', field: 'error_not_register' })}</ErrorText>
              </Fragment>
            )
          }
          {
            error ? (
              <Fragment>
                <Grid__separator size="10px" />
                <ErrorText>{getGlobal({ option: 'check_vehicle', field: 'error_not_found' })}</ErrorText>
              </Fragment>
            ) : null
          }
          {
            errorAction ? (
              <Fragment>
                <Grid__separator size="10px" />
                <ErrorText>{getGlobal({ option: 'check_vehicle', field: 'error_cant_perfom_action' })}</ErrorText>
              </Fragment>
            ) : null
          }
          <Grid__separator size="25px" />
          <Button
            withIcon
            icon={<FontAwesomeIcon icon={faArrowRight} />}
            iconEnd
            event={handleCheckClick}
          >
            {buttonContent}
          </Button>
        </Container>
        {loading && <Loader__wrapper absolute />}
      </Cards>
      <Grid__popup active={shouldRenderSelectModal} closeEvent={handleCloseSelectModal}>
        <ContainerPopUpContent>
          <Content__cardTitle content={getGlobal({ option: 'check_vehicle', field: 'error_not_multiple_vehicle' })} />
          <Grid__separator size="15px" />
          <Content small>{getGlobal({ option: 'check_vehicle', field: 'error_not_multiple_action' })}</Content>
          {
            errorSubscription && (
              <Fragment>
                <Grid__separator size="10px" />
                <ErrorText>{getGlobal({ option: 'check_vehicle', field: 'error_not_register' })}</ErrorText>
              </Fragment>
            )
          }
          {
            errorAction ? (
              <Fragment>
                <Grid__separator size="10px" />
                <ErrorText>{getGlobal({ option: 'check_vehicle', field: 'error_cant_perfom_action' })}</ErrorText>
              </Fragment>
            ) : null
          }
          <Grid__separator size="35px" />
          {
            shouldRenderSelectModal && (
              <Table headerCol={headerCol} lineCol={vehicleListFormated} noSelect />
            )
          }
        </ContainerPopUpContent>
      </Grid__popup>
    </Fragment>
  );
};

export default Cards__checkLaunchService;
