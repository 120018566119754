/* eslint-disable */
import React, {
  useContext, useState, useEffect, Fragment,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import { Grid__separator, Grid__popup } from 'components/grid/grid';
import { Cards__vehicle } from 'components/cards/cards';
import { Content__sectionTitle, Content__servicesTag } from 'components/content/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content__tag from 'components/content/tag/content__tag';
import { List__table, List__content } from 'components/list/list';
import { ContentContext } from 'context/contentContext';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { sequelizeDate } from 'util/date.util';
import { sequelizeMileage } from 'util/mileage.util';
import { ROUTING } from 'util/constant/routing.constant';
import {
  faCreditCard,
  faMoneyBillWave,
  faUniversity,
  faGasPump,
} from '@fortawesome/pro-light-svg-icons';
import {
  shouldRenderStandarRepair,
  shouldRenderRepairApproval,
  shouldRenderPriotity90,
  souldRenderPaymentguarantee,
} from 'util/service/createHistoric';
import {
  strapiServiceHeader,
  strapiRepairApprovalService,
  strapiPriority90Service,
  strapiPaymentGuaranteeService,
  strapiStandartrepairService,
  strapiWorkshopSingleVehicle,
  strapiRegister,
  strapiCase
} from 'util/strapi/content.starpi';
import { AppContext } from 'context/appContext';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import {
  faTruck,
  faWarehouse,
  faHouse,
  faListUl,
  faHandshake,
  faMoneyCheck,
} from '@fortawesome/pro-solid-svg-icons';
import Content__labelText from 'components/content/labelText/content__labelText';
import {
  faStopwatch,
  faHandshakeAlt,
  faShieldCheck,
  faTools,
} from '@fortawesome/pro-regular-svg-icons';
import {
  getHomeBaseKeyFromVehicle,
  getFleetUserKeyFromVehicle,
  isVehicleHomeBase,
  isVehicleApprover,
} from 'util/workshop/getVehicleObjectInformation';
import { useData } from 'components/data/getter/data__getter';
import { Form__textfield } from 'components/form/form';
import Button__primary from 'components/button/primary/button__primary';
import Message__sucess from 'components/message/sucess/message__sucess';
import { SendMail } from 'helpers/api/routes/Mail';
import { useHistory } from 'react-router-dom';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { FleetContext } from 'context/fleetUser/fleetContext';
import {
  FLEET_PAYMENT,
  parsePaymentMethods,
  FLEET_EXTRA_PAYMENT,
} from 'util/constant/fleetPayment';
import Cards__iconCard from 'components/cards/iconCard/cards__iconCard';
import { CreateCase } from '../../../../helpers/api/routes/Cases';
import { renderCasesIcon } from '../../../../util/Case';
import { CheckVehicleId } from '../../../../helpers/api/routes/Fleets';

const Container = styled.div``;

const Header = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'row' })}
`;

const Title = styled.div`
  font-weight: normal;
  font-size: 36px;
  line-height: 49px;
  color: #4d4d4d;
  margin-right: 20px;
`;

const ServiceStatusTitle = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
`;

const ContainerCenter = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', direction: 'column' })}
`;

const Column = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', direction: 'column' })}
`;

const Row = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-start', direction: 'row' })}
`;

const Table = styled(List__table)`
  width: 100%;
  max-height: 360px;
`;

const PaymentCard__info = styled.div`
  margin-top: 15px;
`;

const VehicleSection = styled.div`
  width: 100%;
  ${t.mixins.flex({
  justify: 'center',
  align: 'center',
})};
`;

const FormContainer = styled.div`
  position: relative;
  width: 90%;
  padding: 45px;
  border-radius: 10px;
  background: #e6f8fc;
  text-align: left;
  ${t.mixins.flex({
  justify: 'space-between',
  align: 'flex-start',
})};
`;

let pageNumber = 1;
const MyFleets__singleVehicle = ({
  style, vehicle, active, closePop, baseSrvStandar,
}) => {
  const { getField, getGlobal, getFieldGroup } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [vehicleDetail, setVehicleDetail] = useState(false);
  const history = useHistory();

  const handleGetDataOnLoad = async () => {
    if (vehicle.id && vehicle.id !== '') {
      setLoading(true);
      const res = await CheckVehicleId(vehicle.id);
      const formated = formatApiRes(res);
      if (formated) {
        setLoading(false);
        if (formated.success === true && formated.data) {
          setVehicleDetail(formated.data);
        }
      }
    }
  };

  useEffect(() => {
    handleGetDataOnLoad();
  }, [vehicle]);

  useEffect(() => {
    pageNumber = 1;
    return () => {
      pageNumber = 1;
    };
  }, [active]);

  // eslint-disable-next-line consistent-return
  const getCurrentPaymentMethods = (key, secondary) => {
    if (vehicleDetail && vehicleDetail.UserFleet) {
      const payment = parsePaymentMethods(
        vehicleDetail.UserFleet.paymentMethod,
        vehicleDetail.UserFleet.paymentMethodDetail,
      );
      if (key) {
        if (secondary) {
          return payment.secondary[key];
        }

        return payment.main[key];
      }
    }
  };

  const fuelCards = [
    {
      label: 'DKV',
      slug: FLEET_EXTRA_PAYMENT.DKV,
      active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.DKV, true),
    },
    {
      label: 'UTA',
      slug: FLEET_EXTRA_PAYMENT.UTA,
      active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.UTA, true),
    },
    {
      label: 'Total',
      slug: FLEET_EXTRA_PAYMENT.TOTAL,
      active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.TOTAL, true),
    },
    {
      label: 'Shell',
      slug: FLEET_EXTRA_PAYMENT.SHELL,
      active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.SHELL, true),
    },
    {
      label: 'BP',
      slug: FLEET_EXTRA_PAYMENT.BP,
      active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.BP, true),
    },
    {
      label: getFieldGroup({
        base: strapiRegister,
        groupe: 'payment_methods',
        field: 'other_fuel_card',
      }),
      slug: FLEET_EXTRA_PAYMENT.OTHER,
      active: getCurrentPaymentMethods(FLEET_EXTRA_PAYMENT.OTHER, true),
    },
  ];

  const getActiveFuelCards = () => {
    const find = fuelCards.find((el) => el.active === true);
    if (find) {
      return ` ${find.label}`;
    }

    return ':';
  };

  const paymentMethods = [
    {
      slug: FLEET_PAYMENT.CASH,
      label: getFieldGroup({
        base: strapiRegister,
        groupe: 'payment_methods',
        field: 'method_1_cash',
      }),
      icon: faMoneyBillWave,
      active: getCurrentPaymentMethods(FLEET_PAYMENT.CASH),
    },
    {
      slug: FLEET_PAYMENT.CREDITCARDS,
      label: getFieldGroup({
        base: strapiRegister,
        groupe: 'payment_methods',
        field: 'method_2_card',
      }),
      icon: faCreditCard,
      active: getCurrentPaymentMethods(FLEET_PAYMENT.CREDITCARDS),
    },
    {
      slug: FLEET_PAYMENT.BANKTRANSFER,
      label: getFieldGroup({
        base: strapiRegister,
        groupe: 'payment_methods',
        field: 'method_3_transfer',
      }),
      icon: faUniversity,
      active: getCurrentPaymentMethods(FLEET_PAYMENT.BANKTRANSFER),
    },
    {
      slug: FLEET_PAYMENT.FUELCARD,
      label: `${getFieldGroup({
        base: strapiRegister,
        groupe: 'payment_methods',
        field: 'method_4_fuel',
      })} ${getActiveFuelCards()}`,
      icon: faGasPump,
      active: getCurrentPaymentMethods(FLEET_PAYMENT.FUELCARD),
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [historic, refreshData, setPage, setSearch] = useData({
    params: {
      fleetId: null,
    },
    service: 'cases',
    type: 'cases',
    removeFirstLoading: true,
  });
  const [popActive, setpopActive] = useState(active);

  useEffect(() => {
    setTimeout(() => {
      setpopActive(active);
    }, 10);
    if (active) {
      pageNumber = 1;
      setTimeout(() => {
        setpopActive(active);
      }, 10);
      refreshData({
        vehicle: vehicleDetail.id,
        status: 'closed',
      });
    }
  }, [active]);

  const headerCol = [
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_id' }) },
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_vehicle' }) },
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_open_date' }) },
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_services' }) },
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_case_status' }) },
  ];

  if (vehicleDetail === false || active === false) {
    return null;
  }

  const renderList = () => {
    const orderArray = historic.data;
    const arrayOfLine = [];
    if (orderArray) {
      orderArray.forEach((element) => {
        arrayOfLine.push([
          {
            component: <List__content uppercase>{element.uid}</List__content>,
            type: 'node',
          },
          {
            component: <List__content uppercase>{element.Fleet.registrationNumber}</List__content>,
            type: 'node',
          },
          {
            component: (
              <List__content>
                {sequelizeDate({ date: element.caseOpeningDate, format: 'd/m/y' })}
              </List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <List__content>
                <Content__servicesTag icons={renderCasesIcon(element)} />
              </List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <Content__tag
                content={element.status === 'opened' ? 'Open' : 'Closed'}
                blue={element.status === 'opened'}
                green={element.status === 'closed'}
              />
            ),
            type: 'node',
          },
        ]);
      });
    }

    return arrayOfLine;
  };

  const handleLine = (line) => {
    if (historic.data) {
      const _linedata = historic.data[line.key];
      if (_linedata && _linedata.id) {
        history.push(`${ROUTING.workshop.cases.path}/${_linedata.id}`);
      }
    }
  };

  const handleLoadNewPage = () => {
    if (
      historic.data
      && !historic.loading
      && pageNumber + 1 > 1
      && historic.data.length < historic.count
    ) {
      pageNumber += 1;
      setPage(pageNumber, historic.data, {
        fleetId: vehicleDetail.id,
      });
    }
  };

  return (
    <Container style={style}>
      <Grid__popup active={popActive} flex closeEvent={closePop}>
        <Fragment>
          {/*
            header
          */}

          <Header>
            <Title>{getField({ base: strapiWorkshopSingleVehicle, field: 'title' })}</Title>
            <Content__bigTag
              label={getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' })}
              value={vehicleDetail.registrationNumber}
            />
            <Grid__separator width="15px" />
            <Content__bigTag
              label={getGlobal({ option: 'vehicleParameter', field: 'vincode' })}
              value={vehicleDetail.vin}
            />
          </Header>

          <Grid__separator size="80px" />

          {/*
            launch register vehicle
          */}

          <ContainerCenter>
            <FormContainer>
              <VehicleSection>
                <Cards__vehicle
                  title="Vehicle"
                  noCard
                  noForm
                  data={[
                    {
                      label: getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
                      value: sequelizeDate({ date: vehicleDetail.firstDate, format: 'd/m/y' }),
                    },
                    {
                      label: getGlobal({ option: 'vehicleParameter', field: 'category' }),
                      value: vehicleDetail.type,
                      capitalize: true,
                    },
                    {
                      label: getGlobal({ option: 'vehicleParameter', field: 'mileage' }),
                      value: sequelizeMileage({ value: vehicleDetail.mileage }),
                    },
                    {
                      label: getGlobal({ option: 'vehicleParameter', field: 'brand' }),
                      value: vehicleDetail.brand,
                      capitalize: true,
                    },
                    {
                      label: getGlobal({
                        option: 'vehicleParameter',
                        field: 'countryofregistration',
                      }),
                      value:
                        getGlobal({
                          option: 'country',
                          field: vehicleDetail.countryOfRegistration,
                        }) || vehicleDetail.countryOfRegistration,
                    },
                  ]}
                />
              </VehicleSection>
            </FormContainer>

            <Grid__separator size="80px" />

            {/*
                Service available
              */}

            <Content__sectionTitle
              title={getField({ base: strapiWorkshopSingleVehicle, field: 'service_label' })}
              icon={<FontAwesomeIcon icon={faHandshake} />}
            />

            {/* {shouldRenderStandarRepair(vehicle) ? (
              <Cards__iconCard
                content={getField({ base: baseSrvStandar, field: 'service_title' })}
                icon={faTools}
              />
            ) : null} */}
            {shouldRenderRepairApproval(vehicle) ? (
              <Cards__iconCard
                content={getField({ base: strapiRepairApprovalService, field: 'service_title' })}
                icon={faHandshakeAlt}
              />
            ) : null}
            {/* {shouldRenderPriotity90(vehicle) ? (
              <Cards__iconCard
                content={getField({ base: strapiPriority90Service, field: 'service_title' })}
                icon={faStopwatch}
              />
            ) : null} */}
            {souldRenderPaymentguarantee(vehicle) ? (
              <Cards__iconCard
                content={getField({ base: strapiPaymentGuaranteeService, field: 'service_title' })}
                icon={faShieldCheck}
              />
            ) : null}

            <Grid__separator size="80px" />

            {/*
                Payment methods
              */}

            <Content__sectionTitle
              title={getField({
                base: strapiWorkshopSingleVehicle,
                field: 'payment_methods_title',
              })}
              icon={<FontAwesomeIcon icon={faMoneyCheck} />}
            />
            {paymentMethods.map(
              (item) => item.active && <Cards__iconCard content={item.label} icon={item.icon} />,
            )}
            <PaymentCard__info>
              {getField({
                base: strapiWorkshopSingleVehicle,
                field: 'payment_methods_description',
              })}
            </PaymentCard__info>

            <Grid__separator size="80px" />

            {/*
                Fleet information
              */}

            <Content__sectionTitle
              title={getField({ base: strapiWorkshopSingleVehicle, field: 'fleet_label' })}
              icon={<FontAwesomeIcon icon={faHouse} />}
            />
            <Row>
              <Column>
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'name' })}
                  content={getFleetUserKeyFromVehicle(vehicleDetail, 'companyName')}
                />
                <Grid__separator size="25px" />
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'zipcode' })}
                  content={getFleetUserKeyFromVehicle(vehicleDetail, 'zipcode')}
                />
                <Grid__separator size="25px" />
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'fleet_contact' })}
                  content={`${getFleetUserKeyFromVehicle(
                    vehicleDetail,
                    'lastName',
                  )} ${getFleetUserKeyFromVehicle(vehicleDetail, 'firstName')}`}
                />
              </Column>
              <Column>
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'vat_number' })}
                  content={getFleetUserKeyFromVehicle(vehicleDetail, 'vat')}
                />
                <Grid__separator size="25px" />
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'city' })}
                  content={getFleetUserKeyFromVehicle(vehicleDetail, 'city')}
                />
                <Grid__separator size="25px" />
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'email' })}
                  content={getFleetUserKeyFromVehicle(vehicleDetail, 'email')}
                />
              </Column>
              <Column>
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'country' })}
                  content={getFleetUserKeyFromVehicle(vehicleDetail, 'region')}
                  capitalize
                />
                <Grid__separator size="25px" />
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'address' })}
                  content={getFleetUserKeyFromVehicle(vehicleDetail, 'adress')}
                />
                <Grid__separator size="25px" />
                <Content__labelText
                  label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })}
                  content={getFleetUserKeyFromVehicle(vehicleDetail, 'phoneNumber')}
                />
              </Column>
            </Row>

            <Grid__separator size="80px" />

            {/*
                Home based workshop
              */}

            <Content__sectionTitle
              title={getField({ base: strapiWorkshopSingleVehicle, field: 'homebase_label' })}
              icon={<FontAwesomeIcon icon={faWarehouse} />}
            />
            {getHomeBaseKeyFromVehicle(vehicleDetail, 'companyName') ? (
              <Row>
                <Column>
                  <Content__labelText
                    label={getGlobal({ option: 'workshopInformation', field: 'name' })}
                    content={getHomeBaseKeyFromVehicle(vehicleDetail, 'companyName')}
                  />
                  <Grid__separator size="25px" />
                  <Content__labelText
                    label={getGlobal({ option: 'workshopInformation', field: 'zipcode' })}
                    content={getHomeBaseKeyFromVehicle(vehicleDetail, 'zipcode')}
                  />
                  <Grid__separator size="25px" />
                  <Content__labelText
                    label={getGlobal({ option: 'workshopInformation', field: 'workshop_contact' })}
                    content={`${getHomeBaseKeyFromVehicle(
                      vehicleDetail,
                      'lastName',
                    )} ${getHomeBaseKeyFromVehicle(vehicleDetail, 'firstName')}`}
                  />
                </Column>
                <Column>
                  <Content__labelText
                    label={getGlobal({ option: 'workshopInformation', field: 'vat_number' })}
                    content={getHomeBaseKeyFromVehicle(vehicleDetail, 'vat')}
                  />
                  <Grid__separator size="25px" />
                  <Content__labelText
                    label={getGlobal({ option: 'workshopInformation', field: 'city' })}
                    content={getHomeBaseKeyFromVehicle(vehicleDetail, 'city')}
                  />
                  <Grid__separator size="25px" />
                  <Content__labelText
                    label={getGlobal({ option: 'workshopInformation', field: 'email' })}
                    content={getHomeBaseKeyFromVehicle(vehicleDetail, 'email')}
                  />
                </Column>
                <Column>
                  <Content__labelText
                    label={getGlobal({ option: 'workshopInformation', field: 'country' })}
                    content={getHomeBaseKeyFromVehicle(vehicleDetail, 'region')}
                    capitalize
                  />
                  <Grid__separator size="25px" />
                  <Content__labelText
                    label={getGlobal({ option: 'workshopInformation', field: 'address' })}
                    content={getHomeBaseKeyFromVehicle(vehicleDetail, 'adress')}
                  />
                  <Grid__separator size="25px" />
                  <Content__labelText
                    label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })}
                    content={getHomeBaseKeyFromVehicle(vehicleDetail, 'phoneNumber')}
                  />
                </Column>
              </Row>
            ) : (
              <Row>
                <span>
                  {getField({ base: strapiWorkshopSingleVehicle, field: 'info_fleet_no_homebase' })}
                </span>
              </Row>
            )}

            <Grid__separator size="80px" />

            {/*
                Service history
              */}

            <Content__sectionTitle
              title={getField({ base: strapiWorkshopSingleVehicle, field: 'history_label' })}
              icon={<FontAwesomeIcon icon={faListUl} />}
            />
            <Table
              headerCol={headerCol}
              lineCol={renderList()}
              noSelect
              lineEvent={handleLine}
              maxHeight="360px"
              scrollEndEvent={handleLoadNewPage}
            />
            {historic && historic.loading && <Loader__wrapper />}
          </ContainerCenter>
          {loading ? <Loader__wrapper /> : false}
        </Fragment>
      </Grid__popup>
    </Container>
  );
};

export default MyFleets__singleVehicle;
