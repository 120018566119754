export const SERVICENAME = {
  fairPrice: 'fairPrice',
  standardrepair: 'standardrepair',
  repairapproval: 'repairapproval',
  service24: 'service24',
  priority90: 'priority90',
  paymentguarantee: 'paymentguarantee',
  smartmaintenance: 'smartmaintenance',
  alertgasoil: 'alertgasoil',
  profleetassist: 'profleetassist',
  tpms: 'tpms',
};
