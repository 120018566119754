
import React, {
  useContext, Fragment, useState, useEffect,
} from 'react';
import styled from 'styled-components';
import { AppContext } from 'context/appContext';

import { Grid__separator, Grid__separatorLine, Grid__popup } from 'components/grid/grid';
import Content__serviceConditions from 'components/content/serviceConditions/content__serviceConditions';
import Content__titleLight from 'components/content/titleLight/content__titleLight';
import {
  SingleService__banner as Banner,
  SingleService__intro as Intro,
  SingleService__description as Description,
} from 'components/singleService/singleService';
import {
  strapiAddServicePage,
} from 'util/strapi/content.starpi';

import AddServices__fleetList from 'containers/fleetView/addServices/fleetList/addServices__fleetList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import Cards__focusCard from 'components/cards/focusCard/cards__focusCard';
import { Button__primary } from 'components/button/button';
import { useHistory } from 'react-router-dom';
import { ROUTING } from 'util/constant/routing.constant';
import Form__totalCount from 'components/form/totalCount/form__totalCount';
import { formatApiCountRes } from 'util/function/formatApiRes.function';
import { ContentContext } from 'context/contentContext';
import { getVehicleList } from 'helpers/api/routes/getData';

const Container = styled.div``;

const AddServices__singleAddService = ({
  asHomeBase,
  style,
  popupActive,
  closeEvent,
  intro,
  condition1,
  condition2,
  condition3,
  condition4,
  list,
  rate,
  option,
  handleSelectLine,
  handleSave,
  loading,
  handleCgv,
  cgv,
  cgvPath,
  formError,
  paymentGuarantee,
  customCGV,
}) => {
  const { user } = useContext(AppContext);
  const { getField } = useContext(ContentContext);

  const [fleetArray, setfleetArray] = useState([]);
  const getFleetList = async () => {
    const res = await getVehicleList({
      userId: user.user.UserFleet.id,
    });

    const formated = formatApiCountRes(res);
    if (formated.success) {
      setfleetArray(formated);
    }
  };

  useEffect(() => {
    getFleetList();
  }, []);

  const history = useHistory();
  const handleClick = () => {
    history.push(ROUTING.fleet.myFleet.path);
  };

  const handleClickHomeBased = () => {
    history.push(ROUTING.fleet.homeBase.path);
  };

  let checkHomeBase = true;

  if (paymentGuarantee) {
    checkHomeBase = asHomeBase;
  }

  return (
    <Container style={style}>
      <Grid__popup active={popupActive} closeEvent={() => closeEvent()}>
        <Banner imageUrl={intro.banner} />
        <Intro title={intro.title} txt={intro.txt} price={intro.price} label={intro.label} />
        <Description>
          <Content__serviceConditions
            content={condition1.content}
            title={condition1.title}
            icon={<FontAwesomeIcon icon={condition1.icon} />}
          />
          <Grid__separator size="50px" />
          <Grid__separatorLine />
          <Grid__separator size="50px" />
          <Content__serviceConditions
            content={condition2.content}
            title={condition2.title}
            icon={<FontAwesomeIcon icon={condition2.icon} />}
          />
          <Grid__separator size="50px" />
          <Grid__separatorLine />
          <Grid__separator size="50px" />
          <Content__serviceConditions
            content={condition3.content}
            title={condition3.title}
            icon={<FontAwesomeIcon icon={condition3.icon} />}
          />
          <Grid__separator size="50px" />
          <Grid__separatorLine />
          <Grid__separator size="50px" />
          <Content__serviceConditions
            content={condition4.content}
            title={condition4.title}
            icon={<FontAwesomeIcon icon={condition4.icon} />}
          />
        </Description>
        <Grid__separator size="65px" />
        {fleetArray && fleetArray.data && fleetArray.data.length > 0 && checkHomeBase ? (
          <Fragment>
            <Content__titleLight content={list.title} />
            <Grid__separator size="35px" />
            <AddServices__fleetList handleCheck={handleSelectLine} handleLoadNewPage={() => {}} data={fleetArray.data} />
            {option ? option : null}
            <Grid__separator size="120px" />
            <Form__totalCount
              title={rate.title}
              price={rate.price}
              currency={rate.currency}
              labelPrice={rate.priceLabel}
              numberVehicle={rate.vehicle}
              numberLabel={rate.label}
              buttonText={rate.button}
              handleSave={handleSave}
              handleCgv={handleCgv}
              cgv={cgv}
              cgvPath={cgvPath}
              formError={formError}
              customCGV={customCGV}
            />
          </Fragment>
        ) : checkHomeBase === null ? (
          <Cards__focusCard>
            {getField({ base: strapiAddServicePage, field: 'no_home_based_msg' })}
            <Grid__separator size="35px" />
            <Button__primary event={() => handleClickHomeBased()}>{getField({ base: strapiAddServicePage, field: 'no_home_based_btn' })}</Button__primary>
          </Cards__focusCard>
        ) : (
          <Cards__focusCard>
            {getField({ base: strapiAddServicePage, field: 'no_fleet_msg' })}
            <Grid__separator size="35px" />
            <Button__primary event={() => handleClick()}>{getField({ base: strapiAddServicePage, field: 'no_fleet_btn' })}</Button__primary>
          </Cards__focusCard>
        )}
        {loading ? <Loader__wrapper /> : false}
      </Grid__popup>
    </Container>
  );
};

export default AddServices__singleAddService;
