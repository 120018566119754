
import React, {
  Fragment, useContext, useState, useEffect, useMemo,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import { Grid__separator, Grid__card } from 'components/grid/grid';
import { Cards__vehicle } from 'components/cards/cards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content__tag from 'components/content/tag/content__tag';
import Button__primary from 'components/button/primary/button__primary';
import {
  faDownload,
  faLongArrowLeft, faHandshakeAlt, faShieldCheck, faLongArrowRight,
} from '@fortawesome/pro-regular-svg-icons';
import { formatPrice } from 'util/format/price.format';
import Content__labelText from 'components/content/labelText/content__labelText';
import Cards__serviceMini from 'components/cards/serviceMini/cards__serviceMini';
import Cards__serviceBookmark from 'components/cards/serviceBookmark/cards__serviceBookmark';
import { Button__inline } from 'components/button/button';
import Content__labelTextarea from 'components/content/labelTextarea/content__labelTextarea';
import Cards__duration from 'components/cards/duration/cards__duration';
import MyFleets__singleVehicle from 'containers/workshopView/myFleets/singlePop/myFleets__singleVehicle';
import {
  strapiStandartrepairService,
  strapiMyFleetPage,
  strapiFairPriceService,
  strapiPaymentGuaranteeService,
  strapiRepairApprovalService,
} from 'util/strapi/content.starpi';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTING } from 'util/constant/routing.constant';
import { sequelizeDate, sequelizeTime } from 'util/date.util';
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import { parseFile } from 'util/format/parseFile.format';
import moment from 'moment';
import { SERVICENAME } from 'util/service/serviceName';
import RepairApprovalForm from 'containers/workshopView/dashboardRepairApproval/formPop/dashboardRepairApproval__formPop';
import PaymentGuaranteeForm from 'containers/workshopView/dashboardPaymentGuarantee/formPop/dashboardPaymentGuarantee__formPop';
import ClosedCaseForm from 'containers/workshopView/cases/singleCase/closedCaseForm/singleCase__closedCaseForm';
import DashboardPaymentGuarantee__singlePop from 'containers/workshopView/dashboardPaymentGuarantee/singlePop/dashboardPaymentGuarantee__singlePop';
import DashboardRepairApproval__singlePop from 'containers/workshopView/dashboardRepairApproval/singlePop/dashboardRepairApproval__singlePop';
import { formatServiceIntoArray, canCloseCase } from './Utils';


const Container = styled.div``;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  transition: .3s ease all;
  display: inline-block;
  pointer-events: none;
`;

const Back_button = styled.a`
  font-weight: normal;
  font-size: 16px;
  color: #01B5E2;
  cursor: pointer;
  transition: .3s ease all;
  display: inline-block;
  padding: 10px 20px;
  margin-left: -20px;
  &:hover {
    transform: translateX(10px);
    & svg {
      transform: translateX(-5px);
    }
  }
`;

const Card = styled(Grid__card)`
  padding: 45px 45px 90px 45px;
  margin-bottom: 40px;
`;

const Header = styled.div`
  width: 100%;
  ${t.mixins.flex({
    justify: 'space-between',
    align: 'flex-start',
  })}
`;

const Info = styled.div`
  width: 100%;
  max-width: 500px;
`;

const CaseTitle = styled.div`
  font-weight: 600;
  font-size: 30px;
  color: #313131;
  ${t.mixins.flex({
    direction: 'row',
    justify: 'flex-start',
    align: 'center',
  })}
`;

const SeparatorLine = styled.div`
  height: 1px;
  width: 15px;
  background-color: #B3B3B4;
  margin-top: 40px;
`;

const Timeline = styled.div`
  margin-top: 55px;
`;

const CaseTitle__id = styled.span`
  text-transform: uppercase;
`;

const Timeline__title = styled.div`
  font-size: 25px;
  color: #4C4C4C;
  margin-bottom: 30px;
  font-weight: 600;
`;

const Section = styled.div`
  margin-top: ${(props) => (
    props.type === 'service' ? '-55px'
      : props.type === 'duration' ? '-20px'
        : props.type === 'addService' ? '-74px'
          : props.type === 'end' ? '-77px'
            : props.type === 'closed' ? '7px'
              : 0
  )};
  margin-bottom: ${(props) => (
    props.type === 'service' ? '-55px'
      : props.type === 'duration' ? '-20px'
        : props.type === 'addService' ? '-74px'
          : 0
  )};
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => (props.type === 'closed' ? 'flex-start' : 'center')};
`;

const Date = styled.div`
  font-weight: 600;
  font-size: 14px;
  text-align: right;
  color: #4C4C4C;
  width: 87px;
  ${t.mixins.flex({
    justify: 'flex-start',
  })}
`;

const Hour = styled.div`
  font-weight: normal;
  margin-top: 5px;
`;

const Cercle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${(props) => (props.active ? '#01B5E2' : '#fff')};
  border: 3px solid #01B5E2;
  margin-right: 30px;
  margin-left: 20px;
`;

const Item = styled.div`
`;

const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #4C4C4C;
`;

const AddServiceCard = styled.div`
  background: rgba(1, 181, 226, 0.1);
  padding: 30px 35px 30px 35px;
  border-radius: 20px;
`;

const AddServiceCard__title = styled.div`
  font-weight: 600;
  font-size: 19px;
  text-align: left;
  color: #01B5E2;
  margin-bottom: 20px;
`;

const AddServiceCard__container = styled.div`
  ${t.mixins.flex({
    direction: 'row',
  })}
`;

const LineSeparator = styled.div`
  width: 3px;
  border-radius: 2px;
  height: ${(props) => (
    props.type === 'duration' ? '160px'
      : props.type === 'service' ? '195px'
        : props.type === 'end' ? '245px'
          : props.type === 'addService' ? '215px'
            : props.type === 'addServiceSmall' ? '195px'
              : props.type === 'closed' ? '155px'
                : '100px'
  )};
  margin-left: 113px;
  background: ${(props) => (props.dashed ? 'repeating-linear-gradient(to bottom, #01B5E2 0, #01B5E2 9px, #fff 9px, #fff 15px)' : '#01B5E2')};
`;

const DocumentationContent = styled.div`
 font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  color: #4d4d4d;
  max-width: 500px;
`;

const DocumentationDisable = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #4d4d4d;
`;


const singleCase__content = ({ basePage, data, refresh }) => {
  const history = useHistory();
  const { user } = useContext(AppContext);
  const { getFieldGroup, getField, getGlobal } = useContext(ContentContext);
  const { id } = useParams();
  /* ----------------------------- SINGLE VEHICLE ----------------------------- */
  const [activeVehicle, setactiveVehicle] = useState(false);
  const [activeForm, setActiveForm] = useState(false);
  const [activeSingle, setActiveSingle] = useState(false);
  const [activeClosedForm, setActiveClosedForm] = useState(false);
  const servicesRequested = useMemo(() => formatServiceIntoArray(data, getFieldGroup, getField), [data]);
  const canClose = canCloseCase(servicesRequested);
  const servicesList = [
    {
      icon: faShieldCheck,
      title: getField({ base: strapiPaymentGuaranteeService, field: 'service_title' }),
      id: SERVICENAME.paymentguarantee,
    },
    {
      icon: faHandshakeAlt,
      title: getField({ base: strapiRepairApprovalService, field: 'service_title' }),
      id: SERVICENAME.repairapproval,
    },
  ];
  const [activeServiceList, setActiveServiceList] = useState(servicesList);


  useEffect(() => {
    let list = [...servicesList];
    const request = [...servicesRequested];
    if (data) {
      if (data.UserFleet && data.UserFleet.UserWorkshop && user.user.UserWorkshop.id === data.UserFleet.UserWorkshop.id) {
        list = list.filter((el) => el.id !== SERVICENAME.paymentguarantee);
      }
      if (!data.Fleet.paymentguaranteeSubscription) {
        list = list.filter((el) => el.id !== SERVICENAME.paymentguarantee);
      }
      if (!data.UserFleet.repairApproverUserTier && data.UserFleet.repairApprovalUserId === user.user.id) {
        list = list.filter((el) => el.id !== SERVICENAME.repairapproval);
      }
    }
    if (request) {
      list = list.filter(
        (el) => !request.some(
          (e) => e.type === el.id,
        ),
      );
    }
    setActiveServiceList(list);
  }, [servicesRequested, data]);


  const handleCloseSingleVehicle = () => {
    setactiveVehicle(false);
  };
  const handleOpenVehicle = () => {
    setactiveVehicle(true);
  };
  /* ------------------------------- END VEHICLE ------------------------------ */


  const formatMileage = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  const getMileage = () => {
    if (data.Fleet && data.Fleet.mileage) {
      return `${formatMileage(data.Fleet.mileage)} Km`;
    }

    return '';
  };

  const getFleetInfo = (key) => {
    if (data.Fleet && data.Fleet[key]) {
      return data.Fleet[key];
    }

    return '';
  };

  const getName = () => {
    if (data.UserWorkshop && data.UserWorkshop.UserWorkshopAlias) {
      return `${data.UserWorkshop.UserWorkshopAlias.firstName || ''} ${data.UserWorkshop.UserWorkshopAlias.lastName || ''}`;
    }

    return '';
  };

  const renderTimeline = () => {
    const services = formatServiceIntoArray(data, getFieldGroup, getField);

    return services.map((service, i, row) => {
      const openSingle = () => {
        setActiveSingle({
          type: service.type,
          id: service.id,
        });
      };

      return (
        <>
          <Section type="service">
            <Date>
              <div>
                {sequelizeDate({ date: service.date, format: 'd/m/y' })}
                <Hour>{sequelizeTime({ date: service.date })}</Hour>
              </div>
            </Date>
            <Cercle active />
            <Item>
              <Cards__serviceBookmark
                icon={service.icon}
                title={service.title}
                button={getFieldGroup({ base: basePage, groupe: 'case_single', field: 'service_button' })}
                tag={service.status.text}
                buttonEvent={openSingle}
                {...service.status.color}
              />
            </Item>
          </Section>
          { i + 1 !== row.length && (
            <LineSeparator type="service" />
          )}
        </>
      );
    });
  };

  const renderInterventionTime = () => {
    const services = formatServiceIntoArray(data, getFieldGroup, getField);
    const start = moment(data.createdAt);
    let dateToCompare = null;
    let diff = 0;
    let diffNotStarted = 0;
    let started = false;
    if (services.length > 0) {
      started = true;
      dateToCompare = moment(services[0].date);
      diff = dateToCompare.diff(start, 'minutes');
    } else if (data.status === 'closed') {
      started = true;
      dateToCompare = moment(data.caseClosingDate);
      diff = dateToCompare.diff(start, 'minutes');
    } else {
      started = false;
      diffNotStarted = moment().diff(start, 'minutes');
    }
    const content = dateToCompare ? `${getFieldGroup({ base: basePage, groupe: 'case_single', field: 'timer_content' })} ${diff} ${getFieldGroup({ base: basePage, groupe: 'case_single', field: 'timer_content_unit' })}` : `${getFieldGroup({ base: basePage, groupe: 'case_single', field: 'timer_content_open' })} ${diffNotStarted} ${getFieldGroup({ base: basePage, groupe: 'case_single', field: 'timer_content_unit' })}`;
    if (content) {
      return (
        <>
          <LineSeparator />

          <Section type="duration">
            <Date />
            <Cercle active />
            <Item>
              <Cards__duration
                content={content}
                red={started && diff > 90}
                green={started && diff <= 90}
                orange={!started}
              />
            </Item>
          </Section>

          { started && (
            <LineSeparator type="duration" />
          ) }
        </>
      );
    }
    return false;
  };

  const addServiceToCase = (service) => {
    setActiveForm(service.id);
  };

  const onSingleClose = () => {
    setActiveSingle(false);
  };

  const handleClosedForm = () => {
    setActiveClosedForm(true);
  };

  const handleSuccessForm = (serviceId, type) => {
    setActiveForm(false);
    refresh();
    setActiveSingle({
      type,
      id: serviceId,
    });
  };


  return (
    <>
      <Container>
        <Back_button onClick={() => history.push(ROUTING.workshop.cases.path)}>
          <Icon icon={faLongArrowLeft} />
          {getFieldGroup({ base: basePage, groupe: 'cases_dashboard', field: 'title' })}
        </Back_button>
        <Grid__separator size="25px" />
        <Card>
          {/*
        ===================
              header
        ===================
        */}
          <Header>
            <Info>
              <Grid__separator size="10px" />
              <CaseTitle>
                <span>
                  {getFieldGroup({ base: basePage, groupe: 'case_single', field: 'title' })}
                  {' '}
                  -
                  {' '}
                  <CaseTitle__id>{data.uid}</CaseTitle__id>
                  {' '}
                  -
                </span>
                <Grid__separator width="12px" />
                <Content__tag
                  content={data.status === 'opened' ? getFieldGroup({ base: basePage, groupe: 'case_parameters', field: 'status_open' }) : getFieldGroup({ base: basePage, groupe: 'case_parameters', field: 'status_close' })}
                  blue={data.status === 'opened'}
                  green={data.status === 'closed'}
                />
              </CaseTitle>
              <Grid__separator size="50px" />
              <Content__labelText
                label={getFieldGroup({ base: basePage, groupe: 'case_single', field: 'responsable_label' })}
                content={getName()}
                capitalize
              />
              <SeparatorLine />
              <Grid__separator size="40px" />
              <Content__labelText
                label={getFieldGroup({ base: basePage, groupe: 'case_single', field: 'mileage_label' })}
                content={getMileage()}
              />
              <Grid__separator size="25px" />
              <Content__labelTextarea
                label={getFieldGroup({ base: basePage, groupe: 'case_single', field: 'customer_request_label' })}
                content={data.description}
                lang={data.textLang}
                base={data.translate}
                id={data.id}
                dataName="caseClient"
                convert={user.user.lang}
              />


              <SeparatorLine />
            </Info>
            <Cards__vehicle
              title={getFieldGroup({ base: basePage, groupe: 'case_single', field: 'vehicle_details_button' })}
              data={
                [
                  {
                    label: getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' }),
                    value: getFleetInfo('registrationNumber'),
                    uppercase: true,
                  },
                  {
                    label: getGlobal({ option: 'vehicleParameter', field: 'category' }),
                    value: getFleetInfo('type'),
                    capitalize: true,
                  },
                  {
                    label: getGlobal({ option: 'vehicleParameter', field: 'vincode' }),
                    value: getFleetInfo('vin'),
                    uppercase: true,
                  },
                  {
                    label: getGlobal({ option: 'vehicleParameter', field: 'brand' }),
                    value: getFleetInfo('brand'),
                    capitalize: true,
                  },
                ]
              }
              button={getFieldGroup({ base: basePage, groupe: 'case_single', field: 'vehicle_details_button' })}
              event={handleOpenVehicle}
            />
          </Header>
          {/*
          ===================
                Timeline
          ===================
          */}
          <Timeline>
            <Timeline__title>{getFieldGroup({ base: basePage, groupe: 'case_single', field: 'intervention_timeline' })}</Timeline__title>

            <Section>
              <Date>
                <div>
                  {sequelizeDate({ date: data.createdAt, format: 'd/m/y' })}
                  <Hour>{sequelizeTime({ date: data.createdAt })}</Hour>
                </div>
              </Date>
              <Cercle active />
              <Item><ItemTitle>{getFieldGroup({ base: basePage, groupe: 'case_single', field: 'case_opening' })}</ItemTitle></Item>
            </Section>

            {renderInterventionTime()}

            {renderTimeline()}

            {activeServiceList.length > 0 && data.status !== 'closed' && (
              <>

                <LineSeparator
                  type={servicesRequested.length === 0 ? 'addServiceSmall' : 'addService'}
                  dashed
                />

                <Section type="addService">
                  <Date />
                  <Cercle />
                  <Item>
                    <AddServiceCard>
                      <AddServiceCard__title>
                        +
                        {' '}
                        {getFieldGroup({ base: basePage, groupe: 'case_single', field: 'add_services' })}
                      </AddServiceCard__title>
                      <AddServiceCard__container>
                        {activeServiceList.map((item, key, row) => (
                          <Fragment>
                            <Cards__serviceMini
                              key={key}
                              icon={item.icon}
                              content={item.title}
                              event={() => addServiceToCase(item)}
                            />
                            {key + 1 !== row.length ? (
                              <Grid__separator width="20px" />
                            ) : null}
                          </Fragment>
                        ))}
                      </AddServiceCard__container>
                    </AddServiceCard>
                  </Item>
                </Section>
              </>
            )}

            {
              data.status === 'closed' ? (
                <>
                  <LineSeparator type="closed" />

                  <Section type="closed">
                    <Date>
                      <div>
                        {sequelizeDate({ date: data.caseClosingDate, format: 'd/m/y' })}
                        <Hour>{sequelizeTime({ date: data.caseClosingDate })}</Hour>
                      </div>
                    </Date>
                    <Cercle active />
                    <Item>
                      <ItemTitle>{getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'title' })}</ItemTitle>
                      <Grid__separator size="30px" />
                      <Content__labelText
                        label={getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'price_label' })}
                        content={formatPrice(data.price)}
                      />
                      <Grid__separator size="30px" />
                      <Content__labelTextarea
                        label={getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'description_label' })}
                        content={data.docDescription}
                        base={data.docTranslate}
                        lang={data.docTextLang}
                        convert={user.user.lang}
                        dataName="caseClose"
                        maxWidth="500px"
                        id={data.id}
                      />
                      {
                        data.file && (
                          <>
                            <Grid__separator size="30px" />
                            <Content__labelText
                              label={getFieldGroup({ base: basePage, groupe: 'closing_form', field: 'invoice_label' })}
                              noMargin
                              content={(
                                <Button__inline
                                  content={getField({ base: strapiRepairApprovalService, field: 'request_file_button' })}
                                  icon={<FontAwesomeIcon icon={faDownload} />}
                                  download
                                  href={parseFile(data.file).url}
                                />
                              )}
                            />

                          </>
                        )
                      }
                    </Item>
                  </Section>
                </>
              ) : (
                <>
                  <LineSeparator type="end" dashed />

                  <Section type="end">
                    <Date />
                    <Cercle />
                    <Item>
                      <ItemTitle>{getFieldGroup({ base: basePage, groupe: 'case_single', field: 'close_case_title' })}</ItemTitle>
                      <Grid__separator size="15px" />
                      <DocumentationContent>
                        {getFieldGroup({ base: basePage, groupe: 'case_single', field: 'close_case_content' })}
                      </DocumentationContent>
                      <Grid__separator size="25px" />
                      <Button__primary
                        withIcon
                        iconEnd
                        disable={!canClose}
                        icon={<FontAwesomeIcon icon={faLongArrowRight} />}
                        event={handleClosedForm}
                      >
                        {getFieldGroup({ base: basePage, groupe: 'case_single', field: 'close_case_button' })}
                      </Button__primary>
                      {!canClose && (
                        <>
                          <Grid__separator size="15px" />
                          <DocumentationDisable>{getFieldGroup({ base: basePage, groupe: 'case_single', field: 'close_case_alert' })}</DocumentationDisable>
                        </>
                      )}
                    </Item>
                  </Section>
                </>
              )
            }

          </Timeline>
        </Card>
        <MyFleets__singleVehicle
          active={activeVehicle}
          vehicle={data.Fleet}
          currentUser={data.UserFleet.UserFleetAlias}
          vehicleId={data.Fleet.id}
          closePop={handleCloseSingleVehicle}
          basePage={strapiMyFleetPage}
          baseSrvFairPrice={strapiFairPriceService}
          baseSrvStandar={strapiStandartrepairService}
        />
      </Container>
      <RepairApprovalForm
        active={activeForm === SERVICENAME.repairapproval}
        currentVehicle={{
          ...data.Fleet,
          UserFleet: data.UserFleet,
        }}
        caseId={id}
        closePop={() => setActiveForm(false)}
        onSuccess={(serviceId) => handleSuccessForm(serviceId, SERVICENAME.repairapproval)}
      />
      <PaymentGuaranteeForm
        active={activeForm === SERVICENAME.paymentguarantee}
        currentVehicle={{
          ...data.Fleet,
          UserFleet: data.UserFleet,
        }}
        caseId={id}
        closePop={() => setActiveForm(false)}
        onSuccess={(serviceId) => handleSuccessForm(serviceId, SERVICENAME.paymentguarantee)}
      />
      <DashboardRepairApproval__singlePop
        active={
          activeSingle.type === SERVICENAME.repairapproval
        }
        id={activeSingle.id}
        closeEvent={onSingleClose}
      />
      <DashboardPaymentGuarantee__singlePop
        active={
          activeSingle.type === SERVICENAME.paymentguarantee
        }
        id={activeSingle.id}
        closeEvent={onSingleClose}
      />
      <ClosedCaseForm
        basePage={basePage}
        active={activeClosedForm}
        closePop={() => setActiveClosedForm(false)}
        caseId={id}
        data={data}
        refresh={refresh}
      />
    </>
  );
};

export default singleCase__content;
