
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import t from 'theme';

import { ContentContext } from 'context/contentContext';

import { List__table, List__content } from 'components/list/list';
import { Grid__card } from 'components/grid/grid';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';

/**
 * import containers & components
 */
import { parseStatus } from 'util/conditions/statusProps.util';
import { getAction } from 'util/function/reloadAction.function';
import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import { strapiWorkshopMyFleet } from 'util/strapi/content.starpi';
import Content__tag from 'components/content/tag/content__tag';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import Form__search from 'components/form/search/form__search';


/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 40px;
`;

const ListCardContainer = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'center' })};
  height: 40px;
  margin-bottom: 30px;
`;

const Card = styled(Grid__card)`
  height: calc(100vh - 160px);
`;

const Table = styled(List__table)`
  height: calc(100% - 70px);
`;

const MyFleets__list = ({
  style, handleSelectFleet, data, setSearch, handleLoadNewPage, loading,
}) => {
  const { getGlobal, getFieldGroup, getField } = useContext(ContentContext);
  const [searchValue, setsearchValue] = useState(null);
  const headerCol = [
    { label: getGlobal({ option: 'workshopInformation', field: 'company_name' }) },
    { label: getGlobal({ option: 'workshopInformation', field: 'country' }) },
    { label: getGlobal({ option: 'workshopInformation', field: 'zipcode' }) },
    { label: getGlobal({ option: 'workshopInformation', field: 'city' }) },
    { label: getGlobal({ option: 'workshopInformation', field: 'vehicle' }) },
    { label: getGlobal({ option: 'workshopInformation', field: 'status' }) },
  ];

  const [fleetFormatted, setFleetFormatted] = useState([]);
  const [fleetNoFormatted, setFleetNoFormatted] = useState([]);

  const renderStatus = (status) => {
    if (status === SERVICE_STATUS.waiting) {
      return SERVICE_STATUS.action;
    }

    return status;
  };


  const getArrayWithId = (id) => {
    const orderArray = data;
    const index = orderArray.find((el) => `${el.UserFleet.id}` === id);
    if (index) {
      handleSelectFleet(index);
    }
  };

  const handleCheckParams = () => {
    const userParam = getAction({ key: 'user' });
    if (userParam.success === true) {
      getArrayWithId(userParam.value);
    }
  };

  useEffect(() => {
    if (data) {
      const obj = [];
      const noFormatedObj = [];
      data.forEach((element) => {
        // if (element.FavWorkshopStatus !== SERVICE_STATUS.error) {
        if (element && element.UserFleet.homeBaseRefused !== true) {
          const childObj = [];
          childObj.push({ label: <List__content uppercase>{element.companyName}</List__content> });
          childObj.push({
            label: (
              <List__content capitalize>
                {getGlobal({ option: 'country', field: element.region })
                || element.region}
              </List__content>
            ),
          });
          childObj.push({ label: <List__content uppercase>{element.zipcode}</List__content> });
          childObj.push({ label: (<List__content capitalize>{element.city}</List__content>) });
          childObj.push({ label: <List__content capitalize>{element.Fleets ? element.Fleets.length : 0}</List__content> });
          childObj.push({
            label: (<Content__tag
              content={getFieldGroup({
                base: strapiWorkshopMyFleet,
                groupe: 'status',
                field: renderStatus(element.UserFleet.FavWorkshopStatus),
              })}
              {...parseStatus({ status: renderStatus(element.UserFleet.FavWorkshopStatus) })}
            />),
          });

          obj.push(childObj);
          noFormatedObj.push(element);
        }
      });

      setFleetFormatted(obj);
      setFleetNoFormatted(noFormatedObj);
      handleCheckParams();
    }
  }, [data]);

  const handleLineClick = (payload) => {
    if (fleetNoFormatted) {
      if (fleetNoFormatted[payload.key]) {
        handleSelectFleet(fleetNoFormatted[payload.key]);
      }
    }
  };

  const doSearch = () => {
    setSearch(searchValue);
  };

  return (
    <Container style={style}>
      <Card padding="30px">
        <ListCardContainer>
          <Content__cardTitle
            content={`${getField({ base: strapiWorkshopMyFleet, field: 'table_title' })} (${fleetFormatted ? fleetFormatted.length : 0})`}
          />
          <Form__search
            property="searchValue"
            search
            placeholder={getField({ base: strapiWorkshopMyFleet, field: 'search_label' })}
            change={(value) => setsearchValue(value.value)}
            searchValue={searchValue}
            event={doSearch}
          />
        </ListCardContainer>
        <Table
          headerCol={headerCol}
          lineCol={fleetFormatted}
          noSelect
          lineEvent={handleLineClick}
          maxHeight="100%"
          scrollEndEvent={handleLoadNewPage}
        />
        {
          loading && (
            <Loader__wrapper />
          )
        }
      </Card>
    </Container>
  );
};

export default MyFleets__list;
