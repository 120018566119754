/* eslint-disable */
// import FullCalendar, { DayHeaderContentArg } from "@fullcalendar/react";
// import { add, differenceInMinutes, parseISO } from "date-fns";

import moment from "moment";

// export const formatDayHeaderContent = (dateObj) => {
//   const date = format(dateObj.date, "EEEE[-]dd[-]MMMM");
//   const dateArray = date.split("[-]");
//   if (dateArray && dateArray.length > 0) {
//     return {
//       html: `
//       <div class="customHeader">
//         <span class="customHeaderFullDay">${dateArray[0]}</span>
//         <span class="customHeaderDay">${dateArray[1]}</span>
//         <span class="customHeaderMonth">${dateArray[2]}</span>
//       </div>
//       `,
//     };
//   }
//   return dateObj.text;
// };

// export const formatDayHeaderMonthsContent = (dateObj) => {
//   const date = format(dateObj.date, "EEEE[-]dd[-]MMMM");
//   const dateArray = date.split("[-]");
//   if (dateArray && dateArray.length > 0) {
//     return {
//       html: `
//       <div class="customHeader">
//         <span class="customHeaderMonths">${dateArray[0]}</span>
//       </div>
//       `,
//     };
//   }
//   return dateObj.text;
// };

export const sendViewType = (calendarRef) => {
  const view = calendarRef.current?.getApi()?.view?.type;
  if (view) {
    if (view === "timeGridDay") {
      return 'day';
    }
    if (view === "timeGridWeek") {
      return 'week';
    }
    if (view === "dayGridMonth") {
      return 'month';
    }
  }
  return 'week';
};

const getTimeRange = (start, end) =>
  differenceInMinutes(
    parseISO(`${moment().format("yyyy-MM-dd")}T${start}`),
    parseISO(`${moment().format("yyyy-MM-dd")}T${end}`)
  );

export const numberOfSlots = (
  increment = 15,
  timeRange = ["19:00:00", "09:00:00"],
  numberOfDay = 6
) => {
  return (getTimeRange(timeRange[0], timeRange[1]) / increment) * numberOfDay;
};

export const sendDateRange = (
  calendarRef
) => {
  const view = calendarRef.current?.getApi()?.view;
  if (view) {
    const TempNumberOfday = view.type === "dayGridMonth" ? 31 : 7;
    const start = view.activeStart;
    const end = moment(start).add(TempNumberOfday - 1, 'day');
    const startIso = `${moment(start, "yyyy-MM-dd")}`;
    const endIso = `${moment(end, "yyyy-MM-dd")}`;
    const object = {
      date: {
        start,
        end,
      },
      iso: {
        start: startIso,
        end: endIso,
      },
      numberOfDay: TempNumberOfday,
      numberOfSlots,
    };
    // nombreDeJour *
    return object;
  }
  return undefined;
};
