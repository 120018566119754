
/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';
import './transition__mover.scss';

/**
 * import components
 */


/**
 * styled components
 */

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  user-select:none;
  pointer-events: none;
  background: ${t.color.dark};
`;

/**
 * mover component is..
 */

const Transition__mover = ({ style }) => (
  <Container className="transition__container" style={style} />
);

export default Transition__mover;
