
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Grid__card, Grid__separator } from 'components/grid/grid';
import t from 'theme';
import Button__primary from 'components/button/primary/button__primary';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';

import { List__table, List__content } from 'components/list/list';
import { ContentContext } from 'context/contentContext';
import Content__tag from 'components/content/tag/content__tag';
import { sequelizeDate } from 'util/date.util';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { Content__servicesTag } from 'components/content/content';
import { renderCasesIcon } from 'util/Case';
import { useHistory } from 'react-router-dom';
import { ROUTING } from 'util/constant/routing.constant';
import { strapiCase } from 'util/strapi/content.starpi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';


const Container = styled.div`
  height: 100%;
  width: calc(100% - 410px);
`;

const ListCardContainer = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'center' })};
  height: 40px;
  margin-bottom: 30px;
`;

const Table = styled(List__table)`
  height: 100%;
  max-height: calc(100% - 70px);
`;

const ListCard = styled(Grid__card)`
  height: 100%;
  min-height: 330px;
`;

const Illu = styled.img`
  height: 190px;
`;


const Home__list = ({
  style, historic, loading, handleLoadNewPage,
}) => {
  const { getFieldGroup } = useContext(ContentContext);
  const history = useHistory();
  const headerCol = [
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_id' }) },
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_vehicle' }) },
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_open_date' }) },
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_services' }) },
    { label: getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'array_case_status' }) },
  ];

  const handleCreateCase = () => {
    history.push(ROUTING.workshop.checkVehicle.path);
  };

  const renderList = () => {
    const arrayOfLine = [];
    if (historic) {
      historic.forEach((element) => {
        arrayOfLine.push([
          {
            component: (<List__content uppercase>{element.uid}</List__content>),
            type: 'node',
          },
          {
            component: (<List__content uppercase>{element.Fleet.registrationNumber}</List__content>),
            type: 'node',
          },
          {
            component: (<List__content>{sequelizeDate({ date: element.caseOpeningDate, format: 'd/m/y' })}</List__content>),
            type: 'node',
          },
          {
            component: (
              <List__content>
                <Content__servicesTag
                  icons={renderCasesIcon(element)}
                />
              </List__content>
            ),
            type: 'node',
          },
          {
            component: (
              <Content__tag
                content={element.status === 'opened' ? getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'status_open' }) : getFieldGroup({ base: strapiCase, groupe: 'case_parameters', field: 'status_close' })}
                blue={element.status === 'opened'}
                green={element.status === 'closed'}
              />
            ),
            type: 'node',
          },
        ]);
      });
    }

    return arrayOfLine;
  };


  const handleLineClick = (payload) => {
    history.push(`${ROUTING.workshop.cases.path}/${historic[payload.key].id}`);
  };

  return (
    <Container style={style}>
      <ListCard padding="30px">
        <ListCardContainer>
          <Content__cardTitle content={getFieldGroup({ base: strapiCase, groupe: 'cases_dashboard', field: 'home_title' })} />
        </ListCardContainer>
        <Table
          headerCol={headerCol}
          lineCol={renderList()}
          noSelect
          lineEvent={handleLineClick}
          scrollEndEvent={handleLoadNewPage}
          emptyComponent={(
            <>
              <Illu src={t.image.illu.onboard5} alt="" />
              <Grid__separator size="25px" />
              <Button__primary
                withIcon
                noprevent
                icon={<FontAwesomeIcon icon={faPlus} />}
                event={handleCreateCase}
              >
                {getFieldGroup({ base: strapiCase, groupe: 'cases_dashboard', field: 'open_case_button' })}
              </Button__primary>
            </>
          )}
        />
        {
          loading && (
            <Loader__wrapper />
          )
        }
      </ListCard>
    </Container>
  );
};

export default Home__list;
