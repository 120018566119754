import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
  position: relative;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};
  border-radius: 10px;
  box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.04);
  background-color: ${(props) => props.background || 'white'};
  color: ${(props) => props.color || '#041e3a'};
  padding: ${(props) => props.padding || '25px'};
  transition: ${(props) => (props.hover ? '.3s ease all' : null)};
  cursor: ${(props) => (props.hover ? 'pointer' : null)};
  &:hover {
    box-shadow: ${(props) => (props.hover ? '0px 5px 24px rgba(0, 0, 0, 0.06)' : null)};
  }
`;


const Grid__card = ({
  style, hover, width, height, background, color, className, children, padding, event,
}) => (
  <Container
    hover={hover}
    style={style}
    width={width}
    height={height}
    background={background}
    color={color}
    className={className}
    padding={padding}
    onClick={() => (event ? event() : null)}
  >
    {children}
  </Container>
);

export default Grid__card;
