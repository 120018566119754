/**
 * Import Lib & assets
 */

import React, { Fragment } from 'react';
import styled from 'styled-components';
import t from 'theme';
import { Link } from 'react-router-dom';
import Loader__small from 'components/loader/small/loader__small';

const Container = styled.div`
  ${t.mixins.flex({ direction: 'column' })};
  height: 170px;
  width: 170px;
  min-height: 170px;
  min-width: 170px;
  background: #01b5e2;
  box-shadow: 0px 11px 14px rgba(1, 181, 226, 0.1);
  border-radius: 10px;
  text-decoration: none;
`;

const ContainerLink = styled(Link)`
  ${t.mixins.flex({ direction: 'column' })};
  height: 170px;
  width: 170px;
  min-height: 170px;
  min-width: 170px;
  background: #01b5e2;
  box-shadow: 0px 11px 14px rgba(1, 181, 226, 0.1);
  border-radius: 10px;
  text-decoration: none;
  transition: .3s ease all;
  &:hover {
    box-shadow: 0px 5px 24px rgba(1, 181, 226, 0.6);
  }
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  color: #ffffff;
`;

const Value = styled.span`
  font-weight: 600;
  font-size: 50px;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
`;

const Label = styled.span`
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 5px;
`;

const Cards__blueCard = ({
  style, className, title, value, label, link, to, loading,
}) => {
  const renderContent = () => (
    <Fragment>
      <Title>{title}</Title>
      <Value>{value}</Value>
      <Label>{label}</Label>
    </Fragment>
  );
  return (
    !loading ? (
      link && to ? (
        <ContainerLink style={style} className={className} to={to}>
          {renderContent()}
        </ContainerLink>
      ) : (
        <Container style={style} className={className}>
          {renderContent()}
        </Container>
      )
    ) : (
      <Container style={style} className={className}>
        <Loader__small color="#FFF" />
      </Container>

    )
  );
};

export default Cards__blueCard;
