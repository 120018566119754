
import React, { useContext, Fragment } from 'react';
import styled from 'styled-components';
import t from 'theme';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';
import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';

import { Grid__card } from 'components/grid/grid';
import { slugify } from 'helpers/js/Utile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { GetHomeBaseFromUser } from 'util/fleet/getfleetuserdata.util';
import MemberCard__pdf from '../pdf/memberCard__pdf';

const Container = styled.div``;

const Card__container = styled.div`
  ${t.mixins.flex({ justify: 'center', align: 'center' })}
  height: calc(100vh - 70px);
`;

const Card = styled(Grid__card)`
  margin-top: 100px;
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
  padding-bottom: 65px;
  max-width: 650px;
  position: relative;
`;

const Content = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #4d4d4d;
  max-width: 500px;
  margin-bottom: 25px;
  padding-top: 90px;
`;

const FleetCard__inner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  will-change: transform;
  background: transparent;
`;

const FleetCard = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 390px;
  top: -180px;
  height: 250px;
  transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-style: preserve-3d;
  transform-origin: center;
  background: transparent;
  will-change: transform;
  &:hover ${FleetCard__inner} {
    transform: scale(1.1) rotateY(180deg);
  }
`;

const CardImgFront = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
`;

const CardImgBack = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  background-color: #fff;
  transform: rotateY(180deg);
`;

const CardsWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  padding: 14% 0 13% 0;
`;

const CardsContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 25px;
`;

const CardsSection = styled.div`
  margin-bottom: 10px;
`;

const CardsTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
`;

const CardsText = styled.p`
  font-size: 13px;
  margin-bottom: 3px;
`;

const DownloadButtonContainer = styled.div`
  position: relative;
  transition: 0.3s ease all;
  a {
    height: 40px;
    border-radius: 30px;
    line-height: 40px;
    background-color: #01B5E2;
    color: white;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    transition: all cubic-bezier(0.525,0.005,0.045,1.005) 400ms;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    padding-left: 25px;
    padding-right: 25px;
    min-width: 150px;
    text-align: center;
    box-shadow: 0px 7px 14px rgba(1,181,226,0.2);
    transition: 0.3s ease all;
    padding-left: calc(25px + 16px + 8px);
  }
  &:hover {
      transform: translateY(-2px);
      transition: 0.3s ease all;
      a {
        box-shadow: 0px 7px 14px rgba(1, 181, 226, 0.35);
        transition: 0.3s ease all;
      }
    }
`;

const IconDownloadButton = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
`;

const MemberCard__cardContainer = ({ style, basePage }) => {
  const { getField } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const userInformation = user.user;
  const homeBaseWorkshop = GetHomeBaseFromUser({ user: user.user });

  const renderAddres = ({ adress, zip, city }) => `${adress}, ${zip} ${city}`;

  const renderHomeBaseInformation = (key) => {
    if (homeBaseWorkshop.success) {
      return homeBaseWorkshop.data[key];
    }

    return null;
  };

  const renderPdfCards = () => (
    <MemberCard__pdf
      companyName={userInformation.companyName}
      companyAdress={
        renderAddres({
          adress: userInformation.adress,
          zip: userInformation.zipcode,
          city: userInformation.city,
        })
      }
      companyNumber={`${userInformation.phoneNumber}`}
      companyMail={userInformation.email}
      haveHomeBase={homeBaseWorkshop.success}
      homeBaseName={renderHomeBaseInformation('companyName')}
      homeBaseAdress={
        renderAddres({
          adress: renderHomeBaseInformation('adress'),
          zip: renderHomeBaseInformation('zipcode'),
          city: renderHomeBaseInformation('city'),
        })
      }
      homeBaseNumber={`${renderHomeBaseInformation('phoneNumber')}`}
      homeBaseMail={renderHomeBaseInformation('email')}

    />
  );

  const renderFrontCards = () => (
    <Fragment>
      <CardsSection>
        <CardsTitle>Company</CardsTitle>
        <CardsText>
          {userInformation.companyName}
        </CardsText>
        <CardsText>
          { renderAddres({
            adress: userInformation.adress,
            zip: userInformation.zipcode,
            city: userInformation.city,
          })}
        </CardsText>
        <CardsText>{userInformation.phoneNumber}</CardsText>
        <CardsText>{userInformation.email}</CardsText>
      </CardsSection>
      {
        homeBaseWorkshop.success && (
          <CardsSection>
            <CardsTitle>Home based workshop</CardsTitle>
            <CardsText>
              {renderHomeBaseInformation('companyName')}
            </CardsText>
            <CardsText>
              { renderAddres({
                adress: renderHomeBaseInformation('adress'),
                zip: renderHomeBaseInformation('zipcode'),
                city: renderHomeBaseInformation('city'),
              })}
            </CardsText>
            <CardsText>{renderHomeBaseInformation('phoneNumber')}</CardsText>
            <CardsText>{renderHomeBaseInformation('email')}</CardsText>
          </CardsSection>
        )
      }
    </Fragment>
  );

  return (
    <Container style={style}>
      <Content__pageTitle content={getField({ base: basePage, field: 'page_title' })} />
      <Card__container>
        <Card style={style}>
          <FleetCard>
            <FleetCard__inner>
              <CardImgFront src={t.image.global.fleetCardFront} />
              <CardImgBack src={t.image.global.fleetCardBack} />
              <CardsWrapper>
                <CardsContent>
                  {renderFrontCards()}
                </CardsContent>
              </CardsWrapper>
            </FleetCard__inner>
          </FleetCard>
          <Content>{getField({ base: basePage, field: 'cards_description' })}</Content>
          <DownloadButtonContainer>
            <IconDownloadButton icon={faDownload} color="white" />
            <PDFDownloadLink document={renderPdfCards()} fileName={`${slugify(userInformation.companyName)}.pdf`}>
              {() => getField({ base: basePage, field: 'button' })}
            </PDFDownloadLink>
          </DownloadButtonContainer>
        </Card>
      </Card__container>
    </Container>
  );
};

export default MemberCard__cardContainer;
