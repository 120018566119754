/* eslint-disable spaced-comment */

/**
   * Import Lib
   */

import React, {
  useState, useContext, Fragment,
} from 'react';
import styled from 'styled-components';
import { Grid__card } from 'components/grid/grid/';
import { Content__text } from 'components/content/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form__label from 'components/form/label/form__label';
import {
  faUser, faFileContract, faClock, faTags, faMoneyCheck,
} from '@fortawesome/pro-solid-svg-icons';
import {
  Form__textfield,
} from 'components/form/form';
import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import { Grid__separator } from 'components/grid/grid';
import Button__primary from 'components/button/primary/button__primary';
import Form__checkCondition from 'components/form/checkCondition/form__checkCondition';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { ContentContext } from 'context/contentContext';
import { strapiRegister, strapiMapPage, strapiContract } from 'util/strapi/content.starpi';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { formatApiRes } from 'util/function/formatApiRes.function';
import {
  definePassword, updateWorkshopUserInRegistration, acceptContract, updateFleetUserInRegistration,
} from 'helpers/api/routes/User';
import Form__hourly from 'components/form/hourly/form__hourly';
import { SendMail } from 'helpers/api/routes/Mail';
import { ROUTING } from 'util/constant/routing.constant';
import { ScrollToError } from 'util/conditions/checkField.condition';
import {
  faMoneyBillWave, faUniversity, faGasPump, faCreditCard,
} from '@fortawesome/pro-light-svg-icons';
import Form__checkBoxCard from 'components/form/checkBoxCard/form__checkBoxCard';
import { useParams } from 'react-router-dom';
import { FLEET_EXTRA_PAYMENT, FLEET_PAYMENT, formatPaymentMethods } from '../../../util/constant/fleetPayment';


const Container = styled(Grid__card)`
    width: 1100px;
    padding: 50px 100px;
  `;

const TitleForm = styled(Content__text)`
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 30px;
  line-height: auto;
  text-align: center;
  color: #1a1a1a;
`;

const SubTitleForm = styled(Content__text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  display: block;
  color: #001540;
  margin-top: 25px;
  margin-bottom: 65px;
`;

const ValidateBtn = styled(Button__primary)`
  text-align: center;
  width: 100%;
`;

const Mail = styled.div`
  font-size: 16px;
  margin-top: 5px;
`;

const ContainerField = styled.div`
  max-width: 400px;
`;

const InfoContent = styled.div`
  max-width: 600px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 25px;
`;


const RegisterFinal__form = ({
  style, setmodal, setcredential, currentUser, step,
}) => {
  const {
    getFieldWithNavigatorLang, getFieldGroupWithNavigatorLang, getGlobalWithNavigatorLang, getFieldGroup, getFiles,
  } = useContext(ContentContext);
  const { validationUrl } = useParams();
  const [loading, setloading] = useState(false);
  const [contractValidation, setcontractValidation] = useState(false);
  const [errorcontractValidation, seterrorcontractValidation] = useState(false);
  const [cgv, setcgv] = useState(false);
  const [errorcgv, seterrorcgv] = useState(false);
  const [pass, setpass] = useState('');
  const [passValidation, setpassValidation] = useState({ valide: false });
  const [passError, setpassError] = useState(false);
  const [passConfirm, setpassConfirm] = useState('');
  const [passConfirmError, setpassConfirmError] = useState(false);
  const [rate1Error, setrate1Error] = useState(false);
  const [rate1, setrate1] = useState(false);
  const [rate2, setrate2] = useState(false);
  const [rate2Error, setrate2Error] = useState(false);
  const [rate3, setrate3] = useState(false);
  const [rate3Error, setrate3Error] = useState(false);
  const [submitHourly, setsubmitHourly] = useState(false);
  const [hourValue, sethourValue] = useState([
    {
      day: getFieldWithNavigatorLang({ base: strapiMapPage, field: 'monday' }),
      amStart: {
        key: 'mondayAmStart',
        value: '',
        error: true,
      },
      amEnd: {
        key: 'mondayAmEnd',
        value: '',
        error: true,
      },
      pmStart: {
        key: 'mondayPmStart',
        value: '',
        error: true,
      },
      pmEnd: {
        key: 'mondayPmEnd',
        value: '',
        error: true,
      },
      active: true,
    },
    {
      day: getFieldWithNavigatorLang({ base: strapiMapPage, field: 'tuesday' }),
      amStart: {
        key: 'tuesdayAmStart',
        value: '',
        error: true,
      },
      amEnd: {
        key: 'tuesdayAmEnd',
        value: '',
        error: true,
      },
      pmStart: {
        key: 'tuesdayPmStart',
        value: '',
        error: true,
      },
      pmEnd: {
        key: 'tuesdayPmEnd',
        value: '',
        error: true,
      },
      active: true,
    },
    {
      day: getFieldWithNavigatorLang({ base: strapiMapPage, field: 'wednesday' }),
      amStart: {
        key: 'wednesdayAmStart',
        value: '',
        error: true,
      },
      amEnd: {
        key: 'wednesdayAmEnd',
        value: '',
        error: true,
      },
      pmStart: {
        key: 'wednesdayPmStart',
        value: '',
        error: true,
      },
      pmEnd: {
        key: 'wednesdayPmEnd',
        value: '',
        error: true,
      },
      active: true,
    },
    {
      day: getFieldWithNavigatorLang({ base: strapiMapPage, field: 'thursday' }),
      amStart: {
        key: 'thursdayAmStart',
        value: '',
        error: true,
      },
      amEnd: {
        key: 'thursdayAmEnd',
        value: '',
        error: true,
      },
      pmStart: {
        key: 'thursdayPmStart',
        value: '',
        error: true,
      },
      pmEnd: {
        key: 'thursdayPmEnd',
        value: '',
        error: true,
      },
      active: true,
    },
    {
      day: getFieldWithNavigatorLang({ base: strapiMapPage, field: 'friday' }),
      amStart: {
        key: 'fridayAmStart',
        value: '',
        error: true,
      },
      amEnd: {
        key: 'fridayAmEnd',
        value: '',
        error: true,
      },
      pmStart: {
        key: 'fridayPmStart',
        value: '',
        error: true,
      },
      pmEnd: {
        key: 'fridayPmEnd',
        value: '',
        error: true,
      },
      active: true,
    },
    {
      day: getFieldWithNavigatorLang({ base: strapiMapPage, field: 'saturday' }),
      amStart: {
        key: 'saturdayAmStart',
        value: '',
        error: true,
      },
      amEnd: {
        key: 'saturdayAmEnd',
        value: '',
        error: true,
      },
      pmStart: {
        key: 'saturdayPmStart',
        value: '',
        error: true,
      },
      pmEnd: {
        key: 'saturdayPmEnd',
        value: '',
        error: true,
      },
      active: true,
    },
    {
      day: getFieldWithNavigatorLang({ base: strapiMapPage, field: 'sunday' }),
      amStart: {
        key: 'sundayAmStart',
        value: '',
        error: true,
      },
      amEnd: {
        key: 'sundayAmEnd',
        value: '',
        error: true,
      },
      pmStart: {
        key: 'sundayPmStart',
        value: '',
        error: true,
      },
      pmEnd: {
        key: 'sundayPmEnd',
        value: '',
        error: true,
      },
      active: false,
    },
  ]);

  const [paymentMethodError, setPaymentMethodError] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({
    main: [
      {
        slug: FLEET_PAYMENT.CASH, label: getFieldGroupWithNavigatorLang({ base: strapiRegister, groupe: 'payment_methods', field: 'method_1_cash' }), icon: faMoneyBillWave, active: false,
      },
      {
        slug: FLEET_PAYMENT.CREDITCARDS, label: getFieldGroupWithNavigatorLang({ base: strapiRegister, groupe: 'payment_methods', field: 'method_2_card' }), icon: faCreditCard, active: false,
      },
      {
        slug: FLEET_PAYMENT.BANKTRANSFER, label: getFieldGroupWithNavigatorLang({ base: strapiRegister, groupe: 'payment_methods', field: 'method_3_transfer' }), icon: faUniversity, active: false,
      },
      {
        slug: FLEET_PAYMENT.FUELCARD, label: getFieldGroupWithNavigatorLang({ base: strapiRegister, groupe: 'payment_methods', field: 'method_4_fuel' }), icon: faGasPump, active: false,
      },
    ],
    secondary: [
      { label: 'DKV', slug: FLEET_EXTRA_PAYMENT.DKV, active: true },
      { label: 'UTA', slug: FLEET_EXTRA_PAYMENT.UTA, active: false },
      { label: 'Total', slug: FLEET_EXTRA_PAYMENT.TOTAL, active: false },
      { label: 'Shell', slug: FLEET_EXTRA_PAYMENT.SHELL, active: false },
      { label: 'BP', slug: FLEET_EXTRA_PAYMENT.BP, active: false },
      { label: getFieldGroupWithNavigatorLang({ base: strapiRegister, groupe: 'payment_methods', field: 'method_fuel_card_other' }), slug: FLEET_EXTRA_PAYMENT.OTHER, active: false },
    ],
  });
  const handlecontractValidation = (value) => {
    setcontractValidation(value.value);
  };

  const handleSetPassword = async () => {
    const res = await definePassword({
      id: currentUser.id,
      password: pass,
      tempUrl: validationUrl,
    });

    const formated = formatApiRes(res);

    setloading(true);
    if (formated.success === true) {
      setmodal();
      setcredential({
        user: currentUser.email,
        pass,
      });
    }
  };


  const handleSaveHours = async () => {
    if (currentUser.role === 'workshop') {
      const obj = {};
      let hasError = false;
      hourValue.forEach((element) => {
        if (element.active && (element.amStart.error && element.pmStart.error)) {
          hasError = true;
        }
        if ((!element.amStart.error && element.amEnd.error) || (!element.pmStart.error && element.pmEnd.error)) {
          hasError = true;
        }
        obj[element.amStart.key] = element.amStart.value === '' ? null : element.amStart.value || null;
        obj[element.amEnd.key] = element.amEnd.value === '' ? null : element.amEnd.value || null;
        obj[element.pmStart.key] = element.pmStart.value === '' ? null : element.pmStart.value || null;
        obj[element.pmEnd.key] = element.pmEnd.value === '' ? null : element.pmEnd.value || null;
      });

      obj.userId = currentUser.UserWorkshop.id;
      obj.url = validationUrl;
      obj.id = currentUser.id;

      if (!hasError) {
        await updateWorkshopUserInRegistration(obj);
        return true;
      }
      return false;
    }

    return true;
  };

  const handleSaveRate = async () => {
    if (currentUser.role === 'workshop') {
      const obj = {
        rate1: rate1 ? parseFloat(rate1, 10) : -1,
        rate2: rate2 ? parseFloat(rate2, 10) : -1,
        rate3: rate3 ? parseFloat(rate3, 10) : -1,
        userId: currentUser.UserWorkshop.id,
        url: validationUrl,
        id: currentUser.id,
      };

      await updateWorkshopUserInRegistration(obj);
      return true;
    }

    return true;
  };

  const handleValidAccount = async () => {
    setloading(true);
    await acceptContract({
      id: currentUser.id,
      tempUrl: validationUrl,
    });
    SendMail({
      template: 'welcome',
      templateWelcome: {
        statusLabel: getFieldGroup({
          select_lang: currentUser.lang, base: strapiRegister, groupe: 'mail_welcome_user_workshop', field: 'status',
        }),
        content: getFieldGroup({
          select_lang: currentUser.lang, base: strapiRegister, groupe: 'mail_welcome_user_workshop', field: 'content',
        }),
        btnContent: getFieldGroup({
          select_lang: currentUser.lang, base: strapiRegister, groupe: 'mail_welcome_user_workshop', field: 'btn',
        }),
        btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.fleet.registerFinal.withoutparams}/${currentUser.UserWorkshop.userAccountConfirmation}`,
      },
      subject: getFieldGroup({
        select_lang: currentUser.lang, base: strapiRegister, groupe: 'mail_welcome_user_workshop', field: 'subject',
      }),
      destination: currentUser.email,
    });
    setmodal();
    setloading(false);
  };

  const handleSavePayment = async () => {
    if (step && step === 'firststep' && currentUser && currentUser.role === 'fleet') {
      const payment = formatPaymentMethods(paymentMethod.main, paymentMethod.secondary);
      if (payment.main !== '') {
        const res = await updateFleetUserInRegistration({
          userId: currentUser.UserFleet.id,
          paymentMethod: payment.main,
          paymentMethodDetail: payment.secondary,
          url: validationUrl,
          id: currentUser.id,
        });

        if (res && res.success) {
          return true;
        }
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    setrate1Error(false);
    setrate2Error(false);
    setrate3Error(false);
    setsubmitHourly(true);
    seterrorcontractValidation(false);
    setPaymentMethodError(false);
    if (step === 'firststep' && currentUser && currentUser.role === 'workshop') {
      if (contractValidation === false || (!rate1 && rate1 === '') || (!rate2 && rate2 === '') || (!rate3 && rate3 === '')) {
        setpassError(false);
        setpassConfirmError(false);
        if (contractValidation === false) {
          seterrorcontractValidation(true);
        }
        ScrollToError();
      } else {
        handleValidAccount();
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (((contractValidation === false || cgv === false) && currentUser && currentUser.role === 'fleet') || (!rate1 && rate1 === '') || (!rate2 && rate2 === '') || (!rate3 && rate3 === '')) {
        setpassError(false);
        setpassConfirmError(false);
        if (contractValidation === false) {
          seterrorcontractValidation(true);
        }
        if (cgv === false) {
          seterrorcgv(true);
        }
        ScrollToError();
      } else if (passValidation.valide === false) {
        setpassError(true);
        seterrorcontractValidation(false);
        setpassConfirmError(false);
        ScrollToError();
      } else if (pass !== passConfirm) {
        setpassConfirmError(true);
        setpassError(false);
        seterrorcontractValidation(false);
        ScrollToError();
      } else if (step === 'secondstep' && currentUser && currentUser.role === 'workshop' && cgv === false) {
        seterrorcgv(true);
        ScrollToError();
      } else {
        setloading(true);
        setpassError(false);
        setpassConfirmError(false);
        const hours = await handleSaveHours();
        const savePayment = await handleSavePayment();
        if (hours && savePayment) {
          await handleSaveRate();
          handleSetPassword();
        } else {
          setloading(false);
        }
      }
    }
  };

  const handlePassConfirm = (value) => {
    setpassConfirm(value.value);
  };

  const handlePass = (value) => {
    setpass(value.value);
  };

  const handlePassValidation = (value) => {
    setpassValidation(value);
  };

  const saveEvent = async () => { };

  const handleHourly = (value) => {
    setsubmitHourly(false);
    sethourValue(value);
  };
  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/false || !!document.documentMode;


  const handleCgv = (value) => {
    setcgv(value.value);
  };

  const renderContract = (role, lang) => {
    if (role === 'fleet') {
      return getFiles({ base: strapiContract, field: 'fleet_contract', _lang: lang });
    }
    if (role === 'workshop') {
      return getFiles({ base: strapiContract, field: 'workshop_contract', _lang: lang });
    }
    return '';
  };

  const handlePaymentChange = (res) => {
    setPaymentMethod({
      main: res.choices,
      secondary: res.fuel,
    });
  };

  return (
    <Container style={style}>
      <TitleForm type="h1">
        { currentUser.role === 'workshop'
          ? step === 'firststep'
            ? getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_workshop_supervisor_title' })
            : getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_workshop_user_title' })
          : getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_title' })}
      </TitleForm>
      <SubTitleForm>
        { currentUser.role === 'workshop'
          ? step === 'firststep'
            ? getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_workshop_supervisor_subtitle' })
            : getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_workshop_user_subtitle' })
          : getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_subtitle' })}
      </SubTitleForm>
      {
        step === 'firststep' && (
          <Content__sectionTitle
            title={
              currentUser.role === 'workshop'
                ? getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_workshop_contract_title' })
                : getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_contract_title' })
            }
            icon={<FontAwesomeIcon icon={faFileContract} />}
          />
        )
      }
      {
        step === 'firststep' && (
          isIE ? (
            <iframe
              title="contract"
              width="900px"
              height="1240px"
              src={currentUser && renderContract(currentUser.role, currentUser.countryCode)}
            >
              <p>The PDF contract cannot be preview on your browser.</p>
              <Grid__separator size="15px" />
              <Button__primary
                noprevent
                target="_blank"
                withIcon
                border
                blue
                download
                href={currentUser && renderContract(currentUser.role, currentUser.countryCode)}
                icon={<FontAwesomeIcon icon={faDownload} />}
                event={() => saveEvent()}
              >
                Download PDF contract
              </Button__primary>
            </iframe>
          ) : (
            <object
              width="900px"
              height="1240px"
              // type="application/pdf"
              type="text/html"
              data={currentUser && renderContract(currentUser.role, currentUser.countryCode)}
            >
              <p>The PDF contract cannot be preview on your browser.</p>
              <Grid__separator size="15px" />
              <Button__primary
                noprevent
                target="_blank"
                withIcon
                border
                blue
                download
                href={currentUser && renderContract(currentUser.role, currentUser.countryCode)}
                icon={<FontAwesomeIcon icon={faDownload} />}
                event={() => saveEvent()}
              >
                Download PDF contract
              </Button__primary>
            </object>
          )
        )
      }
      {
        step === 'firststep' && (
          <Fragment>
            <Grid__separator size="45px" />
            <Form__checkCondition
              value={contractValidation}
              property="contractValidation"
              content={
                currentUser.role === 'workshop'
                  ? getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_workshop_contract_cgv_label' })
                  : getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_contract_cgv_label' })
              }
              callback={handlecontractValidation}
              error={{ active: errorcontractValidation }}
            />
          </Fragment>
        )
      }
      {
        step === 'secondstep' && currentUser && currentUser.role === 'workshop' && (
          <Fragment>
            <Grid__separator size="15px" />
            <Content__sectionTitle
              title={getGlobalWithNavigatorLang({ option: 'workshopInformation', field: 'opening_hours' })}
              icon={<FontAwesomeIcon icon={faClock} />}
            />
            <Form__hourly value={hourValue} submitHourly={submitHourly} changeValue={handleHourly} />
            <Grid__separator size="100px" />
            <Content__sectionTitle
              title={getGlobalWithNavigatorLang({ option: 'workshopInformation', field: 'hourly_rate' })}
              icon={<FontAwesomeIcon icon={faTags} />}
            />
            <Form__textfield
              style={{ maxWidth: 250 }}
              value={rate1}
              label={getGlobalWithNavigatorLang({ option: 'workshopInformation', field: 'rate_1' })}
              placeholder="00"
              type="price"
              error={rate1Error ? { active: rate1Error } : false}
              change={(res) => setrate1(res.value)}
            />
            <Grid__separator size="25px" />
            <Form__textfield
              style={{ maxWidth: 250 }}
              value={rate2}
              placeholder="00"
              label={getGlobalWithNavigatorLang({ option: 'workshopInformation', field: 'rate_2' })}
              type="price"
              error={rate2Error ? { active: rate2Error } : false}
              change={(res) => setrate2(res.value)}
            />
            <Grid__separator size="25px" />
            <Form__textfield
              style={{ maxWidth: 250 }}
              value={rate3}
              placeholder="00"
              label={getGlobalWithNavigatorLang({ option: 'workshopInformation', field: 'rate_3' })}
              type="price"
              error={rate3Error ? { active: rate3Error } : false}
              change={(res) => setrate3(res.value)}
            />
            <Grid__separator size="100px" />
          </Fragment>
        )
      }
      {currentUser.role === 'fleet' ? (
        <Fragment>
          <Grid__separator size="100px" />
          <Content__sectionTitle
            title={getFieldGroupWithNavigatorLang({
              base: strapiRegister, groupe: 'payment_methods', field: 'title',
            })}
            icon={<FontAwesomeIcon icon={faMoneyCheck} />}
          />
          <InfoContent>{getFieldGroupWithNavigatorLang({ base: strapiRegister, groupe: 'payment_methods', field: 'description' })}</InfoContent>
          <Form__checkBoxCard
            choices={paymentMethod.main}
            choicesSecondary={paymentMethod.secondary}
            inputSelect={3}
            handleChange={handlePaymentChange}
            error={paymentMethodError}
          />
          <Grid__separator size="100px" />
        </Fragment>
      ) : null}
      {
        (currentUser && (currentUser.role === 'fleet' || step === 'secondstep')) ? (
          <Fragment>
            <Content__sectionTitle
              title={getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_confirmation_email_title' })}
              icon={<FontAwesomeIcon icon={faUser} />}
            />
            <Form__label>{getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_email_label' })}</Form__label>
            <Mail>
              {currentUser.email}
            </Mail>
            <Grid__separator size="35px" />
            <ContainerField>
              <Form__textfield
                change={handlePass}
                value={pass}
                property="newPass"
                labelSecondary={getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_password_sub_label' })}
                label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_password_label' })}
                type="password"
                passWordValidation={handlePassValidation}
                require
                error={{ active: passError }}
              />
              <Grid__separator size="25px" />
              <Form__textfield
                change={handlePassConfirm}
                value={passConfirm}
                property="passConfirm"
                label={getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_password_confirmation_label' })}
                type="password"
                require
                error={{ active: passConfirmError }}
              />
              <Grid__separator size="45px" />
              <Form__checkCondition
                value={cgv}
                property="cgv"
                content={(
                  <span>
                    {getFieldWithNavigatorLang({ base: strapiRegister, field: 'condition_text' })}
                    {' '}
                    <a target="_blank" rel="noopener noreferrer" href={ROUTING.fleet.generalCondition.path}>{getFieldWithNavigatorLang({ base: strapiRegister, field: 'condition_linktext' })}</a>
                    {' '}
                    {getFieldWithNavigatorLang({ base: strapiRegister, field: 'condition_text2' })}
                  </span>
                )}
                error={{ active: errorcgv }}
                callback={handleCgv}
              />
              <Grid__separator size="45px" />
              <ValidateBtn event={handleSubmit}>{getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_submit' })}</ValidateBtn>
              <Grid__separator size="15px" />
            </ContainerField>
            <Grid__separator size="45px" />
          </Fragment>
        ) : (
          <ContainerField>
            <Grid__separator size="35px" />
            <ValidateBtn event={handleSubmit}>
              {
                step === 'firststep'
                  ? getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_supervisor_submit' })
                  : getFieldWithNavigatorLang({ base: strapiRegister, field: 'finalstep_submit' })

              }
            </ValidateBtn>
            <Grid__separator size="15px" />
          </ContainerField>
        )
      }
      {loading ? <Loader__wrapper /> : false}
    </Container>
  );
};

export default RegisterFinal__form;
