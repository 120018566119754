export const getNavigatorLang = () => {
  const navigatorLang = navigator.language || navigator.userLanguage;
  const lang = navigatorLang.split('-');
  let currentLang = 'en';

  if (lang) {
    // eslint-disable-next-line prefer-destructuring
    currentLang = lang[0];
    if (lang[0]) {
      currentLang = lang[0].toLowerCase();
    }
  }
  return {
    navigatorLang,
    lang: currentLang,
  };
};
