const breakpointJson = require('customtheme.json').breakpoint;

export const breakpoint = {
  sm: '768px',
  tab: '992px',
  md: '1200px',
  mdlg: '1200px',
  lg: '1440px',
  xl: '2500px',
  ...breakpointJson,
};
