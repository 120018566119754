export const formatPrice = (string, noCurrency) => {
  if (noCurrency) {
    return new Intl.NumberFormat().format(string);
  }

  return new Intl.NumberFormat('fr-Fr', { style: 'currency', currency: 'EUR' }).format(string);
};

export const isFree = (price) => {
  if (price !== null) {
    if (Number.isNaN(price)) {
      return false;
    }
    const currentPrice = parseInt(price, 10);
    if (currentPrice <= 0) {
      return true;
    }
  }

  return false;
};
