import moment from 'moment';

const dayArray = [
  {
    amStart: 'sundayAmStart',
    amEnd: 'sundayAmEnd',
    pmStart: 'sundayPmStart',
    pmEnd: 'sundayPmEnd',
  },
  {
    amStart: 'mondayAmStart',
    amEnd: 'mondayAmEnd',
    pmStart: 'mondayPmStart',
    pmEnd: 'mondayPmEnd',
  },
  {
    amStart: 'tuesdayAmStart',
    amEnd: 'tuesdayAmEnd',
    pmStart: 'tuesdayPmStart',
    pmEnd: 'tuesdayPmEnd',
  },
  {
    amStart: 'wednesdayAmStart',
    amEnd: 'wednesdayAmEnd',
    pmStart: 'wednesdayPmStart',
    pmEnd: 'wednesdayPmEnd',
  },
  {
    amStart: 'thursdayAmStart',
    amEnd: 'thursdayAmEnd',
    pmStart: 'thursdayPmStart',
    pmEnd: 'thursdayPmEnd',
  },
  {
    amStart: 'fridayAmStart',
    amEnd: 'fridayAmEnd',
    pmStart: 'fridayPmStart',
    pmEnd: 'fridayPmEnd',
  },
  {
    amStart: 'saturdayAmStart',
    amEnd: 'saturdayAmEnd',
    pmStart: 'saturdayPmStart',
    pmEnd: 'saturdayPmEnd',
  },
];

const createSlug = (date, hour) => {
  const _date = date;
  const split = (hour || '9:00').split(':');
  _date.hours(split[0]);
  _date.minutes(split[1]);
  return {
    slug: _date.toISOString(),
    label: _date.format('HH:mm'),
  };
};

const getNextDate = (nextSlug) => createSlug(moment(nextSlug).add(30, 'minutes'), moment(nextSlug).add(30, 'minutes').format('HH:mm'));

export const CreateTimeArray = (workshop, date) => {
  const object = [];

  if (!workshop || !workshop.UserWorkshop) {
    return object;
  }

  if (date) {
    const _date = moment(date, 'DD/MM/YYYY');
    if (_date.isValid()) {
      const number = _date.day();
      const day = dayArray[number];
      if (day) {
        if (workshop.UserWorkshop[day.amStart] && workshop.UserWorkshop[day.amEnd]) {
          const firstDate = workshop.UserWorkshop[day.amStart];
          const endDate = workshop.UserWorkshop[day.amEnd];
          const slug = createSlug(_date, firstDate);
          const endSlug = createSlug(_date, endDate);
          object.push({
            slug: slug.slug,
            label: slug.label,
            active: false,
            disabled: moment().add('hour', 1).isAfter(moment(slug.slug)),
          });
          while (
            moment(endSlug.slug).isAfter(getNextDate(object[object.length - 1].slug).slug)
            ) {
            const _slug = getNextDate(object[object.length - 1].slug);
            object.push({
              slug: _slug.slug,
              label: _slug.label,
              active: false,
              disabled: moment().add('hour', 1).isAfter(moment(getNextDate(object[object.length - 1].slug).slug)),
            });
          }
        }
        if (workshop.UserWorkshop[day.pmStart] && workshop.UserWorkshop[day.pmEnd]) {
          const firstDate = workshop.UserWorkshop[day.pmStart];
          const endDate = workshop.UserWorkshop[day.pmEnd];
          const slug = createSlug(_date, firstDate);
          const endSlug = createSlug(_date, endDate);
          object.push({
            slug: slug.slug,
            label: slug.label,
            active: false,
            disabled: moment().add('hour', 1).isAfter(moment(slug.slug)),
          });
          while (
            moment(endSlug.slug).isAfter(getNextDate(object[object.length - 1].slug).slug)
          ) {
            const _slug = getNextDate(object[object.length - 1].slug);
            object.push({
              slug: _slug.slug,
              label: _slug.label,
              active: false,
              disabled: moment().add('hour', 1).isAfter(moment(getNextDate(object[object.length - 1].slug).slug)),
            });
          }
        }
      }
    }
  }

  return object;
};

export const getClosedDay = (workshop) => {
  const object = [];

  if (!workshop) {
    return object;
  }

  dayArray.forEach((element, key) => {
    if (!workshop[element.amStart] && !workshop[element.pmStart]) {
      object.push(key);
    }
  });

  return object;
};
