
import React from 'react';
import LeadGenerationWorkshopDasboard from 'components/services/LeadGeneration/workshop/dashboard/Dashboard';
import { config } from 'util/constant/leadGeneration/smartMaintenance.config';

/**
 * @name DashboardSmartMaintenance
 */

const DashboardSmartMaintenance = () => (
  <LeadGenerationWorkshopDasboard
    config={config}
  />
);

export default DashboardSmartMaintenance;
