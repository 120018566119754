
import React, {
  useEffect, useState, Fragment, useContext,
} from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { Grid__separator } from 'components/grid/grid';
import Content__labelText from 'components/content/labelText/content__labelText';
import Content__labelTextarea from 'components/content/labelTextarea/content__labelTextarea';
import Content__serviceStatus from 'components/content/serviceStatus/content__serviceStatus';
import { GetSingleService } from 'helpers/api/routes/Priority90';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import Content__tag from 'components/content/tag/content__tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getFleetInformation,
  getFleetUserInformation,
  getWorkshopInformation,
} from 'util/service/getDataFromService';
import { strapiPriority90Service } from 'util/strapi/content.starpi';
import { sequelizeDate, sequelizeDateTime } from 'util/date.util';
import { isWaiting, isError } from 'util/conditions/checkstatus.condition.js';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import { faTruck, faWarehouse, faTools } from '@fortawesome/pro-solid-svg-icons';
import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import {
  Header,
  Title,
  ItemContainer,
  Row,
  Popup,
} from 'containers/workshopView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import { SERVICENAME } from 'util/service/serviceName';
import { AppContext } from 'context/appContext';
import { renderPriority90FleetStatus, parseStatus } from 'util/conditions/statusProps.util';


const Container = styled.div``;

const Column = styled.div``;

const ServiceStatusTitle = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
`;

const DashboardPriority90__singlePop = ({
  style, active, closeEvent, id,
}) => {
  const { user } = useContext(AppContext);
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(false);
  const {
    getFieldGroup, loadPage, haveContentFor, getField, getGlobal,
  } = useContext(ContentContext);
  const getService = async () => {
    const res = await GetSingleService({ id });
    if (res) {
      setdata(res.data.data);
      setloading(false);
    }
  };

  useEffect(() => {
    if (haveContentFor(strapiPriority90Service).success === false) {
      loadPage(strapiPriority90Service);
    }
  }, []);

  useEffect(() => {
    if (active === true) {
      setloading(true);
      getService();
    }
  }, [active]);

  const renderLoader = () => <Loader__wrapper />;

  if (
    haveContentFor(strapiPriority90Service).success === false
  ) {
    return (
      <Container style={style}>
        <Popup active={active} closeEvent={closeEvent}>
          {renderLoader()}
        </Popup>
      </Container>
    );
  }

  const renderEndText = (content) => {
    const { statusText } = renderPriority90FleetStatus(data.status);
    if (statusText === 'waiting' || statusText === 'action') {
      return getField({ base: strapiPriority90Service, field: 'single_request_notavailable' });
    }

    return content;
  };

  const renderContent = () => (
    <Fragment>
      <Header>
        <Title>{getField({ base: strapiPriority90Service, field: 'service_title' })}</Title>
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_id' })}
          value={data.uid}
        />
        <Grid__separator width="15px" />
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_date' })}
          value={sequelizeDate({ date: data.createdAt, format: 'd/m/y' })}
        />
      </Header>
      <Grid__separator size="80px" />
      <Content__serviceStatus
        tag={(
          <Content__tag
            style={{ position: 'absolute', top: 25, right: 25 }}
            border
            content={
              data.status === 'done'
                ? getField({ base: strapiPriority90Service, field: 'status_fleet_done' })
                : getFieldGroup({
                  base: strapiPriority90Service,
                  groupe: 'status',
                  field: renderPriority90FleetStatus(data.status),
                })
            }
            {...parseStatus({ status: renderPriority90FleetStatus(data.status) })}
          />
        )}
        {...parseStatus({ status: renderPriority90FleetStatus(data.status) })}
      >
        {
          isWaiting(renderPriority90FleetStatus(data.status)) ? (
            <div>
              <ServiceStatusTitle>
                {getField({ base: strapiPriority90Service, field: 'waiting_text' })}
              </ServiceStatusTitle>
            </div>
          ) : (
            <div>
              <ServiceStatusTitle>
                {getField({ base: strapiPriority90Service, field: 'response_text' })}
              </ServiceStatusTitle>
            </div>
          )
        }
      </Content__serviceStatus>
      <Grid__separator size="100px" />
      <Row>
        <ItemContainer>
          <Content__sectionTitle
            line
            title={getField({ base: strapiPriority90Service, field: 'vehicle_title' })}
            icon={<FontAwesomeIcon icon={faTruck} />}
          />
          <Row>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' })}
                content={getFleetInformation({ obj: data, key: 'registrationNumber' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'category' })}
                content={getGlobal({ option: 'vehicletype', field: getFleetInformation({ obj: data, key: 'type' }) }) || ''}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' })}
                content={getGlobal({ option: 'country', field: getFleetInformation({ obj: data, key: 'countryOfRegistration' }) }) || ''}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'mileage' })}
                content={getFleetInformation({ obj: data, key: 'mileage' })}
              />
            </Column>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'vincode' })}
                content={getFleetInformation({ obj: data, key: 'vin' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'brand' })}
                content={getFleetInformation({ obj: data, key: 'brand' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label="Fleet"
                content={getFleetUserInformation({ obj: data, key: 'companyName' })}
              />
            </Column>
          </Row>
        </ItemContainer>
        <ItemContainer>
          <Content__sectionTitle
            line
            title={getField({ base: strapiPriority90Service, field: 'workshop_title' })}
            icon={<FontAwesomeIcon icon={faWarehouse} />}
          />
          <Row>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'name' })}
                content={getWorkshopInformation({ obj: data, key: 'companyName' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'address' })}
                content={getWorkshopInformation({ obj: data, key: 'adress' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })}
                content={`${getWorkshopInformation({ obj: data, key: 'phoneNumber' })}`}
              />
            </Column>
            <Column>
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'workshopInformation', field: 'country' })}
                content={getWorkshopInformation({ obj: data, key: 'region' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'city' })}
                content={getWorkshopInformation({ obj: data, key: 'city' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'zipcode' })}
                content={getWorkshopInformation({ obj: data, key: 'zipcode' })}
              />
            </Column>
          </Row>
        </ItemContainer>
      </Row>
      <Grid__separator size="100px" />
      <Content__sectionTitle
        line
        title={getField({ base: strapiPriority90Service, field: 'repair_title' })}
        icon={<FontAwesomeIcon icon={faTools} />}
      />
      <Row>
        <Column style={{ width: '60%' }}>
          <Content__labelTextarea
            label={getField({ base: strapiPriority90Service, field: 'single_service_description' })}
            lang={data.textLang}
            convert={user.user.lang}
            content={renderEndText(data.description)}
            dataName={SERVICENAME.priority90}
            base={data.translate}
            id={data.id}
          />
        </Column>
      </Row>
    </Fragment>
  );

  return (
    <Container style={style}>
      <Popup active={active} closeEvent={closeEvent}>
        {loading || !data ? renderLoader() : renderContent()}
      </Popup>
    </Container>
  );
};

export default DashboardPriority90__singlePop;
