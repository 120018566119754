/**
 * Import Lib & assets
 */

import React, {
  useEffect, useState, Fragment, useContext,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
  geocodeByAddress,
} from 'react-google-places-autocomplete';
import GoogleMapReact from 'google-map-react';
import './form__map.scss';
import Form__label from 'components/form/label/form__label';
import { Grid__separator } from 'components/grid/grid';

/**
 * import components
 */
import { ContentContext } from 'context/contentContext';
import { faTrumpet } from '@fortawesome/pro-solid-svg-icons';
import styles from './mapsStyle';


/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 110px;
  margin-top: 30px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

const Marker = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${t.color.cyan};
  ${t.mixins.flex()};
  border-radius: 100%;
  transform: translate(-50%, -50%);
`;

const MarkerChild = styled.div`
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 100%;
`;


const ErrorText = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  color: #E74C3C;
`;

/**
 * map component is..
 */
const Form__map = ({
  style, event, labelStyle, label, error, placeholder, value, require, noPostalCode,
}) => {
  const [coord, setCoord] = useState({ lat: 48.864716, lng: 2.349014, set: false });
  const [errormap, seterrormap] = useState(false);
  const { getGlobal } = useContext(ContentContext);
  const findKey = (data, key, short) => {
    if (data) {
      const formated = data.filter((item) => {
        if (item && item.types) {
          return item.types.filter((type) => type === key).length > 0;
        }
        return false;
      });

      if (formated && formated[0] && (formated[0].long_name || formated[0].short_name)) {
        return short ? formated[0].short_name : formated[0].long_name || formated[0].short_name;
      }
      return formated[0] || '';
    }

    return false;
  };

  const formatAddress = (data, otherData) => {
    if (data) {
      const obj = {
        zipCode: findKey(data, 'postal_code') || '',
        number: findKey(data, 'street_number') || findKey(data, 'premise') || '',
        address: findKey(data, 'route') || '',
        country: findKey(data, 'country', true) === 'RE' ? 'france' : findKey(data, 'country') || '',
        countryCode: findKey(data, 'country', true) === 'RE' ? 'FR' : findKey(data, 'country', true) || '',
        city: findKey(data, 'locality') || findKey(data, 'administrative_area_level_1') || '',
        description: otherData && otherData.structured_formatting && otherData.structured_formatting.main_text ? otherData.structured_formatting.main_text : findKey(data, 'route') || '',
      };

      if (
        ((findKey(data, 'postal_code') || noPostalCode)
        && findKey(data, 'route')
        && findKey(data, 'country'))
        || (findKey(data, 'country', true) === 'PL' && findKey(data, 'postal_code'))
        || (findKey(data, 'country', true) === 'RE' && findKey(data, 'route') && findKey(data, 'country'))
      ) {
        return obj;
      }

      return false;
    }

    return false;
  };

  const DisableAutoFill = () => {
    const inputMap = document.querySelector('.google-places-autocomplete__input');
    inputMap.setAttribute('autocomplete', 'chrome-off');
  };


  const handle = (e) => {
    let resultAdress = null;
    geocodeByPlaceId(e.place_id)
      .then(async (results) => {
        if (results[0] !== null) {
          if (results[0].hasOwnProperty('address_components')) {
            resultAdress = results[0].address_components;
          }
        }

        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        if (event) {
          seterrormap(false);
          if (formatAddress(resultAdress, e)) {
            event({
              coord: { lat, lng, set: true },
              place: e,
              address: resultAdress,
              formated: formatAddress(resultAdress, e),
              id: e.place_id,
            });
          } else {
            seterrormap(true);
          }
        }
        setCoord({ lat, lng, set: true });
      });
  };

  const initialStateEvent = () => {
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setCoord({ lat, lng, set: true });
      });
  };

  useEffect(() => {
    DisableAutoFill();
    if (value) {
      initialStateEvent();
    }
  }, [value]);

  return (
    <Container style={style} className={errormap || (error && error.active) ? 'mapInputContainer error formError' : 'mapInputContainer'}>
      <Form__label require={require} style={labelStyle}>
        {label}
      </Form__label>
      <GooglePlacesAutocomplete
        onSelect={(e) => handle(e)}
        placeholder={placeholder || ''}
        initialValue={value || ''}
      />
      {errormap && (
        <Fragment>
          <Grid__separator size="10px" />
          <ErrorText>{getGlobal({ option: 'error', field: 'address_field' })}</ErrorText>
        </Fragment>
      )}
      <MapContainer className="mapContainer">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API }}
          zoomControl={false}
          mapTypeControl={false}
          scaleControl={false}
          streetViewControl={false}
          rotateControl={false}
          fullscreenControl={false}
          options={{ styles }}
          defaultCenter={{
            lat: coord.lat,
            lng: coord.lng,
          }}
          defaultZoom={15}
          center={{
            lat: coord.lat,
            lng: coord.lng,
          }}
        >
          {coord.set === true ? (
            <Marker lat={coord.lat} lng={coord.lng} text="My Marker">
              <MarkerChild />
            </Marker>
          ) : null}
        </GoogleMapReact>
      </MapContainer>
    </Container>
  );
};

export default Form__map;
