
/**
   * Import Lib
   */

import React, { useContext } from 'react';
import styled from 'styled-components';
import Cards__checkPlate from 'components/cards/checkPlate/cards__checkPlate';
import { ContentContext } from 'context/contentContext';
import { strapiWorkshopCheck } from 'util/strapi/content.starpi';


const Container = styled.div`
  max-width: 640px;
  text-align: center;
`;

const CheckVehicle__form = ({ style }) => {
  const { getField } = useContext(ContentContext);
  return (
    <Container style={style}>
      <Cards__checkPlate
        title={getField({ base: strapiWorkshopCheck, field: 'title' })}
        content={getField({ base: strapiWorkshopCheck, field: 'text' })}
      />
    </Container>
  );
};

export default CheckVehicle__form;
