/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  opacity: ${({ hide }) => (hide ? '0' : '1')};
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #a0a0a1;
`;

const Content = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
  margin-top: ${(props) => (props.noMargin ? '0' : '10px')};
  line-height: 1.3;
  text-transform: ${(props) => (props.capitalize ? 'capitalize' : 'initial')};
`;

const Content__labelText = ({
  style, label, content, className, capitalize, hide, noMargin,
}) => (
  <Container style={style} hide={hide}>
    <Label className={className}>{label}</Label>
    <Content noMargin={noMargin} capitalize={capitalize}>{content}</Content>
  </Container>
);

export default Content__labelText;
