
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { checkFieldValue, createArrayOfData, createResponseObj } from 'helpers/js/Form';
import { slugify } from 'helpers/js/Utile';
/**
 * import containers & components
 */
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';

import Button__primary from 'components/button/primary/button__primary';
import { Grid__separator } from 'components/grid/grid';
import { Form__select } from 'components/form/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-regular-svg-icons';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { Content__sectionTitle } from 'components/content/content';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import Form__checkCondition from 'components/form/checkCondition/form__checkCondition';

/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const FormContainer = styled.div``;

const MailTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #808080;
  margin-bottom: 15px;
  text-transform: uppercase;
`;

/**
 * information component is..
 */

const Account__settings = ({ style, basePage }) => {
  const {
    getField, getGlobalObj, getGlobal, availableLang, changeLang,
  } = useContext(ContentContext);
  const { user, updateUser } = useContext(AppContext);
  const [activeButton, setActiveButton] = useState(false);
  const [langSelect, setlangSelect] = useState([]);
  const [loading, setloading] = useState(false);
  const [mailNotif, setMailNotif] = useState(user.user.notificationDisable);

  /* ------------------------------ DEFINE STATE ------------------------------ */

  const [state, setState] = useState({
    lang: langSelect,
    result: {
      lang: { result: langSelect[0], type: 'select' },
    },
  });

  /* -------------------------- CHECK STATUS OF FIELD ------------------------- */

  const checkSaveStatus = () => {
    const arrayOfField = [state.result.lang];

    const data = createArrayOfData({ array: arrayOfField });

    if (data.filter((el) => el === null || el === '').length <= 0) {
      setActiveButton(true);
    } else if (activeButton) {
      setActiveButton(false);
    }
  };

  useEffect(() => {
    const keyObject = Object.keys(getGlobalObj({ option: 'language' }));
    const selectArray = [];

    keyObject.forEach((element) => {
      if (availableLang.find((asLang) => element === asLang)) {
        const obj = {
          label: getGlobal({ option: 'language', field: element }),
          slug: slugify(element),
          active: false,
        };
        selectArray.push(obj);
      }
    });

    selectArray[0].active = true;
    setlangSelect(selectArray);

    setState((prevState) => ({
      ...prevState,
      lang: selectArray,
      result: {
        ...prevState.result,
        lang: { result: selectArray[0], type: 'select' },
      },
    }));
  }, []);

  const setinitialDate = () => {
    setState((prevState) => ({
      ...prevState,
      lang: checkFieldValue({
        field: user.user.lang,
        type: 'select',
        additionalCondition: 'slug',
        base: langSelect,
      }).base,
      result: {
        ...prevState.result,
        lang: checkFieldValue({
          field: user.user.lang,
          type: 'select',
          additionalCondition: 'slug',
          base: langSelect,
        }).result,
      },
    }));
  };

  useEffect(() => {
    if (langSelect.length > 0) {
      setinitialDate();
    }
  }, [langSelect]);

  useEffect(() => {
    if (langSelect.length > 0) {
      checkSaveStatus();
    }
  }, [state]);

  /* --------------------------- STATE CHANGE EVENT --------------------------- */

  const handleStateChange = ({
    property, value, result, type,
  }) => {
    const currentState = state;

    currentState.result[property] = { result, type };

    setState((prevState) => ({
      ...prevState,
      [property]: value,
      result: {
        ...prevState.result,
        [property]: { result, type },
      },
    }));
  };

  /* ------------------------------- SAVE EVENT ------------------------------- */

  const saveEvent = async () => {
    setloading(true);
    const arrayOfField = [
      { value: state.result.lang, key: 'lang' },
      { value: { type: 'bool', result: mailNotif }, key: 'notificationDisable' },
    ];

    const data = createResponseObj({ data: arrayOfField });
    changeLang(state.result.lang.result.slug);
    await updateUser({ field: data });
    setloading(false);
  };

  const handleMailNotification = (value) => {
    setMailNotif(value.value);
  };

  /* ------------------------------- RENDER FORM ------------------------------ */

  const renderContactInformationForm = () => (
    <FormContainer>
      <Form__select
        value={state.lang}
        label={getField({ base: basePage, field: 'country_settings' })}
        style={{ width: '100%', maxWidth: '100%', marginRight: 10 }}
        event={(res) => handleStateChange({
          property: 'lang',
          value: res.value,
          result: res.result,
          type: 'select',
        })}
        require
      />
      <Grid__separator size="35px" />
      <MailTitle>
        {getField({ base: basePage, field: 'turn_off_mail_title' })}
      </MailTitle>
      <Form__checkCondition
        red
        content={getField({ base: basePage, field: 'turn_off_mail' })}
        property="mailNotif"
        callback={handleMailNotification}
        value={mailNotif}
      />
      <Grid__separator size="25px" />
      <Button__primary
        withIcon
        icon={<FontAwesomeIcon icon={faSave} />}
        event={() => saveEvent()}
        style={
          activeButton === false
            ? {
              cursor: 'intial',
              pointerEvents: 'none',
              userSelect: 'none',
              opacity: 0.3,
            }
            : {}
        }
      >
        {getField({ base: basePage, field: 'save_button_settings' })}
      </Button__primary>
    </FormContainer>
  );

  /* ------------------------------ GLOBAL RENDER ----------------------------- */
  return (
    <Container style={style}>
      <Content__sectionTitle
        title={getField({ base: basePage, field: 'settings_title' })}
        icon={<FontAwesomeIcon icon={faCog} />}
      />
      {renderContactInformationForm()}
      {loading && (<Loader__wrapper fixedContainer fullWidth />)}
    </Container>
  );
};

export default Account__settings;
