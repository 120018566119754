
import React, {
  useEffect, useState, Fragment, useContext,
} from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import t from 'theme';
import { useParams, useHistory } from 'react-router-dom';
import Message__sucess from 'components/message/sucess/message__sucess';
import { getNavigatorLang } from 'util/function/getNavigatorLang.function';
import {
  Popup,
} from 'containers/fleetView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import { getParams } from 'util/get.util';
import Transition__loader_controller from 'components/transition/loader_controller/transition__loader_controller';

const Container = styled.div`
  padding-bottom: 100px;
  max-width: 1100px;
  margin: 0 auto;
`;

/**
 * @name Confirmation
 */

const Confirmation = ({ config }) => {
  const [loading, setloading] = useState(false);
  const [active, setActive] = useState(false);
  const [loadingAction, setloadingAction] = useState(false);
  const [acceptRequest, setacceptRequest] = useState(false);
  const [declineRequest, setdeclineRequest] = useState(false);
  const [data, setdata] = useState(false);
  const {
    loadPage, haveContentFor, getFieldGroup,
  } = useContext(ContentContext);
  const { id } = useParams();
  const history = useHistory();


  const handleRefuse = async (dataRes) => {
    setloadingAction(true);
    await config.api.update({
      id: dataRes.id,
      status: config.constant.mailStatus.ERROR,
      accepted: false,
      url: id,
    });
    setdeclineRequest(true);
    setActive(true);
    setloadingAction(false);
  };


  const handleAccept = async (dataRes) => {
    setloadingAction(true);
    await config.api.update({
      id: dataRes.id,
      status: config.constant.mailStatus.DONE,
      accepted: true,
      url: id,
    });
    setacceptRequest(true);
    setActive(true);
    setloadingAction(false);
  };

  const getParamsData = (dataRes) => {
    if (getParams('accept')) {
      handleAccept(dataRes);
    }

    if (getParams('declined')) {
      handleRefuse(dataRes);
    }
  };

  const getService = async () => {
    const res = await config.api.getSingleService(id);
    if (res && res.data.success === true) {
      setdata(res.data.data);
      setloading(false);
      getParamsData(res.data.data);
    } else {
      history.push('/login');
    }
  };

  useEffect(() => {
    if (haveContentFor(config.strapiPage).success === false) {
      loadPage(config.strapiPage);
    }
  }, []);

  useEffect(() => {
    setloading(true);
    getService();
  }, []);

  const renderLoader = () => <Loader__wrapper />;


  const renderContent = () => (
    declineRequest ? (
      <Fragment>
        <Message__sucess
          icon={t.image.illu.error}
          title={getFieldGroup({
            select_lang: getNavigatorLang().lang, base: config.strapiPage, groupe: config.content.lead_popup_refuse.group, field: config.content.lead_popup_refuse.title,
          })}
          text={getFieldGroup({
            select_lang: getNavigatorLang().lang, base: config.strapiPage, groupe: config.content.lead_popup_refuse.group, field: config.content.lead_popup_refuse.content,
          })}
          hideButton
        />
        {loadingAction && (renderLoader())}
      </Fragment>
    ) : acceptRequest ? (
      <Fragment>
        <Message__sucess
          icon={t.image.illu.check}
          title={getFieldGroup({
            select_lang: getNavigatorLang().lang, base: config.strapiPage, groupe: config.content.lead_popup_accept.group, field: config.content.lead_popup_accept.title,
          })}
          text={getFieldGroup({
            select_lang: getNavigatorLang().lang, base: config.strapiPage, groupe: config.content.lead_popup_accept.group, field: config.content.lead_popup_accept.content,
          })}
          hideButton
        />
        {loadingAction && (renderLoader())}
      </Fragment>
    ) : null
  );

  return (
    <Container>
      <Transition__loader_controller active={false} />
      <Popup active={active} noClose>
        {loading || !data ? renderLoader() : renderContent()}
      </Popup>
    </Container>
  );
};

export default Confirmation;
