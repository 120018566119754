
import React, {
  useEffect, useState, Fragment, useContext,
} from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { SendMail } from 'helpers/api/routes/Mail';
import { Grid__separator } from 'components/grid/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTING } from 'util/constant/routing.constant';
import {
  faWarehouse, faTruck, faTools, faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import Content__labelText from 'components/content/labelText/content__labelText';
import Content__labelTextarea from 'components/content/labelTextarea/content__labelTextarea';
import Content__serviceStatus from 'components/content/serviceStatus/content__serviceStatus';
import { GetSingleService, UpdateStatus } from 'helpers/api/routes/RepairApproval';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { Button__inline } from 'components/button/button';
import Content__tag from 'components/content/tag/content__tag';
import {
  getFleetInformation,
  getFleetUserInformation,
  getWorkshopInformation,
} from 'util/service/getDataFromService';
import { strapiRepairApprovalService } from 'util/strapi/content.starpi';
import { parseStatus } from 'util/conditions/statusProps.util';
import { sequelizeDate } from 'util/date.util';
import { formatPrice } from 'util/format/price.format';
import { parseFile } from 'util/format/parseFile.format';
import { isWaiting, isError, isAction } from 'util/conditions/checkstatus.condition.js';
import Message__sucess from 'components/message/sucess/message__sucess';
import t from 'theme';
import Content__statusAnwser from 'components/content/statusAnwser/content__statusAnwser';
import { checkIfActionWorkshop } from 'util/conditions/repairApproval.condition';

import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import { AppContext } from 'context/appContext';
import { setAction } from 'util/function/reloadAction.function';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import {
  Header,
  Title,
  ItemContainer,
  Row,
  Popup,
} from 'containers/workshopView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import { SERVICENAME } from 'util/service/serviceName';


const Container = styled.div``;

const Column = styled.div``;

const ServiceStatusTitle = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
`;

const DashboardRepairApproval__singlePop = ({
  style, active, closeEvent, id,
}) => {
  const [loading, setloading] = useState(false);
  const [loadingAction, setloadingAction] = useState(false);
  const [acceptRequest, setacceptRequest] = useState(false);
  const [loadingUser, setloadingUser] = useState(false);
  const [declineRequest, setdeclineRequest] = useState(false);
  const [data, setdata] = useState(false);
  const { getUser, user } = useContext(AppContext);
  const {
    getFieldGroup, loadPage, haveContentFor, getField, getGlobal,
  } = useContext(ContentContext);
  const getService = async () => {
    const res = await GetSingleService({ id });
    if (res) {
      setdata(res.data.data);
      setloading(false);
    }
  };

  useEffect(() => {
    if (haveContentFor(strapiRepairApprovalService).success === false) {
      loadPage(strapiRepairApprovalService);
    }
  }, []);

  useEffect(() => {
    if (active === true) {
      setloading(true);
      getService();
    }
  }, [active]);

  const renderLoader = () => <Loader__wrapper />;

  if (
    haveContentFor(strapiRepairApprovalService).success === false
  ) {
    return (
      <Container style={style}>
        <Popup active={active} closeEvent={closeEvent}>
          {renderLoader()}
        </Popup>
      </Container>
    );
  }

  const handleRefuse = async () => {
    setloadingAction(true);
    const updateRes = await UpdateStatus({
      id: data.id,
      status: SERVICE_STATUS.error,
      statusText: '',
      confirmation: false,
      approverType: 'workshop',
      approvedById: user.user.id,
    });
    if (updateRes) {
      setloadingAction(false);
      setdeclineRequest(true);

      if (!getWorkshopInformation({ obj: data, key: 'notificationDisable' })) {
        SendMail({
          template: 'notification',
          templateNotification: {
            status: 'error',
            statusLabel: getFieldGroup({
              select_lang: getWorkshopInformation({ obj: data, key: 'lang' }), base: strapiRepairApprovalService, groupe: 'mail_end_refused', field: 'status',
            }),
            content: `${user.user.companyName} ${getFieldGroup({
              select_lang: getWorkshopInformation({ obj: data, key: 'lang' }), base: strapiRepairApprovalService, groupe: 'mail_end_refused', field: 'content',
            })}`,
            btnContent: getFieldGroup({
              select_lang: getWorkshopInformation({ obj: data, key: 'lang' }), base: strapiRepairApprovalService, groupe: 'mail_end_refused', field: 'btn',
            }),
            btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.workshop.dashboardRepairApproval.path}`,
          },
          subject: getFieldGroup({
            select_lang: getWorkshopInformation({ obj: data, key: 'lang' }), base: strapiRepairApprovalService, groupe: 'mail_end_refused', field: 'subject',
          }),
          destination: getWorkshopInformation({ obj: data, key: 'email' }),
        });
      }
    }
  };

  const handleAccept = async () => {
    setloadingAction(true);
    const updateRes = await UpdateStatus({
      id: data.id,
      status: SERVICE_STATUS.done,
      statusText: '',
      confirmation: false,
      approverType: 'workshop',
      approvedById: user.user.id,
    });
    if (updateRes) {
      setloadingAction(false);
      setacceptRequest(true);
      if (!getWorkshopInformation({ obj: data, key: 'notificationDisable' })) {
        SendMail({
          template: 'notification',
          templateNotification: {
            status: 'validate',
            statusLabel: getFieldGroup({
              select_lang: getWorkshopInformation({ obj: data, key: 'lang' }), base: strapiRepairApprovalService, groupe: 'mail_end_accept', field: 'subject',
            }),
            content: `${user.user.companyName} ${getFieldGroup({
              select_lang: getWorkshopInformation({ obj: data, key: 'lang' }), base: strapiRepairApprovalService, groupe: 'mail_end_accept', field: 'content',
            })}`,
            btnContent: getFieldGroup({
              select_lang: getWorkshopInformation({ obj: data, key: 'lang' }), base: strapiRepairApprovalService, groupe: 'mail_end_accept', field: 'btn',
            }),
            btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.workshop.dashboardRepairApproval.path}`,
          },
          subject: getFieldGroup({
            select_lang: getWorkshopInformation({ obj: data, key: 'lang' }), base: strapiRepairApprovalService, groupe: 'mail_end_accept', field: 'subject',
          }),
          destination: getWorkshopInformation({ obj: data, key: 'email' }),
        });
      }
    }
  };

  const handleReloadData = async () => {
    const resUser = await getUser();
    if (resUser.success === true) {
      setloadingUser(false);
      setAction({ key: 'action', value: data.id });
    }
  };

  const handleCloseEvent = async () => {
    if (acceptRequest || declineRequest) {
      const resUser = await getUser();
      if (resUser.success === true) {
        closeEvent();
      }
    } else {
      closeEvent();
    }
  };

  const getUserApproverInformation = () => {
    if (data) {
      if (data.approverType === 'tier') {
        return {
          field1: getField({ base: strapiRepairApprovalService, field: 'third_party_approver' }),
          field2: data ? data.approvedByMail : null,
          field3: false,
          field4: false,
          field5: false,
          field6: false,
        };
      }
      if (data.UserApprover) {
        return {
          field1: `${data.UserApprover.companyName} (${data.approverType === 'workshop' ? getGlobal({ option: 'service_table', field: 'id_header' }) : getGlobal({ option: 'vehicleParameter', field: 'fleet' })})`,
          field2: data.UserApprover.adress,
          field3: `${data.UserApprover.zipcode}, ${data.UserApprover.city}`,
          field4: `${data.UserApprover.phoneNumber}`,
          field5: data.UserApprover.email,
          field6: `${data.UserApprover.adress}, ${data.UserApprover.city}, ${data.UserApprover.zipcode}`,
        };
      }
    }

    return {
      field1: false,
      field2: false,
      field3: false,
      field4: false,
      field5: false,
      field6: false,
    };
  };

  const renderContent = () => (
    <Fragment>
      <Header>
        <Title>{getField({ base: strapiRepairApprovalService, field: 'service_title' })}</Title>
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_id' })}
          value={data.uid}
        />
        <Grid__separator width="15px" />
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_date' })}
          value={sequelizeDate({ date: data.createdAt, format: 'd/m/y' })}
        />
      </Header>
      <Grid__separator size="80px" />
      <Content__serviceStatus
        tag={(
          <Content__tag
            style={{ position: 'absolute', top: 25, right: 25 }}
            border
            content={getFieldGroup({
              base: strapiRepairApprovalService,
              groupe: 'status',
              field: checkIfActionWorkshop(data.status, data, user),
            })}
            {...parseStatus({ status: checkIfActionWorkshop(data.status, data, user) })}
          />
        )}
        {...parseStatus({ status: checkIfActionWorkshop(data.status, data, user) })}
      >
        {
          isError(checkIfActionWorkshop(data.status, data, user)) ? (
            <div>
              <ServiceStatusTitle>
                {getField({ base: strapiRepairApprovalService, field: 'error_text' })}
              </ServiceStatusTitle>
              <Grid__separator size="25px" />
              <Content__statusAnwser
                title={getUserApproverInformation().field1}
                subtitle1={getUserApproverInformation().field2}
                subtitle2={getUserApproverInformation().field3}
                link1={getUserApproverInformation().field4}
                link2={getUserApproverInformation().field5}
                link3={getUserApproverInformation().field6}
              />
            </div>
          ) : isAction(checkIfActionWorkshop(data.status, data, user)) ? (
            <div>
              <ServiceStatusTitle>
                {getField({ base: strapiRepairApprovalService, field: 'action_title' })}
              </ServiceStatusTitle>
              <Grid__separator size="10px" />
              <Content__statusAnwser
                action
                subtitle1={getField({ base: strapiRepairApprovalService, field: 'action_text' })}
                buttonRefused={getField({ base: strapiRepairApprovalService, field: 'action_refused_button' })}
                buttonAccept={getField({ base: strapiRepairApprovalService, field: 'action_accepted_button' })}
                buttonRefused_event={handleRefuse}
                buttonAccept__event={handleAccept}
              />
            </div>
          ) : isWaiting(data.status) ? (
            <div>
              {getField({ base: strapiRepairApprovalService, field: 'waiting_text' })}
            </div>
          ) : (
            <div>
              <ServiceStatusTitle>
                {getField({ base: strapiRepairApprovalService, field: 'response_text' })}
              </ServiceStatusTitle>
              <Grid__separator size="25px" />
              <Content__statusAnwser
                title={getUserApproverInformation().field1}
                subtitle1={getUserApproverInformation().field2}
                subtitle2={getUserApproverInformation().field3}
                link1={getUserApproverInformation().field4}
                link2={getUserApproverInformation().field5}
                link3={getUserApproverInformation().field6}
              />
            </div>
          )
        }
      </Content__serviceStatus>
      <Grid__separator size="100px" />
      <Row>
        <ItemContainer>
          <Content__sectionTitle
            line
            title={getField({ base: strapiRepairApprovalService, field: 'vehicle_title' })}
            icon={<FontAwesomeIcon icon={faTruck} />}
          />
          <Row>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' })}
                content={getFleetInformation({ obj: data, key: 'registrationNumber' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'category' })}
                content={getGlobal({ option: 'vehicletype', field: getFleetInformation({ obj: data, key: 'type' }) }) || ''}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' })}
                content={getGlobal({ option: 'country', field: getFleetInformation({ obj: data, key: 'countryOfRegistration' }) }) || ''}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'mileage' })}
                content={getFleetInformation({ obj: data, key: 'mileage' })}
              />
            </Column>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'vincode' })}
                content={getFleetInformation({ obj: data, key: 'vin' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'brand' })}
                content={getFleetInformation({ obj: data, key: 'brand' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'fleet' })}
                content={getFleetUserInformation({ obj: data, key: 'companyName' })}
              />
            </Column>
          </Row>
        </ItemContainer>
        <ItemContainer>
          <Content__sectionTitle
            line
            title={getField({ base: strapiRepairApprovalService, field: 'workshop_title' })}
            icon={<FontAwesomeIcon icon={faWarehouse} />}
          />
          <Row>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'name' })}
                content={getWorkshopInformation({ obj: data, key: 'companyName' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'address' })}
                content={getWorkshopInformation({ obj: data, key: 'adress' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })}
                content={`${getWorkshopInformation({ obj: data, key: 'phoneNumber' })}`}
              />
            </Column>
            <Column>
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'workshopInformation', field: 'country' })}
                content={getWorkshopInformation({ obj: data, key: 'region' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'city' })}
                content={getWorkshopInformation({ obj: data, key: 'city' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'zipcode' })}
                content={getWorkshopInformation({ obj: data, key: 'zipcode' })}
              />
            </Column>
          </Row>
        </ItemContainer>
      </Row>
      <Grid__separator size="100px" />
      <Content__sectionTitle
        line
        title={getField({ base: strapiRepairApprovalService, field: 'repair_title' })}
        icon={<FontAwesomeIcon icon={faTools} />}
      />
      <Row>
        <Column style={{ width: '60%' }}>
          <Content__labelTextarea
            label={getField({ base: strapiRepairApprovalService, field: 'single_service_description' })}
            lang={data.textLang}
            convert={user.user.lang}
            content={data.description}
            dataName={SERVICENAME.repairapproval}
            base={data.translate}
            id={data.id}
          />
          <Grid__separator size="30px" />
          {
            data.file && (
              <Content__labelText
                label={getField({ base: strapiRepairApprovalService, field: 'attachement_label' })}
                content={(
                  <Button__inline
                    content={getField({ base: strapiRepairApprovalService, field: 'request_file_button' })}
                    icon={<FontAwesomeIcon icon={faDownload} />}
                    download
                    href={parseFile(data.file).url}
                  />
                )}
              />
            )
          }
        </Column>
        <Column style={{ width: '25%' }}>
          <Content__labelText label={getField({ base: strapiRepairApprovalService, field: 'parts_price' })} content={formatPrice(data.partprice)} />
          <Grid__separator size="30px" />
          <Content__labelText label={getField({ base: strapiRepairApprovalService, field: 'labour_price' })} content={formatPrice(data.labourprice)} />
          <Grid__separator size="30px" />
          <Content__labelText label={getField({ base: strapiRepairApprovalService, field: 'hours' })} content={data.hour} />
          <Grid__separator size="30px" />
          <Content__labelText label={getField({ base: strapiRepairApprovalService, field: 'additional_cost' })} content={formatPrice(data.additionalcost)} />
          <Grid__separator size="30px" />
          <Content__labelText label={getField({ base: strapiRepairApprovalService, field: 'total_cost' })} content={formatPrice(data.totalCost)} />
        </Column>
      </Row>
    </Fragment>
  );

  return (
    <Container style={style}>
      <Popup active={active} closeEvent={handleCloseEvent}>
        {
          acceptRequest || declineRequest ? (
            <Fragment>
              {
                acceptRequest && (
                  <Message__sucess
                    icon={t.image.illu.check}
                    title={getField({ base: strapiRepairApprovalService, field: 'success_approve_popup_title' })}
                    text={getField({ base: strapiRepairApprovalService, field: 'success_approve_popup_text' })}
                    buttonText={getField({ base: strapiRepairApprovalService, field: 'success_approve_popup_button' })}
                    buttonEvent={handleReloadData}
                  />
                )
              }
              {
                declineRequest && (
                  <Message__sucess
                    icon={t.image.illu.error}
                    title={getField({ base: strapiRepairApprovalService, field: 'success_decline_popup_title' })}
                    text={getField({ base: strapiRepairApprovalService, field: 'success_decline_popup_text' })}
                    buttonText={getField({ base: strapiRepairApprovalService, field: 'success_decline_popup_button' })}
                    buttonEvent={handleReloadData}
                  />
                )
              }
              {loadingUser && (<Loader__wrapper />)}
            </Fragment>
          ) : (
            <Fragment>
              {loading || !data ? renderLoader() : renderContent()}
              {loadingAction && (<Loader__wrapper />)}
            </Fragment>
          )
        }
      </Popup>
    </Container>
  );
};

export default DashboardRepairApproval__singlePop;
