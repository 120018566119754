// import moment from 'moment';
import moment from 'moment/min/moment-with-locales';

export const sequelizeDate = ({ date, format }) => {
  if (date) {
    if (format && format === 'd/m/y') {
      return moment(new Date(date.replace(' ', 'T'))).format('DD/MM/YYYY');
    }
    return new Date(date.replace(' ', 'T'));
  }

  return '';
};

export const sequelizeCustomDate = ({ date, format }) => {
  if (date) {
    return moment(new Date(date.replace(' ', 'T'))).format(format);
  }

  return '';
};

export const sequelizeDateWithOneMonths = ({ date, format }) => {
  if (format && format === 'd/m/y') {
    return moment(new Date(date.replace(' ', 'T'))).add(30, 'days').format('DD/MM/YYYY');
  }
  return new Date(date.replace(' ', 'T'));
};

export const sequelizeToMoment = (date) => new Date(date.replace(' ', 'T'));

export const sequelizeDateTime = ({ date, atContent }) => {
  if (date) {
    return moment(new Date(date.replace(' ', 'T'))).format(`YYYY/MM/DD [${atContent}] H:mm`);
  }

  return '';
};

export const DateTime = ({ date, atContent }) => moment(date).format(`YYYY/MM/DD [${atContent}] H:mm`);

export const sequelizeTime = ({ date }) => {
  if (date) {
    return moment(new Date(date.replace(' ', 'T'))).format('H:mm');
  }

  return '';
};
