
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import t from 'theme';

/**
 * import containers & components
 */
import { Transition__loader_controller } from 'components/transition/transition';
import { ROUTING } from 'util/constant/routing.constant';
import Layout__container from 'containers/layout/container/layout__container';
import Layout__content from 'containers/layout/content/layout__content';
import Layout__wrapper from 'containers/layout/wrapper/layout__wrapper';
import { Grid__card, Grid__separator } from 'components/grid/grid/';
import Content__externalLinks from 'components/content/externalLinks/content__externalLinks';
import Content__signUpLink from 'components/content/signUpLink/content__signUpLink';
import Grid__ShapeBackground from 'components/grid/ShapeBackground/grid__ShapeBackground';
import { strapiRegister, strapiLogin } from 'util/strapi/content.starpi';
import { ContentContext } from 'context/contentContext';
import { PasswordRecovery__form } from 'containers/fleetView/passwordRecovery/passwordRecovery';


const Wrapper = styled(Layout__wrapper)`
  position: relative;
  z-index: 10;
  height: 100%;
  width: 100%;
  padding: 0;
`;

const Content = styled(Layout__content)`
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Formcards = styled(Grid__card)`
  width: 400px;
  padding: 25px 35px;
  padding-top: 35px;
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 50px;
`;

/**
 * @name PasswordRecovery
 */

const PasswordRecovery = () => {
  const {
    loadPage, haveContentFor, getFieldWithNavigatorLang,
  } = useContext(ContentContext);

  const loadContent = async () => {
    if (haveContentFor(strapiRegister).success === false) {
      await loadPage(strapiRegister);
    }
    if (haveContentFor(strapiLogin).success === false) {
      await loadPage(strapiLogin);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (
    haveContentFor(strapiRegister).success === false
    || haveContentFor(strapiLogin).success === false
  ) {
    return <Transition__loader_controller active />;
  }

  return (
    <Layout__container overflow="true" width="100%" style={{ position: 'relative' }}>
      <Transition__loader_controller active={false} />
      <Wrapper>
        <Content>
          <MainContainer>
            <ContainerContent>
              <Logo src={t.image.global.logoBlue} />
              <Formcards>
                <PasswordRecovery__form />
              </Formcards>
              <Grid__separator size="25px" />
              <Content__signUpLink
                content={getFieldWithNavigatorLang({ base: strapiLogin, field: 'noAccount_title' })}
                buttonContent={getFieldWithNavigatorLang({ base: strapiLogin, field: 'noAccount_button' })}
                link={ROUTING.fleet.register.path}
              />
            </ContainerContent>
            <Content__externalLinks />
            <Grid__ShapeBackground />
          </MainContainer>
        </Content>
      </Wrapper>
    </Layout__container>
  );
};

export default PasswordRecovery;
