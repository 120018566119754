import { getParams } from 'util/get.util';
import { insertParam } from '../get.util';

export const setAction = (params) => {
  insertParam({
    key: (params && params.key) ? params.key : 'action',
    value: (params && params.value) ? params.value : 'true',
  });
};

export const getAction = (params) => {
  if (getParams((params && params.key) ? params.key : 'action')) {
    const value = getParams((params && params.key) ? params.key : 'action');
    window.history.pushState({}, '', window.location.pathname);
    if (value && value === 'true') {
      return {
        success: true,
        value: true,
      };
    }
    if (value && value === 'false') {
      return {
        success: true,
        value: false,
      };
    }
    if (value || value === 0) {
      return {
        success: true,
        value,
      };
    }
    return {
      success: true,
      value: true,
    };
  }

  return {
    success: false,
    value: false,
  };
};
