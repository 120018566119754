
import React, {
  useContext, useState, forwardRef, useImperativeHandle, useEffect,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import { ContentContext } from 'context/contentContext';
import { SendMail } from 'helpers/api/routes/Mail';
import { AppContext } from 'context/appContext';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { Grid__separator } from 'components/grid/grid';
import { Content__sectionTitle } from 'components/content/content';
import Button__primary from 'components/button/primary/button__primary';
import { Form__textfield, Form__select } from 'components/form/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faMapMarkedAlt } from '@fortawesome/pro-solid-svg-icons';
import { faTruck } from '@fortawesome/pro-regular-svg-icons';
import Cards__infoCheck from 'components/cards/infoCheck/cards__infoCheck';
import { strapiService24Service } from 'util/strapi/content.starpi';
import { capitalizeFirstLetter } from 'util/formatContent.util';
import { LaunchServiceFairePrice } from 'helpers/api/routes/Service24';
import { Message__sucess } from 'components/message/message';
import { createErrorObject, ScrollToError } from 'util/conditions/checkField.condition';
import { hasFieldError } from 'util/conditions/haveErrorField.conditions';
import { createNewArray } from 'helpers/js/Form';
import Form__map from 'components/form/map/form__map';
import { getNavigatorLang } from 'util/function/getNavigatorLang.function';
import { setAction } from 'util/function/reloadAction.function';
import Form__combined from 'components/form/combined/form__combined';
import { sequelizeDate } from 'util/date.util';


const Container = styled.div`
  ${t.mixins.flex({ justify: 'center', direction: 'column' })};
  width: 100%;
`;

const ContainerForm = styled.div`
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
  width: 500px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Separator = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #B3B3B3;
  position: relative;
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;
  &::after {
    content: '';
    margin-left: 4px;
    margin-bottom: 4px;
    display: inline-block;
    width: 20px;
    height: 0px;
    border: 1px solid #B3B3B3;
  }
  &::before {
    content: '';
    margin-right: 4px;
    margin-bottom: 4px;
    display: inline-block;
    width: 20px;
    height: 0px;
    border: 1px solid #B3B3B3;
  }
`;


const DashboardService24__formPop = forwardRef(({
  style, vehicle, closeEvent, prevEvent, isRequestSucces,
}, ref) => {
  const { getField, getGlobal } = useContext(ContentContext);
  const { user, getUser } = useContext(AppContext);

  const [error, seterror] = useState({});
  const [loadingAction, setloadingAction] = useState(false);
  const [location, setlocation] = useState({
    city: '',
    country: '',
    location: '',
    countryCode: '',
  });
  const [road, setroad] = useState('');
  const [description, setdescription] = useState('');
  const [additional, setadditional] = useState('');
  const [driverName, setdriverName] = useState('');
  const [driverPhone, setdriverPhone] = useState('');
  const [driverPhoneError, setdriverPhoneError] = useState('');
  const [driverLanguage, setdriverLanguage] = useState('');
  const [sucessScreen, setsucessScreen] = useState({ active: false });
  const severityArray = [
    {
      label: getField({ base: strapiService24Service, field: 'form_information_severity_low' }),
      slug: 'low',
      active: true,
    },
    {
      label: getField({ base: strapiService24Service, field: 'form_information_severity_medium' }),
      slug: 'medium',
      active: false,
    },
    {
      label: getField({ base: strapiService24Service, field: 'form_information_severity_high' }),
      slug: 'high',
      active: false,
    },
  ];
  const [severity, setseverity] = useState(createNewArray({ base: severityArray }));
  const [severityValue, setseverityValue] = useState('low');

  const getVehicleInformation = (key) => {
    if (vehicle) {
      if (vehicle.vehicle) {
        if (vehicle.vehicle[key]) {
          return vehicle.vehicle[key];
        }
      }
    }
    return '';
  };

  const getHomeBaseInfo = (key) => {
    if (user.user.UserFleet) {
      if (user.user.UserFleet.UserWorkshop) {
        if (user.user.UserFleet.UserWorkshop.UserWorkshopAlias) {
          if (user.user.UserFleet.UserWorkshop.UserWorkshopAlias[key]) {
            return user.user.UserFleet.UserWorkshop.UserWorkshopAlias[key];
          }
        }
      }
    }
    return '';
  };

  const handleTextField = ({ value, property }) => {
    if (property === 'location') {
      setlocation(value);
    } else if (property === 'description') {
      setdescription(value);
    } else if (property === 'additional') {
      setadditional(value);
    } else if (property === 'road') {
      setroad(value);
    } else if (property === 'driverName') {
      setdriverName(value);
    } else if (property === 'driverPhone') {
      setdriverPhone(value);
    } else if (property === 'driverLanguage') {
      setdriverLanguage(value);
    }
  };

  const handlePhoneChange = ({
    value,
  }) => {
    setdriverPhoneError(!value.valide);
    setdriverPhone(value.value);
  };

  const handleMapField = ({ formated }) => {
    const obj = {
      countryCode: formated.countryCode,
      city: formated.city,
      country: formated.country,
      location: formated.description ? `${formated.description}, ${formated.zipCode}` : `${formated.number} ${formated.address}, ${formated.zipCode}`,
    };
    setlocation(obj);
  };

  const handleSeverityChange = (value) => {
    setseverityValue(value.result.slug);
    setseverity(value.value);
  };

  const checkField = () => {
    let errorObj = {};
    let errorArray = {};

    const baseArrayField = [
      {
        key: 'description',
        type: 'simple',
        value: description,
      },
      {
        key: 'additional',
        type: 'simple',
        value: description,
      },
      {
        key: 'severity',
        type: 'simple',
        value: severityValue,
      },
      {
        key: 'driverName',
        type: 'simple',
        value: driverName,
      },
      {
        key: 'driverLanguage',
        type: 'simple',
        value: driverLanguage,
      },
      {
        key: 'road',
        key2: 'city',
        type: 'conditional-simple',
        value: driverLanguage,
      },
    ];

    errorObj = createErrorObject(errorArray, baseArrayField, errorObj.hasError, getGlobal);
    errorArray = errorObj.object;

    if (road === '' && location.city === '') {
      errorArray.road = { active: true };
      errorArray.location = { active: true };
      if (errorObj.hasError === false) {
        ScrollToError();
      }
      errorObj.hasError = true;
    }

    if (driverPhone === '' || driverPhoneError === true) {
      errorArray.driverPhone = { active: true };
      if (errorObj.hasError === false) {
        ScrollToError();
      }
      errorObj.hasError = true;
    }
    seterror(errorArray);
    return errorObj.hasError;
  };


  const startReq = async () => {
    const params = {
      fleetId: getVehicleInformation('id'),
      location: location.location,
      city: location.city,
      country: location.country,
      countryCode: location.countryCode,
      road,
      description,
      additional,
      severity: severityValue,
      textLang: getNavigatorLang().lang,
      driverName,
      driverPhone,
      driverLanguage,
    };

    const res = await LaunchServiceFairePrice(params);
    if (res) {
      if (res.data.success === true) {
        setsucessScreen({ active: true, id: res.data.data.id });
        if (isRequestSucces) {
          isRequestSucces();
        }
        SendMail({
          template: 'raw',
          content: `
            <b>Assistance needed</b>
            </br></br>
            Please find here under a new case of Alltrucks Service 24 initiated by Alltrucks Fleet Platform.
            </br>
            Thanks to take into consideration this case immediately by contacting the Fleet Contact.
            </br></br>
            Request sent by the fleet
            </br>
            </br>
            <b>Request ID: </b>${res.data.data.uid}</br>
            <b>Date: </b>${sequelizeDate({ date: res.data.data.createdAt, format: 'd/m/y' })}
            </br></br>
            <b>Fleet information</b></br>
            Company name: ${user.user.companyName}</br>
            Localisation: ${user.user.adress}, ${user.user.city}, ${user.user.zipcode}, ${user.user.countryCode}</br>
            Phone: ${user.user.phoneNumber}</br>
            Email: ${user.user.email}</br>
            Contact: ${user.user.gender} ${user.user.lastName} ${user.user.firstName}</br>
            Language: ${getNavigatorLang().lang}</br>
            </b></br></br>
            <b>Vehicle information</b></br>
            Brand: ${getVehicleInformation('brand')}</br>
            Registration number: ${getVehicleInformation('registrationNumber')}</br>
            VIN code: ${getVehicleInformation('vin')}</br>
            Category: ${getVehicleInformation('type')}</br>
            Mileage: ${getVehicleInformation('mileage')}</br>
            </br></br></br>
            <b>Driver</b></br>
            Name: ${driverName}</br>
            Phone number: ${driverPhone}</br>
            Language: ${driverLanguage}</br>
            </br></br></br>
            <b>Breakdown</b></br>
            Address: ${location.location}</br>
            City: ${location.city}</br>
            Country: ${location.country}</br>
            Road: ${road}</br>
            Severity: ${severityValue}</br></br>
            Description:
            </br>
            ${description}</br></br>
            Additionnal information:
            </br>
            ${additional}</br>
            </br></br></br>
            <b>Alltrucks Home Based Workshop</b></br>
            Company name: ${getHomeBaseInfo('companyName')}</br>
            Localisation: ${getHomeBaseInfo('adress')}, ${getHomeBaseInfo('city')}, ${getHomeBaseInfo('zipcode')}, ${getHomeBaseInfo('countryCode')}</br>
            Phone: ${getHomeBaseInfo('phoneNumber')}</br>
            Email: ${getHomeBaseInfo('email')}</br>
            Contact: ${getHomeBaseInfo('gender')} ${getHomeBaseInfo('lastName')} ${getHomeBaseInfo('firstName')}</br>
            </br></br></br>
            After closing the case, thanks to send your report immediately to fleet@alltrucks.com and also to the Home Based Workshop email if it is mentionned above.
          `,
          subject: 'Assistance needed for an Alltrucks fleet vehicle',
          destination: process.env.REACT_APP_SERVICE24MAIL,
        });
        setloadingAction(false);
      } else {
        setloadingAction(false);
      }
    }
  };


  const handleSuccesMessage = async () => {
    setloadingAction(true);
    setAction({ key: 'action', value: sucessScreen.id });
    const resUser = await getUser();
    if (resUser) {
      if (closeEvent) {
        closeEvent();
      }
      setloadingAction(false);
    } else {
      setloadingAction(false);
    }
  };

  const handleSave = async () => {
    if (!checkField()) {
      setloadingAction(true);
      startReq();
    }
  };

  useImperativeHandle(ref, () => ({
    handleReloadHook() {
      handleSuccesMessage();
    },

    handleCleanHook() {
      setlocation({
        city: '',
        country: '',
        location: '',
      });
      setroad('');
      setdescription('');
      setadditional('');
      setseverity(createNewArray({ base: severityArray }));
      setseverityValue('low');
    },

  }));

  const DisableAutoFill = () => {
    const inputMap = document.querySelector('.google-places-autocomplete__input');
    inputMap.setAttribute('autocomplete', 'chrome-off');
  };

  useEffect(() => {
    DisableAutoFill();
  }, []);

  return (
    <Container style={style}>
      {!sucessScreen.active ? (
        <ContainerForm>
          <Cards__infoCheck
            title={getField({ base: strapiService24Service, field: 'vehicle_label' })}
            content={`${capitalizeFirstLetter(getVehicleInformation('brand'))} ${getVehicleInformation('registrationNumber')}`}
            icon={<FontAwesomeIcon icon={faTruck} />}
          />
          <Grid__separator size="65px" />
          <Content__sectionTitle
            title={getField({ base: strapiService24Service, field: 'form_breakdown_title' })}
            icon={<FontAwesomeIcon icon={faMapMarkedAlt} />}
          />
          <Form__map
            event={({ formated, place }) => handleMapField({
              property: 'address',
              formated,
              place,
              type: 'text',
            })}
            value={location.value || null}
            property="address"
            label={getField({ base: strapiService24Service, field: 'form_breakdown_location' })}
            style={{ maxWidth: '100%' }}
            error={hasFieldError(error, 'location')}
          />
          <Separator>{getGlobal({ option: 'globalcontent', field: 'uploader_or' })}</Separator>
          <Form__textfield
            style={{ width: '100%' }}
            label={getField({ base: strapiService24Service, field: 'form_breakdown_road' })}
            property="road"
            value={road}
            change={handleTextField}
            error={hasFieldError(error, 'road')}
          />
          <Grid__separator size="65px" />
          <Content__sectionTitle
            title={getField({ base: strapiService24Service, field: 'form_driver_title' })}
            icon={<FontAwesomeIcon icon={faTools} />}
          />
          <Form__textfield
            label={getField({ base: strapiService24Service, field: 'form_driver_name' })}
            property="driverName"
            require
            value={driverName}
            change={handleTextField}
            error={hasFieldError(error, 'driverName')}
          />
          <Grid__separator size="25px" />
          <Form__combined
            handleTextChange={(isValide, res, final) => handlePhoneChange({
              property: 'driverPhone',
              value: { value: final, valide: isValide },
              result: { value: final, valide: isValide },
              type: 'tel',
            })}
            textValue={driverPhone}
            property="driverPhone"
            label={getField({ base: strapiService24Service, field: 'form_driver_phone' })}
            require
            error={hasFieldError(error, 'driverPhone')}
            allCountry
            style={{ width: '100%' }}
            defaultCountry={user.user.lang}
          />
          <Grid__separator size="25px" />
          <Form__textfield
            label={getField({ base: strapiService24Service, field: 'form_driver_language' })}
            property="driverLanguage"
            require
            value={driverLanguage}
            change={handleTextField}
            error={hasFieldError(error, 'driverLanguage')}
          />
          <Grid__separator size="65px" />
          <Content__sectionTitle
            title={getField({ base: strapiService24Service, field: 'form_information_title' })}
            icon={<FontAwesomeIcon icon={faTools} />}
          />
          <Form__textfield
            style={{ width: '100%' }}
            label={getField({ base: strapiService24Service, field: 'form_information_description' })}
            require
            type="textarea"
            property="description"
            value={description}
            change={handleTextField}
            error={hasFieldError(error, 'description')}
          />
          <Grid__separator size="25px" />
          <Form__textfield
            style={{ width: '100%' }}
            label={getField({ base: strapiService24Service, field: 'form_information_additional' })}
            require
            type="textarea"
            property="additional"
            value={additional}
            change={handleTextField}
            error={hasFieldError(error, 'additional')}
          />
          <Grid__separator size="25px" />
          <Form__select
            value={severity}
            label={getField({ base: strapiService24Service, field: 'form_information_severity' })}
            style={{ width: '100%', maxWidth: '100%', marginRight: 10 }}
            event={(res) => handleSeverityChange({
              property: 'country',
              value: res.value,
              result: res.result,
              type: 'select',
            })}
            require
          />
          <Grid__separator size="25px" />
          <Row>
            <Button__primary border blue event={prevEvent}>
              {getGlobal({ option: 'service', field: 'single_launch_prevbutton' })}
            </Button__primary>
            <Button__primary style={{ marginLeft: 25 }} event={handleSave}>{getField({ base: strapiService24Service, field: 'form_submit' })}</Button__primary>
          </Row>
          <Grid__separator size="25px" />
        </ContainerForm>
      ) : (
        <Message__sucess
          icon={t.image.illu.check}
          title={getField({ base: strapiService24Service, field: 'success_popup_title' })}
          text={getField({ base: strapiService24Service, field: 'success_popup_text' })}
          buttonText={getField({ base: strapiService24Service, field: 'success_popup_button' })}
          buttonEvent={handleSuccesMessage}
        />
      )}
      {loadingAction ? <Loader__wrapper /> : false}
    </Container>
  );
});

export default DashboardService24__formPop;
