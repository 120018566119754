import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
  display: inline-flex;
  align-items: center;
  width: auto;
  height: 34px;
  padding-left: 15px;
  padding-right: 15px;
  background: #E6F8FC;
  border-radius: 10px;
`;

const Value = styled.span`
  font-weight: 600;
  font-size: 15px;
  color: #01b5e2;
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 14px;
  margin-right: 5px;
  color: #01b5e2;
`;


const Content__counterInfo = ({
  style, label, value, className,
}) => (
  value && parseInt(value, 10) > 0 ? (
    <Container style={style} className={className}>
      { label ? (
        <Label>{label}</Label>
      ) : null }
      <Value>{value}</Value>
    </Container>
  ) : null
);

export default Content__counterInfo;
