
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import t from 'theme';

import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';

import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';

import FairPrice from 'containers/fleetView/myServices/fairPrice/myServices__fairPrice';
import StandarRepair from 'containers/fleetView/myServices/standarRepair/myServices__standarRepair';
import RepairApproval from 'containers/fleetView/myServices/repairApproval/myServices__repairApproval';
import Priority90 from 'containers/fleetView/myServices/priority90/myServices__priority90';
import Service24 from 'containers/fleetView/myServices/service24/myServices__service24';
import PaymentGuarantee from 'containers/fleetView/myServices/paymentGuarantee/myServices__paymentGuarantee';
import { Grid__card, Grid__separator } from 'components/grid/grid/';
import Loader__small from 'components/loader/small/loader__small';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { getHaveService } from 'helpers/api/routes/getData';

const Container = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: flex-start;
`;

const Card__container = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', wrap: 'wrap' })}
`;

const ToolContainer = styled.div`
`;

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
  color: #01B5E2;
`;

const Loading__cards = styled(Grid__card)`
    width: 520px;
    height: 380px;
    padding: 30px;
    margin-bottom: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
`;

const MyServices__serviceContainer = ({ style, basePage }) => {
  const { getField } = useContext(ContentContext);
  const { user } = useContext(AppContext);

  const [service24, setservice24] = useState('loading');
  const [paymentGuarantee, setpaymentGuarantee] = useState('loading');

  const haveService24 = async () => {
    const res = await getHaveService({
      service: 'service24Subscription',
      userId: user.user.UserFleet.id,
    });
    const formated = formatApiRes(res);

    if (formated.success && formated.data) {
      setservice24('have');
    } else {
      setservice24(false);
    }

    return true;
  };

  const havePaymentGuarantee = async () => {
    const res = await getHaveService({
      service: 'paymentguaranteeSubscription',
      userId: user.user.UserFleet.id,
    });
    const formated = formatApiRes(res);

    if (formated.success && formated.data) {
      setpaymentGuarantee('have');
    } else {
      setpaymentGuarantee(false);
    }

    return true;
  };
  const startReq = async () => {
    await haveService24();
    await havePaymentGuarantee();
  };
  useEffect(() => {
    startReq();
  }, []);
  return (
    <div>
      <Content__pageTitle content={getField({ base: basePage, field: 'page_title' })} />
      <Container style={style}>
        <Card__container>
          <StandarRepair basePage={basePage} />
          <RepairApproval basePage={basePage} />
          <Priority90 basePage={basePage} />
          {paymentGuarantee === 'have' ? (<PaymentGuarantee basePage={basePage} />) : paymentGuarantee === 'loading' ? <Loading__cards><Loader__small /></Loading__cards> : null}
        </Card__container>
        <ToolContainer>
          <SectionTitle>{getField({ base: basePage, field: 'tools_title' })}</SectionTitle>
          <FairPrice basePage={basePage} />
          <Grid__separator size="40px" />
          {service24 === 'have' ? (<Service24 basePage={basePage} />) : service24 === 'loading' ? <Loading__cards><Loader__small /></Loading__cards> : null}
        </ToolContainer>
      </Container>
    </div>
  );
};

export default MyServices__serviceContainer;
