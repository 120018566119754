
import React, {
  forwardRef, useState, useImperativeHandle, Fragment,
} from 'react';
import styled from 'styled-components';
import { Grid__popup, Grid__separator } from 'components/grid/grid';
import t from 'theme';
import { Content__text } from 'components/content/content';
import Global__stepsIndicator from 'components/global/stepsIndicator/global__stepsIndicator';
import ServiceDashboard__serviceList from 'containers/fleetView/serviceDashboard/serviceList/serviceDashboard__serviceList';
import ServiceDashboard__requestList from 'containers/fleetView/serviceDashboard/requestList/serviceDashboard__requestList';


const Container = styled.div`
    ${t.mixins.flex({ justify: 'flex-start', direction: 'column' })};
    width: 100%;
  `;

const Popup = styled(Grid__popup)`
    padding-top: 45px;
    padding-bottom: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    align-items: center;
  `;

// eslint-disable-next-line react/display-name
const ServiceDashboard__selectPop__fairPrice = forwardRef(({
  style,
  active,
  closeEvent,
  nextEvent,
  serviceName,
  children,
  launchTitle,
  Step1Title,
  Step2Title,
  Step3Title,
  requestSucces,
}, ref) => {
  const [step, setstep] = useState('service');
  const [serviceSelected, setserviceSelected] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [baseVehicle, setbaseVehicle] = useState(null);

  const handleSelectService = (serviceReq) => {
    setserviceSelected(serviceReq);
    setstep('request');
  };

  const handleSelectRequest = (requestReq) => {
    nextEvent({ request: requestReq, service: serviceSelected });
    setstep('claim');
  };

  const getCurrentStep = () => {
    if (step === 'request') {
      return 2;
    }

    if (step === 'claim' && Step3Title) {
      return 3;
    }

    if (step === 'claim' && Step3Title === undefined) {
      return 2;
    }

    return 1;
  };


  const handlePrev = (to) => {
    setstep(to);
  };

  useImperativeHandle(ref, () => ({

    handlePrevHook(to) {
      setstep(to);
    },

    handleClean() {
      setstep('service');
      setserviceSelected(null);
    },

    setVehicle(vehicleId) {
      setbaseVehicle(vehicleId);
    },

  }));

  return (
    <Container style={style}>
      <Popup active={active} closeEvent={closeEvent}>
        <Container>
          {
            !requestSucces && (
              <Fragment>
                <Content__text
                  type="p"
                  color="#4d4d4d"
                  align="center"
                  style={{ fontWeight: 600, fontSize: 26 }}
                >
                  {launchTitle}
                </Content__text>
                <Grid__separator size="25px" />
                <Global__stepsIndicator
                  step1Name={Step1Title}
                  step2Name={Step2Title}
                  step3Name={Step3Title}
                  currentStep={getCurrentStep()}
                />
                <Grid__separator size="65px" />
              </Fragment>
            )
          }
          {
            step === 'service' ? (
              <ServiceDashboard__serviceList
                serviceName={serviceName}
                nextEvent={handleSelectService}
                defaultSelected={active}
              />
            ) : step === 'request' ? (
              <ServiceDashboard__requestList
                serviceName={serviceName}
                saveEvent={handleSelectRequest}
                prevEvent={() => handlePrev('service')}
                serviceSelected={serviceSelected}
              />
            ) : step === 'claim' ? (
              children
            ) : null
          }
        </Container>
      </Popup>
    </Container>
  );
});

export default ServiceDashboard__selectPop__fairPrice;
