import React, {
  Fragment, useContext, useState, useEffect,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import { useHistory } from 'react-router-dom';
import { ContentContext } from 'context/contentContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faTruck,
  faTools,
  faWarehouse,
  faCreditCardBlank,
  faLayerPlus,
  faUser,
  faClipboardListCheck,
  faMapMarkedAlt,
  faCalendarDay,
} from '@fortawesome/pro-regular-svg-icons';
import LogRocket from 'logrocket';

import { Grid__separator } from 'components/grid/grid/';
import { ROUTING } from 'util/constant/routing.constant';
import { AppContext } from 'context/appContext';
import Banner from 'components/global/banner';
import Cards__logRocket from '../cards/logRocket/cards__logRocket';
import Transition__loader_controller from '../transition/loader_controller/transition__loader_controller';


const NavElement = styled.nav`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 70px 00px;
  padding-bottom: 20px;
  width: 238px;
  z-index: 999;
  background: ${t.color.dark};
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 45px;
`;

const LogoPartner = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  bottom: 0;
  border-top: 3px solid #01b5e2;
`;

const LogoPartner__img = styled.img`
  width: 90px;
`;

const LogoPartner__content = styled.span`
  font-size: 10px;
  color: #01B5E2;
  white-space: nowrap;
  padding-right: 12px;
  font-weight: bold;
`;

const PaddingContainer = styled.div`
  width: 100%;
  padding: 0 30px;
  display: block;
`;

const MenuTitle = styled.p`
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: #8691a3;
`;

const MenuRow = styled.div`
  width: 100%;
  padding: 16px 30px;
  ${t.mixins.flex({ justify: 'flex-start', align: 'center' })}
`;

const MenuText = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #e5e8ec;
  margin-left: 15px;
  transition: all ease 0.3s;
`;

const Icon = styled(FontAwesomeIcon)`
  transition: all ease 0.3s;
  font-weight: normal;
  font-size: 16px;
  width: 20px !important;
`;

const MenuItem = styled.a`
  width: 100%;
  background: transparent;
  transition: all ease 0.3s;
  position: relative;
  text-decoration: none;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background: ${t.color.cyan};
    transform: translateX(-4px);
    transition: all ease 0.3s;
  }
  &:hover {
    background: #002550;
    transition: all ease 0.3s;
    ${MenuText} {
      color: ${t.color.cyan};
      opacity: 1;
      transition: all ease 0.3s;
    }
    ${Icon} {
      color: ${t.color.cyan};
      transition: all ease 0.3s;
    }
  }
  ${({ active }) => active
    && `
    background: #002550;
    transition: all ease 0.3s;
    ${MenuText} {
      color: ${t.color.cyan};
      opacity: 1;
      transition: all ease 0.3s;
    }
    &::after {
      transform: translateX(0px);
      transition: all ease 0.3s;
    }
  `}
`;


const Nav = ({ active, workshop }) => {
  const history = useHistory();
  const { getGlobal } = useContext(ContentContext);
  const [session, setsession] = useState(false);
  const [loading, setloading] = useState(false);
  const {
    getUser,
    user,
  } = useContext(AppContext);
  const reloadPage = ['home-based', 'my-services'];
  const checkReloadPage = (link) => reloadPage.find((el) => link.includes(el));

  const handleClick = async (e, { link }) => {
    e.preventDefault();
    if (checkReloadPage(link)) {
      setloading(true);
      await getUser();
      setloading(false);
    }
    history.push(link);
  };

  const getsessionUrl = () => {
    if (!session) {
      LogRocket.getSessionURL((sessionURL) => {
        setsession(sessionURL);
      });
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_LOGROCKET_ID && process.env.REACT_APP_ENV === 'staging') {
      getsessionUrl();
    }
  }, []);

  const loggedAs = localStorage.getItem('logAs');

  return (
    <NavElement>
      <Transition__loader_controller active={loading} />
      {loggedAs && (
        <Banner user={`${user.user.firstName} ${user.user.lastName}`} />
      )}
      {session && (
        <Cards__logRocket url={session} />
      )}
      <PaddingContainer>
        <Logo src={t.image.global.logo} />
      </PaddingContainer>
      {workshop ? (
        <Fragment>
          <PaddingContainer>
            <MenuTitle>Menu</MenuTitle>
            <Grid__separator size="10px" />
          </PaddingContainer>
          <MenuItem
            href="#"
            active={active === 'home' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.workshop.home.path })}
          >
            <MenuRow>
              <Icon icon={faHome} color={active === 'home' ? t.color.cyan : '#E5E8EC'} />
              <MenuText>{getGlobal({ option: 'workshop_menu', field: 'home' })}</MenuText>
            </MenuRow>
          </MenuItem>
          <MenuItem
            href="#"
            active={active === 'checkVehicle' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.workshop.checkVehicle.path })}
          >
            <MenuRow>
              <Icon icon={faClipboardListCheck} color={active === 'checkVehicle' ? t.color.cyan : '#E5E8EC'} />
              <MenuText>{getGlobal({ option: 'workshop_menu', field: 'check_vehicle' })}</MenuText>
            </MenuRow>
          </MenuItem>
          <MenuItem
            href="#"
            active={active === 'cases' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.workshop.cases.path })}
          >
            <MenuRow>
              <Icon icon={faTools} color={active === 'cases' ? t.color.cyan : '#E5E8EC'} />
              <MenuText>{getGlobal({ option: 'workshop_menu', field: 'my_cases' })}</MenuText>
            </MenuRow>
          </MenuItem>
          <MenuItem
            href="#"
            active={active === 'myFleets' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.workshop.myFleets.path })}
          >
            <MenuRow>
              <Icon icon={faTruck} color={active === 'myFleets' ? t.color.cyan : '#E5E8EC'} />
              <MenuText>{getGlobal({ option: 'workshop_menu', field: 'my_fleets' })}</MenuText>
            </MenuRow>
          </MenuItem>

          <MenuItem
            href="#"
            active={active === 'appointment' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.fleet.appointment.path })}
          >
            <MenuRow>
              <Icon
                icon={faCalendarDay}
                color={active === 'appointment' ? t.color.cyan : '#E5E8EC'}
              />
              <MenuText>{getGlobal({ option: 'workshop_menu', field: 'appointment' })}</MenuText>
            </MenuRow>
          </MenuItem>

          <Grid__separator size="65px" />

          <PaddingContainer>
            <MenuTitle>{getGlobal({ option: 'workshop_menu', field: 'label_settings' })}</MenuTitle>
            <Grid__separator size="10px" />
          </PaddingContainer>
          <MenuItem
            href="#"
            active={active === 'joinServices' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.workshop.joinServices.path })}
          >
            <MenuRow>
              <Icon icon={faLayerPlus} color={active === 'joinServices' ? t.color.cyan : '#E5E8EC'} />
              <MenuText>{getGlobal({ option: 'workshop_menu', field: 'join_services' })}</MenuText>
            </MenuRow>
          </MenuItem>
          <MenuItem
            href="#"
            active={active === 'myAccount' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.workshop.myAccount.path })}
          >
            <MenuRow>
              <Icon icon={faUser} color={active === 'myAccount' ? t.color.cyan : '#E5E8EC'} />
              <MenuText>{getGlobal({ option: 'workshop_menu', field: 'my_account' })}</MenuText>
            </MenuRow>
          </MenuItem>
        </Fragment>
      ) : (
        <Fragment>
          <PaddingContainer>
            <MenuTitle>{getGlobal({ option: 'fleet_menu', field: 'label_menu' })}</MenuTitle>
            <Grid__separator size="10px" />
          </PaddingContainer>
          <MenuItem
            href="#"
            active={active === 'home' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.fleet.home.path })}
          >
            <MenuRow>
              <Icon icon={faHome} color={active === 'home' ? t.color.cyan : '#E5E8EC'} />
              <MenuText>{getGlobal({ option: 'fleet_menu', field: 'home' })}</MenuText>
            </MenuRow>
          </MenuItem>
          <MenuItem
            href="#"
            active={active === 'myfleet' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.fleet.myFleet.path })}
          >
            <MenuRow>
              <Icon icon={faTruck} color={active === 'myfleet' ? t.color.cyan : '#E5E8EC'} />
              <MenuText>{getGlobal({ option: 'fleet_menu', field: 'my_fleet' })}</MenuText>
            </MenuRow>
          </MenuItem>
          <MenuItem
            href="#"
            active={active === 'myservices' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.fleet.myServices.path })}
          >
            <MenuRow>
              <Icon icon={faTools} color={active === 'myservices' ? t.color.cyan : '#E5E8EC'} />
              <MenuText>{getGlobal({ option: 'fleet_menu', field: 'my_services' })}</MenuText>
            </MenuRow>
          </MenuItem>
          <MenuItem
            href="#"
            active={active === 'homebase' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.fleet.homeBase.path })}
          >
            <MenuRow>
              <Icon icon={faWarehouse} color={active === 'homebase' ? t.color.cyan : '#E5E8EC'} />
              <MenuText>{getGlobal({ option: 'fleet_menu', field: 'my_home_based' })}</MenuText>
            </MenuRow>
          </MenuItem>
          <MenuItem
            href="#"
            active={active === 'membercard' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.fleet.memberCard.path })}
          >
            <MenuRow>
              <Icon
                icon={faCreditCardBlank}
                color={active === 'membercard' ? t.color.cyan : '#E5E8EC'}
              />
              <MenuText>{getGlobal({ option: 'fleet_menu', field: 'membership_card' })}</MenuText>
            </MenuRow>
          </MenuItem>
          <MenuItem
            href="#"
            active={active === 'map' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.fleet.map.path })}
          >
            <MenuRow>
              <Icon
                icon={faMapMarkedAlt}
                color={active === 'map' ? t.color.cyan : '#E5E8EC'}
              />
              <MenuText>{getGlobal({ option: 'fleet_menu', field: 'map' })}</MenuText>
            </MenuRow>
          </MenuItem>
          <MenuItem
            href="#"
            active={active === 'appointment' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.fleet.appointment.path })}
          >
            <MenuRow>
              <Icon
                icon={faCalendarDay}
                color={active === 'appointment' ? t.color.cyan : '#E5E8EC'}
              />
              <MenuText>{getGlobal({ option: 'fleet_menu', field: 'appointment' })}</MenuText>
            </MenuRow>
          </MenuItem>

          <Grid__separator size="60px" />

          <PaddingContainer>
            <MenuTitle>{getGlobal({ option: 'fleet_menu', field: 'label_settings' })}</MenuTitle>
            <Grid__separator size="10px" />
          </PaddingContainer>
          <MenuItem
            href="#"
            active={active === 'add-services' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.fleet.addServices.path })}
          >
            <MenuRow>
              <Icon icon={faLayerPlus} color={active === 'add-services' ? t.color.cyan : '#E5E8EC'} />
              <MenuText>{getGlobal({ option: 'fleet_menu', field: 'add_services' })}</MenuText>
            </MenuRow>
          </MenuItem>
          <MenuItem
            href="#"
            active={active === 'account' ? true : false}
            onClick={(e) => handleClick(e, { link: ROUTING.fleet.account.path })}
          >
            <MenuRow>
              <Icon icon={faUser} color={active === 'account' ? t.color.cyan : '#E5E8EC'} />
              <MenuText>{getGlobal({ option: 'fleet_menu', field: 'my_account' })}</MenuText>
            </MenuRow>
          </MenuItem>
          <LogoPartner>
            <LogoPartner__content>Sponsored by</LogoPartner__content>
            <LogoPartner__img src={t.image.global.totalLogo} />
          </LogoPartner>
        </Fragment>
      )}
    </NavElement>
  );
};

export default Nav;
