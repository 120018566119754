/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #f8e8ec;
  border-radius: 10px;
`;

const Cards__warning = ({ style, children, className }) => (
  <Container style={style} className={className}>
    {children}
  </Container>
);

export default Cards__warning;
