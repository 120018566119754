
import React, {
  useEffect, useState, Fragment, useContext,
} from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { Grid__separator } from 'components/grid/grid';
import {
 faWarehouse, faTruck, faTools, faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import Content__labelText from 'components/content/labelText/content__labelText';
import Content__labelTextarea from 'components/content/labelTextarea/content__labelTextarea';
import { GetSingleService } from 'helpers/api/routes/StandarRepair';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import {
  getFleetInformation,
  getFleetUserInformation,
  getWorkshopInformation,
} from 'util/service/getDataFromService';
import { strapiStandartrepairService, strapiRepairApprovalService, strapiCase } from 'util/strapi/content.starpi';
import { sequelizeDate } from 'util/date.util';
import { formatPrice } from 'util/format/price.format';
import Content__sectionTitle from 'components/content/sectionTitle/content__sectionTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content__bigTag from 'components/content/bigTag/content__bigTag';
import {
  Header,
  Title,
  ItemContainer,
  Row,
  Popup,
} from 'containers/workshopView/serviceDashboard/singlePop/serviceDashboard_singlePop_element';
import { SERVICENAME } from 'util/service/serviceName';
import { AppContext } from 'context/appContext';
import { GetSingleCase } from 'helpers/api/routes/Cases';
import Button__inline from 'components/button/inline/button__inline';
import { parseFile } from 'util/format/parseFile.format';

const Container = styled.div``;

const Column = styled.div``;

const DashboardStandardRepair__singlePop = ({
  style, active, closeEvent, id,
}) => {
  const { user } = useContext(AppContext);
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(false);
  const {
    loadPage, haveContentFor, getField, getGlobal, getFieldGroup,
  } = useContext(ContentContext);
  const getService = async () => {
    const res = await GetSingleCase(id);
    if (res) {
      setdata(res.data.data);
      setloading(false);
    }
  };

  // const [loading, setLoading] = useState(true);
  // const [caseData, setCaseData] = useState(undefined);
  // const getCase = async () => {
  //   const res = await GetSingleCase(id);
  //   if (res) {
  //     setCaseData(res.data.data);
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    if (haveContentFor(strapiStandartrepairService).success === false) {
      loadPage(strapiStandartrepairService);
    }
  }, []);

  useEffect(() => {
    if (active === true) {
      setloading(true);
      getService();
    }
  }, [active]);

  const renderLoader = () => <Loader__wrapper />;

  if (
    haveContentFor(strapiStandartrepairService).success === false
  ) {
    return (
      <Container style={style}>
        <Popup active={active} closeEvent={closeEvent}>
          {renderLoader()}
        </Popup>
      </Container>
    );
  }


  const renderContent = () => (
    <Fragment>
      <Header>
        <Title>{getField({ base: strapiStandartrepairService, field: 'service_title' })}</Title>
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_id' })}
          value={data.uid}
        />
        <Grid__separator width="15px" />
        <Content__bigTag
          label={getGlobal({ option: 'service', field: 'single_request_date' })}
          value={sequelizeDate({ date: data.createdAt, format: 'd/m/y' })}
        />
      </Header>
      <Grid__separator size="80px" />
      <Row>
        <ItemContainer>
          <Content__sectionTitle
            line
            title={getField({ base: strapiStandartrepairService, field: 'vehicle_title' })}
            icon={<FontAwesomeIcon icon={faTruck} />}
          />
          <Row>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' })}
                content={getFleetInformation({ obj: data, key: 'registrationNumber' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                style={{ textTransform: 'capitalize' }}
                label={getGlobal({ option: 'vehicleParameter', field: 'category' })}
                content={getGlobal({ option: 'vehicletype', field: getFleetInformation({ obj: data, key: 'type' }) }) || ''}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' })}
                content={getGlobal({ option: 'country', field: getFleetInformation({ obj: data, key: 'countryOfRegistration' }) }) || ''}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'mileage' })}
                content={getFleetInformation({ obj: data, key: 'mileage' })}
              />
            </Column>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'vincode' })}
                content={getFleetInformation({ obj: data, key: 'vin' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'vehicleParameter', field: 'brand' })}
                content={getFleetInformation({ obj: data, key: 'brand' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'vehicleParameter', field: 'fleet' })}
                content={getFleetUserInformation({ obj: data, key: 'companyName' })}
              />
            </Column>
          </Row>
        </ItemContainer>
        <ItemContainer>
          <Content__sectionTitle
            line
            title={getField({ base: strapiStandartrepairService, field: 'workshop_title' })}
            icon={<FontAwesomeIcon icon={faWarehouse} />}
          />
          <Row>
            <Column>
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'name' })}
                content={getWorkshopInformation({ obj: data, key: 'companyName' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'address' })}
                content={getWorkshopInformation({ obj: data, key: 'adress' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'phonenumber' })}
                content={`${getWorkshopInformation({ obj: data, key: 'phoneNumber' })}`}
              />
            </Column>
            <Column>
              <Content__labelText
                capitalize
                label={getGlobal({ option: 'workshopInformation', field: 'country' })}
                content={getWorkshopInformation({ obj: data, key: 'region' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'city' })}
                content={getWorkshopInformation({ obj: data, key: 'city' })}
              />
              <Grid__separator size="30px" />
              <Content__labelText
                label={getGlobal({ option: 'workshopInformation', field: 'zipcode' })}
                content={getWorkshopInformation({ obj: data, key: 'zipcode' })}
              />
            </Column>
          </Row>
        </ItemContainer>
      </Row>
      <Grid__separator size="100px" />
      <Content__sectionTitle
        line
        title={getField({ base: strapiStandartrepairService, field: 'repair_title' })}
        icon={<FontAwesomeIcon icon={faTools} />}
      />
      <Row>
        <Column style={{ width: '60%' }}>
          <Content__labelTextarea
            label={getField({ base: strapiStandartrepairService, field: 'single_service_description' })}
            lang={data.docTextLang}
            convert={user.user.lang}
            content={data.docDescription}
            dataName={SERVICENAME.standardrepair}
            base={data.docTranslate}
            id={data.id}
          />
          <Grid__separator size="25px" />
        </Column>
        <Column style={{ width: '25%' }}>
          <Content__labelText label={getField({ base: strapiStandartrepairService, field: 'single_service_price' })} content={formatPrice(data.price)} />
        </Column>
      </Row>
      {
        data.file && (
          <>
            <Grid__separator size="30px" />
            <Row>
              <Content__labelText
                label={getFieldGroup({ base: strapiCase, groupe: 'closing_form', field: 'invoice_label' })}
                noMargin
                content={(
                  <Button__inline
                    content={getField({ base: strapiRepairApprovalService, strapiCase, field: 'request_file_button' })}
                    icon={<FontAwesomeIcon icon={faDownload} />}
                    download
                    href={parseFile(data.file).url}
                  />
              )}
              />
            </Row>
          </>
        )
      }
    </Fragment>
  );

  return (
    <Container style={style}>
      <Popup active={active} closeEvent={closeEvent}>
        {loading || !data ? renderLoader() : renderContent()}
      </Popup>
    </Container>
  );
};

export default DashboardStandardRepair__singlePop;
