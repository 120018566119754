/* eslint-disable prefer-destructuring */

/* ------------------------------ CONVERT FIELD ----------------------------- */

export const convertField = ({ field }) => {
  if (field.type === 'select') {
    return field.result.slug;
  }
  if (field.type === 'selectLabel') {
    return field.result.label;
  }
  if (field.type === 'adress') {
    return field.result.result;
  }
  if (field.type === 'text') {
    return field.result;
  }
  if (field.type === 'tel') {
    return field.result.value;
  }
  if (field.type === undefined) {
    return null;
  }
  return field.result;
};


/* ---------------------------- CREATE NEW SELECT ARRAY --------------------------- */

export const createNewArray = ({ base, reset }) => {
  const newArray = [];

  base.forEach((element) => {
    newArray.push({
      label: element.label,
      slug: element.slug,
      active: reset ? false : element.active,
    });
  });

  return newArray;
};


/* ---------------------------- SPECIFIQUE RETURN FIELD - SELECT --------------------------- */

export const returnSelect = ({
  field, type, base, additionalCondition,
}) => {
  let baseToReturn = createNewArray({ base, reset: true });
  let resultToReturn = base.filter((el) => el.active === true)[0];
  if (field !== null && field !== undefined) {
    let resultObj = null;
    if (additionalCondition === 'label') {
      const resultFilter = baseToReturn.filter((el) => el.label.toLowerCase() === field.toLowerCase());
      if (resultFilter.length > 0) {
        resultObj = resultFilter[0];
      }
    } else {
      const resultFilter = baseToReturn.filter((el) => el.slug.toLowerCase() === field.toLowerCase());
      if (resultFilter.length > 0) {
        resultObj = resultFilter[0];
      }
    }

    if (resultObj !== null) {
      if (baseToReturn[baseToReturn.indexOf(resultObj)]) {
        baseToReturn[baseToReturn.indexOf(resultObj)].active = true;
        resultToReturn = baseToReturn[baseToReturn.indexOf(resultObj)];
      }
    } else {
      baseToReturn = base;
    }
  } else {
    baseToReturn = base;
  }

  return {
    base: baseToReturn,
    result: {
      result: resultToReturn,
      type,
    },
  };
};


/* ---------------------------- CHECK FIELD VALUE --------------------------- */

export const checkFieldValue = ({
  field, type, base, additionalCondition,
}) => {
  if (type === 'select') {
    return returnSelect({
      field, type, base, additionalCondition,
    });
  }
  if (type === 'text') {
    if (field !== '' || field !== null || field !== undefined) {
      return {
        base: { value: field },
        result: { result: field, type },
      };
    }
    return {
      base: { value: '' },
      result: { result: '', type },
    };
  }
  return field.result;
};

/* ---------------------------- CREATE ARRAY OF DATA --------------------------- */

export const createArrayOfData = ({
  array,
}) => {
  const arrayToReturn = [];

  array.forEach((element) => {
    arrayToReturn.push(convertField({ field: element }));
  });

  return arrayToReturn;
};

/* ---------------------------- CREATE REPONSE OBJ --------------------------- */

export const createResponseObj = ({
  data,
}) => {
  const arrayToReturn = [];

  data.forEach((element) => {
    arrayToReturn.push({
      value: convertField({ field: element.value }),
      key: element.key,
    });
  });

  return arrayToReturn;
};
