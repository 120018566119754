import { API_URL } from '../Utile';
import { CallGet, CallPost } from '../Helpers';

/* ---------------------------- GET ALL WORKSHOP ---------------------------- */

export const GetAllWorkshop = async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}workshop/getAll/`;

  const res = await CallGet(url, params, headers);

  return res;
};

export const GetObligoDetails = async ({ workshopId }) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  const params = {};

  const url = `${API_URL}workshop/getObligoDetails/${workshopId}`;

  const res = await CallGet(url, params, headers);

  return res;
};

export const setObligoAmount = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}workshop/setObligoAmount`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const revokeObligo = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}workshop/revokeObligo`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const revokePublicObligo = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}users/public/revokeObligo/${params.url}`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const resetObligo = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}workshop/resetObligo`;

  const res = await CallPost(url, params, headers);

  return res;
};

export const updateObligoUsed = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}workshop/updateObligoUsed`;

  const res = await CallPost(url, params, headers);

  return res;
};

/* ---------------------------- VALIDATE HOME BASE ---------------------------- */

export const homeBaseResponse = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}homebase/validate`;

  const res = await CallPost(url, params, headers);

  return res;
};
