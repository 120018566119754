/**
 * Import Lib & assets
 */

import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import t from 'theme';

/**
 * import components
 */
import Form__label from 'components/form/label/form__label';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

/**
 * styled components
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: ${t.font.primary};
  position: relative;
  width: ${(props) => (props.widthAuto ? 'auto' : '100%')};
  opacity: ${(props) => (props.disabled ? '0.4' : '1')};
`;

const SearchContainer = styled.div`
  position: absolute;
  left: 13px;
  top: 13px;
  color: #adadad;
`;

const SearchIcon = styled(FontAwesomeIcon)``;

const TextField = styled.input`
  font-size: 16px;
  font-weight: normal;
  text-transform: ${(props) => (props.textTransform ? props.textTransform : 'none')};
  color: ${(props) => (props.error
  ? t.color.red
  : props.colorTextField
    ? props.colorTextField
    : !props.asValue
      ? '#191919'
      : props.checkPass
        ? props.isValid === true
          ? t.color.green
          : props.isValid === false
            ? t.color.red
            : '#191919'
        : '#191919')};
  /* border: ${({ noBorder, error }) => (!noBorder ? (error ? `1px solid ${t.color.red}` : 'none') : 'none')}; */
  border: ${({ noBorder, error }) => (!noBorder ? (error ? `1px solid ${t.color.red}` : '1px solid #ececec') : (error ? `1px solid ${t.color.red}` : '1px solid #ececec'))};
  box-shadow: ${({ error }) => (error ? `inset 0px 0px 0px 1px ${t.color.red};` : 'none')};
  border-radius: 10px;
  border-top-right-radius: ${(props) => (props.searchAlternate ? 0 : null)};
  border-bottom-right-radius: ${(props) => (props.searchAlternate ? 0 : null)};
  padding-left: ${(props) => (props.search ? '37px' : props.padding ? props.padding : '15px')};
  height: ${(props) => (props.searchAlternate ? '40px !important' : '42px !important')};
  line-height: ${(props) => (props.searchAlternate ? '40px' : '42px')};
  background-color: #F7F7F7;
  width: 100%;
  position: relative;

  &:focus {
    outline:0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: ${({ removeArrow }) => (removeArrow ? 'none' : 'auto')};
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: ${({ removeArrow }) => (removeArrow ? 'none' : 'auto')};
  }

  ::-ms-input-placeholder {
    color: ${({ error }) => (error ? t.color.red : '#adadad')};
  }

  :-ms-input-placeholder {
    color: ${({ error }) => (error ? t.color.red : '#adadad')};
  }

  ::placeholder {
    color: ${({ error }) => (error ? t.color.red : '#adadad')};
  }
`;

const TextAreaField = styled.textarea`
  resize: none;
  font-size: 16px;
  font-weight: normal;
  color: ${(props) => (props.error
  ? t.color.red
  : props.colorTextField
    ? props.colorTextField
    : !props.asValue
      ? '#191919'
      : props.checkPass
        ? props.isValid === true
          ? t.color.green
          : props.isValid === false
            ? t.color.red
            : '#191919'
        : '#191919')};
  border: ${({ noBorder, error, contrastBorder }) => (!noBorder ? (error ? `1px solid ${t.color.red}` : contrastBorder ? '1px solid #d5d5d5' : 'none') : 'none')};
  box-shadow: ${({ error }) => (error ? `inset 0px 0px 0px 1px ${t.color.red};` : 'none')};
  border-radius: 10px;
  padding: 0 15px;
  padding-left: ${({ search }) => (search ? '37px' : '15px')};
  height: 120px !important;
  padding-top: 15px;
  line-height: 1.3;
  background-color: #F7F7F7;
  width: 100%;
  position: relative;

  &:focus {
    outline:0;
  }

  ::-ms-input-placeholder {
    color: ${({ error }) => (error ? t.color.red : '#adadad')};
  }

  :-ms-input-placeholder {
    color: ${({ error }) => (error ? t.color.red : '#adadad')};
  }

  ::placeholder {
    color: ${({ error }) => (error ? t.color.red : '#adadad')};
  }
`;

const EyesBtn = styled.a`
  width: 20px;
  height: auto;
  text-decoration: none;
  position: absolute;
  bottom: 12px;
  right: 12px;
`;

const Form__labelError = styled(Form__label)`
  color: ${t.color.red};
  text-transform: initial;
  font-size: 12px;
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: absolute;
  bottom: -10px;
  transform: translateY(100%);
  left: 0;
`;

const PriceIcon = styled.p`
  width: 20px;
  height: auto;
  text-decoration: none;
  position: absolute;
  right: 12px;
  font-size: 18px;
  font-weight: 500;
  bottom: 12px;
  color: ${(props) => (props.error
  ? t.color.red
  : props.colorTextField
    ? props.colorTextField
    : !props.asValue
      ? `rgba(${t.mixins.toRgba(t.color.dark)},0.78)`
      : props.checkPass
        ? props.isValid === true
          ? '#008B4E'
          : props.isValid === false
            ? t.color.red
            : t.color.orange
        : t.color.orange)};
`;

const HoursIcon = styled.p`
  height: auto;
  text-decoration: none;
  position: absolute;
  right: 12px;
  font-size: 14px;
  font-weight: 500;
  bottom: 14px;
  color: ${(props) => (props.error
  ? t.color.red
  : props.colorTextField
    ? props.colorTextField
    : !props.asValue
      ? `rgba(${t.mixins.toRgba(t.color.dark)},0.60)`
      : props.checkPass
        ? props.isValid === true
          ? '#008B4E'
          : props.isValid === false
            ? t.color.red
            : t.color.orange
        : t.color.orange)};
`;

const KmIcon = styled.p`
  height: auto;
  text-decoration: none;
  position: absolute;
  right: 12px;
  font-weight: 600;
  font-size: 14px;
  bottom: 14px;
  opacity: .7;
  color: ${(props) => (props.error
  ? t.color.red
  : props.colorTextField
    ? props.colorTextField
    : !props.asValue
      ? `rgba(${t.mixins.toRgba(t.color.dark)},0.60)`
      : props.checkPass
        ? props.isValid === true
          ? '#008B4E'
          : props.isValid === false
            ? t.color.red
            : t.color.orange
        : t.color.orange)};
`;


const Form__textfield = ({
  contrastBorder,
  unit,
  style,
  labelStyle,
  padding,
  inputStyle,
  focus,
  blur,
  change,
  label,
  labelSecondary,
  placeholder,
  searchAlternate,
  value,
  property,
  className,
  error,
  type,
  lock,
  passWordValidation,
  disabled,
  noBorder,
  textTransform,
  colorTextField,
  require,
  search,
  current,
  removeArrow,
  widthAuto,
  onKeyPressEnterEvent,
}) => {
  const [showPass, setShowPass] = useState(false);
  const [isValid, setIsvalid] = useState(false);
  const fieldRef = useRef(null);

  const focusEvent = (event) => {
    if (focus) {
      focus(event.target);
    }
  };

  const blurEvent = (event) => {
    if (blur) {
      blur(event.target);
    }
  };

  const checkPass = ({ _value }) => {
    const mediumRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

    const numRegex = new RegExp('(?=.*[0-9])');
    const majRegex = new RegExp('(?=.*[A-Z])');
    const sizeRegex = new RegExp('(?=.{8,})');
    const valide = mediumRegex.test(_value);

    if (valide === true) {
      passWordValidation({ valide: true, details: { size: true, number: true, maj: true } });
      setIsvalid(true);
    } else if (valide === false) {
      passWordValidation({
        valide: false,
        details: {
          size: sizeRegex.test(_value),
          number: numRegex.test(_value),
          maj: majRegex.test(_value),
        },
      });
      setIsvalid(false);
    }
  };

  const changeEvent = (event) => {
    let valueToPush = event.target.value;
    if (type === 'price') {
      // valueToPush = valueToPush.replace(/\D/g, '');
      // valueToPush = valueToPush.replace(/\s/g, '');
      valueToPush = valueToPush.replace(/(?!-)[^0-9.]/g, '');
    }
    if (type === 'password' && passWordValidation) {
      checkPass({ _value: event.target.value });
    }
    if (type === 'number') {
      valueToPush = valueToPush.replace(/\/r/g, '/');
      change({ property, value: valueToPush, target: event.target });
    }
    if (change && !lock) {
      change({ property, value: valueToPush, target: event.target });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (onKeyPressEnterEvent) {
        onKeyPressEnterEvent();
      }
    }
  };

  const renderInput = () => {
    if (type === 'textarea') {
      return (
        <TextAreaField
          unit={unit}
          contrastBorder={contrastBorder}
          search={search}
          style={inputStyle}
          asValue={value !== '' && value !== null}
          placeholder={placeholder}
          value={type === 'price' ? `${value}` : value != null ? value : ''}
          onFocus={(event) => focusEvent(event)}
          onBlur={(event) => blurEvent(event)}
          onChange={(event) => changeEvent(event)}
          error={error ? error.active : false}
          disabled={disabled}
          isValid={isValid}
          checkPass={passWordValidation ? true : false}
          noBorder={noBorder}
          colorTextField={colorTextField}
          removeArrow={type === 'price' || removeArrow}
          className={error ? 'formError' : ''}
          type={
            type === 'price' ? 'number' : type === 'password'
              ? showPass === true ? 'text' : 'password'
              : type === 'hours'
                ? 'price'
                : type
          }
          lock={lock}
        />
      );
    }

    return (
      <TextField
        padding={padding}
        searchAlternate={searchAlternate}
        onKeyPress={(event) => handleKeyPress(event)}
        textTransform={textTransform}
        search={search}
        style={inputStyle}
        asValue={value !== '' && value !== null}
        placeholder={placeholder}
        value={type === 'price' ? `${value}` : value != null ? value : ''}
        onFocus={(event) => focusEvent(event)}
        onBlur={(event) => blurEvent(event)}
        onChange={(event) => changeEvent(event)}
        error={error ? error.active : false}
        disabled={disabled}
        isValid={isValid}
        checkPass={passWordValidation ? true : false}
        noBorder={noBorder}
        colorTextField={colorTextField}
        removeArrow={type === 'price'}
        ref={fieldRef}
        className={error ? 'formError' : ''}
        unit={unit}
        type={
          type === 'price'
            ? 'number'
            : type === 'password'
              ? showPass === true
                ? 'text'
                : 'password'
              : type
        }
        lock={lock}
      />
    );
  };

  return (
    <Container
      disabled={disabled}
      style={style}
      className={className}
      widthAuto={widthAuto}
    >
      {label ? (
        <Form__label
          style={labelStyle}
          require={require}
          current={current}
          labelSecondary={labelSecondary}
        >
          {label}
        </Form__label>
      ) : null}
      {renderInput()}
      {type === 'password' ? (
        <EyesBtn
          onClick={(e) => {
            e.preventDefault();
            setShowPass(!showPass);
          }}
        >
          {showPass === true ? (
            <FontAwesomeIcon icon={faEye} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} />
          )}
        </EyesBtn>
      ) : null}
      {type === 'price' ? (
        <PriceIcon
          asValue={value !== '' && value !== null}
          error={error}
          colorTextField={colorTextField}
        >
          €
        </PriceIcon>
      ) : type === 'hours' ? (
        <HoursIcon
          asValue={value !== '' && value !== null}
          error={error}
          colorTextField={colorTextField}
        >
          Hour(s)
        </HoursIcon>
      ) : null}

      {search ? (
        <SearchContainer>
          <SearchIcon icon={faSearch} />
        </SearchContainer>
      ) : null}

      {unit && (
        <KmIcon
          asValue={value !== '' && value !== null}
          error={error}
          colorTextField={colorTextField}
        >
          Km
        </KmIcon>
      )}

      <Form__labelError active={error ? error.active : false}>
        {error && error.message ? error.message : ''}
      </Form__labelError>
    </Container>
  );
};

export default Form__textfield;
