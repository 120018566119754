import React from 'react';
import styled from 'styled-components';
import Breadcrumb from 'components/global/breadcrumb';

import { Grid__separator } from 'components/grid/grid';
import Content__pageTitle from 'components/content/pageTitle/content__pageTitle';

const Container = styled.div``;

const ServiceDashboard__header = ({
  style,
  breadCrumb_parent,
  breadCrumb_parentLink,
  breadCrumb_current,
  page_title,
}) => (
  <Container style={style}>
    <Content__pageTitle content={page_title} />
    <Grid__separator size="10px" />
    <Breadcrumb
      parent={breadCrumb_parent}
      parentLink={breadCrumb_parentLink}
      current={breadCrumb_current}
    />
    <Grid__separator size="25px" />
  </Container>
);

export default ServiceDashboard__header;
