/* eslint-disable no-useless-escape */

import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import t from 'theme';

import { FleetContext } from 'context/fleetUser/fleetContext';
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';

import Button__primary from 'components/button/primary/button__primary';
import { Grid__separator, Grid__popup } from 'components/grid/grid';
import { Content__text } from 'components/content/content';
import { Form__uploader } from 'components/form/form';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { List__table, List__content } from 'components/list/list';
import Content__counterInfo from 'components/content/counterInfo/content__counterInfo';
import { getUserFleetInformation } from 'util/service/getDataFromService';
import { createErrorObject } from 'util/conditions/checkField.condition';
import moment from 'moment';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { checkVin } from 'helpers/api/routes/Fleets';
import Message__sucess from 'components/message/sucess/message__sucess';
import { UseParseFile } from './UseParseFile';
import UseCreateXLSXFile from './CreateXlsx';

/**
 * styled components
 */

const Container = styled.div``;

const UploadPop = styled(Grid__popup)`
  padding: 40px;
  position: relative;
  ${t.mixins.flex({ justify: 'flex-start', align: 'center', direction: 'column' })}
`;

const UploadPopHeader = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'center', align: 'center', direction: 'column' })}
`;

const UploadPopHeaderContent = styled.div`
  max-width: 600px;
  text-align: center;
`;

const UploadPopLink = styled.a`
  display: inline;
  width: 100%;
  text-decoration: underline;
  font-weight: bold;
  color: #01B5E2;
  font-size: 14px;
`;

const UploadPopFooter = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
`;

const UploadPopFooterButtonContainer = styled.div`
  ${t.mixins.flex({ justify: 'flex-end' })}
  margin-bottom: 25px;
`;

const PopUpFleetBtn = styled(Button__primary)`
  margin-right: 30px;
`;


const ErrorText = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #E74C3C;
  margin-bottom: 10px;
`;

const CenterError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

/**
 * upload component is..
 */
//  XLSX.utils.json_to_sheet([data])

const Fleet__upload = ({
  style, uploadActive, closeEvent, basePage,
}) => {
  const { getUser, user } = useContext(AppContext);
  const {
    getGlobal, getGlobalObj, getField,
  } = useContext(ContentContext);
  const { AddVehicule } = useContext(FleetContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasFieldError, setHasFieldError] = useState(false);
  const headerCol = [
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'vincode' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'category' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'brand' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'mileage' }),
    },
  ];
  const [stepUpload, setStepUpload] = useState('upload');
  const [csvListOfTrucks, setCsvListOfTrucks] = useState(null);


  const slugify = (string) => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '') // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  };

  const renderLineError = (properties, key, errorArray) => errorArray && errorArray[key] && errorArray[key].hasOwnProperty(properties);

  const addLineToArray = ({ json }) => {
    if (json.length > 0 && json[0] !== 'error') {
      const obj = [];
      json.forEach((element, key) => {
        const childObj = [];
        childObj.push({ label: <List__content uppercase red={renderLineError('Registration', key)}>{element.registrationnumber}</List__content>, content: element.registrationnumber });
        childObj.push({ label: <List__content capitalize red={renderLineError('Country', key)}>{element.countryofregistration}</List__content>, content: element.countryofregistration });
        childObj.push({ label: <List__content uppercase red={renderLineError('Vin', key)}>{element.vincode}</List__content>, content: element.vincode });
        childObj.push({ label: <List__content capitalize red={renderLineError('type', key)}>{element.category}</List__content>, content: element.category });
        childObj.push({ label: <List__content capitalize red={renderLineError('Brand', key)}>{element.brand}</List__content>, content: element.brand });
        childObj.push({ label: <List__content red={renderLineError('First', key)}>{element.firstregistration}</List__content>, content: element.firstregistration });
        childObj.push({ label: <List__content red={renderLineError('Mileage', key)}>{element.mileage}</List__content>, content: element.mileage });

        obj.push(childObj);
      });
      setStepUpload('list');
      setCsvListOfTrucks(obj);
    } else if (json.length > 0 && json[0] === 'error') {
      setStepUpload('error');
    }
  };

  const reRenderLine = (errorArray) => {
    if (csvListOfTrucks.length > 0) {
      const obj = [];
      csvListOfTrucks.forEach((element, key) => {
        const childObj = [];

        childObj.push({ label: <List__content uppercase red={renderLineError('Registration', key, errorArray)}>{element[0].content}</List__content>, content: element[0].content });
        childObj.push({ label: <List__content capitalize red={renderLineError('Country', key, errorArray)}>{element[1].content}</List__content>, content: element[1].content });
        childObj.push({ label: <List__content uppercase red={renderLineError('Vin', key, errorArray)}>{element[2].content}</List__content>, content: element[2].content });
        childObj.push({ label: <List__content capitalize red={renderLineError('type', key, errorArray)}>{element[3].content}</List__content>, content: element[3].content });
        childObj.push({ label: <List__content capitalize red={renderLineError('Brand', key, errorArray)}>{element[4].content}</List__content>, content: element[4].content });
        childObj.push({ label: <List__content red={renderLineError('First', key, errorArray)}>{element[5].content.replaceAll(/[-|.]/g, '/')}</List__content>, content: element[5].content.replaceAll(/[-|.]/g, '/') });
        childObj.push({ label: <List__content red={renderLineError('Mileage', key, errorArray)}>{element[6].content}</List__content>, content: element[6].content });

        obj.push(childObj);
      });
      setCsvListOfTrucks(obj);
    }
  };

  const handleRetry = () => {
    setStepUpload('upload');
    setCsvListOfTrucks(null);
  };

  const getCountry = (content) => {
    const objectOfCountry = getGlobalObj({ option: 'country' });
    const arrayOfCountry = Object.keys(getGlobalObj({ option: 'country' }));
    const CountryText = content || '';
    const have = arrayOfCountry.find((el) => slugify(objectOfCountry[el]) === slugify(CountryText));
    if (have) {
      return have;
    }

    return '';
  };

  const getType = (content) => {
    const objectOfType = getGlobalObj({ option: 'vehicletype' });
    const arrayOfType = Object.keys(getGlobalObj({ option: 'vehicletype' }));
    const typeText = content || '';
    const have = arrayOfType.find((el) => slugify(objectOfType[el]) === slugify(typeText));
    if (have) {
      return have;
    }

    return '';
  };


  const checkVinError = (vinError) => {
    const errorArray = [];
    let hasError = false;
    csvListOfTrucks.forEach((element, key) => {
      errorArray.push({});
      let hasChildError = false;
      const errorObj = {};
      if (vinError[key] === true) {
        errorObj.Vin = { active: true };
        hasChildError = true;
      }

      errorArray[key] = errorObj;
      hasError = errorObj.hasError === true ? true : hasChildError;
    });

    setError(errorArray);
    setHasFieldError(hasError);
    reRenderLine(errorArray);
    return hasError;
  };

  const handleAddVehicule = async () => {
    const data = {
      data: [],
    };

    csvListOfTrucks.forEach((element) => {
      const tempObject = {
        countryOfRegistration: getCountry(element[1].content),
        registrationNumber: element[0].content,
        vin: element[2].content,
        type: getType(element[3].content),
        brand: element[4].content,
        firstDate: moment(element[5].content.replaceAll(/[-|.]/g, '/'), 'DD/MM/YYYY', true),
        mileage: element[6].content.replace(/(\r\n|\n|\r)/gm, ''),
      };
      data.data.push(tempObject);
    });


    const resCheck = await checkVin({ fleetObj: data });
    const formatCheck = formatApiRes(resCheck);
    if (formatCheck && formatCheck.success) {
      const haveError = formatCheck.data.filter((el) => el === true);
      if (haveError.length > 0) {
        setLoading(false);
        checkVinError(formatCheck.data);
      } else {
        const resAdd = await AddVehicule({ fleetObj: data, userId: getUserFleetInformation({ obj: user.user, key: 'id' }) });
        const resUser = await getUser();
        if (resUser.success === true && resAdd.success === true) {
          closeEvent();
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  };

  const generateCsv = (e) => {
    e.preventDefault();

    const rows = [
      [
        getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' }),
        getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' }),
        getGlobal({ option: 'vehicleParameter', field: 'vincode' }),
        getGlobal({ option: 'vehicleParameter', field: 'category' }),
        getGlobal({ option: 'vehicleParameter', field: 'brand' }),
        getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
        getGlobal({ option: 'vehicleParameter', field: 'mileage' }),
      ],
      ['CL034TN', getGlobalObj({ option: 'country' }).france, '1FAPP1283PWXXXXXX', getGlobalObj({ option: 'vehicletype' }).trailer, 'Mercedes', '28/03/2020', '1000000'],
    ];

    const csvContent = `data:text/csv;charset=utf-8,${rows.map((event) => event.join(';')).join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'template.csv');
    document.body.appendChild(link);

    link.click();
  };

  const checkField = () => {
    const errorArray = [];
    let hasError = false;
    csvListOfTrucks.forEach((element, key) => {
      errorArray.push({});
      const baseArrayField = [
        {
          key: 'Registration',
          type: 'nospacedashchar',
          value: element[0].content,
        },
        {
          key: 'Vin',
          type: 'nospacedashchar',
          value: element[2].content,
        },
        {
          key: 'Brand',
          type: 'simple',
          value: element[4].content,
        },
        {
          key: 'type',
          type: 'simple',
          value: element[3].content,
        },
        {
          key: 'First',
          type: 'simple',
          value: element[5].content.replaceAll(/[-|.]/g, '/'),
        },
        {
          key: 'Country',
          type: 'simple',
          value: element[1].content,
        },
        {
          key: 'Mileage',
          type: 'number',
          value: parseInt(element[6].content, 10),
        },
      ];

      const errorObj = createErrorObject(errorArray[key], baseArrayField, hasError, getGlobal);
      if (errorObj.object && !errorObj.object.hasOwnProperty('type')) {
        const objectOfType = getGlobalObj({ option: 'vehicletype' });
        const arrayOfType = Object.keys(getGlobalObj({ option: 'vehicletype' }));
        const typeText = element[3].content || '';
        const have = arrayOfType.find((el) => slugify(objectOfType[el]) === slugify(typeText));
        if (!have) {
          errorObj.object.type = { active: true };
          errorObj.hasError = true;
        }
      }

      if (errorObj.object && !errorObj.object.hasOwnProperty('Country')) {
        const objectOfCountry = getGlobalObj({ option: 'country' });
        const arrayOfCountry = Object.keys(getGlobalObj({ option: 'country' }));
        const CountryText = element[1].content || '';
        const have = arrayOfCountry.find((el) => slugify(objectOfCountry[el]) === slugify(CountryText));
        if (!have) {
          errorObj.object.Country = { active: true };
          errorObj.hasError = true;
        }
      }

      if (errorObj.object && !errorObj.object.hasOwnProperty('First')) {
        const date = element[5].content.replaceAll(/[-|.]/g, '/');

        if (date) {
          const checkDate = moment(date, 'DD/MM/YYYY', true);
          if (checkDate && checkDate.isValid()) {
            //
          } else {
            errorObj.object.First = { active: true };
            errorObj.hasError = true;
          }
        } else {
          errorObj.object.First = { active: true };
          errorObj.hasError = true;
        }
      }

      errorArray[key] = errorObj.object;
      hasError = errorObj.hasError === true ? true : hasError;
    });

    setError(errorArray);
    setHasFieldError(hasError);
    return {
      hasError,
      errorArray,
    };
  };

  const handleSave = () => {
    setLoading(true);
    const checkFieldValue = checkField();
    if (!checkFieldValue.hasError) {
      handleAddVehicule();
    } else {
      reRenderLine(checkFieldValue.errorArray);
      setLoading(false);
    }
  };


  const handleCloseEvent = async () => {
    if (closeEvent) {
      closeEvent();
    }
  };

  const renderErrorText = (type) => {
    if (type === 'Vin') {
      return getField({ base: basePage, field: 'upload_error_vin' });
    }
    if (type === 'Registration') {
      return getField({ base: basePage, field: 'upload_error_registration' });
    }
    if (type === 'Brand') {
      return getField({ base: basePage, field: 'upload_error_brand' });
    }
    if (type === 'Country') {
      return getField({ base: basePage, field: 'upload_error_country' });
    }
    if (type === 'Mileage') {
      return getField({ base: basePage, field: 'upload_error_mileage' });
    }
    if (type === 'type') {
      return getField({ base: basePage, field: 'upload_error_type' });
    }
    if (type === 'First') {
      return getField({ base: basePage, field: 'upload_error_date' });
    }
    return '';
  };

  const shouldeRenderErroMessage = () => {
    if (error) {
      const errorTypeArray = [];
      error.forEach((element) => {
        const keys = Object.keys(element);

        keys.forEach((child) => {
          if (!errorTypeArray.find((el) => el === child)) {
            errorTypeArray.push(child);
          }
        });
      });


      if (errorTypeArray.length > 0) {
        const errorComponents = errorTypeArray.map((item, key) => (
          <ErrorText key={key}>{renderErrorText(item)}</ErrorText>
        ));

        return {
          should: true,
          error: errorComponents,
        };
      }
    }

    return {
      should: false,
      error: '',
    };
  };

  const [parseFile] = UseParseFile(addLineToArray);
  const createTemplate = UseCreateXLSXFile({
    headers: [
      getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' }),
      getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' }),
      getGlobal({ option: 'vehicleParameter', field: 'vincode' }),
      getGlobal({ option: 'vehicleParameter', field: 'category' }),
      getGlobal({ option: 'vehicleParameter', field: 'brand' }),
      getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
      getGlobal({ option: 'vehicleParameter', field: 'mileage' }),
    ],
    row: ['CL034TN', getGlobalObj({ option: 'country' }).france, '1FAPP1283PWXXXXXX', getGlobalObj({ option: 'vehicletype' }).trailer, 'Mercedes', '28/03/2020', '1000000'],
    name: 'template',
  });

  useEffect(() => {
    setError(false);
    setHasFieldError(false);
  }, [stepUpload]);


  return (
    <Container style={style}>
      <UploadPop active={uploadActive === true} closeEvent={handleCloseEvent} width="760px">
        {stepUpload === 'upload' ? (
          <div>
            <UploadPopHeader>
              <Content__text
                type="p"
                color="#4D4D4D"
                align="center"
                style={{ fontWeight: 600, fontSize: 26 }}
              >
                {getField({ base: basePage, field: 'upload_title' })}
              </Content__text>
              <Grid__separator size="50px" />
              <UploadPopHeaderContent>
                <Content__text
                  color={t.color.dark}
                  align="center"
                  style={{
                    width: '100%', display: 'inline', lineHeight: '1.5', fontSize: '14px',
                  }}
                >
                  {getField({ base: basePage, field: 'upload_text' })}
                </Content__text>
                <Grid__separator size="10px" />
                <Content__text
                  color={t.color.dark}
                  align="center"
                  style={{
                    width: '100%', display: 'inline', lineHeight: '1.5', fontSize: '14px',
                  }}
                >
                  {getField({ base: basePage, field: 'upload_template_link' })}
                  {' '}
                </Content__text>
                <UploadPopLink href="#" onClick={(e) => generateCsv(e)}>
                  {getField({ base: basePage, field: 'upload_template_file_csv' })}
                </UploadPopLink>
                {' '}
                <Content__text
                  color={t.color.dark}
                  align="center"
                  style={{
                    width: '100%', display: 'inline', lineHeight: '1.5', fontSize: '14px', textTransform: 'lowercase',
                  }}
                >
                  {getField({
                    base: basePage,
                    field: 'upload_drag_and_drop_additional',
                  })}
                </Content__text>
                {' '}
                <UploadPopLink href="#" onClick={(e) => createTemplate()}>
                  {getField({ base: basePage, field: 'upload_template_file_xlsx' })}
                </UploadPopLink>
              </UploadPopHeaderContent>
            </UploadPopHeader>
            <Grid__separator size="35px" />
            <Form__uploader
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              title={getField({ base: basePage, field: 'upload_drag_and_drop' })}
              conditionalWord={getField({
                base: basePage,
                field: 'upload_drag_and_drop_additional',
              })}
              text={getField({ base: basePage, field: 'upload_browse' })}
              withBtn
              btnText={getField({ base: basePage, field: 'upload_validate_button' })}
              validateEvent={(file) => parseFile({ file })}
            />
          </div>
        ) : stepUpload === 'list' && csvListOfTrucks !== null ? (
          <div>
            <UploadPopHeader>
              <Content__text
                type="p"
                color="#4d4d4d"
                align="center"
                style={{ fontWeight: 600, fontSize: 26 }}
              >
                {getField({ base: basePage, field: 'upload_title' })}
              </Content__text>
              <Grid__separator size="35px" />
              <Content__counterInfo
                value={csvListOfTrucks.length}
                label={getField({ base: basePage, field: 'upload_counter' })}
              />
              <Grid__separator size="25px" />
              {
                shouldeRenderErroMessage().should && (
                  <div>
                    {shouldeRenderErroMessage().error}
                  </div>
                )
              }
              {/* Table */}
              <List__table
                headerCol={headerCol}
                lineCol={csvListOfTrucks}
                maxHeight="290px"
                noSelect
                borderLine
              />
              {/* end Table */}

              <Grid__separator size="35px" />
              <UploadPopFooter>
                <UploadPopFooterButtonContainer>
                  <PopUpFleetBtn border blue event={() => handleRetry()}>
                    {getField({ base: basePage, field: 'upload_button_retry' })}
                  </PopUpFleetBtn>
                  {!hasFieldError && (
                    <Button__primary event={() => handleSave()}>
                      {getField({ base: basePage, field: 'upload_button_save' })}
                    </Button__primary>
                  )}
                </UploadPopFooterButtonContainer>
                <Content__text
                  type="p"
                  color={t.color.dark}
                  align="center"
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    opacity: 0.6,
                    flex: 1,
                    display: 'block',
                    textAlign: 'left',
                  }}
                >
                  {getField({ base: basePage, field: 'upload_indication' })}
                </Content__text>
              </UploadPopFooter>
            </UploadPopHeader>
          </div>
        ) : stepUpload === 'error' ? (
          <CenterError>
            <Message__sucess
              icon={t.image.illu.error}
              title={getField({ base: basePage, field: 'upload_error_column_name' })}
              buttonText={getField({ base: basePage, field: 'upload_button_retry' })}
              buttonEvent={handleRetry}
            />
          </CenterError>
        ) : null}
      </UploadPop>
      {loading ? <Loader__wrapper /> : false}
    </Container>
  );
};

export default Fleet__upload;
