
import React, {
  Fragment, useState, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import t from 'theme';
import { Grid__separator } from 'components/grid/grid';
import { Content__sectionTitle } from 'components/content/content';
import Button__primary from 'components/button/primary/button__primary';
import { Form__textfield, Form__uploader } from 'components/form/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SendMail } from 'helpers/api/routes/Mail';
import { ROUTING } from 'util/constant/routing.constant';
import { faTools, faHandshakeAlt } from '@fortawesome/pro-regular-svg-icons';
import { Message__sucess } from 'components/message/message';
import Grid__popup from 'components/grid/popup/grid__popup';
import { createErrorObject } from 'util/conditions/checkField.condition';
import { ContentContext } from 'context/contentContext';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { formatApiRes } from 'util/function/formatApiRes.function';
import { SERVICE_STATUS } from 'util/constant/servicestatus.constant';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';
import { hasFieldError } from 'util/conditions/haveErrorField.conditions';
import { CreateService, createFile } from 'helpers/api/routes/PaymentGuarentee';
import { AppContext } from 'context/appContext';
import { sizeLimit } from 'util/conditions/sizeFile.util';
import { setAction } from 'util/function/reloadAction.function';
import { updateObligoUsed } from 'helpers/api/routes/Workshop';
import {
  strapiPaymentGuaranteeService, strapiRepairApprovalService, strapiStandartrepairService,
} from 'util/strapi/content.starpi';
import { isVehicleApprover } from 'util/workshop/getVehicleObjectInformation';
import { shouldRenderRepairApproval, shouldRenderStandarRepair } from 'util/service/createHistoric';


const Container = styled(Grid__popup)`
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
`;

const ContainerForm = styled.div`
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
  width: 500px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DashboardPaymentGuarantee__formPop = ({
  style, active, closePop, currentVehicle, caseId, onSuccess,
}) => {
  const { user, getUser } = useContext(AppContext);
  const { getGlobal, getFieldGroup } = useContext(ContentContext);
  const [description, setdescription] = useState('');
  const [price, setprice] = useState('');
  const [error, seterror] = useState(false);
  const [loading, setloading] = useState(false);
  const [file, setfile] = useState(null);
  const [sucessScreen, setsucessScreen] = useState(false);
  const {
    getField,
  } = useContext(ContentContext);

  const handleDescription = (value) => {
    setdescription(value.value);
  };

  const handlePrice = (value) => {
    setprice(value.value);
  };

  const getVehicleInformation = (key) => {
    if (currentVehicle) {
      if (currentVehicle) {
        if (currentVehicle[key]) {
          return currentVehicle[key];
        }
      }
    }
    return '';
  };

  const getVehicleUserInformation = (key) => {
    if (currentVehicle) {
      if (currentVehicle.UserFleet && currentVehicle.UserFleet.UserFleetAlias) {
        if (currentVehicle.UserFleet.UserFleetAlias[key]) {
          return currentVehicle.UserFleet.UserFleetAlias[key];
        }
      }
    }
    return '';
  };

  const checkField = () => {
    let errorObj = {};
    let errorArray = {};

    const baseArrayField = [
      {
        key: 'description',
        type: 'simple',
        value: description,
      },
      {
        key: 'price',
        type: 'simple',
        value: description,
      },
    ];

    errorObj = createErrorObject(errorArray, baseArrayField, errorObj.hasError, getGlobal);
    errorArray = errorObj.object;


    if (file === null || sizeLimit(file)) {
      if (sizeLimit(file)) {
        errorArray.file = { active: true, message: 'File limite' };
      } else {
        errorArray.file = { active: true };
      }
      errorObj.hasError = true;
    }

    seterror(errorArray);
    return errorObj.hasError;
  };

  const checkObligo = () => {
    if (currentVehicle && currentVehicle.UserFleet && price) {
      if (currentVehicle.UserFleet.obligoAmount && currentVehicle.UserFleet.obligoStatus === 'done') {
        const available = currentVehicle.UserFleet.obligoAvailable || 0;
        const amount = currentVehicle.UserFleet.obligoAmount || 0;
        const parseAvailable = parseInt(available, 10);
        const parseAmount = parseInt(amount, 10);
        const parsePrice = parseInt(price, 10);
        if ((parsePrice + parseAvailable) <= parseAmount) {
          return {
            obligo: true,
            obligoUsed: parsePrice + parseAvailable,
            id: currentVehicle.UserFleet.id,
          };
        }
      }
    }

    return {
      obligo: false,
      obligoUsed: 0,
    };
  };

  const sendMailProcess = (obligo) => {
    if (obligo) {
      if (currentVehicle && currentVehicle.UserFleet && currentVehicle.UserFleet.UserWorkshop && !currentVehicle.UserFleet.UserWorkshop.UserWorkshopAlias.notificationDisable) {
        SendMail({
          template: 'notification',
          templateNotification: {
            status: 'validate',
            statusLabel: getFieldGroup({
              select_lang: currentVehicle.UserFleet.UserWorkshop.UserWorkshopAlias.lang, base: strapiPaymentGuaranteeService, groupe: 'mail_auto_obligo', field: 'status',
            }),
            content: `${getFieldGroup({
              select_lang: currentVehicle.UserFleet.UserWorkshop.UserWorkshopAlias.lang, base: strapiPaymentGuaranteeService, groupe: 'mail_auto_obligo', field: 'content',
            })} ${user.user.companyName}`,
            btnContent: getFieldGroup({
              select_lang: currentVehicle.UserFleet.UserWorkshop.UserWorkshopAlias.lang, base: strapiPaymentGuaranteeService, groupe: 'mail_auto_obligo', field: 'btn',
            }),
            btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.workshop.dashboardPaymentGuarantee.path}`,
          },
          subject: getFieldGroup({
            select_lang: currentVehicle.UserFleet.UserWorkshop.UserWorkshopAlias.lang, base: strapiPaymentGuaranteeService, groupe: 'mail_auto_obligo', field: 'subject',
          }),
          destination: currentVehicle.UserFleet.UserWorkshop.UserWorkshopAlias.email,
        });
      }
    } else if (currentVehicle && currentVehicle.UserFleet && currentVehicle.UserFleet.UserWorkshop && !currentVehicle.UserFleet.UserWorkshop.UserWorkshopAlias.notificationDisable) {
      SendMail({
        template: 'notification',
        templateNotification: {
          status: 'action',
          statusLabel: getFieldGroup({
            select_lang: currentVehicle.UserFleet.UserWorkshop.UserWorkshopAlias.lang, base: strapiPaymentGuaranteeService, groupe: 'mail_start', field: 'status',
          }),
          content: `${user.user.companyName} ${getFieldGroup({
            select_lang: currentVehicle.UserFleet.UserWorkshop.UserWorkshopAlias.lang, base: strapiPaymentGuaranteeService, groupe: 'mail_start', field: 'content',
          })}`,
          btnContent: getFieldGroup({
            select_lang: currentVehicle.UserFleet.UserWorkshop.UserWorkshopAlias.lang, base: strapiPaymentGuaranteeService, groupe: 'mail_start', field: 'btn',
          }),
          btnLink: `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${ROUTING.workshop.dashboardPaymentGuarantee.path}`,
        },
        subject: getFieldGroup({
          select_lang: currentVehicle.UserFleet.UserWorkshop.UserWorkshopAlias.lang, base: strapiPaymentGuaranteeService, groupe: 'mail_start', field: 'subject',
        }),
        destination: currentVehicle.UserFleet.UserWorkshop.UserWorkshopAlias.email,
      });
    }
  };

  const handleLaunch = async () => {
    if (!checkField()) {
      setloading(true);
      const checkObligoValue = checkObligo();
      const fileParams = {
        file,
        extension: file.extension,
        serviceUid: '',
        fileId: '',
      };

      const res = await CreateService({
        fleetId: getVehicleInformation('id'),
        userFleetId: getVehicleUserInformation('id'),
        workshopId: user.user.UserWorkshop.id,
        description,
        price,
        caseId,
        validateUser: currentVehicle && currentVehicle.UserFleet ? currentVehicle.UserFleet.workshopId : null,
        textLang: user.user.lang,
        status: checkObligoValue.obligo ? SERVICE_STATUS.done : SERVICE_STATUS.waiting,
        type: checkObligoValue.obligo ? 'obligo' : 'manuel',
      });
      if (checkObligoValue.obligo === true) {
        await updateObligoUsed({
          used: checkObligoValue.obligoUsed,
          fleetId: checkObligoValue.id,
        });
      }
      const formated = formatApiRes(res);
      if (res) {
        if (formated.success) {
          if (res.data.success === true) {
            fileParams.serviceUid = res.data.data.uid;
            fileParams.fileId = res.data.data.id;
            const fileRes = await createFile(fileParams);
            if (fileRes && fileRes.data) {
              if (fileRes.data.success === true) {
                setloading(false);
                setsucessScreen({ active: true, id: res.data.data.id, obligo: checkObligoValue.obligo });
                sendMailProcess(checkObligoValue.obligo);
              } else {
                setloading(false);
              }
            }
          } else {
            setloading(false);
          }
          setsucessScreen({ active: true, id: res.data.data.id, obligo: checkObligoValue.obligo });
        } else {
          setloading(false);
        }
      }
    }
  };

  const handleSuccesMessage = async () => {
    if (onSuccess) {
      onSuccess(sucessScreen.id);
    } else {
      setloading(true);
      const resUser = await getUser();
      if (resUser) {
        setloading(false);
        setAction({ key: 'action', value: sucessScreen.id });
      } else {
        setloading(false);
      }
    }
  };

  const handleClose = () => {
    if (sucessScreen.active) {
      handleSuccesMessage();
    }
    if (closePop) {
      closePop();
    }
  };

  const parseFiles = (fileArray) => {
    setfile(fileArray[0]);
  };

  useEffect(() => {
    if (active) {
      setdescription('');
      setprice('');
      setfile(null);
    }
  }, [active]);


  return (
    <Container style={style} active={active} closeEvent={handleClose}>
      {(!sucessScreen || (sucessScreen && !sucessScreen.active)) ? (
        <Fragment>
          <Content__cardTitle
            content={getField({ base: strapiPaymentGuaranteeService, field: 'request_title' })}
          />
          <Grid__separator size="45px" />
          <ContainerForm>
            <Content__sectionTitle
              title={getField({ base: strapiPaymentGuaranteeService, field: 'repair_title' })}
              icon={<FontAwesomeIcon icon={faTools} />}
            />
            <Form__textfield
              type="textarea"
              label={getField({ base: strapiPaymentGuaranteeService, field: 'repair_description' })}
              require
              property="description"
              value={description}
              change={handleDescription}
              error={hasFieldError(error, 'description')}
            />
            <Grid__separator size="25px" />
            <Form__textfield
              label={getField({ base: strapiPaymentGuaranteeService, field: 'repair_price' })}
              require
              value={price}
              property="price"
              change={handlePrice}
              error={hasFieldError(error, 'price')}
              type="price"
            />
            <Grid__separator size="25px" />
            <Form__uploader
              label={getField({ base: strapiPaymentGuaranteeService, field: 'repair_file' })}
              onFilesAddedCallBack={parseFiles}
              error={error.hasOwnProperty('file') ? { active: true, message: error.file.message } : false}
            />
            <Grid__separator size="25px" />
            <Row>
              <Button__primary event={handleLaunch}>
                {getField({ base: strapiPaymentGuaranteeService, field: 'launch_request_button' })}
              </Button__primary>
            </Row>
          </ContainerForm>
        </Fragment>
      ) : (
        <Message__sucess
          icon={t.image.illu.check}
          title={
            sucessScreen.obligo ? getField({ base: strapiPaymentGuaranteeService, field: 'form_msg_obligo_title' }) : getField({ base: strapiPaymentGuaranteeService, field: 'form_msg_success_title' })
          }
          text={
            sucessScreen.obligo ? getField({ base: strapiPaymentGuaranteeService, field: 'form_msg_obligo_text' }) : getField({ base: strapiPaymentGuaranteeService, field: 'form_msg_success_text' })
          }
          buttonText={getField({ base: strapiPaymentGuaranteeService, field: 'form_msg_success_button' })}
          buttonEvent={handleSuccesMessage}
        />
      )}
      {loading ? <Loader__wrapper /> : false}
    </Container>

  );
};

export default DashboardPaymentGuarantee__formPop;
