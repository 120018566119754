/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  color: #4d4d4d;
`;

const Content__titleLight = ({ style, content }) => <Container style={style}>{content}</Container>;

export default Content__titleLight;
