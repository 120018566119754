
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import t from 'theme';

import { ContentContext } from 'context/contentContext';


/**
 * import containers & components
 */

import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { Grid__separator } from 'components/grid/grid';
import Button__primary from 'components/button/primary/button__primary';
import { List__table, List__content } from 'components/list/list';
import { AppContext } from 'context/appContext';
import { useData } from 'components/data/getter/data__getter';
import { getFormService } from 'util/function/getter';
import { sequelizeDate } from 'util/date.util';
import { strapiSingleServicePage } from 'util/strapi/content.starpi';
import Form__search from 'components/form/search/form__search';
/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  ${t.mixins.flex({ justify: 'center', direction: 'column' })}
`;

const InfoText = styled.div`
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #4d4d4d;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * notset component is..
 */

const ServiceDashboard__requestList = ({
  saveEvent,
  loading,
  prevEvent,
  serviceSelected,
}) => {
  let pageNumber = 1;
  const { user } = useContext(AppContext);
  const { getGlobal, getField } = useContext(ContentContext);
  const [selectedRequest, setselectedRequest] = useState(null);
  const [searchValue, setsearchValue] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      id: user.user.UserFleet.id,
      user: true,
    },
    service: serviceSelected.slug,
    type: 'service',
  });

  const headerCol = [
    {
      label: getGlobal({ option: 'service_table', field: 'id_header' }),
    },
    {
      label: getGlobal({ option: 'service_table', field: 'vehicle_header' }),
    },
    {
      label: getGlobal({ option: 'service_table', field: 'date_header' }),
    },
    {
      label: getGlobal({ option: 'service_table', field: 'workshop' }),
    },
    {
      label: getGlobal({ option: 'workshopInformation', field: 'city' }),
    },
  ];


  const renderList = () => {
    const orderArray = resData && resData.data ? resData.data : [];
    const arrayOfLine = [];

    orderArray.forEach((element) => {
      arrayOfLine.push([
        {
          component: <List__content uppercase>{getFormService(element, 'uid')}</List__content>,
          type: 'node',
        },
        {
          component: (
            <List__content>{getFormService(element, 'registrationNumber', 'vehicle')}</List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>
              {sequelizeDate({ date: getFormService(element, 'createdAt'), format: 'd/m/y' })}
            </List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>{getFormService(element, 'companyName', 'userworkshop')}</List__content>
          ),
          type: 'node',
        },
        {
          component: (
            <List__content>{getFormService(element, 'region', 'userworkshop')}</List__content>
          ),
          type: 'node',
        },
      ]);
    });

    return arrayOfLine;
  };

  const handleSave = () => {
    if (selectedRequest !== null) {
      if (saveEvent) {
        saveEvent(selectedRequest);
      }
    }
  };

  const handleSelectLine = (value) => {
    if (resData && resData.data) {
      setselectedRequest(resData.data[parseInt(value[0].key, 10)]);
    }
  };

  const scrollEndEvent = () => {
    if (resData.data && !resData.loading && pageNumber + 1 > 1 && resData.data.length < resData.count) {
      pageNumber += 1;
      setPage(pageNumber, resData.data);
    }
  };

  const doSearch = () => {
    setSearch(searchValue);
  };

  return (
    <React.Fragment>
      <Container>
        <InfoText>{getField({ base: strapiSingleServicePage, field: 'request_list_label' })}</InfoText>
        <Grid__separator size="20px" />
        <Form__search
          property="searchValue"
          search
          placeholder={getField({ base: strapiSingleServicePage, field: 'request_list_search' })}
          change={(value) => setsearchValue(value.value)}
          searchValue={searchValue}
          event={doSearch}
        />
        <Grid__separator size="30px" />
        <List__table
          headerCol={headerCol}
          lineCol={resData && resData.data ? renderList() : []}
          maxHeight="250px"
          noSelectAll
          selectOne
          borderLine
          selectedLine={handleSelectLine}
          scrollEndEvent={scrollEndEvent}
        />
        <Grid__separator size="35px" />
        <Row>
          <Button__primary border blue event={prevEvent}>{getGlobal({ option: 'service', field: 'single_launch_prevbutton' })}</Button__primary>
          <Button__primary style={{ marginLeft: 25 }} event={handleSave}>{getGlobal({ option: 'service', field: 'select_workshop_button' })}</Button__primary>
        </Row>
      </Container>
      {loading ? <Loader__wrapper /> : false}
    </React.Fragment>
  );
};

export default ServiceDashboard__requestList;
