
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import { getPercent } from 'util/math.utile';
import { strapiService24Service } from 'util/strapi/content.starpi';
import Cards_serviceCoverage from 'components/cards/serviceCoverage/cards__serviceCoverage';
import Cards_serviceInfo from 'components/cards/serviceInfo/cards__serviceInfo';
import { Grid__separator } from 'components/grid/grid';
import Button__inline from 'components/button/inline/button__inline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cards__launchService from 'components/cards/launchService/cards__launchService';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/pro-solid-svg-icons';


const Container = styled.div``;

const DashboardService24__action = ({
  style, infoEvent, settingsEvent, launchServicePop, handleCallButton, coverage, fleet,
}) => {
  const { getGlobal, getField } = useContext(ContentContext);

  return (
    <Container style={style}>
      <Cards__launchService
        title={getField({ base: strapiService24Service, field: 'launch_title' })}
        content={getField({ base: strapiService24Service, field: 'launch_text' })}
        buttonTopContent={getField({ base: strapiService24Service, field: 'launch_call_button' })}
        buttonTopEvent={handleCallButton}
        buttonTopIcon={faPhone}
        buttonBottomContent={getField({ base: strapiService24Service, field: 'launch_request_button' })}
        buttonBottomEvent={launchServicePop}
        buttonBottomIcon={faEnvelope}
        conditionalWord={getGlobal({ option: 'globalcontent', field: 'uploader_or' })}
      />
      <Grid__separator size="20px" />
      <Cards_serviceCoverage
        paddingSmall
        button={(
          <Button__inline
            event={settingsEvent}
            content={getGlobal({ option: 'service', field: 'single_settings_button' })}
            icon={<FontAwesomeIcon icon={faCog} />}
          />
        )}
        to="./test"
        progress={fleet ? getPercent({ total: fleet, number: coverage }) : 0}
        value={coverage}
        labelProgress={getGlobal({ option: 'service', field: 'single_fleetcoverage_title' })}
        labelCounter={getGlobal({ option: 'service', field: 'single_fleetcoverage_vehiclelabel' })}
      />
      <Grid__separator size="20px" />
      <Cards_serviceInfo
        title={`${getGlobal({ option: 'service', field: 'single_info_title' })} ${getField({ base: strapiService24Service, field: 'service_title' })}`}
        event={infoEvent}
      />
      <Grid__separator size="40px" />
    </Container>
  );
};

export default DashboardService24__action;
