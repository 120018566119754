
import React, {
  useContext, useEffect, useState,
} from 'react';
import Nav from 'components/global/nav';
import t from 'theme';
import { ContentContext } from 'context/contentContext';

import {
  faListOl, faQuestion, faBookOpen, faShoppingCart,
} from '@fortawesome/pro-light-svg-icons';
import Layout__container from 'containers/layout/container/layout__container';
import { Transition__loader_controller } from 'components/transition/transition';
import DashboardPriority90__historic from 'containers/fleetView/dashboardPriority90/historic/dashboardPriority90__historic';
import DashboardPriority90__action from 'containers/fleetView/dashboardPriority90/action/dashboardPriority90__action';
import DashboardPriority90__infoPop from 'containers/fleetView/dashboardPriority90/infoPop/dashboardPriority90__infoPop';
import DashboardPriority90__singlePop from 'containers/fleetView/dashboardPriority90/singlePop/dashboardPriority90__singlePop';
import DashboardPriority90__settings from 'containers/fleetView/dashboardPriority90/settings/dashboardPriority90__settings';
import { strapiPriority90Service, strapiSingleServicePage, strapiWorkshopDashboardPriority90 } from 'util/strapi/content.starpi';
import ServiceDashboard__layout from 'containers/fleetView/serviceDashboard/layout/serviceDashboard__layout';
import { ROUTING } from 'util/constant/routing.constant';
import { getParams } from 'util/get.util';
import { SERVICENAME } from 'util/service/serviceName';
import { AppContext } from 'context/appContext';
import { formatApiCountRes } from 'util/function/formatApiRes.function';
import { getcoverage, getVehicleList } from 'helpers/api/routes/getData';
import { useData } from 'components/data/getter/data__getter';


/**
  * @name DashboardPriority90
  */

const DashboardPriority90 = () => {
  const {
    loadPage, haveContentFor, getField, getGlobal,
  } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [activeinfoPop, setactiveinfoPop] = useState(false);
  const [activeSettingPop, setactiveSettingPop] = useState(false);
  const [activesinglePop, setactivesinglePop] = useState(false);
  const [, setactiveLaunchPop] = useState(false);
  const [coverage, setcoverage] = useState(0);
  let pageNumber = 1;
  // eslint-disable-next-line no-unused-vars
  const [resData, refreshData, setPage, setSearch] = useData({
    params: {
      id: user.user.UserFleet.id,
      user: true,
    },
    service: SERVICENAME.priority90,
    type: 'service',
  });


  const [fleetArray, setfleetArray] = useState([]);
  const getFleetList = async () => {
    const res = await getVehicleList({
      userId: user.user.UserFleet.id,
    });

    const formated = formatApiCountRes(res);
    if (formated.success) {
      setfleetArray(formated);
    }
  };

  const loadContent = async () => {
    if (haveContentFor(strapiPriority90Service).success === false) {
      await loadPage(strapiPriority90Service);
    }
    if (haveContentFor(strapiSingleServicePage).success === false) {
      await loadPage(strapiSingleServicePage);
    }
    if (haveContentFor(strapiWorkshopDashboardPriority90).success === false) {
      await loadPage(strapiWorkshopDashboardPriority90);
    }

    if (getParams('launch')) {
      setactiveLaunchPop(true);
      window.history.pushState({}, '', window.location.pathname);
    }
  };

  const loadCoverage = async () => {
    const res = await getcoverage({
      service: 'priority90Subscription',
      userId: user.user.UserFleet.id,
    });

    const formatedCoverage = formatApiCountRes(res);

    setcoverage(formatedCoverage.count);
  };

  useEffect(() => {
    loadContent();
    loadCoverage();
    getFleetList();
  }, []);

  if (
    haveContentFor(strapiPriority90Service).success === false
    || haveContentFor(strapiSingleServicePage).success === false
    || haveContentFor(strapiWorkshopDashboardPriority90).success === false
  ) {
    return <Transition__loader_controller active />;
  }

  const handleCloseInfoPop = () => {
    setactiveinfoPop(false);
  };

  const handleOpenInfoPop = () => {
    setactiveinfoPop(true);
  };

  const handleCloseSettingPop = () => {
    setactiveSettingPop(false);
  };

  const handleOpenSettingPop = () => {
    setactiveSettingPop(true);
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({ active: false, id: null });
  };

  const handleOpenSinglePop = ({ id }) => {
    setactivesinglePop({ active: true, id });
  };

  const handleOpenLaunchPop = () => {
    setactiveLaunchPop(true);
  };

  const renderInfoPopProps = () => ({
    intro: {
      title: getField({ base: strapiPriority90Service, field: 'service_title' }),
      txt: getField({ base: strapiPriority90Service, field: 'description' }),
      price: `0,00 ${getField({ base: strapiSingleServicePage, field: 'currency' })}`,
      label: getField({ base: strapiSingleServicePage, field: 'price_detail' }),
      banner: t.image.global.priority90,
    },
    condition1: {
      content: getField({ base: strapiPriority90Service, field: 'registration_processs' }),
      title: getField({ base: strapiSingleServicePage, field: 'registration_processs' }),
      icon: faListOl,
    },
    condition2: {
      content: getField({ base: strapiPriority90Service, field: 'how' }),
      title: getField({ base: strapiSingleServicePage, field: 'how' }),
      icon: faQuestion,
    },
    condition3: {
      content: getField({ base: strapiPriority90Service, field: 'conditions' }),
      title: getField({ base: strapiSingleServicePage, field: 'conditions' }),
      icon: faBookOpen,
    },
    condition4: {
      content: getField({ base: strapiPriority90Service, field: 'payment' }),
      title: getField({ base: strapiSingleServicePage, field: 'payment' }),
      icon: faShoppingCart,
    },
  });

  const handleTableClick = (line, vehicle) => {
    handleOpenSinglePop({ id: vehicle.id });
  };

  const handleLoadNewPage = () => {
    if (resData.data && !resData.loading && pageNumber + 1 > 1 && resData.data.length < resData.count) {
      pageNumber += 1;
      setPage(pageNumber, resData.data);
    }
  };

  return (
    <Layout__container>
      <Nav active="myservices" />
      <Transition__loader_controller active={false} />
      <ServiceDashboard__layout
        page_title={getField({ base: strapiPriority90Service, field: 'service_title' })}
        // breadCrumb
        breadCrumb_parent={getGlobal({ option: 'fleet_menu', field: 'my_services' })}
        breadCrumb_parentLink={ROUTING.fleet.myServices.path}
        breadCrumb_current={getField({ base: strapiPriority90Service, field: 'service_title' })}
        // Service history layout
        history={(
          <DashboardPriority90__historic
            tableEvent={handleTableClick}
            loadingData={resData.loading}
            data={resData.data}
            scrollEndEvent={handleLoadNewPage}
            loadingNewPage={resData.loading}
          />
        )}
        // Action side element
        action={(
          <DashboardPriority90__action
            infoEvent={handleOpenInfoPop}
            launchServicePop={handleOpenLaunchPop}
            settingsEvent={handleOpenSettingPop}
            coverage={coverage}
            fleet={fleetArray ? fleetArray.count : 0}
          />
        )}
        // Info pop up
        activeInfoPop={activeinfoPop}
        closeInfoPop={handleCloseInfoPop}
        infoPopProps={renderInfoPopProps()}
        infoPop={<DashboardPriority90__infoPop />}
        // Single pop up
        activeSinglePop={activesinglePop}
        closeSinglePop={handleCloseSinglePop}
        singlePop={(
          <DashboardPriority90__singlePop
            active={activesinglePop.active}
            id={activesinglePop.id}
            closeEvent={handleCloseSinglePop}
          />
        )}
        // Setting pop up

        settingPop={(
          <DashboardPriority90__settings activeSettingPop={activeSettingPop} closeSettingPop={handleCloseSettingPop} fleetArray={fleetArray} />
        )}
      />
    </Layout__container>
  );
};

export default DashboardPriority90;
