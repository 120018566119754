
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ContentContext } from 'context/contentContext';
import t from 'theme';
import Cards__serviceCard from 'components/cards/serviceCard/cards__serviceCard';
import { Grid__separator } from 'components/grid/grid';
import { strapiWorkshopDashboardRepairApproval, strapiRepairApprovalService } from 'util/strapi/content.starpi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshakeAlt } from '@fortawesome/pro-regular-svg-icons';


const Container = styled.div``;

const CardContainer = styled.div`
  width: 330px;
  ${t.mixins.flex({ direction: 'column', justify: 'space-between', align: 'flex-start' })}
`;

const DashboardRepairApproval__action = ({ style, infoEvent }) => {
  const { getField } = useContext(ContentContext);

  return (
    <Container style={style}>
      <CardContainer>
        <Cards__serviceCard
          dashboard
          event={infoEvent}
          imageUrl={t.image.global.service24}
          icon={<FontAwesomeIcon icon={faHandshakeAlt} color="#4D4D4D" />}
          title={getField({ base: strapiWorkshopDashboardRepairApproval, field: 'card_discover_title' })}
          description={getField({ base: strapiRepairApprovalService, field: 'short_description' })}
        />
      </CardContainer>
      <Grid__separator size="30px" />
    </Container>
  );
};

export default DashboardRepairApproval__action;
