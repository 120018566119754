/**
 * Import Lib & assets
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

/**
 * import components
 */

import { Form__checkbox } from 'components/form/form';

/**
 * styled components
 */

const Container = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
`;

const ColItem = styled.td`
  display: table-cell;
  vertical-align: middle;
  padding-right: 10px;
  padding-left: 10px;
  text-align: left;
  line-break: inherit;
  word-wrap: break-word;
  width: auto;
  padding-bottom: 10px;
  padding-top: 10px;
`;


const Line = styled.tr`
  display: table-row;
  position: relative;
  vertical-align: inherit;
  border-color: inherit;
  border-radius: 5px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  &:hover {
    background-color: #E6F8FC;
  }

  ${({ disable }) => disable && `
    pointer-events: none;
    opacity: 0.6;
    &:hover {
      background-color: transparent;
    }
  `};
`;

const ItemContent = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: normal;
`;

/**
 * header component is..
 */

const List__header = ({
  style,
  col,
  allCheck,
  handleSingleCheck,
  noSelect,
  maxHeight,
  borderLine,
  selectOne,
  lineEvent,
  square,
  isCheck,
}) => {
  const initCheck = () => {
    const array = [];
    col.forEach((element, _key) => {
      array[_key] = false;
    });
    return array;
  };
  const [checkBoxValue, setCheckBoxValue] = useState(isCheck || initCheck());

  const renderArray = (array) => {
    const toReturn = [];
    const keys = Object.keys(array);
    keys.forEach((element) => {
      toReturn.push({ key: element, value: array[element] });
    });
    return toReturn.filter((check) => check.value === true);
  };

  const handleCheck = (line, key) => {
    const array = checkBoxValue;
    array[key] = !array[key];
    setCheckBoxValue(array);
    handleSingleCheck({
      value: array[key],
      line,
      key,
      array: renderArray(array),
    });
  };

  const handleCheckSelectOne = (line, key) => {
    const array = [];
    col.forEach((element, _key) => {
      array[_key] = false;
    });
    array[key] = true;
    setCheckBoxValue(array);
    handleSingleCheck({
      value: true,
      line,
      key,
      array: renderArray(array),
    });
  };

  useEffect(() => {
    if (allCheck === 'all') {
      const array = {};
      col.forEach((element, key) => {
        array[key] = true;
      });
      setCheckBoxValue(array);
    } else if (allCheck === 'basic') {
      const array = {};
      col.forEach((element, key) => {
        array[key] = checkBoxValue[key] || false;
      });
      setCheckBoxValue(array);
    } else {
      const array = {};
      col.forEach((element, key) => {
        array[key] = false;
      });
      setCheckBoxValue(array);
    }
  }, [allCheck]);

  const renderLine = ({ item }) => {
    if (item) {
      if (item.hasOwnProperty('type')) {
        if (item.type === 'text') {
          return <ItemContent>{item.label}</ItemContent>;
        }

        if (item.type === 'node') {
          return item.component;
        }
      }

      return item.hasOwnProperty('label') ? <ItemContent>{item.label}</ItemContent> : '';
    }

    return '';
  };

  const handleEvent = ({ line, key }) => {
    if (lineEvent) {
      lineEvent({ line, key });
    }

    if (selectOne) {
      handleCheckSelectOne(line, key);
    } else if (!selectOne && !noSelect) {
      handleCheck(line, key);
    }
  };

  return (
    <Container style={style} maxHeight={maxHeight}>
      {col.map((line, key1) => (
        <Line
          style={style}
          key={key1}
          onClick={() => handleEvent({ line, key: key1 })}
          borderLine={borderLine}
          hasEvent={lineEvent ? true : false}
          disable={line[0] === 'disable'}
        >
          {!noSelect && !selectOne ? (
            <ColItem number={col.length + 1}>
              <Form__checkbox
                value={line[0] === 'disable' ? true : checkBoxValue[key1]}
                square={square}
              />
            </ColItem>
          ) : null}
          {selectOne ? (
            <ColItem number={col.length + 1}>
              <Form__checkbox
                value={checkBoxValue[key1]}
                callback={(value) => handleCheckSelectOne(value, line, key1)}
                square={square}
              />
            </ColItem>
          ) : null}
          {line.map((item, key) => (
            item !== 'disable' && (
              <ColItem key={key} number={col.length}>
                {renderLine({ item })}
              </ColItem>
            )
          ))}
        </Line>
      ))}
    </Container>
  );
};

export default List__header;
