/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

const Container = styled.div`
  border: 2px solid #46c9ea;
  border-radius: 10px;
  height: 90px;
  width: 350px;
  ${t.mixins.flex({ justify: 'center', align: 'center', direction: 'row' })};
`;

const Value = styled.span`
  font-weight: 600;
  font-size: 40px;
  text-align: right;
  color: #01b5e2;
`;

const Currency = styled.span`
  font-weight: 600;
  font-size: 30px;
  text-align: right;
  color: #01b5e2;
  margin-left: 5px;
  margin-right: 10px;
`;

const Label = styled.span`
  font-weight: normal;
  font-size: 22px;
  color: #01b5e2;
`;

const Content__totalPrice = ({
  style, value, currency, label,
}) => (
  <Container style={style}>
    <Value>{value}</Value>
    <Currency>{currency}</Currency>
    <Label>{label}</Label>
  </Container>
);

export default Content__totalPrice;
