export const FLEET_PAYMENT = {
  CASH: 'cash',
  CREDITCARDS: 'credit_card',
  BANKTRANSFER: 'bank_transfer',
  FUELCARD: 'fuel_card',
};

export const FLEET_EXTRA_PAYMENT = {
  DKV: 'dkv',
  UTA: 'uta',
  TOTAL: 'total',
  SHELL: 'shell',
  BP: 'bp',
  OTHER: 'other',
};

export const formatPaymentMethods = (main, secondary) => {
  let _main = '';
  let _secondary = null;
  if (main) {
    main.forEach((element) => {
      if (element && element.active) {
        if (_main !== '') {
          _main += `,${element.slug}`;
        } else {
          _main += element.slug;
        }
      }
    });
  }

  if (secondary) {
    if (_main && _main.includes(FLEET_PAYMENT.FUELCARD)) {
      const active = secondary.find((el) => el.active === true);
      if (active) {
        _secondary = active.slug;
      }
    }
  }

  return {
    main: _main,
    secondary: _secondary,
  };
};

export const parsePaymentMethods = (main, secondary) => {
  const _main = {
    [FLEET_PAYMENT.CASH]: false,
    [FLEET_PAYMENT.CREDITCARDS]: false,
    [FLEET_PAYMENT.BANKTRANSFER]: false,
    [FLEET_PAYMENT.FUELCARD]: false,
  };

  const _secondary = {
    [FLEET_EXTRA_PAYMENT.DKV]: false,
    [FLEET_EXTRA_PAYMENT.UTA]: false,
    [FLEET_EXTRA_PAYMENT.TOTAL]: false,
    [FLEET_EXTRA_PAYMENT.SHELL]: false,
    [FLEET_EXTRA_PAYMENT.BP]: false,
    [FLEET_EXTRA_PAYMENT.OTHER]: false,
  };

  if (main && main !== '') {
    const array = main.split(',');
    array.forEach((element) => {
      if (element && element !== '' && _main[element] === false) {
        _main[element] = true;
      }
    });
  }

  if (secondary && secondary !== '') {
    if (_secondary[secondary] === false) {
      _secondary[secondary] = true;
    }
  }

  return {
    main: _main,
    secondary: _secondary,
  };
};
