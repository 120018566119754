import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Card = styled.div`
  padding: 20px 30px 20px 20px;
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 5px 20px rgba(58, 74, 78, 0.1);
  border-radius: 10px;
  min-width: 400px;
  margin-bottom: 20px;
`;

const Card__label = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: rgba(77, 77, 77, 0.9);
`;

const Card__icon = styled(FontAwesomeIcon)`
  font-size: 23px;
  margin-left: 20px;
`;


const Cards__iconCard = ({ icon, content }) => (
  <Card>
    <Card__label>{content}</Card__label>
    <Card__icon icon={icon} />
  </Card>
);

export default Cards__iconCard;
