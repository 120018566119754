
/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';
import t from 'theme';

import Loader__small from 'components/loader/small/loader__small';

const Container = styled.div`
  position: ${({ fixedContainer }) => (fixedContainer ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  width: ${({ fullWidth }) => (fullWidth ? '100vw' : '100%')};
  height: ${({ fullWidth }) => (fullWidth ? '100vh' : '100%')};
  background: #ffffff;
  z-index: 99;
  border-radius: 10px;
  opacity: ${({ opacity }) => (opacity ? 1 : 0.8)};
  ${t.mixins.flex()};
`;

const Content = styled.div`
  /* position: fixed; */
  position: ${({ absolute }) => (absolute ? 'absolute' : 'fixed')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 99;
  ${t.mixins.flex()};
`;

const LoaderBrand__image = styled.img`
  width: 150px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoaderBrand = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const LoaderBrand__container = styled.div`
  position: relative;
  height: 200px;
  width: 200px;
`;


const Loader__wrapper = ({
  style, absolute, fullWidth, fixedContainer, opacity, brand,
}) => (
  <Container style={style} fixedContainer={fixedContainer} opacity={opacity}>
    <Content style={style} absolute={absolute} fullWidth={fullWidth}>
      {brand ? (
        <LoaderBrand__container>
          <LoaderBrand__image src={t.image.global.logoBlue} />
          <LoaderBrand className="loader-1 center">
            <span />
          </LoaderBrand>
        </LoaderBrand__container>
      ) : (
        <Loader__small />
      )}
    </Content>
  </Container>
);

export default Loader__wrapper;
