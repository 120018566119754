
/**
   * Import Lib
   */

import React from 'react';
import styled from 'styled-components';
import { Grid__popup } from 'components/grid/grid';

const Container = styled.div``;


const ServiceDashboard__settingPop = ({
  style, active, closeEvent, children, flex,
}) => (
  <Container style={style}>
    <Grid__popup active={active} closeEvent={closeEvent} flex={flex}>
      {children }
    </Grid__popup>
  </Container>
);

export default ServiceDashboard__settingPop;
