/**
 * @name flex
 */

export const flex = ({
  display, justify, align, direction, wrap,
} = {}) => `
        display: ${display || 'flex'};
        flex-direction: ${direction || 'row'};
        justify-content: ${justify || 'center'};
        align-items: ${align || 'center'};
        flex-wrap: ${wrap || 'initial'};
    `;
