
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import t from 'theme';
/**
 * import containers & components
 */
import { ContentContext } from 'context/contentContext';
import { AppContext } from 'context/appContext';

import Button__primary from 'components/button/primary/button__primary';
import { Grid__separator } from 'components/grid/grid';
import { Content__sectionTitle } from 'components/content/content';
import { Form__textfield } from 'components/form/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-regular-svg-icons';
import { faKey } from '@fortawesome/pro-solid-svg-icons';
import { createArrayOfData } from 'helpers/js/Form';
import { strapiLogin } from 'util/strapi/content.starpi';

/**
 * styled components
 */

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const FormContainer = styled.div``;

/**
 * information component is..
 */

const Account__password = ({ style, basePage }) => {
  const { getField } = useContext(ContentContext);
  const { changePassword } = useContext(AppContext);
  const [activeButton, setActiveButton] = useState(false);
  const [passValidation, setpassValidation] = React.useState({
    valide: false,
    details: { size: false, number: false, maj: false },
  });
  const [errorMainPass, setErrorMainPass] = useState({ active: false, message: '' });
  const [errorConfirmPass, setErrorConfirmPass] = useState({ active: false, message: '' });
  const [errorNewPass, setErrorNewPass] = useState({ active: false, message: '' });

  /* ------------------------------ DEFINE STATE ------------------------------ */

  const [state, setState] = useState({
    currentPas: { value: '' },
    newPass: { value: '' },
    confirmPass: { value: '' },
    result: {
      currentPas: { result: '', type: 'text' },
      newPass: { result: '', type: 'text' },
      confirmPass: { result: '', type: 'text' },
    },
  });

  /* -------------------------- CHECK STATUS OF FIELD ------------------------- */

  const checkSaveStatus = () => {
    const arrayOfField = [state.result.currentPas, state.result.newPass, state.result.confirmPass];

    const data = createArrayOfData({ array: arrayOfField });

    if (data.filter((el) => el === null || el === '').length <= 0) {
      setActiveButton(true);
    } else if (activeButton) {
      setActiveButton(false);
    }
  };

  useEffect(() => {
    checkSaveStatus();
  }, [state]);

  /* --------------------------- STATE CHANGE EVENT --------------------------- */

  const handleStateChange = ({
    property, value, result, type,
  }) => {
    const currentState = state;

    currentState.result[property] = { result, type };

    setState((prevState) => ({
      ...prevState,
      [property]: value,
      result: {
        ...prevState.result,
        [property]: { result, type },
      },
    }));
  };

  /* ------------------------------- SAVE EVENT ------------------------------- */

  const saveEvent = async () => {
    if (state.confirmPass.value !== state.newPass.value || passValidation.valide === false) {
      if (state.confirmPass.value !== state.newPass.value) {
        setErrorConfirmPass({
          active: true,
          message: getField({ base: basePage, field: 'confirm_password_error' }),
        });
      } else {
        setErrorConfirmPass({ active: false, message: '' });
      }

      if (passValidation.valide === false) {
        setErrorNewPass({
          active: true,
          message: getField({ base: basePage, field: 'new_password_error' }),
        });
      } else {
        setErrorNewPass({ active: false, message: '' });
      }
    } else {
      setErrorConfirmPass({ active: false, message: '' });
      setErrorNewPass({ active: false, message: '' });

      const changePassWordReq = await changePassword({
        field: { current: state.result.currentPas.result, new: state.result.newPass.result },
      });
      if (changePassWordReq.sucess === false) {
        if (changePassWordReq.differente === true) {
          setErrorMainPass({
            active: true,
            message: getField({ base: basePage, field: 'current_password_error' }),
          });
        }
      } else {
        setErrorMainPass({ active: false, message: '' });
        setErrorConfirmPass({ active: false, message: '' });
        setErrorNewPass({ active: false, message: '' });
        setState({
          currentPas: { value: '' },
          newPass: { value: '' },
          confirmPass: { value: '' },
          result: {
            currentPas: { result: '', type: 'text' },
            newPass: { result: '', type: 'text' },
            confirmPass: { result: '', type: 'text' },
          },
        });
      }
    }
  };

  /* ------------------------------- RENDER FORM ------------------------------ */

  const renderContactInformationForm = () => (
    <FormContainer>
      <Form__textfield
        change={(res) => handleStateChange({
          property: 'currentPas',
          value: { value: res.value },
          result: res.value,
          type: 'text',
        })}
        value={state.currentPas.value}
        property="currentPas"
        label={`${getField({ base: basePage, field: 'current_password' })}`}
        type="password"
        error={errorMainPass}
        require
      />
      <Grid__separator size="25px" />
      <Form__textfield
        change={(res) => handleStateChange({
          property: 'newPass',
          value: { value: res.value },
          result: res.value,
          type: 'text',
        })}
        labelSecondary={getField({ base: strapiLogin, field: 'resetPass_label_info' })}
        value={state.newPass.value}
        property="newPass"
        label={`${getField({ base: basePage, field: 'new_password' })}`}
        type="password"
        passWordValidation={(value) => setpassValidation(value)}
        error={errorNewPass}
        require
      />
      <Grid__separator size="25px" />
      <Form__textfield
        change={(res) => handleStateChange({
          property: 'confirmPass',
          value: { value: res.value },
          result: res.value,
          type: 'text',
        })}
        value={state.confirmPass.value}
        property="confirmPass"
        label={`${getField({ base: basePage, field: 'confirmation_password' })}`}
        type="password"
        colorTextField={
          state.confirmPass.value === state.newPass.value ? t.color.green : t.color.red
        }
        error={errorConfirmPass}
        require
      />
      <Grid__separator size="30px" />
      <Button__primary
        withIcon
        icon={<FontAwesomeIcon icon={faSave} />}
        event={() => saveEvent()}
        style={
          !passValidation.valide
          || state.newPass.value !== state.confirmPass.value
          || state.currentPas.value === ''
            ? {
              cursor: 'intial',
              pointerEvents: 'none',
              userSelect: 'none',
              opacity: 0.3,
            }
            : {}
        }
      >
        {getField({ base: basePage, field: 'save_button_password' })}
      </Button__primary>
    </FormContainer>
  );

  /* ------------------------------ GLOBAL RENDER ----------------------------- */

  return (
    <Container style={style}>
      <Content__sectionTitle
        title={getField({ base: basePage, field: 'password_title' })}
        icon={<FontAwesomeIcon icon={faKey} />}
      />
      {renderContactInformationForm()}
    </Container>
  );
};

export default Account__password;
