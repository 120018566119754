import React from 'react';
import styled from 'styled-components';
import Button__primary from 'components/button/primary/button__primary';
import { Grid__card } from 'components/grid/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Grid__separator } from 'components/grid/grid/';
import { useHistory } from 'react-router-dom';

const MainCards = styled(Grid__card)`
  width: 245px;
  background: #01B5E2;
  box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.04);
  padding: 20px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 35px;
  color: #FFFFFF;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;
  margin-top: 15px;
  margin-bottom: 20px;
  line-height: 1.2;
`;

const Button = styled(Button__primary)`
  width: 100%;
`;

const Cards__tuto = ({
  style,
  icon,
  title,
  btnService,
  btnServiceHref,
  btnRequest,
  btnRequestHref,
}) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(btnServiceHref);
  };

  const handleSecondClick = () => {
    history.push(btnRequestHref);
  };

  return (
    <MainCards style={style}>
      <Icon icon={icon} />
      <Title>{title}</Title>
      <Button
        event={handleClick}
        icon={<FontAwesomeIcon icon={faArrowRight} />}
        white
        textColor="#01B5E2"
        withIcon
        iconEnd
      >
        {btnService}
      </Button>
      <Grid__separator size="10px" />
      <Button
        event={handleSecondClick}
        icon={<FontAwesomeIcon icon={faChartBar} />}
        border
        white
        iconEnd
        withIcon
      >
        {btnRequest}
      </Button>
    </MainCards>
  );
};

export default Cards__tuto;
