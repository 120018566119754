import { API_URL } from '../Utile';
import { CallGet, CallPost } from '../Helpers';
/* ---------------------------------- ALL USER --------------------------------- */

export const getAllUser = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/user/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/* ----------------------------- GET ALL VEHICLE ---------------------------- */

export const getVehicle = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/fleet/`;

  const res = await CallPost(url, params, headers);


  return res;
};

export const getVehicleList = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/fleetList/`;

  const res = await CallPost(url, params, headers);


  return res;
};


/* ----------------------------- GET SINGLE VEHICLE ---------------------------- */

export const getSingleVehicle = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/fleet/${params.id}`;

  const res = await CallGet(url, params, headers);


  return res;
};


/* ----------------------------- GET SERVICE HISTORI ---------------------------- */

export const getServiceHistory = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/history/`;

  const res = await CallPost(url, params, headers);


  return res;
};

export const getLatestOfType = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/getLatestOfType/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/* ----------------------------- GET ALL VEHICLE ---------------------------- */

export const getHomeBaseFLeet = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/workshop/fleetUser/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/* ----------------------------- GET SINGLE USER ---------------------------- */

export const getSingleUser = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/user/${params.id}`;

  const res = await CallGet(url, params, headers);


  return res;
};

/* ----------------------------- GET SINGLE USER ---------------------------- */

export const getSingleUserWorkshop = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/userworkshop/${params.id}`;

  const res = await CallGet(url, params, headers);


  return res;
};


/* ----------------------------- GET COVERAGE ---------------------------- */

export const getcoverage = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/coverage/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/* ----------------------------- have Service ---------------------------- */

export const getHaveService = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/haveService/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/* ----------------------------- GET ALL FAIRPRICE ---------------------------- */

export const getFairPrice = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/fairprice/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/* ----------------------------- GET ALL PAYMENT GUARANTEE ---------------------------- */

export const getPaymentGuarentee = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/paymentguarantee/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/* ----------------------------- GET ALL PRIORITY 90 ---------------------------- */

export const getPriority90 = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/priority90/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/* ----------------------------- GET ALL REPAIR APPROVAL ---------------------------- */

export const getRepairApproval = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/repairapproval/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/* ----------------------------- GET ALL STANDAR ---------------------------- */

export const getStandard = async (params) => {
  // user
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/standarrepaire/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/* ----------------------------- GET ALL SERVICE24 ---------------------------- */

export const getService24 = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/service24/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/* ----------------------------- GET ALL SMARTMAINTENANCE ---------------------------- */

export const getSmartMaintenance = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/smartMaintenance/`;

  const res = await CallPost(url, params, headers);


  return res;
};

export const getAlertGasoil = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/alertGasoil/`;

  const res = await CallPost(url, params, headers);


  return res;
};

export const getProFleetAssist = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/proFleetAssist/`;

  const res = await CallPost(url, params, headers);


  return res;
};

export const getTpms = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/tpms/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/**
 * FLEET ACTION
 */

/* ----------------------------- GET REPAIRE APPROVAL ACTION ---------------------------- */

export const getFleetRepairApprovalAction = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/repairapproval/fleet/action`;

  const res = await CallPost(url, params, headers);


  return res;
};

/**
 * Workshop Action
 */
/* ----------------------------- GET REPAIRE APPROVAL ACTION ---------------------------- */

export const getActionNeedWorkshop = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/getActionWorkshop/`;

  const res = await CallPost(url, params, headers);


  return res;
};

/* ----------------------------- GET ACTION WORKSHOP ---------------------------- */

export const getActionNeededPriority90 = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/priority90/workshop/action/`;

  const res = await CallPost(url, params, headers);


  return res;
};

export const getActionNeededPaymentGuarantee = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/paymentguarantee/workshop/action`;

  const res = await CallPost(url, params, headers);


  return res;
};

export const getActionNeededWorkshopRepairApproval = async (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const url = `${API_URL}user/v2/service/repairapproval/workshop/action`;

  const res = await CallPost(url, params, headers);


  return res;
};
