export const strapiType = {
  prefix: {
    glb: 'glb',
    flt: 'flt',
    wrk: 'wrk',
    admin: 'admin',
  },
  page: {
    fleet: {
      home: 'flthomes',
      addservice: 'fltaddservices',
      singleservice: 'glbsingleservices',
      membership: 'fltmemberships',
      account: 'glbmyaccounts',
      myService: 'Fltmyservices',
      myfleet: 'Fltmyfleets',
      homebase: 'Flthomebases',
      map: 'fltmaps',
    },
    workshop: {
      home: 'wrkhomes',
      check: 'wrkcheckvehicles',
      myfleets: 'wrkmyfleets',
      joinservice: 'wrkjoinservices',
      myservice: 'wrkmyservices',
      singleservice: 'wrksingleservices',
      singlevehicle: 'wrksinglevehicles',
      dashboardpaymentguarantee: 'Wrkdashboardpaymentguarantees',
      dashboardpriority90: 'Wrkdashboardpriority90s',
      dashboardrepairapproval: 'Wrkdashboardrepairapprovals',
      dashboardstandardrepair: 'Wrkdashboardstandardrepairs',
    },
    service: {
      paymentguarantee: 'srvpaymentguarantees',
      fairprice: 'srvfairprices',
      standardrepair: 'srvstandardrepairs',
      repairapprovals: 'srvrepairapprovals',
      service24: 'srvservice24S',
      priority90: 'srvpriority90S',
      smartmaintenance: 'srvsmartmaintenances',
      profleetassist: 'srvprofleetassists',
      tpms: 'srvtpmss',
      alertgasoil: 'srvalertgasoils',
    },
    other: {
      case: 'glbcases',
      register: 'glbregisters',
      login: 'glblogins',
      conditions: 'glbconditions',
      contract: 'glbcontracts',
      appointment: 'glbappointments',
    },
    global: {
      main: 'glbglobals',
    },
  },
};

export const strapiApi = process.env.REACT_APP_CMS_URL;
