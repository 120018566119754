
import React, {
  useContext, useEffect, useState,
} from 'react';
import Nav from 'components/global/nav';
import t from 'theme';
import { ContentContext } from 'context/contentContext';

import {
  faListOl, faQuestion, faBookOpen, faShoppingCart,
} from '@fortawesome/pro-light-svg-icons';
import Layout__container from 'containers/layout/container/layout__container';
import { Transition__loader_controller } from 'components/transition/transition';
import DashboardStandardRepair__historic from 'containers/fleetView/dashboardStandardRepair/historic/dashboardStandardRepair__historic';
import DashboardStandardRepair__action from 'containers/fleetView/dashboardStandardRepair/action/dashboardStandardRepair__action';
import DashboardStandardRepair__infoPop from 'containers/fleetView/dashboardStandardRepair/infoPop/dashboardStandardRepair__infoPop';
import DashboardStandardRepair__singlePop from 'containers/fleetView/dashboardStandardRepair/singlePop/dashboardStandardRepair__singlePop';
import {
 strapiStandartrepairService, strapiSingleServicePage, strapiRepairApprovalService, strapiCase,
} from 'util/strapi/content.starpi';
import ServiceDashboard__layout from 'containers/fleetView/serviceDashboard/layout/serviceDashboard__layout';
import { ROUTING } from 'util/constant/routing.constant';
import { getParams } from 'util/get.util';
import { SERVICENAME } from 'util/service/serviceName';
import { AppContext } from 'context/appContext';
import { formatApiCountRes } from 'util/function/formatApiRes.function';
import { getcoverage, getVehicleList } from 'helpers/api/routes/getData';
import { useData } from 'components/data/getter/data__getter';

/**
 * @name DashboardStandardRepair
 */

const DashboardStandardRepair = () => {
  const {
    loadPage, haveContentFor, getField, getGlobal,
  } = useContext(ContentContext);
  const { user } = useContext(AppContext);
  const [activeinfoPop, setactiveinfoPop] = useState(false);
  const [activesinglePop, setactivesinglePop] = useState(false);
  const [setactiveLaunchPop] = useState(false);
  let pageNumber = 1;

  const [resData, setPage] = useData({
    params: {
      userFleetId: user.user.UserFleet.id,
      both_counter: true,
      status: 'closed',
    },
    service: 'cases',
    type: 'cases',
  });

  const [fleetArray, setfleetArray] = useState([]);
  const getFleetList = async () => {
    const res = await getVehicleList({
      userId: user.user.UserFleet.id,
    });

    const formated = formatApiCountRes(res);
    if (formated.success) {
      setfleetArray(formated);
    }
  };


  const [coverage, setcoverage] = useState(0);
  const loadContent = async () => {
    if (haveContentFor(strapiStandartrepairService).success === false) {
      await loadPage(strapiStandartrepairService);
    }
    if (haveContentFor(strapiSingleServicePage).success === false) {
      await loadPage(strapiSingleServicePage);
    }
    if (haveContentFor(strapiRepairApprovalService).success === false) {
      await loadPage(strapiRepairApprovalService, strapiCase);
    }
    if (haveContentFor(strapiCase).success === false) {
      await loadPage(strapiCase);
    }

    if (getParams('launch')) {
      setactiveLaunchPop(true);
      window.history.pushState({}, '', window.location.pathname);
    }
  };


  const loadCoverage = async () => {
    const res = await getcoverage({
      service: 'standarRepairSubscription',
      userId: user.user.UserFleet.id,
    });

    const formatedCoverage = formatApiCountRes(res);

    setcoverage(formatedCoverage.count);
  };

  useEffect(() => {
    loadContent();
    loadCoverage();
    getFleetList();
  }, []);

  if (
    haveContentFor(strapiStandartrepairService).success === false
    || haveContentFor(strapiSingleServicePage).success === false
    || haveContentFor(strapiRepairApprovalService, strapiCase).success === false
    || haveContentFor(strapiCase).success === false
  ) {
    return <Transition__loader_controller active />;
  }

  const handleCloseInfoPop = () => {
    setactiveinfoPop(false);
  };

  const handleOpenInfoPop = () => {
    setactiveinfoPop(true);
  };

  const handleCloseSinglePop = () => {
    setactivesinglePop({ active: false, id: null });
  };

  const handleOpenSinglePop = ({ id }) => {
    setactivesinglePop({ active: true, id });
  };

  const renderInfoPopProps = () => ({
    intro: {
      title: getField({ base: strapiStandartrepairService, field: 'service_title' }),
      txt: getField({ base: strapiStandartrepairService, field: 'description' }),
      price: `0,00 ${getField({ base: strapiSingleServicePage, field: 'currency' })}`,
      label: getField({ base: strapiSingleServicePage, field: 'price_detail' }),
      banner: t.image.global.service_image_1,
    },
    condition1: {
      content: getField({ base: strapiStandartrepairService, field: 'registration_processs' }),
      title: getField({ base: strapiSingleServicePage, field: 'registration_processs' }),
      icon: faListOl,
    },
    condition2: {
      content: getField({ base: strapiStandartrepairService, field: 'how' }),
      title: getField({ base: strapiSingleServicePage, field: 'how' }),
      icon: faQuestion,
    },
    condition3: {
      content: getField({ base: strapiStandartrepairService, field: 'conditions' }),
      title: getField({ base: strapiSingleServicePage, field: 'conditions' }),
      icon: faBookOpen,
    },
    condition4: {
      content: getField({ base: strapiStandartrepairService, field: 'payment' }),
      title: getField({ base: strapiSingleServicePage, field: 'payment' }),
      icon: faShoppingCart,
    },
  });

  const handleTableClick = (line, vehicle) => {
    handleOpenSinglePop({ id: vehicle.id });
  };

  const handleLoadNewPage = () => {
    if (resData.data && !resData.loading && pageNumber + 1 > 1 && resData.data.length < resData.count) {
      pageNumber += 1;
      setPage(pageNumber, resData.data);
    }
  };

  return (
    <Layout__container>
      <Nav active="myservices" />
      <Transition__loader_controller active={false} />
      <ServiceDashboard__layout
        page_title={getField({ base: strapiStandartrepairService, field: 'service_title' })}
        // breadCrumb
        breadCrumb_parent={getGlobal({ option: 'fleet_menu', field: 'my_services' })}
        breadCrumb_parentLink={ROUTING.fleet.myServices.path}
        breadCrumb_current={getField({ base: strapiStandartrepairService, field: 'service_title' })}
        // Service history layout
        history={(
          <DashboardStandardRepair__historic
            tableEvent={handleTableClick}
            loadingData={resData.loading}
            data={resData.data}
            scrollEndEvent={handleLoadNewPage}
            loadingNewPage={resData.loading}
          />
        )}
        // Action side element
        action={(
          <DashboardStandardRepair__action
            infoEvent={handleOpenInfoPop}
            coverage={coverage}
            fleet={fleetArray ? fleetArray.count : 0}
          />
        )}
        // Info pop up
        activeInfoPop={activeinfoPop}
        closeInfoPop={handleCloseInfoPop}
        infoPopProps={renderInfoPopProps()}
        infoPop={<DashboardStandardRepair__infoPop />}
        // Single pop up
        activeSinglePop={activesinglePop}
        closeSinglePop={handleCloseSinglePop}
        singlePop={(
          <DashboardStandardRepair__singlePop
            active={activesinglePop.active}
            id={activesinglePop.id}
            closeEvent={handleCloseSinglePop}
          />
        )}
      />
    </Layout__container>
  );
};

export default DashboardStandardRepair;
