/**
 * Import Lib & assets
 */

import React from 'react';
import styled from 'styled-components';

const Description = styled.div`
  background: #f9f9f9;
  width: calc(100% + 130px);
  margin-top: 65px;
  margin-left: -65px;
  padding-left: 65px;
  padding-right: 65px;
  padding-top: 65px;
  padding-bottom: 65px;
`;

const SingleService__description = ({ style, children, className }) => (
  <Description style={style} className={className}>
    {children}
  </Description>
);

export default SingleService__description;
