
/**
   * Import Lib & assets
   */

import React, {
  Fragment, useState, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import Form__textfield from 'components/form/textfield/form__textfield';
import Button__primary from 'components/button/primary/button__primary';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid__separator from 'components/grid/separator/grid__separator';
import t from 'theme';
import CheckVehicle__singlePop from 'containers/workshopView/checkVehicle/singlePop/checkVehicle__singlePop';
import { CheckVehicle } from 'helpers/api/routes/Fleets';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { formatApiRes } from 'util/function/formatApiRes.function';
import {
 strapiMyFleetPage, strapiFairPriceService, strapiCase,
} from 'util/strapi/content.starpi';
import Grid__card from 'components/grid/card/grid__card';

import { ContentContext } from 'context/contentContext';
import { List__table } from 'components/list/list';
import { sequelizeDate } from 'util/date.util';
import Grid__popup from 'components/grid/popup/grid__popup';
import List__content from 'components/list/content/list__content';
import Content__cardTitle from 'components/content/cardTitle/content__cardTitle';


const Cards = styled(Grid__card)`
  width: 100%;
  padding: 65px;
  text-align: center;
  position: relative;
  padding: ${(props) => (props.small ? '30px' : '65px')};
`;

const Container = styled.div``;

const Illu = styled.img`
  width: ${(props) => (props.small ? '70px' : '105px')};
`;

const Header = styled.div`
width: 100%;
display: flex;
justify-content: ${(props) => (props.small ? 'flext-start' : 'center')};
align-items: center;
flex-direction: ${(props) => (props.small ? 'row' : 'column')};
`;

const Title = styled.div`
  margin-top: ${(props) => (props.small ? '0' : '15px')};
  margin-left: ${(props) => (props.small ? '20px' : '0')};
  font-weight: 600;
  font-size: 26px;
  line-height: 1.2;
  text-align: left;
  text-transform: uppercase;
  color: #01B5E2;
`;

const ErrorText = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #E74C3C;
`;

const Content = styled.div`
  margin-top: ${(props) => (props.small ? '15px' : '25px')};
  font-weight: normal;
  font-size: ${(props) => (props.small ? '14px' : '16px')};
  line-height: 1.5;
  text-align: ${(props) => (props.small ? 'left' : 'center')};
  color: #4D4D4D;
  margin-bottom: 25px;
`;

const Button = styled(Button__primary)`
  width: ${(props) => (props.small ? '100%' : 'auto')};
`;

const Table = styled(List__table)`
  height: calc(100% - 70px);
`;

const ContainerPopUpContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;


const Cards__checkPlate = ({
  style, small, title, content,
}) => {
  const { getGlobal } = useContext(ContentContext);
  const [loading, setloading] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [error, seterror] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState(false);
  const [registration, setregistration] = useState('');
  const [shouldRenderSelectModal, setshouldRenderSelectModal] = useState(false);
  const [vehicleList, setvehicleList] = useState(false);
  const [vehicleListFormated, setvehicleListFormated] = useState([]);

  const headerCol = [
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'registrationnumber' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'countryofregistration' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'vincode' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'category' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'brand' }),
    },
    {
      label: getGlobal({ option: 'vehicleParameter', field: 'firstregistration' }),
    },
    {
      label: '',
    },
  ];


  const handleSelect = (key) => {
    setCurrentVehicle(vehicleList[key]);
    setOpenPopUp(true);
    setshouldRenderSelectModal(false);
  };

  useEffect(() => {
    if (vehicleList.length > 0) {
      const obj = [];
      const noFormatedObj = [];
      const orderArray = vehicleList;
      orderArray.forEach((element, key) => {
        const childObj = [];

        childObj.push({ label: <List__content uppercase>{element.registrationNumber}</List__content> });
        childObj.push({
          label: (
            <List__content capitalize>
              {getGlobal({ option: 'country', field: element.countryOfRegistration })
                || element.countryOfRegistration}
            </List__content>
          ),
        });
        childObj.push({ label: <List__content uppercase>{element.vin}</List__content> });
        childObj.push({
          label: (
            <List__content capitalize>
              {getGlobal({ option: 'vehicletype', field: element.type }) || element.type}
            </List__content>
          ),
        });
        childObj.push({ label: <List__content capitalize>{element.brand}</List__content> });
        childObj.push({
          label: (
            <List__content>{sequelizeDate({ date: element.firstDate, format: 'd/m/y' })}</List__content>
          ),
        });
        childObj.push({ label: <Button__primary event={() => handleSelect(key)} withIcon iconEnd icon={<FontAwesomeIcon icon={faArrowRight} />}>{getGlobal({ option: 'vehicleParameter', field: 'button_select' })}</Button__primary> });

        obj.push(childObj);
        noFormatedObj.push(element);
      });

      setvehicleListFormated(obj);
    }
  }, [vehicleList]);

  const handleClosePop = () => {
    setregistration('');
    setCurrentVehicle(false);
    setOpenPopUp(false);
  };

  const handleInputChange = (value) => {
    setregistration(value.value);
  };

  const renderChoiceModal = (data) => {
    const array = [];
    data.forEach((element) => {
      if (!element.deleted) {
        array.push(element);
      }
    });
    setshouldRenderSelectModal(true);
    setvehicleList(data);
  };

  const handleCheckClick = async () => {
    seterror(false);
    if (registration && registration !== '') {
      setloading(true);
      seterror(false);
      const res = await CheckVehicle(registration.toUpperCase());
      const formated = formatApiRes(res);
      if (formated) {
        setloading(false);
        if (formated.success === true && formated.data && formated.data.length > 0) {
          if (formated.data && formated.data.length > 1) {
            renderChoiceModal(formated.data);
          } else if (formated.data && formated.data.length === 1) {
            if (formated.data[0].deleted) {
              seterror(true);
            } else {
              setCurrentVehicle(formated.data[0]);
              setOpenPopUp(true);
            }
          }
        } else {
          seterror(true);
        }
      }
    } else {
      seterror(true);
    }
  };

  const handleCloseSelectModal = () => {
    setshouldRenderSelectModal(false);
  };
  return (
    <Fragment>
      <Cards small={small}>
        <Container style={style}>
          <Header small={small}>
            <Illu small={small} src={t.image.illu.tools} />
            <Title small={small}>{title}</Title>
          </Header>
          <Content small={small}>{content}</Content>
          <Form__textfield
            change={handleInputChange}
            value={registration}
            placeholder="EF547GY"
            require
            label={getGlobal({ option: 'check_vehicle', field: 'input_label' })}
            onKeyPressEnterEvent={handleCheckClick}
          />
          {
            error ? (
              <Fragment>
                <Grid__separator size="10px" />
                <ErrorText>{getGlobal({ option: 'check_vehicle', field: 'error_not_found' })}</ErrorText>
                <Grid__separator size="15px" />
              </Fragment>
            ) : <Grid__separator size="25px" />
          }
          <Button
            small={small}
            withIcon
            icon={<FontAwesomeIcon icon={faArrowRight} />}
            iconEnd
            event={handleCheckClick}
          >
            {getGlobal({ option: 'check_vehicle', field: 'button' })}
          </Button>
        </Container>
        {loading && <Loader__wrapper absolute />}
      </Cards>
      <CheckVehicle__singlePop
        active={openPopUp}
        vehicle={currentVehicle}
        closePop={handleClosePop}
        basePage={strapiMyFleetPage}
        baseSrvFairPrice={strapiFairPriceService}
        baseCase={strapiCase}
      />
      <Grid__popup active={shouldRenderSelectModal} closeEvent={handleCloseSelectModal}>
        <ContainerPopUpContent>
          <Content__cardTitle content={getGlobal({ option: 'check_vehicle', field: 'error_not_multiple_vehicle' })} />
          <Grid__separator size="15px" />
          <Content small={small}>{getGlobal({ option: 'check_vehicle', field: 'error_not_multiple_action' })}</Content>
          <Grid__separator size="35px" />
          {
            shouldRenderSelectModal && (
              <Table
                headerCol={headerCol}
                lineCol={vehicleListFormated}
                noSelect
              />
            )
          }
        </ContainerPopUpContent>
      </Grid__popup>
    </Fragment>
  );
};

export default Cards__checkPlate;
