export const formatApiRes = (res) => {
  if (res) {
    if (res.data) {
      if (res.data.data) {
        return {
          data: res.data.data,
          success: res.data.success,
        };
      }
      return {
        data: res.data.message || null,
        success: res.data.success,
      };
    }
    return {
      data: null,
      success: false,
    };
  }
  return {
    data: null,
    success: false,
  };
};

export const formatApiCountRes = (res) => {
  if (res) {
    if (res.data) {
      if (res.data.data) {
        return {
          data: res.data.data.rows,
          count: res.data.data.count,
          success: res.data.success,
          brute: res.data.data,
        };
      }
      return {
        data: res.data.message || null,
        success: res.data.success,
      };
    }
    return {
      data: null,
      success: false,
    };
  }
  return {
    data: null,
    success: false,
  };
};
