
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { ContentContext } from 'context/contentContext';

/**
 * import containers & components
 */

import { Grid__separator } from 'components/grid/grid/';
import { Form__textfield } from 'components/form/form';
import { AppContext } from 'context/appContext';
import { FleetContext } from 'context/fleetUser/fleetContext';
import { Button__primary, Button__inline } from 'components/button/button';
import { Content__text } from 'components/content/content';
import Loader__wrapper from 'components/loader/wrapper/loader__wrapper';
import { ROUTING } from 'util/constant/routing.constant';
import { strapiLogin } from 'util/strapi/content.starpi';

/**
 * styled components
 */

const FormContent = styled.div`
  width: 100%;
`;

const SaveBtn = styled(Button__primary)`
  width: 100%;
  text-align: center;
`;

const TitleForm = styled(Content__text)`
  text-align: center;
  width: 100%;
  margin-bottom: 35px;
  font-weight: 600;
  font-size: 30px;
  line-height: auto;
  text-align: center;
  color: #1a1a1a;
`;

const BtnSecondary = styled(Button__inline)`
  text-align: center;
  text-decoration: none;
`;

const Container = styled.div`
  text-align: center;
`;

const Error = styled.p`
  font-size: 12px;
  font-weight: normal;
  color: #e74c3c;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
`;

const initialState = {
  mailValue: '',
  passValue: '',
  activeScreen: false,
};

const Login__form = () => {
  const { login, checkAuth } = useContext(AppContext);
  const {
    getFieldWithNavigatorLang,
    changeLang,
    lang,
  } = useContext(ContentContext);
  const { initFleetContext } = useContext(FleetContext);
  const history = useHistory();
  const location = useLocation();

  const [error, setError] = React.useState({});
  const [loading, setloading] = React.useState(false);
  const [errormessage, setErrormessage] = React.useState();
  const [formData, setformData] = React.useState(initialState);
  const [active, setActive] = React.useState(true);
  const { mailValue, passValue } = formData;

  const handleInputChange = ({ property, value }) => {
    setformData({
      ...formData,
      [property]: value,
    });
  };

  const redirectFunc = () => {
    const from_url = localStorage.getItem('from_url');
    if (from_url) {
      history.replace(from_url);
    } else if (location.hasOwnProperty('state') && location.state !== undefined) {
      history.push('/');
    } else {
      history.push('/');
    }
  };

  const getDataAsync = async () => {
    const fleetCall = await initFleetContext();
    if (fleetCall.success === true) {
      return {
        success: true,
      };
    }
    return {
      success: false,
    };
  };

  const handleCheckAuth = async () => {
    const loginCall = await checkAuth();
    if (loginCall.success === true) {
      const getData = await getDataAsync();
      if (getData.success === true) {
        redirectFunc();
      } else {
        setActive(true);
      }
    } else {
      setActive(true);
    }
  };

  const checkField = () => {
    const errorArray = {};
    let hasError = false;
    if (mailValue === '') {
      errorArray.mail = true;
      hasError = true;
    }
    if (passValue === '') {
      errorArray.pass = true;
      hasError = true;
    }
    setError(errorArray);
    return hasError;
  };

  const parseApiError = (apiData) => {
    if (apiData.data) {
      if (apiData.data === 'user not found' || apiData.data === 'invalid password') {
        const errorArray = {};
        errorArray.mail = true;
        errorArray.pass = true;
        setError(errorArray);
        setErrormessage(getFieldWithNavigatorLang({ base: strapiLogin, field: 'wrongPass' }));
      }
    }
  };

  const setLang = (data) => {
    if (data && data.data && data.data.data && data.data.data.user && data.data.data.user.lang) {
      if (lang !== data.data.data.user.lang) {
        changeLang(data.data.data.user.lang);
      }
    }
  };

  const handleSave = async () => {
    const obj = {
      mail: mailValue.toLowerCase(),
      pass: passValue,
    };

    if (!checkField()) {
      setloading(true);
      const loginCall = await login({ mail: obj.mail, pass: obj.pass });

      if (loginCall.success === true) {
        setLang(loginCall);
        const getData = await getDataAsync();
        if (getData.success === true) {
          redirectFunc();
          setloading(false);
        }
      } else {
        setloading(false);
        parseApiError(loginCall);
      }
    }
  };

  useEffect(() => {
    handleCheckAuth();
  }, []);

  return (
    <FormContent>
      {active ? (
        <Container>
          <TitleForm type="h1">
            {getFieldWithNavigatorLang({ base: strapiLogin, field: 'login_title' })}
          </TitleForm>
          {errormessage && <Error>{errormessage}</Error>}
          <Form__textfield
            onKeyPressEnterEvent={() => handleSave()}
            change={handleInputChange}
            value={mailValue}
            property="mailValue"
            label={getFieldWithNavigatorLang({ base: strapiLogin, field: 'email' })}
            require
            error={error.hasOwnProperty('mail') ? { active: true } : false}
          />
          <Grid__separator size="25px" />
          <Form__textfield
            onKeyPressEnterEvent={() => handleSave()}
            require
            change={handleInputChange}
            value={passValue}
            property="passValue"
            label={getFieldWithNavigatorLang({ base: strapiLogin, field: 'password' })}
            placeholder=""
            type="password"
            error={error.hasOwnProperty('pass') ? { active: true } : false}
          />
          <Grid__separator size="25px" />
          <SaveBtn orange event={() => handleSave()}>
            {getFieldWithNavigatorLang({ base: strapiLogin, field: 'login_btn' })}
          </SaveBtn>
          <Grid__separator size="15px" />
          <BtnSecondary
            href={ROUTING.fleet.passwordRecovery.path}
            content={getFieldWithNavigatorLang({ base: strapiLogin, field: 'forgotPass' })}
          />
          {loading ? <Loader__wrapper /> : false}
        </Container>
      ) : null}
    </FormContent>
  );
};

export default Login__form;
