import { SERVICE_STATUS } from '../constant/servicestatus.constant';

export const repairApproval_isAction = (data, user) => {
  if (data) {
    if (data.status === SERVICE_STATUS.error && data.refusedConfirmation === false) {
      return SERVICE_STATUS.action;
    }
    if (user) {
      if (
        data.status === SERVICE_STATUS.waiting
        && user
        && user.user && user.user.UserFleet
      ) {
        if (
          user.user.UserFleet.repairApprovalUserId
          && user.user.UserFleet.repairApprovalUserId === user.user.id
        ) {
          return SERVICE_STATUS.action;
        }
      }
    }

    return data.status;
  }
  return data;
};

export const checkIsApprover = (data, user) => {
  if (data && data.Fleet && data.Fleet.UserFleet && data.Fleet.UserFleet.repairApprovalUserId !== null && !data.Fleet.UserFleet.repairApproverUserTier) {
    if (data.Fleet.UserFleet.repairApprovalUserId === user.user.id) {
      return true;
    }
  }

  if (data && data.fleetUser_repairApprovalUserId !== null && data.fleetUser_repairApprovalUserId === user.user.id) {
    return true;
  }

  return false;
};


export const checkIfActionWorkshop = (dataStatus, data, user) => {
  if (dataStatus) {
    if ((dataStatus === SERVICE_STATUS.waiting || dataStatus === SERVICE_STATUS.action) && checkIsApprover(data, user)) {
      return SERVICE_STATUS.action;
    }
    return dataStatus;
  }

  return false;
};
