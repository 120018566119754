import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import t from 'theme';
import { Grid__separator } from 'components/grid/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Form__textfield } from 'components/form/form';
import { Content__titleLight } from 'components/content/content';
import { ContentContext } from 'context/contentContext';


const Container = styled.div`
  ${({ disable }) => disable && `
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  `};
`;

const IndicatorCercle = styled.span`
  display: block;
  width: 27px;
  height: 27px;
  background: #FFFFFF;
  border: 2px solid #CCCCCC;
  border-color: ${({ current }) => (current ? '#FFFFFF' : '#CCCCCC')};
  border-radius: 50%;
  margin-right: 30px;
  z-index: 1;
  position: relative;
  transition: all ease 0.3s;
`;

const ContentContainer = styled.div`
  ${t.mixins.flex({ justify: 'flex-start', align: 'flex-start', direction: 'column' })};
`;

const CardContainer = styled.div`
  ${t.mixins.flex({ justify: 'space-between', align: 'flex-start' })};
  margin-bottom: 30px;
`;

const Content = styled.div`
  z-index: 1;
  position: relative;
  font-weight: 600;
  font-size: 18px;
  color: ${({ current }) => (current ? '#FFFFFF' : '#4D4D4D')};
  transition: all ease 0.3s;
`;

const SubContent = styled.div`
  z-index: 1;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  color: ${({ current }) => (current ? '#FFFFFF' : '#4D4D4D')};
  transition: all ease 0.3s;
  opacity: .8;
  margin-top: 10px;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ current }) => (current ? '#01B5E2' : '#FFFFFF')};
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 16px;
  transition: all ease 0.3s;
`;

const Card = styled.label`
  background: ${({ current }) => (current ? '#01B5E2' : '#FFFFFF')};
  box-shadow: 0px 5px 20px rgba(58, 74, 78, 0.1);
  border-radius: 10px;
  padding: 30px;
  position: relative;
  width: 100%;
  ${t.mixins.flex({ justify: 'flex-start', align: 'center' })};
  cursor: pointer;
  transition: all ease 0.3s;
  &:not(:last-child) {
    margin-right: 30px;
  }
  pointer-events: ${({ disable }) => (disable ? 'none' : 'initial')};
  opacity: ${({ disable }) => (disable ? '0.5' : '1')};
`;

const Input = styled.input`
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 5px;
  opacity: 0;
`;

const Form__optionSelector = ({
  style,
  title,
  change,
  choice1Title,
  choice1Content,
  choice2Title,
  choice2Content,
  choice3Title,
  choice3Label,
  choice3Content,
  textfieldChange,
  choice3Value,
  choice3Error,
  initialValue,
  asHomeBase,
  disable,
}) => {
  const { getGlobal } = useContext(ContentContext);
  const asInitialValue = () => {
    if (initialValue === 1) {
      return 'radio1';
    }
    if (initialValue === 2) {
      return 'radio2';
    }
    if (initialValue === 3) {
      return 'radio3';
    }

    return false;
  };
  const [currentInput, setcurrentInput] = useState(asHomeBase ? 'radio1' : 'radio2');
  const value1 = 'radio1';
  const value2 = 'radio2';
  const value3 = 'radio3';
  const isCurrent1 = () => currentInput === value1;
  const isCurrent2 = () => currentInput === value2;
  const isCurrent3 = () => currentInput === value3;
  const handleChange = (event) => {
    if (!disable) {
      setcurrentInput(event.currentTarget.value);
      if (change) {
        let value = 1;
        if (event.currentTarget.value === value2) {
          value = 2;
        }

        if (event.currentTarget.value === value3) {
          value = 3;
        }

        change(value);
      }
    }
  };

  useEffect(() => {
    if (initialValue) {
      setcurrentInput(asInitialValue());
    }
  }, [initialValue]);

  return (
    <Container style={style} disable={disable}>
      <Grid__separator size="65px" />
      <Content__titleLight content={title} />
      <Grid__separator size="35px" />

      <CardContainer>
        <Card for="radio1" current={isCurrent1()} disable={!asHomeBase || disable}>
          <IndicatorCercle current={isCurrent1()}>
            <Icon icon={faCheck} current={isCurrent1()} />
          </IndicatorCercle>
          <ContentContainer>
            <Content current={isCurrent1()}>{choice1Title}</Content>
            <SubContent current={isCurrent1()}>{choice1Content ? choice1Content : getGlobal({ option: 'error', field: 'no_home_based' }) }</SubContent>
          </ContentContainer>
          <Input type="radio" name="radio" id={value1} value={value1} onChange={handleChange} />
        </Card>

        <Card for="radio2" current={isCurrent2()} disable={disable}>
          <IndicatorCercle current={isCurrent2()}>
            <Icon icon={faCheck} current={isCurrent2()} />
          </IndicatorCercle>
          <ContentContainer>
            <Content current={isCurrent2()}>{choice2Title}</Content>
            <SubContent current={isCurrent2()}>{choice2Content}</SubContent>
          </ContentContainer>
          <Input type="radio" name="radio" id={value2} value={value2} onChange={handleChange} />
        </Card>
      </CardContainer>

      <Card for="radio3" current={isCurrent3()} disable={disable}>
        <IndicatorCercle current={isCurrent3()}>
          <Icon icon={faCheck} current={isCurrent3()} />
        </IndicatorCercle>
        <ContentContainer>
          <Content current={isCurrent3()}>{choice3Title}</Content>
          <SubContent current={isCurrent3()}>{choice3Content}</SubContent>
          <Grid__separator size="25px" />
          <Form__textfield
            style={{ minWidth: 400 }}
            label={choice3Label}
            require
            current={isCurrent3()}
            change={textfieldChange}
            value={choice3Value}
            error={choice3Error}
          />
        </ContentContainer>
        <Input type="radio" name="radio" id={value3} value={value3} onChange={handleChange} />
      </Card>

    </Container>
  );
};

export default Form__optionSelector;
