
/**
 * Import Lib & assets
 */

import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
// import 'moment/locale/fr';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './form__datePicker.css';

import t from 'theme';
import Form__label from 'components/form/label/form__label';
/**
 * import components
 */

import { ContentContext } from 'context/contentContext';
// import moment from 'moment';
import moment from 'moment/min/moment-with-locales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons';
import { createDateTime } from '../../../util/function/createDateTime';


/**
 * styled components
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: ${t.font.primary};
  position: relative;
  .react-datepicker  {
      border-radius: 10px;
      box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.1);
      border: none;
  }
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
      border-top-right-radius: 10px;
  }
  .react-datepicker__header {
      background-color: #fff;
      border-bottom: none;
  }
  .react-datepicker__day--selected {
    background-color: #01B5E2;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: #4D4D4D;
  }
  .react-datepicker__current-month {
    margin-top: 6px;
  }
`;

const CalendarIcon = styled(FontAwesomeIcon)`
  width: 20px;
  height: auto;
  position: absolute;
  right: 12px;
  font-size: 16px;
  font-weight: 500;
  bottom: 12px;
  color: #4D4D4D;
  cursor: pointer;
`;

const ContainerInput = styled.label`
  cursor: pointer;
`;

const Input = styled.input`
  border-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  margin-top: 6px;
  color: #4D4D4D;
  cursor: pointer;
`;

/**
 * date component is..
 */


const Form__datePicker = ({
  style, event, label, value, className, error, property,
  labelStyle, current, labelSecondary, disabledDays,
}) => {
  const { getGlobal } = useContext(ContentContext);
  const [dateValue, setdateValue] = useState(value);
  const handleInputChange = (fieldValue) => {
    event({
      property,
      value: fieldValue,
    });
  };

  useEffect(() => {
    setdateValue(value);
  }, [value]);

  const getDisabledDays = (date) => {
    if (disabledDays) {
      const day = moment(date).day();
      const find = disabledDays.find((single) => single === day);
      if (find || find === 0) {
        return false;
      }
    }

    return true;
  };

  return (
    <Container style={style} className={className}>
      {label ? (
        <Form__label
          style={labelStyle}
          require={require}
          current={current}
          labelSecondary={labelSecondary}
        >
          {label}
        </Form__label>
      ) : null}
      <DatePicker
        onChange={handleInputChange}
        filterDate={getDisabledDays}
        selected={dateValue}
        minDate={new Date()}
        customInput={(
          <ContainerInput>
            <Input value={moment(dateValue).format('DD/MM/YYYY')} />
            <CalendarIcon icon={faCalendarDay} />
          </ContainerInput>
        )}
      />
    </Container>
  );
};

export default Form__datePicker;
