import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Form__textfield } from 'components/form/form';

import Button__primary from 'components/button/primary/button__primary';

const SearchInput = styled.div`
  width: 330px;
  height: 40px;
  /* box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15); */
  border-radius: 10px;
  display: flex;
`;

const Button = styled(Button__primary)`
  display: inline-block;
  height: 100%;
  width: 50px;
  padding: 0;
  background: #01B5E2;
  position: relative;
  border-radius: 0;
  min-width: auto;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: .3s ease background;
  box-shadow: none;
  &:hover {
    box-shadow: none;
    transform: translate(0);
    background: #0097BD;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 12px;
  right: 13px;
`;

const Form__search = ({
  style,
  placeholder,
  change,
  searchValue,
  event,
}) => (
  <SearchInput style={style}>
    <Form__textfield
      onKeyPressEnterEvent={event}
      property="searchValue"
      searchAlternate
      placeholder={placeholder}
      change={change}
      value={searchValue}
    />
    <Button event={event}>
      <Icon icon={faSearch} />
    </Button>
  </SearchInput>
);

export default Form__search;
