
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import t from 'theme';
import Grid__infoMsg from 'components/grid/infoMsg/grid__infoMsg';

import { AppContext } from 'context/appContext';
import { ContentContext } from 'context/contentContext';

import { format_str } from 'util/formatContent.util';
import { strapiHomePage } from 'util/strapi/content.starpi';
import { getStats } from 'helpers/api/routes/util';
import { formatApiRes } from 'util/function/formatApiRes.function';

const Container = styled.div`
  padding-bottom: 25px;
  ${t.mixins.flex({ justify: 'space-between', direction: 'row', align: 'flex-start' })};
`;

const TitlePrimary = styled.span`
  font-family: ${t.font.primary};
  font-weight: 600;
  font-size: 27px;
  line-height: 37px;
  margin: 0;
  color: #2f2f30;
`;

const TitleSecondary = styled.span`
  font-family: ${t.font.primary};
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #808080;
  margin-top: 5px;
`;

const InfoMsg = styled(Grid__infoMsg)`
  margin-top: -10px;
`;

const TitleContainer = styled.div`
  ${t.mixins.flex({ align: 'left', direction: 'column' })};
`;

const Home__header = ({ style }) => {
  const { user } = useContext(AppContext);
  const { getField, getGlobal } = useContext(ContentContext);
  const [statistic, setstatistic] = useState(null);
  const { gender, lastName, companyName } = user.user;

  const getGlobalState = async () => {
    const res = await getStats();
    const formatedRes = formatApiRes(res);
    if (formatedRes.success) {
      setstatistic(formatedRes.data);
    }
  };

  useEffect(() => {
    getGlobalState();
  }, []);
  return (
    <Container style={style}>
      <TitleContainer>
        <TitlePrimary>
          {getField({ base: strapiHomePage, field: 'welcome' })}
          {' '}
          {getGlobal({ option: 'gender', field: gender })}
          {' '}
          {format_str({ string: lastName, format: 'capital' })}
        </TitlePrimary>
        <TitleSecondary>
          {getField({ base: strapiHomePage, field: 'from' })}
          {' '}
          {format_str({ string: companyName, format: 'capital' })}
        </TitleSecondary>
      </TitleContainer>
      {
        statistic && (
          <InfoMsg
            content={`${getField({ base: strapiHomePage, field: 'notification_part_1' })} ${statistic.userWorkshop} ${getField({ base: strapiHomePage, field: 'notification_part_2' })} ${statistic.userfleet} ${getField({ base: strapiHomePage, field: 'notification_part_3' })} ${statistic.fleet} ${getField({ base: strapiHomePage, field: 'notification_part_4' })}`}
          />
        )
      }
    </Container>
  );
};

export default Home__header;
