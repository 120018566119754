
import React from 'react';
import styled from 'styled-components';

/**
 * styled components
 */

const Container = styled.div`
  background-color: ${(props) => props.background || 'transparent'};
  height: auto;
  min-height: 100%;
  width: ${(props) => props.width || '100%'};
  max-width: 100%;
  margin: 0 auto;
  padding-left: 238px;
  min-height: 100vh;
`;

/**
   * Layout wrapper is used for outer page content
   */

const layout__wrapper = ({
  style, children, background, width, className,
}) => (
  <Container style={style} background={background} width={width} className={className}>
    {children}
  </Container>
);

export default layout__wrapper;
